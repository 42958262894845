<template>
  <div style="display: grid; height: 100%;">
      <img style="width:100%;" src="../assets/offline.png" />
  </div>
</template>
<style scoped>
</style>
<script>
      
    import router from "../router"
    export default {
      name: "Raspberry",
      data() {
        return {
         
        }
      },
    methods: {
      
      },
      created() {
      },
      destroyed() {
      },
    mounted() {
        this.$on('online', () => {
            router.push("/raspberry");
        });
    }
  }
</script>
