<template>
  <div id="dash">
    <div id="meeting-box">
       <div id="meeting-description" class="fit-width" style="max-width: 1000px;margin: 30px auto; padding: 10px 75px;margin-bottom: 15px;">
          <div class="meeting-descript">{{permissions.organization.name}}</div>
          <div v-if="week_data.total_weeks>0 && next_meeting != null && next_meeting!= ''" style="font-size:12px;color:#a9a9a9;">{{$t('dashboard.meeting_text', week_data)}}</div>
          <div style="color:#333;font-size:16px;" v-if="next_meeting != null && next_meeting!= ''"><b>{{$d(new Date("" + next_meeting.split("-").join("/") + " 00:01"), 'day_month_long')}}</b> @ {{meet_start}}</div>
          <div style="color:#333;font-size:16px;" v-if="next_meeting == null || next_meeting== ''"><b>{{$t('dashboard.no_meeting')}}</b></div>
          <div v-if="hour_diff<24 && hour_diff>=1 && following_meeting != null" v-html='$t("dashboard.switching_hours", {date:$d(new Date("" + following_meeting.split("-").join("/") + " 00:01"), "day_month_long"),hours: hour_diff})'></div>
          <div v-if="hour_diff<1 && hour_diff>=0 && min_diff>0 && following_meeting != null" v-html='$t("dashboard.switching_minutes", {date:$d(new Date("" + following_meeting.split("-").join("/") + " 00:01"), "day_month_long"),mins: min_diff})'></div>
          <div v-if="hour_diff<1 && hour_diff>=0 && min_diff==0 && following_meeting != null" v-html='$t("dashboard.switching_soon", {date:$d(new Date("" + following_meeting.split("-").join("/") + " 00:01"), "day_month_long")})'></div>
          <div v-if="next_meeting != null && next_meeting!= '' && joint_organization.length>0" style="color:#a9a9a9;" v-html="$t('dashboard.joint_meeting', {joint_organization: joint_organization})"></div>
        </div>
        
      <Tip :tipNumber="1"></Tip>
        <b-alert :show="debug_mode" style="margin:auto;" class="fit-width" variant="danger">{{ $t('general.debug_message1') }}<br/>{{ $t('general.debug_message2') }}</b-alert>
      <div :id="onboard==1 && official? 'dashboard-sections-setup' : 'dashboard-sections'">
        <div v-if="onboard==1 && official" class="dashboard-section" style="grid-area: a;">
          <div class="dashboard-title" style="text-align:center;">
              <span>{{$t('dashboard.setup_chapter')}}</span>
            </div>
            <!--<div class="portal-option" style="max-width: 300px;" v-on:click="goToSetupPage('/admin/subscription', '',1)">
              <div class="option-title" >{{$t('dashboard.setup_subscription')}}</div>
            </div>-->
            <div class="portal-option" style="max-width: 300px;text-align:left;" v-on:click="goToSetupPage('/admin/general', 'times-anchor',2)">
              <div class="option-title" >{{$t('dashboard.setup_settings')}}</div>
            </div>
            <div class="portal-option" style="max-width: 300px;text-align:left;" v-on:click="goToSetupPage('/admin/members', '',3)">
              <div class="option-title" >{{$t('dashboard.setup_members')}}</div>
            </div>
            <div class="portal-option" style="max-width: 300px;text-align:left;" v-on:click="showSendRequestContentEmails">
              <div class="option-title" >{{$t('dashboard.request_member_content')}}</div>
            </div>
            <div class="portal-option" style="max-width: 300px;text-align:left;" v-on:click="goToSetupPage('/admin/general', 'delegate-anchor',4)">
              <div class="option-title" >{{$t('dashboard.setup_delegating')}}</div>
            </div>
            <div class="portal-option" style="max-width: 300px;text-align:left;" v-on:click="goToSetupPage('/admin/agenda', '',5)">
              <div class="option-title" >{{$t('dashboard.setup_agenda')}}</div>
            </div>
            <div class="portal-option" style="max-width: 300px;text-align:left;" v-on:click="goToSetupPage('/admin/scripts', '',6)">
              <div class="option-title" >{{$t('dashboard.setup_script')}}</div>
            </div>
            <div class="portal-option" style="max-width: 300px;text-align:left;" v-on:click="goToSetupDemo">
              <div class="option-title" >{{$t('dashboard.setup_meeting')}}</div>
            </div>
            <b-button variant="link" v-on:click="hideSetup" style="padding:0;border: none;"><i>{{$t('dashboard.hide_setup')}}</i></b-button>
        </div>
        <div style="padding:10px; grid-area: b;">
          <div class="dashboard-title" style="grid-column:1/3;text-align:center;">
              <span>{{$t('dashboard.plan_manage')}}</span>
            </div>
          <div style="display:grid; grid-gap: 20px;margin-top: 15px;" v-masonry="containerId" transition-duration="0.1s"  column-width="320"  item-selector=".dashboard-bucket" gutter="15">
            <div v-masonry-tile v-if="!official && !rst" class="dashboard-bucket hot-bucket" v-on:click="goToMembers">
              <div class="bucket-title" >
                <b-img :src="getImgUrl('nav-Members-Gray.png')" /><span>{{$t('dashboard.applicants_bucket')}}</span>
              </div>
              <div class="bucket-body" >
                <div><span style="color:#999;">{{$t('dashboard.applicants_bucket')}}:</span> <span :style="{'color' : meetingApplicants.num<meetingApplicants.min? '#F8696B' : '#63BE7B'}">{{meetingApplicants.num}}</span>  <span style="margin-left:10px;">{{$t('dashboard.target_text')}}:</span> <span style="color:#666;">{{meetingApplicants.min}}</span> </div>
              </div>
            </div>
            <div v-masonry-tile v-if="yearRoles.r1>0 && (yearRoles.show>0) && official && !isCorporateConnections" class="dashboard-bucket hot-bucket" v-on:click="goToYearRoles">
              <div class="bucket-title" >
                <b-img :src="getImgUrl('nav-transition2.png')" /><span>{{$t('dashboard.set_next_roles')}}</span>
              </div>
              <div class="bucket-body">
                <div ><span style="color:#999;">{{yearRoles.current_year}}</span>  <span style="color:#666;margin-left:5px;">{{$t('dashboard.roles_assigned',{roles: yearRoles.r1, members: yearRoles.m1})}}</span></div>
                <div ><span style="color:#999;">{{yearRoles.next_year}}</span>  <span style="color:#666; margin-left:5px"><img v-if="yearRoles.r2==0" style="width:12px;height:10px;margin-right: 5px;" :src="getImgUrl('cross-red-HOME.png')" />{{yearRoles.r2>0? $t('dashboard.roles_assigned',{roles: yearRoles.r2, members: yearRoles.m2}) : $t('dashboard.no_roles_assigned')}}</span></div>
                <div style="color:#666;" v-if="yearRoles.transition_time==1">{{$t('dashboard.run_transition')}} <span style="color: #00aeef;">{{$t('dashboard.complete_transition')}}</span></div>
                <div style="color:#999;" v-if="yearRoles.transition_time==0">{{$t('dashboard.can_complete')+" "+$d(new Date(yearRoles.compare_date),'day_month_short')}}</div>
              </div>
            </div>

            <div v-masonry-tile class="dashboard-bucket" :class="{'hot-bucket' : (meetingNotes == null || meetingNotes.length == 0) }"  v-on:click="goToAgenda()">
              <div class="bucket-title" >
                <b-img :src="getImgUrl('nav-Speakers-Gray.png')" /><span>{{$t('dashboard.meeting_preparers')}}</span>
              </div>
              <div class="bucket-body" v-if="meetingNotes == null || meetingNotes.length == 0">
                <div><img style="width:12px;height:10px;margin-right: 5px;" :src="getImgUrl('cross-red-HOME.png')" /><span style="color:#999;">{{$t('dashboard.no_sections')}}</span></div>
              </div>
                  <div v-for="fg in meetingNotes" :key="fg.name" class="bucket-body  hot-bucket-body" v-on:click.stop="goToSectionFiles(fg)">
                    <div>
                        <span class="option-title" >{{fg.name}}</span> - <span style="color:#666;">{{namesToString(fg.names)}}</span>
                    </div>
                    <div v-if="fg.topic.length>0" ><span style="color:#999;font-size:14px;">{{$t("dashboard.topic_text")}}:</span> <span style="color:#666;">{{fg.topic}}</span></div>
                    <div v-if="fg.files.length==0" style="color:#999;font-size:14px;">{{$t("dashboard.none_uploaded")}}</div>
                    <div v-for="f in fg.files" :key="f.num" style="color:#999;font-size:14px;display: grid;grid-template-columns: max-content auto;grid-gap: 5px;">
                      <div v-if="f.link.length>0">{{f.type==1? $t("dashboard.file_text") : $t("dashboard.link_text")}}:</div> <div class="curtail-text option-description-link" v-on:click.stop="goToFileControl(f.hash,f.type, f.link)">{{f.type==2? f.link : f.file_path}}</div>
                    </div>
                    <hr style="margin-bottom: 0;" v-if="fg != meetingNotes[meetingNotes.length-1]"/>
                  </div>
                  <div class="bucket-footer">{{$t("dashboard.section_assign")}}</div>
            </div>
            <div v-masonry-tile v-if="rst" class="dashboard-bucket" :class="{'hot-bucket' : (meetingTopics == null || meetingTopics.length == 0) }"  v-on:click="goToTopics()">
              <div class="bucket-title" >
                <b-img :src="getImgUrl('nav-Topics-Gray.png')" /><span>{{$t('dashboard.topics_text')}}</span>
              </div>
              <div class="bucket-body" v-if="meetingTopics == null || meetingTopics.length == 0">
                <div><img style="width:12px;height:10px;margin-right: 5px;" :src="getImgUrl('cross-red-HOME.png')" /><span style="color:#999;">{{$t('dashboard.no_topics')}}</span></div>
              </div>
                  <div v-for="fg in meetingTopics" :key="fg.name" class="bucket-body  hot-bucket-body" v-on:click.stop="goToTopic(fg)">
                    <div>
                        <span class="option-title" >{{fg.name}}</span><span style="color:#666;" v-if="fg.names && fg.names.length>0"> - {{namesToString(fg.names)}}</span>
                    </div>
                    <div v-if="fg.files.length==0" style="color:#999;font-size:14px;">{{$t("dashboard.none_uploaded")}}</div>
                    <div v-for="f in fg.files" :key="f.num" style="color:#999;font-size:14px;display: grid;grid-template-columns: max-content auto;grid-gap: 5px;">
                      <div v-if="f.link.length>0">{{f.type==1? $t("dashboard.file_text") : $t("dashboard.link_text")}}:</div> <div class="curtail-text option-description-link" v-on:click.stop="goToFileControl(f.hash,f.type, f.url)">{{f.type==2? f.link : f.file_path}}</div>
                    </div>
                    <hr style="margin-bottom: 0;" v-if="fg != meetingTopics[meetingTopics.length-1]"/>
                  </div>
                  <div class="bucket-footer">{{$t("dashboard.topic_assign")}}</div>
            </div>

            <div v-masonry-tile class="dashboard-bucket" :class="{'hot-bucket' : (speakers == null || speakers.length == 0) }"  v-on:click="goToSpeakers()">
              <div class="bucket-title" >
              <b-img :src="getImgUrl('nav-Speakers-Gray.png')" /><span>{{isCorporateConnections? $t('dashboard.spotlight_speakers') : $t('dashboard.featured_speakers')}}</span>
            </div>
            <div class="bucket-body" v-if="speakers == null || speakers.length == 0">
                <div><img style="width:12px;height:10px;margin-right: 5px;" :src="getImgUrl('cross-red-HOME.png')" /><span style="color:#999;">{{isCorporateConnections? $t('dashboard.no_spotlight_speakers') : $t('dashboard.no_speakers')}}</span></div>
              </div>
              <div v-for="speaker in speakers" :key="speaker.num" v-on:click.stop="editEntry(speaker.id)" class="bucket-body  hot-bucket-body">
                <div class="option-title">{{speaker.name}}<span style="color:#666;font-style:italic;font-size:14px;"> - {{speaker.company}}</span></div>
                <div style="color:#666;font-size:13px;">{{speaker.category}}</div>
                <div style="display:grid; grid-template-columns: max-content max-content max-content max-content; column-gap:10px;color:#999;font-size:14px;">
                  <div :class="{'dash-checkmark': speaker.hasPresentation,'dash-redx': !speaker.hasPresentation}"></div>
                  <!--<div v-if="speaker.hasUrl" v-on:click.stop="goToFileControl(speaker.hash, speaker.type, speaker.url)" :class="{'option-description-link' : (speaker.hasUrl && speaker.type>0)}" style="padding-right:30px">{{$t('dashboard.presentation_text') }}</div>-->
                  <div  :class="{'option-description-link' : (speaker.hasUrl && speaker.type>0)}" style="padding-right:30px">{{$t('dashboard.presentation_text') }}</div>
                  <div :class="{'dash-checkmark': speaker.hasBio,'dash-redx': !speaker.hasBio}"></div>
                  <div>{{$t('dashboard.bio_text')}}</div>
                </div>
                <hr style="margin-bottom: 0;" v-if="speaker != speakers[speakers.length-1]"/>
              </div>
              <div v-if="official" class="bucket-footer">{{speakerManage.recipients? $t("dashboard.managed_by")+": "+namesToString(speakerManage.recipients) : $t("dashboard.assign_managers")}}</div>
            </div>

            <div v-masonry-tile class="dashboard-bucket  " :class="{'hot-bucket': !(next_meeting == null || next_meeting== '')}" v-on:click="goToAttendance">
              <div class="bucket-title" >
                <b-img :src="getImgUrl('nav-Attendance-Gray.png')" /><span>{{$t('portal.visitor_host')}}</span>
              </div>
              <div class="bucket-body" >
                  <div v-if="meetingAttendance.visitors && !(next_meeting == null || next_meeting== '')"><span style="color:#666;">{{$t('controller.registered_visitor')}}: {{ meetingAttendance.visitors.filter(v => v.present == 0).length}}</span>  <span style="color:#666;margin-left:20px;">{{$t('controller.present_visitor')}}: {{meetingAttendance.visitors.filter(v => v.present == 1).length}}</span></div>
                  <div v-if="(meetingAttendance.visitors==null || meetingAttendance.visitors.length==0) && !(next_meeting == null || next_meeting== '')"><img style="width:12px;height:10px;margin-right: 5px;" :src="getImgUrl('cross-red-HOME.png')" /><span style="color:#999;">{{$t('dashboard.no_visitors')}}</span></div>
                  <div v-if="next_meeting == null || next_meeting== ''"><img style="width:12px;height:10px;margin-right: 5px;" :src="getImgUrl('cross-red-HOME.png')" /><span style="color:#999;">{{$t('dashboard.no_meeting')}}</span></div>
              </div>
              <div v-if="official" class="bucket-footer">{{meetingAttendance.recipients? $t("dashboard.managed_by")+": "+namesToString(meetingAttendance.recipients) : $t("dashboard.assign_managers")}}</div>
            </div>
            
            <div v-masonry-tile v-if="official && !isCorporateConnections" class="dashboard-bucket  hot-bucket" v-on:click="goToRecognition()">
              <div class="bucket-title" >
                <b-img :src="getImgUrl('nav-Recognition-Gray.png')" /><span>{{$t('dashboard.recognition_text')}}</span>
              </div>
              <div class="bucket-body">
                <div v-if="recognition_leaders.length==0" style="color:#999;">{{$t('dashboard.no_network_leader')}}</div>
                <div v-if="recognition_leaders.length>0" style="color:#666;">{{$t('role.network_leaders')}}</div>
                <div v-for="m in recognition_leaders" :key="m.name" style="color:#666;">
                  &#8226; {{m.name}}
                </div>
              </div>
              <hr style="margin: 0;" />
              <div class="bucket-body" >
                <div v-if="recognition_renewing.length==0" style="color:#999;">{{$t('dashboard.no_renewing_members')}}</div>
                <div v-if="recognition_renewing.length>0" style="color:#666;">{{$t('role.renewing_members')}}</div>
                <div v-for="m in recognition_renewing" :key="m.name" style="color:#666;">
                  &#8226; {{m.name}}
                </div>
              </div>
              <div class="bucket-body performer-bucket"  :style="{'border-top': performers == recognition_performers[0]? '1px rgba(0,0,0,0.1) solid' : ''}"  v-for="performers in recognition_performers" :key="performers.id">
                <span style="color: black;">{{$t('dashboard.top_performer'+performers.id)}}</span> - {{performers.members.map(m => m.name).join(", ")}}
              </div>
            </div>
            
            <div v-if="!rst && !isCorporateConnections" v-masonry-tile class="dashboard-bucket  hot-bucket" v-on:click="goToCategories()">
              <div class="bucket-title" >
                <b-img :src="getImgUrl('nav-Categories-Gray.png')" /><span>{{$t('dashboard.open_categories')}}</span>
              </div>
              <div class="bucket-body" >
                <div style="color:#999;font-size:14px;"><img style="width:12px;height:10px;margin-right: 5px;" :src="getImgUrl(categories.num>0? 'checkmark-green-HOME.png' : 'cross-red-HOME.png')" />{{$tc("dashboard.categories_count",categories.num)}}</div>
              </div>
              <div v-if="official" class="bucket-footer">{{categories.recipients? $t("dashboard.managed_by")+": "+namesToString(categories.recipients) : $t("dashboard.assign_managers")}}</div>
            </div>

            <div v-masonry-tile v-if="official && goals.active_day && !isCorporateConnections" class="dashboard-bucket  hot-bucket"  v-on:click="goToGoals()">
              <div class="bucket-title" >
                <b-img :src="getImgUrl('nav-Goals-Gray.png')" /><span>{{$t('dashboard.goals_text')}}</span>
              </div>
              <div class="bucket-body" >
                <div><img v-if="yearRoles.r2==0" style="width:12px;height:10px;margin-right: 5px;" :src="getImgUrl('checkmark-green-HOME.png')" />{{$t('dashboard.active_goals')+ " "+ $d(new Date(goals.active_day),'day_month_short')}} </div>
              </div>
            </div>
            
            <div v-masonry-tile v-if="official && !isCorporateConnections" class="dashboard-bucket  hot-bucket" v-on:click="goToPerformance()">
              <div class="bucket-title" >
                <b-img :src="getImgUrl('nav-Performance-Gray.png')" /><span>{{$t('dashboard.performance_reporting')}}</span>
              </div>
              <div class="bucket-body" >
                <div> <img style="width:12px;height:10px;margin-right: 5px;" :src="getImgUrl(meetingPerformance.pdata>0? 'checkmark-green-HOME.png' : 'cross-red-HOME.png')" /><span style="color:  #999">{{$t(meetingPerformance.pdata>0 ? 'dashboard.data_updated' : 'dashboard.data_not_updated')}}</span></div>
              </div>
              <div v-if="official" class="bucket-footer">{{meetingPerformance.recipients? $t("dashboard.managed_by")+": "+namesToString(meetingPerformance.recipients) : $t("dashboard.assign_managers")}}</div>
            </div>
            
            <div v-masonry-tile v-if="official" class="dashboard-bucket  hot-bucket" v-on:click="goToEvents()" >
              <div class="bucket-title" >
              <b-img :src="getImgUrl('nav-Events-Gray.png')" /><span>{{$t('dashboard.events_text')}}</span>
            </div>
              <div class="bucket-body">
                <div style="color:#666;"><span>{{$t("dashboard.chapter_text")}}</span>: <img v-if="meetingEvents.chapter==0" style="width:12px;height:10px;" :src="getImgUrl('cross-red-HOME.png')" /><span v-if="meetingEvents.chapter>0">{{meetingEvents.chapter}}</span> <span style="margin-left:20px;">{{$t("dashboard.region_text")}}</span>: <img v-if="meetingEvents.region==0" style="width:12px;height:10px;" :src="getImgUrl('cross-red-HOME.png')" /><span v-if="meetingEvents.region>0">{{meetingEvents.region}}</span></div>
              </div>
              <div v-if="official" class="bucket-footer">{{meetingEvents.recipients? $t("dashboard.managed_by")+": "+namesToString(meetingEvents.recipients) : $t("dashboard.assign_managers")}}</div>
            </div>
            
            <div v-masonry-tile class="dashboard-bucket hot-bucket"  v-on:click="goToQuote()">
              <div class="bucket-title" >
              <b-img :src="getImgUrl('nav-Quotes-Gray.png')" /><span>{{$t('dashboard.closing_quote')}}</span>
            </div>
              <div class="bucket-body">
                <div ><img style="width:12px;height:10px;margin-right: 5px;" :src="getImgUrl(meetingQuote.hasContent==1 ? 'checkmark-green-HOME.png' : 'cross-red-HOME.png')" /><span style="color:  #999">{{$t(meetingQuote.hasContent==1 ? 'dashboard.quote_set' : 'dashboard.quote_not_set')}}</span> </div>
              </div>
              <div v-if="official" class="bucket-footer">{{quote_to_speakers==0?  (meetingQuote.recipients?$t("dashboard.managed_by")+": "+namesToString(meetingQuote.recipients) : $t("dashboard.assign_managers")) : $t("dashboard.managed_by_speakers")}}</div>
            </div>
            <div v-masonry-tile v-if="!isCorporateConnections && official && (permissions.organization.id == 3 || permissions.organization.allow_powerteams==1)" class="dashboard-bucket  hot-bucket"  v-on:click="goToPowerTeams()">
              <div class="bucket-title" >
                <b-img :src="getImgUrl('nav-PowerTeams-Gray.png')" /><span>{{$t('dashboard.powerteams_text')}}</span>
              </div>
              <div class="bucket-body" >
                <div v-if="powerteams && powerteams.teams && powerteams.teams.length>0" >
                  <div class="option-description-link" v-for="team in powerteams.teams" :key="team.id" style="display:grid; grid-template-columns: 70% 30%">
                    <div v-on:click.stop="goToPowerTeam(team.id)" >{{team.title}}</div>
                    <div v-on:click.stop="goToPowerTeam(team.id)" style="text-align:center;">{{team.members}}</div>
                  </div>
                </div>
                
                <div v-if="powerteams.teams == undefined || powerteams.teams.length==0">{{$t('dashboard.no_powerteams')}}</div>
                <br/>
                <div v-if="official" class="bucket-footer">{{ (powerteams.recipients?$t("dashboard.managed_by")+": "+namesToString(powerteams.recipients) : $t("dashboard.assign_managers"))}}</div>
              </div>
            </div>

            <div v-masonry-tile v-if="!official || isCorporateConnections" class="dashboard-bucket  hot-bucket" v-on:click="goToDates()" >
              <div class="bucket-title" >
              <b-img :src="getImgUrl('nav-Events-Gray.png')" /><span>{{$t('dashboard.dates_text')}}</span>
            </div>
              <div class="bucket-body">
                <div style="color:#666;"><span>{{$tc("dashboard.dates_defined", meetingDates.dates)}}</span></div>
              </div>
            </div>
            <div v-masonry-tile v-if="!official || isCorporateConnections" class="dashboard-bucket  hot-bucket" v-on:click="goToQuestions()" >
              <div class="bucket-title" >
              <b-img :src="getImgUrl('nav-Categories-Gray.png')" /><span>{{$t('dashboard.questions_text')}}</span>
            </div>
              <div class="bucket-body">
                <div style="color:#666;"><span>{{$tc("dashboard.questions_defined", meetingQuestions)}}</span></div>
              </div>
            </div>
          </div>
        </div>
        <div id="deliver-section" class="dashboard-section" style="grid-area: c;">
            <div class="dashboard-title" style="text-align:center;grid-area:a;">
              <span>{{$t('dashboard.deliver_meeting')}}</span>
            </div>
            <div class="portal-option" v-on:click="goToPresenter" style="margin:auto;grid-area:b;">
              <div class="option-title" >{{$t('portal.meeting_presenter')}}</div>
              <img  src="../assets/home-PresentationScreen2.png" />
              <div class="option-description" >{{$t('portal.presenter_description')}}</div>
            </div>
            <div class="portal-option" v-on:click="goToController" style="margin:auto;grid-area:c;">
                <div class="option-title" >{{$t('portal.meeting_controller')}}</div>
                <img  src="../assets/home-MeetingController2.png" />
                <div class="option-description" >{{$t('portal.controller_description')}}</div>
              </div>
            <!--<div  v-on:click="goToAttendance" class="portal-option" style="grid-column:1/3;margin:auto;">
              <div class="option-title" >{{$t('portal.visitor_host')}}</div>
              <img src="../assets/home-AttendanceConsole2.png" />
              <div class="option-description" >{{$t('portal.attendance_description')}}</div>
            </div>-->
            <div  class="portal-option" v-on:click="goToDemo" style="margin:auto;grid-area:d;">
              <div class="option-title" >{{$t('portal.test_meeting')}}</div>
              <img src="../assets/home-TestMeeting2.png"  />
              <div class="option-description" >{{$t('portal.demo_description')}}</div>
            </div>
            <div  class="portal-option" v-on:click="checks = []; showSystemCheck = true;" style="margin:auto;grid-area:e;">
              <div class="option-title" >{{$t('portal.system_check')}}</div>
              <img src="../assets/home-SystemCheck2.png"  />
              <div class="option-description" >{{$t('portal.system_description')}}</div>
            </div>
        </div>
      </div>
      <div id="powered-by-logo" class="fit-content">
          <img style="height:50px;" src="@/assets/PoweredByMeetingHUB.png" />
        </div>
      <b-modal id="slide-modal" centered v-model="showRoleEdit" :title="$t('dashboard.assign_roles')">
        <div style="height:600px;display:grid; overflow:auto; grid-template-columns: max-content auto;">
          <table class="table b-table table-bordered table-sm table-hover-new" style="border-collapse: separate;border:none;">
            <tbody>
              <tr>
                <td
                  style="background-color: #fff;z-index: 200;position: sticky;left: 0px;top: 0px;"
                ></td>
                <td
                  style="position:sticky; top:0;background:#fff; z-index:100;"
                  v-for="role in roles"
                  :key="role.id"
                >{{role.name}}</td>
              </tr>
              <tr v-for="member in members" :key="member.id">
                <td style="position:sticky; left:0;background:#fff; z-index:100;">{{member.name}}</td>
                <td v-for="role in roles" :key="role.id">
                  <b-form-checkbox v-model="member.roles" :value="role.id"></b-form-checkbox>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="submit-button4" v-on:click="showRoleEdit=false" variant="clear-blue">
            <div class="cancel-button"></div>
            <span>{{$t('global.button_cancel')}}</span>
          </b-button>
          <b-button class="submit-button4" v-on:click="updateRoles" variant="clear-blue">
            <div class="save-button"></div>
            <span>{{$t('global.button_save')}}</span>
          </b-button>
        </div>
      </b-modal>
      <b-modal id="slide-modal" centered v-model="showSystemCheck" :title="$t('dashboard.system_check')" @hidden="onHidden">
        <div style="overflow:auto; ">
          <div style="color:rgb(0, 174, 239);text-align:center;">{{$t('dashboard.checks_description')}}</div>
          <div style="border:1px solid #ccc;display:grid; grid-template-columns: auto max-content;margin:10px;border-radius:10px;">
            <div class="system-box">
              <img src="../assets/SysCheck-Connection.png" />
              <div>
                <div>{{$t('dashboard.connectivity_title')}}</div>
                <span>{{$t('dashboard.connectivity_descrip')}}</span>
              </div>
            </div>
            <div class="system-box">
              <img src="../assets/SysCheck-Media.png" />
              <div>
                <div>{{$t('dashboard.browser_title')}}</div>
                <span>{{$t('dashboard.browser_descrip')}}</span>
              </div>
            </div>
            <div class="system-box">
              <img src="../assets/SysCheck-Popups.png" />
              <div>
                <div>{{$t('dashboard.popup_title')}}</div>
                <span>{{$t('dashboard.popup_descrip')}}</span>
              </div>
            </div>
            <b-button v-if="checks.length==0" style="grid-column: 2/3; grid-row: 2/3;margin: auto 50px;" class="submit-button4" v-on:click="runChecks()" variant="clear-blue">
              <div class="test-button"></div>
              <span style="font-weight:bold;">{{$t('dashboard.test_all')}}</span>
            </b-button>
            <div v-for="check in checks" :key="check.row" class="system-box" :style="{'grid-column': '2/3', 'grid-row': check.row+'/'+(check.row+1), 'max-width' : '300px'}">
              <img :src="getImgUrl(check.image)" />
              <div>
                <div :style="{'color' : check.image=='cross-red.png'? 'red' : 'rgb(0, 60, 128)'}">{{check.title}}</div>
                <span>{{check.descrip}}</span>
              </div>
            </div>
          </div>
          <div style="border:1px solid #ccc;display:grid; grid-template-columns: auto max-content;margin:10px;border-radius:10px;">
            <div class="system-box">
              <img :src="soundPlaying? getImgUrl('SysCheck-Audio-animated.gif') : getImgUrl('SysCheck-Audio.png')" />
              <div>
                <div :style="{'color':soundPlaying? ' #00aeef' : ''}">{{soundPlaying? $t('dashboard.sound_title2') : $t('dashboard.sound_title')}}</div>
                <span>{{soundPlaying? $t('dashboard.sound_descrip2') : $t('dashboard.sound_descrip')}}</span>
              </div>
            </div>
            <b-button v-if="!soundPlaying" class="submit-button4" v-on:click="playSound" :disabled="soundPlaying" variant="clear-blue" style="margin: auto 50px;">
              <div class="test-button"></div>
              <span style="font-weight:bold;">{{$t('dashboard.button_test')}}</span>
            </b-button>
            <b-button v-if="soundPlaying" class="submit-button4" v-on:click.stop="stopSound" variant="clear-blue" style="margin: auto 50px;">
              <div class="cancel-button"></div>
              <span style="font-weight:bold;">{{$t('dashboard.button_stop')}}</span>
            </b-button>
          </div>
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="submit-button4" v-on:click="showSystemCheck = false;" variant="clear-blue">
            <div class="save-button"></div>
            <span>{{$t('global.button_ok')}}</span>
          </b-button>
        </div>
      </b-modal>
      <b-modal centered v-model="showRequestContent" :title="$t('dashboard.request_content_title')">
            <div style="text-align:center;">
              <div v-html="$t(members_without_photo>0? 'dashboard.request_content_text' : 'dashboard.request_content_text0', {members: members_without_photo})"></div>
            </div>
            <div slot="modal-footer" style="display:grid; grid-template-columns:auto auto;margin: auto;grid-gap:30px;" class="fit-content">
              <b-button v-if="members_without_photo==0" class="submit-button4" v-on:click="showRequestContent = false;" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
              <b-button v-if="members_without_photo>0" class="submit-button4" v-on:click="showRequestContent = false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
              <b-button v-if="members_without_photo>0" class="submit-button4" v-on:click="sendRequestContentEmails" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_confirm')}}</span></b-button>
            </div>
          </b-modal>
      <b-modal id="browser-warning" centered v-model="showBrowserWarning">
        <div slot="modal-header" style="font-size:30px;color:red;width:100%;">
          {{$t('dashboard.warning_text')}}
        </div>
        <div style="overflow:auto; text-align: center;" v-html="$t('dashboard.browser_warning')">
          
        </div>
        <div slot="modal-footer" class="submit-box-2">
           <b-button class="submit-button4" v-on:click="showBrowserWarning = false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span>
          </b-button>
          <b-button class="submit-button4" v-on:click="continueMeetingClick" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_continue')}}</span>
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<style scoped lang="scss">
#powered-by-logo{
  margin: 20px auto;padding-bottom: 20px;
  @if $VUE_APP_IS_CORPORATE==0 {
    display: none;
  }
}

.performer-bucket{
  color: #999;border-top: 1px rgba(0,0,0,0.1) dashed;
}
.bucket-footer{
  text-align: center;
    font-size: 11px;
    color: #ccc;
    font-style: italic;
}

  .system-box{
    grid-column: 1/2;
    display:grid;
    grid-template-columns: max-content auto;
    margin:10px;
  }
  .system-box > img{
    width: 40px;
    margin-right:10px;
    filter: $VUE_APP_COLOUR_MAIN_FILTER;
  }
  .system-box > div{
    display: grid;
    grid-template-rows: max-content max-content;
    text-align: left;
  }
  .system-box > div > div{
    font-size: 16px;
    font-weight: bold;
  }
  .system-box > div > span{
    font-size: 14px;
    color: #999;
  }
  .option-description-link{
    color:#00aeef;
    cursor:pointer;
  }
  .option-description-link:hover{
    text-decoration: underline;
  }
  .dash-checkmark{
    width: 14px !important;
    height: 12px !important;
    background-size: contain !important;
    background: url("../assets/checkmark-green-HOME.png") transparent no-repeat;
    margin: auto;
  }
  .dash-redx{
   width: 14px !important;
    height: 12px !important;
    background-size: contain !important;
    background: url("../assets/cross-red-HOME.png") transparent no-repeat;
    margin: auto;
  }
  .dashboard-object{
    text-align: left;
    border: #ccc solid 1px;
    border-radius: 10px;
    padding: 10px;
    width:100%;
    height: fit-content;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    cursor: pointer;
  }
  .dashboard-object:hover{
    background-color: #e0f5fd;
  }
  #dashboard-sections-setup{
    display:grid;
    grid-gap: 20px;
    grid-template-areas: "a b c";
    grid-template-columns: max-content auto max-content;
    margin: 0 auto;
    width: fit-content;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: 100%; max-width:1500px;
  }

  #dashboard-sections{
    display:grid;
    grid-gap: 20px;
    grid-template-areas: "b c";
    grid-template-columns: auto max-content;
    margin: 0 auto;
    width: fit-content;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: 100%; max-width:1500px;
  }


  .dashboard-section{
    width:100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap:20px;
    height: fit-content;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    padding: 10px;
    row-gap: 15px;
  }
  .dashboard-title{
    border: 1px #f2f2f2 solid;
    color: #7f7f7f;
    border-radius: 2px;
    font-size: 19px;
    background-color: #fafafa;
    padding: 5px;
    margin-top: 15px;
    line-height: 19px;
    display:grid;
    grid-template-columns: max-content auto;
  }
  .dashboard-title > img{
    height: 19px;
  }
  .dashboard-title > span{
    margin-left: 3px;
  }
    .option-title{
      color:$VUE_APP_COLOUR_DASH_TEXT;
      /*font-size:18px;
      font-weight: bold;*/
    }
    .option-description{
      color:#999;
      font-size:14px;
      text-align: left;
    }

    .dashboard-bucket{
      text-align: left;
      border: #ccc solid 1px;
      border-radius: 10px;
      width:320px;
      height: fit-content;
      height: -moz-fit-content;
      height: -webkit-fit-content;
      margin-bottom:15px;
    }

    .bucket-title{
      color: $VUE_APP_COLOUR_DASH_TEXT;
      font-size: 18px;
      padding: 5px;
      background-color: #fafafa;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      border-bottom: 1px solid #ccc;
    }

    .bucket-title >img{
      margin-right: 5px;
      height:20px;
    }

    .bucket-body{
      color: rgb(170, 170, 170);
      font-size: 14px;
      padding: 10px 30px;
      
      
    }

    .hot-bucket-body{
      cursor: pointer;
    }

    .hot-bucket-body:hover{
      background-color: #e0f5fd;
    }

    .hot-bucket{
      cursor: pointer;
    }

    .hot-bucket:hover{
      background-color: #e0f5fd;
    }
    
    .portal-option{
      text-align: center;
      border: #ccc solid 1px;
      border-radius: 10px;
      padding: 10px;
      max-width: 200px;
      height: fit-content;
      height: -moz-fit-content;
      height: -webkit-fit-content;
      cursor: pointer;
    }
    .portal-option>img{
      max-height: 50px;
      margin:auto;
      padding: 3px;
      
    filter: $VUE_APP_COLOUR_MAIN_FILTER;
    }
    .portal-option:hover{
      /*background-color: #d3e3f0;*/
      background-color: #e0f5fd;
    }
  
  #meeting-box-right {
    font-size: 16px;
    padding: 5%;
    overflow: auto;
    display: grid;
  }
  .speaker-description {
    display: grid;
    grid-template-columns: 60px auto;
    font-size: 16px;
  }
  .speaker-number {
    text-align: center;
    font-size: 20px;
  }
  #speaker-icon {
    display: grid;
    grid-template-columns: max-content max-content;
    margin: auto;
    width: fit-content;
  }
  #speaker-icon > img {
    margin: auto;
    margin-right: 10px;
  }
  #speaker-icon > span {
    margin: auto;
    color: #999;
  }
  #meeting-box-middle {
    padding: 0 5%;
    margin: 5% 0;
    overflow: auto;
    border-right: 1px #999 solid;
  }
  .note-divider {
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 0px;
  }
  #meeting-box-left {
    overflow: auto;
    padding: 5%;
  }
  #meeting-logo {
    display: grid;
    grid-template-columns: max-content max-content;
    margin: auto;
    width: fit-content;
  }
  #meeting-logo > img {
    margin: auto;
    margin-right: 10px;
  }
  #meeting-logo > span {
    margin: auto;
    color: #999;
  }
  #meeting-box {
    height: 100%;
  }
  .meeting-descript {
    font-size: 30px;
    color:$VUE_APP_CHAPTER_NAME;margin: 0 50px;font-size:26px;
  }
  #meeting-description {
    background-color: #fafafa;
    min-width: 200px;
    grid-template-columns: 1fr;
    text-align: center;
    grid-template-rows: repeat(4, max-content);
    height: fit-content;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    margin-top: 5%;
    border: 1px #f2f2f2 solid;
    border-radius: 2px;
  }


  #dash {
    height: calc(100% - 84px);
    position: relative;
    bottom: 0px;
    top: 0px;
    right: 0px;
    left: 0px;
    display: block;
  }
  .speaker-link {
    color: #00aeef;
        vertical-align: unset;
        font-weight: bold;
  }

  #deliver-section{
      grid-template-areas:"a a" "b c" "d d" "e e";
    }

  
  @media (max-width: 1090px) {
    #deliver-section{
      grid-template-areas:"a" "b" "c" "d" "e";
    }
    #dashboard-sections-setup  {
        grid-template-areas: "a c" "b b" ;
        grid-template-columns: 340px;
        width: fit-content;
    }
  }
  @media (max-width: 755px) {
   #dashboard-sections-setup  {
        grid-template-areas: "a" "c" "b" ;
        grid-template-columns: 340px;
        width: fit-content;
    }
    #dashboard-sections{
        grid-template-areas: "c" "b" ;
        grid-template-columns: 340px;
        width: fit-content;
    }
  }
</style>
  <script>
import router from "../router";
import sound1 from "../assets/sounds/ding-sound.mp3"
export default {
  name: "Dashboard",
  watch: {
    '$i18n.locale': { 
      handler: function () {
          if (!this.audio_object.paused && !this.audio_object.paused) {
            this.audioText= this.$t('portal.playing_chime');
          } else {
            this.audioText= this.$t('portal.test_audio');
          }
          var self = this;
          this.$nextTick(function(){
              self.$nextTick(function(){
                self.$redrawVueMasonry()
              });
            });
      }
    },
    'permissions.organization.onboard': { 
      handler: function (v) {
         this.onboard = v;
      }
    },
  },
  data() {
    return {
      containerId: null,
      joint_organization: "",
      onboard: 0,
      official: true,
      rst: false,
      quote_to_speakers: 0,
      showRequestContent: false,
      hour_diff: 100,
      min_diff: 100,
      showBrowserWarning: false,
      soundPlaying:false,
      showSystemCheck: false,
      pages: [],
      speakers: [],
      next_meeting: "",
      orgid: 0,
      showAlert: false,
      showSuccess: false,
      alertText: "",
      successText: "",
      next_meeting_date: null,
      audio_object: null,
      audio_timeout: null,
      
      audioText: this.$t("portal.test_audio"),
      meet_start: "",
      sections: [],
      members: [],
      roles: [],
      showRoleEdit: false,
      meetingNotes: [],
      meetingTopics: [],
      meetingQuote: {},
      meetingEvents: {},
       meetingAttendance: {},
       meetingBios: {},
       meetingPerformance: {},
       powerteams: {},
       recognition_renewing: [],
       recognition_leaders: [],
       recognition_performers: [],
       goals: {},
       speakerManage: {},
       categories: 0,
       checks: [],
       week_data: {week: 0, total_weeks: 0},
       yearRoles: {n1: 0,n2: 0, show: 0},
       debug_mode: false,
       continueMeetingCallback: null,
       meetingDates: {dates: 0},
       meetingQuestions: 0,
       meetingApplicants: {min: 0, num: 0},
       following_meeint: "",
       members_without_photo: 0
    };
  },
  methods: {
    
     hideSetup(){
        this.$http.post("/api/organization/onboard/update", {onboard: 0}).then((response) => {
          if (response.data.success) {
            this.permissions.organization.onboard = 0;
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
    goToSetupPage(page, anchor, popup){
      this.permissions.setup_popup = popup;
      router.push({path:page, hash: anchor});
    },
    goToSetupDemo(){
      this.permissions.setup_popup = 7;
      this.checkBrowerWarning("/#/demo/1");
    },
    namesToString(managersString){
      if(managersString){
        var managers = managersString.split(", ");
        var names = [];
        for(var i=0; i<managers.length; i++){
          var sp = managers[i].split(" ");
          if(sp.length==1){
            names.push(sp[0]);
          }else{
            names.push(sp[0]+" "+sp[sp.length-1][0]+".");
          }
        }
        return names.join(", ");
      }
      return "";
    },
    continueMeetingClick(){
      this.showBrowserWarning = false;
      if(this.continueMeetingCallback != null){
        this.continueMeetingCallback();
      }
    },
    testPowerPoint(){
      window.open("https://garoca-my.sharepoint.com/:p:/g/personal/meetinghub_garo_ca/EdVJ0WVk22ZIsKgK01iLvP4Bl_P1I4awWWh_SoFjAHgT8A?action=embedview");
    },
    goToController() {
      this.checkBrowerWarning("#/controller/view");
    },
    goToPresenter() {
      this.checkBrowerWarning("/#/meeting");
    },
    goToAttendance() {
      if(this.next_meeting == null || this.next_meeting== ''){
        return;
      }
      var w= window.open("/#/controller/attendance", "_blank", 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
      this.$root.$emit('newPage', w);
    },
    goToDemo() {
       this.checkBrowerWarning("/#/demo");
    },
    /*goToTestDemo(){
      var w= window.open("/#/demo/1", "_blank", 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
      this.$root.$emit('newPage', w);
    },*/
   
    goToFileControl(hash, type, url){
      if(type == 2){
        url = (url.startsWith("http") ? "" : "http://") + url;
        var v= window.open(url, "_blank", 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
      this.$root.$emit('newPage', v);
      }else if (type == 1){
        var w= window.open("/#/filecontrol/"+hash, "_blank", 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
      this.$root.$emit('newPage', w);
      }
    },
    goToYearRoles(){
      router.push("/admin/yearroles");
    },
    goToCategories(){
      router.push("/admin/categories");
    },
    goToQuote(){
      router.push("/admin/quotes");
    },
    goToEvents(){
      router.push("/admin/events");
    },
    goToRecognition(){
      router.push("/admin/recognition");
    },
    goToSpeakers(){
      if((this.speakers == null || this.speakers.length == 0)){
        router.push("/admin/schedule");
      }
    },
    goToAgenda(){
      if((this.meetingNotes == null || this.meetingNotes == 0)){
        router.push("/admin/agenda");
      }
    },
    goToTopics(){
      if((this.meetingTopics == null || this.meetingTopics.length == 0)){
        router.push("/admin/topics");
      }
    },
    goToTopic(topic){
      router.push("/admin/topics/"+topic.id);
    },
    getToSettings(){
      router.push("/admin/general");
    },
    goToSectionFiles(section) {
        if (section.preparer_hash) {
          router.push("/admin/prepare/" + section.preparer_hash);
        }
      },
      getRecognition() {
        this.$http.post("/api/organization/recognition", {}).then((response) => {
          if (response.data.success) {
            this.recognition_renewing = response.data.renewing_members;
            this.recognition_leaders = response.data.network_leaders;
          }
        }).catch(() => {
        });
      },
      sendRequestContentEmails(){
        
        this.$http.post("/api/member/photo/request/email/byorganization", {}).then((response) => {
          this.showRequestContent = false;
          if (response.data.success) {
            this.$bvToast.toast(this.$t('dashboard.emails_sent_text'), {
                  title: this.$t('dashboard.emails_sent'),
                  variant: 'success',
                  solid: true
                });
          }else{
            this.$bvToast.toast(this.$t('dashboard.no_request_emails'), {
                  variant: 'warning',
                  solid: true
                });
          }
        }).catch(() => {
          this.showRequestContent = false;
        });
      },
      showSendRequestContentEmails(){
        this.members_without_photo = 0;
        this.$http.post("/api/organization/members/withoutphoto", {}).then((response) => {
          if(response.data.success){
            this.members_without_photo = response.data.members;
          }
          this.showRequestContent = true;
        }).catch(() => {
          this.showRequestContent = true;
        });
      },
      goToGoals(){
        router.push("/admin/goals");
      },
      goToPerformance(){
        router.push("/admin/performance");
      },
      goToDates(){
        router.push("/admin/meetingdates");
      },
      goToQuestions(){
        router.push("/admin/questions");
      },
      goToMembers(){
        router.push("/admin/members");
      },
      goToPowerTeams(){
        router.push("/admin/powerteams");
      },
      goToPowerTeam(id){
        router.push("/admin/powerteams/"+id);
      },
    getMeetingNotes() {
      this.$http.post("/api/portal/meeting/notes", {}).then(response => {
          if (response.data.success) {
            
            for (var i = 0; i < response.data.speakers.length; i++) {
              response.data.speakers[i].hasBio = response.data.speakers[i].bio && response.data.speakers[i].bio.length > 10;
              response.data.speakers[i].hasUrl = response.data.speakers[i].type == 0 ||
                (response.data.speakers[i].type > 0 && response.data.speakers[i].url && response.data.speakers[i].url.length > 0);
                response.data.speakers[i].hasPresentation = response.data.speakers[i].presentation_count>0
            }
            this.joint_organization = response.data.organization.joint_organization;
            this.recognition_renewing = response.data.renewing_members;
            this.recognition_leaders = response.data.network_leaders;
            this.recognition_performers = response.data.performers;
            this.speakers = response.data.speakers;
            this.meetingAttendance = response.data.attendance ? response.data.attendance : {};
            this.meetingQuote = response.data.quote ? response.data.quote : {};
            this.meetingEvents = response.data.events ? response.data.events : {};
            this.meetingBios=response.data.emailbios? response.data.emailbios : {};
            this.meetingPerformance = response.data.emailperformance? response.data.emailperformance : {};
            this.powerteams = response.data.powerteams? response.data.powerteams : {};
            this.categories = response.data.categories;
            this.yearRoles = response.data.yearRoles;
            this.goals = response.data.goals;
            this.speakerManage = response.data.speakerManage;
            this.debug_mode = response.data.debug_mode==1;
            this.meetingDates = response.data.dates? {dates: response.data.dates} : {dates: 0};
            this.meetingQuestions = response.data.questions;
            this.meetingApplicants = {min: response.data.organization.min_applicants, num: response.data.organization.applicants};
            this.meetingNotes = response.data.sections;
            this.meetingTopics = response.data.topics;
            this.quote_to_speakers = response.data.organization.quote_to_speakers;
            this.official = response.data.organization.official==1;
            this.rst = response.data.organization.official==2;
            this.onboard = response.data.organization.onboard;
            var self = this;
            this.$nextTick(function(){
              self.$nextTick(function(){
                self.$redrawVueMasonry()
              });
            });
          
           
          } else {
            this.meetingNotes = [];
          }
        })
        .catch(errors => {
          console.log(errors);
        });
    },
    changeRoles() {
      this.getMembers();
      this.getRoles();
      this.showRoleEdit = true;
    },
    getRoles() {
      this.$http
        .post("/api/organization/roles/select", {})
        .then(response => {
          if (response.data.success) {
            this.roles = response.data.roles;
          }
        })
        .catch(() => {});
    },
    getMembers() {
      this.$http
        .post("/api/organization/members/roles/select", {})
        .then(response => {
          if (response.data.success) {
            this.members = response.data.members;
          }
        })
        .catch(() => {});
    },
    updateRoles() {
      this.$http
        .post("/api/members/update/roles", { members: this.members })
        .then(response => {
          if (response.data.success) {
            this.showRoleEdit = false;
          }
        })
        .catch(() => {});
    },
    runChecks(){
      this.checks = [];

      var i = 0;
      var self = this;
      this.getConnectivity(function(){
        i++;
        if(i==1){
          self.checks.push({title: self.$t('dashboard.complete_connectivity_title1'), descrip: self.$t('dashboard.complete_connectivity_descrip1')+" ("+i+"/5)", image: 'checkmark-orange.png', row: 1});
        }else if(i==5){
          for(var g=0; g<self.checks.length;g++){
            if(self.checks[g].title == self.$t('dashboard.complete_connectivity_title1')){
              self.checks[g].title = self.$t('dashboard.complete_connectivity_title2');
              self.checks[g].descrip = self.$t('dashboard.complete_connectivity_descrip2');
              self.checks[g].image = 'checkmark-green.png';
            }
          }
        }else{
           for(var h=0; h<self.checks.length;h++){
            if(self.checks[h].title == self.$t('dashboard.complete_connectivity_title1')){
              self.checks[h].descrip = self.$t('dashboard.complete_connectivity_descrip1')+" ("+i+"/5)";
            }
          }
        }
      });

      setTimeout(function(){
        if(i<5){
          var updated = false;
          for(var g=0; g<self.checks.length;g++){
            if(self.checks[g].title == self.$t('dashboard.complete_connectivity_title1')){
              self.checks[g].title = self.$t('dashboard.complete_connectivity_title3');
              self.checks[g].descrip = self.$t('dashboard.complete_connectivity_descrip3');
              self.checks[g].image = 'cross-red.png';
              updated = true;
            }
          }
          if(!updated){
            self.checks.push({title: self.$t('dashboard.complete_connectivity_title3'), descrip: self.$t('dashboard.complete_connectivity_descrip3'), image: 'cross-red.png', row: 3});
          }
        }
      },3000);

      var browser = this.getBrowser();
      var browser_title = browser.chrome? this.$t('dashboard.complete_browser_title1') : (browser.firefox? this.$t('dashboard.complete_browser_title2') :this.$t('dashboard.complete_browser_title3'));
      var browser_descrip = browser.chrome? this.$t('dashboard.complete_browser_descrip1') : (browser.firefox? this.$t('dashboard.complete_browser_descrip2') :this.$t('dashboard.complete_browser_descrip3'));
      this.checks.push({title: browser_title, descrip: browser_descrip, image: browser.chrome? 'checkmark-green.png' : (browser.firefox? 'checkmark-orange.png' : 'cross-red.png'), row: 2});


      var popup = !this.getPopupBlocked();
      this.checks.push({title: popup? this.$t('dashboard.complete_popup_title1') : this.$t('dashboard.complete_popup_title2'), descrip: popup? this.$t('dashboard.complete_popup_descrip1') : this.$t('dashboard.complete_popup_descrip2'), image: popup? 'checkmark-green.png' : 'cross-red.png', row: 3});
    },  
    onHidden(){
     this.stopSound();
    },
    stopSound(){
      this.soundPlaying = false;
      while(!this.audio_object.paused){
        this.audio_object.pause();
      }
        
    },
    playSound() {
      if (this.audio_object) {
        if (this.soundPlaying) {
          this.soundPlaying = false;
          this.audio_object.pause();
          this.audioText = this.$t("portal.test_audio");
        } else {
          this.audio_object.play();
          this.audioText = this.$t("portal.playing_chime");
          this.soundPlaying = true;
        }
      }
    },
    getConnectivity(callback){
      var i = 0;
      var socket = this.$io({transports: ['websocket'], upgrade: false});
      socket.on("test_return", (data) => {
        i++;
        if(data.success){
          callback();
        }
        if(i<5){
          socket.emit("test", { });
        }
      });
      socket.emit("test", { });
    },
    getBrowser(){
      /*var browser = (function (agent) {
        switch (true) {
            case agent.indexOf("edge") > -1: return "MS Edge (EdgeHtml)";
            case agent.indexOf("edg") > -1: return "MS Edge Chromium";
            case agent.indexOf("opr") > -1 && !!window.opr: return "opera";
            case agent.indexOf("chrome") > -1 && !!window.chrome: return "chrome";
            case agent.indexOf("trident") > -1: return "Internet Explorer";
            case agent.indexOf("firefox") > -1: return "firefox";
            case agent.indexOf("safari") > -1: return "safari";
            default: return "other";
        }
    })(window.navigator.userAgent.toLowerCase());
      // Firefox 1.0+
      //var isFirefox = (typeof InstallTrigger !== 'undefined') || (!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime));
      // Chrome 1 - 79
      //var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime) && navigator.userAgent.indexOf("Edg") == -1;
      var isFirefox = (browser == "firefox");
      var isChrome = (browser == "chrome");*/
      return {chrome: this.$browserDetect.isChrome, firefox: this.$browserDetect.isFirefox};
    },
    getPopupBlocked(){
      var newWin = window.open("https://www.google.com", "_blank",'');             
      if(!newWin || newWin.closed || typeof newWin.closed=='undefined') 
      { 
          return true;
      }
      newWin.close();
      return false;
    },
    checkBrowerWarning(l){
      var browser = this.getBrowser();
      if(browser.chrome || !this.permissions.warn_browser){
        var w= window.open(l, "_blank", 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
        this.$root.$emit('newPage', w);
        return true;
      }else{
        this.showBrowserWarning = true;
        this.permissions.warn_browser = false;
        this.continueMeetingCallback = function(){
          var w= window.open(l, "_blank", 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
          this.$root.$emit('newPage', w);
        }
        return false;
      }
    },
    getDates() {
      this.$http
        .post("/api/organization/dash/info", {})
        .then(response => {
          if (response.data.success === true) {
            this.next_meeting = response.data.next_meeting? response.data.next_meeting : '';
            this.orgid = response.data.orgId;
            //this.getSpeakers(this.next_meeting);
            this.next_meeting_date =this.next_meeting.length > 0? new Date("" + this.next_meeting.split("-").join("/") + " 00:01"): "";
            this.meet_start = response.data.meet_start;
            this.week_data.week = response.data.week;
            this.week_data.total_weeks = response.data.total_weeks;
            this.following_meeting = response.data.following_meeting;

            var td = response.data.time_diff? response.data.time_diff.split(":") : [];
            if(td.length>1){
              this.hour_diff = parseInt(td[0])+Math.round(parseInt(td[1])/30)/2;
              this.min_diff = parseInt(td[1]);
            }
            

          }
        })
        .catch((error) => {console.log(error)});
      
    },
    getSpeakers(d) {
      this.$http
        .post("/api/organization/speakers/day", { date: d,  org: this.orgid, getImages: false }) .then(response => {
          if (response.data.success) {
            for (var i = 0; i < response.data.speakers.length; i++) {
              response.data.speakers[i].hasBio = response.data.speakers[i].bio && response.data.speakers[i].bio.length > 10;
              response.data.speakers[i].hasUrl = response.data.speakers[i].type == 0 ||
                (response.data.speakers[i].type > 0 && response.data.speakers[i].url && response.data.speakers[i].url.length > 0);
            }
            this.speakers = response.data.speakers;
            /*for (var j = 0; j < this.speakers.length; j++) {
              this.$http.post("/api/speaker/get/photo", {id: this.speakers[j].id, num: j}).then(response => {
                  if (response.data.success === true) {
                    this.$set(this.speakers[response.data.num],"photo",response.data.photo);
                  }
                }).catch(() => {});
            }*/
          } else {
            this.speakers = [];
          }
        })
        .catch(errors => {
          console.log(errors);
        });
    },
    editEntry(id) {
      if (this.permissions.access) {
        router.push("/admin/schedule/" + id);
      }
    },
  },
  destroyed() {
    this.removeClass(document.getElementById("menu-home"), "active-menu");
    this.$root.$emit('allowOptions', false);
  },
  mounted() {
    this.addClass(document.getElementById("menu-home"), "active-menu");
    
    this.audio_object = document.createElement("audio");
    this.audio_object.src = sound1;
    var self = this;
    this.audio_object.addEventListener("ended", function() {
      self.audio_timeout = setTimeout(function() {
        if(self.soundPlaying){
          self.audio_object.play();
        }
        
      }, 500);
    });
    
    this.getMeetingNotes();
    this.getDates();
    //this.getRecognition();
    this.$root.$emit('allowOptions', true);
  }
};
</script>
