<template>
  <div>
    <Header v-if="member.name.length>0" :useruid="this.$route.params.useruid" :title="$t('memberphoto.manage_profile')" :showDate="false"></Header>
    <div  style="top: 40px;position: relative;margin: auto;">
      <div id="top-div"></div>
      <div style="grid-template-columns: auto;max-width:900px;margin: auto;">
        <div style="background-color: #eeeeee;padding: 10px; margin: 20px 0px;">
          <div class="fit-width" style="display: grid;grid-template-columns: max-content max-content;margin: auto;">
            <span class="form-box-name" style="margin: 0px 5px;">{{member.isContact==0? $t('memberphoto.member_name') : $t('memberphoto.contact_name')}}:</span>
            <b>{{member.name}}</b>
            <span class="form-box-name" style="margin: 0px 5px;">{{member.isContact==0? $t('memberphoto.member_category') : $t('memberphoto.contact_role')}}:</span>
            <b>{{member.isContact==0? member.category : (member.contact_types? member.contact_types.join(", ") : "")}}</b>
          </div>
        </div>
        <div v-if="memberReturned && needPin">
          <div style="text-align: center;color:#00aeef;" v-html="$t('memberphoto.verification_sent', {email: member.verification_email})"></div>
          <div style="display:grid; grid-template-columns: max-content max-content;margin:10px auto;" class="fit-width">
            <b-form-input v-model="pin" style="max-width: 300px;"></b-form-input>
            <b-button class="submit-button4" variant="blue" v-on:click="getMemberPhoto"><b>{{$t('global.button_submit')}}</b></b-button>
          </div>
          <b-alert variant="danger" class="fit-width" style="margin:auto;" dismissible :show="showVerificationAlert" @dismissed="showVerificationAlert=false">
                {{verificationAlertText}}
          </b-alert>
          
        </div>
        <form v-if="memberReturned && !needPin" v-on:submit.prevent="updateMember">
            <div style="text-align:center;color: rgb(0, 174, 239);">{{member.isContact==0? $t('memberphoto.instructions1') : $t('memberphoto.instructions1c')}}</div>
            <div style="display:grid; grid-template-columns: 60% 40%;">
              <div>
              <span class="form-box-title">{{$t('member.contact_info')}}</span>
              <div class="form-box" style="grid-template-columns: max-content auto;height: calc(100% - 50px);grid-template-rows: repeat(4,max-content);">
                <span v-if="use_taglines && member.isContact==0" class="form-box-name">{{$t('memberphoto.tag_line')}}</span>
                <div v-if="use_taglines && member.isContact==0" style="display:grid; grid-template-columns: auto;grid-column: 2/3;">
                    <b-form-textarea id="request-box" v-model="member.tagline" :rows="2" :maxlength="tagline_chars"  />
                    <div class="page-note" :style="{'color': cc==tagline_chars? 'red' : ''}">{{tag_count}}</div>
                </div>
                <span class="form-box-name">{{$t('member.phone_text')}}</span>
                <b-form-input v-model="member.phone" type="tel" style="max-width: 300px;" ></b-form-input>
                <span class="form-box-name" v-if="isCorporateConnections">{{$t('member.mobile_phone')}}</span>
                <b-form-input v-if="isCorporateConnections" v-model="member.mobile_phone" type="tel" style="max-width: 300px;" ></b-form-input>
                <span class="form-box-name" style="grid-column:1/2;">{{$t('member.email_text')}}</span><b-form-input v-model="member.email" type="email"  name="whateveremail" autocomplete="new-email"></b-form-input>
                <span class="form-box-name" style="grid-column:1/2;">{{$t('member.phone_text')}}</span><b-form-input v-model="member.phone" style="max-width: 300px;"  type="tel"></b-form-input>
                <span class="form-box-name" v-if="isCorporateConnections">{{$t('member.mobile_phone')}}</span><b-form-input v-if="isCorporateConnections" v-model="member.mobile_phone" type="tel" style="max-width: 300px;"></b-form-input>
                <span class="form-box-name" style="grid-column:1/2;">{{$t('member.category_text')}}</span><b-form-input v-model="member.category" type="text"></b-form-input>
                <span class="form-box-name" style="grid-column:1/2;">{{$t('member.company_text')}}</span><b-form-input v-model="member.company" type="text"></b-form-input>
                <span style="grid-column:1" class="form-box-name" v-if="isCorporateConnections">{{$t('member.preferred_contact')}}</span>
                <b-form-select v-if="isCorporateConnections" style="max-width: 300px;"  v-model="member.preferred_contact" :options="contactMethods"/>
                <span class="form-box-name" style="grid-column:1/2;">{{$t('member.website_text')}}</span><b-form-input v-model="member.website" type="text" ></b-form-input>
                <span v-if="isCorporateConnections" class="form-box-name" style="grid-column: 1/2;">{{$t('member.member_introduction')}}</span>
                <b-form-textarea v-if="isCorporateConnections" v-model="member.introduction" :rows="2" maxlength="255"/>
              </div>
              </div>
              <div>
                <span class="form-box-title">{{$t('memberphoto.email_subscriptions')}}</span>
                <div class="form-box" style="grid-template-columns: auto;height: calc(100% - 50px);grid-template-rows: repeat(6,max-content);">
                  <div v-for="row in rows" :key="row.service" style="display: grid;grid-template-columns: max-content auto;grid-gap: 10px;">
                    <span :class="{'dash-checkmark': row.subscribed==1,'dash-redx': row.subscribed==0}"></span><span>{{$t('memberphoto.subscribe_email'+row.service)}}</span>
                  </div>
                  <b-button class="submit-button4" v-on:click="showUnsubscribe=true;" variant="blue" style="margin: auto;margin-top:10px;"><span>{{$t('memberphoto.button_manage')}}</span></b-button>
                </div>
              </div>
              <b-modal id="slide-modal" centered  v-model="showUnsubscribe" :title="$t('memberphoto.manage_subscription')">
                <div >
                  <div class="submit-box-2">
                  <b-button class="submit-button4" v-on:click="unsubscribed = unsubValue;recalculateSubscriptions();" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_none')}}</span></b-button>
                  <b-button class="submit-button4" v-on:click="unsubscribed = 0;recalculateSubscriptions();" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_all')}}</span></b-button>
                </div>
                <b-table :items="rows" :fields="fields" small style="max-width: 600px;margin: auto;">
                    <template v-slot:cell(service)="data">
                        {{$t('memberphoto.subscribe_email'+data.item.service)}}
                    </template>
                    <template v-slot:cell(subscribed)="data">
                        <b-form-checkbox v-model="data.item.subscribed" value="1" unchecked-value="0" style="text-align:center;">
                        </b-form-checkbox>
                    </template>
                </b-table>
              </div>
                <div slot="modal-footer" class="submit-box-2" >
                  <b-button class="submit-button4" v-on:click="showUnsubscribe=false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
                  <b-button class="submit-button4" v-on:click="showUnsubscribe=false;setUnsubscribed();" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
                </div>
          </b-modal>
          <b-modal id="modal-center" v-model="showDelete" centered :title="$t('member.delete_title')">
            <div>
              <p class="my-4">{{$t('memberphoto.delete_text')}}</p>
              <div style="display:grid;grid-template-columns: repeat(4,40px);margin:auto;grid-gap:10px" class="fit-width">
                <b-form-input v-model="deletePin1" type="text" maxlength="1" style="text-align: center;"></b-form-input>
                <b-form-input v-model="deletePin2" type="text" maxlength="1" style="text-align: center;"></b-form-input>
                <b-form-input v-model="deletePin3" type="text" maxlength="1" style="text-align: center;"></b-form-input>
                <b-form-input v-model="deletePin4" type="text" maxlength="1" style="text-align: center;"></b-form-input>
              </div>
              <b-alert variant="danger" class="fit-width" style="margin:auto;margin-top: 10px;" dismissible :show="showDeleteVerificationAlert" @dismissed="showDeleteVerificationAlert=false">
                {{verificationAlertText}}
              </b-alert>
            </div>
            <div slot="modal-footer" class="submit-box-2">
              <b-button class="submit-button4" v-on:click="showDelete=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
              <b-button class="submit-button4" v-on:click="deleteMember" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_confirm')}}</span></b-button>
            </div>
          </b-modal>
            </div>
            <div v-if="member.applicant_photos==1 || member.isContact==1" :style="{'display': 'grid', 'grid-template-columns': ($mq!=='mobile' && member.isContact == 0? '50% 50%' : '')}">
              <div style="padding: 0px 20px;">
                <span class="form-box-title">{{$t('memberphoto.photo_text')}}</span>
                <div class="form-box" style="grid-template-columns: auto;grid-template-rows: 250px auto;">
                  <div v-show="!member.photo"></div>
                  <img v-show="member.photo" style="margin: auto;height: 242px;" :src="member.photo" />
                  <b-button class="submit-button4" style="margin: 15px auto;" v-on:click="openPhotoEdit" variant="blue">{{$t('memberphoto.set_photo')}}</b-button>
                  <b-form-file id='fileid' accept="image/jpeg, image/png, image/gif" @change="setImage" plain hidden></b-form-file>
                  <b-modal centered  v-model="showPhotoEdit" :title="$t('memberphoto.set_photo')">
                    <div style="text-align:center;color: rgb(0, 174, 239);">{{$t('memberphoto.upload_instructions')}}</div>
                    <div :style="{'display': 'grid', 'grid-template-columns': ($mq!=='mobile'? 'min-content auto auto' : 'min-content auto')}">
                      <div id="rotate-options">
                        <b-button v-if="imgSrc && imgSrc.length>0" v-on:click="rotateImageRight" class="image-button" variant="clear-blue"><div class="rotate-left-button"></div></b-button>
                        <b-button v-if="imgSrc && imgSrc.length>0" v-on:click="rotateImageLeft" class="image-button" variant="clear-blue"><div class="rotate-right-button"></div></b-button>
                        <b-button v-if="imgSrc && imgSrc.length>0" v-on:click="flipImageX()"  class="image-button" variant="clear-blue"><div class="flip-y-button"></div></b-button>
                        <b-button v-if="imgSrc && imgSrc.length>0" v-on:click="flipImageY()" class="image-button" variant="clear-blue"><div class="flip-x-button"></div></b-button>
                      </div>
                      <div style="width: auto; height:300px; margin: 20px auto; border: 1px solid gray; display: inline-block;">
                        <vue-cropper ref='cropper'
                                    :guides="true"
                                    :view-mode="2"
                                    drag-mode="crop"
                                    :auto-crop="true"
                                    :auto-crop-area="1"
                                    :min-container-width="250"
                                    :min-container-height="180"
                                    :background="true"
                                    :rotatable="true"
                                    :src="imgSrc"
                                    :aspectRatio="isCorporateConnections? 1 :0.6157"
                                    :cropmove="cropMove"
                                    alt="Source Image"
                                    :img-style="{ 'width': 'auto', 'height': '300px' }">
                        </vue-cropper>
                      </div>
                      <div v-if="isCorporateConnections" class="circular-member" :style="{'background-image': 'url('+tempPhoto+')','grid-column': ($mq!=='mobile'? '3/4': '1/3'), width: '300px', height: '300px'}"></div>
                      <img v-if="$mq!=='mobile' && !isCorporateConnections" id="set-photo-preview" v-show="tempPhoto" :style="{'grid-column': ($mq!=='mobile'? '3/4': '1/3')}" :src="tempPhoto" />
                      <div id="zoom-text">{{$t('member.zoom_text')}}</div>
                      <b-button class="submit-button4" v-on:click="openDialog" variant="blue" style="grid-column:2/3;margin: auto;">{{$t('memberphoto.upload_photo')}}</b-button>
                    </div>
                    <div slot="modal-footer" class="submit-box-2">
                      <b-button class="submit-button4" v-on:click="showPhotoEdit=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
                      <b-button class="submit-button4" v-on:click="cropImage" variant="clear-blue"><div class="save-button"></div><span>{{$t('memberphoto.set_photo')}}</span></b-button>
                    </div>
                  </b-modal>
                </div>
              </div>
              <div v-if="member.isContact==0" style="padding: 0px 20px;">
                <span class="form-box-title">{{$t('memberphoto.logo_text')}}</span>
                <div class="form-box" style="grid-template-columns: auto;grid-template-rows: 250px auto; padding-bottom:5px;">
                  <div v-show="!member.logo"></div>
                  <img v-show="member.logo" style="max-width: 250px;margin: auto;" :src="member.logo" />
                  <b-button class="submit-button4" v-on:click="openDialog2()" style="margin: 15px auto;margin-bottom: 10px;" variant="blue">{{$t('memberphoto.set_logo')}}</b-button>
                  <b-form-file id='fileid2' accept="image/jpeg, image/png, image/gif" @change="setImage2" plain hidden></b-form-file>
                  <div class="page-note" >{{$t('memberphoto.logo_note')}}</div>
                </div>
              </div>
            </div>
            <div v-if="member.isContact==0 && member.official==1">
              <span class="form-box-title">{{$t('memberphoto.weekly_ask')}}</span>
                <div class="form-box" style="grid-template-columns: auto;">
                    <b-form-textarea id="request-box" v-model="member.ask" :rows="2" maxlength="120" :placeholder="$t('memberphoto.request_placeholder')" />
                    <div class="page-note" :style="{'color': cc==120? 'red' : ''}">{{char_count}}</div>
                    <div style="color:#999;width:100%;text-align:right;"><i>{{$t('memberphoto.ask_help')}}</i></div>
                </div>
            </div>
            
            
            <div>
              <b-alert variant="danger" class="fit-width" style="margin:auto;"
                      dismissible
                      :show="showRoleAlert"
                      @dismissed="showRoleAlert=false">
                {{alertText}}
              </b-alert>
            </div>
            <div v-if="showUpload">
              <b-alert style="line-height:30px;" variant="warning" :show="showUpload">
                {{$t('memberphoto.uploading_text')}}
                <b-spinner style="float:right;" label="Uploading" variant="warning"></b-spinner>
              </b-alert>
            </div>

            <div  style="margin:auto;margin-top:30px;display:grid;grid-template-columns: repeat(auto,3);" class="fit-width">
              <b-button v-if="!showUpload" class="submit-button4" type="submit" variant="clear-blue"><div class="save-button"></div><b>{{$t('global.button_save')}}</b></b-button>
              <a id="member-file" :download="memberFile.filename" :href="memberFile.content" hidden></a>
              <b-button v-if="!showUpload" class="submit-button4" v-on:click="downloadMemberInfo" variant="clear-blue"><div class="import-button"></div><b style="margin-left: 5px;">{{$t('member.download_info')}}</b></b-button>
              <b-button v-if="!showUpload" class="submit-button4" v-on:click="requestDelete" variant="clear-blue"><div class="delete-button"></div><b>{{$t('global.button_delete')}}</b></b-button>
              <div class="page-note" style="grid-column: 1/4;argin-top: 10px;">{{$t('memberphoto.save_note')}}</div>
            </div>
          
        </form>
      </div>
      <div class="div-space"></div>
    </div>
    <div v-if="memberReturned && needPin" class="footer white-text center">
      Meeting HUB for {{isCorporateConnections? 'Corporate Connections' : 'BNI'}} was designed and developed by Meeting HUB Inc.<br>
      © Meeting HUB Inc. 2021, All Rights Reserved.<br>
      Use of Meeting HUB is subject to our <a v-on:click="goTerms()" class="clickable" target="_blank"><u>Terms and Conditions</u></a> and <a class="clickable" v-on:click="goPrivacy()" target="_blank"><u>Privacy Policy</u></a>.
    </div>
    <Footer v-if="memberReturned && !needPin && member.isContact==0 && member.official==1" :useruid="this.$route.params.useruid"></Footer>
  </div>
</template>
<style scoped>
.footer{
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 75px;
        background-color: #000;
        padding: 8px 0 0 0;
        font-size: 13px;
        text-align: center;
        color: #fff !important;
    }
.dash-checkmark{
    width: 14px !important;
    height: 12px !important;
    background-size: contain !important;
    background: url("../assets/checkmark-green-HOME.png") transparent no-repeat;
    margin: auto;
  }
  .dash-redx{
   width: 14px !important;
    height: 12px !important;
    background-size: contain !important;
    background: url("../assets/cross-red-HOME.png") transparent no-repeat;
    margin: auto;
  }
request-box::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color:#aaaaaa;
  opacity: 1; /* Firefox */
}

request-box:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #aaaaaa;
}

request-box::-ms-input-placeholder { /* Microsoft Edge */
  color: #aaaaaa;
}
.page-note{
  
  color: #aaaaaa;
  font-size: 14px;
  text-align: center;
  
}
#set-photo-preview{
  height:300px; margin: auto; border: 1px solid gray;
}
#photoset-title{
  display: grid;grid-gap: 20px;margin: auto;
}
</style>
<script>
  import VueCropper from 'vue-cropperjs';
  import router from "../router"
    import 'cropperjs/dist/cropper.css';
    export default {
    name: "MemberPhoto",
    components: {
      VueCropper,
    },
      data() {
        return {
          deletePin1: "",
          deletePin2: "",
          deletePin3: "",
          deletePin4: "",
          showDelete: false,
          memberFile: {filename: "", content: ""},
          contactMethods: [{value:0, text: this.$t("member.sms_mobile")},{value:1, text: this.$t("member.sms_phone")},{value:2, text: this.$t("member.email_link")}],
          showVerificationAlert: false,
          verificationAlertText: "",
          fields: [
            { key: 'service',
              label: this.$t('memberphoto.service_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
             { key: 'subscribed',
              label: this.$t('memberphoto.subscribed_header'),
              sortable: false,
              thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff", "text-align": "center"},
            }
          ],
          member: {name: "", photo: null, logo: null,photo_small: null, ask: "", tagline: "", email: "", phone: "", mobile_phone: "", website: "", contact_types : [], introduction:""},
          showRoleAlert: false,
          alertText: "",
          tempPhoto: "",
          imgSrc: '',
          showPhotoEdit: false,
          succeeded: false,
          showUpload: false,
          showUnsubscribe: false,
          rows: [],
          unsubscribed: 0,
          unsubValue: 0,
          email_actions : [8,11,16,14,27],
          use_taglines: false,
          tagline_chars: 65,
          typeOptions: [],
          pin: "",
          needPin: false,
          memberReturned: false,
          showDeleteVerificationAlert: false
        }
      },
    computed: {
      cc: function () {
        var n = this.member.ask.length;
        return n;
      },
      char_count: function () {
        var n = this.member.ask.length;
        return (n < 120? n+"/120 "+this.$t('memberphoto.char_count_1') : this.$t('memberphoto.char_count_2'));
      },
      tag_count: function () {
        var n = this.member.tagline.length;
        return (n < this.tagline_chars? n+"/"+this.tagline_chars+" "+this.$t('memberphoto.char_count_1') : this.$t('memberphoto.char_count_2'));
      }
    },
    methods: {
      goTerms(){
          if(this.isCorporateConnections){
           window.open("/#/terms", "_blank");
          }else{
            window.open("https://canada.meetinghub.biz/terms.html", "_blank");
          }
       },
       goPrivacy(){
         if(this.isCorporateConnections){
           window.open("/#/privacy", "_blank");
         }else{
           window.open("https://canada.meetinghub.biz/privacy.html", "_blank");
         }
           
       },
      downloadMemberInfo(){
        this.memberFile.filename = this.member.name+".json";
        var storageObj = {name: this.member.name, email: this.member.email, phone:this.member.phone,category: this.member.category, company: this.member.company, website: this.member.website};
        if(this.isCorporateConnections){
          storageObj['mobile_phone']= this.member.mobile_phone;
        }
        storageObj['photo'] = this.member.photo;
        storageObj['logo'] = this.member.logo;

        this.memberFile.content = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(storageObj));
        this.$nextTick(function(){
          document.getElementById("member-file").click();
        });
      },
      openPhotoEdit(){
        this.showPhotoEdit=true
        if(this.imgSrc && this.imgSrc.length>0){
          this.cropMove();
        }
      },
      cropMove() {
        this.tempPhoto = this.$refs.cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.8);
      },
      setImage(e) {
        const file = e.target.files[0];
        if (!file.type.includes('image/')) {
          return;
        }
        if (typeof FileReader === 'function') {
          var self = this;
          this.resizeImage(file, 665, 1080, function (dataurl) {
            self.imgSrc = dataurl;
            self.$refs.cropper.replace(dataurl);
            setTimeout(function () {
              self.tempPhoto = self.$refs.cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.8);
            }, 200);
          });
        }
      },
      resizeImage(file, scale_width,scale_height, onEnd) {
          var reader = new FileReader();
          var self = this;
          reader.onload = function(e) {
              self.resizeDataUrl(e.target.result, 'image/jpeg', scale_width, scale_height, onEnd);
          }
          reader.readAsDataURL(file);
      },
      resizeDataUrl(filedata, imagetype, scale_width, scale_height, onEnd){
        var img = document.createElement("img");
        img.src = filedata;
        img.onload = function(){
          var width = img.width;
          var height = img.height;
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          var MAX_WIDTH = scale_width;
          var MAX_HEIGHT = scale_height;
          if (width > height) {
              if (width > MAX_WIDTH) {
                  height *= MAX_WIDTH / width;
                  width = MAX_WIDTH;
              }
          } else {
              if (height > MAX_HEIGHT) {
                  width *= MAX_HEIGHT / height;
                  height = MAX_HEIGHT;
              }
          }
          canvas.width = width;
          canvas.height = height;
          if(imagetype.includes("j")){
            ctx.fillStyle = '#fff'; 
            ctx.fillRect(0, 0, canvas.width, canvas.height);
          }
          ctx.drawImage(img, 0, 0, width, height);
          var dataurl = canvas.toDataURL(imagetype, 0.8);
          onEnd(dataurl);
        }
      },
      cropImage() {
        if(this.$refs.cropper.getCroppedCanvas()){
          this.member.photo = this.$refs.cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.8);
          var self = this;
          this.resizeDataUrl(this.member.photo, 'image/jpeg',132,216, function(dataurl){
              self.member.photo_small = dataurl;
          });
        }else{
          this.member.photo = null;
          this.member.photo_small = null;
        }
        this.showPhotoEdit = false;
      },
      getContactTypes(){
        this.$http.post("/api/contact/types/get", {}).then((response) => {
          this.typeOptions = response.data.types;

          var ids = this.member.contact_type.split(",");
            this.member.contact_types = [];
            for(var i=0; i<this.typeOptions.length;i++){
              if(ids.indexOf(this.typeOptions[i].id+"")>=0){
                this.member.contact_types.push(this.typeOptions[i].name);
              }
            }
        }).catch(() => {
        });
      },
      getUseTaglines(){
          this.$http.post("/api/region/get/usetaglines/byhash", {hash: this.hash }).then((response) => {
            if (response.data.success === true) {
              this.use_taglines = response.data.use_taglines;
              this.tagline_chars = response.data.tagline_chars;
            }
          }).catch(() => {});
        },
      getMemberPhoto() {
          if (this.hash.length > 0) {
            this.$http.post("/api/member/get/info"+(this.pin.length==0? "" : "/byhash"), { hash: this.hash, pin: this.pin }).then((response) => {
              this.memberReturned = true;
              if (response.data.success) {
                this.needPin = false;
                this.unsubscribed = response.data.member.unsubscribed;
                this.recalculateSubscriptions();
                response.data.member.contact_types = [];
                this.member = response.data.member;
                if(this.member.isContact==1){
                  this.getContactTypes();
                }
              } else if(response.data.wrong_pin){
                this.pin = "";
                this.showVerificationAlert = true;
                this.verificationAlertText = this.$t('memberphoto.wrong_pin');
              } else if(response.data.no_tries){
                this.pin = "";
                this.showVerificationAlert = true;
                this.verificationAlertText = this.$t('memberphoto.no_tries');
              } else {
                this.succeeded = true;
                this.showRoleAlert = true;
                this.alertText = this.$t('memberphoto.link_expired');
              }
            }).catch(() => {
              if(this.pin.length==0){
                this.$http.post("/api/member/get/pin/info", {hash: this.hash }).then((response) => {
                  if (response.data.success === true) {
                    this.member = response.data.member;
                    this.needPin = true;
                    this.memberReturned = true; 
                  }
                }).catch(() => {});
              }
            });
          }
      },
      requestDelete(){
        this.deletePin1 = "";
        this.deletePin2 = "";
        this.deletePin3 = "";
        this.deletePin4 = "";
        this.showDeleteVerificationAlert = false;
        this.showDelete = true;
        this.member.hash = this.hash;
        this.member.pin = this.pin;
        this.$http.post("/api/member/delete/request/byhash", this.member).then((response) => {
            if (response.data.success) {
              this.$bvToast.toast(this.$t('memberphoto.delete_pin_sent')+response.data.member.email, {
                variant: 'success',
                solid: true
              });
            }
          }).catch(() => { this.showUpload = false; this.$bvToast.show('serverError');});
      },
      deleteMember(){
        this.showDeleteVerificationAlert = false;
        this.member.hash = this.hash;
        this.member.pin = this.pin;
        this.member.delete_pin = this.deletePin1+this.deletePin2+this.deletePin3+this.deletePin4;
        this.$http.post("/api/member/delete/byhash", this.member).then((response) => {
            if (response.data.success) {
              this.showDelete = false;
              router.push("/login");
            }else{
              this.verificationAlertText = this.$t('memberphoto.wrong_pin');
              this.showDeleteVerificationAlert = true;
            }
          }).catch(() => { this.showUpload = false; this.$bvToast.show('serverError');});
      },
      updateMember() {
        this.showUpload = true;
        if (this.hash.length > 0) {
          this.member.hash = this.hash;
          this.member.tagline = this.member.tagline.substr(0,this.tagline_chars);
          this.member.pin = this.pin;
          this.$http.post("/api/member/update/photo/byhash", this.member).then((response) => {
            this.showUpload = false;
            if(response.data.error){
              if(response.data.error.errno == 1062){
                  this.showRoleAlert = true;
                  this.alertText = this.$t('memberphoto.email_error');
                }
            }else if (response.data.success) {
              this.succeeded = true;
              this.$bvToast.toast(this.$t('memberphoto.photo_updated'), {
                title: this.$t('memberphoto.title_text'),
                variant: 'success',
                solid: true
              });
            }
          }).catch(() => { this.showUpload = false; this.$bvToast.show('serverError');});
        }
      },
      openDialog() {
        document.getElementById('fileid').click();
      },
      openDialog2() {
        document.getElementById('fileid2').click();
      },
      setImage2(e) {
        const file = e.target.files[0];
        if (!file.type.includes('image/')) {
          alert('Please select an image file');
          return;
        }
        if (typeof FileReader === 'function') {
          var self = this;
          this.resizeImage(file, 1920,1080, function (dataurl) {
            self.member.logo = dataurl;
          });
        } else {
          alert('Sorry, FileReader API not supported');
        }
      },
      rotateImageLeft(){
        this.$refs.cropper.rotate(90);
        this.cropMove();
      },
      rotateImageRight(){
        this.$refs.cropper.rotate(-90);
        this.cropMove();
      },
      flipImageX(){
        var d = this.$refs.cropper.getData()
        this.$refs.cropper.scaleX(d.scaleX*-1);
        this.cropMove();
      },
      flipImageY(){
        var d = this.$refs.cropper.getData()
        this.$refs.cropper.scaleY(d.scaleY*-1);
        this.cropMove();
      },
      recalculateSubscriptions(){
        this.rows = [];
        this.unsubValue = 0;
        for(var i=0; i<this.email_actions.length; i++){
            this.unsubValue+=Math.pow(2,this.email_actions[i]);
            this.rows.push({service: this.email_actions[i], subscribed: (Math.pow(2,this.email_actions[i]) & this.unsubscribed)==0? 1 : 0});
        }

    },
      setUnsubscribed() {
          this.showSuccess = false;
          this.unsubscribed = 0;
          for(var i=0; i<this.rows.length; i++){
              if(this.rows[i].subscribed==0){
                  this.unsubscribed+=Math.pow(2,this.rows[i].service);
              }
        }
          this.$http.post("/api/member/set/unsubscribed/byhash", { hash: this.hash, unsubscribed: this.unsubscribed, pin: this.pin }).then((response) => {
            if (response.data.success) {
                this.showSuccess = true;
            }
            
          }).catch(() => {});
      }
    },
    destroyed() {
    },
    mounted() {
      this.hash = this.$route.params.useruid;
      if(this.$route.params.pin){
        this.pin = this.$route.params.pin;
      }
      document.title = this.$t('memberphoto.member_photo');
      this.getUseTaglines();
      this.getMemberPhoto();
    }
    }
</script>
