<template>
  <div>
    <div class="rel-grid-container">
      <div id="top-div">
      </div>
      <div>
        <div style="display: grid;grid-template-columns: repeat(3,auto);">
          <div style="margin-top:auto;">
            <div class="agenda-section-title">{{$t('slides.meeting_time')}} - <b>{{totalTime}}</b></div>
            <div id="section-selector">
              <span class="form-box-name">{{$t('slides.show_section')}} </span>
              <b-form-select style="margin: 5px 0px;" required v-model="permissions.sectionFilterId" :options="section_select" value-field="id" text-field="name" class="mb-3 " />
            </div>
          </div>
          <div class="submit-box-2" v-if="organization_id==0" style="display: block;">
            <span class="form-box-title">{{$t('slides.section_text')}}</span>
            <div class="form-box" style="grid-template-columns: repeat(2,max-content);">
              <b-button class="submit-button4" v-if="permissions.organization.is_parent || permissions.organization.rst" v-on:click="showCopyPopupSection" variant="clear-blue"><div class="copy-button"></div><span>{{$t('global.button_copy')}}</span></b-button>
              <b-button class="submit-button4" v-on:click="newSection" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
              </div>
          </div>
          <div class="submit-box-2" v-if="organization_id==0" style="display: block;">
            <span class="form-box-title">{{$t('slides.slide_text')}}</span>
            <div class="form-box" style="grid-template-columns: repeat(2,max-content);padding-bottom:7px;">
              <b-button :disabled="permissions.sectionFilterId==0" class="submit-button4" v-on:click="showCopyPopup" variant="clear-blue"><div class="copy-button"></div><span>{{$t('global.button_copy')}}</span></b-button>
              <b-button :disabled="permissions.sectionFilterId==0" class="submit-button4" v-on:click="newSlide" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
              <!--<b-button :disabled="permissions.sectionFilterId==0" class="submit-button4" v-on:click="showPPTModal=true;" variant="clear-blue"><div class="import-button"></div><span>{{$t('global.button_import')}}</span></b-button>-->
              <div v-if="permissions.sectionFilterId==0" style="grid-column:1/3;    text-align: center;font-size:10px;">{{$t('slides.slide_select_note')}}</div>
            </div>
            
          </div>
       
        </div>
        
        <Tip :tipNumber="3"></Tip>
        <div id="select-options">
          <div></div>
          <b-form-checkbox v-on:change="disabledSlidesChange" v-model="permissions.showDisabledSlides">
            {{$t('slides.disabled_slides')}}
          </b-form-checkbox>
          <b-form-checkbox v-model="permissions.getFiles">
            {{$t('slides.show_files')}}
          </b-form-checkbox>
          <b-form-checkbox v-model="permissions.getSlides">
            {{$t('slides.show_slides')}}
          </b-form-checkbox>
        </div>
        <SetupTip v-if="!permissions.organization.is_parent" style="position:absolute; left: -40px;" :tipNumber="5"/>
        <SetupTip v-if="permissions.organization.is_parent" style="position:absolute; left: -40px;" :tipNumber="12"/>
        <table class="table b-table table-bordered table-sm">
          <thead>
            <tr>
              <th class="tableHeader center-text">{{$t('slides.time_header')}} </th>
              <th class="tableHeader">{{$t('slides.section_header')}} </th>
              <th v-if="permissions.getSlides" class="tableHeader">{{$t('slides.slide_header')}} </th>
              <th v-if="permissions.getSlides" class="tableHeader">{{$t('slides.after_header')}} </th>
              <th class="tableHeader center-text">{{$t('slides.duration_header')}} </th>
            </tr>
          </thead>
          <draggable v-for="section in sections" tag="tbody" :key="section.id" v-model="section.slides" group="slides" handle=".slide-move" @end="endDragSlide" @start="startDragSlide" >
            <tr v-for="slide in section.slides.filter(v=> permissions.getSlides? true : (v==section.slides[0]))" :key="slide.id" style="line-height:30px;" v-bind:id="'slide_'+slide.id+'_'+section.section_id" class="dragndrop"
                v-bind:class="{disabledClass : section.disabled, lastRow: slide == (permissions.getSlides? section.slides[section.slides.length-1] : section.slides[0]) && section != sections[sections.length-1] && !moving, 'slide-move': organization_id==0 && slide.isParent ==0 && slide.id>0}">
              <td >
                <div v-if="slide == section.slides[0]"  class="section-time-column">
                  {{start != null ? section.start_time: ""}}
                </div>
              </td>
              <td @mouseenter="section.hover=true;" @mouseleave="section.hover=false;" v-bind:class="{ 'striped-color': section.isParent==1,'td-hover-applied': section.hover ==true,}" v-on:click="editSection(section)"> <!--'td-hover-new' : slide == section.slides[0] && organization_id ==0,-->
                <div v-if="slide == section.slides[0]" :id="'section_'+section.section_id"  class="slide-description-column">
                  <div style="width:23px;display:grid;" class="fit-height" >
                    <b-button v-if="section != sections[0] && section.isParent == 0 && organization_id == 0" id="sectionUpButton" variant="blue" v-on:click.stop="sectionUp(section)" v-b-popover.hover="$t('slides.section_up')" ></b-button>
                    <b-button v-if="section != sections[sections.length-1] && section.isParent == 0 && organization_id == 0" id="sectionDownButton" variant="blue" v-on:click.stop="sectionDown(section)" v-b-popover.hover="$t('slides.section_down')"></b-button>
                  </div>
                  <b style="max-width:200px;">{{section.name && section.name.length>0? section.name : $t('global.no_translation') }}</b>
                  <img style="height: 16px;margin: 7px 5px;" class="image-colour-shift" v-b-popover.hover.top="$t('slides.autoplay_tooltip')" v-if="section.auto_play==0 && !section.disabled" src="../assets/tiny-NoAutoPlay.png" />
                  <img style="height: 16px;margin: 7px 5px;" class="image-colour-shift" v-b-popover.hover.top="$t('slides.looped_tooltip')" v-if="section.looped>0 && !section.disabled" src="../assets/tiny-SectionLoop.png" />
                  <img v-if="section.preparer && section.preparer.length>0 && !section.disabled && !(section.isParent==1 && section.section_id==section.id)" :style="{height: '20px', 'margin': '5px 5px', cursor: organization_id==0? 'pointer' : ''}" class="image-colour-shift" v-on:click.stop="goToFiles(section)"  v-b-popover.hover.top="preparePopup(section)" src="../assets/tiny-SectionPrepare.png" />
                  <img style="height: 20px;margin: auto 5px;" class="image-colour-shift" v-if="section.force_time!=null && !section.disabled" v-b-popover.hover.top="$t('slides.autostart_tooltip')+' '+section.force_time" src="../assets/tiny-SectionAutostart.png" />
                  <div v-if="permissions.getFiles && section.files && section.files.length>0" style="grid-column: 1/6;grid-row: 2;">
                    <div v-for="f in section.files" :key="f.num">
                      <b-button v-on:click.stop="goToFileControl(f.hash,f.type,f.link)" variant="outline-success" class="curtail-text file-button"  style="border-width:2px;">{{f.type == 2? f.link : f.file_path}}</b-button>
                    </div>
                  </div>
                </div>
              </td>
              <td v-if="permissions.getSlides" v-bind:id="'slide_'+slide.id+'_'+section.section_id+'pop'" v-on:click="editSlide(slide)" :style="{'cursor': slide.id>0? 'pointer' : 'auto'}" v-bind:class="{'td-hover-new' : slide.name && slide.name.length>0  && organization_id ==0 , disabledClass : slide.disabled,'striped-color': slide.isParent==1}" >
                <!--<b-form-checkbox v-if="slide.isParent==0 || slide.child_edit==1" style="display:inline-block;line-height: 20px;" @click.native.stop.capture v-on:change="disableSlide(slide)" v-model="slide.disabled" :value="false" :unchecked-value="true">
                </b-form-checkbox>-->
                <div v-if="slide.id>0" v-on:click.stop="toggleSlide(slide)" v-b-popover.hover.left="!(slide.isParent==0 || slide.child_edit==1)? $t('slides.always_visible') : (slide.disabled==0 ? $t('slides.slide_visible') : $t('slides.slide_hidden'))" class="visibility-slide " :class="{'slide-hidden': slide.disabled==1 && (slide.isParent==0 || slide.child_edit==1), 'slide-shown': slide.disabled==0 && (slide.isParent==0 || slide.child_edit==1), 'slide-always-shown': !(slide.isParent==0 || slide.child_edit==1), 'image-colour-shift' : (slide.isParent==0 || slide.child_edit==1)}" style="display:inline-block;"></div>
                
                <span style="margin:auto;">{{slide.name && slide.name.length>0? slide.name : $t('global.no_translation')  }}</span>
                <img style="height: 16px;margin: 7px 5px;" class="image-colour-shift" v-b-popover.hover.top="$t('slides.selected_tooltip')" v-if="[1,3,7,8,10,12,14,18,20].indexOf(slide.type) !== -1" src="../assets/tiny-ControllerSelect.png" />
                <b-popover v-if="slide.id>0" :target="'slide_'+slide.id+'_'+section.section_id+'pop'" triggers="hover focus" @show="onPopoverShow(slide)">
                  <img v-if="slide.thumbnail && slide.thumbnail.length>0" :src="slide.thumbnail" style="width:150px;height:auto;" />
                  <span v-if="!(slide.thumbnail && slide.thumbnail.length>0)">{{$t('slides.no_preview')}} </span>
                </b-popover>
                <div v-if="permissions.getFiles && slide.files && slide.files.length>0" >
                  <div  v-for="f in slide.files"  :key="f.num">
                    <b-button v-on:click.stop="goToFileControl(f.hash,f.type,f.link)" variant="outline-success" class="curtail-text file-button" style="border-width:2px;">{{f.type == 2? f.link : f.file_path}}</b-button>
                  </div>
                </div>
                <div v-if="permissions.getFiles && slide.speaker" class="speaker-description">
                  <span style="grid-column: 1/3">{{slide.speaker.name}}:</span>  
                  <b-button v-if="slide.speaker.bio && slide.speaker.bio.length> 10" v-on:click.stop="showBio(slide.speaker)" variant="outline-success" class="curtail-text file-button" style="border-width:2px;">{{$t('slides.bio_text')}}</b-button>
                  <b-button v-if="slide.speaker.url && slide.speaker.url.length>0" v-on:click.stop="goToFileControl(slide.speaker.hash, slide.speaker.type, slide.speaker.url)" variant="outline-success" class="curtail-text file-button" style="border-width:2px;">{{$t('slides.presentation_text')}}</b-button>
                </div>
              </td>
              <td v-if="permissions.getSlides" :class="{disabledClass : slide.disabled}">{{ slide.then_action== 1? $t('slides.replay_text')+" "+slide.replay_times+"X, "+slide.pauseSeconds+" "+$t('slides.secs_slide') : (slide.then_action==2? $t('slides.next_slide')+" > "+slide.pauseSeconds+" "+$t('slides.secs_text') : (slide.then_action==3? $t('slides.wait_controller') : "")) }}</td>
              <td style="text-align:center;">{{slide == section.slides[0]? (("0"+Math.floor(section.target_duration/60)).substr(-2))+":"+("0"+(section.target_duration%60)).substr(-2) : ""}} 
              </td>
            </tr>
          </draggable>
        </table>
        <div v-if="permissions.access && organization_id == 0 && permissions.getSlides" id="drag-note">{{$t('slides.drag_order')}}</div>
        
      </div>
      <b-modal id="slide-modal" centered :title="$t('slides.copy_slide_title')" v-model="showCopyModal">
        <span class="form-box-title">{{$t('slides.copy_from')}}</span>
        <div class="form-box" style="grid-template-columns: 25% 25% 50%;">
          <span v-if="permissions.organization.is_parent || permissions.organization.rst" class="form-box-name">{{$t('slides.organization_label')}}</span><b-form-select v-if="permissions.organization.is_parent  || permissions.organization.rst" style="margin: 5px;" v-model="copyOid" :options="copy_organizations" value-field="id" text-field="name" class=" form-box-input" />
          
          <span class="form-box-name" >{{$t('slides.section_label')}}</span><b-form-select style="margin: 5px;" v-model="copySid" :options="copy_sections" value-field="id" text-field="name" class=" form-box-input" />
          <span class="form-box-name" >{{$t('slides.slide_label')}}</span><b-form-select style="margin: 5px;" v-model="copyId" :options="copy_slides" value-field="id" text-field="name" class=" form-box-input" />
        </div>
        <Tip :tipNumber="4"></Tip>
        <div v-if="showAlert">
          <b-alert variant="warning"
                   dismissible
                   :show="showAlert"
                   @dismissed="showAlert=false">
            {{alertText}}
          </b-alert>
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button v-if="showReplace" class="submit-button4" v-on:click="copySlide(true)" variant="clear-blue"><div class="replace-button"></div><span>{{$t('global.button_replace')}}</span></b-button>
          <b-button class="submit-button4" v-on:click="copySlide(false)" variant="clear-blue"><div class="copy-button"></div><span>{{$t('global.button_copy')}}</span></b-button>
          <b-button class="submit-button4" v-on:click="showCopyModal=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
        </div>
      </b-modal>
      <b-modal id="slide-modal" centered :title="$t('slides.copy_section_title')" v-model="showCopyModalSection">
        <span class="form-box-title">{{$t('slides.copy_from')}}</span>
        <div class="form-box" style="grid-template-columns: 25% 25% 50%;">
          <span v-if="permissions.organization.is_parent || permissions.organization.rst" class="form-box-name">{{$t('slides.region_text')}}</span><b-form-select v-if="permissions.organization.is_parent || permissions.organization.rst" style="margin: 5px;" v-model="copyOid" :options="copy_organizations" value-field="id" text-field="name" class=" form-box-input" />
          <span v-if="permissions.organization.is_parent || permissions.organization.rst" class="form-box-name">{{$t('slides.chapter_optional')}}</span><b-form-select v-if="permissions.organization.is_parent  || permissions.organization.rst" style="margin: 5px;" v-model="copyCid" :options="copy_chapters" value-field="id" text-field="name" class=" form-box-input" />
          <span class="form-box-name" >{{copyCid>0? $t('slides.chapter_section') : $t('slides.regional_section')}}</span><b-form-select style="margin: 5px;" v-model="copySid" :options="copy_sections" value-field="id" text-field="name" class=" form-box-input" />
        </div>
        <div v-if="showAlert">
          <b-alert variant="warning"
                   dismissible
                   :show="showAlert"
                   @dismissed="showAlert=false">
            {{alertText}}
          </b-alert>
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button v-if="showReplace" class="submit-button4" v-on:click="copySection(true)" variant="clear-blue"><div class="replace-button"></div><span>{{$t('global.button_replace')}}</span></b-button>
          <b-button class="submit-button4" v-on:click="copySection(false)" variant="clear-blue"><div class="copy-button"></div><span>{{$t('global.button_copy')}}</span></b-button>
          <b-button class="submit-button4" v-on:click="showCopyModalSection=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
        </div> 
      </b-modal>
      <b-modal centered v-model="showBioModal">
        <div slot="modal-header" class="w-100" id="bio-modal-header">
          <b-button id="bio-modal-name" class="agenda-width-button " variant="dark">{{$t('slides.speaker_bio')}}: {{current_speaker.name}}</b-button>
        <b-button style="margin: auto;" class="submit-button4" v-on:click="showBioModal= false;" variant="clear-blue"><div class="close-button"></div><span>{{$t('global.button_close')}}</span></b-button>
        </div>
        <div id="bio-modal-text">
          <pre class="script-text" v-html="current_speaker.bio"></pre>
        </div>
        <div slot="modal-footer" class="submit-box-2">
      </div>
      </b-modal>
      <b-modal id="slide-modal" centered v-model="showPPTModal" :title="$t('slides.import_pp')"  :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true">
        <Tip :tipNumber="26"></Tip>
        <div  class="fit-width" style="margin:auto;">
          <div style="display:grid; grid-template-columns: repeat(3, max-content); margin:auto; grid-gap: 20px;" class="fit-width">
            <span>{{$t('slides.import_as')}}</span>
            <b-form-checkbox v-model="importAsObjects" value="1" unchecked-value="0">
              {{$t('slides.objects_text')}}
            </b-form-checkbox>
            <b-form-checkbox v-model="importAsObjects" value="0" unchecked-value="1">
              {{$t('slides.images_text')}}
            </b-form-checkbox>
          </div>
          <br/>
          <b-button variant="blue" v-on:click="openDialog" >{{$t('slides.import_pptx_button')}}</b-button>
          <br/><br/>
          <span v-if="importObject.file != null" style="margin-left:20px;">{{importObject.file.name}}</span>
          <b-form-file ref="file-input"  id='fileid' accept=".pptx" @change="onFileChange" plain hidden></b-form-file>
          <b-form-checkbox v-on:change="disabledSlidesChange" v-model="importObject.allPages" value="1" unchecked-value="0">
            {{$t('slides.all_pages')}}
          </b-form-checkbox>
          <b-form-checkbox v-on:change="disabledSlidesChange" v-model="importObject.allPages" value="0" unchecked-value="1">
            {{$t('slides.custom_pages')}}
          </b-form-checkbox>
          <b-form-input v-if="importObject.allPages==0" v-model="importObject.pages" type="text" style="width:300px;" />
          <span v-if="importObject.allPages==0" style="font-size:12; color: #aaa;">{{$t('slides.custom_note')}}</span>
          <b-alert variant="warning" :show="showImportAlert" >
            {{$t('slides.importing_powerpoint')}}
            <b-spinner style="float:right;" label="Uploading" variant="warning"></b-spinner>
          </b-alert>
          <b-alert variant="success" :show="showPPTImagesSuccess" >
            {{$t('slides.importing_success')}}
          </b-alert>
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button v-if="showPPTImagesSuccess" style="margin: auto;" class="submit-button4" :disabled="showImportAlert" v-on:click="closePPImport" variant="clear-blue"><div class="ok-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
          <b-button v-if="!showPPTImagesSuccess" style="margin: auto;" class="submit-button4" :disabled="showImportAlert" v-on:click="closePPImport" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button v-if="importObject.file != null && !showPPTImagesSuccess" style="margin: auto;" :disabled="showImportAlert" class="submit-button4" v-on:click="importPP" variant="clear-blue"><div class="import-button"></div><span>{{$t('global.button_import')}}</span></b-button>
      </div>
      </b-modal>
      <b-modal id="modal-gallery" centered v-model="showGalleryModal">
        <div slot="modal-header" id="gallery-header" class="w-100">
          <div id="gallery-title">{{$t('slides.gallery_title')}}</div>
          <b-button style="margin: auto;" class="submit-button4" v-on:click="showGalleryModal= false;" variant="clear-blue"><div class="close-button"></div><span>{{$t('global.button_close')}}</span></b-button>
          <div id="gallery-div">
            <span style="grid-column: 1/2;margin:5px;">{{$t('slides.search_tag')}}</span>
            <multiselect :noOptions="$t('global.empty_list')" :class="{blueselect:true}" :selectedLabel="$t('global.selected_text')" selectLabel="" deselectLabel="" v-model="selected_tags" :options="tags" :multiple="true" :close-on-select="false"  :preserve-search="true" :placeholder="$t('global.select_tags')" label="name" track-by="id" :preselect-first="false">
              <template slot="option" slot-scope="props">
                <div>{{ props.option.name }}</div>
              </template>
            </multiselect>
          </div>
        </div>
        <div id="gallery-view">
            <div v-for="preview in previews.filter(function(p){
              for(var i=0; i<selected_tags.length; i++){
                if(p.tags == null || p.tags.split(',').indexOf(selected_tags[i].id+'')==-1){
                  return false;
                }
              }
              return true;
            })" :key="preview.id" style="height:200px; width: 100%;">
              <div  class="highlight-wrapper" :style="{'background-color' : preview.selected? '#95defc':'#fff'}">
                <div v-on:click="toggleGallerySelect(preview)" class="clickable fit-width gallery-preview">
                  <img style="height: 100px; margin: auto;" :src="preview.thumbnail" v-b-popover.hover="preview.description"/>
                  <span>{{preview.name}}</span>
              </div>
            </div>
            </div>
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <span style="margin:5px;">{{$t('slides.add_to')}}</span><b-form-select v-model="permissions.sectionFilterId" :options="section_select" value-field="id" text-field="name" />
          <b-button v-if="permissions.sectionFilterId>0 && gallery_selected.length>0" class="submit-button4" v-on:click="addGallerySlides" variant="clear-blue"><div class="new-button"></div><span>{{$t('slides.add_slides')}}</span></b-button>
      </div>
      </b-modal>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped lang="scss">
.visibility-slide{
  height: 18px;
  width:25px;
   background-size: 100% 100%;
  background-repeat: no-repeat;
  vertical-align: middle;
    margin-right: 5px;
}
.slide-hidden{
  background-image: url("../assets/icon-SlideHidden.png");
}
.slide-shown{
  background-image: url("../assets/icon-SlideVisible.png");
}
.slide-always-shown{
  background-image: url("../assets/icon-SlideAlwaysVisible.png");
}
.agenda-width-button {
    width: 100%;
    font-size: 14px;
    text-align: left;
  }
.agenda-section-title{
  text-align: center;
    font-size: 25px;
    margin-bottom: 10px;
}
#gallery-view{
  height:100%; overflow-y: auto; overflow-x: hidden; font-size:20px;display:grid;  max-width:100%;   grid-template-columns: repeat(4,1fr);
}
#gallery-div{
  display: grid;grid-template-columns: max-content 300px;
}
#gallery-title{
  text-align: center;font-size: 30px;
}
#gallery-header{
  display: grid;grid-template-columns: auto max-content;
}
#bio-modal-text{
  height:100%; overflow: auto; font-size:20px;
}
#bio-modal-name{
  margin-right: auto;width: 400px;font-size: 20px;
}
#bio-modal-header{
  display: grid;grid-template-columns: minmax(250px, 90%) max-content max-content;
}
#drag-note{
  float: right;font-size: 10px;
}
.speaker-description{
  display:grid;grid-template-columns:  max-content max-content;
}
.slide-description-column{
  line-height: 30px; display: grid;grid-auto-flow: column;grid-template-columns:repeat(5,max-content);
}
.section-time-column{
  width:100px;text-align: center;margin: auto;
}
#select-options{
  display:grid;grid-template-columns: auto max-content max-content max-content;grid-gap:20px;
}

#section-selector{
  display:grid; grid-template-columns:auto auto; width: 400px;margin:auto;
}

.highlight-wrapper{
    border-radius: 10px;
    width: 200px;
    margin: auto;
    margin-top: 25px;
}
.gallery-preview{
  padding-top: 10px;
  display:grid;margin: auto;
  text-align: center;
}
#modal-gallery .modal {
    padding: 0 !important;
    bottom: 0;
    right: 0;
  }
  #modal-gallery .modal-dialog {
    height: 100%;
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    margin: 0;
    min-width: 100%;
  }
#modal-gallery .modal-content {
    position: fixed;
    border: 0;
    border-radius: 0;
    height: 100%;
    overflow: auto;
}
  #modal-gallery .modal-body {
    height: calc(100% - 165px);
    padding: 0px;
  }
  #modal-gallery .modal-header {
    padding: 5px;
  }

.file-button{
  max-width: 500px;
    margin: 2px;
    height: 30px;
    padding: 0px 10px;
}

  #sectionDownButton {
    background-image: url("../assets/Icon-Dn-dim.png");
    border: none;
    width: 16px;
    height: 14px;
    border: none;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-color: rgba(0,0,0,0);
    padding: 0px;
  }
    #sectionDownButton:hover {
      filter: $VUE_APP_COLOUR_MAIN_FILTER;
    }
    #sectionUpButton {
      background-image: url("../assets/Icon-Up-dim.png");
      border: none;
      width: 16px;
      height: 14px;
      border: none;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-color: rgba(0,0,0,0);
      padding: 0px;
    }
      #sectionUpButton:hover {
       filter: $VUE_APP_COLOUR_MAIN_FILTER;
      }

      #sectionUpButton.active {
        border: none;
      }

    #sectionDownButton.active {
      border: none;
    }

  .lastRow {
    border-bottom: $VUE_APP_COLOUR_MAIN solid 3px;
  }
  .tbody-stripe-even tr:nth-of-type(even) {
    background-color: rgba(0,0,0,.05);
  }
  .tbody-stripe-odd tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.05);
  }
    .striped-color {
        background-color: rgba(0,0,0,.05);
    }
 
</style>
<script>
  import pttxparse from 'pptx-parser'
  import router from "../router"
  import draggable from "vuedraggable";
  import Multiselect from 'vue-multiselect';
    export default {
    name: "Agenda",
    watch: {
      'permissions.getFiles': {
        handler: function (v) {
          if(v){
            if (this.permissions.sectionFilterId > 0) {
              this.getSectionFiles(this.permissions.sectionFilterId);
            } else {
              this.getAllFiles();
            }
          }
        },
        deep: true
      },
      'permissions.sectionFilterId': {
        handler: function () {
          if (this.permissions.sectionFilterId > 0) {
            this.getSlides();
          } else {
            this.getAllSlides();
          }
        },
        deep: true
      },
      'copyOid': {
        handler: function () {
          this.copyCid = 0;
          this.getChapters();
          this.getCopySections();
        },
        deep: true
      },
      'copyCid': {
        handler: function () {
          this.getCopySections();
        },
        deep: true
      },
      'copySid': {
        handler: function () {
          this.getCopySlides();
        },
        deep: true
      },
      'copyId': {
        handler: function () {
          this.checkCopySlide();
        },
        deep: true
      },
      
      '$i18n.locale': {
        handler: function () {
          var self = this;
          this.organization_id = this.$route.params.id ? this.$route.params.id : 0;
          this.populateVariables(function () {
            self.getSections();
            if (self.permissions.sectionFilterId > 0) {
              self.getSlides();
            } else {
              self.getAllSlides();
            }
          });
        },
        deep: true
      }
    },
      data() {
        return {
          copyCid: 0,
          copy_chapters: [],
          showPPTImagesSuccess: false,
          importAsObjects: 1,
          importObject: {pages: "", allPages: 1, file: null},
          showPPTModal: false,
          moving: false,
          tags: [],
          selected_tags: [],
          gallery_selected: [],
          organization_id: 0,
          sections: [],
          section_select: [],
          copy_sections: [],
          copy_slides: [],
          copy_organizations: [],
          copyOid: 0,
          copySid: 0,
          copyId: 0,
          showCopyModal: false,
          from_default: "own",
          copy_options: [{ id: "own", name: this.$t('slides.self_option') }, { id: "default", name: "" }],
          showAlert: false,
          alertText: this.$t('slides.alert_text'),
          showReplace: false,
          totalTime: "",
          showCopyModalSection: false,
          variable_map: [],
          current_speaker: {name: '', bio: ''},
          showBioModal: false,
          previews: [],
          showGalleryModal: false,
          imageTest: "",
          showImportAlert: false,
          fontOptions: new Set(["Arial","Georgia","Times","Verdana","Tahoma","Raleway",'Arial Black']),
        }
    },
    components: {
      draggable,
      Multiselect
    },
    methods: {
      toggleSlide(slide){
        if(!(slide.isParent==0 || slide.child_edit==1)){
          return;
        }
        slide.disabled = 1-slide.disabled;
        this.$http.post("/api/organization/slide/disable", { slide_id: slide.id, disabled: slide.disabled}).then(() => {
        }).catch((errors) => {
          console.log(errors);
        });
      },
      preparePopup(section){
        if(!section.preparer_names){
            this.$http.post("/api/organization/section/preparer/names"+(this.permissions.organization.is_parent? "/roles" : ""), { section_id: section.id, id: this.organization_id }).then((response) => {
              if (response.data.success) {
                this.$set(section, "preparer_names", response.data.names.join(","));
              }
            }).catch((errors) => {
              console.log(errors);
            });
        }
        return {
          html: true,
          content: () => {
            return this.$t('slides.section_prepared_by')+':<br/> '+ section.preparer_names+(this.organization_id==0 && this.permissions.organization.is_parent==0? '<br/>'+this.$t('slides.click_to_view') : '')
          }
        }
      },
      goToFiles(section) {
        if (section.preparer_hash && this.organization_id==0 && this.permissions.organization.is_parent==0) {
          router.push("/admin/prepare/" + section.preparer_hash);
        }
      },
      onPopoverShow(slide) {
        if (!slide.gotThumbnail) {
          this.$http.post("/api/meeting/slide/get/thumbnail", { slide_id: slide.id, id: this.organization_id }).then((response) => {
            if (response.data.success) {
              this.$set(slide, "thumbnail", response.data.thumbnail);
              this.$set(slide, "gotThumbnail", true);
            }
          }).catch((errors) => {
            console.log(errors);
          });
        }
      },
      newSection() {
        router.push("/admin/section/0");
      },
      checkCopySlide() {
        this.showAlert = false;
        this.showReplace = false;
        if (this.copyId > 0) {
          this.$http.post("/api/organization/slide/copy/check", { id: this.copyId, section_id: this.permissions.sectionFilterId}).then((response) => {
            if (response.data.success && response.data.replace === true) {
              this.showAlert = true;
              this.showReplace = true;
            } 
          }).catch(() => {
          });
        }
      },
      copySlide(replace) {
        if(this.copyId>0){
            var ranks = [];
            var j = 0;
            var lastParent = this.permissions.organization.is_parent? "" : String.fromCharCode(31);
            var toSid = this.permissions.sectionFilterId;
            var sIndex = 0;
            for (var i = 0; i < this.sections.length; i++) {
              if (this.sections[i].section_id == toSid) {
                sIndex = i;
                break;
              }
            }
            for (i = 0; i < this.sections[sIndex].slides.length; i++) {
              if (this.sections[sIndex].slides[i].isParent == 0) {
                ranks.push({ id: this.sections[sIndex].slides[i].id, rank: lastParent + String.fromCharCode(Math.max(Math.min((j + 33), 126), 33)) });
                j++;
              } else {
                lastParent = this.sections[sIndex].slides[i].rank;
                j = 0;
              }
            }
            var rank = lastParent + String.fromCharCode(Math.max(Math.min((j + 33), 126), 33));
            this.$http.post("/api/organization/slide/copy", { id: this.copyId, section_id: this.permissions.sectionFilterId, replace: replace, rank:rank }).then((response) => {
              if (response.data.success === true) {
                this.showCopyModal = false;
                this.getSlides();
              } else {
                this.showCopyModal = false;
              }
            }).catch(() => {
            });
        }
      },
      checkCopySection() {
        this.showAlert = false;
        this.showReplace = false;
        if (this.copySid > 0) {
          this.$http.post("/api/organization/section/copy/check", { id: this.copySid }).then((response) => {
            if (response.data.success && response.data.replace === true) {
              this.showAlert = true;
              this.showReplace = true;
            }
          }).catch(() => {
          });
        }
      },
      copySection(replace) {
        if (this.copySid > 0) {
          var ranks = [];
            var lastParent = this.permissions.organization.is_parent? "" : String.fromCharCode(31);
            var j = 0;
            for (var i = 0; i < this.sections.length; i++) {
              if (this.sections[i].isParent == 0) {
                ranks.push({ id: this.sections[i].section_id, rank: lastParent + String.fromCharCode(Math.max(Math.min((j + 33), 126), 33)) });
                j++;
              } else {
                lastParent = this.sections[i].rank;
                j = 0;
              }
            }
            var rank = lastParent + String.fromCharCode(Math.max(Math.min((j + 33), 126), 33));
          this.$http.post("/api/organization/section/copy", { section_id: this.copySid, replace: replace, rank: rank }).then((response) => {
            if (response.data.success === true) {
              this.showCopyModalSection = false;
              var self = this;
              setTimeout(function () {
                self.getSections();
                self.getAllSlides();
              }, 500);
            } else {
              this.showCopyModalSection = false;
            }
          }).catch(() => {
          });
        }
      },
      toggleGallerySelect(preview){
        var index = this.gallery_selected.indexOf(preview.id);
        if(index >-1){
          this.gallery_selected.splice(index,1);
          preview.selected = false;
        }else{
          this.gallery_selected.push(preview.id)
          preview.selected = true; 
        }
      },
      addGallerySlides(){
        this.$http.post("/api/organization/slides/copy", {section_id: this.permissions.sectionFilterId,  slide_ids: this.gallery_selected}).then((response) => {
            if (response.data.success) {
              for(var i =0; i<this.previews.length;i++){
                this.previews[i].selected = false;
              }
              this.showGalleryModal = false;
              if (this.permissions.sectionFilterId > 0) {
                this.getSlides();
              } else {
                this.getAllSlides();
              }
            } 
          }).catch((errors) => {
            console.log(errors);
          });
      },
      showGallery(){
        if(this.previews.length == 0){
          this.getGalleryPreviews();
        }
        this.showGalleryModal = true;
      },
       getTags() {
          this.$http.post("/api/tags/get", {}).then((response) => {
            if (response.data.success) {
              this.tags = response.data.tags;
              } 
            }).catch(() => {
            });
          },
      getGalleryPreviews() {
        this.getTags();
            this.$http.post("/api/organization/gallery/get", {}).then((response) => {
              if (response.data.success) {
                for(var i =0; i<response.data.slides.length;i++){
                  response.data.slides[i].selected = false;
                }
                this.previews = response.data.slides;
                } 
              }).catch(() => {
              });
          },
          getSlides() {
            this.$http.post("/api/organization/slides", { section_id: this.permissions.sectionFilterId, getDisabled: this.permissions.showDisabledSlides, id: this.organization_id }).then((response) => {
              this.sections = [];
              if (response.data.success) {
                response.data.section.slides = response.data.slides;
                this.sections.push(response.data.section);
                this.populateSlideNameVariables(this.sections[0]);
                if(this.permissions.getFiles){
                  this.getSectionFiles(this.permissions.sectionFilterId);
                }
                this.scrollToSlide();
              } 
              }).catch(() => {
              });
          },
          getAllSlides() {
            if (this.permissions.sectionFilterId == 0) {
              this.$http.post("/api/organization/slides/all", { getDisabled: this.permissions.showDisabledSlides, id: this.organization_id }).then((response) => {
                if (response.data.success) {
                  if (response.data.sections) {
                    this.sections = response.data.sections;
                    this.start = response.data.start;
                    this.makeTableRows();
                  } else {
                    this.sections = [];
                  }
                  if(this.permissions.getFiles){
                    this.getAllFiles();
                  }
                  this.scrollToSlide();
                }
              }).catch(() => {
              });
            }
      },
      scrollToSlide(){
        var self = this;
        setTimeout(function(){
          if(self.permissions.agenda_back && self.permissions.agenda_back.length>0){
            var d = document.getElementById(self.permissions.agenda_back);
            self.permissions.agenda_back = "";
            if(d){
              d.scrollIntoView({block: "center"});
            }
          }
        })
      },
      populateVariables(callback) {
        if (this.organization_id == 0) {
          this.$http.post("/api/organization/slide/get/variables", {joint: false}).then((response) => {
            if (response.data.success) {
              this.variable_map = response.data.variable_map;
            }
            callback();
          }).catch(() => {
            callback();
          });
        } else {
          callback();
        }
      },
      disabledSlidesChange() {
        //this.permissions.showDisabledSlides = !this.permissions.showDisabledSlides;
        if (this.permissions.sectionFilterId > 0) {
          this.getSlides();
        } else {
          this.getAllSlides();
        }
      },
      getSections() {
        this.$http.post("/api/meeting/sections/select", { id: this.organization_id }).then((response) => {
          if (response.data.success) {
            for(var i =0; i<response.data.sections.length;i++){
                    if(!response.data.sections[i].name || response.data.sections[i].name.length==0){
                      response.data.sections[i].name = this.$t('global.no_translation');
                    }
                }
            this.section_select = response.data.sections
            this.section_select.unshift({ id: 0, name: this.$t('slides.all_sections')})
          }
        }).catch(() => {
        });
      },
          newSlide() {
            router.push("/admin/slide/"+this.permissions.sectionFilterId+"/0");
          },
          editSlide(item) {
            if (!item.filler && this.organization_id == 0 && item.allow_editing!=2) {
              this.permissions.agenda_back = "slide_"+item.id+"_"+item.section_id;
              router.push("/admin/slide/" + item.section_id + "/" + item.id );
            }
      },
      editSection(item) {
        if (this.organization_id == 0) {
          this.permissions.agenda_back = "section_"+item.section_id;
          router.push("/admin/section/" + item.section_id);
        }
      },
      startDragSlide(event) {
        event.item.style.color = "#ff0000";
        this.moving = true;
      },
          
      endDragSlide(event) {
        this.moving = false;
            var ranks = [];
            var j = 0;
            var lastParent = this.permissions.organization.is_parent? "" : String.fromCharCode(31);
            var movedSid = event.clone.id.split("_").slice(-1)[0];
            var movedId = event.clone.id.split("_").slice(-2)[0];
            event.item.style.color = "";
            var toSid = event.to.children.length > 0 ? event.to.children[0].id.split("_").slice(-1)[0] : 0;
            var sIndex = 0;
            for (var i = 0; i < this.sections.length; i++) {
              if (this.sections[i].section_id == toSid) {
                sIndex = i;
                break;
              }
            }
            if(this.permissions.sectionFilterId==0){
              this.makeTableRows();
            }
            if (movedSid != toSid) {
              this.$http.post("/api/organization/slides/change/section", { section_id: toSid, id: movedId }).then((response) => {
                if (response.data.success) {
                    for (var i = 0; i < this.sections[sIndex].slides.length; i++) {
                      if (this.sections[sIndex].slides[i].isParent == 0) {
                        ranks.push({ id: this.sections[sIndex].slides[i].id, rank: lastParent + String.fromCharCode(Math.max(Math.min((j + 33), 126), 33)) });
                        j++;
                      } else {
                        lastParent = this.sections[sIndex].slides[i].rank;
                        j = 0;
                      }
                    }
                  this.$http.post("/api/organization/slides/rank", { section_id: toSid, ranks: ranks }).then(() => {
                  }).catch(() => {
                  });
                }
              }).catch(() => {
              });
            } else {
              for (i = 0; i < this.sections[sIndex].slides.length; i++) {
                if (this.sections[sIndex].slides[i].isParent == 0) {
                  ranks.push({ id: this.sections[sIndex].slides[i].id, rank: lastParent + String.fromCharCode(Math.max(Math.min((j + 33), 126), 33)) });
                  j++;
                } else {
                  lastParent = this.sections[sIndex].slides[i].rank;
                  j = 0;
                }
              }
              this.$http.post("/api/organization/slides/rank", { section_id: toSid, ranks: ranks }).then(() => {
              }).catch(() => {
              });
            }
          },

          populateSlideNameVariables(section){
            var self = this;
            for (var j = 0; j < section.slides.length; j++) {
              this.variable_map.forEach(function (item) {
                if(item.id == "{Current_Time}"){
                  item.value = self.$d(new Date(), 'time12') ;
                }else if(item.id == "{Current_Date}"){
                  item.value = self.$d(new Date(), 'short') ;
                }
                if (section.slides[j].name.indexOf(item.id) >= 0) {
                  section.slides[j].name = section.slides[j].name.split(item.id).join(item.value);
                }
                
              });
            }
          },
      makeTableRows() {
        var t = 0;
        for (var i = 0; i < this.sections.length; i++) {
          this.$set(this.sections[i], 'hover', false);
          if (!this.sections[i].disabled) {
            var datetime = new Date(new Date().toDateString() + " " + this.start + " (EST)");
            datetime.setSeconds(datetime.getSeconds() + t);
            this.$set(this.sections[i], 'start_time', datetime.toLocaleTimeString());
            t += this.sections[i].target_duration;
          }
          if (this.sections[i].slides.length == 0) {
            this.sections[i].slides.push({
              id: 0, name: "--", pauseSeconds: 0, then_action: -1, replay_times: -1,
              disabled: false, section_id: 0, filler: true, isParent: this.sections[i].isParent
            });
          }
          if (this.organization_id == 0) {
            this.populateSlideNameVariables(this.sections[i]);
            
          }
          if (this.sections[i].slides.length == 2) {
            if (this.sections[i].slides[0].id == 0) {
              this.sections[i].slides = [this.sections[i].slides[1]];
            }
            if (this.sections[i].slides.length == 2 && this.sections[i].slides[1].id == 0) {
              this.sections[i].slides = [this.sections[i].slides[0]];
            }
          }
          /*if (i == 0) {
            this.$set(this.sections[i], 'tbody', 1);
          } else if (this.sections[i - 1].slides.length % 2 == 0 && this.sections[i - 1].tbody == 0) {
            this.$set(this.sections[i], 'tbody', 0);
          } else if (this.sections[i - 1].slides.length % 2 == 0 && this.sections[i - 1].tbody == 1) {
            this.$set(this.sections[i], 'tbody', 1);
          } else if (this.sections[i - 1].slides.length % 2 == 1 && this.sections[i - 1].tbody == 0) {
            this.$set(this.sections[i], 'tbody', 1);
          } else if (this.sections[i - 1].slides.length % 2 == 1 && this.sections[i - 1].tbody == 1) {
            this.$set(this.sections[i], 'tbody', 0);
          }*/
        }
        var h = Math.floor(t / 3600);
        var m = Math.floor((t % 3600) / 60);
        var s = (t % 60);
        this.totalTime = h + ":" + (m < 10 ? "0" : "") + m + ":" + (s < 10 ? "0" : "") + s;
      },
      showCopyPopup() {
        this.showAlert = false;
        this.showReplace = false;
        this.showCopyModal = true;
        this.copySid = 0;
        this.copyId = 0;
       
        if(this.permissions.organization.is_parent  || this.permissions.organization.rst){
          this.getRegions();
        }else{
           this.getCopySections();
        }
        this.copy_options = [{ id: "own", name: this.permissions.organization.name }];
        if(!this.permissions.organization.is_parent && !this.permissions.organization.rst){
          this.copy_options.push({ id: "default", name: this.permissions.organization.parent_name });
        }else{
          this.copy_options.push({ id: "children", name: this.$t('slides.children_option')  });
        }
      },
      showCopyPopupSection() {
        this.showCopyModalSection = true;
        this.copySid = 0;
        if(this.permissions.organization.is_parent || this.permissions.organization.rst){
          this.getRegions();
        }else{
           this.getCopySections();
        }
        this.copy_options = [{ id: "own", name: this.permissions.organization.name }];
        if(!this.permissions.organization.is_parent && !this.permissions.organization.rst){
          this.copy_options.push({ id: "default", name: this.permissions.organization.parent_name });
        }else{
          this.copy_options.push({ id: "children", name: this.$t('slides.children_option')  });
        }
      },
      getCopySlides() {
        if(!this.copySid || this.copySid == 0){
          return;
        }
        var par = {  section_id: this.copySid };
        var url = "/api/meeting/slides/select/bysect";
        if((this.permissions.organization.is_parent || this.permissions.organization.rst) && this.copyOid >0){
          par = { org: this.copyOid, section_id: this.copySid };
          url = "/api/meeting/slides/select/bysectorg";
        }
        this.$http.post(url, par).then((response) => {
          if (response.data.success){
            for(var i =0; i<response.data.slides.length;i++){
                  if(!response.data.slides[i].name || response.data.slides[i].name.length==0){
                    response.data.slides[i].name = this.$t('global.no_translation');
                  }
              }
            this.copy_slides = response.data.slides;
          }
        }).catch(() => {
        });
      },
      getCopySections() {
        var par = { };
        var url = "/api/meeting/sections/select";
        if((this.permissions.organization.is_parent || this.permissions.organization.rst) && this.copyOid >0){
          par = {org : this.copyCid>0? this.copyCid : this.copyOid};
          url = "/api/meeting/sections/select/byorg";
        }
        this.$http.post(url, par).then((response) => {
          if (response.data.success) {
            for(var i =0; i<response.data.sections.length;i++){
                  if(!response.data.sections[i].name || response.data.sections[i].name.length==0){
                    response.data.sections[i].name = this.$t('global.no_translation');
                  }
              }
            this.copy_sections = response.data.sections
            if (this.copy_sections.length > 0) {
              this.copySid = this.copy_sections[0].section_id;
            }
          }
        }).catch(() => {
        });
      },
      sectionUp(section) {
        var changed = false;
        for (var i = 0; i < this.sections.length; i++) {
          if (i != 0 && this.sections[i] == section) {
            var temp = this.sections[i - 1];
            this.sections[i - 1] = section;
            this.sections[i] = temp;
            changed = true;
            break;
          }
        }
        if (changed) {
          this.rankSections();
        }
      },
      sectionDown(section) {
        var changed = false;
        for (var i = 0; i < this.sections.length; i++) {
          if (i != this.sections.length - 1 && this.sections[i] == section) {
            var temp = this.sections[i + 1];
            this.sections[i + 1] = section;
            this.sections[i] = temp;
            changed = true;
            break;
          }
        }
        if (changed) {
          this.rankSections();
        }
      },
      rankSections() {
        var ranks = [];
            var lastParent = this.permissions.organization.is_parent? "" : String.fromCharCode(31);
            var j = 0;
            var t = 0;
            for (var i = 0; i < this.sections.length; i++) {
              if (!this.sections[i].disabled) {
                var datetime = new Date(new Date().toDateString() + " " + this.start + " (EST)");
                datetime.setSeconds(datetime.getSeconds() + t);
                this.$set(this.sections[i], 'start_time', datetime.toLocaleTimeString());
                t += this.sections[i].target_duration;
              }
              if (this.sections[i].isParent == 0) {
                ranks.push({ id: this.sections[i].section_id, rank: lastParent + String.fromCharCode(Math.max(Math.min((j + 33), 126), 33)) });
                j++;
              } else {
                lastParent = this.sections[i].rank;
                j = 0;
              }
            }

        this.$http.post("/api/organization/sections/rank", { ranks: ranks }).then(() => {
          this.getSections();
        }).catch(() => {
        });
      },
      getSectionFiles(section_id){
        this.$http.post("/api/organization/files/section", {section_id: section_id, id: this.organization_id}).then((response) => {
          if(response.data.success){
            this.setFiles(response.data);
          }
        }).catch((errors) => {
          console.log(errors);
        });
      },
      getAllFiles() {
        this.$http.post("/api/organization/files/all", {id: this.organization_id}).then((response) => {
          if(response.data.success){
            this.setFiles(response.data);
          }
        }).catch((errors) => {
          console.log(errors);
        });
      },
      goToFileControl(hash, type, url){
      if(type == 2){
        url = (url.startsWith("http") ? "" : "http://") + url;
        window.open(url, "_blank");
      }else if (type == 1){
        window.open("/#/filecontrol/"+hash, "_blank");
      }
    },
      getRegions() {
        this.$http.post("/api/regions/get/all", {}).then((response) => {
          if(response.data.success){
            this.copy_organizations = response.data.organizations;
            this.$set(this, 'copyOid', this.permissions.organization.id);
          }
        }).catch((errors) => {
          console.log(errors);
        });
      },
      getChapters(){
        this.$http.post("/api/chapters/get/all", {id: this.copyOid}).then((response) => {
          if(response.data.success){
            this.copy_chapters = response.data.organizations;
            //this.copy_chapters.unshift({id: 0, name: this.$t('slides.optional_chapter')});
          }
        }).catch((errors) => {
          console.log(errors);
        });
      },
      setFiles(d){
        for(var i=0; i<this.sections.length; i++){
          this.$set(this.sections[i], 'files', []);
          for(var y=0; y<d.sectionFiles.length; y++){
            if(this.sections[i].id === d.sectionFiles[y].section_id){
              d.sectionFiles[y].num = this.sections[i].files.length+1;
              this.sections[i].files.push(d.sectionFiles[y]);
            }
          }
          for(var j=0; j<this.sections[i].slides.length; j++){
            this.$set(this.sections[i].slides[j], 'files', []);
            for(var x=0;x< d.slideFiles.length; x++){
              if(this.sections[i].slides[j].id === d.slideFiles[x].slide_id){
                d.slideFiles[x].num = this.sections[i].slides[j].files.length+1;
                this.sections[i].slides[j].files.push(d.slideFiles[x]);
              }
            }
            if(this.sections[i].slides[j].speakerNum>0){
              this.$set(this.sections[i].slides[j], 'speaker', d.speakerFiles[this.sections[i].slides[j].speakerNum-1]);
            }
          }
        }
      },
      openLink(l){
        window.open(l);
      },
      showBio(speaker){
        this.current_speaker = speaker;
        this.showBioModal = true;
      },
      replaceChars(text){
        return text.split('…').join('...').split('’').join('\'').split('\n').join('<br/>');
      },
        openDialog() {
        document.getElementById('fileid').click();
      },
      onFileChange(e){
        var files = e.target.files || e.dataTransfer.files;
            if (!files.length || !this.checkFileSize(files[0]))
              return;
        this.$set(this.importObject, 'file', files[0]);
      },
      closePPImport(){
        this.showPPTModal= false;
        this.showPPTImagesSuccess = false;
        this.importObject={pages: "", allPages: 1, file: null};
      },
      importPPAsImages(pptJson, pages, rankIndex,lastParent){
          let formData = new FormData();
          formData.append("file", this.importObject.file);
          formData.append("pages", Array.from(pages));
          formData.append("allPages", this.importObject.allPages);

          this.$http.post("/api/slides/import/asimages", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }).then((response) => {
            if(response.data.success){
              var filename = response.data.filename;
              var iPages = Array.from(pages);
              var slidePromises = []
              if(this.importObject.allPages==1){
                for(var i=0; i<pptJson.slides.length; i++){
                  var rank = lastParent + String.fromCharCode(Math.max(Math.min((rankIndex + 33), 126), 33));
                  rankIndex++;
                  var slideName = "Slide "+(i+1);
                  slidePromises.push(this.createSlide(slideName, rank, [], [], "/userImages/"+filename+"-"+i+".png"));
                }
              }else{
                for(var i=0; i<iPages.length; i++){
                  var rank = lastParent + String.fromCharCode(Math.max(Math.min((rankIndex + 33), 126), 33));
                  rankIndex++;
                  var slideName = "Slide "+(iPages[i]+1);
                  slidePromises.push(this.createSlide(slideName, rank, [], [], "/userImages/"+filename+"-"+iPages[i]+".png"));
                }
              }
              
              
              var self = this;
              Promise.all(slidePromises).then(() => {
                self.showPPTImagesSuccess = true;
                self.showImportAlert = false;
                if (self.permissions.sectionFilterId > 0) {
                  self.getSlides();
                } else {
                  self.getAllSlides();
                }
              });
            }
          }).catch((e) => {console.log(e);});
      },
      createSlide(name,rank, graphics, texts, background){
        var timer= {id: 0, circular:0, circular_start:0, circular_end:0,default_option: 0,delay_time:0, time_default: 1, active:false,dragWidth:5, continuous: 0, count_direction: 0, disabled: 1, type: 0, x_pos: 0, y_pos: 0, x_px: 0, y_px: 0, width: 5, height: 5, font: "Arial", color: "#000000", back_color: "#ffffff", progressive: 0, num_plays: 1, sound_file: "", auto_start: 1, max_time: 1, secs: 1, mins: 0, direction: 0, countdown_change: 0, alter_colour: "#ffffff", alter_time: 0 };
        var goal= {id: 0, goal_id: 0, disabled: 1,thermo_x:0,thermo_y:0,history_x:0,history_y:0,text_x:0,text_y:0, color: '#000000',description_color: '#000000', title_color: '#000000', notes_color: '#000000', height: 5, font: 'Arial', text_width: 33, history_font_size: 2, thermo: {x_pos: 0, y_pos: 0}, history: {x_pos: 0, y_pos: 0}, text: {x_pos: 0, y_pos: 0, width: 33}, description_size: 2, notes_size: 2, heading_colour: "#ffffff", table_colour: "#000000", heading_back_colour: '#1b629c', table_back_colour: '#ffffff', max_table_rows: 5};
        var memberDescription= {
            slide_id: 0, id:0, type: -1, member_id: 0, role_id: "0", name_font: "Arial", name_height: 5, name_color: "#2c3e50", name_weight: 600,show_company: 1, show_category: 1, selection: 1,circular: 0,
            company_font: "Arial", company_height: 5, company_color: "#2c3e50", company_weight: 300, category_font: "Arial", x_pos: 45, y_pos: 30, width:20,height:20, x_px: 0, y_px: 0,
            category_height: 5, category_color: "#2c3e50", category_weight: 300, name_back_color: "#ffffff", num_rows_x: 1, num_rows_y: 1, speaker_num: 1, company_or_role: 0, first_name: 0,
            next_x_pos: 40, next_y_pos: 70,next_height: 10, next_color: '#ffffff', next_font: 'Arial', alignment: 'left', graphic_x_pos: 0, graphic_y_pos: 0,graphic_height:100, graphic_width:34.633125,alternate_slide_id: 0,
            email_font:'Arial',email_height: 5,email_color: "#ffffff",email_weight: 300,show_email: 1,website_font: 'Arial',website_height: 5,website_color: "#ffffff",website_weight: 300,show_website: 1,
            phone_font: 'Arial',phone_height: 5,phone_color: "#ffffff",phone_weight: 300,show_phone: 1,next_number: 1, show_renewing_date: 0,best_weekly: 0, show_photo: 1,
            job_title_font: 'Arial',job_title_height: 5,job_title_color: "#ffffff",job_title_weight: 300,job_title_show: 0, show_accent: 1, multi_vertical: 1
          };
        var slide= {id: 0, rank: rank, section_id: this.permissions.sectionFilterId, meeting_type: 0, language: '', show_recognition: 1, child_edit: 1, allow_editing: 1, name: name,  trans_in: 0, trans_out: 0, pause: 1, pauseSeconds: 5, replay: 0, replay_times: 0, then_action: 3, next_seconds: 0, back_colour: "#ffffff", back_colour_from: "#ffffff", back_colour_to: "#ffffff", back_grad_type:0, photo: background, back_type: background==null? 0 : 3, disabled:0};
        var self = this;
        return new Promise((resolve) => {
           self.$http.post("/api/organization/slide/update", {
            slide: slide, texts: texts, graphics: graphics,
            memberDescription: memberDescription, timer: timer, goal: goal
          }).then(() => {
            resolve();
          }).catch(() => {resolve();});
        });
       
      },

      
      importPP: async function(){
        
        this.showImportAlert = true;
        var rankIndex = 0;
        var lastParent = this.permissions.organization.is_parent? "" : String.fromCharCode(31);
        var toSid = this.permissions.sectionFilterId;
        var sIndex = 0;
        for (var i = 0; i < this.sections.length; i++) { if (this.sections[i].section_id == toSid) { sIndex = i; break; } }
        for (i = 0; i < this.sections[sIndex].slides.length; i++) {
          if (this.sections[sIndex].slides[i].isParent == 0) {rankIndex=this.sections[sIndex].slides[i].rank.charCodeAt(this.sections[sIndex].slides[i].rank.length-1);} else {lastParent = this.sections[sIndex].slides[i].rank;rankIndex = 0;}
        }
        rankIndex++;
        
        
        var ps = this.importObject.pages.split(",");
        var pages = new Set();
        for(var j=0; j<ps.length; j++){
          if(ps[j].includes("-")){
            var range = ps[j].split("-");
            if(range.length==2){
              var a = Math.max(parseInt(range[0]),0);
              var b = parseInt(range[1]);
              if(a<=b){
                for(var x = a; x<=b; x++){
                  pages.add(x-1)
                }
              }
              
            }
          }else{
            pages.add(parseInt(ps[j])-1)
          }
        }
        const pptJson = await pttxparse(this.importObject.file, { flattenGroup: true });
        if(this.importAsObjects==0){
          this.importPPAsImages(pptJson, pages, rankIndex, lastParent);
          return;
        }
        
        var pageSize = pptJson.pageSize;
        var slidePromises = [];
        var z = 1;
        for(var i=0; i<pptJson.slides.length; i++){
          var slideName = "Slide "+(i+1);
          var titleSize = 0;
          
          if(this.importObject.allPages==1 || pages.has(i)){
            var texts= [];
            var graphics=[];
            for(var j=0; j<pptJson.slides[i].pageElements.length; j++){
              var el = pptJson.slides[i].pageElements[j];
              var pos = el.position;
              var textSnippet = "";
              if(el.image && el.image.contentUrl){
                var du = await this.loadFile(el.image.contentUrl, el.image.cropProperties);
                if(du != null){
                  graphics.push({id: 0, rotation: el.rotate, fit: 1, photo: du, special: '', x_pos: (pos.x.value/pageSize.width.value)*100,  y_pos: (pos.y.value/pageSize.height.value)*100, z_pos:z++,width: (el.size.width.value/pageSize.width.value)*100, height: (el.size.height.value/pageSize.height.value)*100, slide_id: 0});
                }
              }else if(el.fill && el.fill.pictureFill){
                var du = await this.loadFile(el.fill.pictureFill.contentUrl, el.image.cropProperties);
                if(du != null){
                  graphics.push({ id: 0, rotation: el.rotate, fit: 1, photo: du, special: '', x_pos: (pos.x.value/pageSize.width.value)*100,  y_pos: (pos.y.value/pageSize.height.value)*100, z_pos:z++,width: (el.size.width.value/pageSize.width.value)*100, height: (el.size.height.value/pageSize.height.value)*100, slide_id: 0});
                }
              }else if(el.shape && el.shape.text && el.shape.text.paragraphs && el.shape.text.paragraphs[0] != undefined && el.shape.text.paragraphs[0].textSpans != undefined){
                var totalText = "";
                var firstColour = "";
                var fontSize = 0;
                var font= "";
                var center= "";
                var lineHeight= 0;
                var lineHeightType = 0;
                for(var p=0; p<el.shape.text.paragraphs.length; p++){
                    if(lineHeight==0 && el.shape.text.paragraphs[p].paragraphProperty.lineSpacing){
                      lineHeightType= el.shape.text.paragraphs[p].paragraphProperty.lineSpacing.unit=="PX"? 1 : 0;
                      lineHeight = lineHeightType==0? 1.3*el.shape.text.paragraphs[p].paragraphProperty.lineSpacing.value*el.shape.text.paragraphs[p].paragraphProperty.lineSpacing.value : el.shape.text.paragraphs[p].paragraphProperty.lineSpacing.value;
                    }
                    if(center=="" && el.shape.text.paragraphs[p].paragraphProperty.alignment){
                      center = el.shape.text.paragraphs[p].paragraphProperty.alignment;
                    }
                   var ts = el.shape.text.paragraphs[p].textSpans;
                   var paraText="";
                    for(var k=0; k <ts.length; k++){
                      if(ts[k].textRun && ts[k].textRun.content && ts[k].textRun.content != ""){
                        if(firstColour.length==0 && ts[k].textRun.style.foregroundColor){
                          firstColour = ts[k].textRun.style.foregroundColor;
                          font =  ts[k].textRun.style.fontFamily;
                        }
                        if(fontSize==0 && ts[k].textRun.style.fontSize){
                          fontSize = ts[k].textRun.style.fontSize.value;
                        }
                        textSnippet += this.replaceChars(ts[k].textRun.content);
                        
                        if(ts[k].textRun.style && ts[k].textRun.style.italic){
                          paraText += "<i>";
                        }
                        if(ts[k].textRun.style && ts[k].textRun.style.bold){
                          paraText += "<strong>";
                        }
                        if(ts[k].textRun.style && ts[k].textRun.style.foregroundColor && ts[k].textRun.style.foregroundColor!= firstColour){
                          paraText += "<span style='color:"+ts[k].textRun.style.foregroundColor +";'>"+this.replaceChars(ts[k].textRun.content)+"</span>";
                        }else{
                          paraText += this.replaceChars(ts[k].textRun.content);
                        }
                        if(ts[k].textRun.style && ts[k].textRun.style.italic){
                          paraText += "</i>";
                        }
                        if(ts[k].textRun.style && ts[k].textRun.style.bold){
                          paraText += "</strong>";
                        }
                      }
                      
                  }
                  if(paraText.trim().length>0){
                    totalText+="<p>"+paraText+"</p>";
                  }
                  /*
                    gradient back
                    special font
                    tables
                  */
              }
                
              if(totalText.trim().length>0 || (el.fill != undefined && el.fill.fillType=="SOLID_FILL")){
                var fill = 'rgba(0,0,0,0)';
                if(el.fill.fillType=="SOLID_FILL"){
                  fill = el.fill.solidFill;
                }else if (el.fill.fillType=="GRADIENT_FILL" && el.fill.gradientFill.colorStops.length>0){// 
                  /*var angle = el.fill.gradientFill.angel;
                  fill = "linear-gradient("+angle+"deg"
                  for(var c=0; c<el.fill.gradientFill.colorStops.length; c++){
                    fill+= ","+el.fill.gradientFill.colorStops[c].color+" "+el.fill.gradientFill.colorStops[c].position*100+"%";
                  }
                  fill +=")";*/
                  fill = el.fill.gradientFill.colorStops[0].color;
                }
                    texts.push({
                      id: 0, size: (fontSize/pageSize.height.value)*100, text: totalText, language: this.$i18n.locale, x_pos: (pos.x.value/pageSize.width.value)*100, 
                      y_pos: (pos.y.value/pageSize.height.value)*100, z_pos:z++, width: (el.size.width.value/pageSize.width.value)*100, 
                      height: (el.size.height.value/pageSize.height.value)*100, slide_id: 0, font: this.fontOptions.has(font)? font : "Arial", vertical_align: 1,
                      color: firstColour, weight: 300, alignment: el.shape.text.bodyProperty.anchor=="ctr" || center=="CENTER"? "center" : "left", spacing: lineHeight!=0? (lineHeightType==0? lineHeight : (lineHeight/fontSize)): 1.3, special: '', 
                      fit: 0, back_color: fill, rotation: el.rotate-(el.flipV? 180 : 0), border_color: el.outline.outlineFill.fillType=="SOLID_FILL"? el.outline.outlineFill.solidFill : 'rgba(0,0,0,0)', ellipse: el.shape.shapeType=="ellipse"? 1 : 0})
                    }
                    var t = texts[texts.length-1];
                    if(t && t.y_pos<15 && t.size>5 && t.size>titleSize && textSnippet != ""){
                      var sp = textSnippet.split(".");
                      slideName = (sp[0]+(sp.length>1 && sp[0].length<5? sp[1] : "")).trim().substring(0,254);
                      titleSize = t.size;
                    }
                    
                }
            }
            
            var rank = lastParent + String.fromCharCode(Math.max(Math.min((rankIndex + 33), 126), 33));
            rankIndex++;
            slidePromises.push(this.createSlide(slideName, rank, graphics, texts, null));
            
          }
        }
        var self = this;
        Promise.all(slidePromises).then(() => {
          self.showPPTModal = false;
          self.showImportAlert = false;
          if (self.permissions.sectionFilterId > 0) {
            self.getSlides();
          } else {
            self.getAllSlides();
          }
        });
      },
       loadFile: async function(url, cropData){
          let blob = await fetch(url).then(r => r.blob());//.then(blobFile => new File([blobFile], "tempFile", { type: "image/png" }));
          return await this.readFileAsDataURL(blob, cropData);
        },
        readFileAsDataURL: async function (file, cropData) {
          let result_base64 = await new Promise((resolve) => {
              let fileReader = new FileReader();
              fileReader.onload = (e) => resolve(fileReader.result);
              fileReader.readAsDataURL(file);
          });
          if(cropData && (cropData.bottomOffset.value+cropData.leftOffset.value+cropData.rightOffset.value+cropData.topOffset.value)>0){
            let match = result_base64[0].match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/);
            let mimeType = match && match.length>0? match[0] : "image/png";
            return await this.cropDataUrl(result_base64, mimeType, cropData);
          }else{
            return result_base64;
          }
      },
      cropDataUrl: async function(filedata,imagetype, cropData){
        let result_base64 = await new Promise((resolve) => {
          var img = document.createElement("img");
          img.src = filedata;
          
          img.onload = function(){
            var width = img.width;
            var height = img.height;
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");
            
            if(cropData.leftOffset.unit=="PERCENTAGE"){
              canvas.width = width-(cropData.leftOffset.value/100)*width-(cropData.rightOffset.value/100)*width;
              canvas.height = height-(cropData.topOffset.value/100)*height-(cropData.bottomOffset.value/100)*height;
              ctx.drawImage(img, (cropData.leftOffset.value/100)*width, (cropData.topOffset.value/100)*height, width-(cropData.leftOffset.value/100)*width-(cropData.rightOffset.value/100)*width, height-(cropData.topOffset.value/100)*height-(cropData.bottomOffset.value/100)*height, 0,0, width-(cropData.leftOffset.value/100)*width-(cropData.rightOffset.value/100)*width, height-(cropData.topOffset.value/100)*height-(cropData.bottomOffset.value/100)*height);
            }else{
              canvas.width =  width-cropData.leftOffset.value-cropData.rightOffset.value;
              canvas.height = height-cropData.topOffset.vaue-cropData.bottomOffset.value;
              ctx.drawImage(img, cropData.leftOffset.value, cropData.topOffset.value, width-cropData.leftOffset.value-cropData.rightOffset.value, height-cropData.topOffset.vaue-cropData.bottomOffset.value,0,0, width-cropData.leftOffset.value-cropData.rightOffset.value, height-cropData.topOffset.vaue-cropData.bottomOffset.value);
            }
            var dataurl = canvas.toDataURL(imagetype);
            resolve(dataurl);
          }
        });
        return result_base64;
      }
    },
    destroyed() {
      
      this.removeClass(document.getElementById("menu-agenda"), "active-menu");
      this.removeClass(document.getElementById("menu-meeting"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-agenda"), "active-menu");
      this.addClass(document.getElementById("menu-meeting"), "active-menu");
      var self = this;
      this.organization_id = this.$route.params.id ? this.$route.params.id : 0;
      
      this.populateVariables(function () {
        self.getSections();
        if (self.permissions.sectionFilterId > 0) {
          self.getSlides();
        } else {
          self.getAllSlides();
        }
      });
      //this.getSlides();
    }
    }
</script>
