<template>
  <div>
    <div class="rel-grid-container">
      <div id="top-div"></div>
      <div>
        <b-table class="table-hover-new" style="cursor: pointer;" small bordered :items="announcements" :fields="fields" v-on:row-clicked="editAnnouncement" :tbody-tr-class="rowClass">
          <template v-slot:cell(message)="data">
            <pre class="pre-format">{{data.item.message}}</pre>
          </template>
        </b-table>
        <div class="submit-box-2">
          <b-button v-if="permissions.access" class="submit-button4" v-on:click="newAnnouncement" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
        </div>
      </div>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
  
</style>
<script>
    
  import router from "../router"
    export default {
    name: "Announcements",
     watch:{
      '$i18n.locale': {
        handler: function () {
           this.fields[0].label = this.$t('announcements.date_header');
            this.fields[1].label = this.$t('announcements.title_header');
            this.fields[2].label =  this.$t('announcements.description_header');
        },
        deep: true
      }
      },
      data() {
        return {
          fields: [
             { key: 'day',
              label: this.$t('announcements.date_header'),
              thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
            },
             { key: 'title',
              label: this.$t('announcements.title_header'),
              thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
            },
            { key: 'message',
              label: this.$t('announcements.description_header'),
              thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
            }
          ],
          announcements: []
        }
      },
    methods: {
      rowClass(item) {
        if (!item) return
        if (item.disabled) return 'disabledClass'
      },
            getAnnouncements() {
              this.$http.post("/api/organization/announcements", {}).then((response) => {
                if (response.data.success) {
                  this.announcements = response.data.announcements;
                }
              }).catch(() => {
              });
          },
          newAnnouncement() {
            if (this.permissions.access) {
              router.push("/admin/announcement/0");
            }
          },
          editAnnouncement(item) {
            if (this.permissions.access) {
              router.push("/admin/announcement/" + item.id);
            }
          }
    },
     destroyed() {
      this.removeClass(document.getElementById("menu-announce"), "active-menu");
      this.removeClass(document.getElementById("menu-admin"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-announce"), "active-menu");
      this.addClass(document.getElementById("menu-admin"), "active-menu");
      this.getAnnouncements();
    }
    }
</script>
