<template>
  <div> 
    <div class="rel-grid-container" >
      <div id="top-div"></div>
      <div id="centreDiv" style="max-width: fit-content;">  
        <Tip :tipNumber="14"></Tip>
        <div v-if="onboard && showAlert">
          <b-alert variant="danger"
                  dismissible
                  :show="showAlert"
                  @dismissed="showAlert=false">
            {{alertText}}
          </b-alert>
        </div>
        
        <form v-on:submit.prevent="update">
          <span class="form-box-title">{{$t('general.identification_text')}}</span>
          <div class="form-box" style="grid-template-columns: 30% 70%;position:relative;">
            
            <span class="form-box-name">{{$t('general.name_text')}}</span><b-form-input :state="organization_state.name" v-model="organization.name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;" class="form-box-input" :disabled="!permissions.organization.is_parent" required></b-form-input>
            <span v-if="permissions.organization.official" class="form-box-name">{{$t('general.website_text')}}</span><b-form-input v-if="permissions.organization.official" v-model="organization.website" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
            <span class="form-box-name">{{$t('general.qr_code')}}</span><b-form-input  v-model="organization.apply_url" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')"  class="form-box-input"></b-form-input>
            <span class="form-box-name">{{$t('general.language_text')}}</span><b-form-select v-model="organization.language" :options="languageOptions" class="mb-3 form-box-input" value-field="abbrev" text-field="name"/>
          </div>

          <span v-if="!onboard" class="form-box-title">{{$t('general.meeting_location')}}</span>
          <div class="form-box" v-if="!onboard" style="grid-template-columns: 25% 33% 42%;">
            <span class="form-box-name">{{$t('general.location_text')}}</span><b-form-input v-model="organization.location" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
            <span class="form-box-name">{{$t('general.address_text')}}</span><b-form-input v-model="organization.address" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
            <span class="form-box-name">{{$t('general.city_text')}}</span><b-form-input v-model="organization.city" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" ></b-form-input>
            <div id="postal-div"><span class="form-box-name">{{$t('general.postal_code')}}</span><b-form-input v-model="organization.postal_code" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input></div>
            <span class="form-box-name">{{$t('general.country_text')}}</span><country-select class="custom-select form-box-input" v-model="organization.country"  :country="organization.country" />
            <span class="form-box-name" v-if="organization.country!=''">{{$t('general.region_text')}}</span><region-select v-if="organization.country!=''" class="custom-select form-box-input" v-model="organization.region"  :country="organization.country" :region="organization.region" />
          </div>

          <span class="form-box-title">{{$t('general.meeting_time')}}</span>
          <div id="times-anchor" class="form-box" style="grid-template-columns: repeat(3,1fr);">
            <SetupTip style="position:absolute; left: -40px;" :tipNumber="2"/>
            <!--<span class="form-box-name">{{$t('general.recurring_interval')}}</span>
            <b-form-select v-model="organization.meet_interval_days" :options="intervalOptions" class="mb-3 form-box-input" disabled />-->
            <span v-if="permissions.organization.official && !isCorporateConnections" class="form-box-name">{{$t('general.day_of_week')}}</span>
            <b-form-select v-if="permissions.organization.official && !isCorporateConnections" :state="organization_state.meet_day_of_week" v-model="organization.meet_day_of_week" :options="dayOptions" class="mb-3 form-box-input" required />
            <span class="form-box-name">{{$t('general.time_zone')}}</span>
            <b-form-select v-model="organization.meet_timezone" :options="timezoneOptions" value-field="value" text-field="name" class="mb-3 form-box-input" />
            <span v-if="permissions.organization.official && !isCorporateConnections" class="form-box-name">{{$t('general.meeting_start_networking')}}</span>
            <b-form-timepicker v-if="permissions.organization.official && !isCorporateConnections" minutes-step="5" :label-no-time-selected="$t('global.no_time')"  class="form-box-input time-picker" :state="organization_state.meet_pre_start" v-model="organization.meet_pre_start" :locale="$i18n.locale" required></b-form-timepicker>
            <span v-if="permissions.organization.official && !isCorporateConnections" class="form-box-name">{{$t('general.meeting_start_time')}}</span>
            <b-form-timepicker v-if="permissions.organization.official && !isCorporateConnections" minutes-step="5" :label-no-time-selected="$t('global.no_time')" class="form-box-input time-picker" :state="organization_state.meet_start" v-model="organization.meet_start" :locale="$i18n.locale" required></b-form-timepicker>
            <span v-if="permissions.organization.official && !isCorporateConnections" class="form-box-name">{{$t('general.end_time')}}</span>
            <b-form-timepicker v-if="permissions.organization.official && !isCorporateConnections" minutes-step="5" :label-no-time-selected="$t('global.no_time')" class="form-box-input time-picker" :state="organization_state.meet_end"  v-model="organization.meet_end" :locale="$i18n.locale"></b-form-timepicker>
            
            <span class="form-box-name">{{$t('general.weekly_presentation')}}</span>
            <div class="time-box">
              <span class="form-box-name">{{$t('general.min_text')}}</span><b-form-input v-model="organization.weekly_presentation_min" type="number" style="width:150px;" />
            </div>
            <div class="time-box">
              <span class="form-box-name">{{$t('general.sec_text')}}</span><b-form-input v-model="organization.weekly_presentation_sec" type="number" style="width:150px;" />
            </div>
            <span class="form-box-name">{{$t('general.speaker_presentation')}}</span>
            <div class="time-box">
              <span class="form-box-name">{{$t('general.min_text')}}</span><b-form-input v-model="organization.speaker_presentation_min" type="number" style="width:150px;" />
            </div>
            <div class="time-box">
              <span class="form-box-name">{{$t('general.sec_text')}}</span>
              <b-form-input v-model="organization.speaker_presentation_sec" type="number" style="width:150px;" />
            </div>
            <span v-if="permissions.organization.official" class="form-box-name">{{$t('general.referral_round')}}</span>
            <div v-if="permissions.organization.official" class="time-box">
              <span class="form-box-name">{{$t('general.min_text')}}</span>
              <b-form-input v-model="organization.referral_min" type="number" style="width:150px;" />
            </div>
            <div v-if="permissions.organization.official" class="time-box">
              <span class="form-box-name">{{$t('general.sec_text')}}</span>
              <b-form-input v-model="organization.referral_sec" type="number" style="width:150px;" />
            </div>
            <span v-if="permissions.organization.official && !isCorporateConnections && regionChapters.length>1 && organization.allow_joint==1" class="form-box-name">{{$t('general.meeting_with')}}</span>
            <b-form-select v-if="permissions.organization.official && !isCorporateConnections && regionChapters.length>1 && organization.allow_joint==1" v-model="organization.joint_organization" :disabled="originalJointOrg!=0" :options="regionChapters" class="form-box-input" value-field="id" text-field="name"/>
            <div v-if="organization.joint_organization>0 && originalJointOrg==0 && !isCorporateConnections" style="grid-column: 1/4;display:grid;grid-template-columns: 1fr 2fr;">
              <span  class="form-box-name">{{$t('general.date_text')}}</span>
              <b-form-datepicker style="margin: 5px 0px;" class="fit-width" :state="organization_state.joint_day"  v-model="organization.joint_day" :locale="$i18n.locale" :min="new Date()" ></b-form-datepicker>
              <span class="form-box-name">{{$t('general.meeting_start_networking')}}</span>
              <b-form-timepicker minutes-step="5" :label-no-time-selected="$t('global.no_time')"  class="time-picker" :state="organization_state.joint_pre_start" v-model="organization.joint_pre_start" :locale="$i18n.locale" required></b-form-timepicker>
              <span class="form-box-name">{{$t('general.meeting_start_time')}}</span>
              <b-form-timepicker minutes-step="5" :label-no-time-selected="$t('global.no_time')" class="time-picker" :state="organization_state.joint_start" v-model="organization.joint_start" :locale="$i18n.locale" required></b-form-timepicker>
              <span class="form-box-name">{{$t('general.end_time')}}</span>
              <b-form-timepicker minutes-step="5" :label-no-time-selected="$t('global.no_time')" class="time-picker" :state="organization_state.joint_end"  v-model="organization.joint_end" :locale="$i18n.locale"></b-form-timepicker>
            </div>
            <div v-if="organization.joint_organization>0 && originalJointOrg>0 && !isCorporateConnections" style="grid-column: 2/4;display:grid;grid-template-columns: auto 120px 120px; width:100%">
              <div style="font-size:12px; text-align:left;">
                <div >{{organization.joint_confirmed==0? $t('general.joint_not_confirmed') : $t('general.joint_confirmed')}}</div>
                <div v-if="organization.joint_day!=null">{{$d(new Date("" + organization.joint_day.split("-").join("/") + " 00:01"), 'short')}} @ {{organization.joint_start? $d(new Date("" + organization.joint_day.split("-").join("/") + " "+organization.joint_start), 'time12') : ""}}</div>
              </div>
              <b-button v-if="organization.joint_confirmed==0" class="submit-button4" v-on:click="resendJointMeeting" variant="clear-blue"><div class="email-button"></div><span>{{$t('global.button_resend')}}</span></b-button>
              
              <b-button v-if="organization.joint_cancel" class="submit-button4" style="grid-column:3;" v-on:click="showJointClearNote=true;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            </div>
          </div>
          <span class="form-box-title">{{$t('general.meeting_settings')}}</span>
          <div class="form-box" style="grid-template-columns: auto auto auto ;grid-row-gap: 10px;">
            
            <b-form-checkbox v-model="organization.cron_speakers" class="form-box-input" value="1" unchecked-value="0" style="text-align:left;">
              {{$t('general.debug_mode')}}
            </b-form-checkbox>
            <b-form-checkbox v-if="permissions.organization.official && permissions.organization.allow_powerteams==1" v-model="organization.email_powerteams" class="form-box-input" value="1" unchecked-value="0" style="text-align:left;">
              {{$t('general.powerteam_notifications')}}
            </b-form-checkbox>
            <b-form-checkbox v-if="permissions.organization.official && !isCorporateConnections"  v-model="organization.cron_followup" class="form-box-input" value="1" unchecked-value="0" style="text-align:left;">
              {{$t('general.email_followup')}}
            </b-form-checkbox>
            <b-form-checkbox  v-model="organization.scroll_to_member" class="form-box-input" value="1" unchecked-value="0" style="text-align:left;">
              {{$t('general.auto_position')}}
            </b-form-checkbox>
            <b-form-checkbox v-if="permissions.organization.official && !isCorporateConnections" v-model="organization.disable_events_email" class="form-box-input" :value="0" :unchecked-value="1" style="text-align:left;">
              {{$t('general.send_events')}}
            </b-form-checkbox>
            <!--<b-form-checkbox  v-if="permissions.organization.official" v-model="organization.disable_share_speakers" class="form-box-input" :value="0" :unchecked-value="1" style="text-align:left;">
              {{$t('general.send_share_speakers')}}
            </b-form-checkbox>
            <b-form-checkbox v-if="permissions.organization.official" v-model="organization.disable_share_sections" class="form-box-input" :value="0" :unchecked-value="1" style="text-align:left;">
              {{$t('general.send_share_sections')}}
            </b-form-checkbox>-->
             <span v-if="permissions.organization.official" class="form-box-name">{{$t('general.bio_limits')}}</span>
            <div v-if="permissions.organization.official" class="time-box">
              <span class="form-box-name">{{$t('general.bio_recommended')}}</span><b-form-input v-model="organization.recommended_words" type="number" style="width:100px;" />
            </div>
            <div v-if="permissions.organization.official" class="time-box">
              <b-form-checkbox v-model="organization.cap_words" value="1" unchecked-value="0" style="text-align:left;padding-top:8px;margin-left:10px;">
                {{$t('general.bio_cap')}}
              </b-form-checkbox>
              <div >
                <b-form-input v-if="organization.cap_words==1" v-model="organization.max_words" type="number" style="width:100px;" />
              </div>
            </div>
            <span v-if="permissions.organization.official"  class="form-box-name">{{$t('general.prep_notes')}}</span><b-form-textarea v-if="permissions.organization.official" v-model="organization.speaker_prep_notes" style="margin-bottom: 10px;" placeholder="" class="form-box-input" :rows="2" :max-rows="2" />
           <span v-if="permissions.organization.official && !isCorporateConnections"  class="form-box-name">{{$t('general.closing_quote')}}</span>
           <div v-if="permissions.organization.official && !isCorporateConnections" class="fit-width" style="display:grid;grid-template-columns: auto auto;margin: auto 10px;grid-gap: 20px;grid-column: 2/4;">
            <b-form-checkbox v-model="organization.quote_to_speakers" value="0" unchecked-value="1" >
                {{$t('general.manager_below')}}
              </b-form-checkbox>
              <b-form-checkbox v-model="organization.quote_to_speakers" value="1" unchecked-value="0" >
                {{$t('general.featured_speaker')}}
              </b-form-checkbox>
          </div>  
            <span v-if="!permissions.organization.official && !permissions.organization.rst"  class="form-box-name">{{$t('region.min_applicants')}}</span><b-form-input v-if="!permissions.organization.official && !permissions.organization.rst" :min="organization.parent_min_applicants"  v-model="organization.min_applicants" type="number" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;"></b-form-input>
           <div v-if="analytics.length>0" style="width:100%;text-align:center;grid-column: 1/4;">
             {{$t('general.chapter_values')}}
           </div>
           <div v-if="analytics.length>0" style="display:grid; grid-column: 1/4; grid-template-columns: 1fr;margin:auto;" class="fit-content">
             <div v-for="analytic in analytics" :key="analytic.id" style="display:grid;grid-template-columns: auto 250px;">
               <span class="form-box-name">{{analytic.name}}</span><b-form-input v-model="analytic.value" style="width:250px;" />
             </div>
           </div>
          </div>
          <span class="form-box-title" v-if="permissions.organization.official && !isCorporateConnections" >{{$t('general.members_assigned_to')}}</span>
          <div id="delegate-anchor" v-if="permissions.organization.official && !isCorporateConnections"  class="form-box" style="grid-template-columns: max-content auto auto ;grid-row-gap: 10px;position:relative;">
            <SetupTip style="position:absolute; left: -40px;"   :tipNumber="4"/>
            <span class="form-box-name">{{$t('general.email_bios')}}</span>
             <multiselect :noOptions="$t('global.empty_list')" :class="{blueselect:true}" style="grid-column: 2/4;" :selectedLabel="$t('global.selected_text')" selectLabel="" deselectLabel="" v-model="bio_emails" :options="members" :multiple="true" :close-on-select="false"  :preserve-search="true" :placeholder="$t('global.select_members')" label="name" track-by="id" :preselect-first="false">
              <template slot="option" slot-scope="props">
                <div><span>{{ props.option.name }}</span> - <span>{{ props.option.email.length>0 ? props.option.email : $t('global.no_email')  }}</span></div>
              </template>
            </multiselect>
            <span class="form-box-name">{{$t('general.email_attendance')}}</span>
            <multiselect :noOptions="$t('global.empty_list')" :class="{blueselect:true}" style="grid-column: 2/4;" :selectedLabel="$t('global.selected_text')" selectLabel="" deselectLabel="" v-model="emails" :options="members" :multiple="true" :close-on-select="false"  :preserve-search="true" :placeholder="$t('global.select_members')" label="name" track-by="id" :preselect-first="false">
              <template slot="option" slot-scope="props">
                <div><span>{{ props.option.name }}</span> - <span>{{ props.option.email.length>0 ? props.option.email : $t('global.no_email')  }}</span></div>
              </template>
            </multiselect>
            <span class="form-box-name">{{$t('general.quotes_to')}}</span>
            <multiselect :noOptions="$t('global.empty_list')" :class="{blueselect:true}" style="grid-column: 2/4;" :selectedLabel="$t('global.selected_text')" selectLabel="" deselectLabel="" v-model="quote_emails" :options="members" :multiple="true" :close-on-select="false"  :preserve-search="true" :placeholder="$t('global.select_members')" label="name" track-by="id" :preselect-first="false">
              <template slot="option" slot-scope="props">
                <div><span>{{ props.option.name }}</span> - <span>{{ props.option.email.length>0 ? props.option.email : $t('global.no_email')  }}</span></div>
              </template>
            </multiselect>
           
            <span class="form-box-name">{{$t('general.enters_vp')}}</span>
            <multiselect :noOptions="$t('global.empty_list')" :class="{blueselect:true}" style="grid-column: 2/4;" :selectedLabel="$t('global.selected_text')" selectLabel="" deselectLabel="" v-model="tracking_emails" :options="members" :multiple="true" :close-on-select="false"  :preserve-search="true" :placeholder="$t('global.select_members')" label="name" track-by="id" :preselect-first="false">
              <template slot="option" slot-scope="props">
                <div><span>{{ props.option.name }}</span> - <span>{{ props.option.email.length>0 ? props.option.email : $t('global.no_email')  }}</span></div>
              </template>
            </multiselect>
            <span  class="form-box-name">{{$t('general.events_emails')}}</span>
            <multiselect :noOptions="$t('global.empty_list')" :class="{blueselect:true}" style="grid-column: 2/4;" :selectedLabel="$t('global.selected_text')" selectLabel="" deselectLabel="" v-model="events_emails" :options="members" :multiple="true" :close-on-select="false"  :preserve-search="true" :placeholder="$t('global.select_members')" label="name" track-by="id" :preselect-first="false">
              <template slot="option" slot-scope="props">
                <div><span>{{ props.option.name }}</span> - <span>{{ props.option.email.length>0 ? props.option.email : $t('global.no_email')  }}</span></div>
              </template>
            </multiselect>
             
            <span  class="form-box-name">{{$t('general.newsletter_emails')}}</span>
            <multiselect  :noOptions="$t('global.empty_list')" :class="{blueselect:true}" style="grid-column: 2/4;" :selectedLabel="$t('global.selected_text')" selectLabel="" deselectLabel="" v-model="newsletter_emails" :options="members" :multiple="true" :close-on-select="false"  :preserve-search="true" :placeholder="$t('global.select_members')" label="name" track-by="id" :preselect-first="false">
              <template slot="option" slot-scope="props">
                <div><span>{{ props.option.name }}</span> - <span>{{ props.option.email.length>0 ? props.option.email : $t('global.no_email')  }}</span></div>
              </template>
            </multiselect>

            <span  class="form-box-name">{{$t('general.categories_emails')}}</span>
            <multiselect  :noOptions="$t('global.empty_list')" :class="{blueselect:true}" style="grid-column: 2/4;" :selectedLabel="$t('global.selected_text')" selectLabel="" deselectLabel="" v-model="categories_emails" :options="members" :multiple="true" :close-on-select="false"  :preserve-search="true" :placeholder="$t('global.select_members')" label="name" track-by="id" :preselect-first="false">
              <template slot="option" slot-scope="props">
                <div><span>{{ props.option.name }}</span> - <span>{{ props.option.email.length>0 ? props.option.email : $t('global.no_email')  }}</span></div>
              </template>
            </multiselect>

            <span  class="form-box-name">{{$t('general.speakers_emails')}}</span>
            <multiselect  :noOptions="$t('global.empty_list')" :class="{blueselect:true}" style="grid-column: 2/4;" :selectedLabel="$t('global.selected_text')" selectLabel="" deselectLabel="" v-model="speakers_emails" :options="members" :multiple="true" :close-on-select="false"  :preserve-search="true" :placeholder="$t('global.select_members')" label="name" track-by="id" :preselect-first="false">
              <template slot="option" slot-scope="props">
                <div><span>{{ props.option.name }}</span> - <span>{{ props.option.email.length>0 ? props.option.email : $t('global.no_email')  }}</span></div>
              </template>
            </multiselect>

            <span v-if="(permissions.organization.id == 3 || permissions.organization.allow_powerteams==1)" class="form-box-name">{{$t('general.powerteam_emails')}}</span>
            <multiselect v-if="(permissions.organization.id == 3 || permissions.organization.allow_powerteams==1)" :noOptions="$t('global.empty_list')" :class="{blueselect:true}" style="grid-column: 2/4;" :selectedLabel="$t('global.selected_text')" selectLabel="" deselectLabel="" v-model="powerteam_emails" :options="members" :multiple="true" :close-on-select="false"  :preserve-search="true" :placeholder="$t('global.select_members')" label="name" track-by="id" :preselect-first="false">
              <template slot="option" slot-scope="props">
                <div><span>{{ props.option.name }}</span> - <span>{{ props.option.email.length>0 ? props.option.email : $t('global.no_email')  }}</span></div>
              </template>
            </multiselect>
          </div>
          
          <div v-if="permissions.organization.official && !isCorporateConnections" >
            <span class="form-box-title">{{$t('general.social_media_links')}}</span>
            <div class="form-box " style="grid-template-columns:auto;grid-gap:20px;position:relative;">
              
              <div  style="display: grid; grid-template-columns: auto 400px;">
                  <span class="form-box-name">{{$t('general.add_channel')}}</span>
                  <multiselect :noOptions="$t('global.empty_list')" style="min-width:400px;" :class="{blueselect:true}"  :selectedLabel="$t('global.selected_text')" selectLabel="" deselectLabel="" v-model="media_links" :options="mediaOptions" :multiple="true" :close-on-select="false"  :preserve-search="true" :placeholder="$t('general.select_media')" label="name" track-by="channel_id" :preselect-first="false">
                    <template slot="option" slot-scope="props">
                      <div><img style="height:40px;" :src="props.option.image"> <span>{{ props.option.name  }}</span></div>
                    </template>
                  </multiselect>
                  <span class="form-box-name" style="grid-column:1/3;text-align:center;">{{$t('general.selected_media')}}</span>
                  <table class="table b-table  table-sm table-hover-new " style="grid-column:1/3;">
                    <thead>
                      <tr>
                        <th >{{$t('general.channel_header')}}</th>
                        <th style="text-align: left;" >{{$t('general.url_header')}}</th>
                        <th >{{$t('general.remove_header')}}</th>
                      </tr>
                    </thead>
                    <draggable tag="tbody" style="grid-column:1/3;" v-model="media_links">
                        <tr v-for="m in media_links" :key="m.channel_id">
                          <td><img style="height:40px;" :src="m.image"></td>
                          <td><b-form-input v-model="m.link" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 200px;" maxlength="255"></b-form-input></td>
                          <td><b-button class="trash-button" variant="blue" v-on:click="deleteLink(m)"></b-button></td>
                        </tr>
                    </draggable>
                  </table>
              </div>
            </div>
          </div>

           <div v-if="isCorporateConnections" >
            <span class="form-box-title">{{$t('general.chapter_mission')}}</span>
            <div class="form-box " style="grid-template-columns:auto;position:relative;">
                <b-form-textarea v-model="organization.mission_statement" placeholder="" :rows="3"/>
            </div>
          </div>

          

          <div v-if="!onboard && showAlert">
          <b-alert variant="danger"
                  dismissible
                  :show="showAlert"
                  @dismissed="showAlert=false">
            {{alertText}}
          </b-alert>
        </div>
        
          <div class="submit-box-2" v-if="!onboard">
            <b-button class="submit-button4" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="submit-button4" type="submit" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
        </form>
      </div>
      <div class="div-space"></div>
    </div>
    <b-modal id="modal-center" v-model="showJointNote" centered :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true">
          <div class="my-4" v-html="$t('general.joint_note', {chapter: organization.joint_name, president: organization.joint_president})"></div>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="submit-button4" v-on:click="showJointNote= false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_no')}}</span></b-button>
            <b-button class="submit-button4" v-on:click="closeJointNote" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_yes')}}</span></b-button>
          </div>
        </b-modal>
      <b-modal id="modal-center" v-model="showJointClearNote" centered :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true">
        <div class="my-4" v-html="$t('general.joint_clear_note', {chapter: organization.joint_name})"></div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="submit-button4" v-on:click="showJointClearNote= false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_no')}}</span></b-button>
          <b-button class="submit-button4" v-on:click="cancelJointMeeting" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_yes')}}</span></b-button>
        </div>
      </b-modal>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.light-green-row{
 background-color: rgba(100,180,100,.4);
}
.no-wrap{
  white-space: nowrap;
}
.wide-popover { max-width: 600px; }
.bolded-text{
  font-weight: bold;
}
body{
  background-color: white;
}

.section-header-close{
  background-image: url("../assets/nav-Close-Gray.png")  !important;
  filter: $VUE_APP_COLOUR_MAIN_FILTER  !important;
  height: 30px !important;
}

.collapsed > .assistant-section-header{
  background-color: #98989A;
}
.collapsed > .assistant-section-header >div{
  //filter: brightness(0) invert(22%) sepia(1%) saturate(1681%) hue-rotate(227deg) brightness(103%) contrast(89%);
  background-image: url("../assets/ChevronDn-Brn.png");
}
.not-collapsed > .assistant-section-header {
  background-color: #24679A !important;
}
.not-collapsed > .assistant-section-header >div{
  filter: brightness(4);
  background-image: url("../assets/ChevronUp-Brn.png");
}
#date-text{
    color:$VUE_APP_COLOUR_MAIN;font-weight: bold;
  }
#home-chapter-name{
  color:$VUE_APP_CHAPTER_NAME;font-size:16px;padding-left: 10px;white-space: nowrap;    line-height: 20px;
}
#top-home-bar{
  background-color: $VUE_APP_COLOUR_BACK !important;
  padding:0px;border-bottom: solid 1px $VUE_APP_BORDER_COLOUR; height:50px;display: grid; grid-template-columns: max-content max-content auto max-content max-content;
}
#top-home-bar > div{
  margin: 3px;
}
#home-logo{
  margin:0px !important;
  height: 49px;
  background-image: url("../assets/"+$VUE_APP_LOGO_LARGE+".png");
  background-repeat: no-repeat;
    background-size: auto 41px;
    background-position: center;
    
    width: $VUE_APP_LOGO_LARGE_WIDTH;
    background-color: $VUE_APP_COLOUR_LOGO_BACK;
    position: relative;
    //border-bottom: solid 1px $VUE_APP_BORDER_COLOUR;
}
@media(max-width:1000px){
  #home-logo{
    background-image: url("../assets/"+$VUE_APP_LOGO_SMALL+".png");
    width: 50px;
    background-size: auto 41px;
  }
}
#help-bucket{
  position: absolute;right: 25px;border: 1px solid #ccc;border-radius: 10px;display: grid;padding: 5px;width:135px;top: 0px; text-align: center;
}
#help-bucket:hover{
  background-color: #e0f5fd;
}
.green-row{
  color: #009900;
}
#drop-overlay{
  position: absolute;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    background-color: rgba(255, 255, 255,.9);
    z-index: 10;
    margin: 5px;
    text-align: center;
    display:grid;
  grid-template-rows: auto max-content auto;
  font-size: 30px;
}
.cut-filename{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    line-height: 14px;
}
.max-width-checkbox > .custom-control-label{
  width:100%;
}

#website-qr{
    max-height: 50px;
    max-width: 100px;
    border: 1px gray solid;
    margin:10px;
  }
.breakAll {
    word-break: break-all;
  }
#browser-warning___BV_modal_header_{
  background-color: #eee;
  text-align: center;
  padding:0px;
}

.socialMediaIcon{
  height:40px;
  width:40px;
  background-size: 100% 100% !important;
  margin: auto;
}

.trash-button {
    width: 26px;
    height: 30px;
    background: url("../assets/icon-delete.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display:block;
    margin:auto;
    
    filter: $VUE_APP_COLOUR_MAIN_FILTER;
    background-color: transparent !important;
    border: none !important;
  }
.resize-button{
    color: #999;
    font-size: 12px;
    padding: 0px;
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: rgba(255,255,255,0);
    border-color: rgba(255,255,255,0);
}
.resize-button > img{
  width: 20px;
}
.nLayout{
  display:grid;grid-template-columns: repeat(3,1fr); grid-gap:5px;border: 1px solid #333333; padding: 5px; width: 360px;
      margin: 0 auto;
    grid-auto-rows: max-content;
}
.nObject{
  border: 1px dashed #999999;
  cursor: pointer;
  height:50px;
  line-height: 50px;
  font-size:12px;
  position: relative;
}
.nObject > span {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}
 #email_checkboxes .custom-control-input:disabled~.custom-control-label {/*override*/
        color: #999;
        font-style: italic;
    }
.time-picker{
  max-width:220px;max-height: 35px;
}
.exit-portal-button {
    margin: 10px 15px;
    width: 60px;
    height: 60px;
    background-image: url("../assets/nav-Close-Gray.png");
  }
.active-flag{ 
  box-shadow: 0 0 0 0.2rem $VUE_APP_BUTTON_BORDER;/*rgba(0,123,255,.25);*/
}

#fullscreen-overlay{
    height: 100%;
    position: absolute;
    width: 100%;
    background-color: rgba(255,255,255,.8);
    opacity: 0;
    display: grid;
    cursor: pointer;
    line-height: 16px;
    text-align: center;
}
#fullscreen-overlay:hover{
  opacity: 1;
}

#fullscreen-overlay > div{
  margin:auto;  font-size: 18px;color: black;font-family: Raleway;
  cursor: pointer;
}
#language-dropdown > .dropdown-menu {
  min-width: 70px !important;
      top: 38px !important;
}
#zoom-text{
  grid-column:2/3;font-size: 12px; text-align:center;margin-bottom:20px;
}

#cropper-wrapper{
  width: 400px; height:300px; margin: 20px auto;margin-bottom:0px; border: 1px solid gray; display: inline-block;
}
#preview-image{
  height:300px; margin: auto;border: 1px gray solid;
}

#rotate-options{
  display:grid;grid-template-rows: repeat(4, max-content);grid-gap:10px;padding-top:80px;
}
#image-editor{
  display:grid; grid-template-columns:min-content auto auto;
}
.submit-box-2 {
    display: grid;
    grid-auto-flow: column;
    width: fit-content;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    margin: auto;
  }
.image-button{
      padding: 0px;
    width: 30px;
    height: 30px;
}
.image-button > div{
    width: 30px;
    height: 30px;
}
#modal-center {
    text-align: center;
  }
@font-face {
    font-family: 'Raleway'; 
    src: url('../assets/raleway.otf');
  }
  @font-face {
    font-family: 'Arial Black'; 
    src: url('../assets/ariblk.ttf');
  }
 .menu-button2 {
    width: 60px;
    height:61px;
    background-size: 100% ;
    background-repeat: no-repeat;
    cursor: pointer;
    width: fit-content !important;
    width: -moz-fit-content !important;
    width: -webkit-fit-content !important;
  }
  .menu-button2:hover .menu-text{
    color: #000000;
  }
    .menu-button2 .nav-link {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
.editor__content {
    height: 100%;
    min-height: 100px;
    border: 1px solid #333;
    border-radius: 3px;
    margin-top: 10px;
    width: 380px;
    
  }
    .editor__content > div {
      height: 100%;
      white-space: pre-wrap;
      word-break: break-all;
      outline: none !important;
      min-height: 98px;
    }

    .editor__content-small{
      height: 100%;
    border: 1px solid #333;
    border-radius: 3px;
    margin-top: 10px;
    width: 380px;
    }
    .editor__content-small > div {
      height: 100%;
      white-space: pre-wrap;
      word-break: break-all;
      outline: none !important;
    }
  .menubar {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    grid-gap: 5px;
  }
  .actions-centre {
    text-align: center;
  }
.rel-grid-container {
      min-width: 50%;
      top: 40px;
      position: relative;
      margin: auto;
      max-width: 75%;
    }
body, html, #app, #menu {
    height: 100%;
  }
#form-fix {
  min-width: 500px;
    width: fit-content;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    margin: auto;
  }
.center-text{
  text-align: center;
}
.bg-table-blue{
        background-color: rgb(27, 98, 156) !important;
    }
.roleFilled {
    background-color: #e0f5fd;
  }
 .table-hover-new tbody tr:hover {
    background-color: #e0f5fd;
  }
  .td-hover-new:hover {
    background-color: #e0f5fd !important;
    cursor: pointer;
    color:$VUE_APP_COLOUR_MAIN;
  }

  .td-hover-applied{
    background-color: #e0f5fd !important;
    cursor: pointer;
    color:$VUE_APP_COLOUR_MAIN;
  }

.curtail-text{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.multiselect.blueselect .multiselect__option--highlight:not(.multiselect__option--selected),
.multiselect.blueselect .multiselect__tags span:not(.multiselect__placeholder) {
  background:$VUE_APP_COLOUR_MAIN;
}

  #general-asterisk {
    grid-column: 2 / 4;
    text-align: left;
    font-size: 12px;
    width: fit-content;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    margin-right: 0px;
    margin-left: auto;
  }
  .search-button{
    background: url("../assets/button-Search.png") transparent center no-repeat !important;
  }
  .test-button{
    background: url("../assets/icon-test.png") transparent center no-repeat !important;
  }
  .request-button{
    background: url("../assets/icon-RequestControl.png") transparent center no-repeat !important;
  }
  .play-button{
    background: url("../assets/icon-TakeControl.png") transparent center no-repeat !important;
  }
  .stop-button{
    background: url("../assets/icon-ReturnControl.png") transparent center no-repeat !important;
  }
  .reset-button{
    background: url("../assets/button-Reset.png") transparent center no-repeat !important;
  }
  .configure-button{
    background: url("../assets/icon-Customize.png") transparent center no-repeat !important;
  }
  .preview-button{
     background: url("../assets/icon-Preview.png") transparent center no-repeat !important;
  }
  .submit-button {
    background: url("../assets/button-Submit.png") transparent center no-repeat !important;
  }
  .submit-button {
    background: url("../assets/button-Submit.png") transparent center no-repeat !important;
  }
  .red-cross-button {
    background: url("../assets/checkmark-green.png") transparent center no-repeat !important;
  }
  .green-check-button {
    background: url("../assets/cross-red.png") transparent center no-repeat !important;
  }
  .audio-button {
    background: url("../assets/Audio-icon.png") transparent center no-repeat !important;
  }
  .replace-button {
    background: url("../assets/button-Replace.png") transparent center no-repeat !important;
  }
  .import-button {
    background: url("../assets/button-Import.png") transparent center no-repeat !important;
  }
  .close-button {
    background: url("../assets/button-Close.png") transparent center no-repeat !important;
  }
  .plus-button {
    background: url("../assets/button-Add.png")  transparent center no-repeat !important;
  }
  .ok-button {
    background: url("../assets/button-OK.png") transparent center no-repeat !important;
  }
  .back-button {
    background: url("../assets/button-Back.png") transparent center no-repeat !important;
  }
  .next-button {
    background: url("../assets/button-Next.png") transparent center no-repeat !important;
  }
  .move-button {
    background: url("../assets/button-Move.png") transparent center no-repeat !important;
  }
  .decline-button {
    background: url("../assets/button-Decline.png") transparent center no-repeat !important;
  }
  .accept-button {
    background: url("../assets/button-Accept.png") transparent center no-repeat !important;
  }
  .cancel-button {
    background: url("../assets/button-Cancel.png") transparent center no-repeat !important;
  }
   .refresh-button {
    background: url("../assets/button-Refresh.png") transparent center no-repeat !important;
  }
  .save-button {
    background: url("../assets/button-Save.png") transparent center no-repeat !important;
  }
  .delete-button {
    background: url("../assets/button-Delete.png") transparent center no-repeat !important;
  }
  .copy-button {
    background: url("../assets/button-Copy.png") transparent center no-repeat !important;
  }
  .new-button {
    background: url("../assets/button-New.png") transparent center no-repeat !important;
  }
  .login-button {
    background: url("../assets/button-Login.png") transparent center no-repeat !important;
  }
  .email-button {
    background: url("../assets/button-Email.png") transparent center no-repeat !important;
  }
  .send-button {
    background: url("../assets/icon-SendMessage.png") transparent center no-repeat !important;
  }
  .rotate-left-button{
    background: url("../assets/icon-turnL-"+$VUE_APP_IMAGE_MOD+".png") transparent center no-repeat !important;
  }
  .rotate-right-button{
    background: url("../assets/icon-turnR-"+$VUE_APP_IMAGE_MOD+".png") transparent center no-repeat !important;
  }
  .flip-y-button{
    background: url("../assets/icon-flipH-"+$VUE_APP_IMAGE_MOD+".png") transparent center no-repeat !important;
  }
  .flip-x-button{
    background: url("../assets/icon-flipV-"+$VUE_APP_IMAGE_MOD+".png") transparent center no-repeat !important;
  }
  .div-space {
    min-height: 20px;
  }
  #aspect-switch label{
    border: none !important;
  }
  .banner {
    height: 50px;
    vertical-align: middle;
    background: #d6e2ef;
    color: #003c80;
    line-height: 50px;
    font-size: 20px;
    padding-left: 10px;
    border: 1px solid;
  }
  .bg-blue {
    background-color: #e0f5fd !important;
  }

  a.bg-blue:hover, a.bg-blue:focus,
  button.bg-blue:hover,
  button.bg-blue:focus {
    background-color: #e0f5fd !important;
  }
  .list-group-item-blue {
    color: #003c80;
    background-color: #e0f5fd;
  }

    .list-group-item-blue.list-group-item-action:hover, .list-group-item-blue.list-group-item-action:focus {
      color: #003c80;
      background-color: #e0f5fd;
    }

    .list-group-item-blue.list-group-item-action.active {
      color: #003c80;
      background-color: #e0f5fd;
      border-color: #b3d7ff;
    }
  .btn-blue:not(:disabled):not(.disabled):active, .btn-blue:not(:disabled):not(.disabled).active, .show > .btn-blue.dropdown-toggle {
    color: #003c80;
    background-color: #e0f5fd;
    border-color: #b3d7ff;
  }
  .btn-blue {
    color: #003c80;
    background-color: #e0f5fd;
    border-color: #b3d7ff;
  }

    .btn-clear-blue:not(:disabled):not(.disabled):active, .btn-clear-blue:not(:disabled):not(.disabled).active, .show > .btn-clear-blue.dropdown-toggle {
      color: $VUE_APP_BUTTON_TEXT;
      background-color: rgba(255,255,255,0);
      border-color: $VUE_APP_BUTTON_BORDER;
    }

  .btn-clear-blue {
    color: $VUE_APP_BUTTON_TEXT;
    background-color: rgba(255,255,255,0);
    border-color: rgba(255,255,255,0);
  }

    

  .btn-red-outline:not(:disabled):not(.disabled):active, .btn-red-outline:not(:disabled):not(.disabled).active, .show > .btn-red-outline.dropdown-toggle {
    color: #ffffff;
    background-color: #f37067;
    border-color: #f37067;
  }

  .btn-red-outline {
    color: #f37067;
    background-color: #ffffff;
    border-color: #f37067;
  }

   
    .btn-red-outline:focus, .btn-red-outline.focus {
      box-shadow: 0 0 0 0.2rem rgba(243, 112, 103, 0.5);
    }

    .btn-red:focus, .btn-red.focus {
      box-shadow: 0 0 0 0.2rem rgba(243, 112, 103, 0.5);
    }
    .btn-red:not(:disabled):not(.disabled):active, .btn-red:not(:disabled):not(.disabled).active, .show > .btn-red.dropdown-toggle {
      color: #ffffff;
      background-color: #f37067;
      border-color: #f37067;
    }

  .btn-red {
    color: #ffffff;
    background-color: #f37067;
    border-color: #f37067;
  }

    

  .btn-dark-blue-outline:not(:disabled):not(.disabled):active, .btn-dark-blue-outline:not(:disabled):not(.disabled).active, .show > .btn-dark-blue-outline.dropdown-toggle {
    color: #ffffff;
    background-color: #1b629c;
    border-color: #1b629c;
  }

  .btn-dark-blue-outline {
    color: #1b629c;
    background-color: #ffffff;
    border-color: #1b629c;
  }

    

  .btn-dark-blue:not(:disabled):not(.disabled):active, .btn-dark-blue:not(:disabled):not(.disabled).active, .show > .btn-dark-blue.dropdown-toggle {
    color: #ffffff;
    background-color: #1b629c;
    border-color: #1b629c;
  }

  .btn-dark-blue {
    color: #ffffff;
    background-color: #1b629c;
    border-color: #1b629c;
  }

   

  .btn-grey-section-outline:not(:disabled):not(.disabled):active, .btn-grey-section-outline:not(:disabled):not(.disabled).active, .show > .btn-grey-section-outline.dropdown-toggle {
    color: #000000;
    background-color: #f0f0f0;
    border-color: #00AEEF;
    border-style: solid;
    border-width: 2px;
    font-weight: bold;
    padding: 6px 12px 6px 20px;
  }

  .btn-grey-section-outline {
    color: #000000;
    background-color: #f8f8f8;
    border-color: #7DDBFF;
    border-style: solid;
    border-width: 1px;
    font-weight: bold;
    padding: 7px 13px 7px 21px;
  }

   

  .btn-grey-section:not(:disabled):not(.disabled):active, .btn-grey-section:not(:disabled):not(.disabled).active, .show > .btn-grey-section.dropdown-toggle {
    color: #000000;
    background-color: #f0f0f0;
    border-color: #00AEEF;
    border-style: solid;
    border-width: 2px;
    font-weight: bold;
    padding: 6px 12px 6px 20px;
  }

  .btn-grey-section {
    color: #000000;
    background-color: #f0f0f0;
    border-color: #00AEEF;
    border-style: solid;
    border-width: 2px;
    font-weight: bold;
    padding: 6px 12px 6px 20px;
  }

    .btn-grey-section:hover {
      color: #000000;
      background-color: #f0f0f0;
      border-color: #00AEEF;
      border-style: solid;
      border-width: 2px;
      font-weight: bold;
      padding: 6px 12px 6px 20px;
    }
     

    .btn-grey-slide-outline:not(:disabled):not(.disabled):active, .btn-grey-slide-outline:not(:disabled):not(.disabled).active, .show > .btn-grey-slide-outline.dropdown-toggle {
    color: #333333;
    background-color: #dbeef3;
    border-color: #00aeef;
    border-style: solid;
    border-width: 2px;
    padding: 6px 12px 6px 20px;
  }

  .btn-grey-slide-outline {
    color: #333333;
    background-color: #ffffff;
    border-color: #7ddbff;
    border-style: solid;
    border-width: 1px;
    padding: 7px 13px 7px 21px;
  }

   

  .btn-grey-slide:not(:disabled):not(.disabled):active, .btn-grey-slide:not(:disabled):not(.disabled).active, .show > .btn-grey-slide.dropdown-toggle {
    color: #333333;
    background-color: #dbeef3;
    border-color: #00aeef;
    border-style: solid;
    border-width: 2px;
    font-weight: bold;
    padding: 6px 12px 6px 20px;
  }

  .btn-grey-slide {
    color: #333333;
    background-color: #dbeef3;
    border-color: #00aeef;
    border-style: solid;
    border-width: 2px;
    font-weight: bold;
    padding: 6px 12px 6px 20px;
  }

  

  .btn-light-blue-outline:not(:disabled):not(.disabled):active, .btn-light-blue-outline:not(:disabled):not(.disabled).active, .show > .btn-light-blue-outline.dropdown-toggle {
    color: #ffffff;
    background-color: #01aeef;
    border-color: #01aeef;
  }

  .btn-light-blue-outline {
    color: #01aeef;
    background-color: #ffffff;
    border-color: #01aeef;
  }

  .btn-light-blue-outline:hover {
      color: #ffffff;
      background-color: #01aeef;
      border-color: #01aeef;
    }

    .btn-light-blue:not(:disabled):not(.disabled):active, .btn-light-blue:not(:disabled):not(.disabled).active, .show > .btn-light-blue.dropdown-toggle {
    color: #ffffff;
    background-color: #01aeef;
    border-color: #01aeef;
  }

  .btn-light-blue {
    color: #ffffff;
      background-color: #01aeef;
      border-color: #01aeef;
  }

  .btn-light-blue:hover {
      color: #ffffff;
      background-color: #01aeef;
      border-color: #01aeef;
    }



  

  .btn-home:not(:disabled):not(.disabled):active, .btn-home:not(:disabled):not(.disabled).active, .show > .btn-home.dropdown-toggle {
    color: #333333;
    background-color: #a7a7a8;
    border-color: #a7a7a8;
  }

  .btn-home {
    color: #333333;
    background-color: #e7e7e8;
    border-color: #a7a7a8;
  }

    .btn-home:hover {
      color: #333333;
      background-color: #a7a7a8;
      border-color: #a7a7a8;
    }

  .submit-button4 {
    display: flex;
    width: fit-content;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    height: fit-content;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    margin: auto;
    background-size: 100% 100% !important;
    background-repeat: no-repeat;
    margin: 0px 15px;
    
  }
    .submit-button4 > div {
      width: 20px !important;
      height: 20px !important;
      background-size: 100% 100% !important;
      margin: auto;
      
    filter: $VUE_APP_COLOUR_MAIN_FILTER;
    }
    .submit-button4 >span{
      margin-left: 5px;
      width: fit-content !important;
      width: -moz-fit-content !important;
      width: -webkit-fit-content !important;
    }
  #centreDiv {
    text-align: center;
    margin: auto;
  }
  .form-box-name {
    text-align: right;
    margin: 10px 5px;
    color: #999;
  }
  .form-box-input {
    grid-column-start: 2;
    grid-column-end: 4;
    /*border-radius: 10px;*/
  }
  .form-box-input-1 {
    margin: 5px 0;
    /*border-radius: 10px;*/
    border: 1px solid #999;
    padding: 5px;
    min-width: 20px;
    max-width: 150px;
  }
  .form-box {
    position: relative;
    margin: 10px;
    display: grid;
    grid-template-columns: 17% 33% 50%;
    padding: 20px;
    border: 1px solid #999;
    border-radius: 5px;
    color: #999;
  }
  #city-div {
    grid-column-start: 1;
    grid-column-end: 3;
    display: grid;
    grid-template-columns: 34% auto;
  }
  #postal-div {
    display: grid;
    grid-template-columns: 50% auto;
  }
  .form-box-title {
    font-size: 20px;
    color: #003c80;
    background: #fff;
    top: 23px;
    position: relative;
    z-index: 10;
    width: fit-content;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    display: block;
    margin: auto;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }
      
      .btn-grey-slide:hover {
      color: #333333;
    background-color: #dbeef3;
    border-color: #00aeef;
    border-style: solid;
    border-width: 2px;
    font-weight: bold;
    padding: 6px 12px 6px 20px;
    }
     .btn-grey-slide-outline:hover {
      color: #333333;
      background-color: #dbeef3;
    border-color: #00aeef;
      border-style: solid;
      border-width: 2px;
      padding: 6px 12px 6px 20px;
    }
    .btn-grey-section-outline:hover {
      color: #000000;
      background-color: #f0f0f0;
      border-color: #00AEEF;
      border-style: solid;
      border-width: 2px;
      font-weight: bold;
      padding: 6px 12px 6px 20px;
    }

     .btn-dark-blue:hover {
      color: #ffffff;
      background-color: #1b629c;
      border-color: #1b629c;
    }
    .btn-dark-blue-outline:hover {
      color: #ffffff;
      background-color: #1b629c;
      border-color: #1b629c;
    }
    .btn-red:hover {
      color: #ffffff;
      background-color: #f37067;
      border-color: #f37067;
    }
     .btn-red-outline:hover {
      color: #ffffff;
      background-color: #f37067;
      border-color: #f37067;
    }
    .btn-clear-blue:hover {
      color: $VUE_APP_BUTTON_TEXT;
      background-color: #ffffff;
      border-color: $VUE_APP_BUTTON_BORDER;
    }
    .btn-blue:hover {
    color: #003c80;
    background-color: #d6e2ef;
    border-color: #b3d7ff;
  }
  .fit-width {
    width: fit-content !important;
    width: -moz-fit-content !important;
    width: -webkit-fit-content !important;
  }
  .fit-height {
    height: fit-content !important;
    height: -moz-fit-content !important;
    height: -webkit-fit-content !important;
  }
  .grey-row{
    background-color: rgba(0,0,0,0.05);
  }
  .blue-row{
    color: $VUE_APP_COLOUR_MAIN;
  }
  .tableHeader {
    background-color: $VUE_APP_TABLE_HEADER;
    color: #ffffff;
  }
  .exit-controller-button {
    margin: 10px 15px;
    width: 60px;
    height: 60px;
    background-image: url("../assets/nav-Close-Gray.png");
  }
  .pre-format {
    overflow: hidden;
    white-space: pre-wrap; /* Since CSS 2.1 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif;
    margin: 0;
  }

    .script-variable {
    color: #0066ff;
  }

  .shadowClass{
    color:gold;
  }

  .disabledClass {
    color: #aaa;
  }
  .disabledClickClass {
    color: #aaa;
    cursor: pointer;
  }
  .italizedClass{
    font-style: italic;
  }
  .fit-content{
  width: fit-content;
    width: -moz-fit-content;
    width: -webkit-fit-content;
}
  .clickable {
    cursor: pointer;
  }
  .time-box {
      display: grid;
      grid-template-columns: auto auto;
    }

    .fade-enter-active, .fade-leave-active {
    transition: opacity 0.75s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .quickfade-enter-active, .quickfade-leave-active {
    transition: opacity 0.0s;
  }

  .quickfade-enter, .quickfade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .righttoleft-enter-active, .righttoleft-leave-active {
    transition: all 0.75s ease-in;
  }
  .righttoleft-enter /* .fade-leave-active below version 2.1.8 */ {
    transform: translateX(100%);
    
  }
  .righttoleft-leave-to {
    transform: translateX(-100%);
  }

  .lefttoright-enter-active, .lefttoright-leave-active {
    transition: all 0.75s ease-in;
  }
  .lefttoright-enter /* .fade-leave-active below version 2.1.8 */ {
    transform: translateX(-100%);
  }
  .lefttoright-leave-to {
    transform: translateX(100%);
  }

  .toptobottom-enter-active, .toptobottom-leave-active {
    transition: all 0.75s ease-in;
  }
  .toptobottom-enter /* .fade-leave-active below version 2.1.8 */ {
    transform: translateY(100%);
  }
  .toptobottom-leave-to {
    transform: translateY(-100%);
  }

  .bottomtotop-enter-active, .bottomtotop-leave-active {
    transition: all 0.75s ease-in;
  }
  .bottomtotop-enter /* .fade-leave-active below version 2.1.8 */ {
    transform: translateY(100%);
  }
  .bottomtotop-leave-to {
    transform: translateY(-100%);
  }
  #language-dropdown-wrapper .dropdown:hover .dropdown-menu {
      display: block;
    }
   
   

  #modal-fullscreen2___BV_modal_outer_{
    position: inherit !important;
  }
  #modal-fullscreen2{
    height: 100%;
    position: absolute;
    top: 0px;
    width: 100%;
  }
  #modal-fullscreen2___BV_modal_outer_ .modal-backdrop{
    display: none;
  }
  #modal-fullscreen2 .modal-dialog {
    height: 100%;
    margin: 0;
    min-width: 100%;
  }
#modal-fullscreen2 .modal-content {
    height: 100%;
    overflow: auto;
}
  #modal-fullscreen2 .modal-body {
    height: 100%;
    overflow: scroll;
    overflow-x: hidden;
  }

  #modal-fullscreen3___BV_modal_outer_{
    position: inherit !important;
  }
  #modal-fullscreen3{
    height: 90%;
    position: absolute;
    top: 5%;
    width: 90%;
    left:10%;
  }
  #modal-fullscreen3___BV_modal_outer_ > .modal-backdrop{
    display: none;
  }
  #modal-fullscreen3 > .modal-dialog {
    height: 90%;
    margin: 0;
    min-width: 90%;
  }
#modal-fullscreen3 .modal-content {
    height: 90%;
    overflow: auto;
}
  #modal-fullscreen3 .modal-body {
    height: 90%;
    overflow: scroll;
    overflow-x: hidden;
  }

   #normal-text{
    font-weight: normal;
    color: black;
  }

  /*#menu-dropdown .dropdown:hover .dropdown-menu {
      display: block;
    }*/
  #menu-dropdown .nav-link:hover span {
      color: $VUE_APP_COLOUR_MAIN !important;
      
    }
  .image-colour-shift{
    
    filter: $VUE_APP_COLOUR_MAIN_FILTER;
  }
  
  #menu-dropdown .nav-link:hover img{
    /* invert(53%) sepia(94%) saturate(2419%) hue-rotate(161deg) brightness(97%) contrast(107%);*/
    filter: $VUE_APP_COLOUR_MAIN_FILTER;/* invert(53%) sepia(94%) saturate(2419%) hue-rotate(161deg) brightness(97%) contrast(107%);*/
  }

  #menu-dropdown .dropdown-item:hover span {
      color: $VUE_APP_COLOUR_MAIN !important;
    }

  #menu-dropdown .dropdown-item:hover img{
    /* invert(53%) sepia(94%) saturate(2419%) hue-rotate(161deg) brightness(97%) contrast(107%);*/
    filter: $VUE_APP_COLOUR_MAIN_FILTER;/* invert(53%) sepia(94%) saturate(2419%) hue-rotate(161deg) brightness(97%) contrast(107%);*/
  }

  .active-menu img{
    
    filter: $VUE_APP_COLOUR_MAIN_FILTER;
  }

  .main-colour{
    color: $VUE_APP_COLOUR_MAIN !important;
  }
  .active-menu span{
    color: $VUE_APP_COLOUR_MAIN !important;
  }
  #menu-dropdown .dropdown-item {
    display: block;
    padding: 3px 10px;
    
  }

  #menu-dropdown .nav-link{
    padding: 0px;
    margin: auto;
    padding-top: 18px;
    padding-left: 10px;
  }

  
  #menu-dropdown  .dropdown-item:hover, .dropdown-item:focus {
      color: $VUE_APP_COLOUR_MAIN  !important;
      background-color: $VUE_APP_COLOUR_BACK !important;
    }

   #menu-dropdown .dropdown-item.active, .dropdown-item:active {
     color: $VUE_APP_COLOUR_MAIN  !important;
     background-color: $VUE_APP_COLOUR_BACK !important;
    }
  #menu-dropdown .dropdown-menu {
    position: absolute;
    border: 1px solid $VUE_APP_BORDER_COLOUR;
    background-color: $VUE_APP_COLOUR_BACK !important;
    top: 44px !important;
    left: 0px;
    border-radius: 0px;
    transform: translate3d(0,0,0) !important;
    width: fit-content !important;
    width: -moz-fit-content !important;
    width: -webkit-fit-content !important;
  }

  #portal-exit-button:hover img{
  
    filter: $VUE_APP_COLOUR_MAIN_FILTER;
}
#portal-option-button:hover img{
  
    filter: $VUE_APP_COLOUR_MAIN_FILTER;
}
#portal-top-info{
  text-align:center; display:grid; grid-template-rows: auto;line-height:20px;
}
#menu-dropdown .nav-item,.dropdown-menu  {
        min-width: 180px;
  }
@media (max-width: 867px) {
   #menu-dropdown .nav-item  {
        min-width: 50px;
  }
  }
  @media (max-width: 755px) {
    #help-bucket{
      position: relative;margin: auto; right:0px;
    }
  }
</style>
<script>
  import router from "../router"
  import Multiselect from 'vue-multiselect';
  import draggable from "vuedraggable";

  export default {
    name: "General",
    components: {draggable,Multiselect},
    watch: {
      /*'tracking_nums': {
        handler: function (v) {
          this.newsletter.performance_select = [];
          for(var i=0; i<v.length;i++){
            if((this.newsletter.tracking_periods & v[i].value) != 0){
              this.newsletter.performance_select.push(v[i]);
            }
          }
        },
        deep: true
      },*/
      '$i18n.locale': {
        handler: function () {
           this.changeLanguage();
        },
        deep: true
      },
     'organization.joint_organization': {
        handler: function (v) {
           if(v!=0){
             var org = this.regionChapters.filter(c => c.id == v);
             if(org.length>0){
               this.organization.joint_name = org[0].name;
               this.organization.joint_president = org[0].president;
              }
              this.organization.joint_day= this.organization.next_meeting;
              this.organization.joint_pre_start = this.organization.meet_pre_start;
              this.organization.joint_start = this.organization.meet_start;
              this.organization.joint_end = this.organization.meet_end;
           }
        },
        deep: true
      }
    },
    props:{
      onboard: Boolean,
    },
    data() {
      return {
        showJointClearNote: false,
        showJointNote: false,
        originalJointOrg: 0,
        hasShownJointNote: false,
        regionChapters: [],
        mediaOptions:  [],//{channel_id: 1, link:""},{channel_id: 5, link:""},{channel_id: 7, link:""}
        region2: '',
        region: '',
        country: '',
        saveVariant: "blue",
        savedSuccess: false,
        organization : {
          name: "",
          website: "",
          location: "",
          address: "",
          city: "",
          postal_code: "",
          country: 'CA',
          region: 'ON',
          meet_interval_days: 0,
          meet_day_of_week: 0,
          meet_timezone: "",
          language: 'en',
          categories_num: 0
        },
        organization_state:{
          name: null,
          meet_start: null,
          meet_pre_start: null,
          meet_day_of_week: null,
          tracking_periods: null,
          meet_end: null,
          joint_day: null,
          joint_pre_start: null,
          joint_start: null,
          joint_end: null
        },
        showAlert: false,
        alertText: "",
        intervalOptions: [
          //{ value: -1, text: 'Please select an option' },
          { value: 0, text: this.$t('general.interval_option_1') },
          //{ value: 1, text: 'Bi-weekly' },
          //{ value: 2, text: 'Monthly' }
        ],
        trackingOptions: [
          { value: 1, text: this.$t('general.tracking_option_1') },
          { value: 2, text: this.$t('general.tracking_option_2') },
          { value: 4, text: this.$t('general.tracking_option_4') },
          { value: 8, text: this.$t('general.tracking_option_8') },
          { value: 16, text: this.$t('general.tracking_option_16') },
          { value: 32, text: this.$t('general.tracking_option_32') },
          { value: 64, text: this.$t('general.tracking_option_64') },
        ],
        dayOptions: [
          { value: -1, text: this.$t('general.day_option_0') },
          { value: 0, text: this.$t('general.day_option_1') },
          { value: 1, text: this.$t('general.day_option_2') },
          { value: 2, text: this.$t('general.day_option_3') },
          { value: 3, text: this.$t('general.day_option_4') },
          { value: 4, text: this.$t('general.day_option_5') },
          { value: 5, text: this.$t('general.day_option_6') },
          { value: 6, text: this.$t('general.day_option_7') }
        ],
        languageOptions: [],
        timezones : [
          { name: "(GMT -12:00) Eniwetok, Kwajalein", value: "546" },
          { name: "(GMT -11:00) Midway Island, Samoa", value: "562" },
          { name: "(GMT -10:00) Hawaii", value: "583" },
          { name: "(GMT -9:30) Taiohae", value: "548" },
          { name: "(GMT -9:00) Alaska", value: "577" },
          { name: this.$t('timezone.pacific'), value: "379" },
          { name: this.$t('timezone.mountain'), value: "377" },
          { name: this.$t('timezone.central'), value: "375" },
          { name: this.$t('timezone.eastern'), value: "376" },
          { name: this.$t('timezone.atlantic'), value: "374" },
           { name: this.$t('timezone.eastern_daylight'), value: "213" },
          { name: "Newfoundland Time", value: "378" },
          { name: "(GMT -3:00) Brazil, Buenos Aires, Georgetown", value: "86" },
          { name: "(GMT -2:00) Mid-Atlantic", value: "411" },
          { name: "(GMT -1:00) Azores, Cape Verde Islands", value: "336"},
          { name: "(GMT) Western Europe Time, London, Lisbon, Casablanca", value: "451" },
          { name: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris", value: "433" },
          { name: "(GMT +2:00) Kaliningrad, South Africa", value: "446" },
          { name: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg", value: "458" },
          { name: "(GMT +3:30) Tehran", value: "316" },
          { name: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi", value: "245" },
          { name: "(GMT +4:30) Kabul", value: "275" },
          { name: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent", value: "277" },
          { name: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi", value: "251" },
          { name: "(GMT +5:45) Kathmandu, Pokhara", value: "279" },
          { name: "(GMT +6:00) Almaty, Dhaka, Colombo", value: "259" },
          { name: "(GMT +6:30) Yangon, Mandalay", value: "330" },
          { name: "(GMT +7:00) Bangkok, Hanoi, Jakarta", value: "246" },
          { name: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong", value: "360" },
          { name: "(GMT +8:45) Eucla", value: "352" },
          { name: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk", value: "320" },
          { name: "(GMT +9:30) Adelaide, Darwin", value: "320" },
          { name: "(GMT +10:00) Eastern Australia, Guam, Vladivostok", value: "328" },
          { name: "(GMT +10:30) Lord Howe Island", value: "356" },
          { name: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia", value: "394" },
          { name: "(GMT +11:30) Norfolk Island", value: "552" },
          { name: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka", value: "528" },
          { name: "(GMT +12:45) Chatham Islands", value: "530" },
          { name: "(GMT +13:00) Apia, Nukualofa", value: "527" }
        ],
        timezoneOptions: [],
        emails: [],
        bio_emails: [],
        quote_emails: [],
        tracking_emails: [],
        tracking_nums: [],
        events_emails: [],
        newsletter_emails: [],
        categories_emails: [],
        speakers_emails: [],
        powerteam_emails: [],
        members: [],
        original_language: 'en',
        media_links: [],
        analytics: [],
        showPhotoEdit: false,
        tempPhoto: "",
        imgSrc: '',
        quoteSendOptions: [{value: 0, text: this.$t('general.manager_below')},{value: 1, text: this.$t('general.featured_speaker')}]
      }
        
    },
    methods: {
      cancelJointMeeting(){
        this.$http.post("/api/organization/joint/cancel", { }).then((response) => {
          if (response.data.success) {
            this.organization.joint_organization = 0;
            this.organization.joint_day = null;
            this.organization.joint_pre_start = null;
            this.organization.joint_start = null;
            this.organization.joint_end = null;
            this.organization.joint_confirmed = 0;
            this.showJointClearNote = false;
            this.originalJointOrg = 0;
            this.$bvToast.toast(this.$t('general.meeting_cancelled'), {
              variant: 'success',
              solid: true
            });
          }else{
            this.$bvToast.show('serverError');
          }
        }).catch(() => {
        });
      },
      resendJointMeeting(){
        this.$http.post("/api/organization/joint/resend", { }).then((response) => {
          if (response.data.success) {
            this.$bvToast.toast(this.$t('general.joint_resend'), {
              variant: 'success',
              solid: true
            });
          }else{
            this.$bvToast.show('serverError');
          }
        }).catch(() => {
        });
      },
       /* rotateImageLeft(){
        this.$refs.cropper.rotate(90);
        this.cropMove();
      },
      rotateImageRight(){
        this.$refs.cropper.rotate(-90);
        this.cropMove();
      },
      flipImageX(){
        var d = this.$refs.cropper.getData()
        this.$refs.cropper.scaleX(d.scaleX*-1);
        this.cropMove();
      },
      flipImageY(){
        var d = this.$refs.cropper.getData()
        this.$refs.cropper.scaleY(d.scaleY*-1);
        this.cropMove();
      },
          openDialog() {
            document.getElementById('fileid').click();
      },
          clearPhoto(){
        this.imgSrc = '';
        this.organization.qr_code = null;
        this.tempPhoto = '';
      },
      openPhotoEdit(){
        this.showPhotoEdit=true
        if(this.imgSrc && this.imgSrc.length>0){
          this.cropMove();
        }
      },
      cropMove() {
        this.tempPhoto = this.$refs.cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.8);
      },
      setImage(e) {
        const file = e.target.files[0];
        if (!file.type.includes('image/')) {
          alert('Please select an image file');
          return;
        }
        if (typeof FileReader === 'function') {
          var reader = new FileReader();
          var self = this;
          reader.onload = function(e) {
            self.imgSrc =e.target.result;
            self.$refs.cropper.replace(e.target.result);
            setTimeout(function () {
              self.tempPhoto = self.$refs.cropper.getCroppedCanvas().toDataURL(file.type, 0.8);
            }, 200);
          }
          reader.readAsDataURL(file);
        } else {
          alert('Sorry, FileReader API not supported');
        }
      },
      cropImage() {
        if(this.$refs.cropper.getCroppedCanvas()){
          this.organization.qr_code = this.$refs.cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.8);
        }else{
          this.organization.qr_code= null;
        }
        this.showPhotoEdit = false;
      },*/
      closeJointNote(){
        this.showJointNote = false;
        if(this.organization.joint_organization>0)
        this.hasShownJointNote = true;
        this.update();
      },
      getAnalytics() {
        this.$http.post("/api/organization/analytics/get", { }).then((response) => {
          if (response.data.success) {
            this.analytics = response.data.analytics.filter(v => !v.isParent && v.type==1);
          }
        }).catch(() => {
        });
      },
      updateAnalytics() {
        this.$http.post("/api/organization/analytics/update", {analytics: this.analytics }).then(() => {
        }).catch((errors) => {
          console.log(errors);
          this.$bvToast.show('serverError');
        });
      },
      deleteLink(m){
        var index = this.media_links.indexOf(m);
        if (index > -1) {
          this.media_links.splice(index, 1);
        }
      },
      changeLanguage() {
        this.intervalOptions = [
          { value: 0, text: this.$t('general.interval_option_1') },
        ];
        this.trackingOptions= [
          { value: 1, text: this.$t('general.tracking_option_1') },
          { value: 2, text: this.$t('general.tracking_option_2') },
          { value: 4, text: this.$t('general.tracking_option_4') },
          { value: 8, text: this.$t('general.tracking_option_8') },
          { value: 16, text: this.$t('general.tracking_option_16') },
          { value: 32, text: this.$t('general.tracking_option_32') },
          { value: 64, text: this.$t('general.tracking_option_64') },
        ];
        this.tracking_nums = [];
        for(var i=0; i<this.trackingOptions.length;i++){
          if((this.organization.tracking_periods & Math.pow(2,i)) != 0){
            this.tracking_nums.push(this.trackingOptions[i]);
          }
        }
        this.dayOptions = [
          { value: -1, text: this.$t('general.day_option_0') },
          { value: 0, text: this.$t('general.day_option_1') },
          { value: 1, text: this.$t('general.day_option_2') },
          { value: 2, text: this.$t('general.day_option_3') },
          { value: 3, text: this.$t('general.day_option_4') },
          { value: 4, text: this.$t('general.day_option_5') },
          { value: 5, text: this.$t('general.day_option_6') },
          { value: 6, text: this.$t('general.day_option_7') }
        ];
        this.timezones[5].name = this.$t('timezone.pacific');
        this.timezones[6].name =  this.$t('timezone.mountain');
        this.timezones[7].name =  this.$t('timezone.central');
        this.timezones[8].name =  this.$t('timezone.eastern');
        this.timezones[9].name =  this.$t('timezone.atlantic');

        this.getLanguages();
      },
      getTimeZones() {
        this.$http.post("/api/organization/timezones", {}).then((response) => {
          if (response.data.success === true) {
            for(var i=0; i<this.timezones.length; i++){
              if(response.data.timezone_ids_string.indexOf(this.timezones[i].value)>-1){
                this.timezoneOptions.push(this.timezones[i]);
              }
            }
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
      getLanguages(){
        this.$http.post("/api/organization/languages", {}).then((response) => {
          if (response.data.success === true) {
            this.languageOptions = response.data.languages;
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
      
      checkForm(){
        this.organization_state ={
          name: null,
          meet_start: null,
          meet_pre_start: null,
          meet_day_of_week: null,
          meet_end: null,
          joint_day: null,
          joint_pre_start: null,
          joint_start: null,
          joint_end: null
        };
        this.showAlert = false;
        this.alertText = "";
        var allgood = true;
        if(this.organization.name.length == 0){
          allgood = false;
          this.organization_state.name = false;
        }
        if(this.organization.meet_day_of_week < 0){
          allgood = false;
          this.organization_state.meet_day_of_week = false;
        }
        if(this.organization.meet_start == null || this.organization.meet_start == ""){
          allgood = false;
          this.organization_state.meet_start = false;
        }
        if(this.organization.meet_end == null || this.organization.meet_end == ""){
          allgood = false;
          this.organization_state.meet_end = false;
        }
        if(this.organization.meet_pre_start == null || this.organization.meet_pre_start == ""){
          allgood = false;
          this.organization_state.meet_pre_start = false;
        }
        if(this.originalJointOrg != this.organization.joint_organization && this.organization.joint_organization>0 ){
          if(this.organization.joint_day == null){
            allgood = false;
            this.organization_state.joint_day = false;
          }
          if(this.organization.joint_start == null){
            allgood = false;
            this.organization_state.joint_start = false;
          }
          if(this.organization.joint_pre_start == null){
            allgood = false;
            this.organization_state.joint_pre_start = false;
          }
          if(this.organization.joint_end == null){
            allgood = false;
            this.organization_state.joint_end = false;
          }
        }
        if(!allgood){
          this.showAlert = true;
          this.alertText = this.$t("general.alert_text")
        }
        return allgood;
      },
      update(e) {
        this.savedSuccess = false;
        if(!this.checkForm()){
          return;
        }

        

        if(this.originalJointOrg != this.organization.joint_organization && this.organization.joint_organization>0 && !this.hasShownJointNote){
          this.showJointNote = true;
          return;
        }

        if(e){
          e.preventDefault();
        }
        var t = [];
        for(var i=0; i<this.emails.length;i++){
          t.push(this.emails[i].id);
        }
        this.organization.email_list = t.join(",");
        t = []
        for(i=0; i<this.bio_emails.length;i++){
          t.push(this.bio_emails[i].id);
        }
        this.organization.bio_email_list = t.join(",");
        t = []
        for(i=0; i<this.quote_emails.length;i++){
          t.push(this.quote_emails[i].id);
        }
        this.organization.quote_email_list = t.join(",");
        t = []
        for(i=0; i<this.tracking_emails.length;i++){
          t.push(this.tracking_emails[i].id);
        }
        this.organization.period_data_emails = t.join(",");
        t = []
        for(i=0; i<this.events_emails.length;i++){
          t.push(this.events_emails[i].id);
        }
        this.organization.manages_events_list= t.join(",");
        t = []
        for(i=0; i<this.newsletter_emails.length;i++){
          t.push(this.newsletter_emails[i].id);
        }
        this.organization.manages_newsletter_list= t.join(",");
         t = []
        for(i=0; i<this.categories_emails.length;i++){
          t.push(this.categories_emails[i].id);
        }
        this.organization.manages_categories_list= t.join(",");
         t = []
        for(i=0; i<this.speakers_emails.length;i++){
          t.push(this.speakers_emails[i].id);
        }
        this.organization.manages_speakers_list= t.join(",");
         t = []
        for(i=0; i<this.powerteam_emails.length;i++){
          t.push(this.powerteam_emails[i].id);
        }
        this.organization.manages_powerteams_list = t.join(",");
        this.organization.tracking_periods = 0;
        for(i=0; i<this.tracking_nums.length;i++){
          this.organization.tracking_periods |= this.tracking_nums[i].value;
        }
        this.updateAnalytics();
        this.original_language = this.organization.language;
        this.organization.media_links = this.media_links;
        this.organization.originalJointOrg = this.originalJointOrg;
        this.$http.post("/api/organization/update", this.organization).then((response) => {
          if (response.data.success === true) {
            this.savedSuccess = true;
            this.$root.$emit('orgChange', this.organization.name);
            this.saveVariant = 'blue';
            if(!this.onboard){
              this.back();
            }
          } else {
            this.showAlert = true;
            this.alertText = response.data.error.message;
            this.savedSuccess = false;
            this.saveVariant = 'danger';
          }
        }).catch((errors) => {
          console.log(errors);
          this.$bvToast.show('serverError');
        });
      },
       back(){
            router.push("/admin/dashboard");
          },
      pull() {
        this.$http.post("/api/organization/get", {}).then((response) => {
          if (response.data.success) {
            this.organization = response.data.organization;
            this.original_language = this.organization.language;
            this.originalJointOrg = this.organization.joint_organization;
            this.organization.photo = this.organization.banner;

            this.media_links = response.data.media_links;
            var ids = this.organization.email_list? this.organization.email_list.split(",") : [];
            var ids2 = this.organization.bio_email_list ? this.organization.bio_email_list.split(",") : [];
            var ids3 = this.organization.quote_email_list? this.organization.quote_email_list.split(",") : [];
            var ids4 = this.organization.period_data_emails? this.organization.period_data_emails.split(",") : [];
            var ids5 = this.organization.manages_events_list? this.organization.manages_events_list.split(",") : [];
            var ids6 = this.organization.manages_newsletter_list? this.organization.manages_newsletter_list.split(",") : [];
            var ids7 = this.organization.manages_categories_list? this.organization.manages_categories_list.split(",") : [];
            var ids8 = this.organization.manages_speakers_list? this.organization.manages_speakers_list.split(",") : [];
            var ids9 = this.organization.manages_powerteams_list? this.organization.manages_powerteams_list.split(",") : [];
            this.emails = [];
            this.bio_emails = [];
            this.quote_emails = [];
            this.tracking_emails = [];
            this.events_emails = [];
            this.newsletter_emails = [];
            this.categories_emails = [];
            this.speakers_emails = [];
            this.powerteam_emails = [];
            for(var i=0; i<this.members.length;i++){
              if(ids.indexOf(this.members[i].id+"")>=0){
                this.emails.push(this.members[i]);
              }
              if(ids2.indexOf(this.members[i].id+"")>=0){
                this.bio_emails.push(this.members[i]);
              }
              if(ids3.indexOf(this.members[i].id+"")>=0){
                this.quote_emails.push(this.members[i]);
              }
              if(ids4.indexOf(this.members[i].id+"")>=0){
                this.tracking_emails.push(this.members[i]);
              }
              if(ids5.indexOf(this.members[i].id+"")>=0){
                this.events_emails.push(this.members[i]);
              }
              if(ids6.indexOf(this.members[i].id+"")>=0){
                this.newsletter_emails.push(this.members[i]);
              }
              if(ids7.indexOf(this.members[i].id+"")>=0){
                this.categories_emails.push(this.members[i]);
              }
              if(ids8.indexOf(this.members[i].id+"")>=0){
                this.speakers_emails.push(this.members[i]);
              }
              if(ids9.indexOf(this.members[i].id+"")>=0){
                this.powerteam_emails.push(this.members[i]);
              }
            }
            this.tracking_nums = [];
            for(i=0; i<this.trackingOptions.length;i++){
              if((this.organization.tracking_periods & Math.pow(2,i)) != 0){
                this.tracking_nums.push(this.trackingOptions[i]);
              }
            }
          }
        }).catch((error) => {
          console.log(error);
        });
      },
      getMembers() {
        this.$http.post("/api/organization/members/email/select", {}).then((response) => {
          this.members = response.data.members;
          this.pull();
        }).catch(() => {});
      },
      getRegionChapters() {
        this.$http.post("/api/region/chapters", {}).then((response) => {
          this.regionChapters = response.data.chapters;
          this.regionChapters.unshift({id: 0, name: this.$t('general.no_join_meeting')});
        }).catch(() => {});
      },
      getMediaOptions() {
        this.$http.post("/api/media/types/get", {}).then((response) => {
          this.mediaOptions = response.data.types;
        }).catch(() => {});
      },
    },
    destroyed() {
      //this.loadLanguageAsync(this.original_language);
      this.removeClass(document.getElementById("menu-general"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-general"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      this.getMembers();
      this.getLanguages();
      this.getTimeZones();
      this.getAnalytics();
      this.getRegionChapters();
      this.getMediaOptions();
    }  
  }
</script>
