<template>
  <div>
    <div class="rel-grid-container">
      <div id="top-div"></div>
      <div>
          <Tip :tipNumber="5"></Tip>
        <table class="table b-table table-hover-new table-bordered table-sm">
          <thead>
            <tr>
              <th class="tableHeader">{{$t('roles.role_text')}}</th>
              <th v-if="permissions.organization.is_parent" class="tableHeader">{{$t('roles.options_text')}}</th>
              <th  class="tableHeader" style="text-align:center;">{{$t('roles.num_members')}}</th>
              <th v-if="permissions.organization.is_parent && !isCorporateConnections" class="tableHeader" style="text-align:center;">{{$t('roles.official_role')}}</th>
              <th v-if="!permissions.organization.is_parent" class="tableHeader" >{{$t('roles.assigned_members')}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="role in roles" :key="role.id" v-on:click="editRole(role)" v-bind:class="{ 'grey-row' : role.dis, clickable: !role.dis}">
              <td>{{role.name}}</td>
              <td v-if="permissions.organization.is_parent">{{role.singular}}</td>
              <td style="text-align:center;font-weight:bold;">
                <b-button v-if="role.members.length>0 && !permissions.organization.is_parent " variant="link" v-on:click.stop="showMembersOfRole(role.id)"><span style="font-weight:bold;">{{role.members.length}}</span></b-button>
                <span v-if="role.members.length==0 && !permissions.organization.is_parent">{{role.members.length}}</span>
                <span v-if="permissions.organization.is_parent">{{role.num}}</span>
              </td>
              <td v-if="permissions.organization.is_parent && !isCorporateConnections" style="text-align:center;">{{role.official == 1? "Y":""}}</td>
              <td v-if="!permissions.organization.is_parent">
                  <div v-for="m in role.members" :key="m" >
                     <b-button variant="link" v-on:click.stop="goToMember(m)" style="padding:0;border: none;">{{m.name}}</b-button> 
                  </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="submit-box-2">
        <b-button v-if="permissions.access" class="submit-button4" v-on:click="newRole" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
      </div>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
 
</style>
<script>
    
  import router from "../router"
    export default {
      name: "Roles",
      data() {
        return {
          roles: []
        }
      },
      watch: {
      '$i18n.locale': {
        handler: function () {
          this.getRoles();
        }
      }
    },
    methods: {
      getRoles() {
        this.$http.post("/api/organization/roles/members/select", {}).then((response) => {
          if (response.data.success) {
            for (var i = 0; i < response.data.roles.length; i++) {
              response.data.roles[i].singular = response.data.roles[i].singular > 0 ? this.$t('roles.only_one') : "";
              response.data.roles[i].singular += response.data.roles[i].recognition > 0 ? ((response.data.roles[i].singular.length>0? ", ": "")+ this.$t('role.recognition_text')) : "";
            }
            this.roles = response.data.roles;
          }
        }).catch(() => {
        });
      },
      goToMember(m){
        if(m.id>0){
          router.push("/admin/member/"+m.id);
        }
      },
      showMembersOfRole(r){
        this.permissions.filterRoleId = r;
        router.push("/admin/members/");
      },
      newRole() {
        router.push("/admin/role/0");
      },
      editRole(item) {
        if (!item.dis) {
          router.push("/admin/role/" + item.id);
        }
      }
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-roles"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-roles"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      this.getRoles();
    }
    }
</script>
