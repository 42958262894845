<template>
  <div>
    <Header :useruid="this.$route.params.useruid" :title="$t('quote.manage_quote')" :showDate="true"></Header>
    <Header :speakerhash="this.$route.params.useruid" :title="$t('quote.manage_quote')" :showDate="true"></Header>
    <div style="top: 40px;position: relative;">
      <div class="div-space"></div>
      <div>
        <form v-on:submit.prevent="updateQuote" style="width: 600px;margin:auto;">
          <div v-if="!expired">
            <span class="form-box-title">{{$t('quote.quote_text')}}</span>
            <div class="form-box" style="grid-template-columns: auto;display: block;">
              <div class="fit-width" style="display:grid; grid-template-columns: max-content max-content;margin: auto;grid-gap: 20px;">
                <b-form-checkbox v-model="quote.show_image" value="0" unchecked-value="1">
                    {{$t('quote.use_image')}}
                </b-form-checkbox>
                <b-form-checkbox v-model="quote.show_image" value="1" unchecked-value="0">
                    {{$t('quote.use_text')}}
                </b-form-checkbox>
              </div>
              <div v-if="quote.show_image==0" style="display:grid; grid-template-columns: auto auto;">
                    <b-button class="submit-button4" v-on:click="openPhotoEdit((s => $set(quote, 'image', s)),quote.image,true,1500)" variant="clear-blue"><div class="preview-button"></div><span>{{$t('global.set_image')}}</span></b-button>
                    <img style="height:200px;    margin-bottom: 20px;" v-if="quote.image && quote.image.length>0" :src="quote.image" />
              </div>
              <div v-if="quote.show_image==1" class="editor">
                <editor-menu-bar :editor="editor">
                  <div class="menubar" slot-scope="{ commands, isActive }">
                    <b-button variant="outline-dark" :class="{ 'is-active': isActive.bold() }" v-on:click="commands.bold">
                      <b>B</b>
                    </b-button>
                    <b-button variant="outline-dark" :class="{ 'is-active': isActive.italic() }" v-on:click="commands.italic">
                      <i>I</i>
                    </b-button>
                    <b-button variant="outline-dark" :class="{ 'is-active': isActive.strike() }" v-on:click="commands.strike">
                      <s>S</s>
                    </b-button>
                    <b-button variant="outline-dark" :class="{ 'is-active': isActive.underline() }" v-on:click="commands.underline">
                      <u>U</u>
                    </b-button>
                    <b-button variant="outline-dark" :class="{ 'is-active': isActive.paragraph() }" v-on:click="commands.paragraph">
                      P
                    </b-button>
                    <!--<b-button variant="outline-dark" :class="{ 'is-active': isActive.heading({ level: 1 }) }" v-on:click="commands.heading({ level: 1 })">
                      H1
                    </b-button>
                    <b-button variant="outline-dark" :class="{ 'is-active': isActive.heading({ level: 2 }) }" v-on:click="commands.heading({ level: 2 })">
                      H2
                    </b-button>
                    <b-button variant="outline-dark" :class="{ 'is-active': isActive.heading({ level: 3 }) }" v-on:click="commands.heading({ level: 3 })">
                      H3
                    </b-button>-->
                    <b-button variant="outline-dark" :class="{ 'is-active': isActive.bullet_list() }" v-on:click="commands.bullet_list">
                      {{$t('presentation.bullet_text')}}
                    </b-button>
                    <b-button variant="outline-dark" :class="{ 'is-active': isActive.ordered_list() }" v-on:click="commands.ordered_list">
                      {{$t('presentation.list_text')}}
                    </b-button>
                    <b-button variant="outline-dark" v-on:click="commands.undo">
                      {{$t('presentation.undo_text')}}
                    </b-button>
                    <b-button variant="outline-dark" v-on:click="commands.redo">
                      {{$t('presentation.redo_text')}}
                    </b-button>
                  </div>
                </editor-menu-bar>
                <editor-content class="editor__content" :editor="editor" style="height:250px;width:100%;overflow-y: auto;overflow-x: hidden;" />
              </div>
              <div v-if="quote.show_image==1" style="display:grid; grid-template-columns: 30% 70%;margin-top: 10px;">
                  <span style="white-space: nowrap;" class="form-box-name">{{$t('quote.source_text')}}</span><b-form-input v-model="quote.source" :state="quote.source.length>0? null : false" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')"></b-form-input>
              </div>
            </div>
          </div>
          
          <div v-if="showAlert">
            <b-alert variant="danger"
                     dismissible
                     :show="showAlert"
                     @dismissed="showAlert=false">
              {{alertText}}
            </b-alert>
          </div>
          <div v-if="showSuccess">
            <b-alert variant="success"
                     dismissible
                     :show="showSuccess"
                     @dismissed="showSuccess=false">
              {{$t('quote.thanks_message_1')}}
            </b-alert>
          </div>
          <div class="submit-box-2" v-if="!expired">
            <b-button v-if="allowDelete && useruid ==''" v-on:click="deleteQuote" class="submit-button4" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
            <b-button  class="submit-button4" type="submit" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
        </form>
      </div>
      <div class="div-space"></div>
    </div>
    <Footer v-if="!expired" :useruid="this.$route.params.useruid"></Footer>
    <b-modal id="slide-modal" centered  v-model="showPhotoEdit" :title="$t('global.set_image')">
          <b-form-file id='fileid' accept="image/jpeg, image/png, image/gif" @change="setImage" plain hidden></b-form-file>
          <div :style="{'display': 'grid', 'grid-template-columns': 'min-content max-content auto'}">
            <div id="rotate-options">
              <b-button v-if="imageEdit.preview && imageEdit.preview.length>0" v-on:click="rotateImageRight" class="image-button" variant="clear-blue"><div class="rotate-left-button"></div></b-button>
              <b-button v-if="imageEdit.preview && imageEdit.preview.length>0" v-on:click="rotateImageLeft" class="image-button" variant="clear-blue"><div class="rotate-right-button"></div></b-button>
              <b-button v-if="imageEdit.preview && imageEdit.preview.length>0" v-on:click="flipImageX()"  class="image-button" variant="clear-blue"><div class="flip-y-button"></div></b-button>
              <b-button v-if="imageEdit.preview && imageEdit.preview.length>0" v-on:click="flipImageY()" class="image-button" variant="clear-blue"><div class="flip-x-button"></div></b-button>
            </div>
            <div style="width: auto; height:300px; margin: 20px auto; border: 1px solid gray; display: inline-block;">
              <vue-cropper ref='cropper' :guides="true" :view-mode="2" drag-mode="crop" :auto-crop="true" :auto-crop-area="1"
                            :min-container-width="100" :min-container-height="100" :background="true" :rotatable="true"
                            :src="imageEdit.preview" :cropmove="cropMove" alt="Source Image"
                            :img-style="{ 'width': 'auto', 'height': '300px', 'max-width':'300px' }">
              </vue-cropper>
            </div>
            <img id="set-news-preview" style="width:100%;height:100%;object-fit: contain;" v-show="imageEdit.preview"  :src="imageEdit.preview" />
            <div id="zoom-text">{{$t('member.zoom_text')}}</div>
            <b-button class="submit-button4" v-on:click="openDialog" variant="blue" style="grid-column:2/3;margin: auto;">{{$t('memberphoto.upload_photo')}}</b-button>
          </div>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="submit-button4" v-on:click="showPhotoEdit=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="submit-button4" v-on:click="cropImage" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.set_image')}}</span></b-button>
          </div>
        </b-modal>
  </div>
</template>
<style scoped>
#presentation-logo-box{
  display: grid;grid-template-columns: max-content max-content max-content;grid-gap: 20px;margin: auto;
}
</style>
<script>
import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
import router from "../router"
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
  import {
    Blockquote,
    HardBreak,
    HorizontalRule,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Italic,
    Link,
    Strike,
    Underline,
    History,
  } from 'tiptap-extensions'
    export default {
    name: "Quote",
    components: {
      EditorContent,
      EditorMenuBar,
      VueCropper
    },
    props:{
      chapter_id: {type: Number, default: 0},
      quoteObject: {type: Object, default: {id: -1}}
    },
      data() {
        return {
          imageEdit: {data: "", preview: "", successFunc: null, fixed: true,maxwidth:0,maxheight:0},
        image_type: 0,
        showPhotoEdit: false,
        maxwidth: 0,
          editor: new Editor({
            extensions: [
              new Blockquote(),
              new BulletList(),
              new HardBreak(),
              new HorizontalRule(),
              new ListItem(),
              new OrderedList(),
              new TodoItem(),
              new TodoList(),
              new Bold(),
              new Italic(),
              new Link(),
              new Strike(),
              new Underline(),
              new History(),
            ],
            content: '',
          }),
          presentVariant : "primary",
          subVariant: "outline-primary",
          showSuccess: false,
          saveVariant: 'blue',
          showAlert: false,
          alertText: "",
          quote: {id: 0, content: '', source: '', hash: '', image: '', userhash: ''},
          organization_name: "",
          next_meeting: "",
          useruid: '',
          expired: false,
          allowDelete: false
        }
      },
        methods: {
          updateQuote() {
            this.quote.content = this.editor.getHTML();
            if(this.quote.show_image==0){
              this.quote.source = "";
              this.quote.content = "";
            }else{
              this.quote.image = null;
            }

            if(this.quote.show_image==1 && (this.quote.source == undefined || this.quote.source == null || this.quote.source.length==0)){
              this.showAlert = true;
              this.alertText = this.$t("region.alert_text");
              return;
            }
            if(this.quote.content == undefined || this.quote.content == null){
              this.quote.content = "";
            }

            this.quote.useruid = this.useruid;
            this.quote.org = this.chapter_id;
            this.$http.post("/api/organization/quote/update"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""), this.quote).then((response) => {
              this.showUpload = false;
              this.saveVariant = "blue";
              if (response.data.success == true) {
                if(this.useruid == ''){
                  if(this.chapter_id>0){
                    this.quoteObject.id=-1;
                  }else{
                    router.push("/admin/quotes");
                  }
                }else{
                  this.showSuccess = true;
                }
              } else {
                this.showAlert = true;
                this.alertText = response.data.error;
              }
              }).catch((errors) => {
                this.showUpload = false;
                this.saveVariant = "blue";
                this.showAlert = true;
                this.alertText = JSON.stringify(errors);
            });
          },
          deleteQuote(){
            if(this.useruid != ""){
              return;
            }
            this.quote.org = this.chapter_id;
            this.$http.post("/api/organization/delete"+(this.chapter_id>0? "/aschild" : ""), this.quote).then((response) => {
              if (response.data.success) {
                 if(this.chapter_id>0){
                    this.quoteObject.id=-1;
                  }else{
                    router.push("/admin/quotes");
                  }
              }
            }).catch(() => {
            });
          },
          getQuote() {
            this.expired = false;
            this.quote.useruid = this.useruid;
            this.quote.org = this.chapter_id;
            if(this.chapter_id>0){
              this.quote.id = this.quoteObject.id;
            }
            this.$http.post("/api/organization/quote/get"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""), this.quote).then((response) => {
              if (response.data.success) {
                  response.data.quote.show_image = (response.data.quote.image != null)? 0 : 1;
                  this.quote = response.data.quote;
                  this.editor.setContent(this.quote.content);
                  this.organization_name = this.quote.name;
                  this.next_meeting = this.quote.next_meeting;
              }else{
                this.showAlert = true;
                this.alertText = this.$t('quote.link_expired');
                this.expired = true;
              }
            }).catch(() => {
             this.showAlert = true;
              this.alertText = this.$t('quote.link_expired');
              this.expired = true;
            });
          },
          openPhotoEdit(sf, d,f, maxwidth){
        this.imageEdit= {data: "", preview: "", successFunc: null, fixed: true,maxwidth:0,maxheight:0}
        this.showPhotoEdit=true
        this.imageEdit.fixed = f;
        this.imageEdit.successFunc = sf;
        this.imageEdit.maxwidth = maxwidth;
        var self = this;
        if(d && d.length>0){
          this.imageEdit.data = d;
          this.$refs.cropper.replace(self.imageEdit.data);
         setTimeout(function () {
              self.cropMove();
            }, 200);
        }else{
          this.imageEdit.data = "";
          this.$refs.cropper.replace(null);
        }
      },
      cropMove() {
        this.imageEdit.preview = this.$refs.cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.8);
      },
      setImage(e) {
        const file = e.target.files[0];
        if (!file.type.includes('image/')) {
          return;
        }
        if (typeof FileReader === 'function') {
          var reader = new FileReader();
          var self = this;
          reader.onload = function(e) {
              self.imageEdit.data = e.target.result;
              self.$refs.cropper.replace(e.target.result);
              setTimeout(function () {
                self.cropMove();
              }, 200);
              
          }
          reader.readAsDataURL(file);
          
        }
      },
      resizeDataUrl(filedata, imagetype, scale_width, scale_height, onEnd){
        var img = document.createElement("img");
        img.src = filedata;
        img.onload = function(){
          var width = img.width;
          var height = img.height;
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          var MAX_WIDTH = scale_width>0? scale_width : width;
          var MAX_HEIGHT = scale_height>0? scale_height : height;
          if (width > height) {
              if (width > MAX_WIDTH) {
                  height *= MAX_WIDTH / width;
                  width = MAX_WIDTH;
              }
          } else {
              if (height > MAX_HEIGHT) {
                  width *= MAX_HEIGHT / height;
                  height = MAX_HEIGHT;
              }
          }
          canvas.width = width;
          canvas.height = height;
          if(imagetype.includes("j")){
            ctx.fillStyle = '#fff'; 
            ctx.fillRect(0, 0, canvas.width, canvas.height);
          }
          ctx.drawImage(img, 0, 0, width, height);
          var dataurl = canvas.toDataURL(imagetype, 0.8);
          onEnd(dataurl);
        }
      },
      cropImage() {
        if(this.$refs.cropper.getCroppedCanvas()){
          if(this.imageEdit.maxwidth>0 || this.imageEdit.maxheight>0){
            var self = this;
            this.resizeDataUrl(this.$refs.cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.8), 'image/jpeg', this.imageEdit.maxwidth, this.imageEdit.maxheight, function(d){
              self.imageEdit.successFunc(d);
            });
          }else{
            this.imageEdit.successFunc(this.$refs.cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.8));
          }
        }
        this.showPhotoEdit = false;
      },
      openDialog() {
        document.getElementById('fileid').click();
      },
      rotateImageLeft(){
        this.$refs.cropper.rotate(90);
        this.cropMove();
      },
      rotateImageRight(){
        this.$refs.cropper.rotate(-90);
        this.cropMove();
      },
      flipImageX(){
        var d = this.$refs.cropper.getData()
        this.$refs.cropper.scaleX(d.scaleX*-1);
        this.cropMove();
      },
      flipImageY(){
        var d = this.$refs.cropper.getData()
        this.$refs.cropper.scaleY(d.scaleY*-1);
        this.cropMove();
      }
    },
    beforeDestroy() {
      this.editor.destroy()
    },
    mounted() {
      this.setLanguageIfAvailable();
      if(this.$route.params.useruid){
         this.useruid = this.$route.params.useruid;
      }
      if(this.$route.params.id){
         this.quote.id = this.$route.params.id;
         this.allowDelete = true;
      }
      this.getQuote();
    }
}
</script>
