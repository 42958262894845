<template>
  <div >
      <div class="rel-grid-container">
      <div id="top-div"></div>
      <div >
        <b-table class="table-hover-new" small bordered :items="assistants" :fields="fields" >
            <template v-slot:cell(day)="data" >
               <span style="white-space: nowrap;">{{data.item.day? $d(new Date("" + data.item.day.split("-").join("/") + " 00:01"), 'short') : ""}}</span>
            </template>
            <template v-slot:cell(link_uid)="data" >
                <b-button class="submit-button4" style="margin: auto;" v-on:click="openAssistant(data.item.link_uid)" variant="blue" ><span>{{$t('assistant.open_assistant')}}</span></b-button>
                </template>
        </b-table>
      </div>
      <div></div>
      </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import Multiselect from 'vue-multiselect';
export default {
  name: "AssistantAsks",
  components: {Multiselect},
  data() {
        return {
            assistants: [],
           fields: [
               { key: 'day',
              label: this.$t('assistant.date_text'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
            { key: 'name',
              label: this.$t('assistant.name_text'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
             { key: 'link_uid',
              label: this.$t('assistant.assistant_text'),
              sortable: false,
              thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff", 'text-align': 'center'},
              tdClass: "actions-centre"
            }
          ],
        }
  },
  methods: {
      openAssistant(l){
          window.open("/#/assistant/"+l, "_blank", 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
      },
    getAssistants(){
    
      this.$http.post("/api/organization/assistants",{}).then((response) => {
          if(response.data.success){
              this.assistants = response.data.assistants;
          }
      }).catch((e) => { 
       console.log(e);
      })
    },
  },
  destroyed() {
      this.removeClass(document.getElementById("menu-assistants"), "active-menu");
      this.removeClass(document.getElementById("menu-admin"), "active-menu");
    },
  created() {
    this.addClass(document.getElementById("menu-assistants"), "active-menu");
    this.addClass(document.getElementById("menu-admin"), "active-menu");
    this.getAssistants();
  }
}
</script>
