<template>
  <div>
    <div class="rel-grid-container">
      <div id="top-div"></div>
      <div>
        <Tip :tipNumber="10"></Tip>
        <form v-on:submit.prevent="updateAnalytics">
          <b-table class="table-hover-new" small bordered :items="analytics" :fields="fields" :tbody-tr-class="rowClass">
           
            <template v-slot:cell(value)="data" >
              <b-form-input v-if="data.item.type==0 || !permissions.organization.is_parent" v-model="data.item.value" style="max-width: 300px;text-align:right;" class="form-box-input" :disabled="data.item.isParent===1"></b-form-input>
              <div v-if="data.item.type==1 && permissions.organization.is_parent " style="text-align: center;max-width: 300px;">{{$t('analytics.set_value')}}</div>
            </template>
            
            <template v-slot:cell(delete)="data">
              <b-button v-if="!data.item.isParent && (data.item.type==0 || (permissions.organization.is_parent && data.item.type==1))" class="trash-button" variant="blue" v-on:click="showDeleteAnalytic(data.item)"></b-button>
            </template>
          </b-table>
          <br />
          <div class="submit-box-2">
            <b-button class="submit-button4" v-on:click="showAddAnalytic=true;" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
            <b-button v-if="!onboard" class="submit-button4" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button v-if="!onboard" class="submit-button4" type="submit" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
        </form>
      </div>
      <b-modal centered v-model="showAddAnalytic" :title="$t('analytics.new_title')" >
        <div style="display:grid;">
          <span class="form-box-name">{{$t('analytics.title_text')}}</span><b-form-input v-model="title" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;" class="form-box-input"></b-form-input>
          <b-form-checkbox v-if="permissions.organization.id>1 && permissions.organization.is_parent" style="    grid-column: 2/4;"  v-model="type"
                             value="1"
                             unchecked-value="0">
              {{$t('analytics.can_edit')}}
            </b-form-checkbox>
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="submit-button4" v-on:click="showAddAnalytic = false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="submit-button4"  v-on:click="addAnalytic" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
        </div>
      </b-modal>
      <b-modal id="modal-center" v-model="showDelete" centered :title="$t('analytics.delete_title')">
          <p class="my-4" v-html="$t('analytics.delete_text')"></p>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="submit-button4" v-on:click="showDelete=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="submit-button4" v-on:click="deleteAnalytic" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          </div>
        </b-modal>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped lang="scss">
  .trash-button {
    width: 26px;
    height: 30px;
    background: url("../assets/icon-delete.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display:block;
    margin:auto;
    
    filter: $VUE_APP_COLOUR_MAIN_FILTER;
    background-color: transparent !important;
    border: none !important;
  }
  .edit-button {
    width: 30px;
    height: 30px;
    background: url("../assets/icon-Edit.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: block;
    margin: auto;
  }
</style>
<script>
  import router from "../router"
    export default {
    name: "Analytics",
    props:{
      onboard: Boolean,
    },
    watch: {
      '$i18n.locale': {
        handler: function () {
          this.fields[0].label = this.$t('analytics.analytics_item');
          this.fields[1].label = this.$t('analytics.value_text');
          this.fields[2].label = this.$t('analytics.delete_header');
          this.getAnalytics();
        },
        deep: true
      }
    },
      data() {
        return {
          showDelete: false,
          toDelete: null,
          showAddAnalytic : false,
          saveVariant: "blue",
          title: "",
          type: 0,
          fields: [
            {
              key: 'name',
              label: this.$t('analytics.analytics_item'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
            { key: 'value',
              label: this.$t('analytics.value_text'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            },
             { key: 'delete',
              label: this.$t('analytics.delete_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff", "text-align":"center" }
            },
          ],
          analytics: []
        }
      },
    methods: {
      rowClass(item) {
        if (!item) return
        if (item.isParent || (item.type>0 && !this.permissions.organization.is_parent)) return 'grey-row'
      },
      deleteAnalytic() {
        if(this.toDelete!= null){
          this.$http.post("/api/organization/analytics/delete", this.toDelete).then((response) => {
          if (response.data.success == true) {
            var index = this.analytics.indexOf(this.toDelete);
            if (index > -1) {
              this.analytics.splice(index, 1);
            }
          }
          this.showDelete = false;
        }).catch(() => {
          this.showDelete = false;
        });
        }else{
          this.showDelete = false;
        }
      },
      showDeleteAnalytic(a){
        this.toDelete= a;
        this.showDelete = true;
      },
      getAnalytics() {
        this.$http.post("/api/organization/analytics/get", { }).then((response) => {
          if (response.data.success) {
            this.analytics = response.data.analytics;
          }
        }).catch(() => {
        });
      },
      updateAnalytics() {
        this.$http.post("/api/organization/analytics/update", {analytics: this.analytics }).then((response) => {
          if (response.data.success === true) {
            this.saveVariant = 'success';
            if(!this.onboard){
              this.back();
            }
          } else {
            this.saveVariant = 'danger';
          }
        }).catch((errors) => {
          console.log(errors);
          this.$bvToast.show('serverError');
        });
      },
      back() {
        if (this.permissions.organization.is_parent) {
          router.push("/admin/regiondash");
        } else {
          router.push("/admin/dashboard");
        }
      },
      addAnalytic() {
        var t = this.title.split(" ").join("_");
        this.$http.post("/api/organization/analytics/new", {value: "0", value_title: t, name: this.title, type: this.type}).then((response) => {
          if (response.data.success === true) {
            this.saveVariant = 'success';
            this.analytics.push({
              id: response.data.id,
              organization_id: this.analytics.length > 0 ? this.analytics[0].organization_id : this.permissions.organization.id,
              value_title: t,
              type: this.type,
              disable: 0,
              value: "0",
              isParent: 0,
              name: this.title
            });
          }
          this.showAddAnalytic = false;
          this.title = "";
        }).catch((errors) => {
          this.showAddAnalytic = false;
          this.title = "";
          console.log(errors);
          this.$bvToast.show('serverError');
        });
      }
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-analytics"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-analytics"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      this.getAnalytics();
    }
  }
</script>
