<template>
  <div>
    <div class="rel-grid-container">
      <div id="top-div"></div>
      <form v-on:submit.prevent="updateGoal" id="form-fix">
        <span class="form-box-title">{{$t('goal.goal_text')}}</span>
        <div class="form-box" style="grid-template-columns: 25% 25% 50%;">
          <span class="form-box-name">{{$t('goal.title_text')}}</span><b-form-input :state="showSaveAlert && goal.title.length==0? false : null" v-model="goal.title" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;" class="form-box-input" maxlength="35"></b-form-input>
          <span class="form-box-name">{{$t('goal.description_text')}}</span><b-form-textarea v-model="goal.description" pattern="[^<>]*" :title="$t('global.pattern_title')" style="margin-bottom: 10px;" placeholder="" class="form-box-input" :rows="2"  maxlength="255"/>
          <span class="form-box-name">{{$t('goal.value_type')}}</span><b-form-select v-model="goal.type" :options="value_options" value-field="id" text-field="name" class="form-box-input" style="max-width: 150px;" />
          <span class="form-box-name" v-if="goal.type != 4" >{{$t('goal.target_value')}}</span><b-form-input v-if="goal.type != 4" :disabled="goal.type==1" v-model="goal.target" type="number" :step="goal.type==3? .01: 1" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;" class="form-box-input"></b-form-input> 
          <span v-if="goal.type == 4" class="form-box-name">{{$t('goal.from_date')}}</span><b-form-datepicker v-if="goal.type == 4" :state="showSaveAlert && goal.start_date== null? false : null"  style="margin: 5px 0px; "  v-model="goal.start_date" :locale="$i18n.locale" class="form-box-input" ></b-form-datepicker>
          <span v-if="goal.type == 4" class="form-box-name">{{$t('goal.to_date')}}</span><b-form-datepicker v-if="goal.type == 4" :state="showSaveAlert && goal.end_date== null? false : null"  style="margin: 5px 0px; "  v-model="goal.end_date" :locale="$i18n.locale" class="form-box-input" ></b-form-datepicker>
          <span v-if="goal.id==0 && goal.type != 4" class="form-box-name">{{$t('goal.starting_date')}}</span><b-form-datepicker v-if="goal.id==0 && goal.type != 4" :state="showSaveAlert && goal.starting_date== null? false : null"  style="margin: 5px 0px; "  v-model="goal.starting_date" :locale="$i18n.locale" class="form-box-input" ></b-form-datepicker>
          <span v-if="goal.id==0 && goal.type != 4" class="form-box-name">{{$t('goal.starting_value')}}</span><b-form-input v-if="goal.id==0 && goal.type != 4"  :step="goal.type==3?  .01: 1" :state="showSaveAlert && (isNaN(goal.starting_value))? false : null"  v-model="goal.starting_value" type="number" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;" class="form-box-input" ></b-form-input> 
            <b-form-checkbox v-model="goal.disabled"
                                value="1"
                                unchecked-value="0" style="grid-column: 2/4">
                {{$t('goal.disabled_text')}}
            </b-form-checkbox>
        </div>
        <div style="margin: 30px 0px;" v-if="goal.id>0">
        <table class="table b-table table-hover-new table-bordered table-sm">
          <thead>
            <tr>
              <th class="tableHeader">{{$t('goal.last_update')}}</th>
              <th class="tableHeader" >{{$t('goal.highlight_notes')}}</th>
              <th v-if="goal.type != 4" class="tableHeader" style="text-align:center;">{{$t('goal.last_value')}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="update in updates" v-on:click="editUpdate(update)" :key="update.id">
              <td>{{$d(update.display_day, 'day_month_short')}}</td>
              <td ><pre class="pre-format" v-html="update.notes" /></td>
              <td v-if="goal.type != 4" style="text-align:center;">{{update.value}}</td>
            </tr>
          </tbody>
        </table>
        <b-button style="margin:auto;" v-if="permissions.access" class="submit-button4" v-on:click="newGoalUpdate" variant="clear-blue"><div class="new-button"></div><span>{{$t('goal.new_update')}}</span></b-button>
      </div>
      <div v-if="showSaveAlert" style="margin-top:10px;grid-column:1/4;">
          <b-alert variant="danger"
                  dismissible
                  :show="showSaveAlert"
                  @dismissed="showSaveAlert=false">
              {{$t('global.complete_fields')}}
          </b-alert>
      </div>
      <div v-if="showStartingAlert" style="margin-top:10px;grid-column:1/4;">
          <b-alert variant="danger"
                  dismissible
                  :show="showStartingAlert"
                  @dismissed="showStartingAlert=false">
              {{$t('goal.starting_alert1')}}<br/>
              {{$t('goal.starting_alert2')}}
          </b-alert>
      </div>
      <div v-if="showStartingAlert2" style="margin-top:10px;grid-column:1/4;">
          <b-alert variant="danger"
                  dismissible
                  :show="showStartingAlert2"
                  @dismissed="showStartingAlert2=false">
              {{$t('goal.starting_alert1')}}<br/>
              {{$t('goal.starting_date_alert2')}}
          </b-alert>
      </div>
        <div class="submit-box-2">
          <b-btn v-b-modal.modal-center v-if="goal.id>0" class="submit-button4" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-btn>
          <b-button class="submit-button4" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="submit-button4" type="submit" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
        </div>
        <b-modal id="modal-center" v-model="showDelete" centered :title="$t('goal.delete_title')">
          <p class="my-4">{{$t('goal.delete_text')}}</p>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="submit-button4" v-on:click="showDelete=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="submit-button4" v-on:click="deleteGoal" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          </div>
        </b-modal>
        <b-modal  v-model="showGoalUpdate" centered :title="$t('goal.update_text')">
         <span class="form-box-title">{{$t('goal.goal_update')}}</span>
            <div class="form-box" style="grid-template-columns: 25% 25% 50%;">
            <span class="form-box-name">{{$t('goal.day_text')}}</span>
            <b-form-datepicker style="margin: 5px 0px;    grid-column: 2/4;" :state="(showUpdateAlert && !goalUpdateEdit.day)? false : null" v-model="goalUpdateEdit.day" :locale="$i18n.locale"></b-form-datepicker>
            <span v-if="goal.type!=4" class="form-box-name">{{$t('goal.value_text')}}</span><b-form-input v-if="goal.type!=4" :step="goal.type==3?  .01: 1" v-model="goalUpdateEdit.value"  type="number" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;" class="form-box-input"></b-form-input> 
            <div v-if="showAlert" style="margin-top:10px;grid-column:1/4;">
                <b-alert variant="warning"
                        dismissible
                        :show="showAlert"
                        @dismissed="showAlert=false">
                    {{alertText}}
                </b-alert>
            </div>
            <div v-if="showUpdateAlert" style="margin-top:10px;grid-column:1/4;">
              <b-alert variant="danger"
                      dismissible
                      :show="showUpdateAlert"
                      @dismissed="showUpdateAlert=false">
                  {{$t('global.complete_fields')}}
              </b-alert>
          </div>
            <div class="editor" style="grid-column:1/4; margin-top: 30px;">
                    <editor-menu-bar :editor="editor">
                      <div class="menubar" slot-scope="{ commands, isActive }">
                        <b-button variant="outline-dark" :class="{ 'is-active': isActive.bold() }" v-on:click="commands.bold">
                          <b>B</b>
                        </b-button>
                        <b-button variant="outline-dark" :class="{ 'is-active': isActive.italic() }" v-on:click="commands.italic">
                          <i>I</i>
                        </b-button>
                        <b-button variant="outline-dark" :class="{ 'is-active': isActive.strike() }" v-on:click="commands.strike">
                          <s>S</s>
                        </b-button>
                        <b-button variant="outline-dark" :class="{ 'is-active': isActive.underline() }" v-on:click="commands.underline">
                          <u>U</u>
                        </b-button>
                        <b-button variant="outline-dark" :class="{ 'is-active': isActive.paragraph() }" v-on:click="commands.paragraph">
                          P
                        </b-button>
                        <b-button variant="outline-dark" :class="{ 'is-active': isActive.heading({ level: 1 }) }" v-on:click="commands.heading({ level: 1 })">
                          H1
                        </b-button>
                        <b-button variant="outline-dark" :class="{ 'is-active': isActive.heading({ level: 2 }) }" v-on:click="commands.heading({ level: 2 })">
                          H2
                        </b-button>
                        <b-button variant="outline-dark" :class="{ 'is-active': isActive.heading({ level: 3 }) }" v-on:click="commands.heading({ level: 3 })">
                          H3
                        </b-button>
                        <b-button variant="outline-dark" :class="{ 'is-active': isActive.bullet_list() }" v-on:click="commands.bullet_list">
                          {{$t('entry.bullet_text')}}
                        </b-button>
                        <b-button variant="outline-dark" :class="{ 'is-active': isActive.ordered_list() }" v-on:click="commands.ordered_list">
                          {{$t('entry.list_text')}}
                        </b-button>
                        <b-button variant="outline-dark" v-on:click="commands.undo">
                          {{$t('entry.undo_text')}}
                        </b-button>
                        <b-button variant="outline-dark" v-on:click="commands.redo">
                          {{$t('entry.redo_text')}}
                        </b-button>
                      </div>
                    </editor-menu-bar>
                    <editor-content class="editor__content" :editor="editor" style="height:300px;width:100%;overflow-y: auto;overflow-x: hidden;" />
                  </div>
            
          </div>
          
          <div slot="modal-footer" class="submit-box-2">
            <b-btn v-if="goalUpdateEdit.id>0" v-on:click="deleteGoalUpdate(goalUpdateEdit.id)"  class="submit-button4" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-btn>
            <b-button class="submit-button4" v-on:click="showGoalUpdate=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="submit-button4" v-on:click="updateGoalUpdate(goalUpdateEdit)" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
        </b-modal>
      </form>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
 
</style>
<script>
    
  import router from "../router"
  import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
  import {
    Blockquote,
    HardBreak,
    Heading,
    HorizontalRule,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Italic,
    Link,
    Strike,
    Underline,
    History,
  } from 'tiptap-extensions'
    export default {
    name: "Goal",
    components: {
      EditorContent,
      EditorMenuBar
    },
    watch: {
      'goal.type': {
        handler: function (v) {
            if(v == 1){
                this.goal.target = 100;
            }
        },
        deep: true
      },
      'goalUpdateEdit.value': {
        handler: function (v) {
            if(v > this.goal.target){
                this.showAlert = true;
                this.alertText = this.$t('goal.alert_text1');
            }else if(v < 0){
                this.showAlert = true;
                this.alertText = this.$t('goal.alert_text2');
            }else{
                this.showAlert = false;
            }
        },
        deep: true
      },
      '$i18n.locale': {
        handler: function () {
          this.value_options= [{id: 0, name: this.$t('goal.value_option1')},{id: 1, name: this.$t('goal.value_option2')},
                            {id: 2, name: this.$t('goal.value_option3')},{id: 3, name: this.$t('goal.value_option4')},{id: 4, name: this.$t('goal.value_option5')}];
        },
        deep: true
      }
    },
      data() {
        return {
            editor: new Editor({
            extensions: [
              new Blockquote(),
              new BulletList(),
              new HardBreak(),
              new Heading({ levels: [1, 2, 3] }),
              new HorizontalRule(),
              new ListItem(),
              new OrderedList(),
              new TodoItem(),
              new TodoList(),
              new Bold(),
              new Italic(),
              new Link(),
              new Strike(),
              new Underline(),
              new History(),
            ],
            content: '',
          }),
          showSaveAlert: false,
          showUpdateAlert: false,
          meet_day_of_week: 0,
          alertText: "",
          showAlert: false,
          showStartingAlert: false,
          showStartingAlert2: false,
          showDelete: false,
          saveVariant : 'blue',
          goal: {id:0, title: "", description: "", type: 0, target: 0, disabled: 0, starting_date: null, starting_value: 0, start_date: null, end_date: null},
          value_options: [{id: 0, name: this.$t('goal.value_option1')},{id: 1, name: this.$t('goal.value_option2')},
                            {id: 2, name: this.$t('goal.value_option3')},{id: 3, name: this.$t('goal.value_option4')},{id: 4, name: this.$t('goal.value_option5')}],
          updates: [],
          showGoalUpdate: false,
          goalUpdateEdit: {id:0, goal_id:0, day: new Date(), notes: '', value: 0}
        }
      },
    methods: {
      dateDisabled(ymd, date) {
        const weekday = date.getDay();
        return weekday !== this.meet_day_of_week;
      },
    getMeetDay() {
        this.$http.post("/api/organization/get/meet_day_of_week", {}).then((response) => {
          if (response.data.success === true) {
            this.meet_day_of_week = (response.data.meet_day_of_week + 1) % 7;
          }
        }).catch(() => { });
      },
      getGoal() {
        this.goal.id = this.$route.params.id;
        if (this.goal.id > 0) {
          this.$http.post("/api/goal/get", this.goal).then((response) => {
            if (response.data.success) {
              this.goal = response.data.goal;
            }
          }).catch(() => { });
        }
      },
      updateGoal() {
        this.showSaveAlert = false;
        this.showStartingAlert=false;
        this.showStartingAlert2=false;
        if(this.goal.title.length==0 || (this.goal.type!=4 && (this.goal.id==0 && (this.goal.starting_date== null  || isNaN(this.goal.starting_value))))){
          this.showSaveAlert = true;
          return;
        }
        if(this.goal.type == 4 && this.goal.id == 0 && (this.goal.start_date == null || this.goal.end_date == null)){
          this.showSaveAlert = true;
          return;
        }
         if((this.goal.type!=4 && parseInt(this.goal.starting_value)> parseInt(this.goal.target)) ){
          this.showStartingAlert = true;
          return;
        }
        if((this.goal.type==4 && new Date(this.goal.start_date)> new Date(this.goal.end_date)) ){
          this.showStartingAlert2 = true;
          return;
        }
        this.$http.post("/api/goal/update", this.goal).then((response) => {
          if (response.data.success === true) {
            this.saveVariant = 'success';
            this.back();
          } else {
            this.saveVariant = 'danger';
          }
        }).catch(() => { this.$bvToast.show('serverError');});
      },
      deleteGoal() {
        this.$http.post("/api/goal/delete", this.goal).then((response) => {
          if (response.data.success === true) {
            this.back();
          }
        }).catch(() => { });
      },
      getGoalUpdates() {
        this.goal.id = this.$route.params.id;
        if (this.goal.id > 0) {
          this.$http.post("/api/goal/updates/get", this.goal).then((response) => {
            if (response.data.success) {
                for(var i=0; i<response.data.updates.length; i++){
                    var t = response.data.updates[i].day.split("-").join("/");
                    var d = new Date(t+" 00:01");
                    response.data.updates[i].display_day = d.setMinutes(d.getMinutes()+d.getTimezoneOffset());
                }
              this.updates = response.data.updates;
            }
          }).catch(() => { });
        }
      },
      updateGoalUpdate(update) {
        this.showUpdateAlert = false;
        if(!update.day){
          this.showUpdateAlert = true;
          return;
        }
        update.notes = this.editor.getHTML();
        this.$http.post("/api/goal/updates/update", update).then((response) => {
          if (response.data.success === true) {
            if(update.id == 0){
                //this.updates.push(update);
            }
            var d = new Date(update.day.split("-").join("/")+" 00:01");
            update.display_day = d.setMinutes(d.getMinutes()+d.getTimezoneOffset());
            update.id = response.data.inserted_id;
            this.showGoalUpdate = false;
            this.getGoalUpdates();
          }
        }).catch(() => {this.$bvToast.show('serverError'); });
      },
      deleteGoalUpdate(id) {
        this.$http.post("/api/goal/updates/delete", {id:id}).then(() => {
          this.showGoalUpdate = false;
          this.getGoalUpdates();
        }).catch(() => { this.$bvToast.show('serverError');});
      },
      editUpdate(update){
        this.goalUpdateEdit = update;
        this.editor.setContent(update.notes);
        this.showGoalUpdate = true;
      },
      newGoalUpdate(){
        this.showGoalUpdate = true;
        this.goalUpdateEdit = {id:0, goal_id: this.goal.id, day: '', notes: '', value: 0};
        this.editor.setContent('');
      },
      back() {
        window.history.length > 1 ? router.go(-1) : router.push("/admin/goals");
      }
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-goals"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-goals"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      this.getGoal();
      this.getGoalUpdates();
      this.getMeetDay();
      }
    }
</script>
