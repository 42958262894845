<template>
    <div>
      <div class="rel-grid-container">
        <div id="top-div"></div>
        <div>
          <table class="table b-table table-hover-new table-bordered table-sm">
            <thead>
              <tr>
                <th class="tableHeader">{{$t('contacttype.english_name')}}</th>
                <th class="tableHeader">{{$t('contacttype.name_text')}}</th>
              </tr>
            </thead>
            <draggable tag="tbody" v-model="mediatypes"  @end="endDrag">
              <tr style="cursor: pointer;" class="dragndrop"  v-for="t in mediatypes" :key="t.id" v-on:click="editMediatype(t)">
                <td >{{ t.name_en }}</td>
                <td>{{ t.name }}</td>
              </tr>
            </draggable>
          </table>
            <div id="drag-note">{{$t('contacttype.drag_order')}}</div>
        </div>
        <div class="submit-box-2">
          <b-button v-if="permissions.access" class="submit-button4" v-on:click="newMediatype" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
        </div>
        <div class="div-space"></div>
      </div>
      <b-modal centered v-model="showTypeEdit" :title="selectedType.id>0? $t('contacttype.type_edit') :$t('contacttype.type_new') " >
          <div style="display:grid;">
            <span class="form-box-name">{{$t('contacttype.name_text')}}</span><b-form-input v-model="selectedType.name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;" class="form-box-input"></b-form-input>
           <b-form-checkbox style="grid-column:2/4;" v-model="selectedType.chapter_usable" value="1" unchecked-value="0">
              {{$t('contacttype.chapter_usable')}}
            </b-form-checkbox>
            <b-form-checkbox style="grid-column:2/4;" v-model="selectedType.region_usable" value="1" unchecked-value="0">
              {{$t('contacttype.region_usable')}}
            </b-form-checkbox>
            <ImageSelector ref="imageSelector" :imageObject="selectedType" :maxWidth="500" :maxHeight="500" style="grid-column: 1/3;margin-left: 50px;margin-top: 20px;"/>
          </div>
          <div slot="modal-footer" class="submit-box-2">
              <b-button class="submit-button4" v-on:click="deleteType" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
            <b-button class="submit-button4" v-on:click="showTypeEdit = false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="submit-button4"  v-on:click="updateType" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
        </b-modal>
    </div>
  </template>
  <style scoped>
   
  </style>
  <script>
  import draggable from "vuedraggable";
      export default {
        name: "MediaTypes",
        components: {
        draggable
      },
        data() {
          return {
            mediatypes: [],
            selectedType: {id: 0, name: "", image: "", photo: "", chapter_usable: 0, region_usable: 0},
            showTypeEdit: false
          }
        },
        watch: {
        '$i18n.locale': {
          handler: function () {
            this.getMediaTypes();
          }
        }
      },
      methods: {
       getMediaTypes(){
          this.$http.post("/api/media/types/get/edit", {}).then((response) => {
            this.mediatypes = response.data.types;
            
          }).catch(() => {
          });
        },
        deleteType(){
          this.$http.post("/api/media/types/delete", {id: this.selectedType.id}).then((response) => {
              this.showTypeEdit = false;
              if(response.data.success){
                  this.getMediaTypes();
              }
              
          }).catch(() => {
              this.showTypeEdit = false;
          });
        },
        updateType(){
          this.$http.post("/api/media/types/update", this.selectedType).then((response) => {
              this.showTypeEdit = false;
             if(response.data.success){
                 
                  this.getMediaTypes();
              }
          }).catch(() => {
              this.showTypeEdit = false;
          });
        },
        newMediatype() {
            this.selectedType = {id: 0, name: "", image: "", photo: "", chapter_usable: 0, region_usable: 0};
          this.showTypeEdit = true;
        },
        editMediatype(item) {
          this.selectedType = JSON.parse(JSON.stringify(item));
          this.selectedType.photo = this.selectedType.image;
          this.showTypeEdit = true;
        },
         endDrag() {
          var ranks = [];
          for (var i = 0; i < this.mediatypes.length; i++) {
            ranks.push({ id: this.mediatypes[i].id, rank: i });
          }
          this.$http.post("/api/media/types/rank", { ranks: ranks}).then(() => {}).catch(() => {});
        },
      },
      destroyed() {
        this.removeClass(document.getElementById("menu-mediatypes"), "active-menu");
        this.removeClass(document.getElementById("menu-chapter"), "active-menu");
      },
      mounted() {
        this.addClass(document.getElementById("menu-mediatypes"), "active-menu");
        this.addClass(document.getElementById("menu-chapter"), "active-menu");
        this.getMediaTypes();
      }
      }
  </script>
  