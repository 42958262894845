<template>
  <div style="height:100%;">
    <div style="position:relative;" id="setup-main-box">
        <div id="top-div"></div>
        <!--<div>
            <img style="margin:auto;margin-bottom: 50px; height: 150px;" src="../assets/logo-MeetingHub-2.png" />
        </div>-->
        <div id="trans-wrapper" style="position:relative;">
            <div style="text-align:left;">
                <img style="height: 70px;" src="../assets/logo-MeetingHubwSlogan2020.png" />
            </div>
        <transition :name="slide_transition">
            <!--<div v-if="view == 0">
                <i>{{$t('setup.signup_text1')}}</i>
                <i>{{$t('setup.signup_text2')}}</i>
                <i>{{$t('setup.signup_text3')}}</i>
                <b-button style="margin:auto;" class="submit-button4" v-on:click="view = 1" variant="clear-blue"><span>{{$t('global.button_continue')}}</span></b-button>
            </div>
            <div v-if="view == 1">
                <i>{{$t('setup.confirm_text1')}}</i><br/>
                <i>{{$t('setup.confirm_text2')}}</i>
                <div class="submit-box-2" style="grid-gap:50px;">
                    <b-button style="margin:auto;" class="submit-button4" v-on:click="view = 3" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_yes')}}</span></b-button>
                    <b-button style="margin:auto;" class="submit-button4" v-on:click="view = 2" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_no')}}</span></b-button>
                </div>
            </div>
            <div v-if="view == 2">
                <i>{{$t('setup.unauthorized_text1')}}</i><br/>
                <i>{{$t('setup.unauthorized_text2')}}</i>
                <b-button style="margin:auto;" class="submit-button4" v-on:click="goToLogin" variant="clear-blue"><div class="back-button"></div><span>{{$t('global.button_back')}}</span></b-button>
            </div>
            <div v-if="view == 3">
                <div class="form-box" style="grid-template-columns: repeat(3,1fr);">
                    <span class="form-box-name">{{$t('setup.chapter_name')}}</span><b-form-input :state="organization_state.name_state" v-model="organization.name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')"  class="form-box-input" :disabled="confirmed" required></b-form-input>
                    <span class="form-box-name">{{$t('setup.franchise_region')}}</span>
                    <b-form-select v-model="organization.region" :options="regions" :state="organization_state.region_state" class="form-box-input" :disabled="confirmed"/>
                    <span class="form-box-name">{{$t('setup.pres_email')}}</span><b-form-input :state="organization_state.email_state" v-model="organization.email" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input" :disabled="confirmed"></b-form-input>
                
                </div>
                <div v-if="showAlert">
                    <b-alert variant="danger" dismissible :show="showAlert" @dismissed="showAlert=false">
                    {{alertText}}
                    </b-alert>
                </div>
                <b-button style="margin:auto;" class="submit-button4" v-on:click="checkData" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_submit')}}</span></b-button>
            </div>
            <div v-if="view == 4">
                <i>{{$t('setup.unimplemented_text1')}}</i><br/>
                <i>{{$t('setup.unimplemented_text2')}}</i>
            </div>
            <div v-if="view == 5">
                <i>{{$t('setup.name_use_text1')}}</i><br/>
                <i>{{$t('setup.name_use_text2')}}</i>
                <i>{{$t('setup.name_use_text3')}}</i>
                <i>{{$t('setup.name_use_text4')}}<a href="mailto:support@meetinghub.biz">support@meetinghub.biz</a></i>
            </div>
            <div v-if="view == 6">
                <i>{{$t('setup.done_text1')}}</i>
                <i>{{$t('setup.done_text2')}}<b-button variant="link" style="padding:0px;font-style: italic;" v-on:click="goToLogin">{{$t('setup.done_text3')}}</b-button>{{$t('setup.done_text4')}}</i>
            </div>-->

             <div v-if="view == 1" class="transition-view"  ref="view1" key="view1">
                 <div class="fit-width">
                    <span v-html="$t('setup.view1_text1')"></span><br/><br/>
                    <span style="text-align: center;">{{$t('setup.view1_text2')}}:</span>
                    <b-form-select class="fit-width" style="margin:auto;" v-model="organization.region" :options="regions" />
                    <b-button v-if="organization.region>0" style="margin: auto;margin-top:10px;" class="submit-button4" v-on:click="goToView2" variant="clear-blue"><div class="next-button"></div><span>{{$t('global.button_next')}}</span></b-button>
                </div>
            </div>
            <div v-if="view == 2" class="transition-view" ref="view2" key="view2">
                <div class="fit-width">
                    <span v-html="$t('setup.view2_text1', {region: organization.org_name})"></span>
                    <div v-for="contact in signup_contacts" :key="contact.id" class="contact-button" v-on:click="goToView3(contact.id)">
                        <div>{{contact.name}}</div>
                        <div>{{contact.email}}</div>
                    </div>
                    <span style="margin-top:30px;" v-html="$t('setup.view2_text2')"></span>
                </div>
            </div>
            <div v-if="view == 3" class="transition-view"  ref="view3" key="view3">
                <div class="fit-width">
                    <span v-html="$t('setup.view3_text1', {region: organization.org_name})"></span><br/>
                    <span style="text-align: center;">{{organization.email}}</span><br/>
                    <span >{{$t('setup.view3_text2')}}:</span>
                    <b-form-input style="max-width:200px;margin:auto;" :placeholder="$t('setup.enter_code')" v-model="organization.code" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')"  ></b-form-input>
                    <b-alert variant="danger" style="margin: 10px;" dismissible :show="showAlert" @dismissed="showAlert=false">
                        {{$t('setup.code_incorrect')}}
                    </b-alert>
                    <br/>
                    <b-button v-if="organization.code.length>0" style="margin: auto;margin-top:10px;" class="submit-button4" v-on:click="goToView4" variant="clear-blue"><div class="next-button"></div><span>{{$t('global.button_next')}}</span></b-button>
                </div>
            </div>
            <div v-if="view == 4" class="transition-view" ref="view4" key="view4">
                <div class="fit-width">
                    <span v-html="$t('setup.view4_text1',{region: organization.org_name})"></span>
                    <div style="width: 500px; height: 500px;background-color:white;">

                    </div>
                    <div style="display:grid; grid-template-columns: auto auto;    padding: 10px;">
                        <b-form-checkbox style="color:color:#00aeef;" v-model="organization.agree" :value="1" :unchecked-value="0">
                            <span v-html="$t('setup.view4_text2')"></span>
                        </b-form-checkbox>
                    <b-button v-if="organization.agree==1" style="margin-left: auto;" class="submit-button4" v-on:click="goToView5" variant="clear-blue"><div class="next-button"></div><span>{{$t('global.button_next')}}</span></b-button>
                    </div>
                </div>
            </div>
            <div v-if="view == 5" class="transition-view" ref="view5" key="view5">
                <div class="fit-width">
                    <span style="margin-bottom: 20px;" v-html="$t('setup.view5_text1', {region: organization.org_name, username: organization.name})"></span>
                    <div style="display:grid; grid-template-columns: auto auto;">
                        <b-form-input style="max-width:200px;margin:auto;" :placeholder="$t('setup.enter_password')" v-model="organization.password" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')"  ></b-form-input>
                        <span style="    margin: auto 10px;color:#aaa;">{{$t('global.password_requirement')}} </span>
                    </div>
                    <span style="margin-top:20px;" v-html="$t('setup.view5_text2')"></span>
                    <b-button v-if="organization.password.length>=8" style="margin: auto;margin-top:10px;" class="submit-button4" v-on:click="goToView6" variant="clear-blue"><div class="next-button"></div><span>{{$t('global.button_next')}}</span></b-button>
                </div>
                <div style="    display: block;text-align: center;margin-top: 30px;">{{$t('setup.view3_text3')}} <TermsAndConditions /> </div>
            </div>
            <div v-if="view == 6" class="transition-view" ref="view6" key="view6">
                <div class="fit-width">
                    <span v-html="$t('setup.view6_text1', {region: organization.org_name, username: organization.name, password: organization.password})"></span><br/><br/>
                    <b-button style="margin: auto;margin-top:10px;" class="submit-button4" v-on:click="goToLogin" variant="clear-blue"><div class="next-button"></div><span>{{$t('setup.goto_login')}}</span></b-button>
                </div>
            </div>
            <div v-if="view == 7" class="transition-view" ref="view7" key="view7">
                <div class="fit-width">
                    <span v-html="$t('setup.view7_text1')"></span><br/><br/>
                </div>
            </div>
        </transition>
        <div id="setup-footer">
            <div>{{$t('setup.footer1_text')}}</div>
            <div>{{$t('setup.footer2_text')}}</div>
        </div>
        </div>
        
      <div class="div-space"></div>
    </div>
</div>
</template>
<style scoped>

.contact-button{
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
    margin: 10px auto;
    padding: 5px;
    width: 300px;
    background-color: white;
}
.contact-button:hover{
    background-color: #e0f5fd !important;
}
#setup-footer{
    background-color: black; color: white;text-align:center;bottom: 0px;position: absolute;width: 100%;font-size: 12px;padding: 5px;
}
#trans-wrapper{
        width: 799px;
        height: 895px;
        margin:auto;
        background-image: url("../assets/SignupBackground.png");
        overflow: hidden;
}
.setup-header{
    font-size: 24px;
    color: #c147d1;
    margin-bottom: 30px;
}
.setup-linetext{
    font-size: 16px;
    color: black;
    margin-bottom: 20px;
}
    .transition-view{
        text-align:center;position:absolute;width:100%;padding: 50px; text-align: left;
        
    }
    .transition-view > div{
        margin:auto;
        display: grid;
        grid-template-columns: auto;
        
    }
    #setup-main-box{
        top:20px; height:100%;  margin: auto;    text-align: center; display: grid; grid-template-rows: auto max-content max-content auto;
    }

    .righttolefttrans-enter-active, .righttolefttrans-leave-active {
    transition: all 0.5s ease-in;
  }
  .righttolefttrans-enter {
    transform: translateX(100%);
  }
  .righttolefttrans-leave-to {
    transform: translateX(-100%);
  }
</style>
<script>
    
    import router from "../router"
    export default {
    name: "Setup",
    data() {
        return {
            
            slide_transition: "righttolefttrans",
            confirmed: false,
            unimplemented: false,
            payed: false,
            regions: [],
            signup_contacts: [],
            organization:{
                name: "",
                email: "",
                region: 0,
                contact_id: 0,
                code: '',
                password: '',
                agree: 0,
                org_name: "",
                uid: '',
                muid: ''
            },
            organization_state:{
                name_state: null,
                email_state: null,
                region_state: null
            },
            showAlert: false,
            alertText: this.$t('setup.alert_text'),
            view: 1
        }
    },
    methods: {
        
        goToLogin(){
            router.push("/login");
        },
        getRegions(){
            this.$http.post("/api/regions/get", {}).then((response) => {
                if (response.data.success) {
                    this.regions = response.data.regions;
                    this.regions.unshift({value: 0, text: this.$t('setup.select_region')});
                }
            }).catch((errors) => {  console.log(errors);});
        },
        gotOrganizationUid(){
            this.$http.post("/api/region/id/get/byuid", {uid: this.$route.params.org}).then((response) => {
                if (response.data.success) {
                    this.organization.region = response.data.id;
                    this.organization.org_name = response.data.name;
                    this.organization.name = response.data.admin_name;
                    this.view = 5;
                }else{
                    this.view = 7;
                }
            }).catch((errors) => {  console.log(errors);});
        },
        goToView2(){
            /*for(var i=0; i<this.regions.length; i++){
                if(this.regions[i].value == this.organization.region){
                    this.organization.org_name = this.regions[i].text;
                    break;
                }
            }*/

            //if(this.organization.org_name == ""){
            //    this.view = 7;
            //}else{
                this.getSignupContacts();
                this.view = 2;
            //}
        },
        getSignupContacts(){
            this.$http.post("/api/region/signupcontacts/get", {id : this.organization.region}).then((response) => {
                if (response.data.success) {
                    this.organization.org_name = response.data.org_name;
                    this.signup_contacts = response.data.signup_contacts;
                    this.organization.name = response.data.admin_name;

                }else{
                    this.view = 7;
                    //this.signup_contacts = [{name:this.$t('setup.no_contacts'), text: ''}];
                }
            }).catch((errors) => {  console.log(errors);});
        },
        goToView3(cid){
            this.organization.contact_id = cid;
            if(this.organization.contact_id>0){
                this.$http.post("/api/region/signupcode/get", {id : this.organization.region, contact_id: this.organization.contact_id}).then((response) => {
                if (response.data.success) {
                    this.organization.email = response.data.email;
                    this.view = 3;
                }
            }).catch((errors) => {  console.log(errors);});
            }
        },
        goToView4(){
            this.showAlert = false;
            this.$http.post("/api/region/signupcode/check", {id: this.organization.region, code: this.organization.code}).then((response) => {
                if (response.data.success) {
                    this.view = 5;
                    this.organization.uid = response.data.public_uid;
                }else{
                    this.showAlert = true;
                }
            }).catch((errors) => {  console.log(errors);});
        },
        goToView6(){
            if(this.organization.contact_id>0){
                this.organization.muid = this.organization.contact_id;
            }
            this.$http.post("/api/region/enabletrial", {id : this.organization.region, muid: this.organization.muid,
            password: this.organization.password, name: this.organization.name, uid: this.organization.uid}).then((response) => {
                if (response.data.success) {
                    this.view = 6;
                    this.organization.name = response.data.admin_name;
                }
            }).catch((errors) => {  console.log(errors);});
        },
        checkData(){
            this.showAlert = false;
            this.organization_state ={
                name_state: null,
                email_state: null,
                region_state: null
            };
            var allgood = true;
            if(this.organization.name.length ==0){
                allgood = false;
                this.organization_state.name_state = false;
            }
            if(!this.validateEmail(this.organization.email)){
                allgood = false;
                this.organization_state.email_state = false;
            }
            if(this.organization.region == 0){
                allgood = false;
                this.organization_state.region_state = false;
            }
            this.showAlert = !allgood;
            if(!allgood){
                return;
            }
            if(typeof this.organization.region != 'number'){
                this.view = 4;
                 this.$http.post("/api/organization/request/implementation", this.organization).then(() => {
                   
                }).catch(() => { });
                return;
            }
            if(allgood){
                this.requestOrganization();
            }
        },
        validateEmail(mail){
            return (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail));
        },
        requestOrganization(){
            this.$http.post("/api/organization/request", this.organization).then((response) => {
                if (response.data.success) {
                   this.view = 6;
                }else if(response.data.error.code == 1){
                   this.view = 5;
                }
            }).catch(() => { });
        }
    },
    destroyed() {
    },
    mounted() {
        this.setLanguageIfAvailable();
        if(this.$route.params.org){
            this.organization.uid = this.$route.params.org;
            this.organization.muid = this.$route.params.member;
            this.gotOrganizationUid();
        }else{
            this.getRegions();
        }
    }
}
</script>
