<template>
  <div id="public-container" :style="{'grid-template-rows': isCorporateConnections? 'auto 150px max-content auto' : 'auto max-content auto'}">
      <div></div>
      <div v-if="isCorporateConnections"><img style="margin:auto;display:block;" src="../assets/public-cc-banner.png" /></div>
      <div style="max-width: 800px; margin: auto;">
        <div style="font-size: 40px; text-align: center;font-weight: bold;">
            {{member.organization_name}}
        </div>
        <div style="display:grid;grid-template-columns: auto auto;grid-gap: 25px;padding: 40px 0;margin:auto;" class="fit-width">
            <div v-if="isCorporateConnections" style='height: 200px;width: 200px;position: relative;top: 0px;'><img class='member-corner-image'  src='../assets/Picture-CC-backYellow.png'/>
                <div class='circular-member member-graphic' :style="{'background-image': 'url('+member.photo+')'}"></div>
            </div>
            <img v-if="!isCorporateConnections" style="width: 150px;margin: 0 20px;" :src="member.photo"/>
            <div style="font-size: 30px;min-width: 400px;text-align: left;line-height: 35px;grid-gap: 5px;display: grid;">
                <div class="member-name" :style="{'color': isCorporateConnections? '#FFD700' : '#CF2030'}">{{member.name}}</div>
                <div style="font-size: 35px;">{{member.company}}</div>
                <div style="font-weight:bold;">{{member.category}}</div>
                <div v-if="member.phone"><a :href="'tel:'+member.phone">{{member.phone}}</a></div>
                <div><a :href="'mailto:'+member.email">{{member.email}}</a></div>
                <div><a :href='(member.website.startsWith("http") ? "" : "http://") + member.website' target="_blank">{{member.website}}</a></div>
            </div>
            <div style="font-size: 35px;margin:auto;grid-column: 1/3;" class="fit-content">{{member.introduction}}</div>
        </div>
        <div v-if="!isCorporateConnections" style="color: #777;font-size: 20px;">
           {{member.ask}}
        </div>
      </div>
      <div></div>
  </div>
</template>
<style scoped>
#public-container{
  display:grid;  height: 100%; 
}
.member-name{
  font-size: 40px;font-weight:bold;margin-bottom:10px;
}
</style>
<script>
    export default {
    name: "Public",
      data() {
        return {
            member: {name: "", company: '', category: '', phone: '',email: '', website: '', ask: '', photo: '', organization_name: '', introduction: ''}
        }
      },
    methods: {
      getMember(){
          var hash = this.$route.params.h;
          this.$http.post("/api/organization/member/get/bypublichash", {hash: hash}).then((response) => {
            this.member = response.data.member;
        }).catch(() => {});
      }
    },
    mounted() {
        this.getMember();
    }
    }
</script>
