<template>
  <div style="height:100%;width:100%;display: grid; grid-template-rows: auto max-content auto;">
      <div></div>
     <b-alert variant="warning" :show="true" style="margin:auto;text-align:center;color: black;" class="fit-width">
            <span v-html="$t('global.unauthorized_alert')"></span>
    </b-alert>
    <div></div>
  </div>
</template>
<script>
export default {
  name: "Unauthorized",
}
</script>
