<template>
  <div>
    <Header :useruid="this.$route.params.useruid" :title="$t('topics.manage_topics')" :showDate="true"></Header>
    <div class="rel-grid-container">
      <div id="top-div"></div>
      <div >
        <b-table class="table-hover-new" small bordered :items="topics" :fields="fields" v-on:row-clicked="editTopic">
            <template v-slot:cell(disabled)="data">
                <b-form-checkbox v-model="data.item.disabled" v-on:change="updateDisabled(data.item)" value="0" unchecked-value="1" >
                </b-form-checkbox>
          </template>
        </b-table>
        <div class="submit-box-2">
          <b-button v-if="permissions.access || useruid.length>0" class="submit-button4" v-on:click="newTopic" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
        </div>
      </div>
      <div class="div-space"></div>
    </div>
    <Footer :useruid="this.$route.params.useruid"></Footer>
  </div>
</template>
<style scoped>
</style>
<script>
  import router from "../router"
  import draggable from "vuedraggable";
    export default {
    name: "Topics",
    components: {
      draggable
    },
    data() {
      return {
            topics: [],
            useruid: '',
         fields: [
            { key: 'title',
              label: this.$t('topics.topic_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
             { key: 'assigned',
              label: this.$t('topics.assigned_to'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"},
            },
             { key: 'disabled',
              label: this.$t('topics.include_meeting'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" ,"text-align": "center", "width":"200px"},
              tdClass: "actions-centre"
            }
          ],
          
      }
    },
    methods: {
       rowClass(item) {
            if (!item) return
            if (item.disabled==1) return 'disabledClass'
          },
      getTopics() {
        this.$http.post("/api/organization/topics"+(this.useruid==''? "" : "/byuseruid"), { useruid: this.useruid}).then((response) => {
          if (response.data.success) {
            this.topics = response.data.topics;
          }
        }).catch(() => {});
      },
      newTopic() {
        if (this.permissions.access || this.useruid.length>0) {
          if(this.useruid==''){
            router.push("/admin/topics/0");
          }else{
            router.push("/topics/0/"+this.useruid);
          }
        }
      },
      editTopic(item) {
        if (this.permissions.access || this.useruid.length>0) {
           if(this.useruid==''){
            router.push("/admin/topics/" + item.id);
           }else{
             router.push("/topics/"+ item.id+"/"+this.useruid);
           }
        }
      },
      updateDisabled(e){
          this.$http.post("/api/organization/topics/update/disabled"+(this.useruid==''? "" : "/byuseruid"), {id: e.id, disabled: e.disabled, useruid: this.useruid}).then(() => {
        }).catch(() => {});
      },
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-topics"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      if(this.$route.params.useruid){
        this.useruid = this.$route.params.useruid;
      }
      this.addClass(document.getElementById("menu-topics"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      this.getTopics();
    }
  }
</script>
