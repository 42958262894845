<template>
  <div>
    <div>
      <b-form-checkbox-group style="margin: auto;margin-top: 20px;" class="fit-width" v-if="permissions.organization.id!=1" v-model="email.to_leadership" :options="sendOptions">
        </b-form-checkbox-group>
        <div class="form-box" style="grid-template-columns: max-content auto;;    display: grid;width: 75%; max-width:800px;margin: auto;margin-top: 50px;">
          
            <span class="form-box-name">{{$t('sendemail.subject_text')}}</span>
            <b-form-input v-model="email.subject" type="text" style="width:300px;" maxlength="255" />
            <span class="form-box-name">{{$t('sendemail.name_text')}}</span>
            <b-form-input v-model="email.name" type="text" style="width:300px;" maxlength="255" />
            <span class="form-box-name">{{$t('sendemail.email_text')}}</span>
            <b-form-input v-model="email.email" type="text" style="width:300px;" maxlength="255" />
            <!--<span class="form-box-name">{{$t('sendemail.attachment_text')}}</span>
            <div  style="grid-column:2/3; display:grid; grid-template-columns: auto auto;" class="fit-width">
                <b-button class="submit-button4" v-on:click="openDialog" variant="blue">{{ email.file==null? $t('sendemail.choose_attachment') :  $t('sendemail.change_from') +" "+email.filename}}</b-button>
                <b-form-file id='fileid' v-model="email.file" @change="setFile" plain hidden></b-form-file>
                <b-button v-if="email.file!=null" class="submit-button4" v-on:click="email.file=null" variant="blue">{{$t('sendemail.clear_attachment')}}</b-button>
            </div>-->
            <span class="form-box-name" style="grid-column: 1/3;text-align: center;">{{$t('sendemail.body_text')}}</span>
            <div style="grid-column: 1/3;" class="editor">
                <editor-menu-bar :editor="editor">
                    <div class="menubar" slot-scope="{ commands, isActive }">
                    <b-button variant="outline-dark" :class="{ 'is-active': isActive.bold() }" v-on:click="commands.bold">
                        <b>B</b>
                    </b-button>
                    <b-button variant="outline-dark" :class="{ 'is-active': isActive.italic() }" v-on:click="commands.italic">
                        <i>I</i>
                    </b-button>
                    <b-button variant="outline-dark" :class="{ 'is-active': isActive.strike() }" v-on:click="commands.strike">
                        <s>S</s>
                    </b-button>
                    <b-button variant="outline-dark" :class="{ 'is-active': isActive.underline() }" v-on:click="commands.underline">
                        <u>U</u>
                    </b-button>
                    <b-button variant="outline-dark" :class="{ 'is-active': isActive.paragraph() }" v-on:click="commands.paragraph">
                        P
                    </b-button>
                    <b-button variant="outline-dark" :class="{ 'is-active': isActive.heading({ level: 1 }) }" v-on:click="commands.heading({ level: 1 })">
                        H1
                    </b-button>
                    <b-button variant="outline-dark" :class="{ 'is-active': isActive.heading({ level: 2 }) }" v-on:click="commands.heading({ level: 2 })">
                        H2
                    </b-button>
                    <b-button variant="outline-dark" :class="{ 'is-active': isActive.heading({ level: 3 }) }" v-on:click="commands.heading({ level: 3 })">
                        H3
                    </b-button>
                    <b-button variant="outline-dark" :class="{ 'is-active': isActive.bullet_list() }" v-on:click="commands.bullet_list">
                        {{$t('entry.bullet_text')}}
                    </b-button>
                    <b-button variant="outline-dark" :class="{ 'is-active': isActive.ordered_list() }" v-on:click="commands.ordered_list">
                        {{$t('entry.list_text')}}
                    </b-button>
                    <b-button variant="outline-dark" v-on:click="commands.undo">
                        {{$t('entry.undo_text')}}
                    </b-button>
                    <b-button variant="outline-dark" v-on:click="commands.redo">
                        {{$t('entry.redo_text')}}
                    </b-button>
                    </div>
                </editor-menu-bar>
                <editor-content class="editor__content" :editor="editor" style="height:300px;width:100%;overflow-y: auto;overflow-x: hidden;" />
            </div>
        </div>
    </div>
    <b-alert variant="danger" dismissible :show="showAlert" @dismissed="showAlert=false">
        {{alertText}}
    </b-alert>
    <div class="submit-box-2">
        <b-button class="submit-button4" v-on:click="clearEmail" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_clear')}}</span></b-button>
        <b-button class="submit-button4"  v-on:click="showSendConfirm=true;" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_send')}}</span></b-button>
    </div>

    <b-table style="width: 75%; max-width:800px;margin: auto;margin-top: 50px; cursor:pointer;" :per-page="perPage" :current-page="currentPage" v-if="sentEmails.length>0" class="table-hover-new" small bordered :items="sentEmails" :fields="fields" v-on:row-clicked="showEmail">
        <template v-slot:cell(date)="data" >
            {{$d(new Date(data.item.sent), 'short')}}
        </template>
    </b-table>
    <b-pagination v-if="sentEmails.length>perPage" style="max-width:800px;margin:auto;" v-model="currentPage" :total-rows="sentEmails.length" :per-page="perPage" align="fill" size="sm" class="my-0" ></b-pagination>
    <b-modal centered v-model="showEmailModal" :title="preview.subject" >
        <div>
          <div>{{$t('sendemail.to_text')}}: {{preview.to_leadership==0? $t('sendemail.regional_contacts') : $t('sendemail.chapter_leadership')}}</div>
          <div>{{$t('sendemail.name_text')}}: {{preview.name}}</div>
          <div>{{$t('sendemail.email_text')}}: {{preview.reply}}</div>
        <div v-html="preview.body"></div>
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="submit-button4"  v-on:click="showEmailModal=false;" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
        </div>
      </b-modal>
    <b-modal centered v-model="showSendConfirm" :title="$t('sendemail.confirm_send')" >
        <div >
            {{permissions.organization.id==1 || email.to_leadership==1? $t('sendemail.confirm_message') : $t('sendemail.confirm_contacts_message')}}
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="submit-button4" v-on:click="showSendConfirm = false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="submit-button4"  v-on:click="sendEmail" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_send')}}</span></b-button>
        </div>
      </b-modal>
      <div class="div-space"></div>
  </div>
</template>
<style >
</style>
<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
  import {
    Blockquote,
    HardBreak,
    Heading,
    HorizontalRule,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Italic,
    Link,
    Strike,
    Underline,
    History,
  } from 'tiptap-extensions'
export default {
    name: "SendEmail",
    components: {
        EditorContent,
      EditorMenuBar
    },
    watch: {
      '$i18n.locale': {
        handler: function () {
         this.sendOptions= [{value: 0, text: this.$t('sendemail.regional_contacts')},{value: 1, text: this.$t('sendemail.chapter_leadership')}];
         this.fields= [
            {
              key: 'date',
              label: this.$t('sendemail.date_text'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
            { key: 'subject',
              label: this.$t('sendemail.subject_text'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            }
          ];
        },
        deep: true
      }
    },
      data() {
        return {
          sendOptions: [{value: 0, text: this.$t('sendemail.regional_contacts')},{value: 1, text: this.$t('sendemail.chapter_leadership')}],
            showEmailModal: false,
            preview: {subject: "", body: ""},
            showAlert: false,
            alertText: "",
          showSendConfirm: false,
          email: {subject: "", body: "", file: null, filename: "", email: "", name: "", to_leadership: 0},
          editor: new Editor({
            extensions: [
              new Blockquote(),
              new BulletList(),
              new HardBreak(),
              new Heading({ levels: [1, 2, 3] }),
              new HorizontalRule(),
              new ListItem(),
              new OrderedList(),
              new TodoItem(),
              new TodoList(),
              new Bold(),
              new Italic(),
              new Link(),
              new Strike(),
              new Underline(),
              new History(),
            ],
            content: '',
             }),
            sentEmails: [],
            fields: [
            {
              key: 'date',
              label: this.$t('sendemail.date_text'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
            { key: 'subject',
              label: this.$t('sendemail.subject_text'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            }
          ],
         perPage: 20,
        currentPage:1,
        totalRows:0,
        }
      },
    methods: {
        showEmail(item) {
            this.preview = item;
            this.showEmailModal = true;
      },
        clearEmail(){
            this.email = {subject: "", body: "", file: null, filename: "", email: "", name: "", to_leadership: 0};
            this.editor.setContent("");
        },
        getSentEmails(){
            this.$http.post("/api/superadmin/sentemails/get", {}).then((response) => {
                if (response.data.success === true) {
                    this.sentEmails = response.data.sentemails;
                }
            }).catch(() => { });
        },
        sendEmail(){
            this.showAlert = false;
            var body = this.editor.getHTML();
            if(this.email.subject.length == 0 || body.length<8){
                this.alertText = this.$t('sendemail.enter_fields');
                this.showAlert = true;
            }
            let formData = new FormData();
            formData.append("file", this.email.file);
            formData.append("subject", this.email.subject);
             formData.append("to_leadership", this.email.to_leadership);
            formData.append("name", this.email.name);
            formData.append("email", this.email.email);
            formData.append("body", this.editor.getHTML());
            this.$http.post("/api/superadmin/sendemail", formData, {
                headers: {"Content-Type": "multipart/form-data"}
            }).then((response) => {
               if(response.data.success){
                   this.showSendConfirm = false;
                   this.getSentEmails();
                   this.clearEmail();
                   this.$bvToast.toast(this.$t('sendemail.email_sent'), {
                    variant: 'success',
                    solid: true
                    });
               }
            }).catch(() => {
            });
        },
        openDialog() {
            document.getElementById('fileid').click();
          },
        setFile(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length || !this.checkFileSize(files[0]))
            return;
            this.email.file = files[0];
            this.email.filename = files[0].name;
      },
    },
    beforeDestroy() {
      this.editor.destroy()
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-sendemail"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-sendemail"), "active-menu");
      this.getSentEmails();
    }
}
</script>
