<template>
  <div style="display: grid; grid-template-rows: auto max-content auto;margin:auto;height:100%;" class="fit-width">
    <div></div>
    <transition :name="transition_type">
        <div v-if="importStep==1" key="import1">
            <b-button variant="blue" v-on:click="openDialog" style="width:200px;">{{$t('regionimport.select_file')}}</b-button>
            <b-form-file id='fileid' accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="onFileChange($event)" plain hidden></b-form-file>
        </div>
        <div v-if="importStep==2" key="import3">
            <b-table class="table-hover-new" small bordered :items="chapters" :fields="chapterFields">
                <template v-slot:cell(import)="data">
                <b-form-checkbox v-model="data.item.import" value="1" unchecked-value="0" style="margin:auto;">
                </b-form-checkbox>
                </template> 
            </b-table>
            <b-button variant="blue" v-on:click="importChapters" style="margin:auto;">{{$t('regionimport.import_chapters')}}</b-button>
        </div>
        <div v-if="importStep==3" key="import3">
            <b-table class="table-hover-new" small bordered :items="members" :fields="memberFields">
                <template v-slot:cell(import)="data">
                <b-form-checkbox v-model="data.item.import" value="1" unchecked-value="0" style="margin:auto;">
                </b-form-checkbox>
                </template> 
            </b-table>
            <b-button variant="blue" v-on:click="importMembers" style="margin:auto;">{{$t('regionimport.import_members')}}</b-button>
        </div>
        <div v-if="importStep==4" key="import4">
            <div>{{$t('regionimport.success_message')}}</div>
        </div>
    </transition>
    <div></div>
  </div>
</template>
<style scoped>
    .righttolefttrans-enter-active, .righttolefttrans-leave-active {
    transition: all 0.5s ease-in;
  }
  .righttolefttrans-enter {
    transform: translateX(100%);
  }
  .righttolefttrans-leave-to {
    transform: translateX(-100%);
  }
</style>
<script>
export default {
    name: "RegionImport",
      data() {
        return {
            transition_type : "righttolefttrans",
            file: null,
            importStep: 1,
            showUpload: false,
            showAlert: false,
            members: [],
            chapters: [],
            chapterFields: [
                { key: 'name',
                    label: this.$t('regionimport.name_header'),
                    sortable: true,
                    thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
                },
                { key: 'members',
                    label: this.$t('regionimport.members_header'),
                    sortable: true,
                    thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff", 'text-align': 'center'},
                },
                { key: 'import',
                    label: this.$t('regionimport.import_header'),
                    sortable: true,
                    thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff", 'text-align': 'center'},
                }
            ],
            memberFields: [
                { key: 'name',
                    label: this.$t('regionimport.name_header'),
                    sortable: true,
                    thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
                },{ key: 'company',
                    label: this.$t('regionimport.company_header'),
                    sortable: true,
                    thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
                },{ key: 'email',
                    label: this.$t('regionimport.email_header'),
                    sortable: true,
                    thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
                },{ key: 'phone',
                    label: this.$t('regionimport.phone_header'),
                    sortable: true,
                    thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
                },{ key: 'import',
                    label: this.$t('regionimport.import_header'),
                    sortable: true,
                    thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff", 'text-align': 'center'},
                }
            ],
        }
    },
    methods: {
        openDialog() {
            document.getElementById('fileid').click();
        },
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length || !this.checkFileSize(files[0]))
            return;
            this.file = files[0];
            this.previewChapters();
        },
        previewChapters(){
            this.showAlert = false;
            this.showUpload = true;
            let formData = new FormData();
            formData.append("file", this.file);
            this.$http.post("/api/organization/region/import/excel", formData, {
                headers: {"Content-Type": "multipart/form-data"}
            }).then((response) => {
                this.showUpload = false;
                if (response.data.success == true) {
                    this.importStep = 2;
                    this.chapters = response.data.chapters;
                    this.members = response.data.members;
                } else {
                    this.showAlert = true;
                    this.alertText = response.data.error;
                }
            }).catch((errors) => {
                this.showUpload = false;
                this.saveVariant = "blue";
                this.showAlert = true;
                this.alertText = JSON.stringify(errors);
            });
        },
        importChapters(){
            var cs = this.chapters.filter(g => g.import == 1);
            if(cs.length>0){
                this.$http.post("/api/region/chapters/import/all", { chapters: cs}).then((response) => {
                    if (response.data.success === true) {
                        this.importStep = 3;
                    } else {
                        this.showAlert = true;
                        this.alertText = response.data.error;
                    }
                }).catch(() => { });
            }else{
                this.importStep = 3;
            }
        },
        importMembers(){
            var ms = this.members.filter(g => g.import == 1);
            if(ms.length>0){
                this.$http.post("/api/region/chapters/members/import/all", { members: ms}).then((response) => {
                    if (response.data.success === true) {
                        this.importStep = 4;
                    } else {
                        this.showAlert = true;
                        this.alertText = response.data.error;
                    }
                }).catch(() => { });
            }else{
                this.importStep = 4;
            }
        }
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-regional"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-regional"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
    }
}
</script>
