<template>
  <div>
    <Header v-if="memberReturned" :resethash="this.$route.params.h" :title="has_pass? $t('reset.reset_login') : $t('reset.create_login')" :showDate="false"></Header>
    <div v-if="memberFound && memberReturned" style="top: 40px;position: relative;">
      <div id="reset-description">
        <div class="fit-width" style="margin:auto;">
          <span class="form-box-name" style="margin: 0px 5px;">{{$t('reset.member_text')}}:</span> <span style="font-weight:bold;">{{member_name}}</span>
        </div>
      </div>
      <form v-on:submit.prevent="checkAndSetPassword" id="form-fix">
        <span class="form-box-title">{{has_pass? $t('reset.password_reset') : $t('reset.create_password')}}</span>
        <div class="form-box" style="grid-template-columns: auto auto;">
          <span class="form-box-name">{{$t('reset.username_text')}}:</span><span style="margin: 10px 5px; color:#555;" class="form-box-input">{{admin? member_name : email}}</span>
          <span class="form-box-name">{{$t('reset.new_password')}}</span><b-form-input :state="password.length==0? null : password.length>=8 " v-model="password" type="password" class="form-box-input" required></b-form-input>
          <span class="form-box-name">{{$t('reset.retype_password')}}</span><b-form-input :state="password.length==0? null : (password===password_retype && password_retype.length>0)" v-model="password_retype" type="password" class="form-box-input" required></b-form-input>
          <span style="font-size: 12px;text-align:left;color:#aaa;grid-column: 2/3;">*{{$t('global.password_requirement')}}</span>
        </div>
        <div v-if="showAlert" >
          <b-alert variant="danger"
                   dismissible
                   :show="showAlert"
                   @dismissed="showAlert=false">
            {{alertText}}
          </b-alert>
        </div>
        <div class="submit-box-2">
          <b-button style="margin:20px auto;" class="submit-button4" type="submit" variant="clear-blue"><div class="login-button"></div><span>{{$t('reset.set_password')}}</span></b-button>
        </div>
      </form>
      <div class="div-space"></div>
    </div>
    <div v-if="!memberFound && memberReturned" style="width: 400px; margin: 50px auto;">
          <b-alert variant="warning"
                   :show="true">
            {{$t('reset.link_expired')}}
          </b-alert>
    </div>
  </div>
</template>
<style scoped>
#reset-description{
  background-color: #eeeeee;padding: 10px; margin: 20px auto; width: 800px;
}
#rest-description >div{
  display: grid;grid-template-columns: max-content max-content;margin: auto;
}
#reset-logo-box{
  display: grid;grid-template-columns: max-content max-content max-content;grid-gap: 20px;margin: auto; 
}
  
</style>
<script>
    
    import router from "../router"
    export default {
    name: "Password",
      data() {
        return {
          saveVariant: 'blue',
          showAlert: false,
          password: "",
          password_retype: "",
          alertText: "",
          member_name: "",
          organization_name: "",
          has_pass: false,
          admin: false,
          email: "",
          memberFound: false,
          memberReturned: false
        }
      },
    methods: {
      getMember() {
        var hash = this.$route.params.h;
        this.$http.post("/api/member/get/bypasswordhash", {hash: hash}).then((response) => {
        
          if (response.data.success == true) {
            this.memberFound = true;
            this.member_name = response.data.member_name;
            this.organization_name = response.data.organization_name;
            this.has_pass = response.data.has_pass==1;
            this.admin = response.data.admin==1;
            this.email = response.data.email;
          } 
          this.memberReturned = true;
        }).catch(() => { this.memberReturned= true;});
      },
      checkAndSetPassword(){
        var hash = this.$route.params.h;
        this.$http.post("/api/member/check/account/byhash", {hash: hash}).then((response) => {
          if (response.data.success && response.data.account) {
            this.showAlert = true;
            this.alertText = this.$t('reset.account_error');
          }else{
            this.setPassword();
          }
        }).catch(() => {
          
        });
      },
      setPassword() {
        var hash = this.$route.params.h;
        this.showAlert = false;
        if(this.password.length<8){
          this.showAlert = true;
          this.alertText = this.$t('reset.alert_length');
          return;
        }
        if (this.password === this.password_retype) {
          this.$http.post("/api/password/update", { hash: hash, new_pass: this.password, new_pass_retype: this.password_retype }).then((response) => {
            if (response.data.success == true) {
              router.push("/login");
            } else {
              this.showAlert = true;
              this.alertText = response.data.error;
            }
          }).catch(() => { this.$bvToast.show('serverError');});
        } else {
          this.showAlert = true;
          this.alertText = this.$t('reset.alert_match');
        }
      }
    },
    mounted() {
      this.getMember();
    }
    }
</script>
