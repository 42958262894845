<template>
  <div  id="file-box" style="height:100%;width:100%;">
    <div v-if="fileReturned && fileFound" id="top-home-bar" style="grid-template-columns: max-content auto max-content; height:80px;">
      <div class="fit-content" style="display:grid; grid-template-columns: max-content max-content;margin:0px;">
        <div id="home-logo" class="fit-content pre-format" style="height:100%;">
        <div v-on:click="toggleFullScreen" id="fullscreen-overlay" ><div class="fit-content">{{$t('global.fullscreen')}}</div></div>
      </div>
         <div style="display:grid;grid-template-rows: auto auto auto;text-align: left; line-height: 12px;">
          <span v-if="windowWidth>668" id="home-chapter-name">{{organization_name}}</span>
          <!--<div  v-if="windowWidth>668" style="font-weight: bold;    padding-left: 10px;font-size: 15px;">{{$t('general.control_title'+fileData.control_type)}}</div>-->
          <div  v-if="windowWidth>668" style="  padding-left: 10px;font-size: 15px;">{{fileData.day}}</div>
          <div v-if="windowWidth>668" id="file-name">{{fileData.file_path}}</div>
        </div>
        
        </div>
        <div  style="display:grid;grid-template-rows: auto min-content auto;text-align: center;">
          <div></div>
          <div id="below-text" style="font-size:15px; font-weight:bold;">
              <div v-if="id_string != undefined && id_string.length>0" style="margin:auto;">
                <span style="color:green;">{{$t('global.can_control_1')}}</span>
              </div>
              
              <div v-if="id_string != undefined && id_string.length==0" style="margin:auto;">
                <span style="color:red;">{{$t('global.cant_control_1')}}</span>
              </div>
              <div class="fit-content" style="    margin: 0px auto;grid-gap: 50px; display: grid;grid-template-columns: auto auto auto;">
                <b-button v-if="loaded" :disabled="pdfPage==1" v-on:click.stop="goToPresentationSlide(pdfPage-1)" class="file-control-button width-button"  :class="{ 'grey-arrow-left': pdfPage==1, 'blue-arrow-left': pdfPage>1}" variant="clear-blue"></b-button>
                <div>
                  <div v-if="!(id_string != undefined && id_string.length>0 && current_time>=0)" id="preview-text">{{$t('general.preview_text')}}</div>
                  <div v-if="(id_string != undefined && id_string.length>0 && current_time>=0)" :id="current_time>=60? 'timer-text' : 'timer-text-low'">{{getTimeString()}}</div>
                  <div v-if="maxPages>0" style="font-size:12px;">{{pdfPage+" "+$t('global.of_text')+" "+maxPages}}</div>
                </div>
                <b-button v-if="loaded" :disabled="pdfPage==maxPages" v-on:click.stop="goToPresentationSlide(pdfPage+1)" class="file-control-button width-button" :class="{ 'grey-arrow-right': pdfPage==maxPages, 'blue-arrow-right': pdfPage<maxPages}" variant="clear-blue" ></b-button>
            </div>
          </div>
          <div></div>
        </div>
        <div style="display:grid; grid-template-columns:repeat(1,1fr);margin-left:20px;">
          <div v-if="(window && (window.opener != null || window.history.length == 1))" class="clickable"  id="portal-exit-button" v-on:click="logout" style="padding: 10px;"><b-img :src="getImgUrl('nav-Close-Gray.png')" /></div>
        </div>
       </div>
    <div id="page-wrapper" v-if="fileReturned && fileFound">
      <div id="side-wrapper"   style="height:100%; overflow-y:scroll;padding:20px;width:240px;text-align:center;">
        <div class="fit-height" style="display:grid; grid-gap:20px;width:180px;">
           <div :id="'preview_slide_'+i" class="preview-slide fit-height" :class="{'active-page': i==pdfPage}"  v-for="i in maxPages" :key="i" :style="{'cursor': 'pointer'}" v-on:click="goToPage(i)">
          <pdf :src="pdfPath" :page="i" ></pdf>
          <span>{{i}}</span>
        </div>
        </div>
      </div>
      <div style="width:100%; height:100%;padding:10px;max-height: 100%;overflow: hidden;">
        <div style="width:100%; height:100%;position: relative;">
          <div id="pdf-wrapper" >
              <pdf  :src="pdfPath" :page="pdfPage" @num-pages="syncPdfController" :maxHeightDiv="maxHeightDiv" ></pdf>
          </div>
          <div v-if="loaded && pdfPage>1" v-on:click.stop="goToPresentationSlide(pdfPage-1)" style="position:absolute;left:0%;top:0%;z-index:100; height:100%;width:50%;cursor:pointer;"></div>
          <div v-if="loaded && pdfPage<maxPages" v-on:click.stop="goToPresentationSlide(pdfPage+1)" style="position:absolute;left:50%;top:0%; height:100%;z-index:100;width:50%;cursor:pointer;"></div>
        </div>
      </div>
      
    </div>
    <div v-if="fileReturned && !fileFound" style="height:100%;width:100%;display: grid; grid-template-rows: auto max-content auto;">
        <div></div>
        <div style="max-width: 600px;margin:auto;width: 100%;text-align:center;">
            <b-alert variant="warning" :show="!fileFound" style="width: 100%;text-align:center;color:black;">
                <span v-html="$t('general.file_not_found')"></span>
            </b-alert>
        </div>
        <div></div>
    </div>
  </div>
</template>
<style scoped lang="scss">
#timer-text-normal{
  font-weight: normal;
  color: $VUE_APP_COLOUR_MAIN;
  text-align: center;
}
#timer-text-low{
  font-weight: normal;
  color: red;
  text-align: center;
}
#preview-text{
  font-weight: bold;
  color: $VUE_APP_COLOUR_MAIN;
  text-align: center;
}
#file-name{
  color:$VUE_APP_COLOUR_MAIN; margin-left: 5px;
}
#page-wrapper{
  position: relative;margin: auto;height:calc(100% - 80px); width:100%;display:grid;grid-template-columns: 220px calc(100% - 220px);
}
.preview-slide{
  width:160px;
  padding:3px;
}
#controller-header{
  display: grid; padding: 3px;border-bottom:1px solid rgb(0, 174, 239);
}
#pdf-wrapper{
  margin: auto;
  width:100%;
  height:100%;
}
.blue-arrow-left{
  background: url("../assets/Prev-Blue.png") transparent center no-repeat !important;
  filter: $VUE_APP_COLOUR_MAIN_FILTER;
}
.grey-arrow-left{
  background: url("../assets/Prev-Gray.png") transparent center no-repeat !important;
}
.blue-arrow-right{
  background: url("../assets/Next-Blue.png") transparent center no-repeat !important;
  filter: $VUE_APP_COLOUR_MAIN_FILTER;
}
.grey-arrow-right{
  background: url("../assets/Next-Gray.png") transparent center no-repeat !important;
}
.file-control-button{
   width: 36px !important;
    height: 40px!important;
  background-size: 100% 100% !important;
  background-color: transparent !important;
  margin: auto;
}
.active-page{
  border-right: 5px solid $VUE_APP_COLOUR_MAIN;
}
@media (max-width: 755px) {
  #side-wrapper{
    display: none;
  }
  #page-wrapper{
    grid-template-columns: 100%;
  }
}
</style>
<script>
import favicon from "../assets/favicon-present.png";
import pdf from 'vue-pdf'
    export default {
    name: "FileControl",
     components: {
      pdf
    },// 
    watch: {
      '$i18n.locale': {
        handler: function () {
          document.title = this.$t('global.document_title') +" - "+ this.file_name;
        },
        deep: true
      },
    },
      data() {
        return {
          fileReturned: false,
          fileFound: false,
            client: {},
            id_string: "",
            pdfPage: 1,
            pdfPath: "",
            maxHeightDiv: "pdf-wrapper",
            maxPages: 1,
            filehash: "",
            loaded: false,
            organization_name: "",
            windowWidth: 1000,
            file_name: "",
            current_time: -1,
            timer: {interval: null, end_time: 0,disabled:1,maxTime: 0},
            fileData : {file_path: '', control_type: 0, day: ""},
            file_id_string : ""
        }
      },
    methods: {
      goToPage(i){
        this.goToPresentationSlide(i);
      },
      logout() {
      if(window.opener != null || window.history.length == 1){
            window.close()
          }
      },
       getDates(org) {
          this.$http.post("/api/organization/dates/noauth", {org: org}).then((response) => {
            if (response.data.success === true) {
              this.next_meeting = response.data.next_formatted;
              this.orgid = response.data.id;
              this.next_meeting_date = this.next_meeting.length > 0 ? new Date("" + this.next_meeting.split("-").join("/") + " 00:01") : new Date();
              this.organization_name = response.data.name;
            }
          }).catch((e) => { console.log(e);});
          
        },
        getTimeString(){
          return ((Math.floor(this.current_time/60)>0? Math.floor(this.current_time/60)+":" : "")+((Math.floor(this.current_time)%60) < 10 && Math.floor(this.current_time/60)>0? "0"+(Math.floor(this.current_time)%60) : (Math.floor(this.current_time)%60)))
        },
    goToPresentationSlide(i){
        this.pdfPage = i;
        if(this.id_string.length>0){
            this.client.socket.emit("goto", { id_string: this.id_string, section_id: 0, slide_id: 0, member_id: 0, externalPresentation: true, presentation_slide: i });
        }
      },
        syncPdfController(pages){
            this.loaded = true;
            this.maxPages = pages;
        },
         onResize() {
        if(document.getElementById("file-box")){
          this.windowWidth=document.getElementById("file-box").parentElement.offsetWidth;
        }
      },
      auth(){
          this.filehash = this.$route.params.h;
          var self = this;
          this.fileReturned = false;
            this.fileFound = false;
        if(self.filehash.length>0){
            self.$http.post("/api/organization/file/get/byhash", {hash: self.filehash}).then((response) => {
                if(response.data.success){
                  self.file_name = response.data.file_path;
                  self.timer.maxTime = response.data.time;
                  self.fileData = response.data;
                  document.title = self.$t('global.document_title') +" - "+ self.file_name;
                  self.getDates(response.data.org);
                    self.client.socket = self.$io({transports: ['websocket'], upgrade: false});
                    self.pdfPath = "./pdfs/"+response.data.url;
                    self.client.socket.on("presentation_info", function (data) {
                        if (data.showing) {
                            if(self.id_string==""){
                              self.timer.end_time = (new Date()).getTime() / 1000 + self.timer.maxTime;
                              if(self.fileData.control_type!=1){
                                 self.startTimer();
                              }
                            }
                            self.id_string = data.id_string;
                        }else{
                            self.id_string = "";
                        }
                        if(data.slide){
                            self.$set(self,'pdfPage',data.slide);
                        }
                    });
                    self.client.socket.on("sync_timer", (data) => {
                        self.timer.end_time = data.end_time;
                        if(data.timer_playing){
                          self.startTimer();
                        }else{
                          self.stopTimer();
                        }
                        
                    });
                    self.client.socket.on("disconnect", () => {
                        
                    });
                    self.file_id_string = response.data.file_id_string;
                    self.client.socket.emit("register_controller", {file_hash: self.file_id_string, org: response.data.org });
                    self.client.socket.on("reconnect", () => {
                        self.client.socket.emit("register_controller", {file_hash: self.file_id_string, org: response.data.org });
                    });
                     this.fileFound = true;
                      this.fileReturned = true;
                }else{
                   this.fileFound = false;
                  this.fileReturned = true;
                }
                
            }).catch(() =>{
               this.fileFound = false;
                this.fileReturned = true;
            });
        }
      },
      stopTimer(){
        if(this.timer.interval != null){
          clearInterval(this.timer.interval);
          this.timer.interval = null;
        }
      },
      startTimer(){
        if(this.timer.interval != null){
          clearInterval(this.timer.interval);
          this.timer.interval = null;
        }
        this.timer.interval = setInterval(() => {
              var secs = this.timer.end_time - (new Date()).getTime() / 1000;
              if (secs > 0) {
                this.current_time = secs;
              } else {
                clearInterval(this.timer.interval);
                this.timer.interval = null;
                this.current_time = 0;
              }
            }, 50);
      },
      stopTimer(){
        if(this.timer.interval != null){
          clearInterval(this.timer.interval);
          this.timer.interval = null;
        }
        
        var secs = this.timer.end_time - (new Date()).getTime() / 1000;
              if (secs > 0) {
                this.current_time = secs;
              }
      },
      onPress(event) {
        if (event.keyCode == 37 && this.pdfPage>1) {
            this.goToPresentationSlide(--this.pdfPage)
        }else if (event.keyCode == 39 && this.pdfPage<this.maxPages){
          this.goToPresentationSlide(++this.pdfPage)
        }
      }
    },
    
     created(){
      window.addEventListener("resize", this.onResize);
      document.addEventListener("keydown", this.onPress);
    },
    destroyed() {
      clearInterval(this.timeInterval);
      window.removeEventListener("resize", this.onResize);
      document.removeEventListener("keydown", this.onPress);
    },
    mounted() {
      (function() {
          var link = document.querySelector("link[rel~='icon']");
          if (!link) {
              link = document.createElement('link');
              link.rel = 'icon';
              document.getElementsByTagName('head')[0].appendChild(link);
          }
          link.href = favicon;
      })();
      this.onResize();
        this.auth();
        
    }
    }
</script>
