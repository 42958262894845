<template>
  <div>
    <div class="rel-grid-container">
      <div id="top-div"></div>
        <span class="form-box-title">{{$t('super.source_slide')}}</span>
        <div  class="form-box" style="grid-template-columns: max-content auto;max-width: 600px;margin:auto;">
          <span  class="form-box-name">{{$t('super.chapter_text')}}</span><b-form-select v-model="slideCopy.org_id" value-field="id" text-field="name" :options="chapters" class="mb-3" v-on:change="getSections"/>
          <span v-if="slideCopy.org_id>0" class="form-box-name">{{$t('super.section_text')}}</span><b-form-select v-if="slideCopy.org_id>0" v-model="slideCopy.section_id" value-field="id" text-field="name" :options="sections" class="mb-3" v-on:change="getSlides" />
          <span v-if="slideCopy.section_id>0" class="form-box-name">{{$t('super.slide_text')}}</span><b-form-select v-model="slideCopy.slide_id" v-if="slideCopy.section_id>0" value-field="id" text-field="name" :options="slides" class="mb-3" v-on:change="getCopyChapters" />
            <b-button v-on:click="clearSearch" class="submit-button4" style="grid-column: 1/3; margin:auto;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('super.clear_button')}}</span></b-button>
        </div>
        <br/>
        <b-table v-if="slideCopy.slide_id >0" class="table-hover-new" small bordered :items="copyChapters" :fields="fields" >
            <template v-slot:cell(selected)="data">
              <b-form-checkbox :disabled="data.item.section_id == null" v-model="data.item.selected" value="1" unchecked-value="0" style="margin:auto;">
            </b-form-checkbox>
            </template>
          <template v-slot:cell(section)="data">
              {{data.item.section_id != null? 'Y': 'N'}}
            </template>
             <template v-slot:cell(slide)="data">
              {{data.item.section_id != null? (data.item.slide_id != null? 'Y': 'N'): ''}}
            </template>
          <template v-slot:cell(logAs)="data">
            <b-button v-if="data.item.id>0"  style="position:relative;font-size:16px;" size="sm" @click.stop="loginAsChapter(data.item)" variant="light-blue-outline">
              {{$t('organizations.login_as')}}
            </b-button>
          </template>
        </b-table>
        <b-alert style="margin: 20px auto;" class="fit-width" :variant="alertStatus" dismissible :show="showAlert" @dismissed="showAlert=false">{{alertText}}</b-alert>
        <div class="submit-box-2">
            <b-button v-if="slideCopy.slide_id>0 && copyChapters.length>0" v-on:click="copySlide" class="submit-button4" variant="clear-blue"><div class="save-button"></div><span>{{$t('super.copy_slide')}}</span></b-button>
        </div>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
</style>
<script>
import router from "@/router"
    export default {
    name: "SlideCopy",
    data() {
    return {
        chapters: [],
        sections: [],
        slides: [],
        showAlert: false,
        alertText: '',
        alertStatus: 'danger',
        slideCopy: {org_id: 0, section_id: 0, slide_id: 0},
        copyChapters: [],
        fields: [{
              key: 'selected',
              label: this.$t('super.select_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff", "text-align": "center"},
              tdClass: "actions-centre"
            },
             { key: 'chapter',
              label: this.$t('super.chapter_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
            { key: 'section',
              label: this.$t('super.section_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" , "text-align": "center"},
              tdClass: "actions-centre"
            },
             { key: 'slide',
              label: this.$t('super.slide_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" , "text-align": "center"},
              tdClass: "actions-centre"
            },
            { key: 'logAs',
              label: "",
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" , "text-align": "center"},
              tdClass: "actions-centre"
            },
          ],
    }
    },
    methods: {
        clearSearch(){
            this.slideCopy.org_id = 0;
            this.sections = [];
            this.slideCopy.section_id = 0;
            this.slides = [];
            this.slideCopy.slide_id = 0;
        },
        loginAsChapter(org){
            this.$http.post("/api/login/tochild", {id: org.id}).then((response) => {
                if(response.data.success){
                this.permissions.access = response.data.permissions.access;
                this.permissions.admin = response.data.permissions.admin;
                this.permissions.organization = response.data.permissions.organization;
                this.permissions.user = response.data.permissions.user;
                document.title = this.$t('global.document_title_admin') +" "+ this.permissions.organization.name;
                router.push(this.permissions.organization.rst? "/admin/dashboard" : "/admin/regiondash");
                }
            }).catch((errors) => {console.log(errors);});
        },
        copySlide(){
            this.showAlert = false;
            if(this.slideCopy.slide_id>0 && this.copyChapters.length>0){
                this.slideCopy.copyChapters = this.copyChapters;
                this.$http.post("/api/regional/slidecopy", this.slideCopy).then((response) => {
                    if (response.data.success) {
                      this.alertStatus="success";
                      this.alertText = this.$t('super.copy_success', {deleted: response.data.deleted, created: response.data.created});
                    }else{
                        this.alertStatus="danger";
                        this.alertText = this.$t('super.copy_fail');
                    }
                    this.showAlert = true;
                }).catch(() => {
                    this.alertStatus="danger";
                    this.alertText = this.$t('super.copy_fail');
                    this.showAlert = true;
                });
            }
        },
        getCopyChapters(){
            if(this.slideCopy.slide_id>0){
                var section_name = this.sections.filter(v => v.id == this.slideCopy.section_id)[0].name;
                var slide_name = this.slides.filter(v => v.id == this.slideCopy.slide_id)[0].name;
                var org_type = this.chapters.filter(v => v.id == this.slideCopy.org_id)[0].official;
                this.$http.post("/api/regional/slidecopy/chapters", {org_id: this.slideCopy.org_id, section_name: section_name, slide_name: slide_name, org_type: org_type}).then((response) => {
                    if (response.data.success) {
                        for(var i=0; i<response.data.chapters.length; i++){
                            response.data.chapters[i].selected = response.data.chapters[i].section_id!=null? 1 : 0;
                        }
                        this.copyChapters= response.data.chapters;
                    }
                }).catch(() => {});
            }
            
        },
        getChapters() {
            this.$http.post("/api/organization/get/children/select", {}).then((response) => {
                if (response.data.success) {
                    this.chapters = response.data.children;
                    this.chapters.unshift({id: 0, name: this.$t('super.select_chapter'), official:-1});
                }
            }).catch(() => {});
        },
        getSections() {
            if(this.slideCopy.org_id==0){
                this.sections = [];
                this.slideCopy.section_id = 0;
                this.slides = [];
                this.slideCopy.slide_id = 0;
                return;
            }
            this.$http.post("/api/chapter/sections/select/all", this.slideCopy).then((response) => {
                if (response.data.success) {
                    this.sections = response.data.sections;
                    this.sections.unshift({id: 0, name: this.$t('super.select_section')});
                }
            }).catch(() => {});
        },
        getSlides() {
            if(this.slideCopy.section_id==0){
                this.slides = [];
                this.slideCopy.slide_id = 0;
                return;
            }
            this.$http.post("/api/section/slides/select/all", this.slideCopy).then((response) => {
                if (response.data.success) {
                    this.slides = response.data.slides;
                    this.slides.unshift({id: 0, name: this.$t('super.select_slide')});
                }
            }).catch(() => {});
        },
        
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-admin"), "active-menu");
      this.removeClass(document.getElementById("menu-copyslide"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-admin"), "active-menu");
      this.addClass(document.getElementById("menu-copyslide"), "active-menu");
      this.getChapters();
    }
    }
</script>
