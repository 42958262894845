<template>
  <div style="height:100%;">
    <Header :speakerhash="this.$route.params.h" :showDate="!isTopic" :title="$t(pageType +'.speaker_setup')" ></Header>
    <div  v-if="speakerFound && speakerReturned" style="top: 40px;position: relative;">
      <Tip :tipNumber="23"></Tip>
      <div>
        <form v-on:submit.prevent="checkFileThenupload" style="width: 800px;margin:auto;">
          <div style="background-color: #eeeeee;padding: 10px;">
            <div class="fit-width" id="speaker-description">
              <span class="form-box-name" style="margin: 0px 5px;">{{$t(pageType +'.speaker_name')}}:</span>
              <span style="font-weight:bold;">{{presentation.name}}</span>
              <span v-if="presentation.uploaded" class="form-box-name" style="margin: 0px 5px;">{{$t(pageType +'.last_updated')}}:</span>
              <span v-if="presentation.uploaded">{{$d(new Date(presentation.uploaded.split("-").join("/").replace("Z", " GMT")), 'long')}}</span>
            </div>
          </div>
          <div style="text-align:center;color: rgb(0, 174, 239);">{{$t(pageType +'.instructions1')}}</div>
          <div style="text-align:center;color: rgb(0, 174, 239);">{{$t(pageType +'.instructions2')}}</div>
          <div style="text-align:center;color: rgb(0, 174, 239);">{{$t('preparer.valid_files')}} <span style="text-decoration: underline;" class="clickable" v-on:click="showValidFileExts = true;">{{$t('preparer.see_full_list')}}</span></div>
         
          <div v-if="!isTopic">
            <span class="form-box-title">{{$t(pageType +'.speaker_bio')}}</span>
            <div class="form-box" style="grid-template-columns: auto;display: block;">
              <div class="editor">
                <editor-menu-bar :editor="editor">
                  <div class="menubar" slot-scope="{ commands, isActive }">
                    <b-button variant="outline-dark" :class="{ 'is-active': isActive.bold() }" v-on:click="commands.bold">
                      <b>B</b>
                    </b-button>
                    <b-button variant="outline-dark" :class="{ 'is-active': isActive.italic() }" v-on:click="commands.italic">
                      <i>I</i>
                    </b-button>
                    <b-button variant="outline-dark" :class="{ 'is-active': isActive.strike() }" v-on:click="commands.strike">
                      <s>S</s>
                    </b-button>
                    <b-button variant="outline-dark" :class="{ 'is-active': isActive.underline() }" v-on:click="commands.underline">
                      <u>U</u>
                    </b-button>
                    <b-button variant="outline-dark" :class="{ 'is-active': isActive.paragraph() }" v-on:click="commands.paragraph">
                      P
                    </b-button>
                    <b-button variant="outline-dark" :class="{ 'is-active': isActive.heading({ level: 1 }) }" v-on:click="commands.heading({ level: 1 })">
                      H1
                    </b-button>
                    <b-button variant="outline-dark" :class="{ 'is-active': isActive.heading({ level: 2 }) }" v-on:click="commands.heading({ level: 2 })">
                      H2
                    </b-button>
                    <b-button variant="outline-dark" :class="{ 'is-active': isActive.heading({ level: 3 }) }" v-on:click="commands.heading({ level: 3 })">
                      H3
                    </b-button>
                    <b-button variant="outline-dark" :class="{ 'is-active': isActive.bullet_list() }" v-on:click="commands.bullet_list">
                      {{$t('presentation.bullet_text')}}
                    </b-button>
                    <b-button variant="outline-dark" :class="{ 'is-active': isActive.ordered_list() }" v-on:click="commands.ordered_list">
                      {{$t('presentation.list_text')}}
                    </b-button>
                    <b-button variant="outline-dark" v-on:click="commands.undo">
                      {{$t('presentation.undo_text')}}
                    </b-button>
                    <b-button variant="outline-dark" v-on:click="commands.redo">
                      {{$t('presentation.redo_text')}}
                    </b-button>
                  </div>
                </editor-menu-bar>
                <editor-content class="editor__content" :editor="editor" style="height:200px;width:100%;overflow-y: auto;overflow-x: hidden;" />
              </div>
              <div>
                <span>{{$t('entry.recommended_text')+" "+bio.recommended_words+" "+$t('entry.words_text')}}</span>
                <span style="float:right;">{{$t(pageType +'.your_bio')}} <span :style="{color: num_color}">{{getNumWords()}}</span> {{$t('entry.words_text')}}</span>
              </div>
              <div v-if="showOverMax">
                <b-alert variant="warning"
                         dismissible
                         :show="showOverMax"
                         @dismissed="showOverMax=false">
                  {{$tc('entry.over_max',bio.max_words, {n: bio.max_words})}}
                </b-alert>
              </div>
            </div>
            <span v-if="isCorporateConnections" class="form-box-title">{{$t(pageType +'.topic_audience')}}</span>
            <div v-if="isCorporateConnections" class="form-box" >
              <span class="form-box-name">{{$t('presentation.topic_text')}}</span><b-form-input v-model="presentation.topic" max="255" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
                <span class="form-box-name">{{$t('presentation.target_audience')}}</span><b-form-textarea style="margin-bottom: 10px;"  v-model="presentation.target_audience" placeholder="" :rows="3" class="form-box-input"/>
                <span class="form-box-name">{{$t('presentation.value_proposition')}}</span><b-form-textarea v-model="presentation.value_proposition" placeholder="" :rows="3" class="form-box-input"/>
              </div>
          </div>
           <div>
            <span class="form-box-title">{{$t(pageType +'.speaker_presentation')}}</span>
            <div class="form-box" style="grid-template-columns: auto;position:relative;" v-on:drop="dropHandler"  @dragover.prevent  @dragenter.prevent @dragleave.prevent>
              <div v-show="showDrag" id="drop-overlay">
                <div></div>
                <div v-if="presentation.files.length<5">{{$t('preparer.drop_text')}}</div>
                <div v-if="presentation.files.length>=5">{{$t('preparer.max_files')}}</div>
                <div></div>
              </div>
              <div style="display:grid; grid-template-columns: auto max-content;text-align: center;margin-bottom: 20px;"><span v-html="$t('preparer.file_notes')"></span> <b-button v-if="getIsMac()" style="padding:0; width:70px;font-size:14px;" variant="link" v-on:click="showMacNote=true;">{{$t('entry.mac_note')}}</b-button></div>
              <div v-for="file in presentation.files" :key="file" style="display:grid; grid-template-columns: auto max-content;">
                <div style="text-align:center; grid-column:1/3;color: #003c80;font-size: 20px;">{{$t('preparer.file_text')+" #"+(presentation.files.indexOf(file)+1)}}</div>
                <b-button v-if="presentation.files.length>1 || (file.link != '' || file.file != null) " v-on:click="removeFileLink(file)" class="submit-button4" variant="clear-blue" style="margin:auto;grid-column:2/3;grid-row:2/5;"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
                <b-form-checkbox class="setting-check" v-model="file.type" :value="1" :unchecked-value="2" style="grid-column:1/2;">{{$t('preparer.power_point')}}</b-form-checkbox>
                <div style="display:grid; grid-template-columns: max-content auto;grid-column:1/2;" v-if="file.type==1">
                  <b-button  class="submit-button4" v-on:click="openFileDialog(file)" variant="blue" style="margin: auto 20px;">{{file.link.length>0 && file.file_path.length>0? $t('entry.reselect_presentation') : $t('preparer.upload_pp')}}</b-button>
                  <div v-if="file.filename && file.filename.length>0" id="preparer-filename" class="cut-filename">{{file.filename}}</div>
                  <div v-if="(!file.filename || file.filename.length==0)  && file.link.length>0 && file.file_path.length>0" style="text-align:center;line-height: 12px;margin:auto;">
                    <div class="cut-filename">{{file.file_path}}</div>
                    <div><b-button variant="link" v-on:click="viewFile(file.hash)" style="padding:0;border: none;font-weight: bold;">{{$t(pageType+'.speaker_upload')}}</b-button></div>
                    <div style="font-size: 14px;"><a :href="'/uploads/'+file.file_path" download>{{$t('entry.download_original')}}</a></div>
                  </div>
                  <b-button style="grid-column:2;margin-top:20px;" class="submit-button4" v-if="(!file.filename || file.filename.length==0)  && file.link.length>0 && file.file_path.length>0" v-on:click="popupResendModal(file)" variant="clear-blue"><div class="email-button"></div><span>{{$t('preparer.resend_confirm')}}</span></b-button>
                </div>
                <b-form-file :id="'fileupload'+file.number" accept=".pdf, .doc, .docx, .pps, .ppt, .pptx, .ppsx, .xls, .xlsx, .vsd, .vsdx, .odp, .ods, .odt, .key, .bmp, .jpeg, .jpg, .png, .svg, .tif, .tiff, .webp" @change="onFileFileChange($event, file)" plain hidden></b-form-file> <!--  -->
                <b-form-checkbox class="setting-check" v-model="file.type" :value="2" :unchecked-value="1" style="grid-column:1/2;">{{$t('preparer.link_web')}}</b-form-checkbox>
                <div style="display:grid; grid-template-columns: max-content auto;grid-column:1/2;" v-if="file.type==2">
                  <b-form-input v-model="file.link" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input" style="grid-column:1/2;width:200px;grid-column: 1/2;margin: auto 20px;"></b-form-input>
                  <div v-if="file.link.length>0 && file.description.length>0" style="text-align:center;line-height: 12px;margin:auto;">
                    <div class="cut-filename" style="line-height:16px;">{{file.description}}</div>
                    <div><b-button variant="link" v-on:click="viewLink(file.link)" style="padding:0;border: none;font-weight: bold;">{{$t('entry.speaker_link')}}</b-button></div>
                  </div>
                </div>
                <hr style="width: 100%;border-color: grey;grid-column:1/3;" />
              </div>
              <b-button class="submit-button4" v-if="presentation.files.length<5 && !(presentation.files.length>0 && ((presentation.files[presentation.files.length-1].type == 1 && presentation.files[presentation.files.length-1].file == null && presentation.files[presentation.files.length-1].link == '') || (presentation.files[presentation.files.length-1].type == 2 && presentation.files[presentation.files.length-1].link == '')))" v-on:click="addFileLink" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
              <hr style="width: 100%;border-color: grey;" />
              <b-form-checkbox class="setting-check" v-model="presentation.sharefile" value="1" unchecked-value="0" style="margin:10px;">{{$t(pageType +'.share_presentation')}}<br/>{{$t(pageType +'.share_presentation2')}}</b-form-checkbox>
            </div>
            <b-modal centered v-model="showResendModal" :title="$t('preparer.resend_link')">
              <div style="margin-left:40px;">{{$t('preparer.resend_text1')}}</div><br/>
                <div style="display: grid; grid-template-columns: auto auto;margin-left:40px;">
                  <b-form-checkbox style="grid-column: 1/3;" v-model="resendType" value="0" unchecked-value="1">
                  {{$t('preparer.current_recipients')}}
                </b-form-checkbox>
                <b-form-checkbox style="grid-column: 1/3;" v-model="resendType" value="1" unchecked-value="0">
                  {{$t('preparer.alternate_email')}}
                </b-form-checkbox>
                  <span v-if="resendType==1" class="form-box-name">{{$t('preparer.recipient_text')}}</span>
                  <b-form-input v-if="resendType==1" v-model="resendEmail" type="text" pattern="[^<>]*" :state="validEmail" :title="$t('global.pattern_title')" @input="validateEmail"></b-form-input>
                </div><br/>
                <div style="text-align:center;" v-html="$t('preparer.resend_text2')"></div>
                <div slot="modal-footer" class="submit-box-2">
                  <b-button class="submit-button4" v-on:click="showResendModal=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
                  <b-button class="submit-button4" :disabled="resendType==1 && !validEmail" v-on:click="resendFileConfirm" variant="clear-blue"><div class="email-button"></div><span>{{$t('global.button_send')}}</span></b-button>
                </div>
            </b-modal>
          </div>
          <div v-if="showAlert">
            <b-alert variant="danger"
                     dismissible
                     :show="showAlert"
                     @dismissed="showAlert=false">
              <span v-html="alertText"></span>
            </b-alert>
          </div>
          <div v-if="showSuccess">
            <b-alert variant="success"
                     dismissible
                     :show="showSuccess"
                     @dismissed="showSuccess=false">
              {{$t(pageType +'.thanks_message_1')}}<br />
              {{$t(pageType +'.thanks_message_2')}}<br /><br />
              {{$t(pageType +'.thanks_message_3')}}<br /><br />
              {{$t(pageType +'.thanks_message_4')}}
            </b-alert>
          </div>
          <div v-if="showUpload">
            <b-alert style="line-height:30px;" variant="warning" :show="showUpload">
              {{$t(pageType +'.uploading_text')}}
              <b-spinner style="float:right;" label="Uploading" variant="warning"></b-spinner>
            </b-alert>
          </div>
          <div class="submit-box-2">
            <b-button v-if="!showUpload && !showSuccess" class="submit-button4" type="submit" :disabled="showUpload" variant="clear-blue"><div class="save-button"></div><span>{{presentation.type==1? $t('global.button_save_upload') : $t('global.button_save')}}</span></b-button>

          </div>
        </form>
      </div>
      <div class="div-space"></div>
    </div>
    <div v-if="!speakerFound && speakerReturned" style="display:grid;grid-template-rows: auto max-content auto;height:100%;">
      <div></div>
    <div style="max-width: 600px;margin:auto;width: 100%;text-align:center;">
        <b-alert variant="warning" :show="!speakerFound" style="width: 100%;text-align:center;color:black;">
            {{$t(pageType +'.not_found1')}}<br/>
            {{$t(pageType +'.not_found2')}}
        </b-alert>
    </div>
    <div></div>
    </div>
    <b-modal id="modal-center" v-model="showMacNote" centered >
          <p class="my-4" v-html="$t('entry.mac_modal')"></p>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="submit-button4" v-on:click="showMacNote=false" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
          </div>
        </b-modal>
        <b-modal id="modal-center" v-model="showValidFileExts" centered >
      <p class="my-4" v-html="$t('preparer.upload_file_types')"></p>
      <table id="file-ext-table" style="border-collapse: collapse;border: lightgray 1px solid;text-align: left;">
        <tr><td>Microsoft Office</td><td>DOC DOCX PPS PPT PPTX PPSX XLS XLSX VSD VSDX</td></tr>
        <tr><td>OpenOffice</td><td>ODP ODS ODT</td></tr>
        <tr><td>Apple iWork</td><td>KEY NUMBERS PAGES</td></tr>
        <tr><td>Image</td><td>BMP JPEG JPG PNG SVG TIF TIFF WEBP</td></tr>
      </table>
      <div slot="modal-footer" class="submit-box-2">
        <b-button class="submit-button4" v-on:click="showValidFileExts=false" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
      </div>
    </b-modal>
    <Footer :speakerhash="this.$route.params.h"></Footer>
  </div>
</template>
<style scoped>
#file-ext-table > tr > td{
  padding:5px;
  border: lightgray 1px solid;
}
#speaker-description{
  display: grid;grid-template-columns: max-content max-content;margin: auto;
}

#presentation-logo-box{
  display: grid;grid-template-columns: max-content max-content max-content;grid-gap: 20px;margin: auto;
}
</style>
<script>
import router from "../router"
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
  import {
    Blockquote,
    HardBreak,
    Heading,
    HorizontalRule,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Italic,
    Link,
    Strike,
    Underline,
    History,
  } from 'tiptap-extensions'
    export default {
    name: "Presentation",
    components: {
      EditorContent,
      EditorMenuBar,
    },
    watch: {
      'presentation.type': {
        handler: function () {
          if (!this.load) {
            this.presentation.url = "";
          } else {
            this.load = false;
          }
        },
        deep: true
      },
      
    },
      data() {
        return {
          showMacNote: false,
          editor: new Editor({
            extensions: [
              new Blockquote(),
              new BulletList(),
              new HardBreak(),
              new Heading({ levels: [1, 2, 3] }),
              new HorizontalRule(),
              new ListItem(),
              new OrderedList(),
              new TodoItem(),
              new TodoList(),
              new Bold(),
              new Italic(),
              new Link(),
              new Strike(),
              new Underline(),
              new History(),
            ],
            content: '',
          }),
          presentVariant : "primary",
          subVariant: "outline-primary",
          showSuccess: false,
          saveVariant: 'blue',
          showAlert: false,
          showUpload: false,
          alertText: "",
          presentation: { type: -1, url: "", hash: "", bio: "", name: "", day: "", file: null, filename: "", sharefile: 0, topic: "", target_audience: "", value_proposition: "", note: ""},
          load: true,
          organization_name: "",
          showOverMax: false,
          showedOverMax: false,
          bio: { recommended_words: 150, max_words: 200, cap_words: 0 },
          speakerReturned: false,
          speakerFound: false,
          showDrag: false,
          dragTimeout: null,
          dragging: false,
          pageType: 'presentation',
          urlType: 'speaker',
          isTopic: false,
          showResendModal: false,
          validEmail: false,
          resendFile: null,
          resendEmail: "",
          resendType: 0,
          showValidFileExts: false,
        }
      },
        methods: {
          validateEmail() {
          if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.resendEmail)) {
              this.validEmail= true;
          } else {
              this.validEmail= false;
          }
      },
          resendFileConfirm(){
             if(this.resendFile!= null){
          var file = this.resendFile;
          if(this.resendType==0){
              this.resendEmail = "";
          }else{
            this.setCookie("resend_email", this.resendEmail);
          }
          this.showResendModal = false;
         this.$http.post("/api/organization/"+this.urlType+"/resendconfirm/byhash", {speaker_id: file.speaker_id, number: file.number, recipient: this.resendEmail}).then((response) => {
          if(response.data.success){
            this.$bvToast.toast(this.$t('preparer.emails_sent_to')+": "+response.data.emails.join(", "), {
              title: this.$t('preparer.resend_success'),
              variant: 'success',
              solid: true
            });
          }
        }).catch(() => {});
             }
      },
      popupResendModal(file){
        this.resendEmail = this.getCookie("resend_email");
        this.validateEmail();
        this.resendFile = file;
        this.showResendModal = true;
      },
          dropHandler(ev){
        if(ev){
          ev.preventDefault();
         if (ev.dataTransfer.items) {
            for (var i = 0; i < ev.dataTransfer.items.length; i++) {
              if (ev.dataTransfer.items[i].kind === 'file' && this.checkFileSize(ev.dataTransfer.items[i])) {
                var file = ev.dataTransfer.items[i].getAsFile();
                this.addFileLink();
                this.presentation.files[this.presentation.files.length-1].file = file;
                this.presentation.files[this.presentation.files.length-1].filename = file.name;
                this.presentation.files[this.presentation.files.length-1].description = file.name;
              }
            }
          } else {
            for (var j = 0; j < ev.dataTransfer.files.length; j++) {
              if(this.checkFileSize(ev.dataTransfer.files[j])){
                this.addFileLink();
                this.presentation.files[this.presentation.files.length-1].file = ev.dataTransfer.files[j];
                this.presentation.files[this.presentation.files.length-1].filename = ev.dataTransfer.files[j].name;
                this.presentation.files[this.presentation.files.length-1].description = ev.dataTransfer.files[j].name;
              }
            }
          }
        }
        this.showDrag = false;
        this.dragging = false;
      },
      dragEnterHandler(){
        this.showDrag = true;
        this.dragging = true;
      },
      dragHandler(){
        this.showDrag = true;
        this.dragging = true;
      },
      dragLeaveHandler(){
        this.dragging = false;
        var self = this;
        clearTimeout( this.dragTimeout );
        this.dragTimeout = setTimeout( function(){
          if( !self.dragging ){ self.showDrag = false;  }
      }, 200 );
      },
      addFileLink() {
        if((this.presentation.files[this.presentation.files.length-1].type == 1 && this.presentation.files[this.presentation.files.length-1].file == null && this.presentation.files[this.presentation.files.length-1].link == '') || (this.presentation.files[this.presentation.files.length-1].type == 2 && this.presentation.files[this.presentation.files.length-1].link == "")){
          var j = this.entry.files[this.entry.files.length-1].number;
          this.entry.files[this.entry.files.length-1] = { speaker_id: 0, number: j, type: 1, link: "", description: "", filename: "", file_path: "", file: null , new: true};
          return this.presentation.files.length-1;
        }
        for (var k = 1; k <= 5; k++) {
          var found = false;
          for (var i = 0; i < this.presentation.files.length; i++) {
            if (this.presentation.files[i].number == k) {
              found = true;
              
            }
          }
          if (!found) {
            this.showSuccess = false;
            this.presentation.files.push({ speaker_id: 0, number: k, type: 1, link: "", description: "", filename: "", file_path: "", file: null, new: true });
            return k;
          }
        }
        return -1;
      },
      openFileDialog(file) {
        document.getElementById('fileupload' + file.number).click();
      },
      onFileFileChange(e, fileElement) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length || !this.checkFileSize(files[0]))
          return;
         fileElement.link = "";
        fileElement.file_path = "";
        fileElement.description = "";
        fileElement.new = true;
        fileElement.filename = files[0].name;
        fileElement.file = files[0];
        fileElement.new = true;
        if(fileElement.description.length == 0){
          fileElement.description = files[0].name;
        }
      },
     viewFile(h){
        router.push("/filecontrol/"+h);
      },
        viewLink(l){
          window.open(l, "_blank", 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
        },
      removeFileLink(file) {
        var index = this.presentation.files.indexOf(file);
        if (index > -1) {
          this.presentation.files.splice(index, 1);
        }
         if(this.presentation.files.length == 0){
            this.presentation.files.push({ speaker_id: 0, number: 1, type: 1, link: "", description: "", filename: "", file_path: "", file: null, new: true });
          }
      },
          checkFileThenupload(){
            if(this.presentation.type == 1){
              if(this.presentation.url && this.presentation.file == null){
                this.uploadPP();
                return;
              }
              var input = document.getElementById('fileid');
              if (input.files.length > 0) {
                  var file = input.files[0];
                  var fr = new FileReader();
                  var self = this;
                  fr.onload = function () {
                      self.uploadPP();
                  };
                  fr.onerror = function (e) {
                      if (e.target.error.name == "NotFoundError") {
                          self.showAlert=true;
                          self.alertText = self.$t('global.file_deleted');
                      }
                  }
                  fr.readAsText(file);
              } else {
                  this.showAlert = true;
                  this.alertText = this.$t(pageType +'.presentation_error2');
              }
            }else{
              this.uploadPP();
            }
            
          },
          uploadPP() {
            this.showAlert = false;
            if(this.presentation.type<0 || this.presentation.type===false){
              this.showAlert = true;
              this.alertText = this.$t(this.pageType +'.presentation_error1');
              return;
            }else if(this.presentation.type==2 && this.presentation.url.length == 0){
              this.showAlert = true;
              this.alertText = this.$t(this.pageType +'.presentation_error3');
              return;
            }

            this.showUpload = true;
            
            this.alertText = this.$t(this.pageType +'.alert_text');
            this.saveVariant = "";

            if (!this.isTopic && (this.bio.cap_words == 1 && this.getNumWords() > this.bio.max_words)) {
              var o = this.editor.getJSON();
              this.recurseBio(o, 0);
              this.editor.setContent(o);
            }

            let formData = new FormData();
            var presentationUploads = [];
            for (var i = 0; i < this.presentation.files.length; i++) {
              if (this.presentation.files[i].type == 1 && this.presentation.files[i].file && this.presentation.files[i].file != null) {
                formData.append("files", this.presentation.files[i].file);
                this.presentation.files[i].link = "";
                this.presentation.files[i].name = this.presentation.files[i].file.name;
                this.presentation.files[i].file = null;
                presentationUploads.push( this.presentation.files[i]);
              }else if (this.presentation.files[i].link != ""){
                presentationUploads.push( this.presentation.files[i]);
              }
            }
            formData.append("speaker_files", JSON.stringify(presentationUploads));
            formData.append("type", this.presentation.type);
            formData.append("url", this.presentation.url);
            formData.append("hash", this.presentation.hash);
            formData.append("note", this.presentation.note);
            formData.append("topic", this.presentation.topic);
            formData.append("target_audience", this.presentation.target_audience);
            formData.append("value_proposition", this.presentation.value_proposition);
            formData.append("bio", this.editor.getHTML());
            formData.append("uploaded", this.presentation.uploaded);
            formData.append("sharefile", this.presentation.sharefile);

            this.$http.post("/api/organization/"+this.urlType+"/update/byhash", formData, {
              headers: {
                "Content-Type" : "multipart/form-data"
              }
            }).then((response) => {
              this.showUpload = false;
              this.saveVariant = "blue";
              if (response.data.success == true) {
                this.showSuccess = true;
                //this.presentation.uploaded = new Date().toISOString().replace("T"," ");//full_datetime;
              } else {
                this.showAlert = true;
                this.alertText = response.data.error;
              }
              }).catch((errors) => {
                this.showUpload = false;
                this.saveVariant = "blue";
              this.showAlert = true;
              this.alertText = JSON.stringify(errors);
            });
          },
          getPresentation() {
            this.speakerReturned = false;
            this.presentation.hash = this.$route.params.h;
            this.$http.post("/api/organization/speaker/get", this.presentation).then((response) => {
              this.speakerReturned = true;
              if (response.data.success) {
                this.speakerFound = true;
                this.presentation = response.data.speaker;
                if (!this.presentation.bio) {
                  this.presentation.bio = "";
                }

                for (var i = 0; i < this.presentation.files.length; i++) {
                  this.$set(this.presentation.files[i], 'file', null);
                  this.$set(this.presentation.files[i], 'filename', "");
                }
                if(this.presentation.files.length == 0){
                  this.presentation.files.push({ speaker_id: 0, number: 1, type: 1, link: "", description: "", filename: "", file_path: "", file: null, new: true });
                }

                this.editor.setContent(this.presentation.bio);
                this.organization_name = response.data.organization_name;
              }else{
                this.speakerFound = false;
              }
            }).catch((errors) => {
              this.speakerReturned = true;
               this.speakerFound = false;
              console.log(errors);
            });
          },
          openDialog() {
            document.getElementById('fileid').click();
          },
          onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length || !this.checkFileSize(files[0]))
              return;
            
            this.presentation.filename = files[0].name;
            this.presentation.file = files[0];
          },
          getBioCap() {
            this.presentation.hash = this.$route.params.h;
            this.$http.post("/api/organization/get/biocap/byspeaker", { hash: this.presentation.hash }).then((response) => {
              if (response.data.success === true) {
                this.bio.recommended_words = response.data.bio.recommended_words;
                this.bio.max_words = response.data.bio.max_words;
                this.bio.cap_words = response.data.bio.cap_words;
              }
            }).catch(() => { });
          },
          getNumWords() {
            var regex = /(<([^>]+)>)/ig
            var r = this.editor.getHTML().replace(regex, " ");
            var t = r.split(" ");
            var n = t.filter(function (el) {
              return el != "";
            }).length;
            if (this.bio.cap_words == 1 && !this.showedOverMax && n > this.bio.max_words) {
              this.showOverMax = true;
            }
            this.num_color = n <= this.bio.recommended_words ? "#999999" : ((this.bio.cap_words == 1 && n > this.bio.max_words ? '#ff0000' : '#eba834'));
            return n;
          },
          recurseBio(o, wordcount) {
            var l = 0;
            if (o.content) {
              var self = this;
              o.content = o.content.filter(function (el) {
                var k = self.recurseBio(el, wordcount);
                wordcount += k;
                l += k
                return k > 0;
              })
            }
            if (o.text) {
              if (wordcount >= this.bio.max_words) {
                o.text = "";
                return 0;
              } else if (o.text.split(" ").length + l + wordcount > this.bio.max_words) {
                var g = this.bio.max_words - l - wordcount;
                var a = o.text.split(" ").slice(0, g);
                wordcount = this.bio.max_words;
                o.text = a.join(" ");
                return a.length + l;
              } else {
                return o.text.split(" ").length + l;
              }
            } else {
              return l;
            }
          },
          getIsMac(){
              return window.navigator.platform.indexOf('Mac')>=0 || window.navigator.platform.indexOf('mac')>=0 || window.navigator.platform.indexOf('Apple')>=0 || window.navigator.platform.indexOf('apple')>=0;
            }
    },
    beforeDestroy() {
      this.editor.destroy()
    },
    mounted() {
      if(this.$route.path.includes('topic')){
        this.pageType = 'topic';
        this.urlType = 'topic';
        this.isTopic = true;
      }
      this.setLanguageIfAvailable();
      this.getBioCap();
      this.getPresentation();
    }
    }
</script>
