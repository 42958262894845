<template>
  <div v-on:dragover="dragHandler" v-on:dragleave="dragLeaveHandler" v-on:dragenter="dragEnterHandler">
    <Header :useruid="this.$route.params.useruid" :title="$t('entry.manage_speaker')" :showDate="false"></Header>
    <div class="rel-grid-container" @dragleave.prevent :style="{'max-width': chapter_id>0? '100%': '75%'}">
      <div id="top-div"></div>
      <form v-on:submit.prevent="updateEntry" id="form-fix" style="width:800px;">
        <Tip :tipNumber="23"></Tip>
        <div id="date-box">
          <span class="form-box-name">{{$t('entry.date_text')}}</span>
          <b-form-datepicker style="margin: 5px 0px;"  v-model="entry.day" :locale="$i18n.locale" :date-disabled-fn="dateDisabled" :disabled="entry.id !=0 || (!permissions.organization.official && meeting_days.length==0)"></b-form-datepicker>
        </div>
        <div id="speaker-check" class="fit-content">
              <b-form-radio-group v-model="tempMember" :options="memberStates" :disabled="entry.id !=0">
              </b-form-radio-group>
            </div>
          <div>
        <div>
          <span class="form-box-title">{{$t('entry.speaker_info')}}</span>
            <div v-if="tempMember==0" class="form-box">
              <span class="form-box-name">{{$t('entry.member_text')}}</span>
              <b-form-select required v-model="entry.member_id" :options="members" value-field="id" text-field="name" class="mb-3 form-box-input" :disabled="entry.id !=0"/>
              <span class="form-box-name"  v-if="isCorporateConnections">{{$t('entry.note_text')}}</span><b-form-textarea  v-if="isCorporateConnections" v-model="entry.note" placeholder="" :rows="2" class="form-box-input"/>
            </div>
            <div v-if="tempMember==1" class="form-box">
              <span class="form-box-name">{{$t('entry.contact_text')}}</span>
              <b-form-select required v-model="entry.member_id" :options="contacts" value-field="id" text-field="name" class="mb-3 form-box-input" :disabled="entry.id !=0" />
              <span class="form-box-name"  v-if="isCorporateConnections">{{$t('entry.note_text')}}</span><b-form-textarea  v-if="isCorporateConnections" v-model="entry.note" placeholder="" :rows="2" class="form-box-input"/>
            </div>
            <div v-if="tempMember==2" class="form-box">
              <span required  class="form-box-name">{{$t('entry.name_text')}}</span><b-form-input  v-model="entry.nm_name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;" class="form-box-input" :disabled="entry.id !=0" required></b-form-input>
            <span class="form-box-name">{{$t('entry.company_text')}}</span><b-form-input  v-model="entry.nm_company" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
            <span class="form-box-name">{{$t('entry.title_text')}}</span><b-form-input  v-model="entry.nm_title" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
            <span class="form-box-name">{{$t('entry.email_text')}}</span><b-form-input  v-model="entry.nm_email" type="email" class="form-box-input"></b-form-input>
            <span class="form-box-name" v-if="isCorporateConnections">{{$t('entry.note_text')}}</span><b-form-textarea v-if="isCorporateConnections" v-model="entry.note" placeholder="" :rows="2" class="form-box-input"/>
            </div>
            
          </div>
        </div>
        <div v-if="tempMember ==2">
          <span  class="form-box-title">{{$t('entry.speaker_photo')}}</span>
          <div  class="form-box" style="grid-template-columns: 20% 80%;">
            <img v-show="entry.nm_photo" id="member-photo" :src="entry.nm_photo" />
            <b-button class="submit-button4" style="grid-column:2/3;" v-on:click="showPhotoEdit=true" variant="blue">{{$t('entry.set_photo')}}</b-button>
            <b-form-file id='fileid' v-model="entry.nm_photo" accept="image/jpeg, image/png, image/gif" @change="setImage" plain hidden></b-form-file>
          </div>
        </div>
        <b-modal centered id="slide-modal" v-model="showPhotoEdit" :title="$t('entry.set_photo')">
          <div style="text-align:center;color: rgb(0, 174, 239);">{{$t('member.upload_instructions')}}</div>
            <div id="image-editor">
              <div id="rotate-options">
                <b-button v-if="imgSrc && imgSrc.length>0" v-on:click="rotateImageRight" class="image-button" variant="clear-blue"><div class="rotate-left-button"></div></b-button>
                <b-button v-if="imgSrc && imgSrc.length>0" v-on:click="rotateImageLeft" class="image-button" variant="clear-blue"><div class="rotate-right-button"></div></b-button>
                <b-button v-if="imgSrc && imgSrc.length>0" v-on:click="flipImageX()"  class="image-button" variant="clear-blue"><div class="flip-y-button"></div></b-button>
                <b-button v-if="imgSrc && imgSrc.length>0" v-on:click="flipImageY()" class="image-button" variant="clear-blue"><div class="flip-x-button"></div></b-button>
              </div>
              <div id="cropper-wrapper">
                <vue-cropper ref='cropper' :guides="true"  :view-mode="2" drag-mode="crop" :auto-crop-area="1" :min-container-width="250" :min-container-height="180"
                            :background="true" :rotatable="true" :src="imgSrc" :aspectRatio="isCorporateConnections? 1 : 0.6157" :cropmove="cropMove" alt="Photo" :img-style="{ 'width': '400px', 'height': '300px' }">
              </vue-cropper>
              </div>
              <div v-if="isCorporateConnections" class="circular-member" :style="{'background-image': 'url('+tempPhoto+')', width: '300px', height: '300px'}"></div>
              <img v-if="!isCorporateConnections" v-show="tempPhoto" id="preview-image" :src="tempPhoto" />
              <div id="zoom-text">{{$t('member.zoom_text')}}</div>
              <b-button class="submit-button4" v-on:click="openDialog" variant="blue" style="grid-column:2/3;margin: auto;">{{$t('member.upload_photo')}}</b-button>
            </div>
            <div slot="modal-footer" class="submit-box-2">
              <b-button class="submit-button4" v-on:click="showPhotoEdit=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
              <b-button class="submit-button4" v-on:click="cropImage" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
            </div>
        </b-modal>
        <div v-if="entry.hash && entry.hash.length>0">
          <span class="form-box-title">{{$t('entry.speaker_setup')}}</span>
          <div class="form-box" style="grid-template-columns: auto;">
            <span v-if="entry.uploaded" style="font-size:16px;margin-bottom:20px;">{{$t('entry.last_speaker_update')}} {{$d(new Date(entry.uploaded.split("-").join("/")), 'long')}}</span>
            <span v-if="!entry.uploaded" style="font-size:16px;margin-bottom:20px;">{{$t('entry.speaker_submit')}}.</span>
            <div style="text-align:center;color: rgb(0, 174, 239);">{{$t('presentation.instructions1')}}</div>
          <div style="text-align:center;color: rgb(0, 174, 239);">{{$t('presentation.instructions2')}}</div>
          <div style="text-align:center;color: rgb(0, 174, 239);">{{$t('preparer.valid_files')}} <span style="text-decoration: underline;" class="clickable" v-on:click="showValidFileExts = true;">{{$t('preparer.see_full_list')}}</span></div>
            <div >
              <div>
                <span class="form-box-title">{{$t('entry.speaker_bio')}}</span>
                <div class="form-box" style="grid-template-columns: auto;display: block;">
                  <div class="editor">
                    <editor-menu-bar :editor="editor">
                      <div class="menubar" slot-scope="{ commands, isActive }">
                        <b-button variant="outline-dark" :class="{ 'is-active': isActive.bold() }" v-on:click="commands.bold">
                          <b>B</b>
                        </b-button>
                        <b-button variant="outline-dark" :class="{ 'is-active': isActive.italic() }" v-on:click="commands.italic">
                          <i>I</i>
                        </b-button>
                        <b-button variant="outline-dark" :class="{ 'is-active': isActive.strike() }" v-on:click="commands.strike">
                          <s>S</s>
                        </b-button>
                        <b-button variant="outline-dark" :class="{ 'is-active': isActive.underline() }" v-on:click="commands.underline">
                          <u>U</u>
                        </b-button>
                        <b-button variant="outline-dark" :class="{ 'is-active': isActive.paragraph() }" v-on:click="commands.paragraph">
                          P
                        </b-button>
                        <b-button variant="outline-dark" :class="{ 'is-active': isActive.heading({ level: 1 }) }" v-on:click="commands.heading({ level: 1 })">
                          H1
                        </b-button>
                        <b-button variant="outline-dark" :class="{ 'is-active': isActive.heading({ level: 2 }) }" v-on:click="commands.heading({ level: 2 })">
                          H2
                        </b-button>
                        <b-button variant="outline-dark" :class="{ 'is-active': isActive.heading({ level: 3 }) }" v-on:click="commands.heading({ level: 3 })">
                          H3
                        </b-button>
                        <b-button variant="outline-dark" :class="{ 'is-active': isActive.bullet_list() }" v-on:click="commands.bullet_list">
                          {{$t('entry.bullet_text')}}
                        </b-button>
                        <b-button variant="outline-dark" :class="{ 'is-active': isActive.ordered_list() }" v-on:click="commands.ordered_list">
                          {{$t('entry.list_text')}}
                        </b-button>
                        <b-button variant="outline-dark" v-on:click="commands.undo">
                          {{$t('entry.undo_text')}}
                        </b-button>
                        <b-button variant="outline-dark" v-on:click="commands.redo">
                          {{$t('entry.redo_text')}}
                        </b-button>
                      </div>
                    </editor-menu-bar>
                    <editor-content class="editor__content" :editor="editor" style="height:200px;width:100%;overflow-y: auto;overflow-x: hidden;" />
                  </div>
                  <div>
                    <span>{{$t('entry.recommended_text')+" "+bio.recommended_words+" "+$t('entry.words_text')}}</span>
                    <span style="float:right;">{{$t('entry.user_bio')}} <span :style="{color: num_color}">{{getNumWords()}}</span> {{$t('entry.words_text')}}</span>
                  </div>
                  <div v-if="showOverMax">
                    <b-alert variant="warning"
                             dismissible
                             :show="showOverMax"
                             @dismissed="showOverMax=false">
                      {{$tc('entry.over_max',bio.max_words, {n: bio.max_words})}}
                    </b-alert>
                  </div>
                </div>
              </div>
              
              <div>
            <span class="form-box-title">{{$t('entry.speaker_presentation')}}</span>
            <div class="form-box" style="grid-template-columns: auto;position:relative;" v-on:drop="dropHandler"  @dragover.prevent  @dragenter.prevent @dragleave.prevent>
              <div v-show="showDrag" id="drop-overlay">
                <div></div>
                <div v-if="entry.files.length<5">{{$t('preparer.drop_text')}}</div>
                <div v-if="entry.files.length>=5">{{$t('preparer.max_files')}}</div>
                <div></div>
              </div>
              <div style="display:grid; grid-template-columns: auto max-content;text-align: center;margin-bottom: 20px;"><span v-html="$t('preparer.file_notes')"></span> <b-button v-if="getIsMac()" style="padding:0; width:70px;font-size:14px;" variant="link" v-on:click="showMacNote=true;">{{$t('entry.mac_note')}}</b-button></div>
              <div v-for="file in entry.files" :key="file" style="display:grid; grid-template-columns: auto max-content;">
                <div style="text-align:center; grid-column:1/3;color: #003c80;font-size: 20px;">{{$t('preparer.file_text')+" #"+(entry.files.indexOf(file)+1)}}</div>
                <b-button v-if="entry.files.length>1 || (file.link != '' || file.file != null) " v-on:click="removeFileLink(file)" class="submit-button4" variant="clear-blue" style="margin:auto;grid-column:2/3;grid-row:2/5;"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
                <b-form-checkbox class="setting-check" v-model="file.type" :value="1" :unchecked-value="2" style="grid-column:1/2;">{{$t('preparer.power_point')}}</b-form-checkbox>
                <div style="display:grid; grid-template-columns: max-content auto;grid-column:1/2;" v-if="file.type==1">
                  <b-button  class="submit-button4" v-on:click="openFileDialog(file)" variant="blue" style="margin: auto 20px;">{{file.link.length>0 && file.file_path.length>0? $t('entry.reselect_presentation') : $t('preparer.upload_pp')}}</b-button>
                  <div v-if="file.filename && file.filename.length>0" id="preparer-filename" class="cut-filename">{{file.filename}}</div>
                  <div v-if="(!file.filename || file.filename.length==0)  && file.link.length>0 && file.file_path.length>0" style="text-align:center;line-height: 12px;margin:auto;">
                    <div class="cut-filename">{{file.file_path}}</div>
                    <div><b-button variant="link" v-on:click="viewFile(file.hash)" style="padding:0;border: none;font-weight: bold;">{{$t('entry.speaker_upload')}}</b-button></div>
                    <div style="font-size: 14px;"><a :href="'/uploads/'+file.file_path" download>{{$t('entry.download_original')}}</a></div>
                  </div>
                  <b-button style="grid-column:2;margin-top:20px;" class="submit-button4" v-if="(!file.filename || file.filename.length==0)  && file.link.length>0 && file.file_path.length>0" v-on:click="popupResendModal(file)" variant="clear-blue"><div class="email-button"></div><span>{{$t('preparer.resend_confirm')}}</span></b-button>
                </div>
                <b-form-file :id="'fileupload'+file.number" accept=".pdf, .doc, .docx, .pps, .ppt, .pptx, .ppsx, .xls, .xlsx, .vsd, .vsdx, .odp, .ods, .odt, .key, .bmp, .jpeg, .jpg, .png, .svg, .tif, .tiff, .webp" @change="onFileFileChange($event, file)" plain hidden></b-form-file> <!--  -->
                <b-form-checkbox class="setting-check" v-model="file.type" :value="2" :unchecked-value="1" style="grid-column:1/2;">{{$t('preparer.link_web')}}</b-form-checkbox>
                <div style="display:grid; grid-template-columns: max-content auto;grid-column:1/2;" v-if="file.type==2">
                  <b-form-input v-model="file.link" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input" style="grid-column:1/2;width:200px;grid-column: 1/2;margin: auto 20px;"></b-form-input>
                  <div v-if="file.link.length>0 && file.description.length>0" style="text-align:center;line-height: 12px;margin:auto;">
                    <div class="cut-filename" style="line-height:16px;">{{file.description}}</div>
                    <div><b-button variant="link" v-on:click="viewLink(file.link)" style="padding:0;border: none;font-weight: bold;">{{$t('entry.speaker_link')}}</b-button></div>
                  </div>
                </div>
                <hr style="width: 100%;border-color: grey;grid-column:1/3;" />
              </div>
              <b-button class="submit-button4" v-if="entry.files.length<5 && !(entry.files.length>0 && ((entry.files[entry.files.length-1].type == 1 && entry.files[entry.files.length-1].file == null && entry.files[entry.files.length-1].link == '') || (entry.files[entry.files.length-1].type == 2 && entry.files[entry.files.length-1].link == '')))" v-on:click="addFileLink" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
              <hr style="width: 100%;border-color: grey;" />
              <b-form-checkbox class="setting-check" v-model="entry.sharefile" value="1" unchecked-value="0" style="margin:10px;">{{$t('presentation.share_presentation')}}<br/>{{$t('presentation.share_presentation2')}}</b-form-checkbox>
            </div>
            <b-modal centered v-model="showResendModal" :title="$t('preparer.resend_link')">
              <div style="margin-left:40px;">{{$t('preparer.resend_text1')}}</div><br/>
                <div style="display: grid; grid-template-columns: auto auto;margin-left:40px;">
                  <b-form-checkbox style="grid-column: 1/3;" v-model="resendType" value="0" unchecked-value="1">
                  {{$t('preparer.current_recipients')}}
                </b-form-checkbox>
                <b-form-checkbox style="grid-column: 1/3;" v-model="resendType" value="1" unchecked-value="0">
                  {{$t('preparer.alternate_email')}}
                </b-form-checkbox>
                  <span v-if="resendType==1" class="form-box-name">{{$t('preparer.recipient_text')}}</span>
                  <b-form-input v-if="resendType==1" v-model="resendEmail" type="text" pattern="[^<>]*" :state="validEmail" :title="$t('global.pattern_title')" @input="validateEmail"></b-form-input>
                </div><br/>
                <div style="text-align:center;" v-html="$t('preparer.resend_text2')"></div>
                <div slot="modal-footer" class="submit-box-2">
                  <b-button class="submit-button4" v-on:click="showResendModal=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
                  <b-button class="submit-button4" :disabled="resendType==1 && !validEmail" v-on:click="resendFileConfirm" variant="clear-blue"><div class="email-button"></div><span>{{$t('global.button_send')}}</span></b-button>
                </div>
            </b-modal>
          </div>
            </div>
          </div>
          <span v-if="isCorporateConnections" class="form-box-title">{{$t('presentation.topic_audience')}}</span>
            <div v-if="isCorporateConnections" class="form-box">
              <span class="form-box-name">{{$t('presentation.topic_text')}}</span><b-form-input v-model="entry.topic" max="255" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
                <span class="form-box-name">{{$t('presentation.target_audience')}}</span><b-form-textarea style="margin-bottom: 10px;"  v-model="entry.target_audience" placeholder="" :rows="3" class="form-box-input"/>
                <span class="form-box-name">{{$t('presentation.value_proposition')}}</span><b-form-textarea v-model="entry.value_proposition" placeholder="" :rows="3" class="form-box-input"/>
              </div>
          <div v-if="showPresentationAlert">
            <b-alert variant="danger"
                     dismissible
                     :show="showPresentationAlert"
                     @dismissed="showPresentationAlert=false">
              <span v-html="alertText"></span>
            </b-alert>
          </div>
        </div>
        <div v-if="showDupeAlert" style="max-width: 600px;margin: auto;">
          <b-alert variant="danger" dismissible :show="showDupeAlert" @dismissed="showDupeAlert=false">
            {{$t('entry.dupe_alert')}}
          </b-alert>
        </div>
        <div v-if="showAlert" style="max-width: 600px;margin: auto;">
          <b-alert variant="warning" dismissible :show="showAlert" @dismissed="showAlert=false">
            {{$t('performance.error_type1')}}
          </b-alert>
        </div>
        <div v-if="showUpload">
          <b-alert style="line-height:30px;" variant="warning" :show="showUpload">
            {{$t('entry.uploading_text')}}
            <b-spinner style="float:right;" label="Uploading" variant="warning"></b-spinner>
          </b-alert>
        </div>
        
        <div class="submit-box-2">
          <b-button v-if="entry.id>0" class="submit-button4" variant="clear-blue" v-on:click="showDelete=true;"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          <b-button class="submit-button4" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="submit-button4" type="submit" v-if="!showUpload" variant="clear-blue"><div class="save-button"></div><span>{{entry.type==1? $t('global.button_save_upload') : $t('global.button_save')}}</span></b-button>
        </div>
        <b-modal id="modal-center" v-model="showDelete" centered :title="$t('entry.delete_title')">
          <p class="my-4">{{$t('entry.delete_text')}}</p>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="submit-button4" v-on:click="showDelete=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="submit-button4" v-on:click="deleteEntry" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          </div>
        </b-modal>
        <b-modal id="modal-center" v-model="showMacNote" centered >
          <p class="my-4" v-html="$t('entry.mac_modal')"></p>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="submit-button4" v-on:click="showMacNote=false" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
          </div>
        </b-modal>
        <b-modal id="modal-center" v-model="showNoMeetingDates" centered >
          <p class="my-4" v-html="$t('entry.no_meetings_note')"></p>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="submit-button4" v-on:click="closeNoMeetingDates" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
            <b-button v-if="chapter_id>0" class="submit-button4" v-on:click="goToMeetingDates" variant="clear-blue"><div class="next-button"></div><span>{{$t('entry.go_to_meetingdates')}}</span></b-button>
          </div>
        </b-modal>
        <b-modal id="modal-center" v-model="showValidFileExts" centered >
          <p class="my-4" v-html="$t('preparer.upload_file_types')"></p>
          <table id="file-ext-table" style="border-collapse: collapse;border: lightgray 1px solid;text-align: left;">
            <tr><td>Microsoft Office</td><td>DOC DOCX PPS PPT PPTX PPSX XLS XLSX VSD VSDX</td></tr>
            <tr><td>OpenOffice</td><td>ODP ODS ODT</td></tr>
            <tr><td>Apple iWork</td><td>KEY NUMBERS PAGES</td></tr>
            <tr><td>Image</td><td>BMP JPEG JPG PNG SVG TIF TIFF WEBP</td></tr>
          </table>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="submit-button4" v-on:click="showValidFileExts=false" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
          </div>
        </b-modal>
      </form>
      <div class="div-space"></div>
    </div>
    <Footer :useruid="this.$route.params.useruid"></Footer>

  </div>
</template>
<style scoped>
#file-ext-table > tr > td{
  padding:5px;
  border: lightgray 1px solid;
}
#member-photo {
    max-height: 100px;
    max-width: 200px;
    border: 1px gray solid;
  }
#filename-text{
  white-space: nowrap;overflow: hidden;text-overflow: ellipsis;margin: 10px;
}

  #date-box {
    display: grid;
    grid-template-columns: auto auto;
    width: fit-content;
    margin: auto;
  }
  #speaker-check {
    grid-column-start: 2;
    grid-column-end: 4;
    margin: 10px auto;
    color: #999;
  }

</style>
<script>

      
    
  import router from "../router"
  import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
  import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
  import {
    Blockquote,
    HardBreak,
    Heading,
    HorizontalRule,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Italic,
    Link,
    Strike,
    Underline,
    History,
  } from 'tiptap-extensions'

    export default {
    name: "Entry",
    components: {
      VueCropper,
      EditorContent,
      EditorMenuBar
    },
    watch: {
      'entry.type': {
        handler: function (v) {
          if(v == false){
            this.entry.type = 0;
          }
          if (!this.load) {
            this.entry.url = "";
          } else {
            this.load = false;
          }
        },
        deep: true
      },
    },
    props:{
        chapter_id: {type: Number, default: 0},
        speakerObject: {type: Object, default: {id: -1}}
      },
      data() {
        return {
          showValidFileExts: false,
          showNoMeetingDates: false,
          showMacNote: false,
          showAlert: false,
          meet_day_of_week: 0,
          editor: new Editor({
            extensions: [
              new Blockquote(),
              new BulletList(),
              new HardBreak(),
              new Heading({ levels: [1, 2, 3] }),
              new HorizontalRule(),
              new ListItem(),
              new OrderedList(),
              new TodoItem(),
              new TodoList(),
              new Bold(),
              new Italic(),
              new Link(),
              new Strike(),
              new Underline(),
              new History(),
            ],
            content: '',
          }),
          showDelete: false,
          showSetup: false,
          num_color: "#999999",
          saveVariant: 'blue',
          members: [],
          entry: { id: 0, member_id:0, nm_photo: null, nm_photo_small: null, nm_name: "", nm_company: "", nm_title: "", nm_email: "", url: "", hash: "", bio: "", type: -1, uploaded: '', sharefile: 0, day: new Date() , files: [], topic: "", target_audience: "", value_proposition: "", note: ""},
          tempMember: 0,
          load: true,
          showUpload: false,
          tempPhoto: "",
          imgSrc: '',
          showPhotoEdit: false,
          showOverMax: false,
          showedOverMax: false,
          bio: {recommended_words: 150, max_words: 200, cap_words: 0},
         
          memberStates: [{ text: this.$t('entry.member_text'), value: 0 },{ text: this.$t('entry.regional_contact'), value: 1 }, { text: this.$t('entry.non_member_text'), value: 2 }],
          contacts: [],
          original_contacts: [],
          showDupeAlert: false,
          showPresentationAlert: false,
          alertText: "",
          useruid: "",
          showDrag: false,
          dragTimeout: null,
          dragging: false,
          meeting_days: [],
          showResendModal: false,
          validEmail: false,
          resendFile: null,
          resendEmail: "",
          resendType: 0
        }
      },
    methods: {
      closeNoMeetingDates(){
        if(this.chapter_id>0){
          this.showNoMeetingDates = false;
          this.speakerObject.id = -1;
        }else{
          this.showNoMeetingDates = false;
         router.push("/admin/schedule");
        }
      },
      goToMeetingDates(){
        if(this.chapter_id==0){
          this.speakerObject.id = -1;
        }else{
          this.showNoMeetingDates = false;
         router.push("/admin/meetingdates");
        }
      },
      validateEmail() {
          if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.resendEmail)) {
              this.validEmail= true;
          } else {
              this.validEmail= false;
          }
      },
      resendFileConfirm(){
         if(this.resendFile!= null){
          var file = this.resendFile;
          if(this.resendType==0){
              this.resendEmail = "";
          }else{
            this.setCookie("resend_email", this.resendEmail, false);
          }
          this.showResendModal = false;
          this.$http.post("/api/organization/speaker/resendconfirm"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""), {org: this.chapter_id,speaker_id: this.entry.id, number: file.number,recipient: this.resendEmail}).then((response) => {
            if(response.data.success){
              this.$bvToast.toast(this.$t('preparer.emails_sent_to')+": "+response.data.emails.join(", "), {
                title: this.$t('preparer.resend_success'),
                variant: 'success',
                solid: true
              });
            }
          }).catch(() => {});
         }
      },
      popupResendModal(file){
        this.resendEmail = this.getCookie("resend_email");
        this.validateEmail();
        this.resendFile = file;
        this.showResendModal = true;
      },
      dropHandler(ev){
        if(ev){
          ev.preventDefault();
         if (ev.dataTransfer.items) {
            for (var i = 0; i < ev.dataTransfer.items.length; i++) {
              if (ev.dataTransfer.items[i].kind === 'file' && this.checkFileSize(ev.dataTransfer.items[i])) {
                var file = ev.dataTransfer.items[i].getAsFile();
                this.addFileLink();
                this.entry.files[this.entry.files.length-1].file = file;
                this.entry.files[this.entry.files.length-1].filename = file.name;
                this.entry.files[this.entry.files.length-1].description = file.name;
              }
            }
          } else {
            for (var j = 0; j < ev.dataTransfer.files.length; j++) {
              if(this.checkFileSize(ev.dataTransfer.files[j])){
                this.addFileLink();
                this.entry.files[this.entry.files.length-1].file = ev.dataTransfer.files[j];
                this.entry.files[this.entry.files.length-1].filename = ev.dataTransfer.files[j].name;
                this.entry.files[this.entry.files.length-1].description = ev.dataTransfer.files[j].name;
              }
            }
          }
        }
        this.showDrag = false;
        this.dragging = false;
      },
      dragEnterHandler(){
        this.showDrag = true;
        this.dragging = true;
      },
      dragHandler(){
        this.showDrag = true;
        this.dragging = true;
      },
      dragLeaveHandler(){
        this.dragging = false;
        var self = this;
        clearTimeout( this.dragTimeout );
        this.dragTimeout = setTimeout( function(){
          if( !self.dragging ){ self.showDrag = false;  }
      }, 200 );
      },
      addFileLink() {
        if((this.entry.files[this.entry.files.length-1].type == 1 && this.entry.files[this.entry.files.length-1].file == null && this.entry.files[this.entry.files.length-1].link == '') || (this.entry.files[this.entry.files.length-1].type == 2 && this.entry.files[this.entry.files.length-1].link == "")){
          var j = this.entry.files[this.entry.files.length-1].number;
          this.entry.files[this.entry.files.length-1] = { speaker_id: 0, number: j, type: 1, link: "", description: "", filename: "", file_path: "", file: null , new: true};
          return this.entry.files.length-1;
        }
        for (var k = 1; k <= 5; k++) {
          var found = false;
          for (var i = 0; i < this.entry.files.length; i++) {
            if (this.entry.files[i].number == k) {
              found = true;
              
            }
          }
          if (!found) {
            this.showSuccess = false;
            this.entry.files.push({ speaker_id: 0, number: k, type: 1, link: "", description: "", filename: "", file_path: "", file: null , new: true});
            return k;
          }
        }
        return -1;
      },
      openFileDialog(file) {
        document.getElementById('fileupload' + file.number).click();
      },
      onFileFileChange(e, fileElement) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length || !this.checkFileSize(files[0]))
          return;
        fileElement.link = "";
        fileElement.file_path = "";
        fileElement.description = "";
        fileElement.new = true;
        fileElement.filename = files[0].name;
        fileElement.file = files[0];
        fileElement.new = true;
        if(fileElement.description.length == 0){
          fileElement.description = files[0].name;
        }
      },
     /* viewFile(h){
            router.push("/filecontrol/"+h);
          },*/
          viewLink(l){
          window.open(l, "_blank", 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
        },
      removeFileLink(file) {
        var index = this.entry.files.indexOf(file);
        if (index > -1) {
          this.entry.files.splice(index, 1);
        }
         if(this.entry.files.length == 0){
            this.entry.files.push({ speaker_id: 0, number: 1, type: 1, link: "", description: "", filename: "", file_path: "", file: null , new: true});
          }
      },
      gotoFile(hash){
        if(this.chapter_id>0){
          window.open('/filecontrol/'+hash, "_blank", 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
        }else{
          router.push('/filecontrol/'+hash);
        }
      },
      dateDisabled(ymd, date) {
        if(this.meeting_days.length>0){
          return !this.meeting_days.includes(ymd);
        }
        const weekday = date.getDay();
        return weekday !== this.meet_day_of_week;
      },
      cropMove() {
        this.tempPhoto = this.$refs.cropper.getCroppedCanvas().toDataURL();
      },
      setImage(e) {
        const file = e.target.files[0];
        if (!file.type.includes('image/')) {
          alert('Please select an image file');
          return;
        }
        if (typeof FileReader === 'function') {
          var self = this;
          this.resizeImage(file,665,1080, function(dataurl){
            self.imgSrc = dataurl;
            self.$refs.cropper.replace(dataurl);
            setTimeout(function () {
              self.tempPhoto = self.$refs.cropper.getCroppedCanvas().toDataURL();
            }, 200);
          });
        } else {
          alert('Sorry, FileReader API not supported');
        }
      },
      resizeImage(file, scale_width,scale_height, onEnd) {
          var reader = new FileReader();
          var self = this;
          reader.onload = function(e) {
              self.resizeDataUrl(e.target.result,file.type, scale_width, scale_height, onEnd);
          }
          reader.readAsDataURL(file);
    },
    resizeDataUrl(filedata,imagetype, scale_width, scale_height, onEnd){
      var img = document.createElement("img");
      img.src = filedata;
      img.onload = function(){
          var width = img.width;
          var height = img.height;
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");
          
          
          ctx.drawImage(img, 0, 0);
          var MAX_WIDTH = scale_width;
          var MAX_HEIGHT = scale_height;
          if (width > height) {
              if (width > MAX_WIDTH) {
                  height *= MAX_WIDTH / width;
                  width = MAX_WIDTH;
              }
          } else {
              if (height > MAX_HEIGHT) {
                  width *= MAX_HEIGHT / height;
                  height = MAX_HEIGHT;
              }
          }
          canvas.width = width;
          canvas.height = height;
          if(imagetype.includes("j")){
            ctx.fillStyle = '#fff'; 
            ctx.fillRect(0, 0, canvas.width, canvas.height);
          }
          ctx.drawImage(img, 0, 0, width, height);
          var dataurl = canvas.toDataURL(imagetype, 0.8);
          onEnd(dataurl);
      }
    },
      cropImage() {
        if(this.$refs.cropper.getCroppedCanvas()){
          this.entry.nm_photo = this.$refs.cropper.getCroppedCanvas().toDataURL();
          var self = this;
          this.resizeDataUrl(this.entry.nm_photo,'image/jpeg',132,216, function(dataurl){
              self.entry.nm_photo_small = dataurl;
          });
        }else{
          this.entry.nm_photo = null;
          this.entry.nm_photo_small = null;
        }
       
        this.showPhotoEdit = false;
      },
      getEntry() {
        if(this.chapter_id>0){
          this.entry.id = this.speakerObject.id;
        }else if(this.$route.params.id && this.$route.params.id.includes("-")){
          this.entry.id = 0;
          this.entry.day = this.$route.params.id;
        }else{
          this.entry.id = this.$route.params.id;
          if(this.entry.id==0){
            this.getNextMeetingDate();
          }
        }
        
        if (this.entry.id > 0) {
          this.entry.useruid = this.useruid;
          this.entry.org = this.chapter_id;
          this.$http.post("/api/organization/schedule/get"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""), this.entry).then((response) => {
            if (response.data.success) {
              //response.data.entry.files = [];
              this.entry = response.data.entry;
              if (!this.entry.bio) {
                this.entry.bio = "";
              }
              for (var i = 0; i < this.entry.files.length; i++) {
                  this.$set(this.entry.files[i], 'file', null);
                  this.$set(this.entry.files[i], 'filename', "");
                }
              if(this.entry.files.length == 0){
                this.entry.files.push({ speaker_id: 0, number: 1, type: 1, link: "", description: "", filename: "", file_path: "", file: null, new: true });
              }
              this.editor.setContent(this.entry.bio);

              this.tempMember = this.entry.speaker_type;
            }
            
          }).catch(() => {});
        }
      },
      getMembers() {
        this.$http.post("/api/organization/members"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""), {org: this.chapter_id,useruid: this.useruid}).then((response) => {
          this.members = response.data.members;
          this.members.unshift({id: 0, name: this.$t('entry.select_member')});
        }).catch(() => {});
      },
      getContacts() {
        this.$http.post("/api/organization/contacts/assigned"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""), {org: this.chapter_id,useruid: this.useruid}).then((response) => {
          for(var i=0; i<response.data.contacts.length;i++){
            response.data.contacts[i].name = response.data.contacts[i].name+ (response.data.contacts[i].contact_name.length>0? " ("+response.data.contacts[i].contact_name+")" : "");
          }
          this.original_contacts = response.data.contacts.slice();
          this.contacts = response.data.contacts;
          for(var j=0; j<this.original_contacts.length; j++){
              this.contacts[j].name = this.original_contacts[j].name;
          }
          this.contacts.unshift({id: 0, name: this.$t('organizations.select_member')});
        }).catch((errors) => {console.log(errors)});
      },
      updateEntry() {
        this.showAlert = false;
        if((this.tempMember==2 && this.entry.nm_name=="") || (this.tempMember!=2 && this.entry.member_id==0)){
          this.showAlert = true;
          return;
        }

        this.showPresentationAlert = false;
        /*if(this.entry.id >0){
          if(this.entry.type<0 || this.entry.type===false){
            this.showPresentationAlert = true;
            this.alertText = this.$t('presentation.presentation_error1');
            return;
          }else if(this.entry.type==1 && (this.entry.file == null && this.entry.url == "")){
            this.showPresentationAlert = true;
            this.alertText = this.$t('presentation.presentation_error2');
            return;
          }else if(this.entry.type==2 && this.entry.url.length == 0){
            this.showPresentationAlert = true;
            this.alertText = this.$t('presentation.presentation_error2'); 
            return;
          }
        }*/
        
       

        if (this.bio.cap_words == 1 && this.getNumWords() > this.bio.max_words) {
          var o = this.editor.getJSON();
          this.recurseBio(o, 0);
          this.editor.setContent(o);
        }
            this.showUpload = true;
            if (this.tempMember != 2) {
              this.entry.nm_name = "";
              this.entry.nm_company = "";
              this.entry.nm_title = "";
              this.entry.nm_email= "";
              this.entry.nm_photo = null;
              this.entry.nm_photo_small = null;
            } else {
              this.entry.member_id = 0;
              this.entry.nm_company = this.entry.nm_company ? this.entry.nm_company : "";
            }
           

            let formData = new FormData();
           // formData.append("file", this.entry.file);
            var presentationUploads = [];
            for (var i = 0; i < this.entry.files.length; i++) {
              if (this.entry.files[i].type == 1 && this.entry.files[i].file && this.entry.files[i].file != null) {
                formData.append("files", this.entry.files[i].file);
                this.entry.files[i].link = "";
                this.entry.files[i].name = this.entry.files[i].file.name;
                this.entry.files[i].file = null;
                presentationUploads.push( this.entry.files[i]);
              }else if (this.entry.files[i].link != ""){
                presentationUploads.push( this.entry.files[i]);
              }
            }
            formData.append("speaker_files", JSON.stringify(presentationUploads));
            formData.append("bio", this.editor.getHTML());
            formData.append("hash", this.entry.hash);
            formData.append("note", this.entry.note);
            formData.append("topic", this.entry.topic);
            formData.append("target_audience", this.entry.target_audience);
            formData.append("value_proposition", this.entry.value_proposition);
            formData.append("sharefile", this.entry.sharefile);
            formData.append("useruid", this.useruid);
            formData.append("org", this.chapter_id);

            this.$http.post("/api/organization/speaker/update"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""), formData, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            }).then(() => {
              this.showUpload = false;
              this.entry.useruid = this.useruid;
              this.entry.org = this.chapter_id;
               this.$http.post("/api/organization/schedule/update"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""),this.entry).then((response) => {
                if (response.data.success === true) {
                  this.saveVariant = 'success';
                  this.back();
                } else {
                  if(response.data.error && response.data.error.code == 1){
                    this.showDupeAlert = true;
                    return;
                  }
                  this.saveVariant = 'danger';
                }
            }).catch(() => {this.$bvToast.show('serverError'); });
              }).catch(() => {
                this.showUpload = false;
                this.$bvToast.show('serverError');
            });

          },
          deleteEntry() {
            this.entry.useruid = this.useruid;
            this.entry.org = this.chapter_id;
            this.$http.post("/api/organization/schedule/delete"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""), this.entry).then((response) => {
              if (response.data.success === true) {
                this.back();
              }
            }).catch(() => {});
          },
          back(){
            if(this.permissions.access){
               if(this.chapter_id>0){
                 this.speakerObject.id = -1;
               }else{
                  router.push("/admin/schedule");
               }
             
            }else if(this.useruid.length>0){
              router.push("/schedule/"+this.useruid);
            }
            
          },
          viewFile(h){
            window.open("/#/filecontrol/"+h, "_blank", 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
            //router.push("/filecontrol/"+this.entry.hash);
          },
          downloadFile(){

          },
          onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length || !this.checkFileSize(files[0]))
              return;
            this.createImage(files[0]);
          },
          createImage(file) {
            var reader = new FileReader();
            reader.onload = (e) => {
              this.entry.nm_photo = e.target.result;
            };
            reader.readAsDataURL(file);
          },
          removeImage: function () {
            this.entry.nm_photo = false;
          },
          openDialog() {
            document.getElementById('fileid').click();
          },
          openDialog2() {
            document.getElementById('fileid2').click();
          },
          onFileChange2(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length || !this.checkFileSize(files[0]))
              return;

            this.entry.filename = files[0].name;
            this.entry.file = files[0];
         },
      getMeetDay() {
        this.$http.post("/api/organization/get/meet_day_of_week"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""), {org: this.chapter_id,useruid: this.useruid}).then((response) => {
          if (response.data.success === true) {
            this.meet_day_of_week = (response.data.meet_day_of_week + 1) % 7;
            this.meeting_days = response.data.meeting_days;
            if((!response.data.official || this.isCorporateConnections) && this.meeting_days.length==0){
              this.meeting_days = [-1];
              this.showNoMeetingDates = true;
            }
          }
        }).catch(() => { });
      },
      getBioCap() {
        this.$http.post("/api/organization/get/biocap"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""), {org: this.chapter_id,useruid: this.useruid}).then((response) => {
          if (response.data.success === true) {
            this.bio.recommended_words = response.data.bio.recommended_words;
            this.bio.max_words = response.data.bio.max_words;
            this.bio.cap_words = response.data.bio.cap_words;
          }
        }).catch(() => { });
      },
      getNumWords() {
        var regex = /(<([^>]+)>)/ig
        var r = this.editor.getHTML().replace(regex, " ");
        var t = r.split(" ");
        var n = t.filter(function (el) {
          return el != "";
        }).length;
        if (this.bio.cap_words == 1 && !this.showedOverMax && n > this.bio.max_words) {
          this.showOverMax = true;
        }
        this.num_color = n <= this.bio.recommended_words ? "#999999" : ((this.bio.cap_words == 1 && n > this.bio.max_words ? '#ff0000' : '#eba834'));
        return n;
      },
      recurseBio(o, wordcount) {
        var l = 0;
        if (o.content) {
          var self = this;
          o.content = o.content.filter(function (el) {
            var k = self.recurseBio(el, wordcount) ;
            wordcount += k;
            l += k
            return k > 0;
          })
        }
        if (o.text) {
          if (wordcount >= this.bio.max_words) {
            o.text = "";
            return 0;
          } else if (o.text.split(" ").length + l + wordcount > this.bio.max_words) {
            var g = this.bio.max_words - l - wordcount;
            var a = o.text.split(" ").slice(0, g);
            wordcount = this.bio.max_words;
            o.text = a.join(" ");
            return a.length + l;
          } else {
            return o.text.split(" ").length + l;
          }
        } else {
          return l;
        }
      },
      rotateImageLeft(){
        this.$refs.cropper.rotate(90);
        this.cropMove();
      },
      rotateImageRight(){
        this.$refs.cropper.rotate(-90);
        this.cropMove();
      },
      flipImageX(){
        var d = this.$refs.cropper.getData()
        this.$refs.cropper.scaleX(d.scaleX*-1);
        this.cropMove();
      },
      flipImageY(){
        var d = this.$refs.cropper.getData()
        this.$refs.cropper.scaleY(d.scaleY*-1);
        this.cropMove();
      },
      getNextMeetingDate() {
        this.$http.post("/api/organization/dates"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""), {org: this.chapter_id,useruid: this.useruid}).then((response) => {
          if (response.data.success === true) {
            this.entry.day = response.data.next_formatted;
          }
        }).catch(() => {});
      },
      getIsMac(){
        return window.navigator.platform.indexOf('Mac')>=0 || window.navigator.platform.indexOf('mac')>=0 || window.navigator.platform.indexOf('Apple')>=0 || window.navigator.platform.indexOf('apple')>=0;
      }
    },
    beforeDestroy() {
      this.editor.destroy()
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-speakers"), "active-menu");
      this.removeClass(document.getElementById("menu-meeting"), "active-menu");
    },
    mounted() {
      if(this.$route.params.useruid){
        this.useruid = this.$route.params.useruid;
      }
      this.addClass(document.getElementById("menu-speakers"), "active-menu");
      this.addClass(document.getElementById("menu-meeting"), "active-menu");
      this.getMeetDay();
      this.getMembers();
      this.getContacts();
      this.getEntry();
      this.getBioCap();
    }
  }
</script>
