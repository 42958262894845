<template>
  <div style="display:grid;grid-template-rows: auto max-content max-content max-content auto;height:100%;">
      <div></div>
      <div id="logo-box">
      </div>
      <div style="display:grid; grid-template-columns: max-content max-content; margin:auto;" class="fit-width" v-show="alertText!='' && alertVariant!='danger'">
          <span class="form-box-name">{{$t('general.host_chapter')}}:</span><span style="margin: 10px 5px;">{{info.host_name}}</span>
          <span class="form-box-name">{{$t('general.joint_chapter')}}:</span><span style="margin: 10px 5px;">{{info.joint_name}}</span>
          <span class="form-box-name">{{$t('general.joint_date')}}:</span><span style="margin: 10px 5px;">{{info.joint_day? $d(new Date("" + info.joint_day.split("-").join("/") + " 00:01"), 'short') : ""}}</span>
          <span class="form-box-name">{{$t('general.joint_start')}}:</span><span style="margin: 10px 5px;">{{info.joint_start}}</span>
      </div>
      <b-alert class="fit-width" style="margin:auto;" :variant="alertVariant" :show="true">
            {{alertText}}
          </b-alert>
      <div></div>
  </div>
</template>
<style scoped>
#logo-box{
     margin: auto;margin-bottom:25px; max-width: 600px;
    background-image: url("../assets/logo-MeetingHubwSlogan2020.png");
    background-repeat: no-repeat;
    background-size: auto 150px;
    background-position: center;
    width:600px;
    background-color: #FFFFFF;
    height: 150px;
}
</style>
<script>
export default {
  name: "ConfirmJoint",
  data() {
        return {
            alertText: "",
            alertVariant: "success",
            hash : "",
            info: {host_name: "", joint_name: "", joint_day: null, joint_start: ""}
        }
  },
  methods: {
    confirm(){
    if(this.$route.params.hash){
        this.hash = this.$route.params.hash;
      }
      this.$http.post("/api/organization/joint/confirm",{hash: this.hash}).then((response) => {
          if(response.data.success){
             this.info = response.data.info;
              if(response.data.confirmed){
                this.alertVariant = "success";
                this.alertText = this.$t('confirm.joint_confirmed');
              }else{
                this.alertVariant = "warning";
                this.alertText = this.$t('confirm.already_confirmed');
              }
          }else{
              this.alertVariant = "danger";
              this.alertText = this.$t('confirm.link_incorrect');
          }
      }).catch((e) => { 
       console.log(e);
      })
    },
  },
  created() {
    this.confirm();
  }
}
</script>
