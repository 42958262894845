<template>
<div >
    <div v-if="!embedded" style="display:grid; grid-template-columns: max-content auto;">
        <img id="small-photo" style="height:60px;" v-if="!(imageObject.photo && imageObject.photo.length>0) && (imageObject.photo_small && imageObject.photo_small.length>0)" :src="imageObject.photo_small">
        <img id="large-photo" style="height:60px;" v-if="imageObject.photo && imageObject.photo.length>0" :src="imageObject.photo">
        <b-button class="submit-button4" style="margin: auto 20px;" v-on:click="editImage" variant="clear-blue"><div class="preview-button"></div><span>{{$t('global.set_image')}}</span></b-button>
        <b-modal id="slide-modal" centered  v-model="showPhotoEdit" :title="$t('global.set_image')">
            <b-form-file id='image-selector-upload' accept="image/jpeg, image/png, image/gif" @change="setImage" plain hidden></b-form-file>
            <div :style="{'display': 'grid', 'grid-template-columns': 'min-content max-content auto'}">
                <div id="rotate-options">
                    <b-button v-if="imageEdit.preview && imageEdit.preview.length>0" v-on:click="rotateImageRight" class="image-button" variant="clear-blue"><div class="rotate-left-button"></div></b-button>
                    <b-button v-if="imageEdit.preview && imageEdit.preview.length>0" v-on:click="rotateImageLeft" class="image-button" variant="clear-blue"><div class="rotate-right-button"></div></b-button>
                    <b-button v-if="imageEdit.preview && imageEdit.preview.length>0" v-on:click="flipImageX"  class="image-button" variant="clear-blue"><div class="flip-y-button"></div></b-button>
                    <b-button v-if="imageEdit.preview && imageEdit.preview.length>0" v-on:click="flipImageY" class="image-button" variant="clear-blue"><div class="flip-x-button"></div></b-button>
                </div>
                <div style="width: auto; height:300px; margin: 20px auto; border: 1px solid gray; display: inline-block;">
                    <vue-cropper ref='cropper' :guides="true" :view-mode="2" drag-mode="crop" :auto-crop="true" :auto-crop-area="1"
                                :min-container-width="100" :min-container-height="100" :background="true" :rotatable="true"
                                :src="imageEdit.preview" :aspectRatio="ratio" :cropmove="cropMove" alt="Source Image"
                                :img-style="{ 'width': 'auto', 'height': '300px', 'max-width':'300px' }">
                    </vue-cropper>
                </div>
                <div v-if="isCorporateConnections && circular" class="circular-member" :style="{'background-image': 'url('+imageEdit.preview+')', width: '300px', height: '300px'}"></div>
                <img v-if="!isCorporateConnections || !circular" id="set-news-preview" style="width:100%;height:100%;object-fit: contain;" v-show="imageEdit.preview"  :src="imageEdit.preview" />
                <div id="zoom-text">{{$t('member.zoom_text')}}</div>
                <b-button class="submit-button4" v-on:click="openDialog" variant="blue" style="grid-column:2/3;margin: auto;">{{$t('memberphoto.upload_photo')}}</b-button>
            </div>
            <div slot="modal-footer" class="submit-box-2">
                <b-button class="submit-button4" v-on:click="showPhotoEdit=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
                <b-button class="submit-button4" v-on:click="cropImage()" variant="clear-blue"><div class="save-button"></div><span>{{$t('memberphoto.set_photo')}}</span></b-button>
            </div>
        </b-modal>
    </div>
    <div v-if="embedded">
        <b-form-file id='image-selector-upload' accept="image/jpeg, image/png, image/gif" @change="setImage" plain hidden></b-form-file>
        <div :style="{'display': 'grid', 'grid-template-columns': 'min-content max-content auto'}">
            <div id="rotate-options">
                <b-button v-if="imageObject.photo && imageObject.photo.length>0" v-on:click="rotateImageRight" class="image-button" variant="clear-blue"><div class="rotate-left-button"></div></b-button>
                <b-button v-if="imageObject.photo && imageObject.photo.length>0" v-on:click="rotateImageLeft" class="image-button" variant="clear-blue"><div class="rotate-right-button"></div></b-button>
                <b-button v-if="imageObject.photo && imageObject.photo.length>0" v-on:click="flipImageX"  class="image-button" variant="clear-blue"><div class="flip-y-button"></div></b-button>
                <b-button v-if="imageObject.photo && imageObject.photo.length>0" v-on:click="flipImageY" class="image-button" variant="clear-blue"><div class="flip-x-button"></div></b-button>
            </div>
            <div style="width: auto; height:300px;  border: 1px solid gray; display: inline-block;">
                <vue-cropper ref='cropper' :guides="true" :view-mode="2" drag-mode="crop" :auto-crop="true" :auto-crop-area="1"
                            :min-container-width="100" :min-container-height="100" :background="true" :rotatable="true"
                            :src="imageObject.photo" :aspectRatio="ratio" :cropmove="cropMove" alt="Source Image"
                            :img-style="{ 'width': 'auto', 'height': '300px', 'max-width':'300px' }">
                </vue-cropper>
            </div>
            <div v-if="isCorporateConnections" class="circular-member" :style="{'background-image': 'url('+imageObject.photo+')', width: '300px', height: '300px', margin: '20px'}"></div>
                <img v-if="!isCorporateConnections" id="set-news-preview" style="width:100%;height:100%;object-fit: contain;" v-show="imageObject.photo"  :src="imageObject.photo" />
            <div id="zoom-text">{{$t('member.zoom_text')}}</div>
            <b-button class="submit-button4" v-on:click="openDialog" variant="blue" style="grid-column:2/3;margin: auto;">{{$t('memberphoto.upload_photo')}}</b-button>
        </div>
    </div>
</div>
</template>
<style scoped>
</style>
<script>
import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
export default {
    name: "ImageSelector",
     props: {imageObject: Object, ratio: {type: Number, default: NaN}, maxWidth: {type: Number, default: 1000}, maxheight: {type: Number, default: 1000}, embedded: {type: Boolean, default: false}, circular: {type: Boolean, default: true}},
     components: {
      VueCropper
    },
  data() {
    return {
      showPhotoEdit: false,
      image: '',
      imageEdit: {preview: ''},
      hasUploaded: false,
    };
  },
  methods: {
      editImage(){
          this.showPhotoEdit = true;
          this.openPhotoEdit((s => this.$set(this.imageObject, 'photo', s)),this.imageObject.photo,this.ratio,this.maxWidth, this.maxheight)
      },
      resizeDataUrl(filedata, imagetype, scale_width, scale_height, onEnd){
        var img = document.createElement("img");
        img.src = filedata;
        img.onload = function(){
          var width = img.width;
          var height = img.height;
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");
         
          ctx.drawImage(img, 0, 0);
          var MAX_WIDTH = scale_width>0? scale_width : width;
          var MAX_HEIGHT = scale_height>0? scale_height : height;
          if (width > height) {
              if (width > MAX_WIDTH) {
                  height *= MAX_WIDTH / width;
                  width = MAX_WIDTH;
              }
          } else {
              if (height > MAX_HEIGHT) {
                  width *= MAX_HEIGHT / height;
                  height = MAX_HEIGHT;
              }
          }
          canvas.width = width;
          canvas.height = height;
          if(imagetype.includes("j")){
            ctx.fillStyle = '#fff'; 
            ctx.fillRect(0, 0, canvas.width, canvas.height);
          }
          ctx.drawImage(img, 0, 0, width, height);
          var dataurl = canvas.toDataURL(imagetype, 0.8);
          onEnd(dataurl);
        }
      },
      cropImage(callback) {
        var self = this;
        if(this.$refs.cropper.getCroppedCanvas()){
          var image_data = self.$refs.cropper.getCroppedCanvas().toDataURL('image/png', 0.8);
          if(this.imageEdit.maxwidth>0 || this.imageEdit.maxheight>0){
            this.imageObject.photo = image_data;
            this.resizeDataUrl(image_data,'image/png',216,216, function(dataurl){
              self.$set(self.imageObject, 'photo_small', dataurl);
              self.resizeDataUrl(image_data, 'image/png', self.imageEdit.maxwidth, self.imageEdit.maxheight, function(d){
                self.imageEdit.successFunc(d);
                if(callback && callback != undefined){
                  callback();
                }
              });
            });
          }else{
            this.resizeDataUrl(image_data,'image/png',216,216, function(dataurl){
              self.$set(self.imageObject, 'photo_small', dataurl);
              self.imageEdit.successFunc(self.$refs.cropper.getCroppedCanvas().toDataURL('image/png', 0.8));
              if(callback && callback != undefined){
                  callback();
                }
            });
          }
        }
        this.showPhotoEdit = false;
      },
      openPhotoEdit(sf, d,ratio, maxwidth, maxheight){
        this.imageEdit= {data: "", preview: "", successFunc: null, ratio: null,maxwidth:0,maxheight:0}
        this.imageEdit.ratio = ratio;
        this.imageEdit.successFunc = sf;
        this.imageEdit.maxwidth = maxwidth;
        this.imageEdit.maxheight = maxheight;
        var self = this;
        if(d && d.length>0){
          this.imageEdit.data = d;
          if(this.$refs.cropper != undefined){
            this.$refs.cropper.replace(self.imageEdit.data);
          }
         setTimeout(function () {
              self.cropMove();
            }, 200);
        }else{
          this.imageEdit.data = "";
          if(this.$refs.cropper){
             this.$refs.cropper.replace(null);
          }
        }
      },
      cropMove() {
        if(this.$refs.cropper != undefined && this.$refs.cropper.getCroppedCanvas() != null){
          if(this.embedded){
              this.imageObject.photo = this.$refs.cropper.getCroppedCanvas().toDataURL('image/png', 0.8);
          }else{
              this.imageEdit.preview = this.$refs.cropper.getCroppedCanvas().toDataURL('image/png', 0.8);
          }
        }
      },
      rotateImageLeft(){
        this.$refs.cropper.rotate(90);
        this.cropMove();
      },
      rotateImageRight(){
        this.$refs.cropper.rotate(-90);
        this.cropMove();
      },
      flipImageX(){
        var d = this.$refs.cropper.getData()
        this.$refs.cropper.scaleX(d.scaleX*-1);
        this.cropMove();
      },
      flipImageY(){
        var d = this.$refs.cropper.getData()
        this.$refs.cropper.scaleY(d.scaleY*-1);
        this.cropMove();
      },
      setImage(e) {
        const file = e.target.files[0];
        if (!file.type.includes('image/')) {
          return;
        }
        if (typeof FileReader === 'function') {
          var reader = new FileReader();
          var self = this;
          this.hasUploaded = true;
          reader.onload = function(e) {
              self.imageEdit.data = e.target.result;
              self.$refs.cropper.replace(e.target.result);
              setTimeout(function () {
                self.cropMove();
              }, 200);
              
          }
          reader.readAsDataURL(file);
          
        }
      },
      openDialog() {
        document.getElementById('image-selector-upload').click();
      },
      getHasUploaded(){
        return this.hasUploaded;
      }
  },
    mounted(){
        this.openPhotoEdit((s => this.$set(this.imageObject, 'photo', s)),this.imageObject.photo,this.ratio,this.maxWidth, this.maxheight)
    },
  beforeDestroy() {
    },
}
</script>