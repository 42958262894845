<template>
  <div>
    <div class="rel-grid-container">
      <div id="top-div"></div>
      <div >
        <b-table class="table-hover-new" small bordered :items="countries" :fields="fields" v-on:row-clicked="editCountry">
          <template v-slot:cell(languages)="data">
              <pre class="pre-format" >{{data.item.languages}}</pre>
            </template>
          <template v-slot:cell(logAs)="data">
            <b-button v-if="data.item.has_login>0"  style="position:relative;font-size:16px;" size="sm" @click.stop="loginAsNational(data.item)" variant="light-blue-outline">
              {{$t('organizations.login_as')}}
            </b-button>
          </template>
        </b-table>
        <div v-if="permissions.access" class="submit-box-2" style="width:100%;">
          <b-button style="margin:auto;"  class="submit-button4" v-on:click="newCountry" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
        </div>
      </div> 
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
 
</style>
<script>
  import router from "@/router"
    export default {
    name: "Country",
    data() {
      return {
        countries: [],
        moving:false,
        languageOptions: [],
        fields: [{
              key: 'name',
              label: this.$t('language.name_text'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
            { key: 'alpha_2',
              label: this.$t('language.abbrev_text'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff", "text-align": "center"  },
              tdClass: "actions-centre"
            },
             { key: 'languages',
              label: this.$t('country.languages_text'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
            { key: 'logAs',
              label: "",
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" , "text-align": "center"},
              tdClass: "actions-centre"
            },
          ],
      }
    },
    methods: {
      loginAsNational(org){
        this.$http.post("/api/login/tonational", {id: org.id}).then((response) => {
            if(response.data.success){
              this.permissions.access = response.data.permissions.access;
              this.permissions.admin = response.data.permissions.admin;
              this.permissions.country = response.data.permissions.country;
              this.permissions.organization = response.data.permissions.organization;
              this.permissions.user = response.data.permissions.user;
              document.title = this.$t('global.document_title_admin') +" "+ this.permissions.organization.name;
              router.push("/admin/regions");
            }
        }).catch((errors) => {console.log(errors);});
      },
      getLanguages() {
        this.$http.post("/api/superadmin/languages", {}).then((response) => {
          if (response.data.success) {
            this.languageOptions = response.data.languages;
            this.getCountries();
          }
        }).catch(() => {
        });
      },
      getCountries() {
        this.$http.post("/api/superadmin/countries", {}).then((response) => {
          if (response.data.success) {

            for(var x =0; x<response.data.countries.length; x++){
              var country = response.data.countries[x];
              var ids = country.languages.split(",");
              country.languages = [];
             
              for(var i=0; i<this.languageOptions.length;i++){
                  if(ids.indexOf(this.languageOptions[i].id+"")>=0){
                      country.languages.push(this.languageOptions[i].name);
                  }
              }
              country.languages = country.languages.join("\n");
            }

            this.countries = response.data.countries;
          }
        }).catch((e) => {console.log(e);
        });
      },
      newCountry() {
        if (this.permissions.access) {
          router.push("/admin/country/0");
        }
      },
      editCountry(item) {
        if (this.permissions.access) {
          router.push("/admin/country/" + item.id);
        }
      },
      startDragCat(){
        this.moving = true;
      }
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-countries"), "active-menu");
      this.removeClass(document.getElementById("menu-admin"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-countries"), "active-menu");
      this.addClass(document.getElementById("menu-admin"), "active-menu");
      this.getLanguages();
    }
    }
</script>
