<template>
  <div>
    <div class="rel-grid-container">
      <div id="top-div"></div>
      <form v-on:submit.prevent="update" id="form-fix">
        <span class="form-box-title">{{$t('subscription.subscription_text')}}</span>
        <div  class="form-box" style="grid-template-columns: auto;color: black;">
            <div class="sub-box">
                <span :style="{'font-weight':'bold', 'color': status_color}">{{status_text}}</span>
                <span v-if="status_line1!=''" class="status-line" style="margin-top:30px;">{{status_line1}}</span>
                <span v-if="status_line2!=''" class="status-line">{{status_line2}}</span>
            </div>
            <b-button v-if="subscription.status==2 && permissions.organization.is_parent" variant="link" class="fit-height" style="border:none;margin-top:20px;" v-on:click="activateSub">{{$t('subscription.activate_plan')}}</b-button>
            <div style="display: grid;text-align:left;">
                <div style="display:grid; grid-template-columns: 80% 20%; padding:5px;">
                    <span >{{$t('subscription.base_cost')}}</span>
                    <span style="color:black;margin: auto;grid-row: 1/3;grid-column: 2;">${{country.monthly_price}}</span>
                    <span style="margin-top: 0px;font-size: 12px;line-height: 10px;">{{$t('subscription.weekly_recurring')}}</span>
                </div>
                <div v-if="global_discount>0 && subscription.status!=1" style="display:grid; grid-template-columns: 80% 20%;;border-top: 1px solid #aaa;padding:5px;padding-left:15px;">
                    <span   style="margin-bottom:0px;">{{$t('subscription.bni_discount',{discount: global_discount})}}</span>
                    <span  style="color:black;margin: auto;grid-row: 1/3;grid-column: 2;">-${{country.monthly_price*(global_discount)/100}}</span>
                    <span   style="margin-top: 0px;font-size: 12px;line-height: 10px;">{{$t('subscription.our_gift')}}</span>
                </div>
                <div v-if="country.monthly_discount>0  && subscription.status!=1" style="display:grid; grid-template-columns: 80% 20%;;border-top: 1px solid #aaa;padding:5px;padding-left:15px;">
                    <span  style="margin-bottom:0px;">{{$t('subscription.country_discount',{discount: country.monthly_discount, country: country.name})}}</span>
                    <span style="color:black;margin: auto;grid-row: 1/3;grid-column: 2;">-${{country.monthly_price*(country.monthly_discount)/100}}</span>
                    <span  style="margin-top: 0px;font-size: 12px;line-height: 10px;">{{$t('subscription.thanks_country',{country: country.name})}}</span>
                </div>
                <!--<div v-if="!permissions.organization.is_parent  && subscription.status!=1" style="display:grid; grid-template-columns: 80% 20%;;border-top: 1px solid #aaa;padding:5px;padding-left:15px;">
                    <span  >{{$t('subscription.region_pays_text')}}</span>
                    <span style="color:black;margin:  auto;">-${{country.monthly_price*(100-(global_discount+country.monthly_discount))/100-(chapter_pays==0? 0 : chapter_price)}}</span>
                </div>-->
                <div v-if="subscription.status!=1" style="display:grid; grid-template-columns: 80% 20%;border-top: 1px solid #aaa;padding:5px;">
                    <span >{{permissions.organization.is_parent? $t('subscription.per_cost') : $t('subscription.your_cost')}}</span>
                    <span style="font-weight:bold;color:black;margin: auto;">${{country.monthly_price*(100-(global_discount+country.monthly_discount))/100}}</span>
                </div>
                <div v-if="subscription.status==1" style="display:grid; grid-template-columns: 80% 20%;;border-top: 1px solid #aaa;padding:5px;padding-left:15px;">
                    <span  >{{$t('subscription.pilot_discount')}}</span>
                    <span style="color:black;margin:  auto;">-${{country.monthly_price}}</span>
                    <span  style="margin-top: 0px;font-size: 12px;line-height: 10px;">{{$t('subscription.thank_pilot')}}</span>
                </div>
                <div v-if="subscription.status==1" style="display:grid; grid-template-columns: 80% 20%;border-top: 1px solid #aaa;padding:5px;">
                    <span >{{permissions.organization.is_parent? $t('subscription.per_cost') : $t('subscription.your_cost')}}</span>
                    <span style="font-weight:bold;color:black;margin: auto;">$0</span>
                </div>
            </div>
            <div style=" background-color: #eee;padding:10px;text-align:center;display: grid; margin: 50px 0px;">
                <span >{{subscription.status==1? $t('subscription.bni_covered_pilot') : $t('subscription.bni_covered')}}</span>
            </div>
            <!--<b-button v-if="subscription.status==3 && (permissions.organization.is_parent || ((chapter_pays==0? 0 : chapter_price))>0)" style="grid-column: 1/3;" variant="link" class="fit-height" v-on:click="showAlert=false; showCancelModal=true;">{{$t('subscription.cancel_plan')}}</b-button>-->
            
        </div>
        <div style="text-align: center;">Meeting HUB <TermsAndConditions /></div>
        <b-modal id="modal-center" v-model="showActivateSuccess" centered :title="$t('subscription.sub_activated')">
            <div style="margin-top:30px;">{{$t('subscription.thank_you')}}</div>
            <div v-if="permissions.organization.is_parent" >{{$t('subscription.region_activated1')}}</div>
            <div v-if="permissions.organization.is_parent" style="margin-top:30px;">{{$t('subscription.region_activated2')}}</div>
            <div v-if="!permissions.organization.is_parent" >{{$t('subscription.chapter_activated1')}}</div>
            <div v-if="!permissions.organization.is_parent" style="margin-top:30px;">{{$t('subscription.chapter_activated2')}}</div>
            <div slot="modal-footer" class="submit-box-2">
            <b-button class="submit-button4" v-on:click="showActivateSuccess=false" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
            </div>
        </b-modal>
      </form>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
.status-line{
    color: #007bff;font-size:12px;
}
.sub-box{
    background-color: #eee; border: 1px solid #666; border-radius: 10px;min-width: 150px; min-height:100px;display:grid; color: #4588ed;    padding: 20px;
    text-align: center; line-height:16px; margin: auto;
}
</style>
<script>
  import router from "@/router"
    export default {
    name: "SubscriptionStub",
      data() {
        return {
            password: '',
            showActivateSuccess: false,
            showActivateModal: false,
            showCancelModal: false,
            showAlert: false,
            alertText: '',
            showCardModal: false,
            status_text: '',
            status_color: '',
            status_line1: '',
            status_line2: '',
            sessionId: '',
            token: null,
            global_discount: 0,
            chapter_price: 0,
            chapter_pays: 0,
            sub_plan: {billing_price: 0, global_discount: 0, country_discount: 0},
            subscription: {status: 0, expiry_date:null, expired: true},
            showAlert: false,
            country: {monthly_price: 0,currency_type: 'cad', trial_months: 1,
                trial_chapters: 1,monthly_discount: 0 },
        }
      },
        methods: {
            cancelSub(){
                this.showAlert = false,
                this.$http.post("/api/organization/subscription/cancel", {password: this.password}).then((response) => {
                    if (response.data.success) {
                        this.getSubscription();
                        this.showCancelModal = false;
                    }else if(!response.data.password){
                        this.showAlert = true;
                    }
                }).catch(() => {this.$bvToast.show('serverError');});
            },
            activateSub(){
                this.showActivateModal = false;
                 this.$http.post("/api/organization/subscription/activate", {chapter_price: 0, chapter_pays: 0}).then((response) => {
                    if (response.data.success === true) {
                        this.showActivateSuccess = true;
                        this.getSubscription();
                    }
                }).catch(() => {this.$bvToast.show('serverError');});
            },
          getSubscription(){
              this.$http.post("/api/organization/subscriptionstub/get", {}).then((response) => {
              if (response.data.success === true) {
                this.chapter_price = response.data.chapter_price;
                this.chapter_pays = response.data.chapter_pays;
                this.sub_plan = response.data.sub_plan;
                this.country = response.data.country;
                this.chapters = response.data.chapters;
                this.subscription_status = response.data.subscription_status;
                this.global_discount = response.data.global_discount;
                this.subscription = response.data.subscription;
                this.status_line1 = '';
                this.status_line2 = '';
                if(this.subscription.status == 0){
                    this.status_text = this.$t('subscription.imported_text');
                    this.status_color= 'black';
                }else if(this.subscription.status == 1){
                    this.status_text = this.$t('subscription.pilot_text');
                    this.status_color= '#63BE7B';
                }else if(this.subscription.status == 2 && !this.subscription.expired){
                    this.status_text = this.$t('subscription.trial_text');
                    this.status_color= '#63BE7B';
                    this.status_line1 = this.$t('subscription.trial_details', {chapters: this.country.trial_chapters, months: this.country.trial_months});
                    this.status_line2 = this.$t('subscription.trial_descript', {date: this.subscription.expiry_date});
                }else if(this.subscription.status == 2 && this.subscription.expired){
                    this.status_text = this.$t('subscription.expired_text');
                    this.status_color= '#F8696B';
                    this.status_line1 = this.$t('subscription.trial_details', {chapters: this.country.trial_chapters, months: this.country.trial_months});
                    this.status_line2 = this.$t('subscription.trial_descript', {date: this.subscription.expiry_date});
                }else if(this.subscription.status == 3){
                    this.status_text = this.$t('subscription.active_text');
                    this.status_color= '#63BE7B';
                }else if(this.subscription.status == 4){
                    this.status_text = this.$t('subscription.inactive_text');
                    this.status_color= '#F8696B';
                }
              }
            }).catch(() => {this.$bvToast.show('serverError');});
          },
          back(){
            window.history.length > 1 ? router.go(-1) : router.push(permissions.organization.is_parent? "/admin/regiondash" : "/admin/dashboard");
          }
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-admin"), "active-menu");
      this.removeClass(document.getElementById("menu-subscription"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-admin"), "active-menu");
      this.addClass(document.getElementById("menu-subscription"), "active-menu");
      this.getSubscription();
    }
    }
</script>
