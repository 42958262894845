<template>
  <div>
    <div class="rel-grid-container" >
      <Tip :tipNumber="25"></Tip>
      <div id="top-div">
        <div>
          <b-button v-show="multipleMembers" :disabled="!loaded" v-on:click="updateMember(previousMember)" class="submit-button4 fit-width" style="margin-top: 2px;margin: 0px;" variant="clear-blue"><div class="back-button"></div><span>{{$t('global.button_previous')}}</span></b-button>
        </div>
        <div>
          <b-button v-show="multipleMembers" :disabled="!loaded" v-on:click="updateMember(nextMember)" class="submit-button4 fit-width" style="width:93px; margin-right: 0px;margin-left: auto;margin-top: 2px;" variant="clear-blue"><div class="next-button"></div><span>{{$t('global.button_next')}}</span></b-button>
        </div>
      </div>
      <form v-on:submit.prevent="checkAndUpdateMember">
        <div id="big-box">
          <div>
            <div>
              <span class="form-box-title">{{is_parent? $t('member.contact_info') : (official? $t('member.member_info') : $t('member.applicant_info'))}}</span>
              <div class="form-box" style="grid-template-columns: max-content max-content;">
                <span style="white-space: nowrap;" class="form-box-name">{{$t('member.name_text')}}</span><b-form-input v-model="member.name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;" class="form-box-input" required></b-form-input>
                <b-form-checkbox style="grid-column: 2;" v-model="member.disabled" value="1" unchecked-value="0">
                  {{$t('member.disabled_text')}}
                </b-form-checkbox>
                <span v-if="!is_parent" class="form-box-name" style="    grid-column: 1;">{{ $t('member.category_text')}}</span><b-form-input v-if="!is_parent" v-model="member.category" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
                <span v-if="!is_parent && !official" class="form-box-name" style="    grid-column: 1;">{{ $t('member.city_text')}}</span><b-form-input v-if="!is_parent && !official" v-model="member.city" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
                <span v-if="is_parent" class="form-box-name" style="    grid-column: 1;">{{ $t('member.contact_type')}}</span>
                <multiselect :noOptions="$t('global.empty_list')" v-if="is_parent" style="grid-column:2/4;max-width: 400px;" :class="{blueselect:true}" id="contact-select" :selectedLabel="$t('global.selected_text')" selectLabel="" deselectLabel=""  v-model="member.contact_types" :options="typeOptions" :multiple="true" :close-on-select="false"  :preserve-search="true" :placeholder="$t('global.select_types')" label="name" track-by="id" :preselect-first="false">
                  <template slot="option" slot-scope="props">
                    <div>{{ props.option.name }}</div>
                  </template>
                </multiselect>
                <span class="form-box-name" s>{{$t('member.company_text')}}</span><b-form-input v-model="member.company" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
                <span class="form-box-name" v-if="isCorporateConnections">{{$t('member.job_title')}}</span><b-form-input v-if="isCorporateConnections" v-model="member.job_title" type="text" class="form-box-input"></b-form-input>
                <span class="form-box-name">{{$t('member.phone_text')}}</span>
                <b-form-input v-model="member.phone" type="tel" style="max-width: 300px;" class="form-box-input"></b-form-input>
                <span class="form-box-name" v-if="isCorporateConnections">{{$t('member.mobile_phone')}}</span>
                <b-form-input v-if="isCorporateConnections" v-model="member.mobile_phone" type="tel" style="max-width: 300px;" class="form-box-input"></b-form-input>
                <span class="form-box-name">{{$t('member.email_text')}}</span><b-form-input v-model="member.email" type="email" class="form-box-input" name="whateveremail" autocomplete="new-email"></b-form-input>
                <span style="grid-column:1" class="form-box-name" v-if="isCorporateConnections">{{$t('member.preferred_contact')}}</span>
                <b-form-select v-if="isCorporateConnections" style="max-width: 300px;" class="form-box-input"  v-model="member.preferred_contact" :options="contactMethods"/>
                <span class="form-box-name">{{$t('member.website_text')}}</span><b-form-input v-model="member.website" type="text" class="form-box-input"></b-form-input>
                <span class="form-box-name" v-if="isCorporateConnections">{{$t('member.linkedin_url')}}</span><b-form-input v-if="isCorporateConnections" v-model="member.linkedin_url" type="text" class="form-box-input"></b-form-input>
                <span class="form-box-name" v-if="isCorporateConnections">{{$t('member.member_presence')}}</span>
                <multiselect :noOptions="$t('global.empty_list')" v-if="isCorporateConnections" style="grid-column:2/4;max-width: 300px;margin-bottom:5px;" :class="{blueselect:true}" id="contact-select" :selectedLabel="$t('global.selected_text')" selectLabel="" deselectLabel=""  v-model="presence_types" :options="presenceTypeOptions" :multiple="true" :close-on-select="false"  :preserve-search="true" :placeholder="$t('global.select_types')" label="text" track-by="value" :preselect-first="false">
                  <template slot="option" slot-scope="props">
                    <div>{{ props.option.text}}</div>
                  </template>
                </multiselect>
                <span class="form-box-name" v-if="official">{{$t('member.member_since')}}</span>
                <div v-if="official" style="display:grid;grid-template-columns: max-content max-content;grid-gap: 20px;"><b-form-select  v-model="member.since_year" :options="yearOptions"  /><b-form-select  v-model="member.since_month" :options="monthOptions" /></div>
                <span style="grid-column:1" class="form-box-name" v-if="isCorporateConnections">{{$t('member.sponsor_text')}}</span>
                <b-form-select v-if="isCorporateConnections" style="max-width: 300px;" class="form-box-input"  v-model="member.sponsoring_member_id" :options="allMembers" value-field="id" text-field="name" />
                <!--<b-form-datepicker v-if="official" style="margin: 5px 0px;"  v-model="member.member_since" :locale="$i18n.locale" ></b-form-datepicker>-->
                <!--<span class="form-box-name">{{$t('member.renewal_date')}}</span>
                 <b-form-datepicker style="margin: 5px 0px;"  v-model="member.renewal" :locale="$i18n.locale" :date-format-options="{ month: 'long', day: '2-digit' }"></b-form-datepicker>-->
                 <span v-if="!is_parent && use_taglines" class="form-box-name" style="grid-column: 1/2;">{{$t('memberphoto.tag_line')}}</span>
                <div v-if="!is_parent && use_taglines" class="form-box-input">
                    <b-form-textarea v-model="member.tagline" :rows="2" :maxlength="tagline_chars"  />
                    <div class="page-note" :style="{'color': member.tagline.length>=tagline_chars? 'red' : ''}">{{tag_count}}</div>
                </div>
                <span v-if="!is_parent && official" class="form-box-name" style="grid-column: 1/2;">{{$t('memberphoto.weekly_ask')}}</span>
                <div v-if="!is_parent && official" class="form-box-input">
                    <b-form-textarea v-model="member.ask" :rows="2" maxlength="120"  />
                    <div class="page-note" :style="{'color': member.ask.length>=120? 'red' : ''}">{{char_count}}</div>
                </div>
                <span v-if="isCorporateConnections" class="form-box-name" style="grid-column: 1/2;">{{$t('member.member_introduction')}}</span>
                <b-form-textarea v-if="isCorporateConnections" v-model="member.introduction" :rows="2" maxlength="255" class="form-box-input" />
              </div>
            </div>
            <div v-if="member.roles.length>0 && !is_parent && official" >
              <span class="form-box-title">{{$t('member.role_info')}}</span>
              <div class="form-box" style="grid-template-columns: max-content max-content;">
                <span class="form-box-name">{{$t('member.sub_role')}}</span><b-form-input v-model="member.sub_role" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;" class="form-box-input"></b-form-input>
                <span class="form-box-name">{{$t('member.sub_description')}}</span><b-form-textarea v-model="member.role_description" style="margin-bottom: 10px;" placeholder="" class="form-box-input" :rows="2" :max-rows="2" />
              </div>
            </div>
            <div v-if="official || (allowPhotos==1 || is_parent)">
              <span class="form-box-title">{{is_parent? $t('member.contact_media') :$t('member.member_photo')}}</span>
              <div class="form-box" style="grid-template-columns: auto;">
                <div id="member-photo-box">
                  <div v-show="!member.photo" style="height: 100px;"></div>
                  <img v-show="member.photo" id="member-photo" :src="member.photo" />
                  <b-button class="submit-button4" style="margin: auto 15px;" v-on:click="openPhotoEdit" variant="blue">{{$t('member.set_photo')}}</b-button>
                  <b-button class="submit-button4" style="margin: auto 15px;" v-on:click="clearPhoto" variant="blue">{{$t('member.clear_photo')}}</b-button>
                  <b-button v-if="member.id>0" class="submit-button4" style="margin: auto 15px;" v-on:click="requestPhotoByEmail()" variant="blue">{{$t('member.request_photo')}}</b-button>
                  <b-form-file id='fileid' accept="image/jpeg, image/png, image/gif" @change="setImage" plain hidden></b-form-file>
                  <img v-if="allowLogos" v-show="member.logo" id="member-photo" :src="member.logo" />
                  <b-button v-if="allowLogos" class="submit-button4" v-on:click="openDialog2()" style="margin: auto 15px;    grid-column: 2/3;" variant="blue">{{$t('member.set_logo')}}</b-button>
                  <b-button v-if="allowLogos" class="submit-button4" v-on:click="clearLogo()" style="margin: auto 15px;    grid-column: 3/4;" variant="blue">{{$t('member.clear_logo')}}</b-button>
                  <b-form-file v-if="allowLogos" id='fileid2' accept="image/jpeg, image/png, image/gif" @change="setImage2" plain hidden></b-form-file>
                </div>
                <b-modal centered id="slide-modal" v-model="showPhotoEdit" :title="$t('member.set_photo')">
                  <div style="text-align:center;color: rgb(0, 174, 239);">{{$t('member.upload_instructions')}}</div>
                  <div id="image-editor">
                    <div id="rotate-options">
                      <b-button v-if="imgSrc && imgSrc.length>0" v-on:click="rotateImageRight" class="image-button" variant="clear-blue"><div class="rotate-left-button"></div></b-button>
                      <b-button v-if="imgSrc && imgSrc.length>0" v-on:click="rotateImageLeft" class="image-button" variant="clear-blue"><div class="rotate-right-button"></div></b-button>
                      <b-button v-if="imgSrc && imgSrc.length>0" v-on:click="flipImageX()"  class="image-button" variant="clear-blue"><div class="flip-y-button"></div></b-button>
                      <b-button v-if="imgSrc && imgSrc.length>0" v-on:click="flipImageY()" class="image-button" variant="clear-blue"><div class="flip-x-button"></div></b-button>
                    </div>
                    <div id="cropper-wrapper">
                      <vue-cropper v-show="imgSrc && imgSrc.length>0" ref='cropper'
                                   :guides="true"
                                   :view-mode="2"
                                   drag-mode="crop"
                                   :auto-crop-area="1"
                                   :min-container-width="250"
                                   :min-container-height="180"
                                   :background="true"
                                   :rotatable="true"
                                   :src="imgSrc"
                                   :aspectRatio="isCorporateConnections? 1 : 0.6157"
                                   :cropmove="cropMove"
                                   alt="Photo"
                                   :img-style="{ 'width': '400px', 'height': '300px' }">
                      </vue-cropper>
                    </div>
                    <div v-if="isCorporateConnections" class="circular-member" :style="{'background-image': 'url('+tempPhoto+')', width: '300px', height: '300px'}"></div>
                    <img v-if="!isCorporateConnections" v-show="tempPhoto" id="preview-image" :src="tempPhoto" />
                    <div id="zoom-text">{{$t('member.zoom_text')}}</div>
                    <b-button class="submit-button4" v-on:click="openDialog" variant="blue" style="grid-column:2/3;margin: auto;">{{$t('member.upload_photo')}}</b-button>
                  </div>
                  <div slot="modal-footer" class="submit-box-2">
                    <b-button class="submit-button4" v-on:click="showPhotoEdit=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
                    <b-button class="submit-button4" v-on:click="cropImage" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
                  </div>
                </b-modal>
              </div>
            </div>

          </div>
          <div id="in-box-2" v-if="official">
            <div v-if="!is_parent">
              <span class="form-box-title">{{$t('member.member_roles')}}</span>
              <div class="form-box" style="grid-template-columns: auto;height: auto;">
                <b-form-checkbox-group stacked v-model="member.roles" :options="roles" value-field="id" text-field="name">
                </b-form-checkbox-group>
              </div>
            </div>
            <div v-if="allow_logins"> 
              <span class="form-box-title">{{$t('member.app_permissions')}}</span>
              <div class="form-box" style="grid-template-columns: auto auto;">
                <b-form-checkbox style="color: black;grid-column:1/3;" v-model="member.access" value="1" unchecked-value="0" :disabled="leadership">
                  {{$t('member.access_permission')}}
                </b-form-checkbox>
                <span v-if="leadership" style="font-size: 12px;text-align:center;color:#555;margin-top:5px;grid-column:1/3;">{{$t('member.leadership_role')}}</span>
                <span v-if="permissions.admin==1" style="font-size: 12px;text-align:center;margin:auto;">{{member.has_pass? $t('member.pass_set') : $t('member.no_pass')}}</span>
                <b-button v-if="permissions.admin==1" class="submit-button4" v-on:click="showSetPass=true;" style="margin:auto;" variant="clear-blue"><div class="login-button"></div><span>{{$t('member.set_pass')}}</span></b-button>
                <b-button v-if="permissions.admin==1 && member.email.length>0" class="submit-button4" v-on:click="sendReset" style="margin:auto;    grid-column: 1/3;margin-top: 20px;" variant="clear-blue"><div class="email-button"></div><span>{{$t('member.send_reset')}}</span></b-button>
              </div>
            </div>
          </div>

          <div style=" grid-column-start: 1; grid-column-end: 3;">
            <b-alert variant="danger"
                     dismissible
                     :show="showRoleAlert"
                     @dismissed="showRoleAlert=false">
              {{alertText}}
            </b-alert>
          </div>
          <div class="submit-box-2" style="margin-top:30px;">
            <b-button v-if="member.id>0" class="submit-button4" v-on:click="showDelete=true;" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
            <b-button class="submit-button4" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button :disabled="!loaded" class="submit-button4" type="submit" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
          <b-modal id="modal-center" v-model="showDelete" centered :title="$t('member.delete_title')">
            <p class="my-4">{{$t('member.delete_text')}}</p>
            <div slot="modal-footer" class="submit-box-2">
              <b-button class="submit-button4" v-on:click="showDelete=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
              <b-button class="submit-button4" v-on:click="deleteMember" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
            </div>
          </b-modal>
          <b-modal id="modal-center" v-model="showLeadershipRemoved" centered>
            <div slot="modal-header">
            </div>
            <p class="my-4">{{$t('member.leadership_removed1')}}</p>
            <p class="my-4">{{$t('member.leadership_removed2')}}</p>
            <div slot="modal-footer" class="submit-box-2">
              <b-button class="submit-button4" v-on:click="showLeadershipRemoved=false;" variant="clear-blue"><span>{{$t('member.keep_access')}}</span></b-button>
              <b-button class="submit-button4" v-on:click="member.access= 0; showLeadershipRemoved=false;" variant="clear-blue"><span>{{$t('member.remove_access')}}</span></b-button>
            </div>
          </b-modal>
          <b-modal id="modal-center" v-model="showAccessGranted" centered>
            <div slot="modal-header">
            </div>
            <p class="my-4">{{is_parent? $t('member.contact_granted') : $t('member.access_granted')}}</p>
            <div slot="modal-footer" class="submit-box-2">
              <b-button class="submit-button4" v-on:click="showAccessGranted=false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
              <b-button class="submit-button4" v-on:click="sendAccessEmail=true;initAccess=true;showAccessGranted=false;updateMember();" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
            </div>
          </b-modal>
          <b-modal id="modal-center" :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true" v-model="showSetPass" centered :title="$t('member.set_pass_title')" >
            <div style="display:grid; grid-template-columns: auto auto;">
                <span class="form-box-name">{{$tc('member.password_text',(permissions.admin && member.has_pass==1)? 1 : 0)}}</span>
                <b-form-input v-model="member.password" type="password" :state="member.password.length==0? null : (member.password.length>=8)" class="form-box-input" name="whatever" autocomplete="new-password"></b-form-input>
                <span class="form-box-name" >{{$t('member.password_retype')}}</span>
                <b-form-input v-model="member.retype" :state="member.password.length==0? null : (member.password===member.retype && member.retype.length>0)" type="password" class="form-box-input" name="whatevera" autocomplete="new-password-again"></b-form-input>
                <span style="font-size: 12px;text-align:left;color:#aaa;grid-column: 2/3;">*{{$t('global.password_requirement')}}</span>
                <span style="font-size: 12px;text-align:left;color:#aaa;grid-column: 2/3;">*{{$t('member.set_pass_note')}}</span>
            </div>
            <b-alert class="fit-width" style="margin:auto;" variant="danger" dismissible :show="passAlert" @dismissed="passAlert=false">
              {{alertPassText}}
            </b-alert>
            <div slot="modal-footer" class="submit-box-2">
              <b-button class="submit-button4" v-on:click="member.password='';member.retype='';showSetPass=false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
              <b-button class="submit-button4" v-on:click="checkPasses" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
            </div>
          </b-modal>
        </div>
      </form>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
#top-div{
  display: grid;
    grid-template-columns: auto auto;
    width: 900px;
    margin: auto;
}
#contact-select{
  grid-column: 2/4;max-width: 400px;margin-bottom: 10px;
}
#member-photo {
    max-height: 100px;
    max-width: 200px;
    border: 1px gray solid;
  }

  #member-photo-box {
    display: grid;
    grid-template-columns: auto auto auto auto;
    margin: auto;
    width: fit-content;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    vertical-align: middle;
    grid-row-gap: 10px;
  }
  
  #in-box-2 {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 2;
    display: grid;
  }
  #big-box {
    display: grid;
    grid-template-columns: 65% 35%;
    width: 900px;
    margin: auto;
  }
</style>
<script>
    
  import router from "../router"
  import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
  import Multiselect from 'vue-multiselect';
    export default {
    name: "Members",
    components: {
      VueCropper,
      Multiselect
    },
     watch:{
        '$i18n.locale': {
          handler: function () {
            this.getContactTypes();
          },
          deep: true
        },
        'member.roles': {
          handler: function () {
            var initLeader = this.leadership;
            for(var i=0; i<this.member.roles.length;i++){
              for(var j=0; j<this.roles.length;j++){
                if(this.member.roles[i] == this.roles[j].id && this.roles[j].leadership == 1){
                  this.member.access = 1;
                  this.leadership = true;
                  return;
                }
              }
            }
            this.leadership = false;
            if(initLeader && !this.loading){
              this.showLeadershipRemoved = true;
            }
          },
          deep: true
        },
      },
    computed: {
      create_login: function () {
        return Math.min(this.member.access, 1);
      },
      char_count: function () {
        var n = this.member.ask.length;
        return (n < 120? n+"/120 "+this.$t('memberphoto.char_count_1') : this.$t('memberphoto.char_count_2'));
      },
      tag_count: function () {
        var n = this.member.tagline.length;
        return (n < this.tagline_chars? n+"/"+this.tagline_chars+" "+this.$t('memberphoto.char_count_1') : this.$t('memberphoto.char_count_2'));
      },
      yearOptions: function(){
        var now = new Date().getUTCFullYear();
        var years = [{value: 0, text: this.$t('member.year_since')}];
        for (var i=0; i<100;i++){
          years.push({value:now-i, text: now-i});
        }
        return years;
      }
    },
      data() {
        return {
          contactMethods: [{value:0, text: this.$t("member.sms_mobile")},{value:1, text: this.$t("member.email_link")},{value:2, text: this.$t("member.sms_email")}],
          loaded: false,
          allow_logins: false,
          allMembers: [],
          is_parent: false,
          official: false,
          org_id: 0,
          passAlert: false,
          alertPassText: false,
          sendAccessEmail : false,
          showLeadershipRemoved: false,
          showAccessGranted: false,
          initAccess: false,
          preferred_countries: ['CA', 'US'],
          showDelete: false,
          saveVariant : 'blue',
          roles: [],
          member: { id: 0, access: 0, roles: [], city: "", presence: 0, job_title: "", linkedin_url: "", sponsoring_member_id: 0, preferred_contact: 0,
            run_permission: 0, has_pass: 0, category: "", company: "", email: "", photo: null, photo_small: null, retype:"", website: "",
            disabled: 0, password: "", name: "", sub_role: "", role_description: "", phone: "", mobile_phone: "", logo: null, contact_type: "", contact_types: [],ask: "",tagline:"", introduction:""
          },
          showRoleAlert: false,
          alertText: "",
          tempPhoto: "",
          imgSrc: '',
          showPhotoEdit: false,
          typeOptions: [],
          leadership: false,
          showSetPass: false,
          use_taglines: false,
          tagline_chars: 65,
          multipleMembers: false,
          allowPhotos: false,
          allowLogos: false,
          presence_types: [],
          presenceTypeOptions: [{value: 1, text: this.$t('member.global_text')},{value: 2, text: this.$t('member.national_text')},{value: 4, text: this.$t('member.regional_text')},],
          monthOptions: [{value: 0, text: this.$t('member.month_since')},{value: 1, text: this.$t('global.january')},{value: 2, text: this.$t('global.february')},{value: 3, text: this.$t('global.march')},
          {value: 4, text: this.$t('global.april')},{value: 5, text: this.$t('global.may')},{value: 6, text: this.$t('global.june')},
          {value: 7, text: this.$t('global.july')},{value: 8, text: this.$t('global.august')},{value: 9, text: this.$t('global.september')},
          {value: 10, text: this.$t('global.october')},{value: 11, text: this.$t('global.november')},{value: 12, text: this.$t('global.december')}],
        }
      },
    methods: {
      nextMember(){
        this.$http.post("/api/member/next/get", {filterRoleId: this.permissions.filterRoleId, current_id : this.member.id}).then((response) => {
         if(response.data.success){
           this.member.id = response.data.id;
           this.getMember();
         }
        }).catch(() => {});
      },
      previousMember(){
        this.$http.post("/api/member/previous/get", {filterRoleId: this.permissions.filterRoleId, current_id : this.member.id}).then((response) => {
         if(response.data.success){
           this.member.id = response.data.id;
           this.getMember();
         }
        }).catch(() => {});
      },
      getFilterRoleNumber(){
        this.$http.post("/api/member/role/number/get", {filterRoleId: this.permissions.filterRoleId}).then((response) => {
         if(response.data.success){
           this.multipleMembers = response.data.number>1;
         }
        }).catch(() => {});
      },
      sendReset(){
        this.$http.post("/api/password/reset/byid", {id: this.member.id}).then((response) => {
         if(response.data.success){
           this.$bvToast.toast(this.$t('member.reset_sent')+" "+this.member.email, {
              variant: 'success',
              solid: true
            });
         }
        }).catch(() => {
        });
      },
      checkPasses(){
        this.passAlert = false;
        if(this.member.password.length<8){
          this.alertPassText = this.$t("reset.alert_length");
          this.passAlert = true;
          return;
        }
        if(this.member.password != this.member.retype){
          this.alertPassText = this.$t("reset.alert_match");
          this.passAlert = true;
          return;
        }
        this.showSetPass = false;
      },
      getContactTypes(){
        this.$http.post("/api/contact/types/get", {}).then((response) => {
          this.typeOptions = response.data.types;
          this.getMember();
        }).catch(() => {
        });
      },
      clearLogo(){
        this.member.logo = null;
      },
      clearPhoto(){
        this.imgSrc = '';
        this.member.photo = null;
        this.member.photo_small = null;
        this.tempPhoto = '';
      },
      openPhotoEdit(){
        this.showPhotoEdit=true
        if(this.imgSrc && this.imgSrc.length>0){
          this.cropMove();
        }
      },
      cropMove() {
        this.tempPhoto = this.$refs.cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.8);
      },
      setImage(e) {
        const file = e.target.files[0];
        if (!file.type.includes('image/')) {
          alert('Please select an image file');
          return;
        }
        if (typeof FileReader === 'function') {
           var self = this;
          this.resizeImage(file,665,1080, function(dataurl){
            self.imgSrc = dataurl;
            self.$refs.cropper.replace(dataurl);
            setTimeout(function () {
              self.tempPhoto = self.$refs.cropper.getCroppedCanvas().toDataURL("image/jpeg", 0.8);
            }, 200);
          });
        } else {
          alert('Sorry, FileReader API not supported');
        }
      },
      setImage2(e) {
        const file = e.target.files[0];
        if (!file.type.includes('image/')) {
          alert('Please select an image file');
          return;
        }
        if (typeof FileReader === 'function') {
          var self = this;
          this.resizeImage(file,1920,1080, function(dataurl){
            self.member.logo = dataurl;
          });
        } else {
          alert('Sorry, FileReader API not supported');
        }
      },
      resizeImage(file, scale_width,scale_height, onEnd) {
          var reader = new FileReader();
          var self = this;
          reader.onload = function(e) {
              self.resizeDataUrl(e.target.result,'image/jpeg', scale_width, scale_height, onEnd);
          }
          reader.readAsDataURL(file);
    },
    resizeDataUrl(filedata,imagetype, scale_width, scale_height, onEnd){
      var img = document.createElement("img");
      img.src = filedata;
      img.onload = function(){
          var width = img.width;
          var height = img.height;
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");
          
          ctx.drawImage(img, 0, 0);
          var MAX_WIDTH = scale_width;
          var MAX_HEIGHT = scale_height;
          if (width > height) {
              if (width > MAX_WIDTH) {
                  height *= MAX_WIDTH / width;
                  width = MAX_WIDTH;
              }
          } else {
              if (height > MAX_HEIGHT) {
                  width *= MAX_HEIGHT / height;
                  height = MAX_HEIGHT;
              }
          }
          canvas.width = width;
          canvas.height = height;
          if(imagetype.includes("j")){
            ctx.fillStyle = '#fff'; 
            ctx.fillRect(0, 0, canvas.width, canvas.height);
          }
          ctx.drawImage(img, 0, 0, width, height);
          var dataurl = canvas.toDataURL(imagetype, 0.8);
          onEnd(dataurl);
      }
    },
      cropImage() {
        if(this.$refs.cropper.getCroppedCanvas()){
          this.member.photo = this.$refs.cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.8);
          var self = this;
          this.resizeDataUrl(this.member.photo,'image/jpeg',132,216, function(dataurl){
              self.member.photo_small = dataurl;
          });
        }else{
          this.member.photo = null;
          this.member.photo_small = null;
        }
       
        this.showPhotoEdit = false;
      },
          getMember() {
            this.loaded = false;
            this.loading = true;
            var self = this;
            if (this.member.id > 0) {
              this.$http.post("/api/member/get", this.member).then((response) => {
                if (response.data.success) {
                  this.initAccess = response.data.member.access;
                  response.data.member.contact_types = [];
                  response.data.member.since_year = 0;
                    response.data.member.since_month = 0;
                    this.presence_types = [];
                    for(var i=0; i<this.presenceTypeOptions.length;i++){
                      if((this.presenceTypeOptions[i].value & response.data.member.presence) !=0){
                        this.presence_types.push(this.presenceTypeOptions[i]);
                      }
                    }
                  if(response.data.member.member_since && response.data.member.member_since.length>1){
                    var ls = response.data.member.member_since.split("-");
                    response.data.member.since_year = ls[0]==""? 0 : parseInt(ls[0]);
                    response.data.member.since_month =  ls.length<2 || ls[1]==""? 0 : parseInt(ls[1]);
                  }
                  if(response.data.member.contact_type && response.data.member.contact_type.length>0){
                    var ids = response.data.member.contact_type.split(",");
                    for(var i=0; i<this.typeOptions.length;i++){
                      if(ids.indexOf(this.typeOptions[i].id+"")>=0){
                        response.data.member.contact_types.push(this.typeOptions[i]);
                      }
                    }
                  }else{
                    response.data.member.contact_type = "";
                  }

                  this.member = response.data.member;
                  this.$set(this.member, 'password', "");
                  this.$set(this.member, 'retype', "");
                  this.getMemberMedia();
                  this.$nextTick(function(){
                    self.loading = false;
                  });
                }
              }).catch((errors) => {
                console.log(errors);
                this.$nextTick(function(){
                    self.loading = false;
                  });});
            }else{
              this.loaded = true;
            }
          },
          getMemberMedia(){
            if (this.member.id > 0) {
              this.$http.post("/api/member/get/media", {id: this.member.id}).then((response) => {
                if (response.data.success) {
                  this.$set(this.member, 'photo', response.data.photo);
                  this.$set(this.member, 'photo_small', response.data.photo_small);
                  this.$set(this.member, 'logo', response.data.logo);
                }
                this.loaded = true;
              }).catch(() => {this.loaded = true;});
            }else{
              this.loaded = true;
            }
          },
          getRoles() {
            this.$http.post("/api/organization/roles", {}).then((response) => {
              if (response.data.success) {
                this.roles = response.data.roles;
              }
            }).catch(() => {});
          },
          getAllowPhotos() {
            this.$http.post("/api/organization/get/allowphotos", {}).then((response) => {
              if (response.data.success) {
                this.allowPhotos = response.data.allowPhotos;
                this.allowLogos = response.data.allowLogos;
              }
            }).catch(() => {});
          },
          checkAndUpdateMember(){
            if(this.member.password.length>0 || this.member.access == 1 || this.member.has_pass==1){
              this.checkEmailAccount(this.updateMember);
            }else{
              this.updateMember();
            }
          },
          checkEmailAccount(onSuccess){
            this.$http.post("/api/member/check/account", this.member).then((response) => {
              if (response.data.success && response.data.account) {
                this.showRoleAlert = true;
                this.alertText = this.$t('member.account_error') +": " +response.data.org+" > "+response.data.name;
                this.member.access = 0;
                this.member.password = "";
                this.member.retype = "";
              }else{
                onSuccess();
              }
            }).catch(() => {
             
            });
          },
          updateMember(callback) {
            this.loaded = false;
            this.showRoleAlert = false;

            if(this.member.password.length>0){
              if(this.member.password != this.member.retype){
                this.showRoleAlert = true;
                this.alertText = this.$t('member.password_mismatch');
                this.loaded = true;
                return;
              }
              else if(this.member.email == ""){
                this.showRoleAlert = true;
                this.alertText = this.$t('member.email_required');
                this.loaded = true;
                return;
              }
            }

            if(this.official){
              this.member.access==0;
            }

            if(this.member.access==1){
              if(this.member.email == ""){
                this.showRoleAlert = true;
                this.alertText = this.$t('member.email_required');
                this.loaded = true;
                return;
              }
            }
            if(this.member.contact_types && this.member.contact_types.length>0){
              this.member.contact_type = this.member.contact_types.map(v => v? v.id : "").join(",");
            }else{
              this.member.contact_type = "";
            }
            

            if(!this.initAccess && this.member.access == 1 && !this.member.has_pass){
              this.showAccessGranted = true;
              this.loaded = true;
              return;
            }else if(!this.initAccess && this.member.access == 1){
              this.sendAccessEmail = true;
            }
            this.member.presence = 0;
            for(var i=0; i<this.presence_types.length;i++){
              this.member.presence+= this.presence_types[i].value;
            }

            this.member.tagline = this.member.tagline.substr(0,this.tagline_chars);
            this.member.member_since = this.member.since_year+"-"+this.member.since_month;
            this.member.org_id = this.org_id;
            this.$http.post("/api/member/update", this.member).then((response) => {
              if (response.data.error) {
                if (response.data.error.errno == 1) {
                  var role_conflicts = response.data.error.role_conflicts;
                  this.showRoleAlert = true;
                  this.alertText = this.$t('member.role_error') + ": ";
                  for (var i = 0; i < role_conflicts.length; i++) {
                    this.alertText += role_conflicts[i].name + ", ";
                  }
                  this.alertText = this.alertText.slice(0, this.alertText.length - 2);
                } else if(response.data.error.errno == 1062){
                  this.showRoleAlert = true;
                  this.alertText = this.$t('member.email_error') +": " +response.data.error.conflict.org+" > "+response.data.error.conflict.name;
                }
                this.saveVariant = 'danger';
                this.loaded = true;
              }
              else if (response.data.success === true) {
                this.saveVariant = 'success';
                if(!(this.member.id>0)){
                  this.member.id = response.data.member_id;
                }
                if(this.sendAccessEmail){
                  this.$http.post("/api/member/access/granted", {id: this.member.id}).then((response) => {
                    if (response.data.success === true) {
                      if(callback){
                        callback();
                      }else{
                        this.back();
                      }
                      
                    }
                  }).catch(() => {this.loaded = true;});
                }else{
                  if(callback){
                    callback();
                  }else{
                    this.back();
                  }
                }
              } else {
                this.saveVariant = 'danger';
                this.loaded = true;
              }
            }).catch((errors) => {
              console.log(errors);
              this.loaded = true;
              this.$bvToast.show('serverError');
              });
          },
          deleteMember() {
            this.$http.post("/api/member/delete", this.member).then((response) => {
              if (response.data.success === true) {
                this.back();
              }
            }).catch(() => {});
          },
          getUseTaglines(){
            this.$http.post("/api/region/get/usetaglines", {}).then((response) => {
              if (response.data.success === true) {
                this.use_taglines = response.data.use_taglines;
                this.tagline_chars = response.data.tagline_chars;
              }
            }).catch(() => {});
          },
          getAllMembers(){
            this.$http.post("/api/members/all/select", {}).then((response) => {
              if (response.data.success === true) {
                this.allMembers = response.data.members;
                this.allMembers.unshift({id: 0, name: this.$t('member.select_member_contact')});
              }
            }).catch(() => {});
          },
          onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length || !this.checkFileSize(files[0]))
              return;
            this.createImage(files[0]);
          },
          createImage(file) {
            var reader = new FileReader();

            reader.onload = (e) => {
              this.member.photo = e.target.result;
            };
            reader.readAsDataURL(file);
          },
          back() {
            window.history.length > 1? router.go(-1) : router.push("/admin/members");
          },
          openDialog() {
            document.getElementById('fileid').click();
      },
      openDialog2() {
        document.getElementById('fileid2').click();
      },
      requestPhotoByEmail() {
        this.$http.post("/api/member/photo/request/email", this.member).then((response) => {
          if (response.data.success === true) {
            this.$bvToast.toast(this.$t('memberphoto.link_sent')+" "+this.member.email, {
              title: this.$t('memberphoto.link_sent_title'),
              variant: 'success',
              solid: true
            });
          }else{
            this.$bvToast.toast(response.data.error, {
              title: this.$t('memberphoto.link_not_sent'),
              variant: 'warning',
              solid: true
            });
          }
        }).catch(() => { });
      },
      rotateImageLeft(){
        this.$refs.cropper.rotate(90);
        this.cropMove();
      },
      rotateImageRight(){
        this.$refs.cropper.rotate(-90);
        this.cropMove();
      },
      flipImageX(){
        var d = this.$refs.cropper.getData()
        this.$refs.cropper.scaleX(d.scaleX*-1);
        this.cropMove();
      },
      flipImageY(){
        var d = this.$refs.cropper.getData()
        this.$refs.cropper.scaleY(d.scaleY*-1);
        this.cropMove();
      },
      getOrgInfo(){
        this.org_id = this.$route.params.org? this.$route.params.org : 0;
        this.$http.post("/api/member/orginfo", {id: this.org_id}).then((response) => {
          if (response.data.success === true) {
            this.is_parent = response.data.is_parent;
            this.allow_logins = response.data.allow_logins;
            this.official = response.data.official;
          }else{
            this.is_parent = false;
            this.allow_logins = false;
            this.official = false;
          }
        }).catch(() => { });
      }
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-members"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-members"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      if(this.isCorporateConnections){
        this.getAllMembers();
      }
      this.getOrgInfo();
      this.getUseTaglines();
      this.getRoles();
      this.getFilterRoleNumber();
      this.member.id = this.$route.params.id;
      this.getContactTypes();
      this.getAllowPhotos();
    }
    }
</script>
