<template>
  <div>
    <div class="rel-grid-container" style="max-width:100%;">
      <div id="top-div"></div>
      <Tip :tipNumber="17"></Tip>
      <div v-if="needsDate" style="text-align: center;font-size: 20px;">{{$t('global.need_date')}}</div>
      <!--<div v-if="recognition.length>0" class="form-box fit-width" style="margin:10px auto;grid-template-columns:auto;grid-gap:20px;">
         <div v-for="r in recognition" :key="r.id" style="display: grid; grid-template-columns: auto 400px;">
             <span class="form-box-name">{{r.name}}</span>
             <multiselect :noOptions="$t('global.empty_list')" style="min-width:400px;" :class="{blueselect:true}"  :selectedLabel="$t('global.selected_text')" selectLabel="" deselectLabel="" v-model="r.members" :options="members" :multiple="true" :close-on-select="false"  :preserve-search="true" :placeholder="$t('global.select_members')" label="name" track-by="id" :preselect-first="false">
             
            </multiselect>
         </div>
      </div>-->
      <div id="recog-box"  class="fit-content" v-if="!needsDate">
        <div>
          <span class="form-box-title">{{$t('role.network_leaders')}}</span>
          <div class="form-box fit-width" style="margin:10px auto;grid-template-columns:auto;grid-gap:20px;">
            <div  style="display: grid; grid-template-columns: auto 400px;">
                <span class="form-box-name">{{$t('role.add_member')}}</span>
                 <multiselect :noOptions="$t('global.empty_list')" style="min-width:400px;" :class="{blueselect:true}"  :selectedLabel="$t('global.selected_text')" selectLabel="" deselectLabel="" v-model="network_leaders" :options="members" :multiple="true" :close-on-select="false"  :preserve-search="true" :placeholder="$t('global.select_members')" label="name" track-by="id" :preselect-first="false">
                </multiselect>
                 <span class="form-box-name" style="grid-column:1/3;text-align:center;">{{$t('role.selected_members')}}</span>
                 <table class="table b-table  table-sm table-hover-new " style="grid-column:1/3;">
                  <thead>
                    <tr>
                      <th >{{$t('role.name_header')}}</th>
                      <th>{{$t('role.note_header')}}</th>
                      <th style="text-align: center;" >{{$t('role.remove_header')}}</th>
                    </tr>
                  </thead>
                  <draggable v-if="permissions.access" tag="tbody" style="grid-column:1/3;" v-model="network_leaders">
                      <tr v-for="m in network_leaders" :key="m.id">
                        <td style="vertical-align: middle;">{{m.name}}</td>
                        <td><b-form-input v-model="m.note" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" maxlength="255"></b-form-input></td>
                        <td style="vertical-align: middle;"><span class="trash-recog" variant="blue" v-on:click="deleteLeader(m)"></span></td>
                      </tr>
                  </draggable>
                  </table>
            </div>
          </div>
        </div>
        <div>
          <span class="form-box-title">{{$t('role.renewing_members')}}</span>
          <div class="form-box fit-width" style="margin:10px auto;grid-template-columns:auto;grid-gap:20px;">
            <div  style="display: grid; grid-template-columns: auto 400px;">
                <span class="form-box-name">{{$t('role.add_member')}}</span>
                <multiselect :noOptions="$t('global.empty_list')" style="min-width:400px;" :class="{blueselect:true}"  :selectedLabel="$t('global.selected_text')" selectLabel="" deselectLabel="" v-model="renewing_members" :options="members" :multiple="true" :close-on-select="false"  :preserve-search="true" :placeholder="$t('global.select_members')" label="name" track-by="id" :preselect-first="false">
                </multiselect>
                 <span class="form-box-name" style="grid-column:1/3;text-align:center;">{{$t('role.selected_members')}}</span>
                  <table class="table b-table  table-sm table-hover-new " style="grid-column:1/3;">
                  <thead>
                    <tr>
                      <th >{{$t('role.name_header')}}</th>
                      <th v-if="allow_renew_date==1">{{$t('role.renewal_date')}}</th>
                      <th style="text-align: center;" >{{$t('role.remove_header')}}</th>
                    </tr>
                  </thead>
                  <draggable v-if="permissions.access" tag="tbody" style="grid-column:1/3;" v-model="renewing_members">
                      <tr v-for="m in renewing_members" :key="m.id">
                        <td style="vertical-align:middle;">{{m.name}}</td>
                        <td v-if="allow_renew_date==1"><b-form-select style="max-width:120px;"  v-model="m.renew_date" :options="monthOptions" /></td>
                        <td style="vertical-align: middle;"><span class="trash-recog" variant="blue" v-on:click="deleteRenewing(m)"></span></td>
                      </tr>
                  </draggable>
                  </table>
            </div>
          </div>
        </div>
      </div>
      <div v-if="allow_top_performing==1 && !needsDate" >
        <span class="form-box-title">{{$t('role.top_performing')}}</span>
        <div id="top-performer-box" class="form-box fit-width">
          <div id="period-box">
            <div style="display:grid; grid-template-columns: auto auto; margin: auto;margin-top:20px;" class="fit-width">
              <span  class="form-box-name">{{$t('role.period_text')}}</span>
              <b-form-select style="min-width:200px;"  v-model="top_performing_period" :options="tracking_nums"  />
            </div>
          </div>
          <div :id="'top-performer'+i" v-for=" i in [1,2,3,4,5,6]" :key="i" >
            <span class="form-box-title">{{$t('role.top_performer'+i)}}</span>
            <div style="padding: 10px;width: 100%;margin: auto;padding-top: 25px;">
                <multiselect :noOptions="$t('global.empty_list')" style="min-width:200px;" :class="{blueselect:true}"  :selectedLabel="$t('global.selected_text')" selectLabel="" deselectLabel="" v-model="top_performers[i]" :options="recog_members[i]" :multiple="true" :close-on-select="false"  :preserve-search="true" :placeholder="$t('global.select_members')" label="name" track-by="id" :preselect-first="false">
                </multiselect>
                  <span class="form-box-name" style="grid-column:1/3;text-align:center;">{{$t('role.selected_members')}}</span>
                  <table class="table b-table  table-sm table-hover-new " style="grid-column:1/3;">
                  <thead>
                    <tr>
                      <th >{{$t('role.name_header')}}</th>
                      <th >{{$t('role.amount_header')}}</th>
                    </tr>
                  </thead>
                  <draggable v-if="permissions.access" tag="tbody" style="grid-column:1/3;" v-model="top_performers[i]">
                      <tr v-for="m in top_performers[i]" :key="m.id">
                        <td style="vertical-align:middle;font-size:14px;max-width:100px;" class="curtail-text">{{m.name}}</td>
                        <td ><b-form-input v-model="m.amount" style="width: 120px;margin: auto;" type="number" pattern="[^<>]*" :title="$t('global.pattern_title')" maxlength="255"/></td>
                      </tr>
                  </draggable>
                  </table>
            </div>
          </div>
        </div>
      </div>
      <div class="submit-box-2" style="margin-top:50px;" v-if="!needsDate">
        <b-button class="submit-button4" v-on:click="saveRecognition" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
      </div>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
#recog-box{
  display: grid; grid-template-columns: 1fr 1fr; grid-gap: 30px;margin:auto;
}
#top-performer-box{
  margin: 10px auto;grid-template-columns: repeat(6, 280px);text-align: center;padding: 0;row-gap:10px;
}
 .trash-recog {
      width: 30px;
    height: 30px;
    background-image: url("../assets/button-Close.png");
    border: none;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: block;
    margin: auto;
    text-align: center;
    cursor: pointer;
  }
  #top-performer1,#top-performer2,#top-performer3,#top-performer4,#top-performer5{
    border-right: 1px solid #999;
  }
  #period-box{
    grid-column:1/7;
  }
  @media (max-width: 1700px) {
   #recog-box  {
        grid-template-columns: 1fr;
    }
    #top-performer-box{
      grid-template-columns: repeat(3, 270px);
    }
    #top-performer3{
      border: none;
    }
    #period-box{
      grid-column:1/4;
    }
  }
  @media (max-width: 1150px) {
   #recog-box  {
        grid-template-columns: 1fr;
    }
    #top-performer-box{
      grid-template-columns: repeat(2, 270px);
    }
    #top-performer2,#top-performer4{
      border: none;
    }
    #period-box{
    grid-column:1/3;
  }
  }
</style>
<script>
import router from "../router"
import draggable from "vuedraggable";
import Multiselect from 'vue-multiselect';
    export default {
      name: "Recognition",
      components: {  draggable ,Multiselect},
      data() {
        return {
          needsDate: false,
          top_performing_period: 0,
          recognition: [],
          members: [],
          recog_members: {1: [], 2: [], 3: [], 4: [], 5: [], 6: []},
          renewing_members: [],
          network_leaders: [],
          top_performers: {1: [], 2: [], 3: [], 4: [], 5: [], 6: []},
          monthOptions: [{value: 0, text: ''},{value: 1, text: this.$t('global.january')},{value: 2, text: this.$t('global.february')},{value: 3, text: this.$t('global.march')},
          {value: 4, text: this.$t('global.april')},{value: 5, text: this.$t('global.may')},{value: 6, text: this.$t('global.june')},
          {value: 7, text: this.$t('global.july')},{value: 8, text: this.$t('global.august')},{value: 9, text: this.$t('global.september')},
          {value: 10, text: this.$t('global.october')},{value: 11, text: this.$t('global.november')},{value: 12, text: this.$t('global.december')}],
          allow_renew_date: 0,
          allow_top_performing: 0,
          trackingOptions: [
          { value: 1, text: this.$t('general.tracking_option_1') },
          { value: 2, text: this.$t('general.tracking_option_2') },
          { value: 4, text: this.$t('general.tracking_option_4') },
          { value: 8, text: this.$t('general.tracking_option_8') },
          { value: 16, text: this.$t('general.tracking_option_16') },
          { value: 32, text: this.$t('general.tracking_option_32') },
          { value: 64, text: this.$t('general.tracking_option_64') },],
          tracking_nums: []
        }
      },

      watch: {
      '$i18n.locale': {
        handler: function () {
          this.getRecognition();
        }
      }
    },
    /*computed: {
        dayOptions: function () {
          var temp = []
          for(var i = 1;i<=31;i++){
            //if((this.regional.month==2 && i==29) || ([4,6,9,11].indexOf(this.regional.month) !== -1 && i==31)){
            //  break;
            //}
            temp.push({value: i, text: i+""});
          }
          return temp;
        }
      },*/
    methods: {
      /*dayOptions: function (m) {
          var temp = []
          for(var i = 1;i<=31;i++){
            if((m.renew_month==2 && i==29) || ([4,6,9,11].indexOf(m.renew_month) !== -1 && i==31)){
              break;
            }
            temp.push({value: i, text: i+""});
          }
          this.$set(m, "renew_day", Math.min(m.renew_day, i-1));
          return temp;
        },*/
      addRenewing(index){
        console.log(index);
      },
      deleteLeader(m){
        var index = this.network_leaders.indexOf(m);
        if (index > -1) {
          this.network_leaders.splice(index, 1);
        }
      },
      deleteRenewing(m){
        var index = this.renewing_members.indexOf(m);
            if (index > -1) {
              this.renewing_members.splice(index, 1);
            }
      },
      getRecognition() {
        this.$http.post("/api/organization/recognition", {}).then((response) => {
          if (response.data.success) {
            this.top_performing_period = response.data.top_performing_period;
            this.network_leaders = response.data.network_leaders;
            this.renewing_members = response.data.renewing_members;
            this.top_performers = {1: [], 2: [], 3: [], 4: [], 5: [], 6: []};
            for(var i=0; i<response.data.top_performing.length; i++){
              var h = response.data.top_performing[i];
              if(this.top_performers[h.type] != undefined || this.top_performers[h.type] != null){
                this.top_performers[h.type].push(h);
              }
            }
          }else{
            this.needsDate = true;
          }
        }).catch(() => {
        });
      },
      getMembers() {
        this.$http.post("/api/organization/members/email/select/joint", {}).then((response) => {
          this.recog_members={1: [], 2: [], 3: [], 4: []};
          for(var i=0; i<response.data.members.length;i++){
            response.data.members[i].note = "";
            response.data.members[i].renew_date = 0;
            response.data.members[i].amount = 0;
          }
          this.recog_members[1] = JSON.parse(JSON.stringify(response.data.members));
          this.recog_members[2] = JSON.parse(JSON.stringify(response.data.members));
          this.recog_members[3] = JSON.parse(JSON.stringify(response.data.members));
          this.recog_members[4] = JSON.parse(JSON.stringify(response.data.members));
          this.recog_members[5] = JSON.parse(JSON.stringify(response.data.members));
          this.recog_members[6] = JSON.parse(JSON.stringify(response.data.members));
          this.members = response.data.members;
          
          
        }).catch(() => {});
      },
      getAllowRenewDate() {
        this.$http.post("/api/region/get/allowedrecog", {}).then((response) => {
          this.allow_renew_date = response.data.allow_renew_date;
          this.allow_top_performing = response.data.allow_top_performing;
        }).catch(() => {});
      },
      saveRecognition() {
        var top_performing = [];
        for(var i=1; i<7; i++){
          for(var j=0; j<this.top_performers[i].length;j++){
            var m = JSON.parse(JSON.stringify(this.top_performers[i][j]));
            m.type = i;
            if(m.amount == undefined || m.amount == null){
              m.amount = 0;
            }
            top_performing.push(m);
            /*this.top_performers[i][j].type = i;
            if(this.top_performers[i][j].amount == undefined || this.top_performers[i][j].amount == null){
              this.top_performers[i][j].amount = 0;
            }*/
          }
          //Array.prototype.push.apply(top_performing,this.top_performers[i]);
        }
        this.$http.post("/api/organization/recognition/update", {top_performing_period: this.top_performing_period, network_leaders: this.network_leaders, renewing_members: this.renewing_members,top_performing : top_performing}).then((response) => {
          if (response.data.success) {
            this.back();
          }
        }).catch(() => {
        });
      },
      back(){
          router.push("/admin/dashboard");
      },
      getTrackingPeriods(){
        this.$http.post("/api/organization/get/tracking_periods", {}).then((response) => {
          if (response.data.success === true) {
           this.tracking_nums = [];
              for(var i=0; i<this.trackingOptions.length;i++){
                if((response.data.tracking_periods & Math.pow(2,i)) != 0){
                  this.tracking_nums.push(this.trackingOptions[i]);
                }
              }
              this.tracking_nums.unshift({ value: 0, text: this.$t('role.select_period') });
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-recognition"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-recognition"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      this.getAllowRenewDate();
      this.getRecognition();
      this.getMembers();
      this.getTrackingPeriods();
    }
    }
</script>
