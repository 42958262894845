<template>
<div v-if="tip.disabled==0 && this.permissions.organization.official" :style="{width: tip.width+'px', 'margin': 'auto'}">
    <div style="margin-top: 20px;text-align:left">
        <b-alert :show="true" style="border:1px solid #999;" variant="light" dismissible @dismissed="hideTip">
        <div style="display:grid; grid-template-columns: 30px auto;">
        <img style="height:30px;" src="../assets/icon-Tip.png" /><span style="    line-height: 40px;margin-left: 10px;">{{tip.title}}</span>
        <span class="tip-text pre-format" style="grid-column:1/3;font-size: 14px;" v-html="tip.content"></span>
        </div> 
        </b-alert>
    </div>
  </div>
</template>
<style scoped>
.tip-text{
    white-space: pre-wrap; /* Since CSS 2.1 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
}
</style>
<script>
    export default {
    name: "Tip",
     props: {tipNumber: Number},
     watch: {
    '$i18n.locale': { 
      handler: function () {
          this.getTipEnabled();
      },
      deep: true
    }
  },
  data() {
    return {
      tip: {width: 650, content: "", title: "", disabled: 1}
    };
  },
   mounted() {
     this.getTipEnabled();
  },
  destroyed(){
     this.permissions.help_url = "";
  },
  methods: {
     getTipEnabled() {
       this.$http.post("/api/organization/tips/get",{n:this.tipNumber}).then(response  => {
        if(response.data.success){
          this.tip = response.data.tip;
          this.permissions.help_url = this.tip.url;
          this.tip.disabled = (this.getCookie("tips",0) & (Math.pow(2,this.tipNumber-1)));
        }else{
          this.tip.disabled = 1;
        }
        
      }).catch((errors) => {console.log(errors)});
    },
    hideTip(){
      this.setCookie("tips", this.getCookie("tips",0) | (Math.pow(2,this.tipNumber-1)));
    }
  }
}
</script>