<template>
  <div>
    <div class="rel-grid-container">
      <div id="top-div"></div>
      <form v-on:submit.prevent="updateRegion" id="form-fix">
        <span class="form-box-title">{{$t('region.region_text')}}</span>
        <div id="region-box" class="form-box" style="grid-template-columns: repeat(3,1fr);">
            <span class="form-box-name">{{$t('region.name_text')}}</span><b-form-input :state="states.name" v-model="region.name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;" class="form-box-input" required></b-form-input>
            <span class="form-box-name">{{$t('region.email_text')}}</span><b-form-input v-model="region.email" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;" class="form-box-input" ></b-form-input>
            <span class="form-box-name">{{$t('region.company_text')}}</span><b-form-input v-model="region.company" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
            <span class="form-box-name">{{$t('general.address_text')}}</span><b-form-input v-model="region.address" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
            <span class="form-box-name">{{$t('general.city_text')}}</span><b-form-input v-model="region.city" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
            <span class="form-box-name">{{$t('general.postal_code')}}</span><b-form-input v-model="region.postal_code" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
            <span class="form-box-name">{{$t('region.country_text')}}</span><b-form-select :disabled="permissions.country>0" :state="states.country_id" v-model="region.country_id" value-field="id" text-field="name" :options="countries" class="form-box-input" />
            <span class="form-box-name" v-if="region.country!=''">{{$t('general.region_text')}}</span><region-select v-if="region.country!=''" class="custom-select form-box-input" v-model="region.region"  :country="region.country" :region="region.region" />
            <span class="form-box-name">{{$t('region.principal_city')}}</span><b-form-input  v-model="region.principal_city" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;" class="form-box-input" ></b-form-input>
            <span class="form-box-name">{{$t('region.subscription_text')}} </span>
            <b-form-select :disabled="permissions.country>0" style="margin: 5px 0px;" v-model="region.subscription_status" :options="statusOptions"/>
         </div>
         <span v-if="region.subscription_status == 0 || region.id==0" class="form-box-title">{{$t('region.signup_contacts')}}</span>
        <div v-if="region.subscription_status == 0 || region.id==0" id="region-box" class="form-box" style="grid-template-columns: repeat(3,1fr);">
          <b-table small :items="signup_contacts" :fields="fields" style="grid-column: 1/4;margin-top: 30px;">
              <template v-slot:cell(delete)="data">
                <b-button v-if="(!data.item.isParent && data.item.type==0) || permissions.organization.is_parent" class="trash-button" variant="blue" v-on:click="deleteSignupContact(data.item)"></b-button>
              </template>
            </b-table>
            <b-button style="grid-column: 1/4;margin:auto;" class="submit-button4" variant="clear-blue" v-on:click="showSignupContactModal=true;"><div class="new-button"></div><span>{{$t('region.new_signup')}}</span></b-button>
        </div>

         <span v-if="permissions.country==0 || region.subscription_status!=1" class="form-box-title">{{$t('region.admin_logins')}}</span>
        <div v-if="permissions.country==0 || region.subscription_status!=1" id="region-box" class="form-box" style="grid-template-columns: repeat(1,1fr);">
            <span class="form-box-name" style="text-align:left;">{{$t('region.chapter_meetings')}}</span>
            <div style="display:grid;grid-gap: 5px;">
              <b-form-input :state="admin.chapter.name.length>0? null : false" v-model="admin.chapter.name" :placeholder="this.$t('global.username_text')" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;"  required></b-form-input>
              <b-form-input :state="(admin.chapter.password.length==0 || admin.chapter.password.length>8)? null : false" v-model="admin.chapter.password" :placeholder="this.$t('global.password_text')" type="password" style="max-width: 300px;" ></b-form-input>
              <b-form-input v-if="admin.chapter.password && admin.chapter.password.length>0" :placeholder="this.$t('global.retype_password')" style="max-width: 300px;" :state="admin.chapter.password===admin.chapter.password_confirm && admin.chapter.password_confirm.length>0" v-model="admin.chapter.password_confirm" type="password"  ></b-form-input>
            </div>
            <b-form-checkbox v-model="admin.has_startup" value="1" unchecked-value="0" style="margin-top:30px;">
                {{$t('region.startup_meetings')}}<span v-if="admin.has_startup==1" style="margin-left:30px;font-size:12px;color:red;">*{{$t('region.startup_note')}}</span>
              </b-form-checkbox>
            <div v-if="admin.has_startup==1" style="display:grid;grid-gap: 5px;">
              <b-form-input :state="admin.startup.name && admin.startup.name.length>0? null : false" v-model="admin.startup.name" :placeholder="this.$t('global.username_text')" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;"  required></b-form-input>
              <b-form-input v-model="admin.startup.password" :state="admin.startup.password && (admin.startup.password.length==0 || admin.startup.password.length>8)? null : false" :placeholder="this.$t('global.password_text')" type="password" style="max-width: 300px;"  ></b-form-input>
              <b-form-input v-if="admin.startup.password && admin.startup.password.length>0" :placeholder="this.$t('global.retype_password')"  style="max-width: 300px;" :state="admin.startup.password===admin.startup.password_confirm && admin.startup.password_confirm.length>0" v-model="admin.startup.password_confirm" type="password" ></b-form-input>
            </div>
            <b-form-checkbox v-model="admin.has_rst" value="1" unchecked-value="0" style="margin-top:30px;">
                {{$t('region.rst_meetings')}}<span v-if="admin.has_rst==1" style="margin-left:30px;font-size:12px;color:red;">*{{$t('region.startup_note')}}</span>
              </b-form-checkbox>
            <div v-if="admin.has_rst==1" style="display:grid;grid-gap: 5px;">
              <b-form-input :state="admin.rst.nam && admin.rst.name.length>0? null : false" v-model="admin.rst.name" :placeholder="this.$t('global.username_text')" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;"  required></b-form-input>
              <b-form-input v-model="admin.rst.password" :state="admin.rst.password && (admin.rst.password.length==0 || admin.rst.password.length>8)? null : false" :placeholder="this.$t('global.password_text')" type="password" style="max-width: 300px;"  ></b-form-input>
              <b-form-input v-if="admin.rst.password && admin.rst.password.length>0" :placeholder="this.$t('global.retype_password')" style="max-width: 300px;" :state="admin.rst.password===admin.rst.password_confirm && admin.rst.password_confirm.length>0" v-model="admin.rst.password_confirm" type="password"  ></b-form-input>
            </div>
         </div>
        <!--<span v-if="region.id>0 && (permissions.country==0)" class="form-box-title">{{$t('region.share_template')}}</span>
        <div v-if="region.id>0 && (permissions.country==0)" class="form-box" style="grid-template-columns: max-content auto;">
          <span  class="form-box-name">{{$t('region.region_text')}}</span><b-form-select v-model="region.template_region" :options="officialRegions" :disabled="true" class="mb-3" value-field="id" text-field="name"/>
          <b-button class="submit-button4" variant="clear-blue" style="grid-column: 1/3;margin: auto;" v-on:click="showChangeTemplate=true;"><span>{{$t('global.button_change')}}</span></b-button>
         </div>-->
        <span v-if="region.id>0 && (permissions.country==0) " class="form-box-title">{{$t('region.copy_title')}}</span>
        <div v-if="region.id>0 && (permissions.country==0) " id="region-box" class="form-box" style="grid-template-columns: repeat(3,1fr);">
          <span  class="form-box-name">{{$t('region.copy_to')}}</span><b-form-select v-model="copy.copy_to" :options="regionOptions" class="mb-3" /><span  class="form-box-name" style="text-align:left;">{{$t('region.agenda_slides')}}</span>
            <span  class="form-box-name">{{$t('region.copy_text')}}</span><b-form-select v-model="copy.copy_from" value-field="id" text-field="name" :options="regions" class="mb-3 form-box-input" />
            <b-form-checkbox v-model="copy.copy_roles" class="form-box-input" value="1" unchecked-value="0" style="text-align:left;">
              {{$t('region.copy_roles')}}
            </b-form-checkbox>
            <b-form-checkbox v-model="copy.copy_anal" class="form-box-input" value="1" unchecked-value="0" style="text-align:left;">
              {{$t('region.copy_anal')}}
            </b-form-checkbox>
            <b-button v-b-modal.modal-center2 class="submit-button4" variant="clear-blue" style="grid-column: 1/4;margin: auto;"><div class="copy-button"></div><span>{{$t('global.button_copy')}}</span></b-button>
         </div>
        <div v-if="showAlert">
            <b-alert variant="danger" dismissible :show="showAlert" @dismissed="showAlert=false">
            <span v-html="alertText"></span>
            </b-alert>
        </div>
        <div class="submit-box-2">
          <b-button v-b-modal.modal-center v-if="region.id>0" class="submit-button4" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          <b-button class="submit-button4" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="submit-button4" type="submit" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
        </div>
        <b-modal id="modal-center" v-model="showDelete" centered :title="$t('region.delete_title')">
          <p class="my-4">{{$t('region.delete_text')}}</p>

          <div slot="modal-footer" class="submit-box-2">
            <b-button class="submit-button4" v-on:click="showDelete=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="submit-button4" v-on:click="deleteRegion" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          </div>
        </b-modal>
        <b-modal id="modal-center" v-model="showChangeTemplate" centered :title="$t('region.share_template')">
         <div>
          <div style="grid-template-columns: max-content auto;">
            <span  class="form-box-name">{{$t('region.region_text')}}</span><b-form-select v-model="template_region" :options="officialRegions.filter(v=>v.id != region.id)" class="mb-3" value-field="id" text-field="name"/>
            <b>
              {{$t('region.template_change')}}
            </b>
          </div>
         </div>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="submit-button4" v-on:click="showChangeTemplate=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="submit-button4" v-on:click="changeTemplateRegion" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
        </b-modal>
        <b-modal id="modal-center2" v-model="showCopy" centered :title="$t('region.copy_title')">
          <p class="my-4">{{$t('region.copy_warning')}}</p>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="submit-button4" v-on:click="showCopy=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="submit-button4" v-on:click="copyRegion" variant="clear-blue"><div class="copy-button"></div><span>{{$t('global.button_copy')}}</span></b-button>
          </div>
        </b-modal>
         <b-modal  v-model="showSignupContactModal" centered :title="$t('region.copy_title')">
          <span class="form-box-title">{{$t('region.region_text')}}</span>
          <div id="region-box" class="form-box" style="grid-template-columns: repeat(3,1fr);">
              <span class="form-box-name">{{$t('region.name_text')}}</span><b-form-input v-model="new_contact.name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;" class="form-box-input"></b-form-input>
              <span class="form-box-name">{{$t('region.email_text')}}</span><b-form-input v-model="new_contact.email" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;" class="form-box-input"></b-form-input>
          </div>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="submit-button4" v-on:click="showSignupContactModal=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="submit-button4" v-on:click="newSignupContact(new_contact)" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
        </b-modal>
      </form>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style >
</style>
<script>
    
  import router from "@/router"
    export default {
    name: "Region",
    watch:{
      'region.country_id': {
        handler: function () {
           for(var i=0; i<this.countries.length; i++){
             if(this.countries[i].id == this.region.country_id){
               this.region.country = this.countries[i].alpha_2.toUpperCase();
               break;
             }
           }
        },
        deep: true
      },
      'copy.copy_to': {
        handler: function () {
           this.getRegions();
        },
        deep: true
      },
      },
      data() {
        return {
          showChangeTemplate: false,
          statusOptions: [{text: 'All', value: -1},{text: this.$t('subscription.imported_text'), value: 0},{text: this.$t('subscription.pilot_text'), value: 1},{text: this.$t('subscription.trial_text'), value: 2},{text: this.$t('subscription.active_text'), value: 3},{text: this.$t('subscription.inactive_text'), value: 4}],
          showSignupContactModal: false,
          signup_contacts: [],
          new_contact: {name : '', email: ''},
          officialRegions: [],
          regionOptions: [],
          showAlert: false,
          alertText: '',
          showDelete: false,
          countries: [],
          template_region: 0,
          region: {id: 0, name: '', country_id:0, copy_id: 0,event_link: '', official: 1,official_region: 0, address: '', city: '', region: '', country: '', company:'', principal_city: '', subscription_status: 0, postal_code: '', email: '', template_region: 0},
          states: {name: null, country_id: null, admin: null, email: null, pass:null,official_region: null},
          admin: {has_startup: 1, has_rst: 1, chapter: {org_id: 0,id: 0, name: '', password: '', has_pass: false}, startup: {org_id: 0,id: 0, name: '', password: '', has_pass: false}, rst: {org_id: 0,id: 0, name: '', password: '', has_pass: false}},
          regions: [],
          showCopy: false,
          copy: {copy_to: 0,copy_from: 0, copy_roles: 0, copy_anal: 0},
           fields: [
            {
              key: 'name',
              label: this.$t('region.name_text'),
            },
            { key: 'email',
              label: this.$t('region.email_text'),
            },
             { key: 'delete',
              label: this.$t('region.delete_header'),
              thStyle: {"text-align":"center" }
            },
          ],
        }
      },
        methods: {
          changeTemplateRegion(){
            if(this.region.id>0){
              this.$http.post("/api/superadmin/region/change_template", {id: this.region.id, template_region: this.template_region}).then((response) => {
                if (response.data.success == true) {
                 this.region.template_region = this.template_region;
                 this.showChangeTemplate = false;
                }
              }).catch(() => {
              });
            }
          },
          deleteSignupContact(a){
            if(this.region.id>0){
             this.$http.post("/api/superadmin/signupcontacts/delete", a).then((response) => {
              if (response.data.success == true) {
                var index = this.signup_contacts.indexOf(a);
                if (index > -1) {
                  this.signup_contacts.splice(index, 1);
                }
              }
            }).catch(() => {
            });
            }
          },
          newSignupContact(a){
            this.showSignupContactModal = false;
             if(this.region.id>0){
              this.$http.post("/api/superadmin/signupcontacts/new", {id: this.region.id, name: this.new_contact.name, email: this.new_contact.email}).then((response) => {
                if (response.data.success == true) {
                  this.new_contact = {name: '', email: ''};
                  a.id = response.data.id;
                  this.signup_contacts.push(a);
                }
              }).catch(() => {
              });
             }else{
               this.new_contact = {name: '', email: ''};
               this.signup_contacts.push(a);
             }
          },
            getRegions() {
                this.$http.post("/api/superadmin/regions/select/byid", {id: this.copy.copy_to}).then((response) => {
                if (response.data.success) {
                    this.regions = response.data.regions;
                }
                }).catch(() => {
                });
            },
          getCountries(){
              this.$http.post("/api/superadmin/countries", this.region).then((response) => {
                if (response.data.success) {
                  this.countries = response.data.countries;
                }
                this.getRegion();
              }).catch(() => {});
          },
          getRegion() {
            this.region.id = this.$route.params.id;
            if (this.region.id > 0) {
              this.$http.post("/api/superadmin/region/get", this.region).then((response) => {
                if (response.data.success) {
                  this.region = response.data.region;
                  this.admin = response.data.admin;
                  this.signup_contacts = response.data.signup_contacts;
                  this.regionOptions = [];
                  if(this.admin.chapter.org_id>0){
                    this.copy.copy_to = this.admin.chapter.org_id;
                    this.regionOptions.push({value: this.admin.chapter.org_id, text: this.$t('region.chapter_text')});
                  }
                  if(this.admin.startup.org_id>0){
                    this.regionOptions.push({value: this.admin.startup.org_id, text: this.$t('region.startup_text')});
                  }
                  if(this.admin.rst.org_id>0){
                    this.regionOptions.push({value: this.admin.rst.org_id, text: this.$t('region.rst_text')});
                  }
                  
                }
              }).catch(() => {});
            }else{
              this.getDefaultCountry();
            }
            
          },
          checkData(){
              this.showAlert = false;
              this.states ={
                  name: null, country_id: null, admin: null, email: null, pass:null
              };
              var allgood = true;
              if(this.region.name.length==0){
                  allgood = false;
                  this.states.name = false;
              }
             if(this.region.country_id==0){
                  allgood = false;
                  this.states.country_id = false;
              }
              if(this.admin.chapter.name.length==0){
                  allgood = false;
              }
              if(this.admin.has_startup==1 && this.admin.startup.name.length==0){
                  allgood = false;
              }
              if(this.admin.has_rst==1 && this.admin.rst.name.length==0){
                  allgood = false;
              }
              
              if((this.admin.chapter.password && this.admin.chapter.password.length==0 &&  this.region.id==0)
              || (this.admin.chapter.password!==this.admin.chapter.password_confirm && this.admin.chapter.password && this.admin.chapter.password.length>0)){
                  this.states.pass = false;
                  allgood = false;
              }
              if(this.admin.has_startup==1 && ((this.admin.startup.password && this.admin.startup.password.length==0 &&  this.region.id==0)
              || (this.admin.startup.password!==this.admin.startup.password_confirm && this.admin.startup.password && this.admin.startup.password.length>0))){
                  allgood = false;
              }
              if(this.admin.has_rst==1 && ((this.admin.rst.password && this.admin.rst.length==0 &&  this.region.id==0)
              || (this.admin.rst.password!==this.admin.rst.password_confirm && this.admin.rst.password && this.admin.rst.password.length>0))){
                  allgood = false;
              }
              this.showAlert = !allgood;
              if(this.showAlert){
                this.alertText = this.$t('region.alert_text')
              }
              return allgood;
          },
          updateRegion() {
            if(!this.checkData()){
              return;
            }
            this.$http.post("/api/superadmin/region/update",{region: this.region, admin: this.admin,signup_contacts: this.signup_contacts}).then((response) => {
              if (response.data.success === true) {
                this.saveVariant = 'success';
                this.back();
              } else {
                this.saveVariant = 'danger';
              }
            }).catch(() => {this.$bvToast.show('serverError');});
          },
          getDefaultCountry() {
            this.$http.post("/api/superadmin/getdefaultcountry",{}).then((response) => {
              if (response.data.success === true) {
                this.region.country_id = response.data.country_id;
              } 
            }).catch(() => {this.$bvToast.show('serverError');});
          },
          deleteRegion() {
            this.$http.post("/api/superadmin/region/delete", this.region).then((response) => {
              if (response.data.success === true) {
                this.back();
              }else if(response.data.chapters>0){
                this.showDelete = false;
                this.alertText = this.$t('region.chapters_present')
                this.showAlert = true;
              }
            }).catch(() => {});
          },
           copyRegion() {
             //this.copy.copy_to = this.region.id;
             if(this.copy.copy_to>0 && this.copy.copy_from>0 && this.copy.copy_from != this.region.id){
               this.$http.post("/api/superadmin/region/copy", this.copy).then((response) => {
                if (response.data.success === true) {
                  this.back();
                }
              }).catch(() => {});
             }
            
          },
          back(){
            window.history.length > 1 ? router.go(-1) : router.push("/admin/regions");
          },
          getOfficialRegions(){
            this.$http.post("/api/regions/official/get", {}).then((response) => {
              if (response.data.success === true) {
                this.officialRegions = response.data.regions;
                this.officialRegions.unshift({id: 0, name: this.$t('region.no_template')})
              } 
            }).catch(() => {});
          }
    },
 destroyed() {
      this.removeClass(document.getElementById("menu-regions"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-regions"), "active-menu");
      this.getCountries();
      this.getOfficialRegions();
    }
    }
</script>
