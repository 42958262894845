<template>
  <div>
    <div class="rel-grid-container">
      <div id="top-div"></div>
      <div>
        <table class="table b-table table-hover-new table-bordered table-sm">
          <thead>
            <tr>
              <th class="tableHeader">{{$t('contacttype.english_name')}}</th>
              <th class="tableHeader">{{$t('contacttype.name_text')}}</th>
            </tr>
          </thead>
          <draggable tag="tbody" v-model="contacttypes"  @end="endDrag">
            <tr style="cursor: pointer;" class="dragndrop"  v-for="t in contacttypes" :key="t.id" v-on:click="editContacttype(t)">
              <td >{{ t.name_en }}</td>
              <td>{{ t.name }}</td>
            </tr>
          </draggable>
        </table>
          <div id="drag-note">{{$t('contacttype.drag_order')}}</div>
      </div>
      <div class="submit-box-2">
        <b-button v-if="permissions.access" class="submit-button4" v-on:click="newContacttype" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
      </div>
      <div class="div-space"></div>
    </div>
    <b-modal centered v-model="showTypeEdit" :title="selectedType.id>0? $t('contacttype.type_edit') :$t('contacttype.type_new') " >
        <div style="display:grid;">
          <span class="form-box-name">{{$t('contacttype.name_text')}}</span><b-form-input v-model="selectedType.name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;" class="form-box-input"></b-form-input>
         <b-form-checkbox style="grid-column:2/4;" v-model="selectedType.chapter_assignable" value="1" unchecked-value="0">
            {{$t('contacttype.chapter_assignable')}}
          </b-form-checkbox>
          <b-form-checkbox v-if="isCorporateConnections" style="grid-column:2/4;" v-model="selectedType.auto_register" value="1" unchecked-value="0">
            {{$t('contacttype.auto_register')}}
          </b-form-checkbox>
        </div>
        <div slot="modal-footer" class="submit-box-2">
            <b-button class="submit-button4" v-on:click="deleteType" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          <b-button class="submit-button4" v-on:click="showTypeEdit = false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="submit-button4"  v-on:click="updateType" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
        </div>
      </b-modal>
  </div>
</template>
<style scoped>
 
</style>
<script>
import draggable from "vuedraggable";
    export default {
      name: "ContactTypes",
      components: {
      draggable
    },
      data() {
        return {
          contacttypes: [],
          selectedType: {chapter_assignable: 1,auto_register: 0},
          showTypeEdit: false
        }
      },
      watch: {
      '$i18n.locale': {
        handler: function () {
          this.getContactTypes();
        }
      }
    },
    methods: {
     getContactTypes(){
        this.$http.post("/api/contact/types/get/edit", {}).then((response) => {
          this.contacttypes = response.data.types;
          
        }).catch(() => {
        });
      },
      deleteType(){
        this.$http.post("/api/contact/types/delete", {id: this.selectedType.id}).then((response) => {
            this.showTypeEdit = false;
            if(response.data.success){
                this.getContactTypes();
            }
            
        }).catch(() => {
            this.showTypeEdit = false;
        });
      },
      updateType(){
          
        this.$http.post("/api/contact/types/update", this.selectedType).then((response) => {
            this.showTypeEdit = false;
           if(response.data.success){
               
                this.getContactTypes();
            }
        }).catch(() => {
            this.showTypeEdit = false;
        });
      },
      newContacttype() {
          this.selectedType = {id: 0, name: "", chapter_assignable: 1,auto_register: 0};
        this.showTypeEdit = true;
      },
      editContacttype(item) {
        this.selectedType = {id: item.id, name: item.name, chapter_assignable: item.chapter_assignable,auto_register: item.auto_register};
        this.showTypeEdit = true;
      },
       endDrag() {
        var ranks = [];
        for (var i = 0; i < this.contacttypes.length; i++) {
          ranks.push({ id: this.contacttypes[i].id, rank: i });
        }
        this.$http.post("/api/contact/types/rank", { ranks: ranks}).then(() => {}).catch(() => {});
      },
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-contacttypes"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-contacttypes"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      this.getContactTypes();
    }
    }
</script>
