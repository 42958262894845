<template>
  <div id="centre-div-parent" :class="{'rel-grid-container' : browser_good}">
    <div id="top-div"></div>
    <div v-if="!browser_good">
      <div style="padding-bottom:50px; max-width: 600px; margin: auto;">
        <img style="width:100%;" src="../assets/logo-MeetingHubwSlogan4.png" />
      </div>
      <span id="loginText">We are sorry, but Meeting HUB is unsupported by this browser.</span>
      <span id="loginText">Please install or switch to Chrome or Firefox to access this application.</span>
    </div>
    <div style="font-weight: bold;" v-if="browser_good">
      <div style="padding-bottom:50px; max-width: 600px; margin: auto;">
        <img style="width:100%;" src="../assets/logo-MeetingHubwSlogan4.png" />
      </div>
      <span id="loginText">Please login below.</span>
      <br />
      <div class="login-form">
        <form v-on:submit.prevent="login">
          <b-form-input style="max-width:400px; margin:10px auto;" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" name="username" v-model="username" placeholder="Username" required></b-form-input>
          <b-form-input style="max-width:400px; margin:10px auto;" type="password" name="password" v-model="password" placeholder="Password" required></b-form-input>
          <div v-if="showAlert">
            <b-alert variant="danger"
                     dismissible
                     :show="showAlert"
                     @dismissed="showAlert=false" style="text-align:center;">
              {{alertText}}
            </b-alert>
          </div>
          <b-button style="margin:20px auto;" class="submit-button4" v-on:click="login" variant="clear-blue"><div class="login-button"></div><span>{{$t('global.button_login')}}</span></b-button>
        </form>
        <b-btn v-on:click="showPassReset = true;" variant="link">Forgot password.</b-btn>
        <b-modal id="modal-center" v-model="showPassReset" centered title="Reset password" @ok="resetPass" ok-title="Send">
          <div>Please enter the name of the account below.</div>
          <div>An email will be sent to the address belonging to the account.</div>
          <div style="display: grid;">
            <span class="form-box-name">Account Name</span><b-form-input v-model="reset_name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
          </div>
          <div v-if="showResetAlert" style="text-align: center;">
            <b-alert variant="danger"
                     dismissible
                     :show="showResetAlert"
                     @dismissed="showResetAlert=false" style="text-align:center;">
            </b-alert>
          </div>
        </b-modal>

        <!--<a id="forgotPass" href="">Forgot password.</a>-->
      </div>
    </div>
    <div class="div-space">
      <b-modal id="terms-modal" v-model="showTerms" centered title="Terms and Conditions">
        <div style="overflow:auto;height:500px; padding:40px; text-align:left;" @scroll="onScroll">
          <div>
            <p>
              <strong></strong>
            </p>
            <p align="center">
              <strong>TERMS AND CONDITIONS</strong>
            </p>
            <p>
              <strong></strong>
            </p>
            <p>
              <strong>THESE TERMS AND CONDITIONS </strong>
              apply to all Users of the App created by        <strong>GARO CONSULTING INC.</strong> ("<strong>GARO</strong>"). Each
              of you and GARO may be referred to as a "<strong>Party</strong>" and
              together as the "<strong>Parties</strong>".
            </p>
          </div>
          <br clear="all" />
          <p>
            <strong>1. </strong>
            <strong>Definitions.</strong>
          </p>
          <p>
            <strong></strong>
          </p>
          <p>
            "<strong>Agreement</strong>" means these Terms and Conditions, the Order
            Form, and your payment of the fees for use of the App.
          </p>
          <p>
            "<strong>App</strong>" means the meeting guide application called Meeting
            Hub, created by GARO to simplify the management of structured routine
            meetings, and which has been customized for BNI.
          </p>
          <p>
            "<strong>Chapter</strong>" means a group or collection of individuals who
            form a separate group that is subject to, managed or administered by BNI.
          </p>
          <p>
            "<strong>Confidential Information</strong>" means all confidential and
            proprietary information of the Parties and BNI, including without
            limitation all business, financial, technical and other information,
            intellectual property, Content and data.
          </p>
          <p>
            "<strong>Content</strong>" means the data or content you add to the App.
          </p>
          <p>
            "<strong>Order Form</strong>" means the form that you submit to GARO to
            apply to use the App.
          </p>
          <p>
            "<strong>Term</strong>" means the duration of this Agreement which begins
            on the start date specified in the Order Form and continues a period of one
            (1) year.
          </p>
          <p>
            "<strong>User</strong>" means the person over the age of 18 who is
            authorized by the Chapter to use the App.
          </p>
          <p>
            "<strong>you</strong>" or "<strong>your</strong>" means, as applicable, the
            Chapter, and/or User.
          </p>
          <p>
            <strong></strong>
          </p>
          <p>
            <strong>2. </strong>
            <strong>Use of the App.</strong>
          </p>
          <p>
            <strong></strong>
          </p>
          <p>
            a. <u>Limited License to Use.</u> GARO grants you a non-exclusive,
            non-assignable, non-sub-licensable and non-transferable license to use the
            App during the Term solely for your Chapter's business meeting purposes,
            subject to compliance with and terms and conditions of this Agreement. All
            rights not expressly granted to you herein are reserved by GARO. GARO is
            providing the App on an "as is" basis. GARO may, but is not obligated to
            provide updates to or for the App from time to time and reserves the right
            to alter or discontinue the App at any time on notice to you or on its
            website.<strong></strong>
          </p>
          <p>
            <strong></strong>
          </p>
          <p>
            b. <u>Changes</u>. GARO may change this Agreement or the App from time to
            time in its sole and complete discretion, with or without notice to you,
            and has the right, in its discretion to block, rescind or discontinue your
            access to the App at any time in its discretion without notice or
            explanation.<strong></strong>
          </p>
          <p>
            <strong></strong>
          </p>
          <p>
            c. <u>Option to Participate in New Versions</u>. From time to time GARO may
            invite you to try or participate in, at no charge, a new or updated version
            of a GARO App. If you choose to participate you will be asked to sign a
            participant agreement, and such App will provided under and subject to the
            terms of the participant agreement only, and shall not be subject to the
            terms of this Agreement unless otherwise specified in writing.
          </p>
          <p>
            <strong></strong>
          </p>
          <p>
            d. <u>Your Responsibilities. </u>
          </p>
          <p>
            <u></u>
          </p>
          <p>
            i. You agree to: (A) be responsible for Users' compliance with this
            Agreement and their activities with respect to the App, including keeping
            passwords and account information secret, and for all acts that occur if a
            User fails to do so; (B) use reasonable efforts to prevent unauthorized
            access to or use of the App, and notify GARO promptly of any such
            unauthorized access or use; (C) use the App only in accordance with this
            Agreement, the policies and procedures of your Chapter and/or BNI, and all
            applicable laws and regulations; (D) obtain and maintain all systems and
            devices needed to access and use the App.
          </p>
          <p>
            ii. This Agreement and the use of the App are not intended to and do not
            authorize you to act, and you agree that you shall not hold yourself out,
            as an agent of GARO. You shall not knowingly permit to be done, or
            participate in or associate the App with any activity or conduct that might
            in any way A) affect the validity of the App, B) prejudice the rights of
            GARO with respect to the App, C) diminish or depreciate the value of the
            App or GARO, and/or use the App to imply or suggest that GARO recommends or
            otherwise endorses you, or your Chapter.
          </p>
          <p>
            e. <u>Limitations on Use</u><strong>. </strong>You may not access the App
            if you are a direct competitor, except with GARO's prior written consent.
            In addition, you may not access any App for purposes of monitoring,
            availability, performance, functionality, or any other benchmarking or
            competitive purposes.<strong></strong>
          </p>
          <p>
            <strong></strong>
          </p>
          <p>
            <strong>3. </strong>
            <strong>Third Party Providers.</strong>
          </p>
          <p>
            <strong></strong>
          </p>
          <p>
            a. <u>Third Party Materials.</u><u></u>
          </p>
          <p>
            <strong></strong>
          </p>
          <p>
            i. Any content and materials, including branded content, from BNI are and
            shall remain the property of BNI. GARO has permission to upload the BNI
            media and content offered in the App at the time of your original access to
            the App, from BNI Golden Horseshoe and Niagara. You agree to use any BNI
            content strictly in accordance with your Chapter agreements with BNI. GARO
            is not responsible for monitoring your use and compliance with BNI
            agreements, policies and procedures and /or use of BNI confidential
            information or intellectual property.
          </p>
          <p>
            ii. GARO does not warrant or support non-GARO apps or content. Any links to
            third party providers, if available, are provided to you as a matter of
            convenience, and GARO is not liable or responsible for any content, apps,
            or other materials available from such third party providers. GARO shall
            have no liability, obligation or responsibility related to any
            communications, terms, conditions, representations or warranties between
            you and any such third party.
          </p>
          <p>
            <strong></strong>
          </p>
          <p>
            b. <u>Non-GARO Applications and your Data</u><strong>.</strong> If you
            install or enable applications to be used with the App, that are not
            certified or offered by GARO, GARO shall not be responsible for any
            disclosure, modification, compromise or deletion of your Content that may
            result.<strong></strong>
          </p>
          <p>
            <strong></strong>
          </p>
          <p>
            <strong>4. </strong>
            <strong>Fees and Payment.</strong>
          </p>
          <p>
            <strong></strong>
          </p>
          <p>
            a. <u>Fees.</u> Set-up fees, the annual subscription fee, and applicable
            taxes if any, are as specified in the Order Form. Payment in full is
            required prior to your being granted access to the App. Upon payment, fees
            are non-refundable.<strong></strong>
          </p>
          <p>
            <strong></strong>
          </p>
          <p>
            b. <u>Renewal.</u> Fees for renewal shall be according to the rates posted
            by GARO from time to time and are due and payable prior to the last day of
            any Term. You agree to provide GARO with valid and updated payment
            information including current name, address, email address and credit card
            information or information required for another payment method accepted by
            GARO at least thirty (30) days prior to the end of the current Term. In the
            event payment is not received by GARO by the last day of the current Term,
            GARO may deny access to the App until payment is received by GARO for the
            renewal subscription Term, or this Agreement and your access to and use of
            the App shall terminate as of the end of the current Term.<strong></strong>
          </p>
          <p>
            <strong></strong>
          </p>
          <p>
            <strong>5. </strong>
            <strong>Proprietary Rights.</strong>
          </p>
          <p>
            <strong></strong>
          </p>
          <p>
            a. <u>Reservation of Rights</u>.
          </p>
          <p>
            i. <u>Rights Reserved</u>. Except as provided herein, GARO and its third
            party providers, reserve all right, title and interest in and to the App
            and all intellectual property rights contained therein, including but not
            limited to unpatented inventions, patent applications and patents if any,
            all design rights, copyrights, trademarks, service marks, trade names,
            domain name rights, know-how and other trade secrets, and all derivatives
            thereof. No rights are granted other than the right of access and use, as
            expressly set forth herein.
          </p>
          <p>
            ii. <u>Copyright.</u> The entire content of the App, including but not
            limited to trademarks, and all written, graphic, electronic, digital or
            oral, and all text, graphics and code is copyrighted, and the property of
            either GARO or BNI. ALL RIGHTS ARE RESERVED. Any use, other than for the
            purposes specified by GARO or, as applicable, BNI, is strictly prohibited.
            To the extent you upload, manipulate, revise, copy or print any information
            or materials you agree to and shall leave all proprietary notices intact,
            and shall not change or delete any notices from such information or
            materials.
          </p>
          <p>
            iii. <u>Trademarks</u>. All trademarks, service marks and trade names of
            GARO, BNI and any other used on the App are trademarks or registered
            trademarks of such parties, and may not be used by you without the prior
            written consent and agreement of GARO, BNI or its third party providers,
            which consent is in the complete and unfettered discretion of GARO, BNI or
            such third party, as applicable.
          </p>
          <p>
            b. <u>Restrictions</u>. You agree to and shall not:
          </p>
          <p>
            i. make the App available to anyone other than Users,
          </p>
          <p>
            ii. sell, resell, license, sublicense, transfer, assign, distribute or
            otherwise commercially exploit or make available to any third party the App
            or the content thereof, directly or indirectly,
          </p>
          <p>
            iii. use the App to store or transmit infringing, libelous, or otherwise
            unlawful or tortious material, or to store or transmit material in
            violation of third party privacy rights,
          </p>
          <p>
            iv. use or permit another to or who may use the App to store or transmit
            viruses, worms, time bombs, trojan horses and other harmful or malicious
            code, files, scripts, agents or programs,
          </p>
          <p>
            v. interfere with or disrupt the integrity or performance of the App or
            third party data or Content contained therein,
          </p>
          <p>
            vi. attempt to gain unauthorized access to the App or any of its systems or
            networks,
          </p>
          <p>
            vii. modify or reverse engineer, decrypt, de-compile, disassemble, or
            create any derivative works of the App or the content contained therein,
          </p>
          <p>
            viii. build a App using similar ideas, features, functions or graphics of
            the App, or copy any ideas, features, functions or graphics of the App,
            and/or
          </p>
          <p>
            ix. create internet or other links to the App, or "frame" or "mirror" any
            content on any other server or device, other than for your own internal
            requirements and back-up.
          </p>
          <p>
            c. <u>Your Content</u>.
          </p>
          <p>
            i. Subject to the terms of this Agreement, GARO acquires no right, title or
            interest in your Content, including any intellectual property rights
            therein. You, or the owner of the Content, retain any such rights.
            Notwithstanding this, GARO shall not be responsible or liable for the
            deletion, correction, destruction, damage or loss of Content, or the
            failure to back-up Content. In addition, you understand that in the event
            this Agreement is terminated for any reason, upon termination (a) your
            right to access or use the Content via the App may cease, and (b) GARO has
            no responsibility to make available access to the Content or make a file of
            your Content available to you.
          </p>
          <p>
            ii. You are responsible for ensuring that Content does not include any
            information or material that: (A) violates, infringes or misappropriates
            any BNI or any other third party's copyright, trademark, trade secret,
            right of publicity or other intellectual property or proprietary right; (B)
            is unlawful, harmful, threatening, abusive, harassing, tortious,
            defamatory, vulgar, obscene, libelous, invasive of another's privacy,
            hateful, or racially, ethnically or otherwise objectionable; (C) collects
            or stores personal data about other users or otherwise violates another
            user's privacy without their consent; or (D) contains software viruses or
            any other computer code, files or programs designed to interrupt, destroy
            or limit the functionality of any computer software, device or other
            hardware or equipment.
          </p>
          <p>
            d. <u>Suggestions.</u> GARO shall have all rights to and own any
            suggestions, enhancement requests, recommendations or other feedback
            provided by you and Users, relating to the App including its operation and
            functionality, without additional compensation owing or payable to you.
          </p>
          <p>
            <strong>6. </strong>
            <strong>Confidentiality.</strong>
          </p>
          <p>
            <strong></strong>
          </p>
          <p>
            a. Each Party agrees to hold the Confidential Information of the other
            Party and BNI in confidence and to not disclose such information to third
            parties or to use such information for any purposes whatsoever, except the
            performance of obligations or the exercise of its rights hereunder, without
            the prior written consent of the disclosing Party. Each Party shall be
            responsible for its affiliates, employees, contractors, agents and
            representatives, as applicable, and you agree to and shall be responsible
            for all Users. The Parties shall use reasonable efforts to assist each
            other in identifying and preventing any unauthorized use or disclosure of
            any Confidential Information, agree to inform each other as soon as
            possible in the event that either learns or has reason to believe that
            there has been a breach of this obligation.<strong></strong>
          </p>
          <p>
            <strong></strong>
          </p>
          <p>
            b. A Party shall not be liable for the disclosure of Confidential
            Information if and to the extent only that:<strong></strong>
          </p>
          <p>
            i. at the time of disclosure to the receiving Party the Confidential
            Information is published, known publicly or otherwise in the public domain;
          </p>
          <p>
            ii. after disclosure to the receiving Party the Confidential Information is
            published, becomes publicly known or otherwise becomes part of the public
            domain other than as a result of a disclosure by the receiving Party;
          </p>
          <p>
            iii. after disclosure by the disclosing Party the Confidential Information
            becomes available to the receiving Party from a source other than the
            disclosing Party as shown by documentation maintained in the ordinary
            course of business, and such third party was not bound by any contractual,
            legal or fiduciary obligation of confidentiality with respect to such
            Confidential Information;
          </p>
          <p>
            iv. such Confidential Information was known to or in the lawful possession
            of the receiving Party immediately prior to the time of disclosure by or on
            behalf of the disclosing Party as evidenced by the written records of the
            receiving Party at that time;
          </p>
          <p>
            v. such Confidential Information is independently developed by or on behalf
            of the receiving Party without use of or reliance upon the Confidential
            Information of the disclosing Party.
          </p>
          <p>
            <strong>7. </strong>
            <strong>Warranties, Disclaimers and Limitation of Liability.</strong>
          </p>
          <p>
            <strong></strong>
          </p>
          <p>
            a. <u>Warranties. </u>
          </p>
          <p>
            i. You represent and warrant that: you have the legal authority to enter
            into this Agreement, have correctly identified the legal name for signing
            authority, and that the billing and payment information from time to time
            is correct. By uploading your Content you also represent and warrant that
            you have: (A) the right to copy and upload the Content; (B) you do not
            knowlingly infringe any third party rights, including proprietary and
            privacy rights; and (C) the Content complies with this Agreement and
            applicable laws.
          </p>
          <p>
            ii. GARO warrants that the App will not knowingly infringe third party
            proprietary rights and complies with applicable laws.
          </p>
          <p>
            <strong></strong>
          </p>
          <p>
            b. <u>Disclaimer.</u><strong> </strong>The App is licensed to you on an 'as
            is' basis and GARO makes no representation, warranty, or guarantee as to:
            (i) the reliability, suitability, availability, accuracy or completeness of
            a App or any content thereof, (ii) the use of the App will be secure,
            uninterrupted, error-free or operate properly or seamlessly with your
            computer or other systems, software and/or Content, (iii) the App will meet
            your expectations or requirements, and/or (iv) any errors or defects in the
            App will be corrected. Except as expressly provided in this Agreement, GARO
            makes no representations or warranties of any kind, whether express,
            implied, statutory or otherwise, and specifically disclaims all implied
            warranties, including any warranties of merchantability or fitness for a
            particular purpose, to the maximum extent permitted by applicable law.
          </p>
          <p>
            c. <u>Limitation of Liability</u>. The maximum aggregate liability,
            including applicable legal fees and court costs, of GARO to you, an BNI,
            Chapter or to any third party concerning performance or non-performance of
            this Agreement, or in any manner related to this Agreement, including for
            termination of access to the App for any reason, is limited to direct
            damages in an amount not to exceed the fees paid under this Agreement
            during the Term of the event giving rise to such liability. In no event
            will GARO be liable for indirect, special, incidental, consequential,
            exemplary, or punitive damages (even if advised of the possibility of such
            damages) arising out of the services or otherwise related to this
            Agreement, including damages for lost profits or lost business. This
            limitation shall apply irrespective of the nature of the cause of action,
            demand or action, including but not limited to breach of contract,
            negligence, tort or any other legal theory, and survives a fundamental
            breach or failure of the essential purpose of the Agreement. GARO shall not
            be liable or responsible for any delays, delivery failures, or other damage
            resulting from any limitations, delays, and other problems inherent in the
            use of applications, the internet and electronic communications.
          </p>
          <p>
            <strong>8. </strong>
            <strong>Term and Termination Obligations.</strong>
          </p>
          <p>
            <strong></strong>
          </p>
          <p>
            a. <u>Subscription Term</u>. Your App subscription commences on the start
            date specified in the applicable Order Form and continues during the Term.
            Except as otherwise specified in the applicable Order Form, all App
            subscriptions shall automatically renew for additional periods unless a
            Party gives the other notice of non-renewal at least 30 days before the end
            of a subscription Term. Unless otherwise agreed, renewal subscription
            pricing shall not exceed the amount charged to new BNI chapters for the
            App.
          </p>
          <p>
            b. <u>Termination.</u> In the event of non-renewal or non-payment of the
            any amounts payable under this Agreement, this Agreement shall terminate
            and your right of access to the App shall cease. In addition, GARO may
            terminate this Agreement and the license herein, and shall have the right
            to block your access to the App without notice to you in the event of any
            breach of this Agreement.
          </p>
          <p>
            <strong></strong>
          </p>
          <p>
            c. <u>Survival.</u><strong> </strong>Upon termination or expiration of the
            Term of this Agreement, the following Articles of this Agreement shall
            continue and survive: 5 (Proprietary Rights), 6 (Confidentiality), 7
            (Warranties, Disclaimers and Limitation of Liability), 8c. (Survival), 9
            (Other Provisions).
          </p>
          <p>
            <strong>9. </strong>
            <strong>Other Provisions.</strong>
          </p>
          <p>
            <strong></strong>
          </p>
          <p>
            a. <u>Compliance with Laws.</u> Each Party agrees to and shall comply with
            all applicable laws and regulations, including privacy legislation in force
            and effect in Ontario, Canada.
          </p>
          <p>
            b. <u>Notices.</u> Notice may be given by personal or courier delivery to
            the last known business address for a Party, or by email to the last email
            address on record, upon confirmation of receipt. Any notice received prior
            to 4:00 p.m. on a business day in the jurisdiction of the recipient shall
            be deemed to have been given on that day and any notice received after 4:00
            p.m. on a business day, or on any other day, shall deemed to have been
            given on the next business day.
          </p>
          <p>
            c. <u>Dispute Resolution and Governing Law.</u> If any dispute arises
            between the Parties pertaining to this Agreement which the Parties are
            unable to resolve amicably, such dispute shall be submitted to mediation,
            excepting only any cause of action giving rise to a claim for equitable
            relief and/or claims related to or involving GARO's or any third party's
            intellectual property. This Agreement shall be governed by and construed in
            accordance with the laws of the Province of Ontario without regard to
            conflicts of laws, and the Parties submit to the jurisdiction of the
            commercial courts in Toronto, Ontario for the final resolution of any
            disputes.
          </p>
          <p>
            d. <u>Relationship of the Parties.</u> The Parties are independent
            contractors. This Agreement does not create a partnership, franchise, joint
            venture, agency, fiduciary or employment relationship between the Parties.
          </p>
          <p>
            e. <u>Waiver.</u> No failure or delay by either Party in exercising any
            right under this Agreement shall constitute a waiver of that right.
          </p>
          <p>
            f. <u>Severability.</u><strong> </strong>If any provision of this Agreement
            is held by a court of competent jurisdiction to be contrary to law, the
            provision shall be modified by the court and interpreted so as best to
            accomplish the objectives of the original provision to the fullest extent
            permitted by law, and the remaining provisions of this Agreement shall
            remain in effect.
          </p>
          <p>
            g. <u>Assignment</u><u>.</u> You agree that you shall not assign any rights
            under this Agreement. Any attempted or purported assignment shall be null
            and void and shall result in the termination of this Agreement as of the
            date of the purported assignment.
          </p>
          <p>
            h. <u>Entire Agreement.</u> This Agreement, the Order Form and your payment
            constitute the entire agreement between the Parties and supersedes all
            prior and contemporaneous agreements, proposals or representations, written
            or oral, concerning its subject matter. No modification, amendment, or
            waiver of any provision of this Agreement shall be effective unless in
            writing and either signed or accepted electronically by the Party against
            whom the modification, amendment or waiver is to be asserted.
            Notwithstanding any language to the contrary therein, no terms or
            conditions stated in any email, order or documentation sent by you or your
            Chapter to GARO shall be incorporated into or form any part of this
            Agreement, and all such terms or conditions shall be null and void. The
            terms of this Agreement, may be updated by GARO from time to time in its
            sole discretion and shall be applicable on your next use of the App.
          </p>
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <div style="padding: 10px;">By clicking I AGREE below, you confirm that you are authorized to enter into this Agreement for your BNI chapter, have read, understand and agree to the Terms and Conditions for use of the App and that GARO may communicate with you by way of email in connection with the App.</div>
          <b-button class="submit-button4" v-on:click="showTerms=false" variant="clear-blue"><div class="decline-button"></div><span>{{$t('global.button_decline')}}</span></b-button>
          <b-button class="submit-button4" v-on:click="acceptedTerms" :disabled="cantAccept" variant="clear-blue"><div class="accept-button"></div><span>{{$t('global.button_accept')}}</span></b-button>
        </div>

      </b-modal>
    </div>
  </div>
</template>
<style scoped>
  #centre-div-parent {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    display: grid;
    grid-template-rows: auto auto auto;
    text-align: center;
  }

  #terms-modal .modal-dialog {
    width: 80%;
    max-width: 80%;
  }

  .submit-button-login {
    width: 162px;
    height: 46px;
    margin: auto;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0px 15px;
  }

  #choiceDiv {
    text-align: center;
  }

  .login-form {
    border: solid 1px #aaa;
    padding: 20px;
    max-width: 600px;
    margin: auto;
    text-align: right;
  }

    .login-form h1 {
      text-align: center;
      color: #4d4d4d;
      font-size: 24px;
      padding: 20px 0 20px 0;
    }

    .login-form input[type="password"],
    .login-form input[type="text"]{
      width: 100%;
      padding: 15px;
      border: 1px solid #dddddd;
      margin-bottom: 15px;
      box-sizing: border-box;
    }

    .login-form input[type="submit"] {
      width: 100%;
      padding: 15px;
      background-color: #535b63;
      border: 0;
      box-sizing: border-box;
      cursor: pointer;
      font-weight: bold;
      color: #ffffff;
    }

  #loginText {
    width: 100%;
    display: block;
  }

  #forgotPass {
    font-size: 12px;
    color: #B2CEF0;
    display: block;
    text-align: left;
  }
</style>
<script>
  import router from "../router"
  
  export default {
    name: "Login",
    data() {
      return {
        cantAccept: true,
        showTerms: false,
        showPassReset: false,
        reset_name: "",
        showAlert: false,
        alertText: "Username or password is incorrect",
        showResetAlert: false,
        alertResetText: "",
        password: "",
        username: "",
        browser_good: true
      }
    },
    methods: {
      acceptedTerms() {
        this.showAlert = false;
        let data = {
          username: this.username,
          password: this.password,
          admin: false,
        }
        this.$http.post("/api/terms", data).then((response) => {
          if (response.data.success === true) {
            if (response.data.terms === true) {
              this.auth();
            } else {
              this.showTerms = true;
            }
          } else {
            this.alertText = "Username or password is incorrect"
            this.showAlert = true;
          }
        }).catch(() => {
          this.alertText = "An error occurred while trying to log in."
          this.showAlert = true;
        });
      },
      changedRedirect() {
      },
      login() {
        this.showAlert = false;
        let data = {
          username: this.username,
          password: this.password,
          admin: false
        }
        this.$http.post("/api/login", data).then((response) => {
          if (response.data.success === true) {
            if (response.data.terms === true) {
              this.auth();
            } else {
              this.showTerms = true;
            }
          } else {
            this.alertText = "Username or password is incorrect"
            this.showAlert = true;
          }
        }).catch(() => {
          this.alertText = "An error occurred while trying to log in."
          this.showAlert = true;
        });
      },
      auth(){
        var self = this;
        this.loginAuthenticate(function(){
            self.permissions.previousSpeakers = false;
            self.permissions.previousDates = false;
            self.permissions.showDisabledSlides = true;
            self.permissions.filterRoleId = 0;
            self.permissions.sectionFilterId = 0;
            self.permissions.shownAnnouncements = false;
            router.push("/raspberry");
        })
      },
      resetPass(e) {
        this.showResetAlert = false;
        e.preventDefault();
        this.$http.post("/api/password/reset", { name: this.reset_name }).then((response) => {
          if (response.data.sent === true) {
            this.showResetAlert = false;
            this.showPassReset = false;
          } else {
            this.alertResetText = response.data.error;
            this.showResetAlert = true;
          }
        }).catch(() => {
          this.alertResetText = "Error during reset.";
          this.showResetAlert = true;
        });
      },
      onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
        if (scrollTop + clientHeight >= scrollHeight) {
          this.cantAccept = false;
        }
      }
    },
    mounted() {
      this.browser_good = this.detectIE() < 0;
    }
  }
</script>
