<template>
  <div>
    <div class="rel-grid-container">
      <div id="top-div"></div>
      <form v-on:submit.prevent="update" id="form-fix">
          <span class="form-box-title">{{$t('subscription.account_primary')}}</span>
        <div  class="form-box" style="grid-template-columns: max-content auto;">
            <SetupTip style="position:absolute; left: -40px;" :tipNumber="1"/>
            <span class="form-box-name">{{$t(permissions.organization.is_parent? 'subscription.exec_director': 'subscription.contact_name')}}</span><b-form-input v-model="customer.name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input" required></b-form-input>
            <span class="form-box-name">{{$t('subscription.email_text')}}</span><b-form-input v-model="customer.email" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input" required></b-form-input>
            <span class="form-box-name">{{$t('subscription.phone_text')}}</span><b-form-input v-model="customer.phone" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input" required></b-form-input>
            <span v-if="permissions.organization.is_parent" class="form-box-name">{{$t('subscription.company_text')}}</span><b-form-input v-if="permissions.organization.is_parent" v-model="customer.metadata.company" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input" required></b-form-input>
            <span class="form-box-name">{{$t('subscription.address_text')}}</span><b-form-input v-model="customer.address.line1" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input" required></b-form-input>
            <span class="form-box-name">{{$t('subscription.city_text')}}</span><b-form-input v-model="customer.address.city" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input" required></b-form-input>
            <span class="form-box-name">{{$t('subscription.state_text')}}</span><region-select class="custom-select form-box-input" v-model="customer.address.state"  :country="customer.address.country" :region="customer.address.state" required/>
            <span class="form-box-name">{{$t('subscription.postal_code')}}</span><b-form-input style="max-width:200px;" v-model="customer.address.postal_code" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input" required></b-form-input>
            <span class="form-box-name">{{$t('subscription.country_text')}}</span><country-select class="custom-select form-box-input" v-model="customer.address.country"  :country="customer.address.country" required/>
        </div>
        <span class="form-box-title">{{$t('subscription.monthly_subscription')}}</span>
        <div  class="form-box" style="grid-template-columns: 70% 30%;color: black;">
            <div style="display: grid;text-align:left;">
                <div style="display:grid; grid-template-columns: 80% 20%; padding:5px;">
                    <span >{{$t('subscription.base_cost')}}</span>
                    <span style="color:black;margin: auto;grid-row: 1/3;grid-column: 2;">${{country.monthly_price}}</span>
                    <span style="margin-top: 0px;font-size: 12px;line-height: 10px;">{{$t('subscription.weekly_recurring')}}</span>
                </div>
                <div v-if="global_discount>0 && subscription.status!=1" style="display:grid; grid-template-columns: 80% 20%;;border-top: 1px solid #aaa;padding:5px;padding-left:15px;">
                    <span   style="margin-bottom:0px;">{{$t('subscription.bni_discount',{discount: global_discount})}}</span>
                    <span  style="color:black;margin: auto;grid-row: 1/3;grid-column: 2;">-${{country.monthly_price*(global_discount)/100}}</span>
                    <span   style="margin-top: 0px;font-size: 12px;line-height: 10px;">{{$t('subscription.our_gift')}}</span>
                </div>
                <div v-if="country.monthly_discount>0  && subscription.status!=1" style="display:grid; grid-template-columns: 80% 20%;;border-top: 1px solid #aaa;padding:5px;padding-left:15px;">
                    <span  style="margin-bottom:0px;">{{$t('subscription.country_discount',{discount: country.monthly_discount, country: country.name})}}</span>
                    <span style="color:black;margin: auto;grid-row: 1/3;grid-column: 2;">-${{country.monthly_price*(country.monthly_discount)/100}}</span>
                    <span  style="margin-top: 0px;font-size: 12px;line-height: 10px;">{{$t('subscription.thanks_country',{country: country.name})}}</span>
                </div>
                <div v-if="!permissions.organization.is_parent  && subscription.status!=1" style="display:grid; grid-template-columns: 80% 20%;;border-top: 1px solid #aaa;padding:5px;padding-left:15px;">
                    <span  >{{$t('subscription.region_pays_text')}}</span>
                    <span style="color:black;margin:  auto;">-${{country.monthly_price*(100-(global_discount+country.monthly_discount))/100-(chapter_pays==0? 0 : chapter_price)}}</span>
                </div>
                <div v-if="subscription.status!=1" style="display:grid; grid-template-columns: 80% 20%;border-top: 1px solid #aaa;padding:5px;">
                    <span >{{permissions.organization.is_parent? $t('subscription.per_cost') : $t('subscription.your_cost')}}</span>
                    <span style="font-weight:bold;color:black;margin: auto;">${{permissions.organization.is_parent? country.monthly_price*(100-(global_discount+country.monthly_discount))/100 : (chapter_pays==0? 0 : chapter_price)}}</span>
                </div>
                <div v-if="subscription.status==1" style="display:grid; grid-template-columns: 80% 20%;;border-top: 1px solid #aaa;padding:5px;padding-left:15px;">
                    <span  >{{$t('subscription.pilot_discount')}}</span>
                    <span style="color:black;margin:  auto;">-${{country.monthly_price}}</span>
                    <span  style="margin-top: 0px;font-size: 12px;line-height: 10px;">{{$t('subscription.thank_pilot')}}</span>
                </div>
                <div v-if="subscription.status==1" style="display:grid; grid-template-columns: 80% 20%;border-top: 1px solid #aaa;padding:5px;">
                    <span >{{permissions.organization.is_parent? $t('subscription.per_cost') : $t('subscription.your_cost')}}</span>
                    <span style="font-weight:bold;color:black;margin: auto;">$0</span>
                </div>
                
                <div v-if="subscription.status==3" style="display:grid; grid-template-columns: 80% 20%;padding:5px;">
                    <span v-html="$t('subscription.cost_chapter_region', {region: country.monthly_price*(100-(global_discount+country.monthly_discount))/100-(chapter_pays==0? 0 : chapter_price), chapter: (chapter_pays==0? 0 : chapter_price)})"></span>
                </div>
            </div>
            <div class="sub-box">
                <span style="color: #aaa;">{{$t('subscription.status_text')}}:</span>
                <span :style="{'font-weight':'bold', 'color': status_color}">{{status_text}}</span>
            </div>
            
            <div style="grid-column: 1/3; background-color: #eee;padding:10px;text-align:center;display: grid; margin: 50px 0px;">
                <span v-if="status_line1.length>0">{{status_line1}}</span>
                <b v-if="status_line2.length>0">{{status_line2}}</b>
            </div>
            
            <b-button v-if="subscription.status==3 && (permissions.organization.is_parent || ((chapter_pays==0? 0 : chapter_price))>0)" style="grid-column: 1/3;" variant="link" class="fit-height" v-on:click="showAlert=false; showCancelModal=true;">{{$t('subscription.cancel_plan')}}</b-button>
            <b-button v-if="subscription.status==2 && (permissions.organization.is_parent || subscription.parent_status == 3)" style="grid-column: 1/3;" variant="link" class="fit-height" v-on:click="showActivateModal=true;">{{$t('subscription.activate_plan')}}</b-button>
        </div>
        <span class="form-box-title">{{$t('subscription.payment_method')}}</span>
        <div  class="form-box" style="grid-template-columns: auto auto;">
            <div style="display: grid;">
                <span style="text-decoration:underline;">{{$t('subscription.payment_method')}}</span>
                <span v-if="token==null && card.id.length==0" style="color:black;">{{$t('subscription.no_method')}}</span>
                <span v-if="token!=null">{{$t('subscription.new_card')}}</span>
                <span v-if="token==null && card.id.length>0" style="color:black;">{{card.brand+ " "+$t('subscription.ending_in')+" "+card.last4}}</span>
                <span v-if="token==null && card.id.length>0" style="color:black;">{{$t('subscription.expires_in')+" "+card.exp_month+"/"+card.exp_year}}</span>
                <span v-if="token==null && card.id.length>0" style="color:black;">{{$t('subscription.token_id')+" "+card.id}}</span>
            </div>
            <b-button variant="link" class="fit-height" v-on:click="showCardModal=true;">{{$t('subscription.setup_payment')}}</b-button>
        </div>
        <b-modal id="modal-center" v-model="showCardModal" centered :title="$t('slide.delete_title')">
            <stripe-element-card ref="elementRef" :pk="publishableKey" @token="tokenCreated"/>
            <div slot="modal-footer" class="submit-box-2">
            <b-button class="submit-button4" v-on:click="showCardModal=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="submit-button4" v-on:click="generateCardToken" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
            </div>
        </b-modal>
        
        <div class="submit-box-2" >
            <b-button class="submit-button4" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="submit-button4" type="submit" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>

          <b-modal id="modal-center" v-model="showActivateModal" centered :title="permissions.organization.is_parent? $t('subscription.activate_region') :$t('subscription.activate_chapter')">
              <div style="display: grid;text-align:left;">
                <div style="display:grid; grid-template-columns: 80% 20%; padding:5px;">
                    <span >{{$t('subscription.base_cost')}}</span>
                    <span style="color:black;margin: auto;grid-row: 1/3;grid-column: 2;">${{country.monthly_price}}</span>
                    <span style="margin-top: 0px;font-size: 12px;line-height: 10px;">{{$t('subscription.weekly_recurring')}}</span>
                </div>
                <div v-if="global_discount>0 && subscription.status!=1" style="display:grid; grid-template-columns: 80% 20%;;border-top: 1px solid #aaa;padding:5px;padding-left:15px;">
                    <span   style="margin-bottom:0px;">{{$t('subscription.bni_discount',{discount: global_discount})}}</span>
                    <span  style="color:black;margin: auto;grid-row: 1/3;grid-column: 2;">-${{country.monthly_price*(global_discount)/100}}</span>
                    <span   style="margin-top: 0px;font-size: 12px;line-height: 10px;">{{$t('subscription.our_gift')}}</span>
                </div>
                <div v-if="country.monthly_discount>0  && subscription.status!=1" style="display:grid; grid-template-columns: 80% 20%;;border-top: 1px solid #aaa;padding:5px;padding-left:15px;">
                    <span  style="margin-bottom:0px;">{{$t('subscription.country_discount',{discount: country.monthly_discount, country: country.name})}}</span>
                    <span style="color:black;margin: auto;grid-row: 1/3;grid-column: 2;">-${{country.monthly_price*(country.monthly_discount)/100}}</span>
                    <span  style="margin-top: 0px;font-size: 12px;line-height: 10px;">{{$t('subscription.thanks_country',{country: country.name})}}</span>
                </div>
                <div v-if="!permissions.organization.is_parent  && subscription.status!=1" style="display:grid; grid-template-columns: 80% 20%;;border-top: 1px solid #aaa;padding:5px;padding-left:15px;">
                    <span  >{{$t('subscription.region_pays_text')}}</span>
                    <span style="color:black;margin:  auto;">-${{country.monthly_price*(100-(global_discount+country.monthly_discount))/100-(chapter_pays==0? 0 : chapter_price)}}</span>
                </div>
                <div v-if="subscription.status!=1" style="display:grid; grid-template-columns: 80% 20%;border-top: 1px solid #aaa;padding:5px;">
                    <span >{{permissions.organization.is_parent? $t('subscription.per_cost') : $t('subscription.your_cost')}}</span>
                    <span style="font-weight:bold;color:black;margin: auto;">${{permissions.organization.is_parent? country.monthly_price*(100-(global_discount+country.monthly_discount))/100 : (chapter_pays==0? 0 : chapter_price)}}</span>
                </div>
                <div v-if="subscription.status==1" style="display:grid; grid-template-columns: 80% 20%;;border-top: 1px solid #aaa;padding:5px;padding-left:15px;">
                    <span  >{{$t('subscription.pilot_discount')}}</span>
                    <span style="color:black;margin:  auto;">-${{country.monthly_price}}</span>
                    <span  style="margin-top: 0px;font-size: 12px;line-height: 10px;">{{$t('subscription.thank_pilot')}}</span>
                </div>
                <div v-if="subscription.status==1" style="display:grid; grid-template-columns: 80% 20%;border-top: 1px solid #aaa;padding:5px;">
                    <span >{{permissions.organization.is_parent? $t('subscription.per_cost') : $t('subscription.your_cost')}}</span>
                    <span style="font-weight:bold;color:black;margin: auto;">$0</span>
                </div>
                
            </div>
            <div v-if="permissions.organization.is_parent" style="margin-top:30px;">
                <div  class="form-box-name" style="text-align: center;">{{$t('subscription.current_chapters')}}: <span style="font-weight:bold;color:black;">{{chapters}}</span></div>
                <div style="display:grid; grid-template-columns: auto;margin:auto;margin-top: 20px;text-align: left;" class="fit-width">
                    <span style="color: #999;text-align:center; border-bottom: 1px solid #aaa;">{{$t('subscription.select_billing')}}</span>
                    <b-form-checkbox v-model="chapter_pays" :value="0" :unchecked-value="1">
                        {{$t('subscription.region_pays_all')}}
                    </b-form-checkbox>
                    <div style="display:grid; grid-template-columns: auto auto;">   
                        <b-form-checkbox v-model="chapter_pays"  :value="1" :unchecked-value="0">
                            {{$t('subscription.chapter_pays_some')}}
                        </b-form-checkbox>
                        <b-form-select v-model="chapter_price" :options="chapterPayOptions"  style="margin-left: 5px;" />
                    </div>
                </div>
            
                <!--<div style="display:grid; grid-column:1/3;margin:auto;" >
                    <span v-if="country.monthly_price*(100-(global_discount+country.monthly_discount))/100-chapter_price>0" style="color:black;">{{$t('subscription.region_pays', {price: country.monthly_price*(100-(global_discount+country.monthly_discount))/100-chapter_price, chapters: chapters, total: (country.monthly_price*(100-(global_discount+country.monthly_discount))/100 - chapter_price)*chapters})}}</span>
                    <span v-if="chapter_price>0" style="color:black;">{{$t('subscription.chapter_price', {price: chapter_price})}}</span>
                    <span style="color:black;">{{$t('subscription.new_chapters')}}</span>
                </div>-->
            </div>
            <div v-if="permissions.organization.is_parent" style="display:grid;text-align:left;margin-top:30px;">
                <span style="margin-bottom:30px;">{{$t('subscription.region_activate1')}}</span>
                <span>{{$t('subscription.region_activate2', {price: country.monthly_price*(100-(global_discount+country.monthly_discount))/100 - chapter_price})}}</span>
                <span style="margin-bottom:30px;">{{$t('subscription.region_activate3', {price: (chapter_pays==0? 0 : chapter_price)})}}</span>
                <span>{{$t('subscription.limitations_released')}}</span>
            </div>
            <div v-if="!permissions.organization.is_parent" style="display:grid;text-align:left;margin-top:30px;">
                <span style="margin-bottom:30px;">{{$t('subscription.chapter_activate', {price: (chapter_pays==0? 0 : chapter_price)})}}</span>
                <span>{{$t('subscription.limitations_released')}}</span>
            </div>
            <div slot="modal-footer" class="submit-box-2">
                <b-button v-on:click="activateSub" variant="link"><span>{{$t('subscription.agree_and_activate')}}</span></b-button>
            </div>
        </b-modal>
        <b-modal id="modal-center" v-model="showActivateSuccess" centered :title="$t('subscription.sub_activated')">
            <div style="margin-top:30px;">{{$t('subscription.thank_you')}}</div>
            <div v-if="permissions.organization.is_parent" >{{$t('subscription.region_activated1')}}</div>
            <div v-if="permissions.organization.is_parent" style="margin-top:30px;">{{$t('subscription.region_activated2')}}</div>
            <div v-if="!permissions.organization.is_parent" >{{$t('subscription.chapter_activated1')}}</div>
            <div v-if="!permissions.organization.is_parent" style="margin-top:30px;">{{$t('subscription.chapter_activated2')}}</div>
            <div slot="modal-footer" class="submit-box-2">
            <b-button class="submit-button4" v-on:click="showActivateSuccess=false" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
            </div>
        </b-modal>
        <b-modal id="modal-center" v-model="showCancelModal" centered :title="$t('subscription.cancel_title')">
            <div v-html="$t('subscription.cancel_text1')"></div>
            <div>{{(permissions.organization.is_parent? $t('subscription.region_text') : $t('subscription.chapter_text')) +": "+permissions.organization.name}}</div>
            <br v-if="permissions.organization.is_parent"/>
            <div v-if="permissions.organization.is_parent" v-html="$t('subscription.cancel_region', {chapters: chapters})"></div>
            <br/>
            <div>{{$t('subscription.cancel_confirm')}}</div>
            <br/>
            <b-form-input style="max-width:200px; margin:auto;" :placeholder="$t('login.password_text')" class="login-field"  type="password" v-model="password"></b-form-input>
            <b-alert variant="danger" style="margin: 10px;" dismissible :show="showAlert" @dismissed="showAlert=false">
             {{$t('subscription.password_incorrect')}}
            </b-alert>
            <div slot="modal-footer" class="submit-box-2">
            <b-button class="submit-button4" v-on:click="cancelSub" variant="link"><span>{{$t('subscription.account_cancellation')}}</span></b-button>
            </div>
        </b-modal>
                
      </form>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
.sub-box{
    background-color: #eee; border: 1px solid #666; border-radius: 10px;min-width: 150px; min-height:100px;display:grid; color: #4588ed;    padding: 20px;
    text-align: center; line-height:16px; margin: auto;
}
</style>
<script>
import { StripeElementCard } from '@vue-stripe/vue-stripe';
  import router from "@/router"
    export default {
    name: "Subscription",
    components: {
     StripeElementCard,
  },
      data() {
        this.publishableKey = 'pk_test_WgRSXe5B1rREOxUO6oOGtpNo';
        return {
            password: '',
            showActivateSuccess: false,
            showActivateModal: false,
            showCancelModal: false,
            showAlert: false,
            alertText: '',
            showCardModal: false,
            //publishableKey: '',
            status_text: '',
            status_color: '',
            status_line1: '',
            status_line2: '',
            sessionId: '',
            token: null,
            global_discount: 0,
            chapter_price: 0,
            chapter_pays: 0,
            sub_plan: {billing_price: 0, global_discount: 0, country_discount: 0},
            subscription: {status: 0, expiry_date:null, expired: true},
            showAlert: false,
            country: {monthly_price: 0,currency_type: 'cad', trial_months: 1,
                trial_chapters: 1,monthly_discount: 0 },
            customer: {email: '', name: '', phone:'', metadata: {company: ''}, address: {
                city: '',
                country: '',
                line1: '',
                line2: '',
                postal_code: '',
                state: ''
            }},
            card: {
                id: '',
                last4: '',
                brand: '',
                exp_month: '',
                exp_year: ''
            }
        }
      },
      computed: {
        chapterPayOptions: function () {
          var cp = [];
          var price = this.country.monthly_price*(100-(this.global_discount+this.country.monthly_discount))/100;
          for(var i =0; i<=price; i+=5){
              cp.push({value: i, text: '$'+i});
          }
          return cp;
        }
      },
        methods: {
            cancelSub(){
                this.showAlert = false,
                this.$http.post("/api/organization/subscription/cancel", {password: this.password}).then((response) => {
                    if (response.data.success) {
                        this.getSubscription();
                        this.showCancelModal = false;
                    }else if(!response.data.password){
                        this.showAlert = true;
                    }
                }).catch(() => {this.$bvToast.show('serverError');});
            },
            activateSub(){
                this.showActivateModal = false;
                 this.$http.post("/api/organization/subscription/activate", {chapter_price: this.chapter_price, chapter_pays: this.chapter_pays}).then((response) => {
                    if (response.data.success === true) {
                        this.showActivateSuccess = true;
                        this.getSubscription();
                    }
                }).catch(() => {this.$bvToast.show('serverError');});
            },
            update(){
                this.$http.post("/api/organization/subscription/update", {customer: this.customer, token: this.token, chapter_price: this.chapter_price, chapter_pays: this.chapter_pays}).then((response) => {
                    if (response.data.success === true) {
                        this.back();
                    }
                }).catch(() => {this.$bvToast.show('serverError');});
            },
            generateCardToken(){
                this.$refs.elementRef.submit();
            },
            tokenCreated (token) {
                this.token = token;
                this.showCardModal = false;
            },
          getSubscription(){
              this.$http.post("/api/organization/subscription/get", {}).then((response) => {
              if (response.data.success === true) {
                  if(response.data.customer.address == undefined){
                      response.data.customer.address = {
                        city: '',
                        country: '',
                        line1: '',
                        line2: '',
                        postal_code: '',
                        state: ''
                    };
                  }
                  if(response.data.customer.metadata == undefined){
                      response.data.customer.metadata = {company: ''};
                  }
                this.customer = response.data.customer;
                this.card = response.data.card;
                this.chapter_price = response.data.chapter_price;
                this.chapter_pays = response.data.chapter_pays;
                this.sub_plan = response.data.sub_plan;
                this.country = response.data.country;
                this.chapters = response.data.chapters;
                this.subscription_status = response.data.subscription_status;
                this.global_discount = response.data.global_discount;
                if(this.sub_plan.billing_price>0){
                    this.global_discount = this.sub_plan.global_discount;
                    this.country.monthly_price = this.sub_plan.billing_price;
                    this.country.monthly_discount = this.sub_plan.country_discount;
                }
                var reg = this.permissions.organization.is_parent;
                this.subscription = response.data.subscription;
                if(this.subscription.status == 0){
                    this.status_text = this.$t('subscription.imported_text');
                    this.status_color= 'black';
                    this.status_line1 = this.$t('subscription.imported_descrip');
                    this.status_line2= '';
                }else if(this.subscription.status == 1){
                    this.status_text = this.$t('subscription.pilot_text');
                    this.status_color= '#63BE7B';
                    this.status_line1 = reg? this.$t('subscription.pilot_descrip_region') : this.$t('subscription.pilot_descrip_chapter');
                    this.status_line2= '';
                }else if(this.subscription.status == 2 && !this.subscription.expired){
                    this.status_text = this.$t('subscription.trial_text');
                    this.status_color= '#63BE7B';
                    this.status_line1 = this.$t('subscription.trial_descript', {date: this.subscription.expiry_date});
                    this.status_line2 = reg?  this.$t('subscription.trial_activate_region') : this.$t('subscription.trial_awaiting');
                }else if(this.subscription.status == 2 && this.subscription.expired){
                    this.status_text = this.$t('subscription.expired_text');
                    this.status_color= '#F8696B';
                    this.status_line1 = this.$t('subscription.trial_descript', {date: this.subscription.expiry_date});
                    this.status_line2= reg?  this.$t('subscription.trial_activate_region') : this.$t('subscription.trial_awaiting');
                    if(this.subscription.parent_status == 3 && !reg){
                        this.status_line2= this.$t('subscription.trial_activate_chapter');
                    }
                }else if(this.subscription.status == 3){
                    this.status_text = this.$t('subscription.active_text');
                    this.status_color= '#63BE7B';
                    this.status_line1 = this.$t('subscription.active_descript',{date: this.subscription.billing_date});
                    this.status_line2= '';
                }else if(this.subscription.status == 4){
                    this.status_text = this.$t('subscription.inactive_text');
                    this.status_color= '#F8696B';
                    this.status_line1 = this.$t('subscription.inactive_descript', {date: this.subscription.inactive_date});
                    this.status_line2= this.$t('subscription.inactive_declined');
                }

              }
            }).catch(() => {this.$bvToast.show('serverError');});
          },
          back(){
            window.history.length > 1 ? router.go(-1) : router.push("/admin/regiondash");
          }
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-admin"), "active-menu");
      this.removeClass(document.getElementById("menu-subscription"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-admin"), "active-menu");
      this.addClass(document.getElementById("menu-subscription"), "active-menu");
      this.getSubscription();
    }
    }
</script>
