<template>
  <div>
    <div class="rel-grid-container">
      <div id="top-div"></div>
      <div >
        <table class="table b-table table-hover-new table-bordered table-sm">
          <thead>
            <tr>
              <th class="tableHeader">{{$t('questions.question_text')}}</th>
            </tr>
          </thead>
          <draggable  tag="tbody" v-model="questions"  @end="endDragCat" @start="startDragCat">
            <tr v-bind:class="{disabledClass : question.disabled, dragndrop: true}" style="cursor: pointer;" v-for="question in questions" :key="question.id" v-on:click="editQuestion(question)">
              <td>{{ question.content }}</td>
            </tr>
          </draggable>
        </table>
        <div id="drag-note">*{{$t('questions.drag_order')}}</div>
        <div  style="width: fit-content;margin: 5px auto;">
          <b-button class="submit-button4" v-on:click="newQuestion" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
        </div>
        <div  v-if="questions.length>0" class="submit-box-2" style="grid-gap: 50px;">
          <b-button style="margin:auto;"  class="submit-button4" v-on:click="showEnableAll=true;" variant="clear-blue"><div class="new-button"></div><span>{{$t('questions.enable_all')}}</span></b-button>
          <b-button style="margin:auto;"  class="submit-button4" v-on:click="showDisableAll = true;" variant="clear-blue"><div class="new-button"></div><span>{{$t('questions.disable_all')}}</span></b-button>
        </div>
        <b-modal centered v-model="showEnableAll" :title="$t('questions.enable_title')">
              <div style="text-align:center;">
              {{$t('questions.enable_check')}}
            </div>
            <div slot="modal-footer" style="display:grid; grid-template-columns:auto auto;margin: auto;grid-gap:30px;" class="fit-content">
              <b-button class="submit-button4" v-on:click="showEnableAll = false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
              <b-button class="submit-button4" v-on:click="setAllEnabled" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
            </div>
          </b-modal>
          <b-modal centered v-model="showDisableAll" :title="$t('questions.disable_title')">
              <div style="text-align:center;">
              {{$t('questions.disable_check')}}
            </div>
            <div slot="modal-footer" style="display:grid; grid-template-columns:auto auto;margin: auto;grid-gap:30px;" class="fit-content">
              <b-button class="submit-button4" v-on:click="showDisableAll = false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
              <b-button class="submit-button4" v-on:click="setAllDisabled" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
            </div>
          </b-modal>
          <b-modal id="system-modal" centered :title="$t(questionEdit.id>0? 'questions.edit_question' : 'questions.new_question')" v-model="showQuestionEdit">
            <div>
                <span class="form-box-title">{{$t('questions.question_text')}}</span>
                <div class="form-box" style="grid-template-columns: repeat(3,1fr);">
                  
                   <span class="form-box-name">{{$t('questions.text_text')}}</span><b-form-textarea v-model="questionEdit.content" maxlength="255" type="text" pattern="[^<>]*" :rows="2" :title="$t('global.pattern_title')" style="max-width: 600px;width:450px;" class="form-box-input" required></b-form-textarea>
                     <b-form-checkbox style="grid-column: 2;" v-model="questionEdit.disabled" :value="1" :unchecked-value="0">
                        {{$t('member.disabled_text')}}
                    </b-form-checkbox>
                </div>
                <b-alert variant="danger" dismissible :show="showAlert" @dismissed="showAlert=false" style="margin: auto;" class="fit-width">
                    {{alertText}}
                    </b-alert>
                </div>

            <div slot="modal-footer" class="submit-box-2">
                <b-button class="submit-button4" v-on:click="showQuestionEdit=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
                <b-button v-if="questionEdit.id>0" class="submit-button4" v-on:click="deleteQuestion" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
                <b-button class="submit-button4" v-on:click="updateQuestion" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
                
            </div>
        </b-modal>
      </div> 
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
  #drag-note{
    float: right;font-size: 10px;
  }
</style>
<script>
  import draggable from "vuedraggable";
    export default {
    name: "Questions",
    components: {
      draggable
    },
    data() {
      return {
        questions: [],
        moving:false,
        showEnableAll: false,
        showDisableAll: false,
        showQuestionEdit: false,
        questionEdit: {id: 0, content: '', disabled: 0},
        showAlert: false,
          alertText: ''
      }
    },
    methods: {
        updateQuestion(){
        this.showAlert = false;
          if(this.questionEdit.content == ""){
              this.showAlert = true;
              this.alertText = this.$t("global.complete_fields");
              return;
          }
          this.$http.post("/api/organization/questions/update",  this.questionEdit).then((response) => {
          if (response.data.success) {
            this.getQuestions();
          }
          this.showQuestionEdit = false;
        }).catch(() => {
             this.showQuestionEdit = false;
        });
        },
        deleteQuestion(){
            this.$http.post("/api/organization/questions/delete", this.questionEdit).then((response) => {
          if (response.data.success) {
            this.getQuestions();
          }
          this.showQuestionEdit = false;
        }).catch(() => {
            this.showQuestionEdit = false;
        });
        },
      getQuestions() {
        this.$http.post("/api/organization/questions", {}).then((response) => {
          if (response.data.success) {
            this.questions = response.data.questions;
          }
        }).catch(() => {
        });
      },
      newQuestion() {
          this.showAlert = false;
        this.questionEdit = {id: 0, content: '', disabled: 0};
        this.showQuestionEdit = true;
      },
      editQuestion(item) {
          this.showAlert = false;
        this.questionEdit = Object.assign({}, item);
        this.showQuestionEdit = true;
      },
      startDragCat(){
        this.moving = true;
      },
      endDragCat() {
        this.moving = false;
        var ranks = [];
        for (var i = 0; i < this.questions.length; i++) {
          ranks.push({ id: this.questions[i].id, rank: i });
        }
        this.$http.post("/api/organization/questions/rank", { ranks: ranks}).then(() => {
        }).catch(() => {
        });
      },
      setAllEnabled(){
        this.$http.post("/api/organization/questions/enable/all", { }).then((response) => {
          if(response.data.success==true){
            for (var i = 0; i < this.questions.length; i++) {
              this.questions[i].disabled = 0;
            }
          }
          this.showEnableAll = false;
        }).catch(() => {
        });
      },
      setAllDisabled(){
        this.$http.post("/api/organization/questions/disable/all", { }).then((response) => {
          if(response.data.success==true){
            for (var i = 0; i < this.questions.length; i++) {
              this.questions[i].disabled = 1;
            }
          }
           this.showDisableAll = false;
        }).catch(() => {
        });
      }
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-questions"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-questions"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      this.getQuestions();
    }
    }
</script>
