<template>
 <span><a v-on:click="openTerms" style="color: #007bff;cursor:pointer;">{{$t('setup.view3_text4')}}</a> {{$t('setup.view3_text5')}} <a v-on:click="openPolicy" style="color: #007bff;cursor:pointer;">{{$t('setup.view3_text6')}}</a>
<!--<b-modal id="slide-modal" centered :title="$t('setup.terms_conditions')" v-model="showTermsModal">
    <div>
        <div id="pdf-wrapper" class="fit-width">
            <pdf  :src="'./assets/Meeting_terms.pdf'" :page="pdfPage" @num-pages="syncPdfController" :maxHeightDiv="'pdf-wrapper'" ></pdf>
        </div>
    </div>
    <div style="display:grid; grid-template-columns: 1fr 1fr; margin:auto;" class="fit-width">
        <b-button v-show="pdfPage>1 && loaded" class="submit-button4" v-on:click="pdfPage--" variant="clear-blue"><div class="back-button"></div><span>{{$t('global.button_back')}}</span></b-button>
        <b-button v-show="pdfPage<maxPages && loaded" style="margin-left: auto;grid-column:2;" class="submit-button4" v-on:click="pdfPage++" variant="clear-blue"><span>{{$t('global.button_next')}}</span><div class="next-button"></div></b-button>
    </div>
    <div slot="modal-footer" class="submit-box-2">
        <b-button class="submit-button4" v-on:click="showTermsModal=false" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
    </div> 
</b-modal>-->
 </span>
</template>
<style scoped>
#pdf-wrapper{
    height: 700px;
    margin: auto;
}
</style>
<script>
    //import pdf from 'vue-pdf'
    export default {
    name: "TermsAndCOnditions",
     
    //components: {
    //  pdf
    //},
  data() {
    return {
        showTermsModal: false,
        maxPages: 0,
        pdfPage: 1,
        loaded : false,
    };
  },
   mounted() {
  },
  methods: {
      openTerms(){
          window.open("https://canada.meetinghub.biz/terms.html", "_blank");
      },
      openPolicy(){
          window.open("https://canada.meetinghub.biz/privacy.html", "_blank");
      },
     syncPdfController(pages){
            this.loaded = true;
            this.maxPages = pages;
        },
  }
}
</script>