<template>
  <div>
    <div class="rel-grid-container">
      <div id="top-div"></div>
      <div>
          <div style="display: grid; grid-template-columns: repeat(4,auto);grid-gap:10px;margin: 5px;">
              <span class="form-box-name">Page</span>
            <b-form-select style="width:200px;" v-model="translationsFilter.page" :options="pageOptions"/>
            <span class="form-box-name">English/French contains:</span>
            <b-form-input v-model="translationsFilter.content" type="text" style="width:200px;" />

            <span class="form-box-name">English updated on/after:</span>
          <b-form-datepicker style="margin: 5px 0px;"  v-model="translationsFilter.updated" reset-button :locale="$i18n.locale"></b-form-datepicker>
            <b-form-checkbox v-model="translationsFilter.missing" value="1" unchecked-value="0" style="margin:auto;">
                Only Missing French
            </b-form-checkbox>
            <b-button class="submit-button4" v-on:click="clearFilters" variant="clear-blue"><div class="copy-button"></div><span>CLEAR FILTERS</span></b-button>
          </div>
          <div>
              *Please maintain all html in translations, as well as not translating anything in {}, ex. {name}.
          </div>
          <b-table class="table-hover-new" style="margin-bottom:0px;cursor:pointer;" small bordered :per-page="perPage" @filtered="onFiltered"
           :filter="translationsFilter" :filter-function="tableFilter" :current-page="currentPage" :items="translations" :fields="fields" >
            <template v-slot:cell(language1)="data">
                {{data.item.language1}}
            </template>
            <template v-slot:cell(language2)="data">
               <b-form-textarea style="width:350px"  v-model="data.item.language2" :rows="2"  v-on:change="updateTranslation(data.item)"/>
            </template>
            <template v-slot:cell(updated1)="data">
               {{data.item.updated1? $d(new Date(data.item.updated1), 'all') : ''}}
            </template>
            <template v-slot:cell(updated2)="data">
              {{data.item.updated2? $d(new Date(data.item.updated2), 'all') : ''}}
            </template>
          </b-table>
          <b-pagination  v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0" ></b-pagination>
          
          <div class="submit-box-2">
            <b-button style="margin:50px auto;" class="submit-button4" v-on:click="syncEnglish" variant="clear-blue" ><div class="import-button"></div><span>SYNC ENGLISH DATABASE</span></b-button>
            <b-button style="margin:50px auto;" class="submit-button4" v-on:click="showLoadModal = true;" variant="clear-blue" ><div class="import-button"></div><span>MAKE CHANGES LIVE</span></b-button>
            <b-modal centered v-model="showLoadModal">
                This will make all french changes live. Are you sure you wish to continue?
                <div slot="modal-footer" class="submit-box-2">
                    <b-button class="submit-button4" v-on:click="showLoadModal = false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
                    <b-button class="submit-button4" v-on:click="loadLanguage" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_yes')}}</span></b-button>
                </div>
            </b-modal>
          </div>
          <div>
            <span class="form-box-title">JSON Actions</span>
            <div class="form-box" style="grid-template-columns: auto auto;">
                <div style="grid-column:1/3;">Only use if instructed to. these will overwrite all translation data.</div>
                <b-form-select style="grid-column:1/3;" v-model="importlanguage" :options="languageOptions" class="mb-3 form-box-input" value-field="abbrev" text-field="name"/>
                <b-form-checkbox style="grid-column:1/3;" v-model="importServer" value="1" unchecked-value="0">
                    Import Server
                </b-form-checkbox>
                <b-form-file ref="file-input" :id="'fileupload'" accept=".json" @change="onFileFileChange($event)" plain hidden></b-form-file>
                <b-button class="submit-button4" v-on:click="selectLanguageFile" variant="clear-blue"><div class="copy-button"></div><span>IMPORT LANGUAGE JSON</span></b-button>
                <b-button class="submit-button4" v-on:click="getJSONFile" variant="clear-blue"><div class="copy-button"></div><span>EXPORT LANGUAGE JSON</span></b-button>
                <a id="export-file" :download="exportFile.filename" :href="exportFile.content" hidden></a>
                <b-button class="submit-button4" v-if="exportFile.filename!=''" v-on:click="downloadJSONExport" variant="clear-blue" ><div class="import-button"></div><span>DOWNLOAD LANGUAGE JSON</span></b-button>
            </div>
          </div>
      </div>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
</style>
<script>
import BaseEditor from '../BaseEditor.vue';
export default {
  components: { BaseEditor },
    name: "SiteTranslations",
    data() {return {
        importlanguage: 'en',
        importServer: 0,
        showLoadModal: false,
        pages: {'analytics': 'key values','announcement': 'announcement','announcements': 'announcements','attendance': 'attendance','bios': 'bios','categories': 'categories','category': 'category','contacttype': 'contact type','controller': 'controller','country': 'country','dashboard': 'dashboard','dates': 'meeting dates','emails': 'emails','entry': 'speaker','event': 'event','events': 'events','files': 'files','footer': 'footer','general': 'general','global': 'global','goal': 'goal','goals': 'goals','home': 'home','import': 'import','language': 'language','languageadmin': 'language admin','login': 'login','logs': 'logs','meeting': 'presentation screen','member': 'member','memberphoto': 'member content update','members': 'members','newsletter': 'newsletter','notes': 'notes','notifications': 'notifications','onboard': 'onboard','organizations': 'chapters','payment': 'payment','performance': 'performance','portal': 'portal','powerteam': 'powerteam','powerteams': 'powerteams','section presentation setup': 'preparer','presentation setup': 'speaker presentation','questions': 'questions','quote': 'quote','region': 'region','regionimport': 'region import','reset': 'reset password','role': 'role','roles': 'roles','schedule': 'speakers','scripts': 'scripts','section': 'section','sections': 'sections','sendemail': 'send email','setup': 'setup','slide': 'slide','slides': 'agenda','status': 'status','subscription': 'subscription','super': 'super admin','timezone': 'timezone','tips': 'tips','topic': 'topic','topics': 'topics','userdash': 'member dashboard','visitor': 'visitor registration','yearroles': 'year end roles'},
        languageOptions: [],
        translations: [],
        exportFile: {filename: '', content: ''},
        translationsFilter: {page: 'All', updated: '', content: '', missing: 0},
        perPage: 50,
        currentPage:1,
        totalRows:0,
        pageOptions: [],
        fields: [
          { key: 'pagename',
            label: 'Page',
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
          { key: 'id',
            label: 'Key',
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'language1',
            label: "English",
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
          { key: 'language2',
            label: 'French',
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
          { key: 'updated1',
            label: 'English Updated',
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'updated2',
            label: 'French Updated',
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          }
        ],
    }
    },
    methods: {
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        clearFilters(){
            this.translationsFilter= {page: 'All', updated: '', content: '', missing: 0};
        },
        tableFilter(row, filter){
            /*if(row.organization== filter || filter==0){
            return true;
            }*/
            if(filter.missing==1 && (row.olanguage2==null || row.olanguage2=="")){
                return true;
            }
            if(filter.missing==0 && (filter.page.length==0 || filter.page=="All" || row.pagename.toLocaleLowerCase().includes(filter.page.toLocaleLowerCase())) &&
            (filter.content.length==0 || (row.language1!= null && row.language1.toLocaleLowerCase().includes(filter.content.toLocaleLowerCase())) || (row.olanguage2!= null && row.olanguage2.toLocaleLowerCase().includes(filter.content.toLocaleLowerCase())))
            && (filter.updated == null || row.updated1>filter.updated)){
                return true;
            }
            return false;
        },
        downloadJSONExport(){
            document.getElementById("export-file").click();
        },
        onFileFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length || !this.checkFileSize(files[0]))
            return;
            this.importLanguageFile(files[0]);
        },
        selectLanguageFile(){
            document.getElementById('fileupload').click();
        },
        importLanguageFile(file){
            this.showUpload = true;
            this.showAlert = false;
            this.alertText = this.$t('import.alert_text');
            this.saveVariant = "";
            this.$refs['file-input'].reset();
            let formData = new FormData();
            formData.append("file", file);
            formData.append("language",this.importlanguage);
            formData.append("server",this.importServer);
            this.$http.post("/api/superadmin/sitelanguage/import/json", formData, {headers: {"Content-Type": "multipart/form-data"}}).then((response) => {
                this.showUpload = false;
                if (response.data.success == true) {
                    this.$bvToast.toast("Success", {
                        variant: 'success',
                        solid: true
                        });
                } else {
                    this.showAlert = true;
                    this.alertText = response.data.error;
                }
                }).catch((errors) => {
                this.showUpload = false;
                this.showAlert = true;
                this.alertText = JSON.stringify(errors);
            });
        },
        getJSONFile() {
        this.$http.post("/api/superadmin/sitelanguage/export/json", {language: this.importlanguage, server: this.importServer}).then((response) => {
            if (response.data.success) {
                this.exportFile = response.data.file;
            }
        }).catch((e) => {console.log(e);});
        },
        getLanguages(){
        this.$http.post("/api/organization/languages", {}).then((response) => {
          if (response.data.success === true) {
            this.languageOptions = response.data.languages;
            
            this.importlanguage = this.languageOptions[0].abbrev;
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
      getTranslations(){
        this.$http.post("/api/superadmin/sitelanguage/get", {}).then((response) => {
          if (response.data.success === true) {
            this.translations = response.data.translations;
            var p = new Set();
            for(var i=0; i<this.translations.length; i++){
                var t = this.pages[this.translations[i].page];
                if(t){
                    this.translations[i].pagename = t;
                    p.add(t)
                }else{
                    p.add(this.translations[i].page);
                    this.translations[i].pagename = this.translations[i].page;
                }
                
            }
            this.pageOptions = Array.from(p);
            this.pageOptions.unshift("All");
            this.totalRows = this.translations.length;
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
      loadLanguage(){
          this.showLoadModal = false;
          this.$http.post("/api/superadmin/sitelanguage/load", {}).then((response) => {
          if (response.data.success === true) {
              
              this.$bvToast.toast("Loaded. Please reload the site to see changes.", {
                        variant: 'success',
                        solid: true
                        });
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },  
      syncEnglish(){
        this.$http.post("/api/superadmin/sitelanguage/import/current", {}).then((response) => {
        if (response.data.success === true) {
            
            this.$bvToast.toast("Database synced. Please reload the page.", {
                      variant: 'success',
                      solid: true
                      });
        }
      }).catch((errors) => {
        console.log(errors)
      });
    },   
    updateTranslation(translation){
        var t = {language: 'fr', id: translation.id, page: translation.page, content: translation.language2, server: translation.server};
        this.$http.post("/api/superadmin/sitelanguage/update", t).then((response) => {
            if (response.data.success === true) {
                translation.updated2 = response.data.updated;
            }
        }).catch((errors) => {
          console.log(errors)
        });
    }
},
destroyed() {
    this.removeClass(document.getElementById("menu-admin"), "active-menu");
    this.removeClass(document.getElementById("menu-sitetranslations"), "active-menu");
},
mounted() {
    this.addClass(document.getElementById("menu-admin"), "active-menu");
    this.addClass(document.getElementById("menu-sitetranslations"), "active-menu");
    this.getLanguages();
    this.getTranslations();
}
}
</script>
