<template>
  <div id="demo-box">
      <!--<div id="controller-header" :style="{'grid-template-columns': 'max-content max-content max-content auto max-content'}">
        <div style="height: 41px;" class="fit-content">
          <img style="height: 41px;" :src="getImgUrl('MeetingHub-EmailLogo.png')" />
          <div v-on:click="toggleFullScreen" id="fullscreen-overlay" :style="{width:50+'px'}"><div class="fit-content">{{$t('global.fullscreen')}}</div></div>
        </div>
         <div style="display:grid;grid-template-rows: auto auto;text-align: left;">
          <span v-if="!isDemo && windowWidth>768" style="color:#ff0000;font-size:16px;padding-left: 10px;white-space: nowrap;    line-height: 20px;" >{{permissions.organization.name}}</span>
          <span v-if="!isDemo && windowWidth>768" class="curtail-text" style="color:#000;font-size:14px;padding-left: 10px;white-space: nowrap;    line-height: 20px;">{{permissions.user.name}}</span>
        </div>
        <div style="margin: auto;margin-left: 20px;">
          <SetupTip  :tipNumber="7"/>
        </div>
        <div  style="display:grid;grid-template-rows: auto min-content auto;text-align: center;">
          <div></div>
          <div id="below-text" :style="{'font-size': '15px',     'padding': '0px 10px'}">
           <div style="color:rgb(0, 174, 239);font-weight: bold;">{{$d(next_meeting_date, 'day_text')}}</div>
            <div>{{meet_start}}</div>
          </div>
          <div></div>
        </div>
        <div style="display:grid; grid-template-columns:repeat(2,1fr);margin-left:20px;">
          <div class="clickable"  id="portal-exit-button" v-on:click="logout" style='padding: 10px;grid-column:2;'><b-img :src="getImgUrl('nav-Close-Gray.png')" /></div>
        </div>
       </div>-->
      <multipane @paneResize="onPaneResize" layout="vertical" style="width:100%;height:100%;position:relative;">
             <div id="controller-head-wrapper" :style="{  width: '50%', maxWidth: '75%', minWidth: '25%'}">
                <div style="width:100%;height:100%;">
                    <Controller :test="true" :demo="$route.params.d==1"></Controller>
                </div>
            </div>

       
         <multipane-resizer  :style="{'background-color': '#ddd', 'z-index': 100}"></multipane-resizer>
        <div id="presenter-head-wrapper" :style="{height: (presenterHeight)+'px'}">
            <div id="presenter-wrapper-wrapper" >
                <div id="presenter-wrapper" >
                    <Meeting :test="true" :demo="$route.params.d==1"></Meeting>
                </div>
            </div>
        </div>
      </multipane>
      <b-modal centered v-model="showOptions" :title="$t('controller.options_text')">
            <div style="text-align:center;">
              <div class="option-title">{{$t('controller.language_text')}}</div>
              <div  class="fit-content" style="display:grid; grid-auto-flow: column;margin: auto;">
                <b-button v-for="language in languageOptions" :key="language.id"  :class="{'submit-button4' : true, 'active-flag': $i18n.locale == language.abbrev}" class="language-flag"  v-on:click="loadLanguageAsync(language.abbrev)" variant="clear-blue" ><img :src="getImgUrl('flags/'+language.flag+'.png')" v-bind:alt="language.flag+' flag'"></b-button>
              </div>
            </div>
            <div slot="modal-footer" style="display:grid; grid-template-columns:auto auto;margin: auto;grid-gap:30px;" class="fit-content">
              <b-button class="submit-button4" v-on:click="showOptions = false;" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
            </div>
          </b-modal>
  </div>
</template>
<style scoped>
#controller-header{
  display: grid; padding: 3px;border-bottom:1px solid rgb(0, 174, 239);height:50px;
}
  
  #below-text {
    display: grid;
  }
#date-time{
  display:grid;
  text-align: center;
}
#demo-top-info{
  text-align:center; display:grid; grid-template-rows: auto;line-height:20px;
}
#demo-exit-button:hover{
    filter: invert(53%) sepia(94%) saturate(2419%) hue-rotate(161deg) brightness(97%) contrast(107%);
}
#demo-top-box{
  display:grid;grid-template-columns: 126px max-content auto minmax(max-content,15%) max-content;padding:3px;border-bottom: 1px solid rgb(0, 174, 239);background-color: #f8f9fa!important;
}
.thick-line{
  width:100%;height:100%;border: 1px black solid;background-color: #ccc;
}
.large-bold{
  font-weight:bold; font-size:30px;
}
#descript-box{
  grid-template-columns: 1fr; text-align: center; padding: 5px 8%;margin: auto;
}
#logo-box{
  padding:20px; max-width: 50%; margin: 5px auto;
}
#header-box{
  display:grid; grid-template-columns: 50% 50%;
}
#demo-box{
  width:100%;height:100%;position:relative;display:grid; grid-template-rows: 100%;display: grid;
}
#controller-head-wrapper{
    border: 2px solid black;margin:1%;
}
#presenter-head-wrapper{
    flex-grow: 1; margin: auto 1%; display: grid; grid-template-rows: 100%;
}
#presenter-wrapper-wrapper{
    width:100%;height:100%;border: 2px black solid;margin-left:1%; margin-right:1%;
}
#presenter-wrapper{
    width:100%;
    height:100%;
    /*padding: 1%;*/
    overflow: hidden;
}
</style>
<script>
    
  import router from "../router"
  import Meeting from './Meeting.vue';
  import Controller from "./Controller.vue"
  import { Multipane, MultipaneResizer } from 'vue-multipane';
    export default {
    name: "Demo",
    components: {
      Meeting,
      Controller,
      Multipane,
      MultipaneResizer
    },
     watch: {
      '$i18n.locale': {
        handler: function () {
          document.title = this.$t('global.document_title_demo') +" "+ this.permissions.organization.name;
        },
        deep: true
      },
     },
      data() {
        return {
          next_meeting_date: new Date(),
          current_time: new Date(),
          next_meeting: "",
          orgid: 0,
          meet_start: "",
          presenterWidth: 1000,
          presenterHeight: 1000,
          timeInterval: null,
          windowWidth: 1000,
          showOptions: false,
          languageOptions: [],
          isDemo: false
            
        }
      },
    methods: {
      getDates() {
          this.$http.post("/api/organization/dates"+(this.isDemo? "/demo":""), {}).then((response) => {
            if (response.data.success === true) {
              this.next_meeting = response.data.next_formatted;
              this.orgid = response.data.id;
              this.next_meeting_date = this.next_meeting.length > 0 ? new Date("" + this.next_meeting.split("-").join("/") + " 00:01") : new Date();
            }
          }).catch(() => { });
          this.$http.post("/api/organization/get/starttime", {}).then((response) => {
            if (response.data.success === true) {
              this.meet_start = response.data.meet_start;
              //this.$mq = 'mobile';
            }
          }).catch(() => { });
        },
        onPaneResize(){
            this.$root.$emit('paneSizeChange', 0);
            this.onResize();
        },
        back(){
            router.push("/admin/dashboard");
          },
      onResize() {
        if(document.getElementById("presenter-wrapper")){
          this.presenterWidth = document.getElementById("presenter-wrapper").offsetWidth;
          this.presenterHeight = this.presenterWidth * (9.0/16.0);
          this.windowWidth=document.getElementById("demo-box").parentElement.offsetWidth;
        }
      },
      getLanguages(){
        this.$http.post("/api/organization/languages", {}).then((response) => {
          if (response.data.success === true) {
            this.languageOptions = [];
            for(var i=0; i<response.data.languages.length; i++){
                this.languageOptions.push(response.data.languages[i]);
            }
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
      logout() {
       window.close();
      },
    },
    created(){
      window.addEventListener("resize", this.onResize);
      if(this.$route.params.d==1){
       this.permissions.setup_popup = 7;
     }
    },
    destroyed() {
      clearInterval(this.timeInterval);
      window.removeEventListener("resize", this.onResize);
    },
    mounted() {
      //this.isDemo = this.$route.params.d==1;
     
      var self = this;
      window.addEventListener("focus", function(event) {
        self.authenticate();
      }, false);
      this.timeInterval = setInterval(function () {
        self.current_time = new Date();
      }, 50);
        this.onResize();
        
        this.getDates();
        if(!this.isDemo){
          this.authenticate(function(){
          document.title = self.$t('global.document_title_demo')+" "+ self.permissions.organization.name;
        });
          this.getLanguages();
        }
        
      }
    }
</script>
