<template>
<div v-if="tip.disabled==0 && permissions.organization.onboard==1 && permissions.organization.official" >
    <div >
        <img style="height:30px;cursor: pointer;" src="../assets/icon-SetupTip.png" v-on:click="showTipModal= true;"/>
    </div>
    <b-modal id="slide-modal" centered v-model="showTipModal">
        <div slot="modal-header" class="w-100" id="bio-modal-header">
            <div style="display:grid; grid-template-columns: 30px auto;">
                <img style="height:30px;" src="../assets/icon-SetupTip.png" /><span style=" color: #09397F;font-size: 20px;margin-left: 10px;">{{tip.title}}</span>
            </div> 
        </div>
        <div >
            <span class="tip-text pre-format" style="grid-column:1/3;font-size: 14px;" v-html="tip.content"></span>
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="submit-button4" v-on:click="showTipModal=false" variant="clear-blue">
            <div class="save-button"></div>
            <span>{{$t('global.button_continue')}}</span>
          </b-button>
        </div>
      </b-modal>
  </div>
</template>
<style scoped>
.tip-text{
    white-space: pre-wrap; /* Since CSS 2.1 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
}
</style>
<script>
    export default {
    name: "SetupTip",
     props: {tipNumber: Number},
     watch: {
    '$i18n.locale': { 
      handler: function () {
          this.getTipEnabled();
      },
      deep: true
    }
  },
  data() {
    return {
      showTipModal: false,
      tip: {width: 650, content: "", title: "", disabled: 1}
    };
  },
   mounted() {
       
     this.getTipEnabled();
  },
  destroyed(){
      this.permissions.setup_popup = 0;
  },
  methods: {
     getTipEnabled() {
       this.$http.post("/api/organization/onboardtips/get",{n:this.tipNumber}).then(response  => {
        if(response.data.success){
          this.showTipModal = (this.permissions.setup_popup==this.tipNumber);
          this.tip = response.data.tip;
          this.tip.disabled = response.data.disabled;
        }else{
          this.tip.disabled = 1;
        }
      }).catch((errors) => {console.log(errors)});
    }
  }
}
</script>