<template>
  <div>
    <div class="rel-grid-container">
      <div id="top-div"></div>
      <form v-on:submit.prevent="updateRole" id="form-fix">
        <span class="form-box-title">{{$t('role.organization_role')}}</span>
        <div class="form-box">
          <span class="form-box-name">{{$t('role.name_text')}}</span><b-form-input v-model="role.name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;" class="form-box-input" required></b-form-input>
          <div id="role-check">
            <b-form-checkbox v-model="role.singular"
                             value="1"
                             unchecked-value="0">
              {{$t('role.one_only')}}
            </b-form-checkbox>
            <b-form-checkbox v-if="permissions.organization.is_parent && !isCorporateConnections" v-model="role.official"
                             value="1"
                             unchecked-value="0">
              {{$t('role.official_role')}}
            </b-form-checkbox>
            <b-form-checkbox v-if="permissions.organization.is_parent" v-model="role.leadership"
                             value="1"
                             unchecked-value="0">
              {{$t('role.leadership_role')}}
            </b-form-checkbox>
            <!--<b-form-checkbox v-if="permissions.organization.is_parent" v-model="role.president"
                             value="1"
                             unchecked-value="0">
              {{$t('role.president_role')}}
            </b-form-checkbox>-->
          </div>

        </div>
         <b-alert variant="danger" class="fit-width" dismissible :show="showAlert" style="margin: 10px auto;" @dismissed="showAlert=false">
            {{alertText}}
          </b-alert>
        <div class="submit-box-2">
          <b-btn v-b-modal.modal-center v-if="role.id>0" class="submit-button4" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-btn>
          <b-button class="submit-button4" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="submit-button4" type="submit" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
        </div>
        <b-modal id="modal-center" v-model="showDelete" centered :title="$t('role.delete_title')">
          <p class="my-4">{{$t('role.delete_text')}}</p>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="submit-button4" v-on:click="showDelete=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="submit-button4" v-on:click="deleteRole" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          </div>
        </b-modal>
      </form>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
  #role-check {
    grid-column-start: 2;
    grid-column-end: 4;
    margin: 10px;
    color: #999;
  }
</style>
<script>
    
  import router from "../router"
    export default {
    name: "Role",
   
      data() {
        return {
          showDelete: false,
          saveVariant : 'blue',
          selected: [],
          role: {id:0, name: "", singular: 0,official :0, recognition: 0, leadership: 0, president: 0},
          alertText: "",
          showAlert: false
        }
      },
    methods: {
      getRole() {
        this.role.id = this.$route.params.id;
        if (this.role.id > 0) {
          this.$http.post("/api/role/get", this.role).then((response) => {
            if (response.data.success) {
              this.role = response.data.role;
              this.role.singular = "" + this.role.singular;
            }
          }).catch(() => { });
        }
      },
      updateRole() {
        this.$http.post("/api/role/update", this.role).then((response) => {
          if(response.data.errorcode == 2){
                this.showAlert = true;
                this.alertText = this.$t('role.duplicate_name');
                return;
            }
          if (response.data.success === true) {
            this.saveVariant = 'success';
            this.back();
          } else {
            this.saveVariant = 'danger';
          }
        }).catch(() => { this.$bvToast.show('serverError');});
      },
      deleteRole() {
        this.$http.post("/api/role/delete", this.role).then((response) => {
          if (response.data.success === true) {
            this.back();
          }
        }).catch(() => { });
      },
      back() {
        window.history.length > 1 ? router.go(-1) : router.push("/admin/roles");
      }
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-roles"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-roles"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      this.getRole();
      }
    }
</script>
