<template>
  <div id="Slide">
    <div>
      <div id="banner-input">
        <div>
          <b-button v-show="previous_slide>0" v-on:click="previousSlide" class="submit-button4 fit-width" style="margin-top: 2px;margin: 0px;" variant="clear-blue"><div class="back-button"></div><span>{{$t('global.button_previous')}}</span></b-button>
        </div>
        <span style="margin: 7px;font-size:16px;"><span style="color: #aaa;">{{$t('slide.section_text')}}:</span> {{(section_name.length>0? section_name : $t('global.no_translation') ) + (slide.id>0? " > "+current_rank : "")}}</span>
        <span class="form-box-name" style=" margin: 7px;">{{$t('slide.name_text')}}</span>
        <b-form-input v-model="slide.name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" required :disabled="childView"></b-form-input>
        <div>
          <b-button v-show="next_slide>0" v-on:click="nextSlide" class="submit-button4 fit-width" style="width:93px; margin-right: 0px;margin-left: auto;margin-top: 2px;" variant="clear-blue"><div class="next-button"></div><span>{{$t('global.button_next')}}</span></b-button>
        </div>
      </div>
      <b-modal id="modal-center" v-model="showDelete" centered :title="$t('slide.delete_title')">
        <p class="my-4">{{$t('slide.delete_text')}}</p>
        
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="submit-button4" v-on:click="showDelete=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="submit-button4" v-on:click="deleteSlide" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
        </div>
      </b-modal>
      <b-modal id="modal-html" v-model="showHTMLImage" centered>
        <img id="img-html" :src="output" style="width:200px; height:auto;">
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="submit-button4" v-on:click="showHTMLImage=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
        </div>
      </b-modal>
      <b-modal id="modal-center" v-model="showCopyTexts" centered :title="$t('slide.copy_language')">
        <p class="my-4">{{$t('slide.copylanguage_text')}}</p>
          <b-form-select v-model="copylanguage" :options="copyOptions" value-field="abbrev" text-field="name"/>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="submit-button4" v-on:click="showCopyTexts=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="submit-button4" v-on:click="copyTextObjects(copylanguage)" variant="clear-blue"><div class="copy-button"></div><span>{{$t('global.button_copy')}}</span></b-button>
        </div>
      </b-modal>
    </div>
    <div id="slide-container">
      <div id="inner-slide">
        <div class="submit-box-2" style="margin-bottom:10px;">
          <b-button v-on:click="showDelete=true;" v-if="slide.id>0 && !childView" class="submit-button4" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          <b-button  class="submit-button4" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button v-if="!showUpload" class="submit-button4" v-on:click="saveSlide" :disabled="showUpload" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
        </div>
        <div v-if="showAlert" >
          <b-alert variant="danger"
                   dismissible
                   :show="showAlert"
                   @dismissed="showAlert=false">
            {{alertText}}
          </b-alert>
        </div>
        <div v-if="showUpload" >
          <b-alert style="line-height:30px;" variant="warning" :show="showUpload">
            {{$t('slide.uploading_presentation')}}
            <b-spinner style="float:right;" label="Uploading" variant="warning"></b-spinner>
          </b-alert>
        </div>
        <div v-if="!childView" style="display:grid;grid-auto-flow:column;grid-gap:5px;grid-template-columns: auto repeat(10, min-content);margin-bottom:10px">
          <div></div>
          <b-button v-b-popover.hover.top="$t('slide.delete_items')" v-if="selected_items.filter(v => v.special=='').length>0" class="grid-button" variant="clear-blue" v-on:click="deleteSelected"><img style="height:22px;" src="../assets/icon-DeleteObject2.png" /></b-button>
          <b-button v-b-popover.hover.top="$t('slide.copy_items')" v-if="selected_items.length>0" class="grid-button" variant="clear-blue" v-on:click="copySelected" id="slide-copy-button"></b-button>
          <b-button v-b-popover.hover.top="$t('slide.paste_items')" v-if="permissions.copy_items.length>0" class="grid-button" variant="clear-blue" v-on:click="pasteItems" id="slide-paste-button"></b-button>
          <b-button v-b-popover.hover.top="$t('slide.paste_format')" v-if="permissions.copy_items.length>0 && permissions.copy_items[0].spacing && selected_items.length>0 && selected_items[0].spacing" class="grid-button" variant="clear-blue" v-on:click="pasteFormat" id="slide-paste-language-button"></b-button>
          <b-button v-b-popover.hover.top="$t('slide.bring_front')" v-if="dragObject && dragObject.z_pos != undefined" class="grid-button" variant="clear-blue" v-on:click="bringToFront" id="slide-bringfront-button"></b-button>
          <b-button v-b-popover.hover.top="$t('slide.send_back')" v-if="dragObject && dragObject.z_pos != undefined" class="grid-button" variant="clear-blue" v-on:click="sendToBack" id="slide-sendback-button"></b-button>
          <b-button v-b-popover.hover.top="$t('slide.copy_language')" v-if="permissions.organization.language_admin==1" class="grid-button" variant="clear-blue" v-on:click="showCopyTexts=true;" id="slide-copy-language-button"></b-button>
          <b-button v-b-popover.hover.top="$t('slide.show_grid')" class="grid-button" variant="clear-blue" v-on:click="setGrid(!showGrid)" :id="gridImage"></b-button>
          <b-form-input v-if="showGrid" style="margin: 5px 0px;width:60px;height:30px;" v-model="gridNum" min="10" max="50" type="number"></b-form-input>
          <b-form-checkbox v-if="showGrid" style="margin: 7px 0px;" v-model="snapGrid"
                           value="1"
                           unchecked-value="0">
            {{$t('slide.snap_grid')}}
          </b-form-checkbox>
        </div>
        <div id="main-slide">
          <div id="slide-options">
            <b-button v-if="childView && slide.child_edit==1" class="slide-button" variant="clear-blue" v-on:click="showChildSettings=true;"><div id="settings-button" class="image-colour-shift"></div><span>{{$t('slide.settings_button')}}</span></b-button>
            <b-button v-if="!childView" class="slide-button" variant="clear-blue" v-on:click="showSettings=true;"><div id="settings-button" class="image-colour-shift"></div><span>{{$t('slide.settings_button')}}</span></b-button>
            <b-button v-if="!childView" class="slide-button" variant="clear-blue" v-on:click="showMembers=true"><div id="members-button2" class="image-colour-shift"></div><span>{{$t('slide.members_button')}}</span></b-button>
            <b-button v-if="!childView" class="slide-button" variant="clear-blue" v-on:click="addText(true)"><div id="text-button" class="image-colour-shift"></div><span>+{{$t('slide.text_button')}}</span></b-button>
            <b-button v-if="!childView" class="slide-button" variant="clear-blue" v-on:click="addGraphic(true)"><div id="graphic-button" class="image-colour-shift"></div><span>+{{$t('slide.graphic_button')}}</span></b-button>
            <b-button v-if="!childView" class="slide-button" variant="clear-blue" v-on:click="showTimer"><div id="timer-button" class="image-colour-shift"></div><span>{{timer.disabled==1? "+" : ""}}{{$t('slide.timer_button')}}</span></b-button>
            <b-button v-if="!childView" class="slide-button" variant="clear-blue" v-on:click="showFilesEdit=true;"><div id="files-button" class="image-colour-shift"></div><span>{{$t('slide.files_button')}}</span></b-button>
            <b-button v-if="permissions.organization.id == 4" class="slide-button" variant="clear-blue" v-on:click="showGalleryOptions"><div id="files-button" class="image-colour-shift"></div><span>{{$t('slide.gallery_button')}}</span></b-button>
            <b-button v-if="!preview" class="slide-button" variant="clear-blue" v-on:click="previewSlide"><div id="preview-button" class="image-colour-shift"></div><span>{{$t('slide.preview_button')}}</span></b-button>
            <b-button v-if="preview" class="slide-button" variant="clear-blue" v-on:click="outlineSlide"><div id="outline-button" class="image-colour-shift"></div><span>{{$t('slide.outline_button')}}</span></b-button>
            <b-form-select style="width:90%;" v-if="memberDescription.type==1 && preview" v-model="preview_member_id" :options="members" value-field="id" text-field="name" />
            <b-form-select style="width:90%;" v-if="memberDescription.type==7 && preview" v-model="preview_member_id" :options="attendees" value-field="id" html-field="name" />
            <b-form-select style="width:90%;" v-if="memberDescription.type==8 && preview" v-model="goal.goal_id" :options="goals" value-field="id" text-field="name"/>
            <b-form-select style="width:90%;" v-if="memberDescription.type==10 && preview" v-model="preview_member_id" :options="performancePeriods" value-field="id" text-field="text"/>
            <b-form-select style="width:90%;" v-if="memberDescription.type==14 && preview" v-model="preview_member_id" :options="recog_members" value-field="id" text-field="name" />
          </div>
          <div v-on:click.self="backgroundClick" ref="printMe" id="main-view" v-bind:style="backgroundStyle" v-on:drop="dropHandler" @dragover.prevent  @dragenter.prevent @dragleave.prevent>
            <div v-if="showGrid" id="grid-layout" :style="{'grid-template-columns': 'repeat('+gridNum+',1fr)','grid-template-rows': 'repeat('+gridNum+',1fr)'}">
                <div v-for="v in gridNum*gridNum" class="grid-element" :key="v"></div>
              </div>
              <VueDragResize v-if="([1,2,3,4,7,6,9,14,18].indexOf(memberDescription.type) !== -1 && memberDescription.show_photo==1)" :isActive="memberDescription.graphic.active" v-on:clicked="onMemberDescClick($event)" v-on:activated="onActivated(memberDescription.graphic)"  v-on:resizing="resize" v-on:dragging="resize" :x="(memberDescription.graphic.x_pos / 100) * windowWidth" :y="(memberDescription.graphic.y_pos / 100) * windowHeight" :minh="5" :h="memberDescription.graphic.height*windowHeight/100" :w="memberDescription.graphic.width*windowWidth/100" :parentLimitation="true" :parentW="windowWidth" :parentH="windowHeight" class="draggable pointerDrag" 
                             v-bind:id="'single-member1'" :style="{'z-index': 11, left: memberDescription.graphic.x_pos+'%', top: memberDescription.graphic.y_pos+'%',  height: memberDescription.graphic.height+'%', width: memberDescription.graphic.width+'%', border: memberDescription.graphic.active || memberDescription.graphic.selected? 'dashed 1px #555' : 'dashed 1px transparent'}">
                <div v-if="!isCorporateConnections && (slide_member.is_network ==1 || slide_member.is_renewing==1) && slide.show_recognition==1" class="member-banner" :style="{'font-size': (.04*memberDescription.graphic.height/100)*windowHeight+'px'}">{{slide_member.is_network ==1? $t('slide.network_leader') : $t('slide.renewing_member')}}</div>
                <img v-if="settings.show_bni_accent==1 && memberDescription.show_accent!=0" class="member-corner-image"  :src="isCorporateConnections? getImgUrl(memberDescription.show_accent==1?'Picture-CC-backYellow.png' : 'Picture-CC-backGray.png') : getImgUrl('Corner.png')"/>
                <div :class="{'circular-member': memberDescription.circular==1}" class="member-graphic" :style="{'background-image': 'url('+slide_member.photo+')'}" ></div>
                <div v-if="!isCorporateConnections && slide_member.is_speaker ==1" class="member-banner" :style="{'bottom': '0','font-size': (.04*memberDescription.graphic.height/100)*windowHeight+'px'}">{{$t('slide.featured_speaker')}}</div>
              </VueDragResize>
              
              <div v-if="memberDescription.type==8" :style="{'font-family' : goal.font}">
                <VueDragResize v-on:clicked="onMemberDescClick($event)" :isActive="goal.text.active" :sticks="['mr','ml']" v-on:activated="onActivated(goal.text)" v-on:resizing="resize"  v-on:dragging="resize" :x="(goal.text.x_pos / 100) * windowWidth" :y="(goal.text.y_pos / 100) * windowHeight" :w="goal.text.width*windowWidth/100"  :parentLimitation="true" :parentW="windowWidth" :parentH="windowHeight" class="draggable pointerDrag fit-width"
                             :style="{display: 'grid', 'grid-template-rows': 'repeat(3,min-content)', border: goal.text.active? 'dashed 1px #555' : 'dashed 1px transparent',left: goal.text.x_pos+'%', top: goal.text.y_pos+'%', width: goal.text.width+'%', height: 'fit-content', height: '-moz-fit-content', height: '-webkit-fit-content'}">
                  <b :style="{color: goal.title_color,'font-size': ((goal.height/100)*windowHeight)+'px', 'line-height':'100%'}">{{preview? slide_goal.title: $t('slide.title_text')}}</b>
                  <i :style="{color: goal.description_color,'font-size': ((goal.description_size/100)*windowHeight)+'px', 'line-height':'100%'}">{{preview? slide_goal.description : $t('slide.description_text')}}</i>
                  <span :style="{color: goal.notes_color,'font-size': ((goal.notes_size/100)*windowHeight)+'px', 'line-height':'100%', 'margin-top': '30px'}" v-if="preview" v-html="slide_goal.notes"></span>
                  <span :style="{color: goal.notes_color,'font-size': ((goal.notes_size/100)*windowHeight)+'px', 'line-height':'100%', 'margin-top': '30px'}" v-if="!preview">{{$t('slide.notes_text')}}</span>
                </VueDragResize>
                <VueDragResize v-on:clicked="onMemberDescClick($event)" :isActive="goal.history.active" :sticks="[]" v-on:activated="onActivated(goal.history)"  v-on:dragging="resize" :x="(goal.history.x_pos / 100) * windowWidth" :y="(goal.history.y_pos / 100) * windowHeight" :parentLimitation="true" :parentW="windowWidth" :parentH="windowHeight" class="draggable pointerDrag"
                             :style="{'text-align': 'center',border: goal.history.active? 'dashed 1px #555' : 'dashed 1px transparent',left: goal.history.x_pos+'%', top: goal.history.y_pos+'%', width: 'fit-content', width: '-moz-fit-content', width: '-webkit-fit-content', height: 'fit-content', height: '-moz-fit-content', height: '-webkit-fit-content', 'font-size': ((goal.history_font_size/100)*windowHeight)+'px'}">
                  <table v-if="!preview || slide_goal.history.length>0" class="table b-table table-bordered table-sm table-hover-new">
                    <thead>
                      <tr>
                        <th :style="{'background-color': goal.heading_back_colour, 'color': goal.heading_colour}">{{$t('goal.day_text')}} </th>
                        <th :style="{'background-color': goal.heading_back_colour, 'color': goal.heading_colour}">{{$t('goal.value_text')}} </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="h in slide_goal.history.slice(0,goal.max_table_rows)" :key="h.day" :style="{'font-weight': h == slide_goal.history[0]? 'bold' : 'normal'}">
                        <td :style="{'background-color': goal.table_back_colour, 'color': goal.table_colour}">{{h.day}}</td>
                        <td :style="{'background-color': goal.table_back_colour, 'color': goal.table_colour}">{{h.value}}</td>
                      </tr>
                    </tbody>
                  </table>
                </VueDragResize>
                <VueDragResize v-on:clicked="onMemberDescClick($event)" :isActive="goal.thermo.active" :sticks="[]" v-on:activated="onActivated(goal.thermo)"  v-on:dragging="resize" :x="(goal.thermo.x_pos / 100) * windowWidth" :y="(goal.thermo.y_pos / 100) * windowHeight" :parentLimitation="true" :parentW="windowWidth" :parentH="windowHeight" class="draggable pointerDrag donation-meter"
                             :style="{border: goal.thermo.active? 'dashed 1px #555' : 'dashed 1px transparent',left: goal.thermo.x_pos+'%', top: goal.thermo.y_pos+'%', height: '80%'}">
                  <span class="glass">
                      <!--<strong v-if="!preview || !slide_goal.value || ((slide_goal.value/slide_goal.target)*100 < slide_goal.target-5)" :style="{color: goal.color}" class="goal">{{preview? $t('slide.target_text')+" "+ (slide_goal.target != null && slide_goal.target != undefined ? slide_goal.target : ""): $t('slide.target_text')}}</strong>
                      <strong class="total" :style="{color: goal.color,bottom: (preview? (slide_goal.value? Math.max(Math.min((slide_goal.value/slide_goal.target)*100,98),0) : 0) : 50)+'%'}">{{preview? ($t('slide.current_text')+" "+ (slide_goal.value != null && slide_goal.value != undefined ? slide_goal.value : "0")) : $t('slide.value_text')}}</strong>-->
                      <span class="amount" :style="{height: (preview? (slide_goal.value? Math.max(Math.min((slide_goal.value/slide_goal.target)*100,98),0) : 0) : 50)+'%'}"></span>
                      <hr class="goal-increment" v-for="x in [1,2,3,4,5,6,7,8,9,15]" :key="x" :style="{'bottom': (x*10-4)+'%'}">
                  </span>
                  <div class="bulb">
                      <span class="red-circle"></span>
                      <span class="filler">
                          <span></span>
                      </span>
                  </div>
                </VueDragResize>
              </div>
              <VueDragResize id="descriptionBox" :isActive="memberDescription.active" v-on:activated="onActivated(memberDescription)" v-if="[1,2,3,4,6,7,9,14,18].indexOf(memberDescription.type) !== -1" v-on:clicked="onMemberDescClick($event)" v-on:resizing="resize"  v-on:dragging="resize" :x="(memberDescription.x_pos / 100) * windowWidth" :y="(memberDescription.y_pos / 100) * windowHeight" :h="memberDescription.height*windowHeight/100" :w="memberDescription.width*windowWidth/100" :parentLimitation="true" :parentW="windowWidth" :parentH="windowHeight" class="draggable pointerDrag textElement"
                             :style="{'text-align': memberDescription.alignment,border: memberDescription.active || memberDescription.selected? 'dashed 1px #555' : 'dashed 1px transparent',left: memberDescription.x_pos+'%', top: memberDescription.y_pos+'%', height: memberDescription.height+'%', width: memberDescription.width+'%',}">
                <div>
                <div id="member-name" :style="{'font-size': ((memberDescription.name_height/100)*windowHeight)+'px', 'line-height':'100%', color: memberDescription.name_color, 'font-family' : memberDescription.name_font, 'font-weight': memberDescription.name_weight}">{{slide_member.name}}</div>
                <div v-if="memberDescription.job_title_show==1 && slide_member.job_title && slide_member.job_title.length>0" id="member-job_title" :style="{'font-size': ((memberDescription.job_title_height/100)*windowHeight)+'px','line-height':'100%', color: memberDescription.job_title_color, 'font-family' : memberDescription.job_title_font, 'font-weight': memberDescription.job_title_weight}">{{slide_member.job_title}}</div>
                <div v-if="memberDescription.show_company==1 && slide_member.company && slide_member.company.length>0" id="member-company" :style="{'font-size': ((memberDescription.company_height/100)*windowHeight)+'px','line-height':'100%', color: memberDescription.company_color, 'font-family' : memberDescription.company_font, 'font-weight': memberDescription.company_weight}">{{slide_member.company}}</div>
                <div v-if="memberDescription.show_category==1 && slide_member.category && slide_member.category.length>0  && memberDescription.type !=7" id="member-category" :style="{'font-size': ((memberDescription.category_height/100)*windowHeight)+'px', 'line-height':'100%', color: memberDescription.category_color, 'font-family' : memberDescription.category_font, 'font-weight': memberDescription.category_weight}">{{slide_member.category}}</div>
                <div v-if="memberDescription.show_phone==1 && slide_member.phone && slide_member.phone.length>0" id="member-phone" :style="{'font-size': ((memberDescription.phone_height/100)*windowHeight)+'px', 'line-height':'100%', color: memberDescription.phone_color, 'font-family' : memberDescription.phone_font, 'font-weight': memberDescription.phone_weight}">{{slide_member.phone}}</div>
                <div v-if="memberDescription.show_email==1 && slide_member.email && slide_member.email.length>0" id="member-email" :style="{'font-size': ((memberDescription.email_height/100)*windowHeight)+'px', 'line-height':'100%', color: memberDescription.email_color, 'font-family' : memberDescription.email_font, 'font-weight': memberDescription.email_weight}">{{slide_member.email}}</div>
                <div v-if="memberDescription.show_website==1 && slide_member.website && slide_member.website.length>0" id="member-website" :style="{'font-size': ((memberDescription.website_height/100)*windowHeight)+'px', 'line-height':'100%', color: memberDescription.website_color, 'font-family' : memberDescription.website_font, 'font-weight': memberDescription.website_weight}">{{slide_member.website}}</div>
                <div v-if="memberDescription.show_category==1 && slide_member.category && slide_member.category.length>0  && memberDescription.type ==7" id="member-category" :style="{'font-size': ((memberDescription.category_height/100)*windowHeight)+'px', 'line-height':'100%', color: memberDescription.category_color, 'font-family' : memberDescription.category_font, 'font-weight': memberDescription.category_weight, 'margin-top': '10px'}">{{slide_member.category}}</div>
                </div>
              </VueDragResize>

              <VueDragResize id='next-box' :isActive="memberDescription.next.active" :sticks="['tm', 'bm']" v-on:activated="onActivated(memberDescription.next)" v-if="(memberDescription.type == 1 || memberDescription.type == 7 || memberDescription.type == 18) && memberDescription.selection>1 && (!preview || slide_member.next)" v-on:clicked="onMemberDescClick($event)" v-on:resizing="resize" v-on:dragging="resize" :x="(memberDescription.next.x_pos / 100) * windowWidth" :y="(memberDescription.next.y_pos / 100) * windowHeight"  :minh="5"  :h="memberDescription.next.height*windowHeight/100" :parentLimitation="true" :parentW="windowWidth" :parentH="windowHeight" class="draggable pointerDrag"
                             :style="{border: memberDescription.next.active || memberDescription.next.selected? 'dashed 1px #555' : 'dashed 1px transparent',left: memberDescription.next.x_pos+'%', top: memberDescription.next.y_pos+'%',color: memberDescription.next_color,'font-family' : memberDescription.next_font, width: 'fit-content', width: '-moz-fit-content', width: '-webkit-fit-content', height: (memberDescription.next.height*(memberDescription.next_number+1))+'%', 'text-align': 'center','font-size': ((memberDescription.next.height/100)*windowHeight)+'px', 'line-height':'100%'}">
                <i>{{(memberDescription.next_number==0? "" : ( memberDescription.next_number==1? $t('slide.next_presenter') : $t('slide.next_presenters') ))}}</i><br/>
                <div v-for="n in slide_member.next" :key="n.name"><i :style="{ 'font-weight': n==slide_member.next[0]? 'bold' : 'normal', 'font-size': n==slide_member.next[0]? ((memberDescription.next.height/100)*windowHeight)+'px' : ((memberDescription.next.height/100)*windowHeight)*.8+'px'}">{{n.name}}</i></div>
              </VueDragResize>
              <VueDragResize :isActive="timer.active" :sticks="['tm', 'bm']" v-on:clicked="onTimerClick($event)" v-on:activated="onActivated(timer)"  v-on:resizing="resize" v-on:dragging="resize" :x="(timer.x_pos / 100) * windowWidth" :y="(timer.y_pos / 100) * windowHeight" :minh="5" :h="timer.height*windowHeight/100" :parentLimitation="true" :parentW="windowWidth" :parentH="windowHeight" id="timer-text" v-on:dblclick="showTimeEdit" v-if="!timer.disabled && (timer.type==1 || (timer.type==2 && !preview))" class="draggable pointerDrag"
                             :style="{'z-index': 10,'-webkit-text-stroke': '1px '+timer.back_color, border: timer.active || timer.selected? 'dashed 1px #555' : 'dashed 1px transparent','font-size': ((timer.height/(100))*windowHeight)+'px','line-height':'100%',left: timer.x_pos+'%', top: timer.y_pos+'%',  height: timer.height+'%', color: timer.color, 'font-family' : timer.font, width: ((timer.height/(100))*windowHeight)*2.5+'px', 'text-align': 'right'}">
                {{getTimeString()}}
              </VueDragResize>
              <VueDragResize :isActive="timer.active" v-on:clicked="onTimerClick($event)" v-on:activated="onActivated(timer)"  v-on:resizing="resize" v-on:dragging="resize" :x="(timer.x_pos / 100) * windowWidth" :y="(timer.y_pos / 100) * windowHeight" :minh="5" :minw="5" :w="timer.width*windowWidth/100" :h="timer.height*windowHeight/100" :parentLimitation="true" :parentW="windowWidth" :parentH="windowHeight" id="timer-bar" v-on:dblclick="showTimeEdit" v-if="!timer.disabled && timer.type==0" class="draggable pointerDrag"
              :style="{'border-radius': timer.circular==0? '0%' : '50%', 'background': (timer.circular==0? ('linear-gradient(to right,'+timer.color+' '+(75+25*timer.direction)+'%,'+timer.back_color+' 25%)') : ('conic-gradient(from '+timer.circular_start+'deg,'+timer.color+' '+timer.circular_end*(timer.direction==0? 0.75 : 1)+'deg, '+timer.back_color+' '+timer.circular_end*(timer.direction==0? 0.75 : 1)+'deg '+timer.circular_end+'deg,rgba(0,0,0,0) '+timer.circular_end+'deg)')), border: timer.active || timer.selected? 'dashed 1px #555' : 'none', left: timer.x_pos+'%', top: timer.y_pos+'%', height: timer.height+'%', width: timer.width+'%',  'z-index':10}">
              </VueDragResize>
              <VueDragResize v-for="text in texts" :key="text.html_id" v-bind:id="text.html_id" :class="{verticalTextAlign: text.vertical_align==1}"
                             :isActive="text.active" v-on:clicked="onTextClick($event, text)" v-on:activated="onActivated(text)"  v-on:resizing="resize" v-on:dragging="resize" :x="(text.x_pos / 100) * windowWidth" :y="(text.y_pos / 100) * windowHeight" :minh="5" :h="text.height*windowHeight/100" :w="text.width*windowWidth/100" :parentLimitation="true" :parentW="windowWidth" :parentH="windowHeight" class="draggable pointerDrag textElement" 
                             :style="{'background-color': text.back_color,'z-index': text.z_pos, border: text.active || text.selected? 'dashed 1px #555' : 'solid 1px '+text.border_color, 'font-size': ((text.size/100)*windowHeight)+'px', 'line-height':(100*text.spacing)+'%',left: text.x_pos+'%', top: text.y_pos+'%',  height: text.height+'%', width: text.width+'%', color: text.color, 'font-family' : text.font, 'font-weight': text.weight, 'text-align': text.alignment, transform: 'rotate('+text.rotation+'deg)', borderRadius: text.ellipse==1? '50%' : ''}">
                    <div v-if="preview && !text.show_qr" v-html="text.var_text"></div>
                    <div v-if="preview && text.show_qr"><div v-if="text.var_text"><qr-code class="qr-block" :text="text.var_text"></qr-code><div id="linkedin-image" v-if="text.is_linkedin" ></div></div></div>
                    <div v-if="text.special.length==0 && !preview && text.text.length>0" v-html="text.text"></div>
                    <div v-if="text.special.length==0 && !preview && text.text.length==0">text</div>
                    <div v-if="text.special.length>0 && !preview">{{'{'+$t('slide.'+text.special+'_special')+'}'}}</div>
              </VueDragResize>
              <VueDragResize :isActive="graphic.active" :sticks="graphic.special.length>0 || graphic.fit==1?  ['tm', 'bm','ml','mr'] : ['tm', 'bm']" v-on:clicked="onGraphicClick($event, graphic)" v-on:activated="onActivated(graphic)"  v-on:resizing="resize" v-on:dragging="resize" :x="(graphic.x_pos / 100) * windowWidth" :y="(graphic.y_pos / 100) * windowHeight" :minh="5" :minw="3" :h="graphic.height*windowHeight/100" :w="graphic.width*windowWidth/100" :parentLimitation="true" :parentW="windowWidth" :parentH="windowHeight" class="draggable pointerDrag" v-for="graphic in graphics" :key="graphic.html_id"
                             v-bind:id="graphic.html_id" :style="{'text-align': 'center', 'z-index': graphic.z_pos, left: graphic.x_pos+'%', top: graphic.y_pos+'%',  height: graphic.height+'%',  width: '-moz-fit-content', width: '-webkit-fit-content', width: (graphic.special.length>0 || graphic.fit==1)? graphic.width+'%' : 'fit-content' , border: graphic.active || graphic.selected? 'dashed 1px #555' : 'dashed 1px transparent', transform: 'rotate('+graphic.rotation+'deg)'}">
                <img :style="{width: graphic.fit==1? '100%' : 'auto'}" v-if="graphic.special.length==0 || preview" :src="graphic.photo" />
                <div v-if="graphic.special.length>0 && !preview" :style="{'font-size': ((graphic.width/400)*windowHeight)+'px', 'line-height':(graphic.height*windowHeight/100)+'px', 'text-align': 'center'}">{{'{'+$t('slide.'+graphic.special+'_special')+'}'}}</div>
              </VueDragResize>
              <VueDragResize id="role-members" v-if="memberDescription.type==5 || memberDescription.type==15 || memberDescription.type==17 || memberDescription.type==19 || memberDescription.type==21 || memberDescription.type==22"
              :isActive="memberDescription.active" v-on:activated="onActivated(memberDescription)" v-on:clicked="onMemberDescClick($event)" v-on:resizing="resize"  v-on:dragging="resize" :x="(memberDescription.x_pos / 100) * windowWidth" :y="(memberDescription.y_pos / 100) * windowHeight" :h="memberDescription.height*windowHeight/100" :w="memberDescription.width*windowWidth/100" :parentLimitation="true" :parentW="windowWidth" :parentH="windowHeight" class="draggable pointerDrag"
                             :style="{'text-align': memberDescription.alignment,border: memberDescription.active || memberDescription.selected? 'dashed 1px #555' : 'dashed 1px transparent',left: memberDescription.x_pos+'%', top: memberDescription.y_pos+'%', height: memberDescription.height+'%', width: memberDescription.width+'%','grid-template-columns': 'repeat('+Math.min(memberDescription.num_rows_x, slide_members.length)+', 1fr)','grid-template-rows': 'repeat('+Math.min(memberDescription.num_rows_y,Math.ceil(slide_members.length/memberDescription.num_rows_x))+', 1fr)'}">
                <div  class="slide-role-member" :style="{'grid-template-rows': memberDescription.multi_vertical==1?  memberDescription.height+'% '+(100-memberDescription.height)+'%' : '100%', 'grid-template-columns': memberDescription.multi_vertical==0?  memberDescription.height+'% '+(100-memberDescription.height)+'%' : '100%'}"  v-for="member in slide_members.slice(0, Math.min(slide_members.length, memberDescription.num_rows_x*memberDescription.num_rows_y))" :key="member">
                 <!---->
                  <!--<div class="role-image-wrapper" >
                    <div class="role-image-div">
                      <div v-if="(member.is_network ==1 || member.is_renewing==1) && slide.show_recognition==1" class="members-banner" :style="{'width': (windowHeight*(memberDescription.height/(100*Math.min(memberDescription.num_rows_y,Math.ceil(slide_members.length/memberDescription.num_rows_x))) )-40)*.8*0.6157+'px', 'z-index': '10', 'font-size': (.04*memberDescription.height/(100*Math.min(memberDescription.num_rows_y,Math.ceil(slide_members.length/memberDescription.num_rows_x))))*windowHeight+'px'}">{{member.is_network ==1? $t('slide.network_leader') : $t('slide.renewing_member')}}</div>
                      <img v-if="settings.show_bni_accent==1" class="member-corner-image"  src="../assets/Corner.png"/>
                      <img class="member-role-image"  :src="preview? member.photo : getImgUrl('default-short.jpg')" />
                      <div v-if="member.is_speaker ==1" class="members-banner " :style="{'width': (windowHeight*(memberDescription.height/(100*Math.min(memberDescription.num_rows_y,Math.ceil(slide_members.length/memberDescription.num_rows_x))) )-40)*.8*0.6157+'px', 'bottom': '0','font-size': (.04*memberDescription.height/(100*Math.min(memberDescription.num_rows_y,Math.ceil(slide_members.length/memberDescription.num_rows_x))))*windowHeight+'px'}">{{$t('slide.featured_speaker')}}</div>
                    </div>
                  </div>-->
                  <div v-if="!preview || member.photo" class="role-image-wrapper-2" :style="multiMemberStyle">
                    <img v-if="settings.show_bni_accent==1 && memberDescription.show_accent!=0" style="z-index:0;" class="member-corner-image"  :src="isCorporateConnections? getImgUrl(memberDescription.show_accent==1?'Picture-CC-backYellow.png' : 'Picture-CC-backGray.png') : getImgUrl('Corner.png')"/>
                    <div class="role-image-div">
                      <div v-if="!isCorporateConnections && (member.is_network ==1 || member.is_renewing==1) && slide.show_recognition==1" class="members-banner" :style="{'width': (windowHeight*(memberDescription.height/(100*Math.min(memberDescription.num_rows_y,Math.ceil(slide_members.length/memberDescription.num_rows_x))) )-40)*.8*0.6157+'px', 'z-index': '10', 'font-size': (.04*memberDescription.height/(100*Math.min(memberDescription.num_rows_y,Math.ceil(slide_members.length/memberDescription.num_rows_x))))*windowHeight+'px'}">{{member.is_network ==1? $t('slide.network_leader') : $t('slide.renewing_member')}}</div>
                      <div :class="{'member-role-image-2': memberDescription.circular==0, 'circular-member': memberDescription.circular==1}" :style="{'background-image': preview? 'url('+member.photo+')' : 'url('+getImgUrl('default-short.jpg')+')'}"  ></div>
                      <div v-if="!isCorporateConnections && member.is_speaker ==1" class="members-banner " :style="{'width': (windowHeight*(memberDescription.height/(100*Math.min(memberDescription.num_rows_y,Math.ceil(slide_members.length/memberDescription.num_rows_x))) )-40)*.8*0.6157+'px', 'bottom': '0','font-size': (.04*memberDescription.height/(100*Math.min(memberDescription.num_rows_y,Math.ceil(slide_members.length/memberDescription.num_rows_x))))*windowHeight+'px'}">{{$t('slide.featured_speaker')}}</div>
                    </div>
                  </div>
                  <div v-if="preview && !member.photo"></div>
                  <div :class="{'fit-width': memberDescription.multi_vertical==1}" class="role-image-name" :style="{margin: memberDescription.multi_vertical==0? 'auto' : '','background-color': memberDescription.name_back_color, 'line-height':'100%', 'text-align': memberDescription.multi_vertical==1? 'center' : 'left', width:'100%'}">
                    <span :style="{ 'line-height':'100%','font-size': ((memberDescription.name_height/(100*Math.min(memberDescription.num_rows_y,Math.ceil(slide_members.length/memberDescription.num_rows_x))))*windowHeight)+'px', color: memberDescription.name_color, 'font-family' : memberDescription.name_font, 'font-weight': memberDescription.name_weight}">{{preview? (member.name && memberDescription.first_name>0? member.name.split(" ")[0] : member.name) : $t('slide.text_text')}}</span>
                    <br/><span v-if="memberDescription.type==17 || memberDescription.type==19 || memberDescription.type==21 || memberDescription.type==22 || memberDescription.show_renewing_date==1 || (memberDescription.type==15 && memberDescription.member_id>3)" :style="{ 'line-height':'100%','font-size': ((memberDescription.category_height/(100*Math.min(memberDescription.num_rows_y,Math.ceil(slide_members.length/memberDescription.num_rows_x))))*windowHeight)+'px', color: memberDescription.category_color, 'font-family' : memberDescription.category_font, 'font-weight': memberDescription.category_weight}" v-html="member.category"></span>
                    <!--<div v-if="preview && member.linkedin_url && memberDescription.multi_vertical==0" style="width: 100%;padding-bottom: 100%;position: relative;margin-top:10px;"><qr-code class="qr-block" :text="member.linkedin_url"></qr-code><div id="linkedin-image"></div></div>-->
                  </div>
                </div>
              </VueDragResize>
            </div>
        </div>
        <div style=" text-align: center;"><span class="form-box-name">{{$t('slide.presenter_ratio')}}: {{ratioNames[settings.edit_ratio]}}</span></div>

      </div>
    </div>
    <b-modal centered id="slide-modal-2" v-model="showTextEdit" :title="$t('slide.text_title')" >
      <form id="text-form" v-on:submit.prevent="updateSlide">
        <div id="text-box">
          <div >
            <span class="form-box-title">{{$t('slide.text_block')}}</span>
            <div class="form-box" style="grid-template-columns: 80px auto;">
              <span class="form-box-name">{{$t('slide.font_text')}}</span><b-form-select v-model="editTextObject.font" :options="fontOptions"/>
              <span class="form-box-name">{{$t('slide.size_text')}}</span>
              <div style="display:grid; grid-template-columns: repeat(4,auto);">
                <b-form-input v-model="editTextObject.size" :disabled="editTextObject.fit==1" min="1" max="100" type="number" style="max-width: 100px;" required></b-form-input>
                <b-form-checkbox style="margin:auto;"  v-model="editTextObject.fit"  value="1" unchecked-value="0">
                    {{$t('slide.fit_text')}}
                  </b-form-checkbox>
                <span class="form-box-name">{{$t('slide.spacing_text')}}</span><b-form-input v-model="editTextObject.spacing" min="1" max="100" type="number"  required></b-form-input>
              </div>
              <span class="form-box-name">{{$t('slide.align_text')}}</span>
              <div style="display:grid; grid-template-columns: repeat(4,auto);">
                <span class="form-box-name">{{$t('slide.horizontal_text')}}</span>
                <b-form-select v-model="editTextObject.alignment" :options="alignOptions"/>
                <span class="form-box-name">{{$t('slide.vertical_text')}}</span>
                <b-form-select v-model="editTextObject.vertical_align" :options="vAlignOptions"/>
              </div>
              
              <div v-if="editTextObject.special.length==0" class="editor" style="grid-column:1/3; width: 100%;">
                  <editor-menu-bar :editor="editor">
                    <div class="menubar" slot-scope="{ commands, isActive }">
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.bold() }" v-on:click="commands.bold">
                        <b>B</b>
                      </b-button>
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.italic() }" v-on:click="commands.italic">
                        <i>I</i>
                      </b-button>
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.strike() }" v-on:click="commands.strike">
                        <s>S</s>
                      </b-button>
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.underline() }" v-on:click="commands.underline">
                        <u>U</u>
                      </b-button>
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.paragraph() }" v-on:click="commands.paragraph">
                        P
                      </b-button>
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.superscript() }" v-on:click="commands.superscript()">
                        super<sup>script</sup>
                      </b-button>
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.bullet_list() }" v-on:click="commands.bullet_list">
                        {{$t('entry.bullet_text')}}
                      </b-button>
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.ordered_list() }" v-on:click="commands.ordered_list">
                        {{$t('entry.list_text')}}
                      </b-button>

                      
                      <b-button variant="outline-dark"  v-on:click="commands.setcolor({ color: text_color })">
                        <b-icon-brush></b-icon-brush>{{$t('entry.set_text')}}
                      </b-button>
                       <colorpicker :color="text_color" v-model="text_color" :top="false"/>
                      <b-button variant="outline-dark" v-on:click="commands.undo">
                        {{$t('entry.undo_text')}}
                      </b-button>
                      <b-button variant="outline-dark" v-on:click="commands.redo">
                        {{$t('entry.redo_text')}}
                      </b-button>
                    </div>
                  </editor-menu-bar>
                  <editor-content class="editor__content" :editor="editor" style="height:190px;width:100%;overflow-y: auto;overflow-x: hidden;" />
                </div>
              
              
            </div>
          </div>
          <div v-if="editTextObject.special.length==0" id="variables-box">
            <span class="form-box-title">{{$t('slide.insert_variables')}}</span>
            <div class="form-box" style="grid-template-columns: auto;">
              <div>
                  <b-form-checkbox-group style="display: grid;" v-model="variableGroup" :options="groupOptions" value-field="value" text-field="name" >
                  </b-form-checkbox-group>
                </div>
                <div style="height: 221px; overflow-y: auto; margin-top: 10px;">
                  <table class="table b-table table-hover-new table-bordered table-sm">
                    <tbody>
                      <tr class="variable-row" v-for="variable in variable_map.filter(v => v.group == variableGroup)" v-on:click="addTextVariable(variable)" :key="variable.id">
                        <td>{{ variable.name }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              <div style="font-size: 10px;color: #777;">{{$t('slide.add_entry')}}</div>
            </div>
          </div>
          <div style="grid-column: 1/3;">
            <span class="form-box-title">{{$t('slide.appearance_text')}}</span>
            <div class="form-box" style="grid-template-columns: repeat(6,auto);">
              <div style="display:grid;grid-template-columns: max-content max-content; grid-column: 1/7;margin:auto;grid-gap:20px;" class="fit-width">
              <b-form-checkbox  v-model="editTextObject.ellipse"  value="0" unchecked-value="1">
                  {{$t('slide.square_text')}}
                </b-form-checkbox>
                <b-form-checkbox  v-model="editTextObject.ellipse" value="1" unchecked-value="0">
                  {{$t('slide.ellipse_text')}}
                </b-form-checkbox>
              </div>
                <span class="form-box-name">{{$t('slide.color_text')}}</span><colorpicker style="width:150px;max-width: 200px;margin: auto auto auto 0px; height:30px;" :color="editTextObject.color" v-model="editTextObject.color" />
                <span class="form-box-name">{{$t('slide.x_pos')}}</span>
                <b-form-input style="margin: 5px 0px;" v-model="editTextObject.x_pos" min="0" max="100" type="number" required></b-form-input>
                <span class="form-box-name">{{$t('slide.y_pos')}}</span>
                <b-form-input style="margin: 5px 0px;" v-model="editTextObject.y_pos" min="0" max="100" type="number" required></b-form-input>
                <span class="form-box-name">{{$t('slide.back_color')}}</span><colorpicker style="width:150px;max-width: 200px;margin: auto auto auto 0px; height:30px;" :color="editTextObject.back_color" v-model="editTextObject.back_color" />
                <span class="form-box-name">{{$t('slide.timer_width')}}</span>
                <b-form-input style="margin: 5px 0px;" v-model="editTextObject.width" min="0" max="100" type="number" required></b-form-input>
                <span class="form-box-name">{{$t('slide.height_text')}}</span>
                <b-form-input style="margin: 5px 0px;" v-model="editTextObject.height" min="0" max="100" type="number" required></b-form-input>
                <span class="form-box-name">{{$t('slide.border_color')}}</span><colorpicker style="width:150px;max-width: 200px;margin: auto auto auto 0px; height:30px;" :color="editTextObject.border_color" v-model="editTextObject.border_color" />
                <span class="form-box-name">{{$t('slide.rotation_text')}}</span>
                <b-form-input v-model="editTextObject.rotation" type="number" style="max-width: 100px;" required></b-form-input>
            </div>
          </div>
        </div>
      </form>
      <div v-if="editTextObject.special.length>0" style="font-size:12px; color: #aaa;">
        *{{$t('slide.special_text_note')}}
      </div>
       <div v-if="showTextAlert" >
          <b-alert variant="danger"
                   dismissible
                   :show="showTextAlert"
                   @dismissed="showTextAlert=false">
            {{$t("slide.text_alert")}}
          </b-alert>
        </div>
      <div slot="modal-footer" class="submit-box-2">
        <b-button class="submit-button4" v-if="editTextObject.special.length==0" v-on:click="deleteText" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
        <b-button class="submit-button4"  v-on:click="setTextFromEditor" variant="clear-blue"><div class="ok-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
      </div>
    </b-modal>
    <b-modal centered id="slide-modal" v-model="showGraphicEdit" :title="$t('slide.graphic_title')">
      <form id="graphic-form" v-on:submit.prevent="updateSlide">
        <div id="graphic-box">
          <div>
            <span class="form-box-title">{{$t('slide.graphic_block')}}</span>
            <div class="form-box" style="grid-template-columns: 25% 25% 25% 25%;">
              <span class="form-box-name">{{$t('slide.graphic_xpos')}}</span>
              <b-form-input style="margin: 5px 0px;" v-model="editGraphicObject.x_pos" min="0" max="100" type="number" required></b-form-input>
              <span class="form-box-name">{{$t('slide.graphic_ypos')}}</span>
              <b-form-input style="margin: 5px 0px;" v-model="editGraphicObject.y_pos" min="0" max="100" type="number" required></b-form-input>
              <!--<span class="form-box-name">Width</span>
            <b-form-input style="margin: 5px 0px;" v-model="editGraphicObject.width" type="number" required></b-form-input>-->
              <span class="form-box-name">{{$t('slide.graphic_height')}}</span>
              <b-form-input style="margin: 5px 0px;" v-model="editGraphicObject.height" min="1" max="100" type="number" required></b-form-input>
              <span class="form-box-name">{{$t('slide.rotation_text')}}</span>
                <b-form-input v-model="editGraphicObject.rotation" type="number" style="max-width: 100px;" required></b-form-input>
              <b-form-checkbox style="grid-column:3/5;margin:auto;"  v-model="editGraphicObject.fit" value="1" unchecked-value="0">
                  {{$t('slide.fit_graphic')}}
                </b-form-checkbox>
            </div>
          </div>
          <div v-if="editGraphicObject.special.length == 0">
            <span class="form-box-title">{{$t('slide.graphic_photo')}}</span>
            <div class="form-box" style="grid-template-columns: auto;">
              <b-button class="submit-button4" v-on:click="openDialog2" variant="blue" style="width:200px;margin:auto;">{{$t('slide.upload_photo')}}</b-button>
              <b-form-file id='fileid2' accept="image/jpeg, image/png, image/gif" @change="setImage($event)" plain hidden></b-form-file>
                <div style="display:grid; grid-template-columns:min-content 350px auto;">
                    <div v-if="imgType  != 'image/gif'" style="display:grid;grid-template-rows: repeat(4, max-content);grid-gap:10px;padding-top:80px;">
                      <b-button v-if="imgSrc && imgSrc.length>0" v-on:click="rotateImageRight" class="image-button" variant="clear-blue"><div class="rotate-left-button"></div></b-button>
                      <b-button v-if="imgSrc && imgSrc.length>0" v-on:click="rotateImageLeft" class="image-button" variant="clear-blue"><div class="rotate-right-button"></div></b-button>
                      <b-button v-if="imgSrc && imgSrc.length>0" v-on:click="flipImageX()"  class="image-button" variant="clear-blue"><div class="flip-y-button"></div></b-button>
                      <b-button v-if="imgSrc && imgSrc.length>0" v-on:click="flipImageY()" class="image-button" variant="clear-blue"><div class="flip-x-button"></div></b-button>
                    </div>
                    <div v-if="imgType  != 'image/gif'"  style="width: 300px; height:300px; margin: 20px auto; border: 1px solid gray; display: inline-block;">
                      <vue-cropper ref='cropper'
                                   :guides="true"
                                   :view-mode="2"
                                   drag-mode="crop"
                                   :auto-crop="true"
                                   :auto-crop-area="1"
                                   :min-container-width="250"
                                   :min-container-height="180"
                                   :background="true"
                                   :rotatable="true"
                                   :src="imgSrc"
                                   :cropmove="cropMove"
                                   alt="Source Image"
                                   :img-style="{ 'width': '300px', 'height': '300px' }">
                      </vue-cropper>
                    </div>
                    <img style="margin:auto;" id="edit-graphic-image" v-show="editGraphicObject.photo" :src="editGraphicObject.photo" />
                    <div v-if="imgType  != 'image/gif'"  style="grid-column:2/3;font-size: 12px; text-align:center;margin-bottom:20px;">{{$t('member.zoom_text')}}</div>
                  </div>
            </div>
          </div>
        </div>
      </form>
      <div v-if="editGraphicObject.special.length>0" style="font-size:12px; color: #aaa;">
        *{{$t('slide.special_text_note')}}
      </div>
      <div slot="modal-footer" class="submit-box-2">
        <b-button class="submit-button4" v-if="editGraphicObject.special.length==0" v-on:click="deleteGraphic" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
        <b-button class="submit-button4" v-on:click="showGraphicEdit=false" variant="clear-blue"><div class="ok-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
      </div>
    </b-modal>
    <b-modal centered id="slide-modal" v-model="showSettings" :title="$t('slide.slide_settings')">
      <form id="settings-form" v-on:submit.prevent="updateSlide">
        <div id="setting-box">
          <div>
            <div>
              <span class="form-box-title">{{$t('slide.general_text')}}</span>
              <div class="form-box" style="grid-template-columns: auto auto auto;">
                <span class="form-box-name">{{$t('slide.transition_in')}}</span>
                <b-form-select v-model="slide.trans_in" :options="transInOptions" class="mb-3 form-box-input" />
                <span v-if="languageOptions.length>1" class="form-box-name">{{$t('slide.presentation_language')}}</span><b-form-select v-if="languageOptions.length>1" v-model="slide.language" :options="languageOptions" class="form-box-input" value-field="abbrev" text-field="name"/>
                <span style="grid-column: 1/4;font-size: 11px;text-align: right;margin-bottom:20px;">*{{$t('slide.language_note')}}</span>
                <span v-if="isCorporateConnections" class="form-box-name">{{$t('slide.meeting_type')}}</span>
                <b-form-select v-if="isCorporateConnections" v-model="slide.meeting_type" :options="meetingTypeOptions" class="mb-3 form-box-input" />
                <b-form-checkbox v-model="slide.disabled" class="form-box-input" style="grid-column-start: 1;"
                                 value="1"
                                 unchecked-value="0">
                  {{$t('slide.disable_slide')}}
                </b-form-checkbox>
                <b-form-checkbox v-if="permissions.organization.is_parent" v-model="slide.child_edit" class="form-box-input" style="grid-column-start: 1;"
                                 value="1"
                                 unchecked-value="0">
                  {{$t('slide.child_edit')}}
                </b-form-checkbox>
              </div>
            </div>
            <div id="display-box">
              <span class="form-box-title">{{$t('slide.after_displaying')}}</span>
              <div class="form-box" style="grid-template-columns: 40% 30% 30%;">
                <span v-if="slide.then_action!=3" class="form-box-name">{{$t('slide.pause_for')}}</span>
                <b-form-input v-if="slide.then_action!=3" v-model="slide.pauseSeconds" type="number" style="max-width: 100px;" required />
                <span v-if="slide.then_action!=3" class="form-box-name" style="text-align:left;">{{$t('slide.seconds_text')}}</span>
                <div id="then-text" v-if="slide.then_action!=3">{{$t('slide.then_text')}}</div>
                <b-form-checkbox class="setting-check" v-model="slide.then_action"
                                 value="1" unchecked-value="2">
                  {{$t('slide.replay_slide')}}
                </b-form-checkbox>
                <b-form-input v-if="slide.then_action==1" v-model="slide.replay_times" type="number" style="max-width: 100px;" required />
                <span v-if="slide.then_action==1" class="form-box-name" style="text-align:left;">{{$t('slide.times_text')}}</span>

                <b-form-checkbox class="setting-check" v-model="slide.then_action" value="2" unchecked-value="3">{{$t('slide.next_slide')}}</b-form-checkbox>
                <b-form-checkbox style="margin: 10px 0px;  grid-column-start: 1; grid-column-end: 4;" v-model="slide.then_action"
                                 value="3" unchecked-value="2">
                  {{$t('slide.wait_controller')}}
                </b-form-checkbox>
              </div>
            </div>
            
          </div>
          <div style="display: grid;grid-template-rows: min-content;">
            <div id="backgroud-box">
              <span class="form-box-title">{{$t('slide.background_text')}}</span>
              <div class="form-box" style="grid-template-columns: auto;">
                <b-form-checkbox class="setting-check" v-model="slide.back_type"
                                 value="1" unchecked-value="4">
                  {{$t('slide.solid_color')}}
                </b-form-checkbox>
                <div v-if="slide.back_type==1" style="display: grid;grid-template-columns: 30% 70%;">
                  <span class="form-box-name">{{$t('slide.color_text')}}</span>
                  <colorpicker style="max-width: 200px;margin: auto auto auto 0px; height:30px;" :color="slide.back_colour" v-model="slide.back_colour" />
                </div>

                <b-form-checkbox class="setting-check" v-model="slide.back_type" value="2" unchecked-value="1">{{$t('slide.color_gradient')}}</b-form-checkbox>
                <div v-if="slide.back_type==2" style="display: grid;grid-template-columns: 31% 22% 25% 22%;">
                <span class="form-box-name">{{$t('slide.from_color')}}</span>
                  <colorpicker style="max-width: 200px;margin: auto auto auto 0px; height:30px;" :color="slide.back_colour_from" v-model="slide.back_colour_from" />
                  <span class="form-box-name">{{$t('slide.to_color')}}</span>
                  <colorpicker style="max-width: 200px;margin: auto auto auto 0px; height:30px;" :color="slide.back_colour_to" v-model="slide.back_colour_to" />
                  <span class="form-box-name">{{$t('slide.type_text')}}</span>
                  <b-form-select v-model="slide.back_grad_type" :options="gradOptions" class="mb-3 form-box-input" style="grid-column-end: 5;" />
                </div>
                <b-form-checkbox class="setting-check" v-model="slide.back_type"
                                 value="3" unchecked-value="1">
                  {{$t('slide.upload_image')}}
                </b-form-checkbox>
                <div v-if="slide.back_type==3">
                  <b-img v-show="slide.photo" id="slide-photo" :src="slide.photo" />
                  <b-button style="margin: auto 20px;width:150px;" class="submit-button4" v-on:click="openDialog" variant="blue">{{$t('slide.upload_image')}}</b-button>
                  <b-form-file id='fileid' v-model="slide.photo" accept="image/jpeg, image/png, image/gif" @change="onFileChange($event,slide)" plain hidden></b-form-file>
                </div>
                <b-form-checkbox class="setting-check" v-model="slide.back_type" value="4" unchecked-value="1">
                  {{$t('slide.select_library')}}
                </b-form-checkbox>
                <div v-if="slide.back_type==4" style="overflow-x: auto;overflow-y: hidden;display: flex;flex-wrap: nowrap;">
                  <div v-for="back in backgrounds" style="padding:10px;" :key="back">
                    <b-img v-on:click="setBack(back)" class="back-photo" :src="getImgUrl('backgrounds/'+back)" :alt="back" rounded></b-img>
                    <span style="color:#333; font-size:10px;">{{back.split(".")[0]}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style=" grid-column-start: 1; grid-column-end: 3;">
          <b-alert variant="danger"
                   dismissible
                   :show="showRoleAlert"
                   @dismissed="showRoleAlert=false">
            {{alertText}}
          </b-alert>
        </div>
      </form>
      <!--doesn't work in ie?-->
      <div slot="modal-footer" class="submit-box-2">
        <b-button class="submit-button4" v-on:click="showSettings=false" variant="clear-blue"><div class="ok-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
      </div>
    </b-modal>
    <b-modal centered id="slide-modal-3" v-model="showMembers" :title="$t('slide.members_title')">
      <form id="members-form" v-on:submit.prevent="updateSlide">
        <div id="members-box">
          <div>
            <div>
              <span class="form-box-title" >{{$t('slide.members_show')}}</span>
              <div class="form-box" style="grid-template-columns: auto;">
                <div>
                  <span class="form-box-name">{{$t('slide.category_text')}}</span>
                  <b-form-select style="margin: 5px 0px;" v-on:change="filterChanged" v-model="typeFilter" :options="typeFilters" class="mb-3 " />
                </div>
                <b-form-checkbox v-if="typeFilter==0" class="setting-check" v-model="memberDescription.type" :value="0">{{$t('slide.members_show1')}}</b-form-checkbox>
                <b-form-checkbox v-if="typeFilter==8" class="setting-check" v-model="memberDescription.type" :value="8" :unchecked-value="0">{{$t('slide.members_show9')}}<img class="controller-select" src="../assets/tiny-ControllerSelect.png" /></b-form-checkbox>
                <b-form-checkbox v-if="typeFilter==1 && !permissions.organization.is_parent" class="setting-check" v-model="memberDescription.type" :value="4" :unchecked-value="0">{{$t('slide.members_show5')}}</b-form-checkbox>
                <b-form-checkbox v-if="typeFilter==1" class="setting-check" v-model="memberDescription.type" :value="2" :unchecked-value="0">{{$t('slide.members_show3')}}</b-form-checkbox>
                <b-form-checkbox v-if="typeFilter==1" class="setting-check" v-model="memberDescription.type" :value="1" :unchecked-value="0">{{$t('slide.members_show2')}}<img class="controller-select" src="../assets/tiny-ControllerSelect.png" /></b-form-checkbox>
                <b-form-checkbox v-if="typeFilter==1" class="setting-check" v-model="memberDescription.type" :value="6" :unchecked-value="0">{{$t('slide.members_show7')}}</b-form-checkbox>
                <b-form-checkbox v-if="typeFilter==1" class="setting-check" v-model="memberDescription.type" :value="5" :unchecked-value="0">{{$t('slide.members_show6')}}</b-form-checkbox>
                <b-form-checkbox v-if="typeFilter==1" class="setting-check" v-model="memberDescription.type" :value="14" :unchecked-value="0">{{$t('slide.members_show15')}}<img class="controller-select" src="../assets/tiny-ControllerSelect.png" /></b-form-checkbox>
                <b-form-checkbox v-if="typeFilter==1" class="setting-check" v-model="memberDescription.type" :value="15" :unchecked-value="0">{{$t('slide.members_show16')}}</b-form-checkbox>
                <b-form-checkbox v-if="typeFilter==3" class="setting-check" v-model="memberDescription.type" :value="3" :unchecked-value="0">{{$t('slide.members_show4')}}<img class="controller-select" src="../assets/tiny-ControllerSelect.png" /></b-form-checkbox>
                <b-form-checkbox v-if="typeFilter==3" class="setting-check" v-model="memberDescription.type" :value="19" :unchecked-value="0">{{$t('slide.members_show20')}}</b-form-checkbox>
                <b-form-checkbox v-if="typeFilter==2" class="setting-check" v-model="memberDescription.type" :value="7" :unchecked-value="0">{{$t('slide.members_show8')}}<img class="controller-select" src="../assets/tiny-ControllerSelect.png" /></b-form-checkbox>
                <b-form-checkbox v-if="typeFilter==5" class="setting-check" v-model="memberDescription.type" :value="10" :unchecked-value="0">{{$t('slide.members_show11')}}<img class="controller-select" src="../assets/tiny-ControllerSelect.png" /></b-form-checkbox>
                <b-form-checkbox v-if="typeFilter==5" class="setting-check" v-model="memberDescription.type" :value="13" :unchecked-value="0">{{$t('slide.members_show14')}}</b-form-checkbox>
                <b-form-checkbox v-if="typeFilter==5" class="setting-check" v-model="memberDescription.type" :value="11" :unchecked-value="0">{{$t('slide.members_show12')}}</b-form-checkbox>
                <b-form-checkbox v-if="typeFilter==6" class="setting-check" v-model="memberDescription.type" :value="12" :unchecked-value="0">{{$t('slide.members_show13')}}<img class="controller-select" src="../assets/tiny-ControllerSelect.png" /></b-form-checkbox>
                <b-form-checkbox v-if="typeFilter==4  && !permissions.organization.is_parent" class="setting-check" v-model="memberDescription.type" :value="9" :unchecked-value="0">{{$t('slide.members_show10')}}</b-form-checkbox>
                <b-form-checkbox v-if="typeFilter==7" class="setting-check" v-model="memberDescription.type" :value="16" :unchecked-value="0">{{$t('slide.members_show17')}}<img class="controller-select" src="../assets/tiny-ControllerSelect.png" /></b-form-checkbox>
                <b-form-checkbox v-if="typeFilter==4" class="setting-check" v-model="memberDescription.type" :value="17" :unchecked-value="0">{{$t('slide.members_show18')}}</b-form-checkbox>
                <b-form-checkbox v-if="typeFilter==4" class="setting-check" v-model="memberDescription.type" :value="18" :unchecked-value="0">{{$t('slide.members_show19')}}<img class="controller-select" src="../assets/tiny-ControllerSelect.png" /></b-form-checkbox>
                <b-form-checkbox v-if="typeFilter==9" class="setting-check" v-model="memberDescription.type" :value="20" :unchecked-value="0">{{$t(permissions.organization.rst? 'slide.members_show21rst' : 'slide.members_show21')}}<img class="controller-select" src="../assets/tiny-ControllerSelect.png" /></b-form-checkbox>
                <b-form-checkbox v-if="typeFilter==9" class="setting-check" v-model="memberDescription.type" :value="22" :unchecked-value="0">{{$t(permissions.organization.rst? 'slide.members_show23rst' : 'slide.members_show23')}}<img class="controller-select" src="../assets/tiny-ControllerSelect.png" /></b-form-checkbox>
                <b-form-checkbox v-if="typeFilter==9" class="setting-check" v-model="memberDescription.type" :value="21" :unchecked-value="0">{{$t(permissions.organization.rst? 'slide.members_show22rst' : 'slide.members_show22')}}</b-form-checkbox>
              </div>
            </div>
            <div  v-if="[1,2,3,4,5,6,7,9,13,14,15,17,18,19,21,22].indexOf(memberDescription.type) !== -1">
              <span class="form-box-title">{{$t('slide.options_text')}}</span>
              <div class="form-box" style="grid-template-columns: auto;">
                <div v-show="memberDescription.type ==1" >
                  <b-form-checkbox-group class="form-box-input" v-model="memberDescription.selection" :options="selectionOptions">
                  </b-form-checkbox-group>
                  <div v-if="memberDescription.selection>1" style="display:grid; grid-template-columns: max-content auto;">
                    <span  class="form-box-name">{{$t('slide.next_number')}}</span>
                    <b-form-select style="width:70px;"  v-model="memberDescription.next_number" :options="nextOptions" value-field="id" text-field="name" />
                  </div>
                  <b-form-checkbox class="setting-check" v-model="memberDescription.best_weekly" :value="1" :unchecked-value="0">{{$t('slide.assign_best_weekly')}}</b-form-checkbox>
                  <br/>
                </div>
                <div v-if="memberDescription.type==3">
                  <span  style="text-align:left;" class="form-box-name" >{{$t('slide.default_speaker')}}</span>
                  <b-form-input  v-model="memberDescription.speaker_num" type="number" style="max-width: 100px;" required  />
                </div>
                <div v-if="memberDescription.type==4">
                  <b-form-select  v-model="memberDescription.member_id" :options="members" class="mb-3 form-box-input" value-field="id" text-field="name" />
                </div>
                <div v-if="memberDescription.type==5" >
                  <!--<b-form-select  v-model="memberDescription.role_id" :options="multiRoles" class="form-box-input" value-field="id" text-field="name" />
                  <div  class="role-count">{{role_num}} {{$t('slide.members_match')}}</div>-->
                  
                  <multiselect :noOptions="$t('global.empty_list')" :class="{blueselect:true}" :selectedLabel="$t('global.selected_text')" selectLabel="" deselectLabel="" v-model="member_roles" :options="multiRoles" :multiple="true" :close-on-select="false"  :preserve-search="true" :placeholder="$t('slide.select_roles')" label="name" track-by="id" :preselect-first="false">
                  </multiselect>
                  <b-form-checkbox class="setting-check" v-model="memberDescription.show_renewing_date" :value="1" :unchecked-value="0">{{$t('slide.show_role_name')}}</b-form-checkbox>
                  <span style="text-align:left;" class="form-box-name" >{{$t('slide.multi_role')}}</span>
                  <b-form-select  v-model="memberDescription.alternate_slide_id" :options="slides" value-field="id" text-field="name" />
                </div>
                <div v-if="memberDescription.type==6">
                  <b-form-select  v-model="memberDescription.role_id" :options="singularRoles" class="form-box-input" value-field="id" text-field="name" />
                  <div  class="role-count">{{$tc('slide.is_member_role', role_num>0? 1 : 0)}}</div>
                </div>
                <div v-show="memberDescription.type ==7 || memberDescription.type == 18" >
                  <b-form-checkbox-group style="margin: auto auto auto 5px;" class="form-box-input" v-model="memberDescription.selection" :options="selectionOptions.filter(v=>v.value<4)">
                  </b-form-checkbox-group>
                  <div v-if="memberDescription.selection>1" style="display:grid; grid-template-columns: max-content auto;">
                    <span  class="form-box-name">{{$t('slide.next_number')}}</span>
                    <b-form-select style="width:70px;"  v-model="memberDescription.next_number" :options="nextOptions" value-field="id" text-field="name" />
                  </div>
                </div>
                <div v-if="memberDescription.type==13" >
                  <b-form-select v-model="memberDescription.member_id" :options="tracking_nums" class="mb-3"  />
                </div>
                <div v-if="memberDescription.type==9">
                  <b-form-select  v-model="memberDescription.member_id" :options="contacts" class="mb-3 form-box-input" value-field="id" text-field="name" />
                </div>
                <div v-if="memberDescription.type==15">
                  <b-form-select  v-model="memberDescription.member_id" :options="recognitionTypes.filter(v => v.id != 1)" class="mb-3 form-box-input" value-field="id" text-field="name" />
                    <b-form-checkbox v-if="region.allow_renewing_date==1" class="setting-check" v-model="memberDescription.show_renewing_date" :value="1" :unchecked-value="0">{{$t('slide.show_renewing_date')}}</b-form-checkbox>
                  <span  style="text-align:left;" class="form-box-name" >{{$t('slide.multi_recog')}}</span>
                  <b-form-select    v-model="memberDescription.alternate_slide_id" :options="slides" value-field="id" text-field="name" />
                  
                </div>
                <div v-if="memberDescription.type==14">
                  <b-form-select  v-model="memberDescription.member_id" :options="recognitionTypes.filter(v => v.id <=3)" class="mb-3 form-box-input" value-field="id" text-field="name" />
                  <b-form-checkbox v-if="region.allow_renewing_date==1" class="setting-check" v-model="memberDescription.show_renewing_date" :value="1" :unchecked-value="0">{{$t('slide.show_renewing_date')}}</b-form-checkbox>
                </div>
                <div v-if="memberDescription.type==17" >
                  <multiselect :noOptions="$t('global.empty_list')" :class="{blueselect:true}" :selectedLabel="$t('global.selected_text')" selectLabel="" deselectLabel="" v-model="contact_types" :options="typeOptions" :multiple="true" :close-on-select="false"  :preserve-search="true" :placeholder="$t('slide.select_contact_types')" label="name" track-by="id" :preselect-first="false">
                  </multiselect>
                </div>
                <div v-if="memberDescription.type==7">
                  <div style="width:100%;margin:10px auto;border-bottom:1px solid black;grid-column: 1/5;"></div>
                  <b-form-checkbox style="grid-column:1/3;" v-model="memberDescription.show_photo" value="1" unchecked-value="0">{{ $t('slide.show_photo')}}</b-form-checkbox>
                </div>
                <div v-if="[5,15,17,19,21,22].indexOf(memberDescription.type) !== -1" style="display: grid;    column-gap: 10px; grid-template-columns: repeat(4,max-content);overflow:initial;">
                  <div style="width:100%;margin:10px auto;border-bottom:1px solid black;grid-column: 1/5;"></div>
                  <b-form-checkbox v-if="memberDescription.type== 5 || memberDescription.type == 15 || memberDescription.type == 19" v-model="slide.show_recognition" class="form-box-input" style="grid-column-start: 1;"
                                 value="0"
                                 unchecked-value="1">
                  {{$t('slide.show_recognition')}}
                </b-form-checkbox>
                <b-form-checkbox v-if="isCorporateConnections" style="grid-column:1/3;" v-model="memberDescription.circular" value="1" unchecked-value="0">{{$t('slide.circular_text')}}</b-form-checkbox>
                <div v-if="isCorporateConnections" style="display:grid; grid-template-columns: max-content max-content;grid-column: 1/4;">
                      <span  class="form-box-name">{{$t('slide.show_accent')}}</span>
                      <b-form-select  v-model="memberDescription.show_accent" :options="accentOptions"  />
                    </div>
                <b-form-checkbox v-if="isCorporateConnections" style="grid-column:1/3;" v-model="memberDescription.multi_vertical" value="0" unchecked-value="1">{{$t('slide.horizontal_layout')}}</b-form-checkbox>
                  <span class="form-box-name" style="grid-column:1;">{{memberDescription.type!=21? $t('slide.name_background') : $t('slide.title_background')}}</span>
                  <colorpicker style="grid-column:2/5;margin:3px 0px;width:100px" :color="memberDescription.name_back_color" v-model="memberDescription.name_back_color" /><!--<b-form-input  type="color" v-model="memberDescription.name_back_color" style="max-width: 200px;grid-column:3/5;" class="form-box-input" required></b-form-input>-->
                  <span class="form-box-name" style="grid-column:1;">{{$t('slide.number_rows')}}</span>
                    <b-form-input style="margin: 5px 0px;" v-model="memberDescription.num_rows_y" min="1" max="100" type="number" required></b-form-input>
                  <span class="form-box-name" style="grid-column:1;">{{$t('slide.number_cols')}}</span>
                  <b-form-input style="margin: 5px 0px;" v-model="memberDescription.num_rows_x" min="1" max="100" type="number" required></b-form-input>
                  <span style=" grid-column: 1/2;" class="form-box-name">{{$t('slide.image_height')}}</span>
                  <b-form-input style="margin: 5px 0px;" v-model="memberDescription.height" min="1" max="100" type="number" required></b-form-input>
                  <b-form-checkbox class="setting-check" v-if="memberDescription.type!=21" v-model="memberDescription.first_name" value="1" unchecked-value="0" style="margin:10px auto;grid-column: 1/5;">{{$t('slide.only_first')}}</b-form-checkbox>
                </div>
                <div v-if="[1,2,3,4,6,9,14,18].indexOf(memberDescription.type) !== -1">
                  <b-form-checkbox v-if="memberDescription.type!= 18 && memberDescription.type != 9" v-model="slide.show_recognition" class="form-box-input" style="grid-column-start: 1;"
                                 value="0"
                                 unchecked-value="1">
                  {{$t('slide.show_recognition')}}
                </b-form-checkbox>
                  <b-form-checkbox class="setting-check" v-model="memberDescription.company_or_role" value="0">{{$t('slide.company_category')}}</b-form-checkbox>
                  <div v-if="memberDescription.company_or_role==0 && false" style="margin-left:30px;">
                    <b-form-checkbox class="setting-check" value="0">{{$t('slide.name_text')}}</b-form-checkbox>
                    <b-form-checkbox class="setting-check" value="1">{{$t('slide.company_text')}}</b-form-checkbox>
                    <b-form-checkbox class="setting-check" value="2">{{$t('slide.category_text')}}</b-form-checkbox>
                  </div>
                  <b-form-checkbox class="setting-check" v-model="memberDescription.company_or_role" value="1">{{$t('slide.role_description')}}</b-form-checkbox>
                  <div v-if="memberDescription.company_or_role==1 && false" style="margin-left:30px;">
                    <b-form-checkbox class="setting-check" value="0">{{$t('slide.name_text')}}</b-form-checkbox>
                    <b-form-checkbox class="setting-check" value="1">{{$t('slide.sub_role')}}</b-form-checkbox>
                    <b-form-checkbox class="setting-check" value="2">{{$t('slide.sub_description')}}</b-form-checkbox>
                  </div>
                  <div style="width:100%;margin:10px auto;border-bottom:1px solid black;"></div>
                    <b-form-checkbox v-if="isCorporateConnections" style="grid-column:1/3;" v-model="memberDescription.circular" value="1" unchecked-value="0">{{$t('slide.circular_text')}}</b-form-checkbox>
                    <div v-if="isCorporateConnections"  style="display:grid; grid-template-columns: max-content max-content;">
                      <span  class="form-box-name">{{$t('slide.show_accent')}}</span>
                      <b-form-select  v-model="memberDescription.show_accent" :options="accentOptions"  />
                    </div>
                    <b-form-checkbox style="grid-column:1/3;" v-model="memberDescription.show_photo" value="1" unchecked-value="0">{{ $t('slide.show_photo')}}</b-form-checkbox>
                    <b-form-checkbox v-if="isCorporateConnections" style="grid-column:1/3;" v-model="memberDescription.job_title_show" value="1" unchecked-value="0">{{$t('slide.show_job_title')}}</b-form-checkbox>
                    <b-form-checkbox style="grid-column:1/3;" v-model="memberDescription.show_company" value="1" unchecked-value="0">{{memberDescription.company_or_role==0? $t('slide.show_company') : $t('slide.show_role')}}</b-form-checkbox>
                    <b-form-checkbox style="grid-column:1/3;" v-model="memberDescription.show_category" value="1" unchecked-value="0">{{memberDescription.company_or_role==0? $t('slide.show_category') : $t('slide.show_subrole')}}</b-form-checkbox>
                    <b-form-checkbox style="grid-column:1/3;" v-model="memberDescription.show_phone" value="1" unchecked-value="0">{{ $t('slide.show_phone')}}</b-form-checkbox>
                    <b-form-checkbox style="grid-column:1/3;" v-model="memberDescription.show_email" value="1" unchecked-value="0">{{ $t('slide.show_email')}}</b-form-checkbox>
                    <b-form-checkbox style="grid-column:1/3;" v-model="memberDescription.show_website" value="1" unchecked-value="0">{{ $t('slide.show_website')}}</b-form-checkbox>
                    <div style="display:grid; grid-template-columns: max-content max-content;">
                      <span style="grid-column: 1/2;" class="form-box-name">{{$t('slide.align_text')}}</span><b-form-select v-model="memberDescription.alignment" :options="alignOptions" class=" form-box-input"/>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="[1,2,3,4,5,6,7,9,14,15,17,18,19,21,22].indexOf(memberDescription.type) !== -1">
            <div>
              <span class="form-box-title-compressed">{{memberDescription.type==10? $t('slide.referrals_text') :  (memberDescription.type==21? $t('slide.titles_text') : $tc('slide.names_text',memberDescription.type==5 || memberDescription.type==15 || memberDescription.type==17 || memberDescription.type==19? 0 : 1))}}</span>
              <div class="form-box-compressed" >
                <span class="form-box-name">{{$t('slide.member_font')}}</span><b-form-select v-model="memberDescription.name_font" :options="fontOptions" />
                <span v-if="memberDescription.type!=10" class="form-box-name">{{$t('slide.member_height')}}</span><b-form-input v-if="memberDescription.type!=10" v-model="memberDescription.name_height" min="1" max="100" type="number" style="max-width: 100px;" required></b-form-input>
                <span class="form-box-name">{{$t('slide.member_color')}}</span>
                <colorpicker style="max-width: 200px;margin: auto auto auto 0px;height:30px;" :color="memberDescription.name_color" v-model="memberDescription.name_color" />
                <span class="form-box-name">{{$t('slide.member_weight')}}</span><b-form-select v-model="memberDescription.name_weight" :options="weightOptions" />
              </div>
            </div>
            <div v-if="memberDescription.job_title_show && isCorporateConnections">
              <span class="form-box-title-compressed">{{$t('slide.job_title')}}</span>
              <div class="form-box-compressed" >
                <span class="form-box-name">{{$t('slide.member_font')}}</span><b-form-select v-model="memberDescription.job_title_font" :options="fontOptions" />
                <span class="form-box-name">{{$t('slide.member_height')}}</span><b-form-input v-model="memberDescription.job_title_height" min="1" max="100" type="number" style="max-width: 100px;" required></b-form-input>
                <span class="form-box-name">{{$t('slide.member_color')}}</span>
                <colorpicker style="max-width: 200px;margin: auto auto auto 0px;height:30px;" :color="memberDescription.job_title_color" v-model="memberDescription.job_title_color" />
                <span class="form-box-name">{{$t('slide.member_weight')}}</span><b-form-select v-model="memberDescription.job_title_weight" :options="weightOptions" />
              </div>
            </div>
            <div v-if="memberDescription.type!=5 && memberDescription.type!=15 && memberDescription.type!=17 && memberDescription.type!=19 && memberDescription.type!=21 && memberDescription.type!=22  && memberDescription.show_company==1">
              <span class="form-box-title-compressed">{{memberDescription.type==10? $t('slide.visitors_text') : $tc('slide.name_role', memberDescription.company_or_role==0? 1 : 0)}}</span>
              <div class="form-box-compressed" >
                <span class="form-box-name">{{$t('slide.member_font')}}</span><b-form-select v-model="memberDescription.company_font" :options="fontOptions" />
                <span v-if="memberDescription.type!=10" class="form-box-name">{{$t('slide.member_height')}}</span><b-form-input v-if="memberDescription.type!=10" v-model="memberDescription.company_height" min="1" max="100" type="number" style="max-width: 100px;" required></b-form-input>
                <span class="form-box-name">{{$t('slide.member_color')}}</span>
                <colorpicker style="max-width: 200px;margin: auto auto auto 0px; height:30px;" :color="memberDescription.company_color" v-model="memberDescription.company_color" />
                <span class="form-box-name">{{$t('slide.member_weight')}}</span><b-form-select v-model="memberDescription.company_weight" :options="weightOptions" />
              </div>
            </div>
            <div v-if="(memberDescription.show_renewing_date==1 && memberDescription.type==5) || (memberDescription.show_category==1 && memberDescription.type!=7 && memberDescription.type!=15 && memberDescription.type!=5) || (memberDescription.show_renewing_date==1 && memberDescription.type==15)">
              <span class="form-box-title-compressed">{{memberDescription.type==21? $t('slide.descriptions_text') : (memberDescription.type==19? $t('slide.date_header') : memberDescription.type==5? $t('slide.member_role') : memberDescription.type==15? (memberDescription.member_id>3? $t('slide.amount_text') : $t('slide.renew_date')) : (memberDescription.type==17? $t('slide.contact_type') : (memberDescription.type==10? $t('slide.ones_text') : (memberDescription.type == 7? $t('slide.invited_by_text') : (memberDescription.company_or_role==0? $t('slide.category_description') :$t('slide.sub_role_text'))))))}}</span>
              <div class="form-box-compressed" >
                <span class="form-box-name">{{$t('slide.member_font')}}</span><b-form-select v-model="memberDescription.category_font" :options="fontOptions" />
                <span v-if="memberDescription.type!=10" class="form-box-name">{{$t('slide.member_height')}}</span><b-form-input v-if="memberDescription.type!=10" v-model="memberDescription.category_height" min="1" max="100" type="number" style="max-width: 100px;"  required></b-form-input>
                <span class="form-box-name">{{$t('slide.member_color')}}</span>
                <colorpicker style="max-width: 200px;margin: auto auto auto 0px; height:30px;" :color="memberDescription.category_color" v-model="memberDescription.category_color" />
                <span class="form-box-name">{{$t('slide.member_weight')}}</span><b-form-select v-model="memberDescription.category_weight" :options="weightOptions"/>
              </div>
            </div>
            <div v-if="memberDescription.type!=5 && memberDescription.type!=15 && memberDescription.type!=17 && memberDescription.type!=19 && memberDescription.type!=21 && memberDescription.type!=22 && memberDescription.type!=10 && memberDescription.show_phone==1">
              <span class="form-box-title-compressed">{{$t('slide.phone_text')}}</span>
              <div class="form-box-compressed">
                <span class="form-box-name">{{$t('slide.member_font')}}</span><b-form-select v-model="memberDescription.phone_font" :options="fontOptions" />
                <span class="form-box-name">{{$t('slide.member_height')}}</span><b-form-input v-model="memberDescription.phone_height" min="1" max="100" type="number" style="max-width: 100px;"  required></b-form-input>
                <span class="form-box-name">{{$t('slide.member_color')}}</span>
                <colorpicker style="max-width: 200px;margin: auto auto auto 0px; height:30px;" :color="memberDescription.phone_color" v-model="memberDescription.phone_color" />
                <span class="form-box-name">{{$t('slide.member_weight')}}</span><b-form-select v-model="memberDescription.phone_weight" :options="weightOptions"/>
              </div>
            </div>
            <div v-if="memberDescription.type!=5 && memberDescription.type!=15 && memberDescription.type!=17 && memberDescription.type!=19 && memberDescription.type!=21 && memberDescription.type!=22 && memberDescription.type!=10 && memberDescription.show_email==1">
              <span class="form-box-title-compressed">{{$t('slide.email_text')}}</span>
              <div class="form-box-compressed">
                <span class="form-box-name">{{$t('slide.member_font')}}</span><b-form-select v-model="memberDescription.email_font" :options="fontOptions" />
                <span class="form-box-name">{{$t('slide.member_height')}}</span><b-form-input v-model="memberDescription.email_height" min="1" max="100" type="number" style="max-width: 100px;"  required></b-form-input>
                <span class="form-box-name">{{$t('slide.member_color')}}</span>
                <colorpicker style="max-width: 200px;margin: auto auto auto 0px; height:30px;" :color="memberDescription.email_color" v-model="memberDescription.email_color" />
                <span class="form-box-name">{{$t('slide.member_weight')}}</span><b-form-select v-model="memberDescription.email_weight" :options="weightOptions"/>
              </div>
            </div>
            <div v-if="memberDescription.type!=5 && memberDescription.type!=15 && memberDescription.type!=17 && memberDescription.type!=19 && memberDescription.type!=21 && memberDescription.type!=22 && memberDescription.show_website==1">
              <span class="form-box-title-compressed">{{memberDescription.type==10? $t('slide.tycfb_text') : $t('slide.website_text')}}</span>
              <div class="form-box-compressed">
                <span class="form-box-name">{{$t('slide.member_font')}}</span><b-form-select v-model="memberDescription.website_font" :options="fontOptions" />
                <span v-if="memberDescription.type!=10" class="form-box-name">{{$t('slide.member_height')}}</span><b-form-input v-if="memberDescription.type!=10" v-model="memberDescription.website_height" min="1" max="100" type="number" style="max-width: 100px;"  required></b-form-input>
                <span class="form-box-name">{{$t('slide.member_color')}}</span>
                <colorpicker style="max-width: 200px;margin: auto auto auto 0px; height:30px;" :color="memberDescription.website_color" v-model="memberDescription.website_color" />
                <span class="form-box-name">{{$t('slide.member_weight')}}</span><b-form-select v-model="memberDescription.website_weight" :options="weightOptions"/>
              </div>
            </div>
            <div v-if="memberDescription.type==7">
              <span class="form-box-title-compressed">{{memberDescription.type==10? $t('slide.ones_text') : (memberDescription.type == 7? $t('slide.invited_by_text') : (memberDescription.company_or_role==0? $t('slide.category_description') :$t('slide.sub_role_text')))}}</span>
              <div class="form-box-compressed">
                <span class="form-box-name">{{$t('slide.member_font')}}</span><b-form-select v-model="memberDescription.category_font" :options="fontOptions" />
                <span v-if="memberDescription.type!=10" class="form-box-name">{{$t('slide.member_height')}}</span><b-form-input v-if="memberDescription.type!=10" v-model="memberDescription.category_height" min="1" max="100" type="number" style="max-width: 100px;"  required></b-form-input>
                <span class="form-box-name">{{$t('slide.member_color')}}</span>
                <colorpicker style="max-width: 200px;margin: auto auto auto 0px; height:30px;" :color="memberDescription.category_color" v-model="memberDescription.category_color" />
                <span class="form-box-name">{{$t('slide.member_weight')}}</span><b-form-select v-model="memberDescription.category_weight" :options="weightOptions"/>
              </div>
            </div>
            
            <div v-if="memberDescription.type==1 || memberDescription.type==7 || memberDescription.type==18">
              <span class="form-box-title-compressed">{{$t('slide.next_presenter')}}</span>
              <div class="form-box-compressed">
                <span class="form-box-name">{{$t('slide.member_font')}}</span><b-form-select v-model="memberDescription.next_font" :options="fontOptions" />
                <span class="form-box-name">{{$t('slide.member_height')}}</span><b-form-input v-model="memberDescription.next.height" min="1" max="100" type="number" style="max-width: 100px;" required></b-form-input>
                <span class="form-box-name">{{$t('slide.member_color')}}</span>
                <colorpicker style="max-width: 200px;margin: auto auto auto 0px; height:30px;" :color="memberDescription.next_color" v-model="memberDescription.next_color" />
              </div>
            </div>
          </div>
          <div v-if="memberDescription.type==8" >
            <span class="form-box-title">{{$t('slide.slide_summary')}}</span>
            <div class="form-box" style="grid-template-columns: repeat(3, max-content);">
              <span class="form-box-name">{{$t('slide.font_text')}}</span><b-form-select v-model="goal.font"  :options="fontOptions" class="form-box-input" />
              <span class="form-box-name">{{$t('slide.color_text')}}</span><colorpicker style="width: 150px;margin: auto 0; height:40px;" :color="goal.color" v-model="goal.color" />
              <span style="grid-column:1/2;" class="form-box-name">{{$t('slide.titlecolor_text')}}</span><colorpicker style="width: 150px;margin: auto 0; height:40px;" :color="goal.title_color" v-model="goal.title_color" />
              <span style="grid-column:1/2;" class="form-box-name">{{$t('slide.descriptcolor_text')}}</span><colorpicker style="width: 150px;margin: auto 0; height:40px;" :color="goal.description_color" v-model="goal.description_color" />
              <span style="grid-column:1/2;" class="form-box-name">{{$t('slide.notescolor_text')}}</span><colorpicker style="width: 150px;margin: auto 0; height:40px;" :color="goal.notes_color" v-model="goal.notes_color" />
              <span class="form-box-name" style="grid-column:1/2;">{{$t('slide.title_font_size')}}</span><b-form-input v-model="goal.height" min="1" max="100" type="number" style="max-width: 100px;grid-column: 2/4;" required></b-form-input>
              <span class="form-box-name" style="grid-column:1/2;">{{$t('slide.description_font_size')}}</span><b-form-input v-model="goal.description_size" min="1" max="100" type="number" style="max-width: 100px;grid-column: 2/4;" required></b-form-input>
              <span class="form-box-name" style="grid-column:1/2;">{{$t('slide.notes_font_size')}}</span><b-form-input v-model="goal.notes_size" min="1" max="100" type="number" style="max-width: 100px;grid-column: 2/4;" required></b-form-input>
            </div>
            <span class="form-box-title">{{$t('slide.slide_history')}}</span>
            <div class="form-box" style="grid-template-columns: repeat(3, max-content);">
              <span class="form-box-name" style="grid-column:1/2;">{{$t('slide.history_size')}}</span><b-form-input v-model="goal.history_font_size" min="1" max="100" type="number" style="max-width: 100px;grid-column: 2/4;" required></b-form-input>
              <span class="form-box-name" style="grid-column:1/2;">{{$t('slide.heading_colour')}}</span><colorpicker style="width: 150px;margin: auto 0; height:40px;" :color="goal.heading_colour" v-model="goal.heading_colour" />
              <span class="form-box-name" style="grid-column:1/2;">{{$t('slide.heading_back_colour')}}</span><colorpicker style="width: 150px;margin: auto 0; height:40px;" :color="goal.heading_back_colour" v-model="goal.heading_back_colour" />
              <span class="form-box-name" style="grid-column:1/2;">{{$t('slide.table_colour')}}</span><colorpicker style="width: 150px;margin: auto 0; height:40px;" :color="goal.table_colour" v-model="goal.table_colour" />
              <span class="form-box-name" style="grid-column:1/2;">{{$t('slide.table_back_colour')}}</span><colorpicker style="width: 150px;margin: auto 0; height:40px;" :color="goal.table_back_colour" v-model="goal.table_back_colour" />
              <span class="form-box-name" style="grid-column:1/2;">{{$t('slide.table_max')}}</span><b-form-input v-model="goal.max_table_rows" min="1" max="100" type="number" style="max-width: 100px;grid-column: 2/4;" required></b-form-input>
            </div>
            
          </div>
        </div>
      </form>
      <div slot="modal-footer" class="submit-box-2">
        <b-button class="submit-button4" v-on:click="showMembers=false" variant="clear-blue"><div class="ok-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
      </div>
    </b-modal>
    <b-modal centered id="slide-modal" v-model="showTimerEdit" :title="$t('slide.timer_title')">
      <form id="timer-form" v-on:submit.prevent="updateSlide">
        <div id="timer-box">
          <div class="submit-box-2">
            <b-button class="submit-button4" :variant="progressVariant" v-on:click="timer.type = 0">{{$t('slide.progress_bar')}}</b-button>
            <b-button class="submit-button4" :variant="counterVariant" v-on:click="timer.type = 1">{{$t('slide.text_counter')}}</b-button>
            <b-button v-if="memberDescription.type==3" class="submit-button4" :variant="presentationVariant" v-on:click="timer.type = 2">{{$t('slide.presentation_counter')}}</b-button>
          </div>
          <div v-if="timer.type ==0">
            <span class="form-box-title">{{$t('slide.timer_appearance')}}</span>
            <div class="form-box" style="grid-template-columns: 25% 25% 25% 25%;">
              <span class="form-box-name">{{$t('slide.timer_xpos')}}</span>
              <b-form-input style="margin: 5px 0px;" v-model="timer.x_pos" min="0" max="100" type="number" required></b-form-input>
              <span class="form-box-name">{{$t('slide.timer_ypos')}}</span>
              <b-form-input style="margin: 5px 0px;" v-model="timer.y_pos" min="0" max="100" type="number" required></b-form-input>
              <span class="form-box-name">{{$t('slide.timer_width')}}</span>
              <b-form-input style="margin: 5px 0px;" v-model="timer.width" type="number" required></b-form-input>
              <span class="form-box-name">{{$t('slide.timer_height')}}</span>
              <b-form-input style="margin: 5px 0px;" v-model="timer.height" min="1" max="100" type="number" required></b-form-input>
              <span class="form-box-name">{{$t('slide.fill_color')}}</span>
              <colorpicker style="max-width: 200px;margin: auto; height:30px;" class="form-box-name" :color="timer.color" v-model="timer.color" />
              <span class="form-box-name">{{$t('slide.back_color')}}</span>
              <colorpicker style="max-width: 200px;margin: auto; height:30px;" class="form-box-name" :color="timer.back_color" v-model="timer.back_color" />

            <b-form-checkbox style="grid-column:2;" v-model="timer.countdown_change" value="1" unchecked-value="0">{{$t('slide.change_color')}}</b-form-checkbox>
              <div v-if="timer.countdown_change==1" style="display:grid; grid-template-columns: repeat(4,1fr);grid-column:2/5;">
                <span  class="form-box-name">{{$t('slide.at_time')}}</span>
                <b-form-input v-model="timer.alter_time" type="number" min="0" style="max-width: 70px;" required></b-form-input>
                <span class="form-box-name">{{$t('slide.new_color')}}</span>
                <colorpicker style="max-width: 200px; height:30px;margin:auto;" class="form-box-name" :color="timer.alter_colour" v-model="timer.alter_colour" />
              </div>
              <b-form-checkbox v-if="isCorporateConnections" style="grid-column:2;" v-model="timer.circular" value="1" unchecked-value="0">{{$t('slide.circular_text')}}</b-form-checkbox>
              <div v-if="timer.circular==1 && isCorporateConnections" style="display:grid; grid-template-columns: repeat(4,1fr);grid-column:2/5;">
                <span class="form-box-name" style="grid-column:1;">{{$t('slide.start_deg')}}</span>
                <b-form-input v-model="timer.circular_start" type="number" min="0" max="360" style="max-width: 70px;"></b-form-input>
                <span class="form-box-name">{{$t('slide.end_deg')}}</span>
                <b-form-input v-model="timer.circular_end" type="number" min="0" max="360" style="max-width: 70px;" ></b-form-input>
              </div>
            </div>
          </div>
          <div v-if="timer.type==1 || timer.type==2">
            <span class="form-box-title">{{$t('slide.timer_appearance')}}</span>
            <div class="form-box" style="grid-template-columns: 25% 25% 25% 25%;">
              <span class="form-box-name">{{$t('slide.timer_font')}}</span><b-form-select v-model="timer.font" id="font-input" :options="fontOptions" class="form-box-input" />
              <span class="form-box-name">{{$t('slide.timer_height')}}</span>
              <b-form-input style="margin: 5px 0px;" v-model="timer.height" min="1" max="100" type="number" required></b-form-input>
              <span class="form-box-name" style="grid-column:1;">{{$t('slide.timer_color')}}</span>
              <colorpicker style="max-width: 200px;margin: auto; height:30px;" :color="timer.color" v-model="timer.color" />
              <span class="form-box-name">{{$t('slide.outline_color')}}</span>
              <colorpicker style="max-width: 200px;margin: auto; height:30px;" :color="timer.back_color" v-model="timer.back_color" />

              <b-form-checkbox style="margin: 12px auto;grid-column:2;" v-model="timer.countdown_change" value="1" unchecked-value="0">{{$t('slide.change_color')}}</b-form-checkbox>
              <div v-if="timer.countdown_change==1" style="display:grid; grid-template-columns: auto auto;grid-column:2;">
                <span  class="form-box-name">{{$t('slide.at_time')}}</span>
                <b-form-input v-model="timer.alter_time" type="number" min="0" style="max-width: 70px;margin:0 auto;" required></b-form-input>
              </div>
              <span v-if="timer.countdown_change==1" class="form-box-name">{{$t('slide.new_color')}}</span>
              <colorpicker v-if="timer.countdown_change==1" style="max-width: 200px;margin: auto; height:30px;" class="form-box-name" :color="timer.alter_colour" v-model="timer.alter_colour" />
            </div>
          </div>
          <div>
            <span class="form-box-title">{{$t('slide.timer_behavior')}}</span>
            <div class="form-box" style="grid-template-columns: 20% 20% 30% 30%;">
              <span class="form-box-name">{{$t('slide.countdown_time')}}</span>
              <b-form-checkbox style="margin: 12px auto;" v-model="timer.time_default" value="0">{{$t('slide.default_text')}}</b-form-checkbox>
              <b-form-checkbox style="margin: 12px auto;" v-model="timer.time_default" value="1">{{$t('slide.set_text')}}</b-form-checkbox>
              <b-form-select v-if="timer.time_default == 0"  v-model="timer.default_option" :options="defaultOptions"  value-field="id" text-field="name" />
              <div v-if="timer.time_default == 1" style="display:grid; grid-template-columns: auto auto auto auto;" >
                <span class="form-box-name">{{$t('slide.mins_text')}}</span><b-form-input v-model="timer.mins" type="number" min="0" style="max-width: 70px;" required></b-form-input>
                <span class="form-box-name">{{$t('slide.secs_text')}}</span><b-form-input v-model="timer.secs" type="number" min="0" style="max-width: 70px;" required></b-form-input>
              </div>
              <span class="form-box-name">{{$t('slide.time_sound')}}</span>
              <b-form-select v-model="timer.sound_file" :options="soundOptions" class="form-box-input" value-field="file_name" text-field="name" />
              <div v-if="timer.sound_file.length==0"></div>
              <b-button v-if="timer.sound_file.length>0" class="submit-button4" variant="blue" v-on:click="playSound">{{$t('slide.preview_text')}}</b-button>

              <b-form-checkbox style="margin: 12px auto;" v-model="timer.progressive" value="1" unchecked-value="0">{{$t('slide.progressive_volume')}}</b-form-checkbox>
              <span v-show="timer.progressive==1" class="form-box-name">{{$t('slide.number_times')}}</span>
              <b-form-input v-show="timer.progressive==1" style=" max-width: 125px;" v-model="timer.num_plays" min="0" type="number" required></b-form-input>
              <b-form-checkbox style="margin: 12px auto;" v-model="timer.continuous" value="1" unchecked-value="0">
                {{$t('slide.continue_playing')}}
              </b-form-checkbox>
              <b-form-checkbox v-if="timer.type==0" style="margin: 12px auto;grid-column:1/2;" v-model="timer.direction" value="1" unchecked-value="0">
                {{$t('slide.start_full')}}
              </b-form-checkbox>
              <b-form-checkbox v-if="timer.type==1" style="margin: 12px auto;grid-column:2/3;" v-model="timer.auto_start" value="1" unchecked-value="0">
                {{$t('slide.auto_start')}}
              </b-form-checkbox>
              <div v-if="timer.type==1" style="display:grid; grid-template-columns: max-content max-content; margin-left:5px;">
              <b-form-input v-model="timer.delay_time" type="number" min="0" style="max-width: 70px;" required></b-form-input><span class="form-box-name" style='text-align:left;'>{{$t('slide.secs_text')}}</span>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div slot="modal-footer" class="submit-box-2">
        <b-button class="submit-button4"  v-on:click="showTimerEdit=false; timer.disabled = 1;" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
        <b-button class="submit-button4"  v-on:click="showTimerEdit=false" variant="clear-blue"><div class="ok-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
      </div>
    </b-modal>

    <b-modal centered id="slide-modal" v-model="showFilesEdit" :title="$t('slide.files_title')">
      <form v-on:submit.prevent="updateSlide">
        <div>
          <span class="form-box-title">{{$t('slide.files_links')}}</span>
          <div class="form-box" style="grid-template-columns: auto;">
            <b-button class="submit-button4" v-if="slide_files.length<3" v-on:click="addFileLink" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
            <div v-for="file in slide_files" :key="file.number" style="display:grid; grid-template-columns: auto ;" >
              <div style="text-align:center; grid-column:1/3;color: #003c80;font-size: 20px;">{{$t('preparer.file_text')+" #"+(slide_files.indexOf(file)+1)}}</div>
                <b-button v-if="slide_files.length>1 || (file.link != '' || file.file != null) " v-on:click="removeFileLink(file)" class="submit-button4" variant="clear-blue" style="margin:auto;grid-column:2/3;grid-row:2/5;"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
                <b-form-checkbox class="setting-check" v-model="file.type" :value="1" :unchecked-value="2" style="grid-column:1/2;">{{$t('preparer.power_point')}}</b-form-checkbox>
                <div style="display:grid; grid-template-columns: max-content auto;grid-column:1/2;" v-if="file.type==1">
                  <b-button  class="submit-button4" v-on:click="openFileDialog(file)" variant="blue" style="margin: auto 20px;">{{file.link.length>0 && file.file_path.length>0? $t('entry.reselect_presentation') : $t('preparer.upload_pp')}}</b-button>
                  <div v-if="file.filename && file.filename.length>0" id="preparer-filename" class="cut-filename">{{file.filename}}</div>
                  <div v-if="(!file.filename || file.filename.length==0)  && file.link.length>0 && file.file_path.length>0" style="text-align:center;line-height: 12px;margin:auto;">
                    <div class="cut-filename">{{file.file_path}}</div>
                    <div><b-button variant="link" v-on:click="viewFile(file.hash)" style="padding:0;border: none;font-weight: bold;">{{$t('entry.speaker_upload')}}</b-button></div>
                    <div style="font-size: 14px;"><a :href="'/uploads/'+file.file_path" download>{{$t('entry.download_original')}}</a></div>
                  </div>
                  <!--<b-button style="grid-column:2;margin-top:20px;" class="submit-button4" v-if="(!file.filename || file.filename.length==0)  && file.link.length>0 && file.file_path.length>0" v-on:click="resendFileConfirm(file)" variant="clear-blue"><div class="email-button"></div><span>{{$t('preparer.resend_confirm')}}</span></b-button>-->
                </div>
                <b-form-file :id="'fileupload'+file.number" accept=".doc, .docx, .pdf, .ppt, .pptx, .pptm, .pub, .png, .jpg, .jpeg" @change="onFileFileChange($event, file)" plain hidden></b-form-file> 
                <b-form-checkbox class="setting-check" v-model="file.type" :value="2" :unchecked-value="1" style="grid-column:1/2;">{{$t('preparer.link_web')}}</b-form-checkbox>
                <div style="display:grid; grid-template-columns: max-content auto;grid-column:1/2;" v-if="file.type==2">
                  <b-form-input v-model="file.link" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input" style="grid-column:1/2;width:200px;grid-column: 1/2;"></b-form-input>
                  <div v-if="file.link.length>0 && file.description.length>0" style="text-align:center;line-height: 12px;margin:auto;">
                    <div class="cut-filename" style="line-height:16px;">{{file.description}}</div>
                    <div><b-button variant="link" v-on:click="viewLink(file.link)" style="padding:0;border: none;font-weight: bold;">{{$t('entry.speaker_link')}}</b-button></div>
                  </div>
                </div>
                <hr style="width: 100%;border-color: grey;grid-column:1/3;" />
            </div>
          </div>
        </div>
      </form>
      <div slot="modal-footer" class="submit-box-2">
        <b-button class="submit-button4" v-on:click="showFilesEdit=false" variant="clear-blue"><div class="ok-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
      </div>
    </b-modal>
    <b-modal centered id="slide-modal" v-model="showScriptEdit" :title="$t('slide.script_title')" >
      <div>
        <span class="form-box-title">{{$t('slide.slide_scripts')}}</span>
        <div class="form-box" style="grid-template-columns:auto;">
          <b-form-textarea disabled v-model="currentSlideScript.script" :rows="8"  />
        </div>
      </div>
      <div slot="modal-footer" class="submit-box-2">
        <b-button class="submit-button4" v-on:click="showScriptEdit = false;" variant="clear-blue"><div class="ok-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
      </div>
    </b-modal>
    <b-modal centered id="slide-modal" v-model="showGalleryModal" :title="$t('slide.gallery_title')" >
      <div>
        <span class="form-box-title">{{$t('slide.slide_options')}}</span>
        <div class="form-box" style="grid-template-columns:auto;">
            <span style="grid-column: 1/2;margin:5px;">{{$t('slides.search_tag')}}</span>
            <multiselect :noOptions="$t('global.empty_list')" :class="{blueselect:true}" :selectedLabel="$t('global.selected_text')" selectLabel="" deselectLabel="" v-model="selected_tags" :options="tags" :multiple="true" :close-on-select="false"  :preserve-search="true" :placeholder="$t('global.select_tags')" label="name" track-by="id" :preselect-first="false">
              <template slot="option" slot-scope="props">
                <div>{{ props.option.name }}</div>
              </template>
            </multiselect>
            <span style="grid-column: 1/2;margin:5px;">{{$t('slides.search_tag')}}</span>
            <b-form-textarea id="textarea1" v-model="slide.description" placeholder="" :rows="3" :max-rows="3" />
            <b-form-checkbox style="margin: 7px 0px;" v-model="slide.allow_editing"
                           value="1"
                           unchecked-value="0">
            {{$t('slide.allow_editing')}}
          </b-form-checkbox>
        </div>
      </div>
      <div slot="modal-footer" class="submit-box-2">
        <b-button class="submit-button4" v-on:click="showGalleryModal = false;" variant="clear-blue"><div class="ok-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
      </div>
    </b-modal>
    <b-modal centered id="slide-modal" v-model="showChildSettings" :title="$t('slide.slide_settings')">
      <form id="timer-form" v-on:submit.prevent="updateSlide">
        <div>
          <span class="form-box-title">{{$t('slide.general_text')}}</span>
            <div class="form-box" style="grid-template-columns: 25% auto auto;">
              <b-form-checkbox v-model="slide.disabled" value="1" unchecked-value="0" style="grid-column: 1/3;">
                {{$t('slide.disable_slide')}}
              </b-form-checkbox>
              <span style="grid-column:1;" v-if="languageOptions.length>1" class="form-box-name">{{$t('slide.presentation_language')}}</span><b-form-select v-if="languageOptions.length>1" v-model="slide.language" :options="languageOptions" class="form-box-input" value-field="abbrev" text-field="name"/>
          <span style="grid-column: 1/4;font-size: 11px;text-align: right;">*{{$t('slide.language_note')}}</span><br/>
            </div>
          
        </div>
        <div v-if="(memberDescription.type == 1 || memberDescription.type == 7) && memberDescription.selection>1">
          <span class="form-box-title">{{$t('slide.member_select')}}</span>
            <div class="form-box">
          <!--<b-form-checkbox-group style="margin: auto auto auto 5px;grid-column-start: 1;" class="form-box-input" v-model="memberDescription.selection" :options="selectionOptions">
          </b-form-checkbox-group>-->
          <div v-if="memberDescription.selection>1" style="display:grid; grid-template-columns: max-content auto;">
            <span  class="form-box-name">{{$t('slide.next_number')}}</span>
            <b-form-select style="width:70px;"  v-model="memberDescription.next_number" :options="nextOptions" value-field="id" text-field="name" />
          </div>
            </div>
        </div>
        <div id="timer-box" v-if="timer.disabled!=1">
          <div>
            <span class="form-box-title">{{$t('slide.timer_text')}}</span>
            <div class="form-box" style="grid-template-columns: 20% 20% 30% 30%;">
              <span class="form-box-name">{{$t('slide.time_sound')}}</span>
              <b-form-select v-model="timer.sound_file" :options="soundOptions" class="form-box-input" value-field="file_name" text-field="name" />
              <div v-if="timer.sound_file.length==0"></div>
              <b-button v-if="timer.sound_file.length>0" class="submit-button4" variant="blue" v-on:click="playSound">{{$t('slide.preview_text')}}</b-button>

              <!--<b-form-checkbox style="margin: 12px auto;" v-model="timer.progressive" value="1" unchecked-value="0">{{$t('slide.progressive_volume')}}</b-form-checkbox>
              <span v-show="timer.progressive==1" class="form-box-name">{{$t('slide.number_times')}}</span>
              <b-form-input v-show="timer.progressive==1" style=" max-width: 125px;" v-model="timer.num_plays" min="0" type="number" required></b-form-input>
              <b-form-checkbox style="margin: 12px auto;" v-model="timer.continuous" value="1" unchecked-value="0">
                {{$t('slide.continue_playing')}}
              </b-form-checkbox>
              <b-form-checkbox v-if="timer.type==0" style="margin: 12px auto;grid-column:1/2;" v-model="timer.direction" value="1" unchecked-value="0">
                {{$t('slide.start_full')}}
              </b-form-checkbox>-->
              <b-form-checkbox style="margin: 12px auto;grid-column:2/3;" v-model="timer.auto_start" value="1" unchecked-value="0">
                {{$t('slide.auto_start')}}
              </b-form-checkbox>
              <div style="display:grid; grid-template-columns: max-content max-content; margin-left:5px;">
              <b-form-input v-model="timer.delay_time" type="number" min="0" style="max-width: 70px;" required></b-form-input><span class="form-box-name" style='text-align:left;'>{{$t('slide.secs_text')}}</span>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div slot="modal-footer" class="submit-box-2">
        <b-button class="submit-button4"  v-on:click="showChildSettings=false" variant="clear-blue"><div class="ok-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
      </div>
    </b-modal>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
#linkedin-image{
  background-repeat: no-repeat;
    background-size: 25%;
    background-position: 50%;
    position:absolute;background-image:url('../assets/LinkedIn.png');width:100%;height:100%;
}
.global-image{
  background-image: url("../assets/icon-AreaGlobal.png");
  height: 100%;
    width: auto;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 90%;
        height: 150%;
    top: -25%;
    position: relative;
}
.national-image{
  background-image: url("../assets/icon-AreaGlobalNational.png");
  height: 100%;
    width: auto;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 90%;
        height: 150%;
    top: -25%;
    position: relative;
}
.regional-image{
  background-image: url("../assets/icon-AreaGlobalLocal.png");
  height: 100%;
    width: auto;
    background-repeat: no-repeat;
    background-size: contain;
    background-position:90%;
        height: 150%;
    top: -25%;
    position: relative;
}
#slide-copy-button{
  background-image: url("../assets/icon-copy-"+$VUE_APP_IMAGE_MOD+".png");
}
#slide-paste-button{
  background-image: url("../assets/icon-paste-"+$VUE_APP_IMAGE_MOD+".png");
}
#slide-paste-language-button{
  background-image: url("../assets/icon-PasteTextFormatpng-"+$VUE_APP_IMAGE_MOD+".png");
}
#slide-copy-language-button{
  background-image: url("../assets/icon-CopyTextObjects-"+$VUE_APP_IMAGE_MOD+".png");
}
#slide-sendback-button{
  background-image: url("../assets/icon-sendBack-"+$VUE_APP_IMAGE_MOD+".png");
}
#slide-bringfront-button{
  background-image: url("../assets/icon-bringFront-"+$VUE_APP_IMAGE_MOD+".png");
}
#slide-show-grid-button{
  background-image: url("../assets/icon-gridHeavy-"+$VUE_APP_IMAGE_MOD+".png");
}
#slide-hide-grid-button{
  background-image: url("../assets/icon-gridNone.png");
}

@if $VUE_APP_IS_CORPORATE==0 {
  .member-corner-image{
    position: absolute;
      width: 30%!important;
      height: auto!important;
      left: -6%;
      top: -3%;
      z-index: -1;
  }
}
@if $VUE_APP_IS_CORPORATE==1 {
  .member-corner-image{
    position: absolute;
      width: 94% !important;
      height: 114% !important;
      left: -5.5%;
      top: -7%;
      z-index: -1;
  }
}

.member-graphic{
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.circular-member{
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 50% 26%;
  border-radius: 50%;
  background-color:  #c5c5c5;
}
.test-member-image{
  height: 76%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 50% 26%;
    border-radius: 50%;
    margin-top: 17%;
}
.form-box-compressed {
    margin: 0 10px;
    display: grid;
    grid-template-columns: repeat(8, minmax(50px, max-content));
    grid-gap:10px;
    padding: 20px;
    border: 1px solid #999;
    border-radius: 5px;
    color: #999;
  }
  .form-box-title-compressed {
    font-size: 20px;
    color: #003c80;
    background: #fff;
    top: 13px;
    position: relative;
    z-index: 10;
    width: fit-content;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    display: block;
    margin: auto;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }
.members-best-weekly{
  position: absolute;left: 55%;height: 40%; bottom: 0px;
}
.best-weekly{
  position: absolute;right: 0px;height: 40% !important; bottom: 0px;
}
.member-banner{
    width: 100%;
    text-align: center;
    background-color: #C00000;
    color: white;
    position: absolute;
    font-size: 20px;
}
.members-banner{
  text-align: center;
    background-color: #C00000;
    color: white;
    position: absolute;
    font-size: 20px;
    left: 0;
    right: 0;
    margin: auto;
    padding: 0 10px;
}
.qr-block{
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    border: 3px solid #fff;
    background-color: #fff;
}
.qr-block > img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
}
.controller-select{
  height: 16px;margin: 7px 5px;
}
.special-graphic{
  width:100%;height:100%;object-fit: contain;
}
  .grid-button {
    width: 40px;
    height: 40px;
    padding: 0px;
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
    
  }
  #grid-layout {
    width: 100%;
    height: 100%;
    position: absolute;
    grid-gap: 0px;
    display: grid;
  }
  .grid-element {
    border-top: 1px dashed #777777;
    border-left: 1px dashed #777777;
    width: 100%;
    height: 100%;
  }
  .slide-button {
    display: flex;
    width: fit-content;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    margin: auto;
    background-size: 100% 100% !important;
    background-repeat: no-repeat;
    margin: 0px 15px;
  }

    .slide-button > div {
      width:48px !important;
      height:48px !important;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .slide-button > span {
      margin: auto 0px auto 5px;
    }

  #settings-button {
    background-image: url("../assets/button-EditSettings.png");
  }

  #text-button {
    background-image: url("../assets/button-EditTextShape.png");
  }
  #graphic-button {
    background-image: url("../assets/button-EditGraphic.png");
  }
  #goal-button {
    background-image: url("../assets/button-EditGraphic.png");
  }
  #members-button2 {
    background-image: url("../assets/button-EditMembers.png");
  }
  #files-button {
    background-image: url("../assets/button-EditFiles.png");
  }
  #timer-button {
    background-image: url("../assets/button-EditTimer.png");
  }
  #preview-button {
    background-image: url("../assets/button-EditPreview.png");
  }
  #outline-button {
    background-image: url("../assets/button-EditOutline.png");
  }

  .back-photo {
    height: 100px;
    border: solid 2px #ccc;
  }
  .visitor-bold {
    font-weight: bold;
    color: #06f;
  }
  #timer-bar > span {
    width: 75%;
    height: 100%;
    display: block;
  }
  #timer-bar{
    border: none;
  }
  #none-member-view {
    position: relative;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  #member-name,#member-company, #member_job_title {
    padding-bottom: 5px;
  }
  #member-category,#member-email,#member-phone {
    padding-bottom: 5px;
  }
  .variable-row {
    font-size: 11px;
    cursor: pointer;
    color:#333;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  }
  .role-count {
    color: #6eb6da;
    font-size: 12px;
    padding-left: 20px;
  }
  .slide-role-member {
    height: 100%;
    /*padding: 10% 20.5%;*/
    padding: 20px;
    width: 100%;
    position: relative;
    margin: auto;
    display: grid;
    grid-template-rows: auto max-content;
  }
    .slide-role-member .role-image-wrapper {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
      text-align: center;
      /*display: grid;
      grid-template-rows: 80% 20%;*/
    }

    .role-image-wrapper-2{
          width: 100%;
      height: 100%;
      position: relative;
      text-align: center;
      position: relative;
      margin: auto;
    }
  .role-image-name {
    text-align: center;
    overflow: hidden;
    margin:auto;
    padding-top: 6px;
        word-break: break-word;
        margin-top: 0;
    /*margin: 0 1%;
    height: 20%;
    max-height: 20%;*/
  }

  .role-image-div {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
  }
    .role-image-div > .member-role-image {
      position: absolute;
      top: -9999px;
      bottom: -9999px;
      left: -9999px;
      right: -9999px;
      margin: auto;
      height: 100%;
      width: auto;
    }

    .member-role-image-2{
        background-position: center;
      background-repeat: no-repeat;
      background-size: auto 100%;
      position: relative;
      height:100%;
    }
  #descriptionBox {
    position: absolute;
    cursor: pointer;
  }
  #role-members {
    display: grid;
    justify-content: center;
    position: absolute;
    width:100%;
  }
  #single-member {
    overflow: hidden;
    position: relative;
    text-align: center;
  }
    #single-member > img {
      position: absolute;
      top: -9999px;
      bottom: -9999px;
      left: -9999px;
      right: -9999px;
      margin: auto;
      height: 100%;
      width: auto;
    }
  #font-input {
    grid-column: 2 / 5;
  }
  #textarea1 {
    grid-column: 1 / 5;
  }
  .pointerDrag {
    cursor: pointer;
  }
  .draggable {
    position: absolute;
    width: fit-content;
    width: -moz-fit-content;
    width: -webkit-fit-content;
  }
    .draggable > img {
      height: 100%;
      width: auto;
    }
    .draggable > div > img {
      height: 100%;
      width: auto;
    }
  #text-box {
    display: grid;
    grid-template-columns: auto 250px;
  }
  #members-box {
    display: grid;
    grid-template-columns: 40% 60%;
  }
  #edit-graphic-image {
    height: 100px;
  }
  #slide-photo {
    height: 100px;
  }
  #variables-box {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 2;
  }
  #then-text {
    grid-column-start: 1;
    grid-column-end: 4;
    text-align: center;
    color: #003c80;
    font-size: 20px;
  }
  .setting-check {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  #setting-box {
    display: grid;
    grid-template-columns: 50% 50%;
    margin: auto;
  }
  #send-modal .modal-dialog {
    max-width: 550px !important;
    width: 550px !important;
  }
    #slide-modal .modal-dialog {
        max-width: 800px !important;
        width: 800px !important;
    }
    #slide-modal-2 .modal-dialog {
        max-width: 1000px !important;
        width: 1000px !important;
    }
    #slide-modal-3 .modal-dialog {
        max-width: 1200px !important;
        width: 1200px !important;
    }
    #slide-modal-4 .modal-dialog {
        max-width: 70% !important;
        width: 70% !important;
    }
    #language-modal .modal-dialog {
        max-width: 1200px !important;
        width: 1200px !important;
    }

    #system-modal .modal-dialog {
    max-width: 600px;
    width: 600px;
    height: auto;
  }

  #modal-html .modal-dialog {
    max-width: 1000px;
    width: 1000px;
    height: auto;
  }
  #modal-html .modal-body {
    padding: 0px;
  }

  #img-html {
    max-width: 998px;
    width: 998px;
    height:auto;
  }

  #main-view {
    border-radius: 0px;
    display: grid;
    background-repeat: no-repeat;
    border: 2px solid #bbb;
    border-radius: 3px;
    position: relative;
    background-size: 100% 100%;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
    overflow:hidden;
  }

  #slide-options {
    grid-template-columns: auto;
    display: grid;
    grid-row-gap: 10px;
    z-index: 10;
    height: fit-content;
    height: -moz-fit-content;
    height: -webkit-fit-content;
  }
  #main-slide {
    display: grid;
    grid-template-columns: 225px auto;
  }
  #inner-slide {
    display: grid;
    grid-template-rows: content-max auto 40px ;
    height: 100%;
  }
  #slide-container {
    width: 95%;
    bottom: 0;
    top: 20px;
    position: relative;
    margin: auto;
  }
  #banner-input {
    display: grid;
    grid-template-columns: 20% 20% 10% 30% 20%;
    width: 90%;
    margin: auto;
    margin-top: 10px;
  }

  #slide-banner {
    display: grid;
    grid-template-columns: 33% 33% 33%;
  }
  #categories-variable {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    overflow: hidden;
    position: absolute;
  }
  .category-entry{
    min-width: 50%;
  }
  .upcoming-padding{
    padding: 10px;
  }
  #upcoming-speakers {
    text-align: left;
    display: grid;
  }
  #upcoming-renewal {
    text-align: left;
    display: grid;
    grid-row-gap: 4%;
  }
  .on-this-day {
    text-align: center;
    white-space: pre-wrap; /* Since CSS 2.1 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
  }

  .donation-meter {
    width: 100px;
    position: absolute;
    left: 80%;
    top:20%;
  }
  .glass {
    background: #e5e5e5;
    border-radius: 100px 100px 0 0;
    display: block;
    height: calc(100% - 100px);;
    margin: 0 35px 10px;
    padding: 5px;
    position: relative;
    width: 30px;
  }
  .amount {
    background: #f34e54;
    border-radius: 100px;
    display: block;
    width: 20px;
    position: absolute;
     bottom: 5px;
  }
  .total, .goal {
    font-size: 16px;
    position: absolute;
    left: 50px;
    display: block; 
    text-align: center;
    white-space: nowrap;
  }
  .goal{
    bottom: 95%;
  }
  .goal-increment{
    position:absolute;left:35px;width:10px;border-color:#000;border-width: 3px;
  }
.bulb {
  background: #e5e5e5;
  border-radius: 100px;
  display: block;
  height: 60px;
  margin: 0 35px 10px;
  padding: 5px;
  position: relative;
    top: -20px;
    right: 15px;
  width: 60px;
  
}
.red-circle {
    background: #f34e54;
    border-radius: 100px;
    display: block;
    height: 50px;
    width: 50px;
  }
  .filler {
    background: #f34e54;
    border-radius: 100px 100px 0 0;
    display: block;
    height: 30px;
    width: 20px;
    position: relative;
      top: -65px;
      right: -15px;
    z-index: 30;
  }

  p{
    margin-bottom: 0px !important;
    
  }
  p:empty:before {
  content: ' ';
  white-space: pre;
}
  strong{
    font-weight: bold !important;
  }

  .verticalTextAlign{
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  .textElement{
    white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    white-space: pre-wrap;       /* css-3 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    white-space: -webkit-pre-wrap; /* Newer versions of Chrome/Safari*/
    word-break: break-word;
    white-space: normal;
  }

  .textElement > div{
    overflow: hidden;
    width: 100%;
    max-height: 100%;
    height: fit-content ;
    height: -moz-fit-content ;
    height: -webkit-fit-content ;
    padding-bottom: 5px;
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding-top: 3px;
  }

  .textElement > div::-webkit-scrollbar {
  display: none;
}
</style>
<script>
  import router from "../router";
  import Multiselect from 'vue-multiselect';
  import tempImage from "../assets/temp_image.png";
  import tempMemberImage from "../assets/default-tall.jpg";

    import colorpicker from './Colorpicker.vue';
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
    import VueDragResize from 'vue-drag-resize';

import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
  import Superscript from '@/assets/Superscript'
  import SetColor from '@/assets/SetColor'
  import {
    Blockquote,
    HardBreak,
    HorizontalRule,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Italic,
    Link,
    Strike,
    Underline,
    History
  } from 'tiptap-extensions'

    export default {
    name: "Slide",
    components: {
      VueDragResize,
      colorpicker,
      VueCropper,
      Multiselect,
       EditorContent,
      EditorMenuBar
    },
    watch: {
      '$i18n.locale': {
        handler: function () {
          this.slide_member ={
            name: this.$t('slide.member_name'),
            company: this.$tc('slide.member_company', 0),
            category: this.$tc('slide.member_category', 0), 
            photo: tempMemberImage,
            email: this.$t('slide.email_text'),
            website: this.$t('slide.website_text'),
            phone: this.$t('slide.phone_text'),
            job_title: this.$t('slide.job_title'),
          };
          this.goal_fields[0].label = this.$t('slide.date_header');
          this.goal_fields[1].label = this.$t('slide.value_header');

          if(this.singularRoles.length>0){
            this.singularRoles[0].name = this.$t('slide.trans_option0');
          }
          if(this.multiRoles.length>0){
            this.multiRoles[0].name = this.$t('slide.trans_option0');
          }
          
         
          this.transInOptions = [{ value: 0, text: this.$t('slide.trans_option0') }, { value: 1, text: this.$t('slide.trans_option1') }, { value: 2, text: this.$t('slide.trans_option2') }, { value: 3, text: this.$t('slide.trans_option3') }, { value: 4, text: this.$t('slide.trans_option4') }, { value: 5, text: this.$t('slide.trans_option5') }];
          this.gradOptions = [{ value: 0, text: this.$t('slide.grad_option1') }, { value: 1, text: this.$t('slide.grad_option2') }, { value: 2, text: this.$t('slide.grad_option3') }];
          this.weightOptions = [{ value: 300, text: this.$t('slide.weight_option1') }, { value: 600, text: this.$t('slide.weight_option2') }, { value: 900, text: this.$t('slide.weight_option3') }];
          this.actions = [{ id: 1, name: this.$t('slide.action_option1') }, { id: 2, name: this.$t('slide.action_option2') }, { id: 3, name: this.$t('slide.action_option3') }];
          this.timerTypeOptions = [{ name: this.$t('slide.progress_bar'), value: 0 }, { name: this.$t('slide.text_counter'), value: 1}];
          this.soundOptions = [{ name: this.$t('slide.sound_option0'), file_name: "" }, { name: this.$t('slide.sound_option1'), file_name: "alert-sound.wav" },
            { name: this.$t('slide.sound_option2'), file_name: "drum-sound.wav" }, { name: this.$t('slide.sound_option3'), file_name: "high-sound.wav" },
            { name: this.$t('slide.sound_option4'), file_name: "harp-sound.mp3" }, { name: this.$t('slide.sound_option5'), file_name: "ding-sound.mp3" }];
          this.defaultOptions = [{ id: 0, name: this.$t('slide.default_option0') }, { id: 1, name: this.$t('slide.default_option1')}];
          
          
          this.getSlide(this.slide.id,this.slide.section_id);
        }
      },
      'memberDescription.num_rows_x': {
        handler: function (v) {
          if(!this.preview){
            this.slide_members = new Array(this.memberDescription.num_rows_y * v).fill(0);
          }else{
            this.previewSlide();
          }
        },
        deep: true
      },
      'memberDescription.num_rows_y': {
        handler: function (v) {
          if(!this.preview){
             this.slide_members = new Array(this.memberDescription.num_rows_x*v).fill(0);
          }else{
            this.previewSlide();
          }
         
        },
        deep: true
      },
      'memberDescription.next_number': {
        handler: function (v) {
            this.slide_member.next = new Array(this.memberDescription.next_number).fill({name:this.$t('slide.next_name') });
         
        },
        deep: true
      },
      'preview_member_id': {
        handler: function () {
          if (this.preview) {
            this.previewSlide();
          }
        },
        deep: true
      },
      'goal.goal_id': {
        handler: function () {
          if (this.preview) {
            this.previewSlide();
          }
        },
        deep: true
      },
      'preview_attendance_id': {
        handler: function () {
          if (this.preview) {
            this.previewSlide();
          }
        },
        deep: true
      },
      'timer.type': {
        handler: function (v) {
          this.progressVariant = (v == 0 ? "success" : "blue");
          this.counterVariant = (v == 1 ? "success" : "blue");
          this.presentationVariant = (v == 2 ? "success" : "blue");
        },
        deep: true
      },
      
      'memberDescription.show_renewing_date': {
        handler: function (v) {
          if(!this.loadingSlide && this.memberDescription.type==14){
            if(v == 1){
             this.addSpecialType([{type:1, id: 'renew_date', text: '<div>{Special_Renew_Date}</div>'}]);
            }else{
              this.removeSingleSpecialType('renew_date');
            }
          }
           
        },
        deep: true
      }, 
      'memberDescription.type': {
        handler: function (v, o) {
          if(o != -1 && v!=o && !this.loadingSlide){
            this.removeSpecialType();
            this.memberDescription.member_id = 0;
            this.memberDescription.role_id = 0;
          }
          
          this.preview_member_id = 0;
          if (this.preview) {
            this.previewSlide();
          }
          this.goal.disabled = (v != 8);
          var found = false;
          if (v == 4 && this.memberDescription.member_id == 0 && this.members.length > 0) {
            this.memberDescription.member_id = this.members[0].id;
          } /*else if (v == 5 && this.multiRoles.length > 0) {
            for (var i = 0; i < this.multiRoles.length; i++) {
              if (this.memberDescription.role_id == this.multiRoles[i].id) { found = true; }
            }
            if (!found) { this.memberDescription.role_id = this.multiRoles[0].id; }
          } else if (v == 6 && this.singularRoles.length > 0) {
            for (var j = 0; j < this.singularRoles.length; j++) {
              if (this.memberDescription.role_id == this.singularRoles[j].id) { found = true; }
            }
            if (!found) { this.memberDescription.role_id = this.singularRoles[0].id; }
          } else */
          if (v == 7) {
            this.$set(this.slide_member, 'company', this.$t('slide.company_text'));
            this.$set(this.slide_member, 'category', this.$t('slide.invited_by_text'));
          }else if( v == 10 || v == 11 || v == 13){
           
              var slideObjects = [{type:1, id: 'referrals', text: '<div>{Special_Referrals}</div>'},
              {type:1, id: 'visitors', text: '<div>{Special_Visitors}</div>'},
              {type:1, id: 'one_to_ones', text: '<div>{Special_Ones}</div>'},
              {type:1, id: 'tyfcb', text: '<div>{Special_TYFCB}</div>'},
              {type:1, id: 'ceus', text: '<div>{Special_CEUs}</div>'},
              {type:1, id: 'measurement_period', text: '<div>{Special_Measurement_Period}</div>'},
              {type:1, id: 'measurement_day', text: '<div>{Special_Measurement_Day}</div>'}];//
              this.addSpecialType(slideObjects);
          }else if(v==12){
            var slideObjects2 = [{type:1, id: 'event_title', text: '<div>{Special_Event_Title}</div>'},
              {type:1, id: 'event_description', text: '<div>{Special_Event_Description}</div>'},
              {type:1, id: 'event_when', text: '<div>{Special_Event_When}</div>'},
              {type:1, id: 'event_submitted', text: '<div>{Special_Event_SubmittedBy}</div>'},
              {type:2, id: 'event_image'}];//
              this.addSpecialType(slideObjects2);
          }
          else if(v==14){
            var slideObjects3 = [{type:1, id: 'recog_note', text: '<div>{Special_Recog_Note}</div>'}];
            if(this.memberDescription.show_renewing_date == 1){
              slideObjects3.push({type:1, id: 'renew_date', text: '<div>{Special_Renew_Date}</div>'});
            }else{
              this.removeSingleSpecialType('renew_date');
            }
              this.addSpecialType(slideObjects3);
          }else if(v==16){
              this.addSpecialType([{type:2, id: 'quote_image'},
            {type:1, id: 'quote_text', text: '<div>{Special_Quote_Text}</div>'},
            {type:1, id: 'quote_source', text: '<div>{Special_Quote_Source}</div>'}]);
          }else if(v==20){
            var t = [{type:2, id: 'topic_picture'},
            {type:1, id: 'topic_title', text: '<div>{Special_Topic_Title}</div>'},
            {type:1, id: 'topic_contacts', text: '<div>{Special_Topic_Contacts}</div>'},
            {type:1, id: 'topic_description', text: '<div>{Special_Topic_Description}</div>'}];
            if(!this.permissions.organization.rst){
              t.push({type:1, id: 'topic_activity', text: '<div>{Special_Topic_Activity}</div>'})
            }
              this.addSpecialType(t);
            
          }else if(v==22){
            var t = [{type:1, id: 'topic_title', text: '<div>{Special_Topic_Title}</div>'}];
            this.addSpecialType(t);
          }
        },
        deep: true
      },
      'memberDescription.member_id': {
        handler: function () {
          if (this.preview) {
            this.previewSlide();
          }
        },
        deep: true
      },
      'memberDescription.role_id': {
        handler: function () {
          if (this.preview) {
            this.previewSlide();
          }
         
        },
        deep: true
      },
      'memberDescription.speaker_num': {
        handler: function () {
          if (this.preview) {
            this.previewSlide();
          }
        },
        deep: true
      },
      'memberDescription.company_or_role': {
        handler: function (v) {
          this.memberDescription.company_or_role = v;
          if (this.preview) {
            this.previewSlide();
          } else if (this.memberDescription.type != 7){
            this.$set(this.slide_member, 'company', this.$tc('slide.member_company', this.memberDescription.company_or_role==1 ? 0 : 1));
            this.$set(this.slide_member, 'category', this.$tc('slide.member_category', this.memberDescription.company_or_role==1 ? 0 : 1));
          }
        },
        deep: true
      },
      'permissions.organization.rst': {
        handler: function (v) {
          if(this.isCorporateConnections){
            this.typeFilters= [{value: 0, text: this.$t('slide.blank_text')},{value: 1, text: this.$t('slide.member_text')},{value: 2, text: this.$t('slide.visitor_text')}
          ,{value: 3, text: this.$t('slide.speaker_text')}];
          }else{
            this.typeFilters= [{value: 0, text: this.$t('slide.blank_text')},{value: 1, text: this.$t('slide.member_text')},{value: 2, text: this.$t('slide.visitor_text')}
          ,{value: 3, text: this.$t('slide.speaker_text')},{value: 4, text: this.$t('slide.regional_contact')},{value: 5, text: this.$t('slide.performance_data')}
          ,{value: 6, text: this.$t('slide.event_text')},{value: 7, text: this.$t('slide.quote_text')},{value: 8, text: this.$t('slide.goal_text')}
          ,{value: 9, text: v? this.$t('slide.topic_text') : this.$t('slide.powerteams_text')}];
          }
          
        },
        deep: true
      },
      gridNum: {
        handler: function (v) {
          this.gridNum = Math.max(Math.min(v,50),10);
        },
        deep: true
      },
      showGrid: {
        handler: function () {
          this.gridImage = this.showGrid? 'slide-hide-grid-button': 'slide-show-grid-button';
        },
        deep: true
      }
    },
    computed: {
      selectionOptions: function(){
        var f = [{ value: 1, text: this.$t('general.selection_option_0')  },{ value: 2, text:  this.$t('general.selection_option_1')  },{ value: 3, text:  this.$t('general.selection_option_2')  }];
        if(this.permissions.organization.official==0){
          f.push({ value: 4, text:  this.$t('general.selection_option_3')  });
        }
        if(this.permissions.organization.official==1){
          f.push({ value: 5, text:  this.$t('general.selection_option_4')  });
        }
        if(this.isCorporateConnections){
          f.push({ value: 6, text:  this.$t('general.selection_option_5')  });
        }
        return f;
      },
      multiMemberStyle: function(){
        var imageRatio=(this.memberDescription.circular==1 || this.memberDescription.type==21? 1 : 0.6157);
        var multiBoxHeight = (this.windowHeight*(this.memberDescription.height/(100*Math.min(this.memberDescription.num_rows_y,Math.ceil(this.slide_members.length/this.memberDescription.num_rows_x))) )-40);
        var multiImagePart = (this.memberDescription.multi_vertical==1? this.memberDescription.graphic_height/100.0: 1.0);
        var multiImagePart = this.memberDescription.graphic_height/100.0;
        var width = Math.round(multiBoxHeight*multiImagePart*imageRatio);
        var s = {'max-width': width+'px'};
        if(this.memberDescription.circular==1){
          multiImagePart = this.memberDescription.graphic_height/100.0;
          var multiBoxWidth = (this.windowWidth*(this.memberDescription.width/(100*Math.min(this.memberDescription.num_rows_x, this.slide_members.length)) )-40);
          s['max-height'] = Math.min(width, multiBoxWidth*multiImagePart)+'px';
        }
        return s;
      },
      backgroundStyle: function () {
        var s = { "background-color": "#ffffff" };
        if (this.slide.back_type == 0 && this.preview) {
          s = { "background-color": "#ffffff" };
        }else if (this.slide.back_type == 1) {
          s = { "background-color": this.slide.back_colour };
        } else if (this.slide.back_type == 2) {
          s = { "background-image": this.gradNames[this.slide.back_grad_type] + this.slide.back_colour_from + "," + this.slide.back_colour_to + ")" };
        } else if ((this.slide.back_type == 3 || this.slide.back_type == 4)) {
          var url = this.slide.photo && this.slide.photo.length<50 && this.slide.photo.length>0? this.getImgUrl("backgrounds/"+this.slide.photo) : this.slide.photo;
          s = { "background-image": "url(\"" + url + "\")" };
        }
        s["height"] = this.windowHeight + "px";
        return s;
      },
      role_num: function () {
        if (this.memberDescription.type == 5) {
          for (var i = 0; i < this.multiRoles.length; i++) {
            if (this.multiRoles[i].id == this.memberDescription.role_id) {
              return this.multiRoles[i].num;
            }
          }
        } else if (this.memberDescription.type == 6) {
          for (var j = 0; j < this.singularRoles.length; j++) {
            if (this.singularRoles[j].id == this.memberDescription.role_id) {
              return this.singularRoles[j].num;
            }
          }
        }
        return 0;
      }
    },
      data() {
        return {
          output: "",
          showRoleAlert: false,
          accentOptions: [{value: 0, text: this.$t('slide.accent_option0')},{value: 1, text: this.$t('slide.accent_option1')},{value: 2, text: this.$t('slide.accent_option2')}],
          meetingTypeOptions: [{value: 0, text: this.$t('slide.meeting_type0')},{value: 1, text: this.$t('slide.meeting_type1')},{value: 2, text: this.$t('slide.meeting_type2')}],
          typeOptions: [],
          typeFilter: 0,
          typeFilters: (this.isCorporateConnections? [{value: 0, text: this.$t('slide.blank_text')},{value: 1, text: this.$t('slide.member_text')},{value: 2, text: this.$t('slide.visitor_text')}
          ,{value: 3, text: this.$t('slide.speaker_text')}] : [{value: 0, text: this.$t('slide.blank_text')},{value: 1, text: this.$t('slide.member_text')},{value: 2, text: this.$t('slide.visitor_text')}
          ,{value: 3, text: this.$t('slide.speaker_text')},{value: 4, text: this.$t('slide.regional_contact')},{value: 5, text: this.$t('slide.performance_data')}
          ,{value: 6, text: this.$t('slide.event_text')},{value: 7, text: this.$t('slide.quote_text')},{value: 8, text: this.$t('slide.goal_text')}
          ,{value: 9, text: this.$t('slide.powerteams_text')}]) ,
          typeReverseMap: {0: 0, 1 :1 ,2 :1 ,4 :1 ,5 :1 ,6 :1, 14 :1 ,15 :1, 7: 2, 3:3, 19:3, 9:4,17:4,18:4, 10:5,11:5,13:5, 12:6, 16:7, 8:8, 20: 9, 21: 9, 22 : 9 },
          typeMap: {0: [0], 1 : [1,2,4,5,6,14,15], 2: [7], 3:[3,19], 4: [9,17,18], 5: [10,11,13], 6: [12], 7: [16], 8:[8], 9: [20,21,22]},
          text_color: '#000000',
          loadingSlide: false,
          editor: new Editor({
            disableInputRules: true,
            disablePasteRules: true,
            extensions: [
              new Blockquote(),
              new BulletList(),
              new HardBreak(),
              new HorizontalRule(),
              new ListItem(),
              new OrderedList(),
              new TodoItem(),
              new TodoList(),
              new Bold(),
              new Italic(),
              new Link(),
              new Strike(),
              new Underline(),
              new History(),
              new Superscript(),
              new SetColor()
            ],
            content: ''
          }),
          showChildSettings: false,
          nextOptions: [{id: 0, name: "0"},{id: 1, name: "1"},{id: 2, name: "2"},{id: 3, name: "3"}],
          copylanguage: 'en',
          copyOptions: [],
          languageOptions: [],
          showCopyTexts: false,
          contacts: [],
          showGalleryModal: false,
          tempPhoto: "",
          imgSrc: '',
          imgType: 'image/jpeg',
          showPhotoEdit: false,
          groupOptions: [{name: this.$t('slide.group_option1'), value: 'chapter'},{name: this.$t('slide.group_option2'), value: 'analytic'},
          {name: this.$t('slide.group_option3'), value: 'regional'},{name: this.$t('slide.group_option4'), value: 'role'},{name: this.$t('slide.group_option7'), value: 'transition'},
          {name: this.$t('slide.group_option5'), value: 'speaker'},{name: this.$t('slide.group_option6'), value: 'other'}],
          variableGroup: 'chapter',
          gridImage: 'slide-show-grid-button',
          showGrid: false,
          snapGrid: 1,
          gridNum: 30,
          row_options: [{ value: "1", name: "1" }, { value: "2", name: "2" }],
          next_slide: 0,
          previous_slide: 0,
          previous_section: 0,
          next_section: 0,
          current_rank: "(1/1)",
          showDelete: false,//'BlueAnalytics.jpg', 'BlueClear.jpg', 'BlueGrayBottom.jpg', 'BlueWhiteGrayFooter.jpg', 'BlueWhiteHalf.jpg',
                            // 'RedAnalytics.jpg', 'RedClear.jpg', 'RedGrayBottom.jpg', 'RedWhiteGrayFooter.jpg', 'RedWhiteHalf.jpg'
          backgrounds: process.env.VUE_APP_IS_CORPORATE==1? ['CCWhiteContent.jpg','CCBlueContent.jpg', 'CCGrayContent.jpg', 'CCWhitewithTopBox.png', 'CCWhitewithBrown.png','CC-3-way-Split.png','CC-Brown.png'] : [ 'FullbackLight.jpg', 'FullbackLight1.jpg', 'FullbackLight2.jpg', 'FullbackLight3.jpg', 'FullBackRed.jpg', 'FullRedCol.jpg', 'FullRedCol1.jpg', 'FullRedCol2.jpg', 'HandsTogether.jpg', 'Meeting.jpg', 
          'Passcard.jpg','BackCover.jpg', 'BackScreens.jpg', 'LargeCorner.png','SmallCorner.png'],
          showScriptEdit: false,
          currentSlideScript: {
            id: 0,
            script: ""
          },
          showHTMLImage: false,
          childView: false,
          showUpload: false,
          showFilesEdit: false,
          showGoalEdit: false,
          progressVariant: "success",
          counterVariant: "blue",
          selected_items: [],
          showTextAlert: false,
         
          timer: {id: 0, circular:0, circular_start:0, circular_end:0,default_option: 0,delay_time:0, time_default: 1, active:false,dragWidth:5, continuous: 0, count_direction: 0, disabled: 1, type: 0, x_pos: 0, y_pos: 0, x_px: 0, y_px: 0, width: 5, height: 5, font: "Arial", color: "#000000", back_color: "#ffffff", progressive: 0, num_plays: 1, sound_file: "", auto_start: 1, max_time: 1, secs: 1, mins: 0, direction: 0, countdown_change: 0, alter_colour: "#ffffff", alter_time: 0 },
          goal: {id: 0, goal_id: 0, disabled: 1,thermo_x:0,thermo_y:0,history_x:0,history_y:0,text_x:0,text_y:0, color: '#000000',description_color: '#000000', title_color: '#000000', notes_color: '#000000', height: 5, font: 'Arial', text_width: 33, history_font_size: 2, thermo: {x_pos: 0, y_pos: 0}, history: {x_pos: 0, y_pos: 0}, text: {x_pos: 0, y_pos: 0, width: 33}, description_size: 2, notes_size: 2, heading_colour: "#ffffff", table_colour: "#000000", heading_back_colour: '#1b629c', table_back_colour: '#ffffff', max_table_rows: 5},
           
          ratioOptions: {
            1: 9.0/16.0,
            2: 5.0/8.0,
            3: 3.0 / 4.0 ,
            4: 4.0/5.0
          },
          ratioNames: {
            1: "16x9",
            2: "8x5",
            3: "4x3",
            4: "5x4"
          },
          variable_map : [],
          special_map : [],
          alertText: "",
          showAlert: false,
          windowWidth: 1000,
          windowHeight: 1000,
          texts: [],
          graphics: [],
          memberDescription: {
            id:0, type: -1, member_id: 0, role_id: "0", name_font: "Arial", name_height: 5, name_color: "#2c3e50", name_weight: 600,show_company: 1, show_category: 1, selection: 1,circular: 0,
            company_font: "Arial", company_height: 5, company_color: "#2c3e50", company_weight: 300, category_font: "Arial", x_pos: 45, y_pos: 30, width:20,height:20, x_px: 0, y_px: 0,
            category_height: 5, category_color: "#2c3e50", category_weight: 300, name_back_color: "#ffffff", num_rows_x: 1, num_rows_y: 1, speaker_num: 1, company_or_role: 0, first_name: 0,
            next: {x_pos: 40, y_pos: 70,height: 10}, next_color: '#ffffff', next_font: 'Arial', alignment: 'left', graphic: {x_pos: 0, y_pos: 0, height: 100,width:34.633125}, graphic_height:100, graphic_width:34.633125,alternate_slide_id: 0,
            email_font:'Arial',email_height: 5,email_color: "#ffffff",email_weight: 300,show_email: 1,website_font: 'Arial',website_height: 5,website_color: "#ffffff",website_weight: 300,show_website: 1,
            phone_font: 'Arial',phone_height: 5,phone_color: "#ffffff",phone_weight: 300,show_phone: 1,next_number: 1, show_renewing_date: 0,best_weekly: 0, show_photo: 1,
            job_title_font: 'Arial',job_title_height: 5,job_title_color: "#ffffff",job_title_weight: 300,job_title_show: 0, show_accent: 1, multi_vertical: 1
          },
          fontOptions: [{ value: "Arial", text: "Arial" }, { value: "Georgia", text: "Georgia" }, { value: "Times", text: "Times" }, { value: "Verdana", text: "Verdana" }, { value: "Tahoma", text: "Tahoma" }, { value: "Raleway", text: "Raleway" }, {value: 'Arial Black', text: 'Arial Black'}],
          showTextEdit: false,
          showMembers: false,
          showSettings: false,
          showGraphicEdit: false,
          showTimerEdit: false,
          saveVariant: 'blue',
          slide_files: [],
          slide: { meeting_type: 0, language: '', show_recognition: 1, child_edit: 1, allow_editing: 1, name: "",  trans_in: 0, trans_out: 0, pause: 1, pauseSeconds: 5, replay: 0, replay_times: 0, then_action: 3, next_seconds: 0, back_colour: "#ffffff", back_colour_from: "#ffffff", back_colour_to: "#ffffff", back_grad_type:0, photo: null, back_type: 0, disabled:0},
          
          gradNames: ["linear-gradient(", "linear-gradient(to right,", "radial-gradient("],
          members: [],
          multiRoles: [],
          singularRoles: [],
          slide_member: {
            name: this.$t('slide.member_name'),
            company: this.$tc('slide.member_company', 0),
            category: this.$tc('slide.member_category', 0),
            email: this.$t('slide.email_text'),
            website: this.$t('slide.website_text'),
            phone: this.$t('slide.phone_text'),
             photo: tempMemberImage, next: [{name:this.$t('slide.next_name') }],
             job_title: this.$t('slide.job_title')
          },
          member_roles: [],
          contact_types: [],
          slide_goal: {history:[]},
          slide_members: [0],
          editTextObject: {special:''},
          editGraphicObject: {special:'', fit: 0},
          dragObject: null,
          preview: false,
          preview_member_id: 0,
          preview_attendance_id: 0,
          attendees: [],
          settings: { edit_ratio: 1, back_type: 1, back_color: "#ffffff", language: 'en', show_bni_accent: 0 },
          section_name: "",
          saving: false,
          fact_num: 0,
          facts: [],
          max_fact: { length: 14, event: this.$t('slide.this_fact')},
          factInterval: null,
          section_id: 0,
          sections: [],
          slides: [],
          counter:0,
          clickTimer:null,
          goals: [],
          tags: [],
          selected_tags: [],
          
          goal_fields: [
            {
              key: 'day',
              label: this.$t('slide.date_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
            { key: 'value',
              label: this.$t('slide.value_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            }
          ],
          recognitionTypes: [{id: 0, name: this.$t('slide.select_recognition')},{id: 1, name: this.$t('controller.both_text')},{id: 2, name: this.$t('controller.network_leader')},
          {id: 3, name: this.$t('controller.new_renewing')},{id: 4, name: this.$t('slide.top_121s')},{id: 5, name: this.$t('slide.top_referrals')},
          {id: 6, name: this.$t('slide.top_visitors')},{id: 7, name: this.$t('slide.top_tyfcb')},{id: 8, name: this.$t('slide.top_ceus')},{id: 9, name: this.$t('slide.top_points')}],
          transInOptions: [{ value: 0, text: this.$t('slide.trans_option0') }, { value: 1, text: this.$t('slide.trans_option1') }, { value: 2, text: this.$t('slide.trans_option2') }, { value: 3, text: this.$t('slide.trans_option3') }, { value: 4, text: this.$t('slide.trans_option4') }, { value: 5, text: this.$t('slide.trans_option5') }],
          gradOptions: [{ value: 0, text: this.$t('slide.grad_option1') }, { value: 1, text: this.$t('slide.grad_option2') }, { value: 2, text: this.$t('slide.grad_option3') }],
          alignOptions: [{ value: 'left', text: this.$t('slide.align_option1') }, { value: 'center', text: this.$t('slide.align_option2') }, { value: 'right', text: this.$t('slide.align_option3') }],
          vAlignOptions: [{ value: 0, text: this.$t('slide.valign_option1') }, { value: 1, text: this.$t('slide.valign_option2') }],
          weightOptions: [{ value: 300, text: this.$t('slide.weight_option1') }, { value: 600, text: this.$t('slide.weight_option2') }, { value: 900, text: this.$t('slide.weight_option3') }],
          actions: [{ id: 1, name: this.$t('slide.action_option1') }, { id: 2, name: this.$t('slide.action_option2') }, { id: 3, name: this.$t('slide.action_option3') }],
          timerTypeOptions: [{ name: this.$t('slide.progress_bar'), value: 0 }, { name: this.$t('slide.text_counter'), value: 1}],
          soundOptions: [{ name: this.$t('slide.sound_option0'), file_name: "" }, { name: this.$t('slide.sound_option1'), file_name: "alert-sound.wav" },
            { name: this.$t('slide.sound_option2'), file_name: "drum-sound.wav" }, { name: this.$t('slide.sound_option3'), file_name: "high-sound.wav" },
            { name: this.$t('slide.sound_option4'), file_name: "harp-sound.mp3" }, { name: this.$t('slide.sound_option5'), file_name: "ding-sound.mp3" }],
          defaultOptions: [{ id: 0, name: this.$t('slide.default_option0') }, { id: 1, name: this.$t('slide.default_option1')}, { id: 2, name: this.$t('slide.default_option2')}],
           trackingOptions: [
          { value: 0, text: this.$t('slide.select_performance') },
          { value: 1, text: this.$t('general.tracking_option_1') },
          { value: 2, text: this.$t('general.tracking_option_2') },
          { value: 4, text: this.$t('general.tracking_option_4') },
          { value: 8, text: this.$t('general.tracking_option_8') },
          { value: 16, text: this.$t('general.tracking_option_16') },
          { value: 32, text: this.$t('general.tracking_option_32') },
          { value: 64, text: this.$t('general.tracking_option_64') },
          
        ],
        tracking_nums: [],
        recog_members: [],
        region: {allow_renewing_date: 0}
        }
      },
    methods: {
      backgroundClick(){
        if (this.dragObject) {
          this.$set(this.dragObject, 'active', false);
          this.$set(this.dragObject, 'selected', false);
        }
      },
      filterChanged(v){
        if(this.typeMap[v] && this.typeMap[v].length==1){
          this.memberDescription.type = this.typeMap[v][0];
        }
      },
       getLanguages(){
        this.$http.post("/api/organization/languages", {}).then((response) => {
          if (response.data.success === true) {
            this.copyOptions = response.data.languages.filter(() => true);
            this.languageOptions = response.data.languages;
            this.languageOptions.unshift({name: this.$t('slide.user_language'), abbrev: ""});
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
      getTrackingPeriods(){
        this.$http.post("/api/organization/get/tracking_periods", {}).then((response) => {
          if (response.data.success === true) {
           this.tracking_nums = [];
              for(var i=0; i<this.trackingOptions.length;i++){
                if((response.data.tracking_periods & Math.pow(2,i)) != 0){
                  this.tracking_nums.push(this.trackingOptions[i]);
                }
              }
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
      setGrid(n) {
        this.showGrid = n;
      },
      setBack(b) {
        this.slide.photo = b;
      },
      getSlide(slide_id, section_id) {
        this.slide.id = slide_id;//this.$route.params.id;
        this.slide.section_id = section_id;//this.$route.params.sid;
        this.memberDescription.slide_id = this.slide.id;
        this.getSlides();
        if (this.slide.id > 0) {
          this.slide.joint=false;
          this.$http.post("/api/organization/slide/get", this.slide).then((response) => {
            if (response.data.success) {
              this.loadingSlide = true;
              this.childView = response.data.slide.is_parent==0? false : true;
              this.slide = response.data.slide;
              if (this.slide.section_id > 0) {
                this.$http.post("/api/meeting/section/get/slideinfo", { section_id: this.slide.section_id, id: this.slide.id }).then((response) => {
                  if (response.data.success) {
                    this.section_name = response.data.name;
                    this.next_slide = response.data.next_slide;
                    this.previous_slide = response.data.previous_slide;
                    this.next_section = response.data.next_section === 0 ? this.slide.section_id : response.data.next_section;
                    this.previous_section = response.data.previous_section === 0 ? this.slide.section_id : response.data.previous_section;
                    var ranks = response.data.ranks.split(",");
                    var i = 0;
                    for (i = 0; i < ranks.length; i++) {
                      if (this.slide.rank == ranks[i]) {
                        break;
                      }
                    }
                    this.current_rank = "(" + (i + 1) + "/" + ranks.length + ")";
                  }
                }).catch((errors) => { console.log(errors);});
              }
              if(response.data.region){
                this.region = response.data.region;
              }

              if (response.data.memberDescription) {
                this.memberDescription = response.data.memberDescription;
                this.$set(this.memberDescription, 'active', false);
                this.memberDescription.x_px = (this.memberDescription.x_pos / 100) * this.windowWidth;
                this.memberDescription.y_px = (this.memberDescription.y_pos / 100) * this.windowHeight;
                this.memberDescription.next = {x_pos: this.memberDescription.next_x_pos,
                                          y_pos: this.memberDescription.next_y_pos,
                                          height: this.memberDescription.next_height};
                this.memberDescription.graphic= {x_pos: this.memberDescription.graphic_x_pos, y_pos: this.memberDescription.graphic_y_pos, width: this.memberDescription.graphic_width, height: this.memberDescription.graphic_height};
                this.slide_member.next = new Array(this.memberDescription.next_number).fill({name:this.$t('slide.next_name') });
                this.typeFilter = this.typeReverseMap[this.memberDescription.type];
                }
              if(response.data.goal){
                this.goal = response.data.goal;
                this.$set(this.goal, 'goal_id', 0);
                this.$set(this.goal, 'thermo', {active: false,x_pos: this.goal.thermo_x, y_pos: this.goal.thermo_y, x_px: (this.goal.thermo_x / 100) * this.windowWidth,y_px: (this.goal.thermo_y / 100) * this.windowHeight});
                this.$set(this.goal, 'history', {active: false,x_pos: this.goal.history_x, y_pos: this.goal.history_y, x_px: (this.goal.history_x / 100) * this.windowWidth,y_px: (this.goal.history_y / 100) * this.windowHeight});
                this.$set(this.goal, 'text', {width: this.goal.text_width, active: false,x_pos: this.goal.text_x, y_pos: this.goal.text_y, x_px: (this.goal.text_x / 100) * this.windowWidth,y_px: (this.goal.text_y / 100) * this.windowHeight});
              }
              if (response.data.slide_files) {
                this.slide_files = response.data.slide_files;
                for (var j = 0; j < this.slide_files.length; j++) {
                  this.$set(this.slide_files[j], 'file', null);
                  this.$set(this.slide_files[j], 'filename', "");
                }
              }
              if (response.data.settings) {
                this.settings = response.data.settings;
                this.windowWidth = document.getElementById("main-view").offsetWidth;
                this.windowHeight = this.windowWidth * (this.ratioOptions[this.settings.edit_ratio]);
              }
              if (response.data.timer) {
                this.timer = response.data.timer;
                this.timer.x_px = (this.timer.x_pos / 100) * this.windowWidth;
                this.timer.y_px = (this.timer.y_pos / 100) * this.windowHeight;
                this.timer.mins = Math.floor(this.timer.max_time / 60);
                this.timer.secs = this.timer.max_time % 60;
                this.$set(this.timer, 'active', false);
                this.timer.dragWidth = 5;
              }
              this.texts = response.data.texts;
              for (var i = 0; i < this.texts.length; i++) {
                this.texts[i].html_id = "text" + this.texts[i].id;
                this.texts[i].tempId = this.texts[i].id;
                this.texts[i].x_px = (this.texts[i].x_pos / 100) * this.windowWidth;
                this.texts[i].y_px = (this.texts[i].y_pos / 100) * this.windowHeight;
                
                this.texts[i].dragWidth = 5;
                this.$set(this.texts[i], 'active', false);
                
              }
              this.graphics = response.data.graphics;
              for (i = 0; i < this.graphics.length; i++) {
                this.graphics[i].html_id = "graphic" + this.graphics[i].id;
                this.graphics[i].tempId = this.graphics[i].id;
                this.graphics[i].x_px = (this.graphics[i].x_pos / 100) * this.windowWidth;
                this.graphics[i].y_px = (this.graphics[i].y_pos / 100) * this.windowHeight;
                this.graphics[i].dragWidth = 5;
                this.$set(this.graphics[i], 'active', false);
              }
              var self = this;
              this.$nextTick(function(){
                self.loadingSlide = false;
              });
              this.populateVariables();
            }
            this.getRoles();
            this.getContactTypes();
          }).catch((errors) => {console.log(errors); });
        } else {
          this.getRoles();
          this.getContactTypes();
          this.memberDescription.type = 0;
          this.$http.post("/api/organization/slide/settings", {}).then((response) => {
            if (response.data.success && response.data.settings) {
              this.settings = response.data.settings;
              this.windowWidth = document.getElementById("main-view").offsetWidth;
              this.windowHeight = this.windowWidth * (this.ratioOptions[this.settings.edit_ratio]);
            }
            this.populateVariables();
          }).catch((errors) => { console.log(errors);});
        }
      },
      nextSlide() {
        this.getSlide(this.next_slide, this.next_section);
      },
      previousSlide() {
        this.getSlide(this.previous_slide, this.previous_section);
      },
      getSlides() {
        this.$http.post("/api/organization/slides", { section_id: this.slide.section_id, getDisabled: true }).then((response) => {
          if (response.data.success) {
            this.slides = response.data.slides;
            var index = -1;
            for(var i=0; i<this.slides.length;i++){
              if(this.slides[i].id == this.slide.id){
                index = i; break;
              }
            }
            if(index>-1){
              this.slides.splice(index, 1);
            }
            this.slides.unshift({ id: 0, name: this.$t('slide.none_text') });
          } 
        }).catch((errors) => {console.log(errors);
        });
      },
          previewSlide() {
             var t = []
             var i =0;
            if(this.memberDescription.type==5){
              for(i=0; i<this.member_roles.length;i++){
                t.push(this.member_roles[i].id);
              }
              this.memberDescription.role_id = t.join(",");
            }else if(this.memberDescription.type ==17){
              for(i=0; i<this.contact_types.length;i++){
                t.push(this.contact_types[i].id);
              }
              this.memberDescription.role_id = t.join(",");
            }
            if (this.preview_member_id==0 && this.members.length>0) {
              this.preview_member_id = this.members[0].id;
            }
            var req = { id: this.slide.id, memberDescription: this.memberDescription, member_id: this.memberDescription.type == 8? this.goal.goal_id : this.preview_member_id };
            this.$http.post("/api/organization/slide/data", req).then((response) => {
              if (response.data.success) {
                if ([1,2,3,4,6,7,9,14,18].indexOf(this.memberDescription.type) !== -1) {
                  if (response.data.slide_member) {
                    this.slide_member = response.data.slide_member;
                  } else {
                    this.slide_member = [{ event: this.$t('slide.nothing_happened') }];
                  }
                } else if ((this.memberDescription.type == 5 || this.memberDescription.type == 15 || this.memberDescription.type == 17 || this.memberDescription.type == 19 || this.memberDescription.type == 21 || this.memberDescription.type == 22) && response.data.slide_members) {
                  this.slide_members = response.data.slide_members.slice(0, Math.min(this.memberDescription.num_rows_x * this.memberDescription.num_rows_y, response.data.slide_members.length));
                }
                var self = this;
               

                if(response.data.slide_goal){
                  this.slide_goal = response.data.slide_goal;
                }

                
                this.slide.var_name = this.slide.name? this.slide.name : "";
                this.variable_map.forEach(function (item) {
                  if (self.slide.var_name.indexOf(item.id) >= 0) {
                    self.slide.var_name = self.slide.var_name.split(item.id).join(item.value);
                  } 
                });
                for (var i = 0; i < this.texts.length; i++) {
                  this.texts[i].var_text = this.texts[i].text;
                  this.variable_map.forEach(function (item) {
                    if(item.id == "{Current_Time}"){
                      item.value = self.$d(new Date(), 'time12') ;
                    }else if(item.id == "{Current_Date}"){
                      item.value = self.$d(new Date(), 'short') ;
                    }else if(item.id == "{Slide_Name}"){
                      item.value = self.slide.var_name;
                    }else if(item.id == "{Section_Topic}"){
                      item.value = self.slide.section_topic;
                    }else if(item.id == "{Categories_Wanted}"){
                      const cw = /{Categories_Wanted_([0-9]+)}/;
                      var gw = self.texts[i].var_text.split(cw);
                      if(gw.length==3){
                        var p = "<span id='categories-variable'><span class='category-entry'>" + item.raw.slice(0,gw[1]).join("</span><span class='category-entry'>") + "</span></span>";
                        self.texts[i].var_text = gw[0]+p+gw[2];
                      }
                    }else if(item.id == "{Categories_Wanted_Random}"){
                      const cwr = /{Categories_Wanted_Random_([0-9]+)}/;
                      var gr = self.texts[i].var_text.split(cwr);
                      if(gr.length==3){
                        var pr = "<span id='categories-variable'><span class='category-entry'>" + item.raw.slice(0,gr[1]).join("</span><span class='category-entry'>") + "</span></span>";
                        self.texts[i].var_text = gr[0]+pr+gr[2];
                      }
                    }
                    if (self.texts[i].var_text.indexOf(item.id) >= 0) {
                      var g = self.texts[i].var_text.split(item.id);
                      if(item.id.includes("QRCode")){
                        self.texts[i].show_qr = true;
                        self.texts[i].var_text = item.value;
                      }else if(g.length == 2 && g[0].slice(-3) == "<p>" && g[1].slice(0,4)=="</p>" && item.value == ""){
                        self.texts[i].var_text = g[0].slice(0,-3)+g[1].slice(4);
                      }else{
                        self.texts[i].var_text = g.join(item.value);
                      }
                      
                    }
                     
                  });
                  const regexpSize = /{Show_QRCode_(.*)}/;
                  const match = self.texts[i].var_text.match(regexpSize);
                  if(match && match.length>1){
                    self.texts[i].show_qr = true;
                    self.texts[i].var_text = match[1];
                  }

                  


                  if(this.texts[i].fit == 1){
                    this.fitTextToDiv(this.texts[i]);
                  }
                }
               
               if(response.data.special_map && response.data.special_map.length>0){
                 this.special_map = response.data.special_map;
                var ptexts = this.texts;
                var pgraphics = this.graphics.filter(v => v.special.length>0);
                for(var k=0; k<ptexts.length;k++){
                  if(ptexts[k].special>0){
                    ptexts[k].var_text = ptexts[k].text;
                  }
                  
                }
                this.special_map.forEach(function (item) {
                  if(item.type==1){
                    if(item.id=="{Special_Measurement_Day}" && item.value != ""){
                      item.value = self.$d(new Date(item.value), 'day_text');
                    }
                    if(item.id=="{Special_Measurement_Period}" && item.value != ""){
                      item.value = self.$t('general.tracking_option_'+item.value);
                    }
                    
                    for(var k=0; k<ptexts.length;k++){
                      if (ptexts[k].var_text.indexOf(item.id) >= 0) {
                        if(item.id.includes("QRCode")){
                          ptexts[k].show_qr = true;
                          ptexts[k].var_text = item.value;
                          ptexts[k].is_linkedin = (item.id == "{Member_Linkedin_QRCode}");
                        }else{
                          ptexts[k].var_text = ptexts[k].var_text.split(item.id).join(item.value);
                          var h = self.texts.indexOf(ptexts[k]);
                          self.$set(self.texts,h,ptexts[k]);
                        }
                      } 
                    }
                  }else if(item.type==2){
                    for(var b=0; b<pgraphics.length;b++){
                      if(pgraphics[b].special == item.id){
                        pgraphics[b].photo = item.value;
                      }
                    }
                  }
                });
               }

                this.preview = true;
              }
            }).catch((errors) => { console.log(errors);});
      },
      getTimeString(){
        var t = this.timer.time_default==0? 0 :  parseInt(this.timer.mins)*60+parseInt(this.timer.secs);
        return (Math.floor(t/60)>0? Math.floor(t/60)+":" : "")+((Math.floor(t)%60) < 10 && Math.floor(t/60)>0? "0"+(Math.floor(t)%60) : (Math.floor(t)%60))
      },
      populateVariables() {
        this.$http.post("/api/organization/slide/get/variables", {joint: false}).then((response) => {
          if (response.data.success) {
            if (response.data.variables.events) {
              this.facts = response.data.variables.events;
            } else {
              this.facts = [{ event: this.$t('slide.nothing_happened') }];
            }
            this.variable_map = response.data.variable_map;
            if (this.childView) {
              this.previewSlide();
            }
          }
          
        }).catch((errors) => {console.log(errors); });
      },
      cycleFacts() {
        if ( this.facts.length>0) {
          this.factInterval = setInterval(() => {
            document.getElementById("on-this-day").innerHTML = this.facts[this.fact_num].event;
            this.fact_num = (this.fact_num + 1) % this.facts.length;
          }, 10000);
        }
      },
      stopFacts() {
        if (this.factInterval) {
          clearInterval(this.factInterval);
        }
      },
      outlineSlide() {
        this.stopFacts();
        this.slide_members = new Array(this.memberDescription.num_rows_x * this.memberDescription.num_rows_y).fill(0);
        this.slide_member = {
          name: this.$t('slide.member_name'),
          company: this.memberDescription.type == 7 ? this.$set(this.slide_member, 'company', this.$t('slide.company_text')) :
           this.$tc('slide.member_company', this.memberDescription.company_or_role ? 0 : 1),
          category: this.memberDescription.type == 7 ? this.$set(this.slide_member, 'category', this.$t('slide.invited_by_text')) :this.$tc('slide.member_category', this.memberDescription.company_or_role ? 0 : 1),
          email: this.$t('slide.email_text'),
          website: this.$t('slide.website_text'),
          phone: this.$t('slide.phone_text'),
          job_title: this.$t('slide.job_title'),
          photo: tempMemberImage,
          next: new Array(this.memberDescription.next_number).fill({name:this.$t('slide.next_name') })
        };
            this.preview = false;
          },
      getMembers() {
        this.$http.post("/api/organization/members", {}).then((response) => {
          if (response.data.success) {
            this.members = response.data.members;
            this.members.unshift({ id: 0, name: this.$t('slide.select_member') });
          } else {
            this.members = [{ id: 0, name: this.$t('slide.select_member') }];
          }
              
            }).catch((errors) => {console.log(errors);});
          this.$http.post("/api/organization/recognition", {}).then((response) => {
              if (response.data.success) {
                for(var i=0; i<response.data.renewing_members.length; i++){
                  response.data.renewing_members[i].name = response.data.renewing_members[i].name+" ("+this.$t('role.renewing_members')+")";
                }
                for(i=0; i<response.data.network_leaders.length; i++){
                  response.data.network_leaders[i].name = response.data.network_leaders[i].name+" ("+this.$t('role.network_leaders')+")";
                }
                this.recog_members= response.data.renewing_members;
                this.recog_members.push.apply(this.recog_members, response.data.network_leaders);
                this.recog_members.unshift({ id: 0, name: this.$t('slide.selected_controller') });
              } else {
                this.recog_members = [{ id: 0, name: this.$t('slide.selected_controller') }];
              }
            }).catch((errors) => {console.log(errors);});
      },
       getContacts() {
        this.$http.post("/api/organization/contacts/assigned", {}).then((response) => {
          for(var i=0; i<response.data.contacts.length;i++){
            response.data.contacts[i].name = response.data.contacts[i].name+ (response.data.contacts[i].contact_name.length>0? " ("+response.data.contacts[i].contact_name+")" : "");
          }
          this.contacts = response.data.contacts;
          this.contacts.unshift({id: 0, name: this.$t('organizations.select_member')});
        }).catch((errors) => {console.log(errors);});
      },
      getAttendees() {
        this.$http.post("/api/organization/attendance/get/attendees", {}).then((response) => {
          if (response.data.success) {
            this.attendees = response.data.attendees;
            this.attendees.unshift({ id: 0, name: this.$t('slide.selected_controller') });
          }
        }).catch((errors) => { console.log(errors);});
      },
      validateSlide() {
            this.showAlert = false;
            this.alertText = "";
            if (!this.slide.name || this.slide.name.length < 1) {
              this.alertText += this.$t('slide.slide_alert1');
              this.showAlert = true;
            }
            if (this.slide.then_action!= 3 && this.slide.pauseSeconds < 1) {
              this.alertText += this.$t('slide.slide_alert2');
              this.showAlert = true;
            }
            return !this.showAlert;
          },
          saveSlide(){
            if(!this.childView){
              this.updateSlide();
            }else{
              this.updateParentSlide();
            }
          },
        updateParentSlide(){
            var override = {};
            override.selection = this.memberDescription.selection;
            override.next_number = this.memberDescription.next_number;
            override.sound_file = this.timer.sound_file;
            override.progressive = this.timer.progressive;
            override.num_plays  = this.timer.num_plays;
            override.continuous = this.timer.continuous ;
            override.auto_start = this.timer.auto_start ;
            override.delay_time = this.timer.delay_time ;
            override.direction = this.timer.direction;
            override.slide_id = this.slide.id;
            override.disabled = this.slide.disabled;
            override.language = this.slide.language;
            this.$http.post("/api/organization/slide/override", override).then((response) => {
            if (response.data.success === true) {
              this.saveVariant = 'success';
              this.back();
            } else {
              this.saveVariant = 'danger';
            }
            this.saving = false;
          }).catch(() => {this.saving = false; this.$bvToast.show('serverError');});
        },
      updateSlide: async function() {
        this.showGrid = false;
        if (this.childView && !this.saving) {
          this.saving = true;
          this.updateSlideScript();
        } else if (!this.childView && !this.saving && this.validateSlide()) {
          this.saving = true;
          this.timer.max_time = Math.max(this.timer.mins, 0) * 60 + Math.max(this.timer.secs);
          this.timer.num_plays = parseInt(this.timer.progressive) > 0 ? this.timer.num_plays : 1;
          if (document.getElementById("timer-text")) {
            this.timer.width = (document.getElementById("timer-text").getBoundingClientRect().width / this.windowWidth) * 100;
          } else if (document.getElementById("timer-bar")) {
            this.timer.width =(document.getElementById("timer-bar").getBoundingClientRect().width / this.windowWidth) * 100;
          } else {
            this.timer.width = 5;
          }

          this.slide.thumbnail = await this.makeImage();
          this.goal.thermo_x = this.goal.thermo.x_pos;
          this.goal.thermo_y = this.goal.thermo.y_pos;
          this.goal.history_x = this.goal.history.x_pos;
          this.goal.history_y = this.goal.history.y_pos;
          this.goal.text_x = this.goal.text.x_pos;
          this.goal.text_y = this.goal.text.y_pos;
          this.goal.text_width = this.goal.text.width;
           var t = [];
          if(this.tags.length!=0){
            for(var i=0; i<this.selected_tags.length;i++){
              t.push(this.selected_tags[i].id);
            }
            this.slide.tags = t.join(",");
          }

          this.memberDescription.next_x_pos = this.memberDescription.next.x_pos;
          this.memberDescription.next_y_pos = this.memberDescription.next.y_pos;
          this.memberDescription.next_height = this.memberDescription.next.height;

          this.memberDescription.graphic_x_pos = this.memberDescription.graphic.x_pos;
          this.memberDescription.graphic_y_pos = this.memberDescription.graphic.y_pos;
          if(([1,2,3,4,6,7,9,14,18].indexOf(this.memberDescription.type) !== -1 && this.memberDescription.show_photo==1)){
            this.memberDescription.graphic_height = this.memberDescription.graphic.height;
            this.memberDescription.graphic_width = this.memberDescription.graphic.width;
          }else{
            this.memberDescription.graphic_height = this.memberDescription.height? this.memberDescription.height: 5;
            this.memberDescription.graphic_width = this.memberDescription.width? this.memberDescription.width : 5;
          }

          if(this.memberDescription.type==5){
            t = []
            for(i=0; i<this.member_roles.length;i++){
              t.push(this.member_roles[i].id);
            }
            this.memberDescription.role_id = t.join(",");
          }else if(this.memberDescription.type ==17){
            t = []
            for(i=0; i<this.contact_types.length;i++){
              t.push(this.contact_types[i].id);
            }
            this.memberDescription.role_id = t.join(",");
          }

          this.memberDescription.role_id = ""+this.memberDescription.role_id;

          this.$http.post("/api/organization/slide/update", {
            slide: this.slide, texts: this.texts, graphics: this.graphics,
            memberDescription: this.memberDescription, timer: this.timer, goal: this.goal
          }).then((response) => {
            if (response.data.success === true) {
              this.saveVariant = 'success';
              this.uploadFiles();
            } else {
              this.saveVariant = 'danger';
            }
            this.saving = false;
          }).catch((errors) => {console.log(errors); this.saving = false; this.$bvToast.show('serverError');});
        }
      },
      fitTextToDiv(text){
        var self = this;
        setTimeout(function () {
          var d = document.getElementById(text.html_id);
          if(d){
            var i = parseFloat(d.children[0].style.fontSize.slice(0,-2));
            if(isNaN(i)){
              i = parseFloat(d.style.fontSize.slice(0,-2));
            }
            var oi = i;
            if(d.children[0].scrollHeight<d.offsetHeight-oi){
              for(var t = 0; t<100000;t++){
                if(d.children[0].scrollHeight<d.offsetHeight-oi){
                    d.children[0].style.fontSize = d.children[0].style.lineHeight = i+"px";
                    i+= (d.children[0].scrollHeight<d.offsetHeight-3*oi)? .5 : .1;
                }else{
                  break;
                }
              }
            }else{
              for(var b = 0; b<100000;b++){
                if(d.children[0].scrollHeight>d.children[0].offsetHeight+3){
                    d.children[0].style.fontSize = d.children[0].style.lineHeight = i+"px";
                    i-= (d.children[0].scrollHeight>d.children[0].offsetHeight+3*oi)? .5 : .1;
                }else{
                  break;
                }
            }
            }
            if(d.children[0].scrollHeight>d.children[0].offsetHeight+3){
              i-=.1;
            }
            text.size = (i/self.windowHeight)*100;
            d.children[0].style.fontSize = d.children[0].style.lineHeight = "";
          }else{
            self.fitTextToDiv(text);
          }
        }, document.getElementById(text.html_id)? 0 : 50);
      },
      fitTextObjectToDiv(k){
        var self = this;
        self.texts[k].size = 2;
        /*setTimeout(function () {
        var d = document.getElementById(self.texts[k].html_id);
        console.log(d);
         var i = parseFloat(d.children[0].style.fontSize.slice(0,-2));
         if(isNaN(i)){
           i = parseFloat(d.style.fontSize.slice(0,-2));
         }
         var oi = i;
          if(d.children[0].scrollHeight<d.offsetHeight-oi){
            for(var t = 0; t<100000;t++){
              if(d.children[0].scrollHeight<d.offsetHeight-oi){
                  self.texts[k].size+=.1;
              }else{
                break;
              }
            }
          }else{
            for(var b = 0; b<100000;b++){
              if(d.children[0].scrollHeight>d.children[0].offsetHeight+3){
                  self.texts[k].size-=.1;
              }else{
                break;
              }
          }
          }
          console.log(self.texts[k].size);
           }, 50);*/
      },
      showMemberEdit() {
        if (!this.childView) {
          this.showMembers = true
        }
      },
      showTimeEdit() {
        if (!this.childView) {
          this.showTimerEdit = true
        }
      },
      deleteSlide() {
        this.$http.post("/api/organization/slide/delete", this.slide).then((response) => {
          if (response.data.success === true) {
            this.back();
          }
        }).catch((errors) => {console.log(errors);});
      },
      back(){
        
        window.history.length > 1 ? router.go(-1) : router.push("/admin/agenda");
      },
      onFileChange(ev,el) {
        var files = ev.target.files || ev.dataTransfer.files;
        if (!files.length || !this.checkFileSize(files[0]))
          return;
        this.createImage(files[0],el);
  },
      onFileFileChange(e, fileElement) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length || !this.checkFileSize(files[0]))
          return;
        fileElement.filename = files[0].name;
        fileElement.file = files[0];
        fileElement.new = true;
      },
          createImage(file,el) {
            this.resizeImage(file, 1920, 1080, function (dataurl) {
              el.photo = dataurl;
            });
          },
          openDialog() {
            document.getElementById('fileid').click();
      },
      openFileDialog(file) {
        document.getElementById('fileupload'+file.number).click();
      },
          openDialog2() {
            document.getElementById('fileid2').click();
          },
          getRoles() {
            this.$http.post("/api/organization/roles/select", {}).then((response) => {
              if (response.data.success) {
                 var ids = (this.memberDescription.role_id+"").split(",");
                this.member_roles = [];

                for (var i = 0; i < response.data.roles.length; i++) {
                  //if (!response.data.roles[i].singular) {
                  response.data.roles[i].id = response.data.roles[i].id+"";
                  this.multiRoles.push(response.data.roles[i]);
                  //}
                  this.singularRoles.push(response.data.roles[i]);
                  
                }
                for(i=0; i<ids.length; i++){
                  var k = response.data.roles.filter(v => v.id+"" == ids[i])
                  if(k.length==1){
                    this.member_roles.push(k[0]);
                  }
                }
                this.singularRoles.unshift({id: 0, name: this.$t('slide.select_role'), singular: 1, num: 0});
                //this.multiRoles.unshift({id: 0, name: this.$t('slide.trans_option0'), singular: 1, num: 0});
              }
            }).catch((errors) => { console.log(errors);});
          },
    copyTextObjects(l){
     this.$http.post("/api/organization/slide/get/texts/bylanguage", {id: this.slide.id, language: l}).then((response) => {
        if (response.data.success) {
          var maxId = 0;
          for (var i = 0; i < this.texts.length; i++) {
            maxId = Math.max(maxId, this.texts[i].tempId);
          }

          var temp_texts = response.data.texts;
          for (i = 0; i < temp_texts.length; i++) {
            if(temp_texts[i].special.length==0){
              maxId += 1;
              var o = Object.assign({}, temp_texts[i])
              o.id = 0;
              o.html_id = "text" + maxId;
              o.tempId = maxId;
              o.language = this.$i18n.locale;
              this.texts.push(o);
            }
          }
          this.showCopyTexts = false;
        }
      }).catch((errors) => { console.log(errors);});
      
    },
    removeSpecialType(){
      this.texts = this.texts.filter(v => v.special.length==0);
       this.graphics = this.graphics.filter(v => v.special.length==0);
    },
    removeSingleSpecialType(special){
      this.texts = this.texts.filter(v => v.special != special);
       this.graphics = this.graphics.filter(v => v.special.length != special);
    },
    addSpecialType(slideObjects){
      for(var i=0; i<slideObjects.length;i++){
        if(slideObjects[i].type==1){
           var addTextObect = true;
            for(var j=0; j<this.texts.length;j++){
              if(this.texts[j].special == slideObjects[i].id){
                addTextObect = false;
                break;
              }
            }
            if(addTextObect){
              this.addText(false);
              this.texts[this.texts.length-1].special = slideObjects[i].id;
              this.texts[this.texts.length-1].text = slideObjects[i].text;
              this.texts[this.texts.length-1].x_pos = 10*i;
              this.texts[this.texts.length-1].x_px = (this.texts[this.texts.length-1].x_pos/100)*this.windowWidth;
            }
        }else if (slideObjects[i].type==2){
          var addGraphic = true;
            for(var k=0; k<this.graphics.length;k++){
              if(this.graphics[k].special == slideObjects[i].id){
                addGraphic = false;
                break;
              }
            }
            if(addGraphic){
              this.addGraphic(false);
              this.graphics[this.graphics.length-1].special = slideObjects[i].id;
              this.graphics[this.graphics.length-1].x_pos = 10*i;
              this.graphics[this.graphics.length-1].x_px = (this.graphics[this.graphics.length-1].x_pos/100)*this.windowWidth;
            }
        }
       
      }
    },
    deselectAll(o){
      if(this.dragObject){
        this.dragObject.selected = false;
        this.dragObject.active = false;
      }
      for(var i=0; i<this.texts.length;i++){
        this.texts[i].active = false;
        this.texts[i].selected = false;
      }
      for(i=0; i<this.graphics.length;i++){
        this.graphics[i].active = false;
        this.graphics[i].selected = false;
      }
      this.dragObject = o;
      this.dragObject.active = true;
      this.dragObject.selected = true;
    },

    addText(select) {
      
      var maxId = 0;
      for (var i = 0; i < this.texts.length; i++) {
        maxId = Math.max(maxId, this.texts[i].tempId);
      }
      maxId += 1;
      var ti=0;
      var tj=0;
      if(this.texts.length>0){
        ti = Math.max.apply(Math, this.texts.map(function(o) { return o.z_pos; }));
      }
      if(this.graphics.length>0){
        tj = Math.max.apply(Math, this.graphics.map(function(o) { return o.z_pos; }));
      }
      var tz = Math.max(ti,tj)+1;
      if(tz==null){
        tz = 1;
      }
      this.texts.push({
        id: 0, size: 5, html_id: "text" + maxId, tempId: maxId, text: this.$t('slide.text_text') + (this.texts.length + 1), language: this.$i18n.locale,  var_text: "", dragWidth: 5, active: false, selected: false,
        x_pos: 0, y_pos: 0, x_px: 0, y_px: 0, z_pos:tz, width: 10, height: 10, slide_id: this.slide.id, font: "Arial", color: "#2c3e50", weight: 300, alignment: 'left', spacing: 1.0, special: '', fit: 0, 
        back_color: 'rgba(0,0,0,0)', rotation: 0, border_color: 'rgba(0,0,0,0)', ellipse: 0,vertical_align: 0});
      if(select){
        this.deselectAll(this.texts[this.texts.length-1]);
      }
     },
    
      setTextFromEditor(){
       
        if (this.editTextObject) {
          /*var temp = this.editor.getHTML();
          if(temp.length == 0 || temp == "<p></p>"){
            this.showTextAlert = true;
            return;
          }*/
          this.editTextObject.text = this.editor.getHTML();
            if (!this.editTextObject.text)  {
              this.editTextObject.text = "";
            }
          }
          if (this.preview) {
            var self = this;
            this.editTextObject.var_text = this.editTextObject.text;
            
            this.editTextObject.show_qr = false;
            this.variable_map.forEach(function (item) {
              if(item.id == "{Current_Time}"){
                  item.value = self.$d(new Date(), 'time12') ;
                }else if(item.id == "{Current_Date}"){
                  item.value = self.$d(new Date(), 'short') ;
                }
              if (self.editTextObject.var_text.indexOf(item.id) >= 0) {
                var g = self.editTextObject.var_text.split(item.id);
                if(item.id.includes("QRCode")){
                    self.editTextObject.show_qr = true;
                    self.editTextObject.var_text = item.value;
                }else if(g.length == 2 && g[0].slice(-3) == "<p>" && g[1].slice(0,4)=="</p>" && item.value == ""){
                  self.editTextObject.var_text = g[0].slice(0,-3)+g[1].slice(4);
                }else{
                  self.editTextObject.var_text = g.join(item.value);
                }
               
              }
            });
          }
           this.showTextAlert = false;
          this.showTextEdit = false;
      },
      editText(text) {
        if (this.dragObject) {
          this.dragObject.active = false;
        }
        if (!this.childView) {
          this.editTextObject = text;
          this.editor.setContent(text.text);
           this.showTextAlert = false;
          this.showTextEdit = true;
        }
            
          },
          deleteText() {
            var index = this.texts.indexOf(this.editTextObject);
            if (index > -1) {
              this.texts.splice(index, 1);
            }
            this.showTextEdit = false;
          },
          addGraphic(select) {
            var maxId = 0;
            for (var i = 0; i < this.graphics.length; i++) {
              maxId = Math.max(maxId, this.graphics[i].tempId);
            }
            maxId +=1;

            var ti=0;
            var tj=0;
            if(this.texts.length>0){
              ti = Math.max.apply(Math, this.texts.map(function(o) { return o.z_pos; }));
            }
            if(this.graphics.length>0){
              tj = Math.max.apply(Math, this.graphics.map(function(o) { return o.z_pos; }));
            }
            var tz = Math.max(ti,tj)+1;
            if(tz==null){
              tz = 1;
            }
            this.graphics.push({
              id: 0, html_id: "graphic" + maxId, tempId: maxId, photo: tempImage, special: '',
              x_pos: 0, y_pos: 0, x_px: 0, y_px: 0, z_pos: tz, width: 10, height: 10, slide_id: this.slide.id, dragWidth: 5, active:select, selected: select, fit: 0, rotation: 0
            });
            if(select){
                this.deselectAll(this.graphics[this.graphics.length-1]);
              }
          },
          deleteGraphic() {
            var index = this.graphics.indexOf(this.editGraphicObject);
            if (index > -1) {
              this.graphics.splice(index, 1);
            }
            this.showGraphicEdit = false;
          },
      editGraphic(graphic) {
        if (this.dragObject) {
          this.dragObject.active = false;
        }
        if (!this.childView) {
          this.editGraphicObject = graphic;
          this.showGraphicEdit = true;

          this.imgSrc = this.editGraphicObject.photo;
          this.imgType = "image/png";
          let base64ContentArray = this.imgSrc.split(",")     
          if(base64ContentArray != null){
            var matches = base64ContentArray[0].match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/);
            if(matches != null){
               this.imgType = matches[0];
            }
          }
          
          if(this.imgType != "image/gif"){
            this.$refs.cropper.replace(this.editGraphicObject.photo);
          }
        }
      },
      //removed dragging event form mouseup in vue-drag-resize
      resize(newRect) {
        
        if (this.gridNum > 0 && this.showGrid && this.snapGrid==1) {
          let partitiony = this.windowHeight / this.gridNum;
          newRect.top = Math.round(newRect.top / partitiony) * partitiony;
          let partitionx = this.windowWidth / this.gridNum;
          newRect.left = Math.round(newRect.left / partitionx) * partitionx;
        }

        if (!this.childView && this.dragObject) {
          this.dragObject.x_pos = (newRect.left / this.windowWidth) * 100;
          this.dragObject.y_pos = (newRect.top / this.windowHeight) * 100;
          var f = this.texts.indexOf(this.dragObject);
          
          this.dragObject.width = (this.dragObject == this.timer && this.timer.type == 0) || f>-1 || this.dragObject==this.memberDescription || this.dragObject==this.memberDescription.graphic
            || (this.dragObject == this.goal.text) || (this.dragObject.special && this.dragObject.special.length>0) || this.dragObject.fit==1? (newRect.width / this.windowWidth) * 100 : "fit-content";
         this.dragObject.height = (newRect.height / this.windowHeight) * 100;
        }
      },
      onTextClick(e, t) {
        if (this.childView) {
          return;
        }
        this.selectItem(e,t);
        var self = this;
        this.counter++;
        if (this.counter == 1) {
          this.clickTimer = setTimeout(function () {
            self.counter = 0;
          }, 400);
        } else {
          clearTimeout(this.clickTimer);
          self.counter = 0;
          self.editText(t);
        }
      },
      onGraphicClick(e, t) {
        if (this.childView) {
          return;
        }
        this.selectItem(e,t);
        var self = this;
        this.counter++;
        if (this.counter == 1) {
          this.clickTimer = setTimeout(function () {
            self.counter = 0;
          }, 400);
        } else {
          clearTimeout(this.clickTimer);
          self.counter = 0;
          self.editGraphic(t);
        }
      },
      onMemberDescClick(e){
        if (this.childView) {
          return;
        }
        this.selectItem(e,this.memberDescription);
        var self = this;
        this.counter++;
        if (this.counter == 1) {
          this.clickTimer = setTimeout(function () {
            self.counter = 0;
          }, 400);
        } else {
          clearTimeout(this.clickTimer);
          self.counter = 0;
          self.showMemberEdit();
        }
      },
      onTimerClick(e) {
        if (this.childView) {
          return;
        }
        this.selectItem(e,this.timer);
        var self = this;
        this.counter++;
        if (this.counter == 1) {
          this.clickTimer = setTimeout(function () {
            self.counter = 0;
          }, 400);
        } else {
          clearTimeout(this.clickTimer);
          self.counter = 0;
          self.showTimerEdit = true;
        }
      },
      showTimer(){
        this.showTimerEdit=true; 
        this.timer.disabled==1? this.timer.type=1 : this.timer.type = this.timer.type; 
        this.timer.disabled=0; 
        this.deselectAll(this.timer);
      },
      onGoalClick() {
        //this.selectItem(e,this.goal);
        if (this.childView) {
          return;
        }
        var self = this;
        this.counter++;
        if (this.counter == 1) {
          this.clickTimer = setTimeout(function () {
            self.counter = 0;
          }, 400);
        } else {
          clearTimeout(this.clickTimer);
          self.counter = 0;
          self.showGoalEdit = true;
        }
      },
      selectItem(e, t){
        this.$set(t, 'selected', true);
        if(e.shiftKey){
          if(!(t in this.selected_items)){
            this.selected_items.push(t);
          }
        }else{
          for(var g in this.selected_items){
            this.$set(this.selected_items[g], 'selected', false);
          }
          this.selected_items = [t];
        }
      },
      
      onActivated(o) {
        if (this.dragObject) {
          this.$set(this.dragObject, 'active', false);
        }
        if (this.childView) {
          return;
        }
        this.dragObject = o;
        this.$set(this.dragObject, 'active', true);
        //this.dragObject.dragWidth = document.getElementById(this.dragObject.html_id).getBoundingClientRect().width;
      },
      playSound() {
        if (this.timer.sound_file.length > 0) {
          var audio = new Audio(this.getImgUrl("sounds/"+this.timer.sound_file)); // path to file
          audio.volume = 0.5;
          audio.play();
        }
      },
      onResize() {
        this.windowWidth = document.getElementById("main-view").offsetWidth;
        this.windowHeight = this.windowWidth * (this.ratioOptions[this.settings.edit_ratio]);
            
      },
      addTextVariable(variable) {
        const { state } = this.editor;
        var t = this.editor.getHTML();

        var a = state.selection.$anchor.nodeBefore != null? state.selection.$anchor.nodeBefore.text : "";
        var b = state.selection.$anchor.nodeAfter != null? state.selection.$anchor.nodeAfter.text: "";

        var parts = t.split(a+b);
        if(parts.length == 2){
          this.editor.setContent(parts[0]+a+variable.id+b+parts[1]);
        }else{
          this.editor.setContent(t+variable.id);
        }
      },
      /*addTextVariable(variable) {
        if (this.editTextObject) {
          this.editTextObject.text += variable.id;
        }
      },*/
      addFileLink() {
        for (var k = 1; k <= 3; k++) {
          var found = false;
          for (var i = 0; i < this.slide_files.length; i++) {
            if (this.slide_files[i].number == k) {
              found = true;
            } 
          }
          if (!found) {
            this.slide_files.push({ slide_id: this.slide.id, number: k, type: 1, link: "", description: "", filename: "", file: null });
            break;
          }
        }
      },
      removeFileLink(file) {
        var index = this.slide_files.indexOf(file);
        if (index > -1) {
          this.slide_files.splice(index, 1);
        }
      },
      uploadFiles() {
        this.showUpload = true;
        this.saveVariant = "";

        let formData = new FormData();
        for (var i = 0; i < this.slide_files.length; i++) {
          if (this.slide_files[i].type == 1 && this.slide_files[i].file && this.slide_files[i].file != null) {
            formData.append("files", this.slide_files[i].file);
            this.slide_files[i].link = "";
            this.slide_files[i].name = this.slide_files[i].file.name;
          }
          this.slide_files[i].file = null;
        }
        formData.append("slide_files", JSON.stringify(this.slide_files));
        formData.append("slide_id", this.slide.id);
        this.$http.post("/api/organization/slide/update/files", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }).then((response) => {
          this.showUpload = false;
          this.saveVariant = "blue";
          if (response.data.success == true) {
            this.back();
          }
          }).catch((errors) => {console.log(errors);
          this.showUpload = false;
          this.saveVariant = "blue";
          this.$bvToast.show('serverError');
        });
      },
      getSlideScript() {
        if (this.slide != null) {
          this.currentSlideScript = {
            id: 0,
            above_text: "",
            script: "",
            below_text: ""
          };
          this.$http.post("/api/meeting/slide/get/script", this.slide).then((response) => {
            if (response.data.success && response.data.script) {
              this.currentSlideScript = response.data.script;
            } else {
              this.currentSlideScript = {};
            }
          }).catch((errors) => {console.log(errors);
          });
        }
      },
      updateSlideScript() {
        if (this.currentSlideScript != null && this.currentSlideScript.id > 0) {
          this.$http.post("/api/meeting/slide/update/script", this.currentSlideScript).then(() => {
            this.uploadFiles();
          }).catch((errors) => {console.log(errors);
          this.$bvToast.show('serverError');
          });
        }
      },
      makeImage: async function() {
        if(this.dragObject){
          this.dragObject.selected = false;
          this.dragObject.active = false;
        }
        
        //var node = document.getElementById('main-view"');
        const el = this.$refs.printMe;
        // add option type to get the image version
        // if not provided the promise will return 
        // the canvas.

        var sc = 300 / this.windowWidth;

        const options = {
          type: 'dataURL',
          scale: sc,
          logging: false,
          
        }
        return await this.$html2canvas(el, options);
        //this.showHTMLImage = true;
      },
       rotateImageLeft(){
        this.$refs.cropper.rotate(90);
        this.cropMove();
      },
      rotateImageRight(){
        this.$refs.cropper.rotate(-90);
        this.cropMove();
      },
      flipImageX(){
        var d = this.$refs.cropper.getData()
        this.$refs.cropper.scaleX(d.scaleX*-1);
        this.cropMove();
      },
      flipImageY(){
        var d = this.$refs.cropper.getData()
        this.$refs.cropper.scaleY(d.scaleY*-1);
        this.cropMove();
      },
      openPhotoEdit(){
        this.showPhotoEdit=true
        if(this.imgSrc && this.imgSrc.length>0){
          this.cropMove();
        }
      },
      cropMove() {
        if(this.editGraphicObject && this.imgType != 'image/gif' ){
          this.editGraphicObject.photo = this.$refs.cropper.getCroppedCanvas().toDataURL(this.imgType,1.2);
        }
      },
      dropHandler(ev){
        if(ev){
          ev.preventDefault();
          var self = this;
          if (ev.dataTransfer.items) {
            for (var i = 0; i < ev.dataTransfer.items.length; i++) {
              if (ev.dataTransfer.items[i].type.includes("image") && this.checkFileSize(ev.dataTransfer.items[i])) {
                var blob = ev.dataTransfer.items[i].getAsFile();
                self.addGraphic(true);
                self.editGraphicObject = self.graphics[self.graphics.length-1];
                self.setFile(blob);
                self.showGraphicEdit = true;
                break;
              }
            }
          }/* else {
            for (var j = 0; j < ev.dataTransfer.files.length; j++) {
              console.log(ev.dataTransfer.files[j]);
            }
          }*/
        }
      },
      pasteImage(){
        navigator.permissions.query({ name: "clipboard-read" }).then((result) => {
          // If permission to read the clipboard is granted or if the user will
          // be prompted to allow it, we proceed.
          var self = this;
          if (result.state == "granted" || result.state == "prompt") {
            navigator.clipboard.read().then((data) => {
              for (let i = 0; i < data.length; i++) {//
                if (!data[i].types.includes("image/png")) {
                  alert("Clipboard contains non-image data. Unable to access it.");
                } else {
                  data[i].getType("image/png").then((blob) => {
                    self.setFile(blob);
                  });
                }
              }
            });
          }
        });
          /*var item = pasteEvent.clipboardData.items[0];
          if (item.type.indexOf("image") === 0){
              var blob = item.getAsFile();
              this.setFile(blob);
          }*/
      },
      setImage(e) {
        this.showPhotoEdit=true
        const file = e.target.files[0];
        if (!file.type.includes('image/')) {
          return;
        }
        /*if(file.type=="image/gif"){
          this.imgType = file.type;
          var reader = new FileReader();
          reader.onload = function (e) {
            //var img = document.createElement("img");
            //img.src = e.target.result;
            self.imgSrc = e.target.result;
            //img.onload = function () {

           // }
          }
          reader.readAsDataURL(file);
          console.log(file);
          return;
        }*/
        this.setFile(file);
      },
      setFile(file){
        if (typeof FileReader === 'function') {
          var self = this;
          this.imgType = file.type;
          if(file.type=="image/gif"){
            var reader = new FileReader();
            reader.onload = function (e) {
              self.imgSrc = e.target.result;
              self.editGraphicObject.photo =e.target.result;
            }
            reader.readAsDataURL(file);
            return;
          }
          this.resizeImage(file, 1920, 1080, function (dataurl) {
            self.imgSrc = dataurl;
            self.$refs.cropper.replace(dataurl);
            setTimeout(function () {
              if(self.editGraphicObject){
                self.editGraphicObject.photo = self.$refs.cropper.getCroppedCanvas().toDataURL(file.type, 1.2);
              }
            }, 200);
          });
        }
      },
      resizeImage(file, scale_width, scale_height, onEnd) {
        var reader = new FileReader();
        reader.onload = function (e) {
          var img = document.createElement("img");
          img.src = e.target.result;
          img.onload = function () {
            var width = img.width;
            var height = img.height;
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");
            
            ctx.drawImage(img, 0, 0);
            var MAX_WIDTH = scale_width;
            var MAX_HEIGHT = scale_height;
            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
            } else {
              if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }
            }
            canvas.width = width;
            canvas.height = height;
            if(file.type.includes("j")){
              ctx.fillStyle = '#fff'; 
              ctx.fillRect(0, 0, canvas.width, canvas.height);
            }
            ctx.drawImage(img, 0, 0, width, height);
            var dataurl = canvas.toDataURL(file.type, 1.2);
            onEnd(dataurl);
          }
        }
        reader.readAsDataURL(file);
      },
      cropImage() {
        this.member.photo = this.$refs.cropper.getCroppedCanvas().toDataURL(this.imgType, 1.2);
        this.showPhotoEdit = false;
      },
      sendToBack(){
        if(this.dragObject && this.dragObject.z_pos != undefined){
          for(var i =0; i<this.texts.length;i++){
            this.texts[i].z_pos++;
          }
          for(i =0; i<this.graphics.length;i++){
            this.graphics[i].z_pos++;
          }
          this.dragObject.z_pos = 1;
        }
      },
      bringToFront(){
        if(this.dragObject && this.dragObject.z_pos != undefined){
          var ti=0;
          var tj=0;
          if(this.texts.length>0){
            ti = Math.max.apply(Math, this.texts.map(function(o) { return o.z_pos; }));
          }
          if(this.graphics.length>0){
            tj = Math.max.apply(Math, this.graphics.map(function(o) { return o.z_pos; }));
          }
          var tz = Math.max(ti,tj)+1;
          if(tz==null){
            tz = 1;
          }
          this.dragObject.z_pos = tz;
        }
      },
      deleteSelected(){
        for(var g in this.selected_items){
          var index = this.texts.indexOf(this.selected_items[g]);
          if (index > -1 && this.selected_items[g].special == "") {
            this.texts.splice(index, 1);
            continue;
          }
          index = this.graphics.indexOf(this.selected_items[g]);
          if (index > -1) {
            this.graphics.splice(index, 1);
          }
        }
        this.selected_items = [];
      },
      copySelected(){
        this.permissions.copy_items = [];
        for(var g in this.selected_items){
          if(!(this.selected_items[g].special && this.selected_items[g].special.length>0)){
            var t = JSON.parse(JSON.stringify(this.selected_items[g]));
            t.selected = false;
            t.active = false;
            this.permissions.copy_items.push(t);
          }
        }
      },
      pasteFormat(){
        if(this.permissions.copy_items.length>0 && this.permissions.copy_items[0].spacing && this.selected_items.length>0 && this.selected_items[0].spacing){
          var format = this.permissions.copy_items[0];
          var item = this.selected_items[0];
          item.spacing = format.spacing;
          item.font = format.font;
          item.color = format.color;
          item.alignment = format.alignment;
          item.size = format.size;
          item.weight = format.weight;
        }
      },
      pasteItems(){
        var maxId = 0;
        for (var i = 0; i < this.texts.length; i++) {
          maxId = Math.max(maxId, this.texts[i].tempId);
        }
        maxId += 1;

        var gMaxId = 0;
        for (i = 0; i < this.graphics.length; i++) {
          gMaxId = Math.max(gMaxId, this.graphics[i].tempId);
        }
        gMaxId += 1;
            
        for(var g in this.permissions.copy_items){
          var t = JSON.parse(JSON.stringify(this.permissions.copy_items[g]));
          if(t.html_id && t.html_id.startsWith("text")){
            if(t.slide_id == this.slide.id){
              t.x_pos = 0;
              t.x_px = 0;
              t.y_pos = 0;
              t.y_px = 0;
            }
            t.html_id = "text"+maxId;
            t.tempId = maxId; 
            t.id = 0;
            maxId+=1;
            t.slide_id = this.slide.id
            t.language= this.$i18n.locale
            this.texts.push(t);
            
          } else if(t.html_id && t.html_id.startsWith("graphic")){
            if(t.slide_id == this.slide.id){
              t.x_pos = 0;
              t.x_px = 0;
              t.y_pos = 0;
              t.y_px = 0;
            }
            t.html_id = "graphic"+gMaxId;
            t.tempId = gMaxId;
            t.id = 0
            gMaxId+=1;
            t.slide_id = this.slide.id
            this.graphics.push(t);
          }else if(t.member_id != undefined){
            t.slide_id = this.slide_id;
            t.id = this.memberDescription.id;
            this.memberDescription = t;
          }else if(t.time_default != undefined){
            t.slide_id = this.slide_id;
            t.id = this.timer.id;
            this.timer = t;
          }
          this.deselectAll(t);
        }
      },
      getGoals(){
        this.$http.post("/api/goals/select", {}).then((response) => {
            if(response.data.success){
              this.goals = response.data.goals;
              this.goals.unshift({ id: 0, name: this.$t('slide.selected_controller') });
            }else{
              this.goals = [];
            }
          }).catch((errors) => {console.log(errors);
          });
      },
      getTags() {
          this.$http.post("/api/tags/get", {}).then((response) => {
            if (response.data.success) {
              this.tags = response.data.tags;
              var ids = this.slide.tags.split(",");
               for(var i=0; i<this.tags.length;i++){
              if(ids.indexOf(this.tags[i].id+"")>=0){
                this.selected_tags.push(this.tags[i]);
              }
            }
            } 
          }).catch(() => {
          });
        },
        showGalleryOptions(){
          this.showGalleryModal = true;
          if(this.tags.length==0){
              this.getTags();
          }
        },
        deleteKey(){//e
         /* if (e.keyCode == 46 && !this.showGraphicEdit && !this.) {
            var index = this.texts.indexOf(this.dragObject);
            if (index > -1) {
              this.texts.splice(index, 1);
              this.showTextEdit = false;
            }
            index = this.graphics.indexOf(this.dragObject);
            if (index > -1) {
              this.graphics.splice(index, 1);
              this.showGraphicEdit = false;
            }
          }*/
        },
        getPeriods(){
        this.$http.post("/api/organization/reports/get/all", {}).then((response) => {
            if(response.data.success){
              for (var i = 0; i < response.data.reports.length; i++) {
                response.data.reports[i].variant = "grey-slide-outline";
                response.data.reports[i].text= this.$t('general.tracking_option_'+response.data.reports[i].period_id)+" "+this.$t('slide.for_text')+" "+response.data.reports[i].day;
              }
              this.performancePeriods = response.data.reports;
            }else{
              this.performancePeriods = [];
            }
          }).catch((errors) => {console.log(errors);
          });
      },
      getContactTypes(){
        this.$http.post("/api/contact/types/get", {}).then((response) => {
          this.contact_types = [];
          if (response.data.success) {
            var ids = (this.memberDescription.role_id+"").split(",");
            for(var i=0; i<ids.length; i++){
              var k = response.data.types.filter(v => v.id+"" == ids[i])
              if(k.length==1){
                this.contact_types.push(k[0]);
              }
            }
            this.typeOptions = response.data.types;
          }
        }).catch((errors) => {console.log(errors);
          });
      },
      viewFile(h){
            router.push("/filecontrol/"+h, 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
          },
          viewLink(l){
          window.open(l, "_blank", 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
        },
    },
    created() {
      window.addEventListener("resize", this.onResize);
      window.addEventListener("keydown", this.deleteKey);
    },
    destroyed() {
      this.stopFacts();
      window.removeEventListener("resize", this.onResize);
      window.removeEventListener("keydown", this.deleteKey);
      this.removeClass(document.getElementById("menu-agenda"), "active-menu");
      this.removeClass(document.getElementById("menu-meeting"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-agenda"), "active-menu");
      this.addClass(document.getElementById("menu-meeting"), "active-menu");
      this.windowWidth = document.getElementById("main-view").offsetWidth;
      this.windowHeight = this.windowWidth * (this.ratioOptions[this.settings.edit_ratio]);
      this.memberDescription.x_px = (this.memberDescription.x_pos / 100) * this.windowWidth;
      this.memberDescription.y_px = (this.memberDescription.y_pos / 100) * this.windowHeight;

      this.typeFilters=  (this.isCorporateConnections? [{value: 0, text: this.$t('slide.blank_text')},{value: 1, text: this.$t('slide.member_text')},{value: 2, text: this.$t('slide.visitor_text')}
          ,{value: 3, text: this.$t('slide.speaker_text')}] : [{value: 0, text: this.$t('slide.blank_text')},{value: 1, text: this.$t('slide.member_text')},{value: 2, text: this.$t('slide.visitor_text')}
          ,{value: 3, text: this.$t('slide.speaker_text')},{value: 4, text: this.$t('slide.regional_contact')},{value: 5, text: this.$t('slide.performance_data')}
          ,{value: 6, text: this.$t('slide.event_text')},{value: 7, text: this.$t('slide.quote_text')},{value: 8, text: this.$t('slide.goal_text')}
          ,{value: 9, text: this.$t('slide.powerteams_text')}]);
      this.getLanguages();
      this.getSlide(this.$route.params.id, this.$route.params.sid);
      
      this.getMembers();
      this.getAttendees();
      this.getGoals();
      this.getContacts();
      this.getLanguages();
      this.getTrackingPeriods();
      this.getPeriods();
      
      var self = this;
      document.onpaste = function(pasteEvent) {
          var item = pasteEvent.clipboardData.items[0];
          if (item.type.indexOf("image") === 0){
              var blob = item.getAsFile();
              self.addGraphic(true);
              self.editGraphicObject = self.graphics[self.graphics.length-1];
              self.setFile(blob);
              self.showGraphicEdit = true;
          }
      }
    }
    }
</script>
