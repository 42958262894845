<template>
<div class="editor">
    <editor-menu-bar :editor="editor"  v-slot="{ commands, isActive, focused }">
        <div class="editor-menu is-hidden fit-width" :class="{ 'is-focused': focused }" >
            <b-button v-if="!smallFormat" variant="outline-dark" class="tiptap-button" :class="{ 'is-active': isActive.bold() }" v-on:click.prevent="commands.bold"><b>B</b></b-button>
            <b-button v-if="!smallFormat" variant="outline-dark" class="tiptap-button" :class="{ 'is-active': isActive.italic() }" v-on:click.prevent="commands.italic"><i>I</i></b-button>
            <b-button v-if="!smallFormat" variant="outline-dark" class="tiptap-button" :class="{ 'is-active': isActive.underline() }" v-on:click.prevent="commands.underline"><u>U</u></b-button>
            <b-button v-if="!smallFormat" variant="outline-dark" class="tiptap-button" :class="{ 'is-active': isActive.heading({ level: 1 }) }" v-on:click.prevent="commands.heading({ level: 1 })">H1</b-button>
            <b-button v-if="!smallFormat" variant="outline-dark" class="tiptap-button" :class="{ 'is-active': isActive.heading({ level: 2 }) }" v-on:click.prevent="commands.heading({ level: 2 })">H2</b-button>
            <b-button v-if="!smallFormat" variant="outline-dark" class="tiptap-button" :class="{ 'is-active': isActive.link() }" v-on:click.prevent="showLinkPrompt(commands.link)"><b-icon-link45deg></b-icon-link45deg></b-button>
            <b-button v-if="!smallFormat" variant="outline-dark" class="tiptap-button" :class="{ 'is-active': getTextAlign('left')}"  @click.prevent="commands.aligntext({ align: 'left' })">
                <b-icon-text-left></b-icon-text-left>
            </b-button>
            <!-- :class="{ 'is-active': editor.activeNodeAttrs.aligntext.align === 'left' }"-->
            <b-button v-if="!smallFormat" variant="outline-dark" class="tiptap-button" :class="{ 'is-active': getTextAlign('center')}" @click.prevent="commands.aligntext({ align: 'center' })">
                <b-icon-text-center></b-icon-text-center>
            </b-button>
            
            <b-button v-if="!smallFormat" variant="outline-dark" class="tiptap-button" :class="{ 'is-active': getTextAlign('right')}" @click.prevent="commands.aligntext({ align: 'right' })">
                <b-icon-text-right></b-icon-text-right>
            </b-button>
            <b-button v-if="!smallFormat" variant="outline-dark" class="tiptap-button" :class="{ 'is-active': isActive.bullet_list() }" v-on:click.prevent="commands.bullet_list">
              <b-icon-list-ul></b-icon-list-ul>
            </b-button>
            <b-button variant="outline-dark" class="tiptap-button"  v-on:click.prevent="commands.setcolor({ color: text_color })">
                  <b-icon-brush></b-icon-brush>
                </b-button>
                 <colorpicker style="height:34px;width:40px;" :color="text_color" v-model="text_color" :top="false"/>
            <!--<b-button variant="outline-dark" class="tiptap-button" v-on:click.prevent="showImagePrompt(commands.image,538)">IMG</b-button>-->
        </div>
    </editor-menu-bar>
    <editor-content :class="{'editor__content': !smallFormat, 'editor__content-small': smallFormat}" class=" fit-height blur-editor" :editor="editor"  style="width:100%;overflow: auto; margin-top: 6px;padding:10px;"/>
    <b-modal id="modal-center" v-model="showLinkModal" centered :title="$t('newsletter.link_title')">
          <div style="display:grid;grid-template-columns: max-content auto;">
              <span class="form-box-name">{{$t('newsletter.link_url')}}</span><b-form-input style="width: 400px;" v-model="linkurl" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" ></b-form-input>
              <div style="color:#aaaaaa; font-size:14px;grid-column:1/3;">*{{$t('newsletter.link_note')}}</div>
              <div style="color:#aaaaaa; font-size:14px;grid-column:1/3;">*{{$t('newsletter.mail_note')}}</div>
          </div>
          
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="submit-button4" v-on:click="showLinkModal=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="submit-button4" v-on:click="setLink(linkurl)" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
          </div>
        </b-modal>
</div>
</template>
<style scoped>
.blur-editor{
    border: 1px solid #ccc;
}
.blur-editor:focus{
    box-shadow: 0 0 2px 5px #bfdeff;
    border: 1px solid #62b9f5;
}
.tiptap-button{
    color:black;
    background-color: white;
    border:none;
    width: 40px;
    padding: 5px 0px;
}
.tiptap-button:hover{
    color: white;
    background-color: gray;
}
.is-active{
    background-color: lightgray;
}
 .editor-menu{
    display:flex; flex-direction: row;flex-wrap: wrap;
    grid-gap: 5px;
 }
</style>
<script>
import colorpicker from './Colorpicker.vue';
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import SetColor from '@/assets/SetColor'
import AlignText from '@/assets/TextAlign.js'; 
  import {
    Heading,
    Bold,
    Italic,
    Link,
    Underline,
   OrderedList,
    BulletList,
    ListItem,
    Blockquote,
    HardBreak,
    HorizontalRule,
    TodoItem,
    TodoList,
    Strike,
    History
  } from 'tiptap-extensions'
    export default {
    name: "BaseEditor",
     props: {content: Object, smallFormat: {type: Boolean, default: false}},
     components: {
      EditorContent,
      EditorMenuBar,
      colorpicker,
    },
     watch: {
      'content.update': {
        handler: function (v) {
          this.content.text = v;
          this.editor.setContent(this.content.text);
        },
        deep: true
      },
     },
  data() {
    return {
      linkurl: '',
      showLinkModal: false,
      text_color: '#000000',
      editor: new Editor({
       // disableInputRules: true,
          // disablePasteRules: true,
          //disablePasteRules: ['aligntext'],
          /*editorProps:{ 
          transformPasted: function(slice){
            console.log(slice.toJSON());
          }},*/
          parseOptions: {preserveWhitespace: 'full'},
                extensions: [
                new Heading({ levels: [1, 2, 3] }),
                new Bold(),
                new Italic(),
                new Link(),
                new Underline(),
                new AlignText(),
                new ListItem(),
                new BulletList(),
                new OrderedList(),
                new SetColor(),
                new Blockquote(),
                new HardBreak(),
                new HorizontalRule(),
                new TodoItem(),
                new TodoList(),
                new Strike(),
                new History()
                ],
                content: '',
                onBlur: ({ event }) => {
                    this.content.text = this.editor.getHTML();
                }
            }),
    };
  },
  methods: {
    getTextAlign(align){
      const state = this.editor.state
        const { from, to } = state.selection;
        var a = 'left';
        state.doc.nodesBetween(from, to, (node) => {
          if(node.attrs.align){
            a = node.attrs.align;
          }
        })
        return a==align;
    },
    setLink(href){
        href = ((href.startsWith("http") || href.startsWith("mailto:") || href.startsWith("tel:")? "" : "http://") + href) ;
          if(this.command != null){
              this.command({ href : href });
            this.showLinkModal = false;
            this.command = null;
          }
      },
    showLinkPrompt(command) {
        this.command = command;
        const state = this.editor.state
        const { from, to } = state.selection
        let marks = []
        state.doc.nodesBetween(from, to, (node) => {
          marks = [...marks, ...node.marks];
        })

        const mark = marks.find((markItem) => markItem.type.name === 'link')
        if (mark && mark.attrs.href) {
         this.linkurl = mark.attrs.href;
        }else{
          this.linkurl = '';
        }

        this.showLinkModal = true;
      },
  },
  created(){
    //this.content.text = this.content.update;
     this.editor.setContent(this.content.text);
  },
    
  beforeDestroy() {
      this.editor.destroy();
    },
}
</script>