<template>
  <div id="public-container" :style="{'grid-template-rows': isCorporateConnections? 'auto 150px max-content auto' : 'auto max-content auto'}">
      <div></div>
      <div v-if="isCorporateConnections"><img style="margin:auto;display:block;" src="../assets/public-cc-banner.png" /></div>
      <div style="max-width: 800px; margin: auto;">
        <div style="font-size: 40px; text-align: center;font-weight: bold;">
            {{event.organization_name}}
        </div>
        <div style="display:grid;grid-template-rows: auto auto;padding: 40px 0;margin:auto;display: grid;" class="fit-width">
            <img v-if="event.image" style="height:200px;margin: 20px auto;" :src="event.image"/>
            <div style="font-size: 30px;min-width: 400px;text-align: center;line-height: 35px;">
                <div class="event-title" :style="{'color': isCorporateConnections? '#FFD700' : '#CF2030'}">{{event.title}}</div>
                <div style="font-size: 35px;">{{event.when_description}}</div>
                <br/>
                <div style="text-align:left;">{{event.description}}</div>
                <div><a :href='(event.link.startsWith("http") ? "" : "http://") + event.link' target="_blank">{{event.link}}</a></div>
            </div>
        </div>
        <div style="color: #777;font-size: 20px;">
           {{event.ask}}
        </div>
      </div>
      <div></div>
  </div>
</template>
<style scoped>
#public-container{
  display:grid;  height: 100%; 
}
.event-title{
  font-size: 40px;font-weight:bold;margin-bottom:10px;
}
</style>
<script>
    export default {
    name: "PublicEvent",
      data() {
        return {
            event: {title: "", when_description: '', description: '', link: '',image: '', organization_name: ''}
        }
      },
    methods: {
      getEvent(){
          var hash = this.$route.params.h;
          this.$http.post("/api/organization/event/get/bypublichash", {hash: hash}).then((response) => {
            this.event = response.data.event;
        }).catch(() => {});
      }
    },
    mounted() {
        this.getEvent();
    }
    }
</script>
