import { Node } from 'tiptap';
import { wrappingInputRule,toggleWrap,toggleBlockType, setBlockType, textblockTypeInputRule } from 'tiptap-commands';

export default class AlignText extends Node {
	get name() {
		return 'aligntext';
	}

	get schema() {
		return {
            content: 'inline*',
            group: 'block',
            defining: true,
            draggable: false,
            attrs: {
				align: {
					default: 'left',
				},
			},
			parseDOM: [
				{
                    tag: 'div[style]',
                    getAttrs: dom => ({
                      align: dom.getAttribute('style').split(":")[1],
                    })
                  },
			],
			toDOM:
            node => {
                return ['div', {
                  style: `text-align:${node.attrs.align}`
                }, 0]
              }
             //mark => ['span', { style: `text-align: ${mark.attrs.align}; display: block` }, 0],
		};
	}

	commands({ type, schema }) {
        return attrs => toggleBlockType(type, schema.nodes.paragraph,attrs)
      }
    
      
}