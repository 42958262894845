<template>
  <div style="display: grid; height: 100%;">
    <div style="display: grid;">
      <div></div>
      <div class="form-box" id="raspberry-description-box">
        <span style="font-weight:bold; font-size:30px;color:#641935;">{{permissions.organization.name}}</span>
        <span style="color:#000;font-size: 24px;">{{permissions.user.name}}</span>
        <div style="font-weight:bold; font-size:30px;color:#333;">{{next_meeting_text}}</div>
        <div style="display:grid;">
          <div></div>
          <div style="display:grid;">
            <div style="display: grid;margin:auto">
              <div></div>
              <button v-show="permissions.access" style="position:relative;" id="startButton" class="dashButton" type="button" v-on:click="startMeeting"></button>
              <div></div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
        <div id="raspberry-tests">
          <button v-show="permissions.access" v-on:click="playSound()" id="start-sound-button" class="raspberry-dashButton" type="button"></button>
          <button v-show="permissions.access" v-on:click="checkFile()" id="check-file-button" class="raspberry-dashButton" type="button"></button>
          <button v-show="permissions.access" v-on:click="logout()" id="raspberry-logout-button" class="raspberry-dashButton" type="button"></button>
        </div>
    </div>
    <div v-if="isOnline" style="margin:0 auto;">
      <b-button class="submit-button4" v-show="permissions.access && totalSlides==0" v-on:click="getSlideShow" variant="clear-blue"><div class="import-button"></div><span>Pre-Load</span></b-button>
      <b-button class="submit-button4" v-show="permissions.access && totalSlides != 0 && totalSlides == slidesRetreived" v-on:click="startOfflineMeeting" variant="clear-blue"><div class="next-button"></div><span>Start Offline Meeting</span></b-button>
      <br/><b-progress style="height: 50px;" v-show="totalSlides>0" :value="slidesRetreived" :max="totalSlides" show-progress animated></b-progress>
    </div>
  </div>
</template>
<style scoped>
#raspberry-tests{
  display:grid;margin:auto;grid-template-columns: auto auto auto;
}
#raspberry-description-box{
  grid-template-columns: auto;text-align:center;max-width: 400px;margin: auto;min-width: 400px;min-height: 300px;
}
#start-sound-button {
      background-image: url("../assets/nav-soundcheck-off.png");
      padding: 0px;
    }
      #start-sound-button:hover {
        background-image: url("../assets/nav-soundcheck-on.png");
        background-color: rgba(0,0,0,0);
        border: none;
      }
#startButton {
      position: relative;
      background-repeat: no-repeat;
      background-size: 100%;
      margin: 0px auto;
      background-image: url("../assets/nav-startmeeting-off.png");
      min-width: 100px;
      width: 100px;
      height: 100px;
      background-color: rgba(0,0,0,0);
      border: none;
    }

      #startButton:hover {
        background-image: url("../assets/nav-startmeeting-on.png");
        background-color: rgba(0,0,0,0);
        border: none;
      }
.dashButton {
      background-color: rgba(0,0,0,0);
      border: none;
    }
.raspberry-dashButton{
  background-color: rgba(0,0,0,0);
  border: none;
  width: 80px;
  height: 69px;
  background-size: 100%;
}

</style>
<script>
      
    import router from "../router"
    import sound1 from "../assets/sounds/ding-sound.mp3"
    import backImage from "../assets/nav-soundcheck-on.png"
    export default {
      name: "Raspberry",
      data() {
        return {
          controllerText: "",
          fileInput: null,
          next_meeting: "",
          orgid: 0,
          time_interval: null,
          showAlert: false,
          showSuccess: false,
          alertText: "",
          successText: "",
          feedback: {bug: true, name: "", description: "" },
          showFeedback: false,
          next_meeting_text: "",
          audio_object: null,
          audio_timeout: null,
          totalSlides: 0,
          slidesRetreived: 0
        }
      },
    methods: {
      checkFile() {
        window.open("https://garoca-my.sharepoint.com/:p:/g/personal/meetinghub_garo_ca/EU2tv_pbM9NAvN178J5_pW4BkhyyuZLeu1DBd9WVbDzgJw?action=embedview")
      },
        playSound() {
          if (this.audio_object) {
            if (!this.audio_object.paused && !this.audio_object.paused) {
              clearTimeout(this.audio_timeout);
              this.audio_object.pause();
              if (document.getElementById("start-sound-button")) {
                document.getElementById("start-sound-button").style.backgroundImage = '';
                document.getElementById("start-sound-button-text").style.color = '';
              }
            } else {
              this.audio_object.play();
              if (document.getElementById("start-sound-button")) {
                document.getElementById("start-sound-button").style.backgroundImage = 'url(' + backImage + ')';
                document.getElementById("start-sound-button-text").style.color = '#000000';
              }
            }

          }
        },
        startMeeting() {
          router.push("/meeting");
        },
        startOfflineMeeting(){
          router.push("/meeting/true");
        },
        getDates() {
          this.$http.post("/api/organization/dates", {}).then((response) => {
            if (response.data.success === true) {
              this.next_meeting = response.data.next_formatted;
              this.orgid = response.data.id;
              this.next_meeting_text = this.next_meeting.length > 0 ? new Date("" + this.next_meeting + " 00:01").toLocaleDateString("en-US", { hour12: true, year: 'numeric', month: 'long', day: 'numeric' }) : "";
            }
          }).catch(() => { });
        },
        onResize() {
          
        },
        auth() {
          var self = this;
          if(this.isOnline){
            this.genericAuthenticate("/api/auth","/login2",function(){
              document.title = self.$t('global.document_title') +" "+ self.permissions.organization.name;
            });
          }else{
            router.push("/offline");
          }
          
        },
        logout() {
          this.$http.get("/api/logout")
            .then(() => {
              router.push("/login2");
            })
            .catch(() => {
              router.push("/login2");
            });
        },
        getSlideShow: async function(){
          var self = this;
          this.$http.post("/api/meeting/sections/slides", {}).then((response) => {
            var meeting = {sections: {}};
            meeting.facts = response.data.variables.events? response.data.variables.events : [{ event: "Apparently nothing of note occured." }];
            meeting.variable_map = response.data.variable_map;
            
            this.totalSlides = 0;
            this.slidesRetreived = 0;
            for (var i = 0; i < response.data.sections.length; i++) {
              for (var j = 0; j < response.data.sections[i].slide_data.length; j++) {
                meeting.sections[response.data.sections[i].section_id].slides[response.data.sections[i].slide_data[j].id] = response.data.sections[i].slide_data[j];
              }
              meeting.sections[response.data.sections[i].section_id] = response.data.sections[i];
              if(meeting.sections[response.data.sections[i].section_id] && meeting.sections[response.data.sections[i].section_id].slides){
                this.totalSlides += meeting.sections[response.data.sections[i].section_id].slides.length;
              }
            }
            return meeting;
          }).then(function(m){
            var slides = [];
            for(var s in m.sections){
              if(m.sections[s].slides && m.sections[s].slides.length>0){
                for(var c =0; c<m.sections[s].slides.length; c++){
                  slides.push(self.getSlide(m,m.sections[s].slides[c].id, m.sections[s].section_id, m.variable_map));
                }
              }
            }
            return Promise.all(slides).then(function(){return m});
          }).then(function(s){
            return self.saveToStorage("meeting", s);
          }).then(function(){
            return self.checkStorage("meeting");
          }).then(function(g){
            console.log(g);
          }).catch((errors) => {
            console.log(errors);
          });
        },
        getSlide(meeting, slideId, sectionId, variable_map){
          return this.$http.post("/api/organization/slide/get", {joint: false, populate: true, id: slideId, section_id: sectionId, member_id: 0, memberDescription: { slide_id: slideId } }).then((response) => {
            var temp_slide = response.data.slide;
            if (response.data.timer) {
              temp_slide.timer = response.data.timer;
              temp_slide.timer.mins = Math.floor(temp_slide.timer.max_time / 60);
              temp_slide.timer.secs = temp_slide.timer.max_time % 60;
            } else {
              temp_slide.timer = { continuous: 0, count_direction: 0, disabled: 1, type: 0, x_pos: 0, y_pos: 0, x_px: 0, y_px: 0, width: 5, height: 5, font: "Arial", color: "#000", back_color: "#fff", progressive: 0, num_plays: 1, sound_file: "", max_time: 1, secs: 1, mins: 0 };
            }
            if(response.data.goal){
              temp_slide.goal = response.data.goal;
            }else{
              temp_slide.goal = {disabled: 1};
            }
            temp_slide.graphics = response.data.graphics;
            temp_slide.texts = response.data.texts;

            var self = this;
            for (var i = 0; i < temp_slide.texts.length; i++) {
              temp_slide.texts[i].var_text = temp_slide.texts[i].text;
              temp_slide.texts[i].line_count = temp_slide.texts[i].text? temp_slide.texts[i].text.split(/\r*\n/).length : 1;
              variable_map.forEach(function (item) {
                if(item.id == "{Current_Time}"){
                  item.value = self.$d(new Date(), 'time12') ;
                }else if(item.id == "{Current_Date}"){
                  item.value = self.$d(new Date(), 'short') ;
                }
                if (temp_slide.texts[i].var_text.indexOf(item.id) >= 0) {
                  temp_slide.texts[i].var_text = temp_slide.texts[i].var_text.split(item.id).join(item.value);
                }
              });
            }

            if (response.data.memberDescription) {
              temp_slide.memberDescription = response.data.memberDescription;
              if ([1,2,3,4,6,7,9].indexOf(temp_slide.memberDescription.type) !== -1) {
                if(response.data.slide_member && response.data.slide_member.contact_type>0){
                  response.data.slide_member.category = this.typeOptions[response.data.slide_member.contact_type].text;
                }
                temp_slide.slide_member = response.data.slide_member? response.data.slide_member: { name: "", category: "", company: "", photo: null };
              } else if (temp_slide.memberDescription.type == 5) {
                temp_slide.slide_members = response.data.slide_members? response.data.slide_members.slice(0, Math.min(temp_slide.memberDescription.num_rows_x * temp_slide.memberDescription.num_rows_y, response.data.slide_members.length)): [];
              }
            }
            meeting.sections[sectionId].slide_data[slideId] = temp_slide;
            this.slidesRetreived++;
            return meeting;
          }).catch((errors) => { console.log(errors);});
        }
      },
      created() {
        window.addEventListener("resize", this.onResize);
      },
      destroyed() {
        window.removeEventListener("resize", this.onResize);
      },
    mounted() {
      this.auth();
      this.audio_object = document.createElement('audio');
      this.audio_object.src = sound1;
      var self = this;
      this.audio_object.addEventListener("ended", function () {
        self.audio_timeout = setTimeout(function () {
          self.audio_object.play();
        }, 500);
      });
      this.getDates();
      this.checkStorage("meeting").then(function(meeting){
        console.log(meeting);
      });
      
    }
  }
</script>
