<template>
  <div>
    <div class="rel-grid-container">
      <div id="top-div"></div>
      <form v-on:submit.prevent="updateEvent" id="form-fix" style="width:600px;">
        <div>
          <span  class="form-box-title">{{isManager || useruid==''? $t('event.event_title') : $t('event.event_submit')+ " "+organization_name}}</span>
          <div  class="form-box" style="grid-template-columns: max-content auto;">
            <span class="form-box-name">{{$t('event.title_text')}}</span><b-form-input :disabled="event.is_parent==1" :state="event.title_state"  v-model="event.title" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
            <span class="form-box-name">{{$t('event.when_text')}}</span><b-form-input :disabled="event.is_parent==1" :state="event.when_state" v-model="event.when_description" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
             <span class="form-box-name">{{$t('event.short_description')}}</span><b-form-textarea :disabled="event.is_parent==1" style="margin-bottom: 7px;" v-model="event.description" :rows="8"  class="form-box-input"/>
            <span class="form-box-name">{{$t('event.registration_link')}}</span><b-form-input :disabled="event.is_parent==1"  v-model="event.link" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
            <span v-if="event.is_parent==1" class="form-box-name">{{$t('event.picture_text')}}</span>
            <b-button v-if="event.is_parent==0" class="submit-button4"  v-on:click="showPhotoEdit=true" variant="blue">{{$t('event.set_picture')}}</b-button>
            <img v-show="event.image" id="member-photo" :src="event.image" style="margin-bottom: 10px;"/>
            <span class="form-box-name" v-if="(isManager || useruid=='') && event.is_parent==0" style="grid-column:1/2;">{{ $t('event.submitted_by') }}</span><b-form-select v-if="(isManager || useruid=='') && !event.is_parent" v-model="event.submitted_by" :options="members" value-field="id" text-field="name" />
            <b-form-file id='fileid' accept="image/jpeg, image/png, image/gif" @change="setImage" plain hidden></b-form-file>
          </div>
        </div>
        <b-modal centered id="slide-modal" v-model="showPhotoEdit" :title="$t('event.set_picture')">
          <div style="text-align:center;color: rgb(0, 174, 239);">{{$t('member.upload_instructions')}}</div>
            <div id="image-editor">
              <div id="rotate-options">
                <b-button v-if="imgSrc && imgSrc.length>0" v-on:click="rotateImageRight" class="image-button" variant="clear-blue"><div class="rotate-left-button"></div></b-button>
                <b-button v-if="imgSrc && imgSrc.length>0" v-on:click="rotateImageLeft" class="image-button" variant="clear-blue"><div class="rotate-right-button"></div></b-button>
                <b-button v-if="imgSrc && imgSrc.length>0" v-on:click="flipImageX()"  class="image-button" variant="clear-blue"><div class="flip-y-button"></div></b-button>
                <b-button v-if="imgSrc && imgSrc.length>0" v-on:click="flipImageY()" class="image-button" variant="clear-blue"><div class="flip-x-button"></div></b-button>
              </div>
              <div id="cropper-wrapper">
                <vue-cropper ref='cropper' :guides="true"  :view-mode="2" drag-mode="crop" :auto-crop-area="1" :min-container-width="250" :min-container-height="180"
                            :background="true" :rotatable="true" :src="imgSrc"  :cropmove="cropMove" alt="Photo" :img-style="{ 'width': '400px', 'height': '300px' }">
              </vue-cropper>
              </div>
              <img v-show="tempPhoto" id="preview-image" :src="tempPhoto" />
              <div id="zoom-text">{{$t('member.zoom_text')}}</div>
              <b-button class="submit-button4" v-on:click="openDialog" variant="blue" style="grid-column:2/3;margin: auto;">{{$t('member.upload_photo')}}</b-button>
            </div>
            <div slot="modal-footer" class="submit-box-2">
              <b-button class="submit-button4" v-on:click="showPhotoEdit=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
              <b-button class="submit-button4" v-on:click="cropImage" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
            </div>
        </b-modal>
        <div v-if="showSuccess">
          <b-alert variant="success" :show="showSuccess">
            {{$t('event.successful_save')}}
          </b-alert>
        </div>
        <div class="submit-box-2" v-if="event.is_parent==0">
          <b-button v-b-modal.modal-center v-if="event.id>0" class="submit-button4" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          <b-button class="submit-button4" v-if="!showSuccess" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="submit-button4" type="submit" v-if="!showSuccess" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
        </div>
        <b-modal id="modal-center" v-model="showDelete" centered :title="$t('event.delete_title')">
          <p class="my-4">{{$t('event.delete_text')}}</p>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="submit-button4" v-on:click="showDelete=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="submit-button4" v-on:click="deleteEvent" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          </div>
        </b-modal>
      </form>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
#member-photo {
    max-height: 100px;
    max-width: 200px;
    border: 1px gray solid;
  }

</style>
<script>

      
    
  import router from "../router"
  import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';

    export default {
    name: "Event",
    components: {
      VueCropper
    },
      data() {
        return {
            showDelete: false,
            showPhotoEdit: false,
            event: {id: 0, title : '', when_description: '', description: "", link: "", image: "", title_state: null, link_state: null, when_state: null,submitted_by: 0, is_parent:false},
            imgSrc: '',
            useruid: '',
            showSuccess: false,
            members: [],
            isManager: false,
            organization_name: '',
            tempPhoto: ''
        }
      },
    methods: {
      getEvent(){

        
        if(this.event.id>0){
          this.event.useruid = this.useruid;
           this.$http.post("/api/organization/events/get"+(this.useruid==''? "" : "/byuseruid"), this.event).then((response) => {
              if (response.data.success) {
                response.data.title_state=null; response.data.link_state= null; response.data.when_state= null;
                this.event = response.data.event;
              }
            }).catch(() => {});
        }
      },
        updateEvent(){
          this.event.title_state=null; this.event.link_state= null; this.event.when_state= null;
          var eventCheck = true;
          if(this.event.title.length==0){
            eventCheck = false;
            this.event.title_state = false;
          }
          if(this.event.when_description.length==0){
            eventCheck = false;
            this.event.when_state = false;
          }
          if(eventCheck){
            this.event.useruid = this.useruid;
            this.$http.post("/api/organization/events/update"+(this.useruid==''? "" : "/byuseruid"), this.event).then((response) => {
              if (response.data.success) {
                if(this.isManager || this.useruid==''){
                  this.back();
                }else{
                  this.showSuccess=true;
                }
              }
            }).catch(() => {});
          }
        },
        deleteEvent(){
          this.event.useruid = this.useruid;
          this.$http.post("/api/organization/events/delete"+(this.useruid==''? "" : "/byuseruid"), this.event).then((response) => {
            if (response.data.success) {
              this.back();
            }
          }).catch(() => {});
        },
        getMembers(org) {
          if(org>0){
             this.$http.post("/api/organization/members/noauth", {org:org,all:true}).then((response) => {
               if (response.data.success) {
                  this.members = response.data.members;
               }
            }).catch(() => { });
          }
           
        },
        getIsManager(){
          if(this.useruid!=''){
            this.$http.post("/api/organization/events/getismanager", {useruid: this.useruid}).then((response) => {
             if (response.data.success) {
                  this.isManager = response.data.manager;
                  if(this.event.id==0){
                    this.event.submitted_by = response.data.member_id;
                  }
                  if(this.isManager){
                    this.getMembers(response.data.organization);
                  }else{
                    this.organization_name = response.data.organization_name;
                  }
             }

            }).catch(() => { });
          }else{
            this.getMembers(this.permissions.organization.id);
            if(this.event.id==0){
              this.event.submitted_by = this.permissions.user.id;
            }
          }
           
        },
      cropMove() {
        this.tempPhoto = this.$refs.cropper.getCroppedCanvas().toDataURL();
      },
      setImage(e) {
        const file = e.target.files[0];
        if (!file.type.includes('image/')) {
          alert('Please select an image file');
          return;
        }
        if (typeof FileReader === 'function') {
          var self = this;
          this.resizeImage(file,665,1080, function(dataurl){
            self.imgSrc = dataurl;
            self.$refs.cropper.replace(dataurl);
            setTimeout(function () {
              self.tempPhoto = self.$refs.cropper.getCroppedCanvas().toDataURL();
            }, 200);
          });
        } else {
          alert('Sorry, FileReader API not supported');
        }
      },
      resizeImage(file, scale_width,scale_height, onEnd) {
          var reader = new FileReader();
          var self = this;
          reader.onload = function(e) {
              self.resizeDataUrl(e.target.result,file.type, scale_width, scale_height, onEnd);
          }
          reader.readAsDataURL(file);
    },
    resizeDataUrl(filedata,imagetype, scale_width, scale_height, onEnd){
      var img = document.createElement("img");
      img.src = filedata;
      img.onload = function(){
          var width = img.width;
          var height = img.height;
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");
          
          ctx.drawImage(img, 0, 0);
          var MAX_WIDTH = scale_width;
          var MAX_HEIGHT = scale_height;
          if (width > height) {
              if (width > MAX_WIDTH) {
                  height *= MAX_WIDTH / width;
                  width = MAX_WIDTH;
              }
          } else {
              if (height > MAX_HEIGHT) {
                  width *= MAX_HEIGHT / height;
                  height = MAX_HEIGHT;
              }
          }
          canvas.width = width;
          canvas.height = height;
          if(imagetype.includes("j")){
            ctx.fillStyle = '#fff'; 
            ctx.fillRect(0, 0, canvas.width, canvas.height);
          }
          ctx.drawImage(img, 0, 0, width, height);
          var dataurl = canvas.toDataURL(imagetype, 0.8);
          onEnd(dataurl);
      }
    },
      cropImage() {
        if(this.$refs.cropper.getCroppedCanvas()){
          this.event.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
          /*var self = this;
          this.resizeDataUrl(this.event.nm_photo,'image/jpeg',132,216, function(dataurl){
              self.event.image = dataurl;
          });*/
        }else{
          this.event.image = null;
        }
       
        this.showPhotoEdit = false;
      },
          back(){
            router.push(this.useruid==''? "/admin/events": "/events/"+this.useruid);
          },
          onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length || !this.checkFileSize(files[0]))
              return;
            this.createImage(files[0]);
          },
          createImage(file) {
            var reader = new FileReader();
            reader.onload = (e) => {
              this.event.image = e.target.result;
            };
            reader.readAsDataURL(file);
          },
          removeImage: function () {
            this.event.image = null;
          },
          openDialog() {
            document.getElementById('fileid').click();
          },
          openDialog2() {
            document.getElementById('fileid2').click();
          },
          onFileChange2(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length || !this.checkFileSize(files[0]))
              return;

            this.event.filename = files[0].name;
            this.event.file = files[0];
         },
      rotateImageLeft(){
        this.$refs.cropper.rotate(90);
        this.cropMove();
      },
      rotateImageRight(){
        this.$refs.cropper.rotate(-90);
        this.cropMove();
      },
      flipImageX(){
        var d = this.$refs.cropper.getData()
        this.$refs.cropper.scaleX(d.scaleX*-1);
        this.cropMove();
      },
      flipImageY(){
        var d = this.$refs.cropper.getData()
        this.$refs.cropper.scaleY(d.scaleY*-1);
        this.cropMove();
      }
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-events"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      
      if(this.$route.params.useruid){
        this.useruid = this.$route.params.useruid;
      }
      this.event.id = this.$route.params.id;
      this.getIsManager();
      this.getEvent();
      this.addClass(document.getElementById("menu-events"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
    }
  }
</script>
