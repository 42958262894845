import { Mark } from 'tiptap'
import { toggleMark, markInputRule, markPasteRule } from 'tiptap-commands'

export default class Superscript extends Mark {

  get name() {
    return 'superscript'
  }

  get schema() {
    return {
      parseDOM: [
        {
          tag: 'sup',
        },
        {
          style: 'vertical-align',
          getAttrs: value => /^(super(er)?|[5-9]\d{2,})$/.test(value) && null,
        },
      ],
      toDOM: () => ['sup', 0],
    }
  }

  keys({ type }) {
    return {
      'Mod-sup': toggleMark(type),
    }
  }

  commands({ type }) {
    return () => toggleMark(type)
  }

  inputRules({ type }) {
    return [
      markInputRule(/(?:\*\*|__)([^*_]+)(?:\*\*|__)$/, type),
    ]
  }

  pasteRules({ type }) {
    return [
      markPasteRule(/(?:\*\*|__)([^*_]+)(?:\*\*|__)/g, type),
    ]
  }

}
