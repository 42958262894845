<template>
  <div>
      <div class="rel-grid-container" style="top:20px;">
      <div id="top-div"></div>
      <div>
        <div style="max-width: 600px;margin: auto;">
          <span class="form-box-title">{{$t('import.import_options')}}</span>
          <div class="form-box" style="grid-template-columns: 50% 50%;">
            <b-button variant="blue" v-on:click="openDialog" style="width:200px;">{{$t('import.select_file')}}</b-button>
            <b-form-file id='fileid' accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="onFileChange($event)" plain hidden></b-form-file>
            <div style="margin: auto;">
              {{file.name}}
            </div>
            <b-form-checkbox style="grid-column: 1/3; margin-top: 20px;" v-model="sendEmails" value="1" unchecked-value="0">
              {{$t('import.email_members')}}
            </b-form-checkbox>
            <div style="grid-column: 1/3; margin-top: 20px;font-size:12px;">
              *{{$t('import.note_message')}}
            </div>
          </div>
        </div>
      </div>
      <b-modal centered id="slide-modal-4" v-model="showPreviewImport" :title="$t('import.import_title')">
        <div id="preview-members">
          <b-table class="table-hover-new" small bordered :items="previewPerformance" :fields="fields">
            <template v-slot:cell(import)="data">
              <b-form-checkbox v-model="data.item.import" value="1" unchecked-value="0" style="margin:auto;">
            </b-form-checkbox>
            </template>
          </b-table>
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="submit-button4" v-on:click="cancelImport" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button v-if="!onboard" class="submit-button4" v-on:click="importChanges" variant="clear-blue"><div class="import-button"></div><span>{{$t('global.button_import')}}</span></b-button>
          <b-button v-if="onboard" class="submit-button4" v-on:click="confirmImport" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_confirm')}}</span></b-button>
        </div>
      </b-modal>
      <div v-if="showAlert">
        <b-alert variant="danger"
                 dismissible
                 :show="showAlert"
                 @dismissed="showAlert=false">
          {{alertText}}
        </b-alert>
      </div>
      <div v-if="showSuccess">
        <b-alert variant="success"
                 dismissible
                 :show="showSuccess"
                 @dismissed="showSuccess=false">
          {{$t('import.successful_import')}}
        </b-alert>
      </div>
      <div v-if="showUpload">
        <b-alert style="line-height:30px;" variant="warning" :show="showUpload">
          {{$t('import.importing_message')}}
          <b-spinner style="float:right;" label="Uploading" variant="warning"></b-spinner>
        </b-alert>
      </div>
      <div class="submit-box-2" v-if="!onboard">
        <b-button class="submit-button4" v-on:click="back()" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
      </div>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>

</style>
<script>
  //import router from "../router"
    export default {
    name: "PerformanceImport",
      data() {
        return {
            showUpload: false,
        showAlert: false,
        saveVariant: "",
        alertText: "",
        showSuccess: false,
        file: { name: "" },
        showPreviewImport: false,
        previewPerformance: [],
        fields: [
          { key: 'name',
            label: this.$t('import.name_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'referrals',
            label: this.$t('performance.referrals_text'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'visitors',
            label: this.$t('performance.visitors_text'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'one_to_ones',
            label: this.$t('performance.referrals_text'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'tyfcb',
            label: this.$t('performance.referrals_text'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
            { key: 'ceus',
            label: this.$t('performance.referrals_text'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
            { key: 'import',
            label: this.$t('events.import_header'),
            sortable: true,
            thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff", 'text-align': 'center'},
        }
        ],
        }
      },
    methods: {
      onFileChange(e) {
         var files = e.target.files || e.dataTransfer.files;
        if (!files.length || !this.checkFileSize(files[0]))
          return;
        this.file = files[0];
        
        this.importPerformance();
      },
      importPerformance() {
        this.showImportPerformance = false;
        this.showUpload = true;
        this.showAlert = false;
        this.alertText = this.$t('import.alert_text');
        this.saveVariant = "";
        this.$refs['file-input'].reset();
        let formData = new FormData();
        formData.append("file", this.file);
        this.$http.post("/api/organization/performance/import/excel", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }).then((response) => {
          
          this.showUpload = false;
          this.saveVariant = "blue";
          if (response.data.success == true) {
            this.previewPerformance= response.data.performance;
            this.showPreviewImport = true;
          } else {
            this.showAlert = true;
            this.alertText = response.data.error;
          }
        }).catch((errors) => {
          this.showUpload = false;
          this.saveVariant = "blue";
          this.showAlert = true;
          this.alertText = JSON.stringify(errors);
        });
      },
      cancelImport() {
        this.previewPerformance = [];
        this.showPreviewImport = false;
        this.file = { name: "" };
      },
      importChanges() {
        var vs = this.previewPerformance.filter(g => g.import == 1);
        if(vs.length>0){
        this.$http.post("/api/organization/performance/import/all", { Performance: this.previewPerformance}).then((response) => {
            if (response.data.success === true) {
                this.showPreviewImport = false;
            }
        }).catch(() => { });
        }else{
          this.showPreviewImport = false;
        }
          
      },
      openDialog() {
        document.getElementById('fileid').click();
      },
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-performance"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-performance"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
     
    }
  }
</script>
