<template>
    <div >
        <div class="rel-grid-container">
            <div style="display:grid;grid-template-columns: repeat(2,max-content);margin:auto;" class="fit-width">
                <span class="form-box-name">{{$t('goal.from_date')}}</span><b-form-datepicker :state="from_date== null? false : null"  style="margin: 5px 0px; "  v-model="from_date" :locale="$i18n.locale" ></b-form-datepicker>
                <span class="form-box-name">{{$t('goal.to_date')}}</span><b-form-datepicker :state="to_date== null || from_date> to_date? false : null"  style="margin: 5px 0px; "  v-model="to_date" :locale="$i18n.locale" ></b-form-datepicker>
            </div>
            <b-alert variant="success" dismissible :show="showSuccess"  @dismissed="showSuccess=false" style="margin:auto;" class="fit-width">
            {{$t('attendance.export_success')}}
            </b-alert>
            <b-alert variant="warning" dismissible :show="showAlert"  @dismissed="showAlert=false" style="margin:auto;" class="fit-width">
            {{$t('attendance.export_failure')}}
            </b-alert>
            <div style="display:grid; grid-template-columns: max-content max-content; margin: auto;" class="fit-width">
                <b-button class="submit-button4" style="margin:auto;" v-if="!showSuccess" :disabled="from_date==null || to_date == null || from_date> to_date" v-on:click="generateEngagementReport" variant="clear-blue"><div class="import-button"></div><span>{{$t('global.button_export')}}</span></b-button>
                <div v-if="exportFile.filename.length>0 && showSuccess" style="margin:auto;" class="fit-width"> 
                    <b-button class="submit-button4" v-on:click="downloadEngagementReport" variant="clear-blue" ><div class="import-button"></div><span>{{$t('attendance.download_export')}}</span></b-button>
                    <a id="export-file" :download="exportFile.filename" :href="exportFile.content" hidden></a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
name: "EngagementReport",
data() {
        return {
        showAlert: false,
        showSuccess: false,
        exportFile: {filename: '', content: ''},
        from_date: null,
        to_date: null
        }
},
methods: {
    generateEngagementReport(){
        this.$http.post("/api/organization/egagementreport/export", {from_date: this.from_date, to_date: this.to_date}).then((response) => {
        if (response.data.success === true) {
            this.exportFile = response.data.file;
            this.showSuccess = true;
        }else{
            this.showAlert = true;
        }
        }).catch(() => { });
    },
    downloadEngagementReport(){
        document.getElementById("export-file").click();
        this.showSuccess = false;
        this.showAlert = false;
    },
},
destroyed() {
    this.removeClass(document.getElementById("menu-engagement"), "active-menu");
    this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
created() {
    this.addClass(document.getElementById("menu-engagement"), "active-menu");
    this.addClass(document.getElementById("menu-chapter"), "active-menu");
}
}
</script>
