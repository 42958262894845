<template>
  <div>
    <Header :useruid="this.$route.params.useruid" :title="isManager? $t('powerteam.manage_powerteam') : ($t('powerteam.powerteam_update')+': '+powerteam.title)" :showDate="true"></Header>
    <div class="rel-grid-container">
      <div id="top-div"></div>
      <form v-on:submit.prevent="updatePowerteam" id="form-fix" style="width:800px;">
        <div v-if="(useruid=='' || isManager)">
          <span  class="form-box-title">{{$t('powerteam.powerteam_title')}}</span>
          <div  class="form-box" style="grid-template-columns: max-content auto;">
            <span class="form-box-name">{{$t('powerteam.title_text')}}</span><b-form-input :state="powerteam.title_state"  v-model="powerteam.title" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')"></b-form-input>
             <span class="form-box-name">{{$t('powerteam.description_text')}}</span><b-form-textarea  style="margin-bottom: 7px;" v-model="powerteam.description" :rows="2" :maxlength="255"/>
            <span  class="form-box-name">{{$t('powerteam.picture_text')}}</span>
            <div style="display:grid;grid-template-columns: max-content auto;">
                <b-button  class="submit-button4"  v-on:click="showPhotoEdit=true" variant="blue">{{$t('powerteam.set_picture')}}</b-button>
                <div v-if="powerteam.photo != null"><img  id="member-photo" :src="powerteam.photo" style="margin-bottom: 10px;"/></div>
                <b-form-file id='fileid' v-model="powerteam.photo" accept="image/jpeg, image/png, image/gif" @change="setImage" plain hidden></b-form-file>
            </div>
            <span v-if="(useruid=='' || isManager)" class="form-box-name">{{$t('powerteam.members_text')}}</span>
            <multiselect style="margin-bottom:10px;" v-if="(useruid=='' || isManager)" :noOptions="$t('global.empty_list')" :class="{blueselect:true}" :selectedLabel="$t('global.selected_text')" selectLabel="" deselectLabel="" v-model="value" :options="members" :multiple="true" :close-on-select="false"  :preserve-search="true" :placeholder="$t('powerteam.select_members')" label="name" track-by="id" :preselect-first="false">
              <template slot="option" slot-scope="props">
                <div><span>{{ props.option.name }}</span>  <span >- {{ props.option.email.length>0 ? props.option.email : $t('global.no_email')  }}</span></div>
              </template>
            </multiselect>
            <table class="table b-table  table-sm table-hover-new" style="grid-column:2/3;margin-bottom:0px;">
              <thead>
                <tr>
                  <th >{{$t('role.name_header')}}</th>
                  <th style="text-align: center;" >{{$t('role.remove_header')}}</th>
                </tr>
              </thead>
              <draggable tag="tbody" v-model="value">
                  <tr v-for="m in value" :key="m.id">
                    <td style="vertical-align:middle;">{{m.name}}</td>
                    <td style="vertical-align: middle;"><span class="trash-recog" variant="blue" v-on:click="deleteMember(m)"></span></td>
                  </tr>
              </draggable>
              </table>
              <div class="form-box-name" style="grid-column:2/3;font-size:12px;margin-top:0px;margin-bottom:20px;">*{{$t('global.drag_order')}}</div>
            <span v-if="(useruid=='' || isManager) && value.length>0" class="form-box-name">{{$t('powerteam.manager_text')}}</span><b-form-select v-if="(useruid=='' || isManager) && value.length>0" v-model="powerteam.manager_id" :options="powerteamMembers" value-field="id" text-field="name"/>
            <div v-if="(useruid=='' || isManager) && value.length>0 && powerteam.manager_id>0" style="grid-column: 2/3;font-size:12px;">{{$t('powerteam.manager_note')}}</div>
          </div>
        </div>
        <div>
          <span  class="form-box-title">{{$t('powerteam.activity_text')}}</span>
          <div  class="form-box" style="grid-template-columns: auto;">
             <b-form-textarea  style="margin-bottom: 7px;" v-model="powerteam.activity" :rows="4" />
          </div>
        </div>
        <div >
            <span class="form-box-title">{{$t('powerteam.speaker_presentation')}}</span>
            <div class="form-box" style="grid-template-columns: auto;position:relative;" v-on:drop="dropHandler"  @dragover.prevent  @dragenter.prevent @dragleave.prevent>
              <div v-show="showDrag" id="drop-overlay">
                <div></div>
                <div v-if="powerteam.files.length<6">{{$t('preparer.drop_text')}}</div>
                <div v-if="powerteam.files.length>=6">{{$t('preparer.max_files')}}</div>
                <div></div>
              </div>
              <div style="display:grid; grid-template-columns: auto max-content;"><span v-html="$t('preparer.file_notes')"></span> <b-button v-if="getIsMac()" style="padding:0; width:70px;font-size:14px;" variant="link" v-on:click="showMacNote=true;">{{$t('entry.mac_note')}}</b-button></div>
              <div v-for="file in powerteam.files" :key="file" style="display:grid; grid-template-columns: auto max-content;">
                <div style="text-align:center; grid-column:1/3;color: #003c80;font-size: 20px;">{{$t('preparer.file_text')+" #"+(powerteam.files.indexOf(file)+1)}}</div>
                <b-button v-if="powerteam.files.length>1 || (file.link != '' || file.file != null) " :disabled="!(useruid=='' || isManager || file.member_uid == useruid)" v-on:click="removeFileLink(file)" class="submit-button4" variant="clear-blue" style="margin:auto;grid-column:2/3;grid-row:2/5;"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
                <b-form-checkbox class="setting-check" :disabled="!(useruid=='' || isManager || file.member_uid == useruid)" v-model="file.type" :value="1" :unchecked-value="2" style="grid-column:1/2;">{{$t('preparer.power_point')}}</b-form-checkbox>
                <div style="display:grid; grid-template-columns: max-content auto;grid-column:1/2;" v-if="file.type==1">
                  <b-button  class="submit-button4" v-on:click="openFileDialog(file)" :disabled="!(useruid=='' || isManager || file.member_uid == useruid)" variant="blue" style="margin: auto 20px;">{{file.link.length>0 && file.file_path.length>0? $t('entry.reselect_presentation') : $t('preparer.upload_pp')}}</b-button>
                  <div v-if="file.filename && file.filename.length>0" id="preparer-filename" class="cut-filename">{{file.filename}}</div>
                  <div v-if="(!file.filename || file.filename.length==0)  && file.link.length>0 && file.file_path.length>0" style="text-align:center;line-height: 12px;margin:auto;">
                    <div class="cut-filename">{{file.file_path}}</div>
                    <div><b-button variant="link" v-on:click="viewFile(file.hash)" style="padding:0;border: none;font-weight: bold;">{{$t('entry.speaker_upload')}}</b-button></div>
                    <div style="font-size: 14px;"><a :href="'/uploads/'+file.file_path" download>{{$t('entry.download_original')}}</a></div>
                  </div>
                  <b-button style="grid-column:2;margin-top:20px;"  class="submit-button4" v-if="(!file.filename || file.filename.length==0)  && file.link.length>0 && file.file_path.length>0" v-on:click="popupResendModal(file)" variant="clear-blue"><div class="email-button"></div><span>{{$t('preparer.resend_confirm')}}</span></b-button>
                </div>
                <b-form-file :id="'fileupload'+file.number" accept=".doc, .docx, .pdf, .ppt, .pptx, .pptm, .pub, .png, .jpg, .jpeg" @change="onFileFileChange($event, file)" plain hidden></b-form-file> <!--  -->
                <b-form-checkbox class="setting-check" :disabled="!(useruid=='' || isManager || file.member_uid == useruid)" v-model="file.type" :value="2" :unchecked-value="1" style="grid-column:1/2;">{{$t('preparer.link_web')}}</b-form-checkbox>
                <div style="display:grid; grid-template-columns: max-content auto;grid-column:1/2;" v-if="file.type==2">
                  <b-form-input v-model="file.link" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input" style="grid-column:1/2;width:200px;grid-column: 1/2;"></b-form-input>
                  <div v-if="file.link.length>0 && file.description.length>0" style="text-align:center;line-height: 12px;margin:auto;">
                    <div class="cut-filename" style="line-height:16px;">{{file.description}}</div>
                    <div><b-button variant="link" v-on:click="viewLink(file.link)" style="padding:0;border: none;font-weight: bold;">{{$t('entry.speaker_link')}}</b-button></div>
                  </div>
                </div>
                <hr style="width: 100%;border-color: grey;grid-column:1/3;" />
              </div>
              <b-button class="submit-button4" v-if="powerteam.files.length<6 && !(powerteam.files.length>0 && ((powerteam.files[powerteam.files.length-1].type == 1 && powerteam.files[powerteam.files.length-1].file == null && powerteam.files[powerteam.files.length-1].link == '') || (powerteam.files[powerteam.files.length-1].type == 2 && powerteam.files[powerteam.files.length-1].link == '')))" v-on:click="addFileLink" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
            </div>
            <div v-if="showPresentationAlert">
            <b-alert variant="danger"
                     dismissible
                     :show="showPresentationAlert"
                     @dismissed="showPresentationAlert=false">
              <span v-html="alertText"></span>
            </b-alert>
          </div>
        </div>
        <b-modal centered v-model="showResendModal" :title="$t('preparer.resend_link')">
          <div style="margin-left:40px;">{{$t('preparer.resend_text1')}}</div><br/>
            <div style="display: grid; grid-template-columns: auto auto;margin-left:40px;">
              <b-form-checkbox style="grid-column: 1/3;" v-model="resendType" value="0" unchecked-value="1">
              {{$t('preparer.current_recipients')}}
            </b-form-checkbox>
            <b-form-checkbox style="grid-column: 1/3;" v-model="resendType" value="1" unchecked-value="0">
              {{$t('preparer.alternate_email')}}
            </b-form-checkbox>
              <span v-if="resendType==1" class="form-box-name">{{$t('preparer.recipient_text')}}</span>
              <b-form-input v-if="resendType==1" v-model="resendEmail" type="text" pattern="[^<>]*" :state="validEmail" :title="$t('global.pattern_title')" @input="validateEmail"></b-form-input>
            </div><br/>
            <div style="text-align:center;" v-html="$t('preparer.resend_text2')"></div>
            <div slot="modal-footer" class="submit-box-2">
              <b-button class="submit-button4" v-on:click="showResendModal=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
              <b-button class="submit-button4" :disabled="resendType==1 && !validEmail" v-on:click="resendFileConfirm" variant="clear-blue"><div class="email-button"></div><span>{{$t('global.button_send')}}</span></b-button>
            </div>
        </b-modal>
        <b-modal centered id="slide-modal" v-model="showPhotoEdit" :title="$t('powerteam.set_picture')">
          <div style="text-align:center;color: rgb(0, 174, 239);">{{$t('member.upload_instructions')}}</div>
            <div id="image-editor">
              <div id="rotate-options">
                <b-button v-if="imgSrc && imgSrc.length>0" v-on:click="rotateImageRight" class="image-button" variant="clear-blue"><div class="rotate-left-button"></div></b-button>
                <b-button v-if="imgSrc && imgSrc.length>0" v-on:click="rotateImageLeft" class="image-button" variant="clear-blue"><div class="rotate-right-button"></div></b-button>
                <b-button v-if="imgSrc && imgSrc.length>0" v-on:click="flipImageX()"  class="image-button" variant="clear-blue"><div class="flip-y-button"></div></b-button>
                <b-button v-if="imgSrc && imgSrc.length>0" v-on:click="flipImageY()" class="image-button" variant="clear-blue"><div class="flip-x-button"></div></b-button>
              </div>
              <div id="cropper-wrapper">
                <vue-cropper ref='cropper' :guides="true"  :view-mode="2" drag-mode="crop" :auto-crop-area="1" :min-container-width="250" :min-container-height="180"
                            :background="true" :rotatable="true" :aspectRatio="1" :src="imgSrc"  :cropmove="cropMove" alt="Photo" :img-style="{ 'width': '400px', 'height': '300px' }">
              </vue-cropper>
              </div>
              <img v-show="tempPhoto" id="preview-image" :src="tempPhoto" />
              <div id="zoom-text">{{$t('member.zoom_text')}}</div>
              <b-button class="submit-button4" v-on:click="openDialog" variant="blue" style="grid-column:2/3;margin: auto;">{{$t('member.upload_photo')}}</b-button>
            </div>
            <div slot="modal-footer" class="submit-box-2">
              <b-button class="submit-button4" v-on:click="showPhotoEdit=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
              <b-button class="submit-button4" v-on:click="cropImage" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
            </div>
        </b-modal>
        <div v-if="showSuccess">
          <b-alert variant="success" :show="showSuccess">
            {{$t('powerteam.successful_save')}}
          </b-alert>
        </div>
        <div v-if="showAlert" style="max-width: 600px;margin: auto;">
          <b-alert variant="warning" dismissible :show="showAlert" @dismissed="showAlert=false">
            {{$t('performance.error_type1')}}
          </b-alert>
        </div>
        <div v-if="showUpload">
          <b-alert style="line-height:30px;" variant="warning" :show="showUpload">
            {{$t('powerteam.uploading_text')}}
            <b-spinner style="float:right;" label="Uploading" variant="warning"></b-spinner>
          </b-alert>
        </div>
        <div class="submit-box-2" >
          <b-button v-on:click="showDelete=true;" v-if="powerteam.id>0 && (useruid=='' || isManager)" class="submit-button4" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          <b-button class="submit-button4" v-if="!showSuccess && (useruid=='' || isManager)" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="submit-button4" type="submit" v-if="!showSuccess" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
        </div>
        <b-modal id="modal-center" v-model="showDelete" centered :title="$t('powerteam.delete_title')">
          <p class="my-4">{{$t('powerteam.delete_text')}}</p>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="submit-button4" v-on:click="showDelete=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="submit-button4" v-on:click="deletePowerteam" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          </div>
        </b-modal>
        <b-modal id="modal-center" v-model="showSendEmailModal" centered :title="$t('powerteam.sendemail_title')">
          <p v-html="$t('powerteam.sendemail_text')"></p>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="submit-button4" v-on:click="updatePowerteam" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_no')}}</span></b-button>
            <b-button class="submit-button4" v-on:click="sendEmail=true; updatePowerteam();" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_yes')}}</span></b-button>
          </div>
        </b-modal>
      </form>
      <div class="div-space"></div>
    </div>
    <Footer :useruid="this.$route.params.useruid"></Footer>
  </div>
</template>
<style scoped>
#member-photo {
    max-height: 100px;
    max-width: 200px;
    border: 1px gray solid;
  }
  .trash-recog {
      width: 30px;
    height: 30px;
    background-image: url("../assets/button-Close.png");
    border: none;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: block;
    margin: auto;
    text-align: center;
    cursor: pointer;
  }

</style>
<script>
import draggable from "vuedraggable";
  import router from "../router"
  import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
import Multiselect from 'vue-multiselect'
    export default {
    name: "Powerteam",
    components: {
      draggable,
      VueCropper,
      Multiselect,
    },
    watch: {
      'value': {
        handler: function (v) {
          this.powerteamMembers = v.filter(x => x.id>0);
          this.powerteamMembers.unshift({id: 0, name: this.$t('powerteam.no_manager')});
          var found = false;
          for(var i=0; i<this.powerteamMembers.length;i++){
            if(this.powerteam.manager_id == this.powerteamMembers[i].id){
              found = true;
            }
          }
          if(!found){
            this.powerteam.manager_id = 0;
          }
        },
        deep: true
      },
    },
      data() {
        return {
            showMacNote: false,
            showDelete: false,
            showPhotoEdit: false,
            powerteam: {id: 0, manager_id: 0, title : '', description: "", photo: null, activity: "", title_state: null, assigned_ids: '',disabled: 0, files: [{ presentation_id: 0, number: 1, type: 1, link: "", description: "", filename: "", file_path: "", file: null, new: true}] },
            imgSrc: '',
            useruid: '',
            showSuccess: false,
            members: [],
            isManager: false,
            organization_name: '',
            tempPhoto: '',
            value: [],
            showUpload: false,
            showPresentationAlert: false,
            alertText: "",
            showSendEmailModal: false,
            sendEmailShown: false,
            sendEmail: false,
            showResendModal: false,
            validEmail: false,
            resendFile: null,
            resendEmail: "",
            resendType: 0,
            original_manager_id: 0,
            powerteamMembers: []
        }
      },
    methods: {
      deleteMember(m){
        var index = this.value.indexOf(m);
          if (index > -1) {
            this.value.splice(index, 1);
          }
      },
      validateEmail() {
          if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.resendEmail)) {
              this.validEmail= true;
          } else {
              this.validEmail= false;
          }
      },
    resendFileConfirm(){
      if(this.resendFile!= null){
        var file = this.resendFile;
        if(this.resendType==0){
            this.resendEmail = "";
        }else{
          this.setCookie("resend_email", this.resendEmail);
        }
        this.showResendModal = false;
         this.$http.post("/api/organization/powerteam/resendconfirm/byhash", {presentation_id: this.powerteam.id, number: file.number, recipient: this.resendEmail}).then((response) => {
          if(response.data.success){
            this.resendFile = null;
            this.$bvToast.toast(this.$t('preparer.emails_sent_to')+": "+response.data.emails.join(", "), {
              title: this.$t('preparer.resend_success'),
              variant: 'success',
              solid: true
            });
          }
        }).catch(() => {});
      }
      
      },
      popupResendModal(file){
        this.resendEmail = this.getCookie("resend_email");
        this.validateEmail();
        this.resendFile = file;
        this.showResendModal = true;
      },
      dropHandler(ev){
        if(ev){
          ev.preventDefault();
         if (ev.dataTransfer.items) {
            for (var i = 0; i < ev.dataTransfer.items.length; i++) {
              if (ev.dataTransfer.items[i].kind === 'file' && this.checkFileSize(ev.dataTransfer.items[i])) {
                var file = ev.dataTransfer.items[i].getAsFile();
                this.addFileLink();
                this.powerteam.files[this.powerteam.files.length-1].file = file;
                this.powerteam.files[this.powerteam.files.length-1].filename = file.name;
                this.powerteam.files[this.powerteam.files.length-1].description = file.name;
              }
            }
          } else {
            for (var j = 0; j < ev.dataTransfer.files.length; j++) {
              if(this.checkFileSize(ev.dataTransfer.files[j])){
                this.addFileLink();
                this.powerteam.files[this.powerteam.files.length-1].file = ev.dataTransfer.files[j];
                this.powerteam.files[this.powerteam.files.length-1].filename = ev.dataTransfer.files[j].name;
                this.powerteam.files[this.powerteam.files.length-1].description = ev.dataTransfer.files[j].name;
              }
            }
          }
        }
        this.showDrag = false;
        this.dragging = false;
      },
      dragEnterHandler(){
        this.showDrag = true;
        this.dragging = true;
      },
      dragHandler(){
        this.showDrag = true;
        this.dragging = true;
      },
      dragLeaveHandler(){
        this.dragging = false;
        var self = this;
        clearTimeout( this.dragTimeout );
        this.dragTimeout = setTimeout( function(){
          if( !self.dragging ){ self.showDrag = false;  }
      }, 200 );
      },
      addFileLink() {
        if((this.powerteam.files[this.powerteam.files.length-1].type == 1 && this.powerteam.files[this.powerteam.files.length-1].file == null && this.powerteam.files[this.powerteam.files.length-1].link == '') || (this.powerteam.files[this.powerteam.files.length-1].type == 2 && this.powerteam.files[this.powerteam.files.length-1].link == "")){
          var j = this.powerteam.files[this.powerteam.files.length-1].number;
          this.powerteam.files[this.powerteam.files.length-1] = {member_uid: this.useruid, presentation_id: 0, number: j, type: 1, link: "", description: "", filename: "", file_path: "", file: null , new: true};
          return this.powerteam.files.length-1;
        }
        for (var k = 1; k <= 6; k++) {
          var found = false;
          for (var i = 0; i < this.powerteam.files.length; i++) {
            if (this.powerteam.files[i].number == k) {
              found = true;
              
            }
          }
          if (!found) {
            this.showSuccess = false;
            this.powerteam.files.push({member_uid: this.useruid, presentation_id: 0, number: k, type: 1, link: "", description: "", filename: "", file_path: "", file: null , new: true, hash: ''});
            return k;
          }
        }
        return -1;
      },
      openFileDialog(file) {
        document.getElementById('fileupload' + file.number).click();
      },
      onFileFileChange(e, fileElement) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length || !this.checkFileSize(files[0]))
          return;
        fileElement.link = "";
        fileElement.file_path = "";
        fileElement.description = "";
        fileElement.new = true;
        fileElement.filename = files[0].name;
        fileElement.file = files[0];
        fileElement.new = true;
        if(fileElement.description.length == 0){
          fileElement.description = files[0].name;
        }
      },
          viewLink(l){
          window.open(l, "_blank", 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
        },
      removeFileLink(file) {
        var index = this.powerteam.files.indexOf(file);
        if (index > -1) {
          this.powerteam.files.splice(index, 1);
        }
         if(this.powerteam.files.length == 0){
            this.powerteam.files.push({member_uid: this.useruid, presentation_id: 0, number: 1, type: 1, link: "", description: "", filename: "", file_path: "", file: null , new: true});
          }
      },
      gotoFile(hash){
        router.push('/filecontrol/'+hash);
      },
      getPowerteam(){
        if(this.powerteam.id>0){
          this.powerteam.useruid = this.useruid;
           this.$http.post("/api/organization/powerteams/get"+(this.useruid==''? "" : "/byuseruid"), this.powerteam).then((response) => {
              if (response.data.success) {
                this.isManager = response.data.manager;
                this.original_manager_id = response.data.powerteam.manager_id;
                response.data.title_state=null; 
                this.powerteam = response.data.powerteam;
                var ids = this.powerteam.assigned_ids.split(",");
                for(var i=0; i<ids.length; i++){
                  for(var j=0; j<this.members.length;j++){
                    if(ids[i] == (this.members[j].id+"")){
                        this.value.push(this.members[j]);
                        break;
                    }
                  }
                }
                for (var i = 0; i < this.powerteam.files.length; i++) {
                  this.$set(this.powerteam.files[i], 'file', null);
                  this.$set(this.powerteam.files[i], 'filename', "");
                }
                if(this.powerteam.files.length == 0){
                  this.powerteam.files.push({member_uid: this.useruid, presentation_id: 0, number: 1, type: 1, link: "", description: "", filename: "", file_path: "", file: null, new: true});
                }
              }
            }).catch(() => {});
        }
      },
      arraysAreIdentical(arr1, arr2){
          if (arr1.length !== arr2.length) return false;
           arr1.sort((a, b) => (parseInt(a)> parseInt(b)) ? 1 : -1);
          arr2.sort((a, b) => (parseInt(a)> parseInt(b)) ? 1 : -1);
          for (var i = 0, len = arr1.length; i < len; i++){
              if (arr1[i] != arr2[i]){
                  return false;
              }
          }
          return true; 
      },
        updatePowerteam() {
            this.powerteam.title_state=null;
            var powerteamCheck = true;
            if(this.powerteam.title.length==0){
                powerteamCheck = false;
                this.powerteam.title_state = false;
            }
            if(powerteamCheck){
                var t = [];
                for(var i=0; i<this.value.length;i++){
                    t.push(this.value[i].id);
                }
               /* if(this.original_manager_id != this.powerteam.manager_id && !this.sendEmailShown && (this.useruid=='' || this.isManager) ){
                  this.showSendEmailModal = true;
                  this.sendEmailShown = true;
                  return;
                }*/
                this.powerteam.assigned_ids = t.join(",");
                this.powerteam.useruid = this.useruid;

                this.showAlert = false;
                this.showSuccess = false;
                this.alertText = this.$t('powerteam.alert_text');
                this.saveVariant = "";
                this.$http.post("/api/organization/powerteams/update"+(this.useruid==''? "" : "/byuseruid"), this.powerteam).then((response) => {
                  if (response.data.success) {
                    if(this.powerteam.files.filter(v => v.new).length>0){
                      this.showUpload = true;
                      this.powerteam.id = response.data.id;
                      let formData = new FormData();
                      var presentationUploads = [];
                      for (var i = 0; i < this.powerteam.files.length; i++) {
                          if (this.powerteam.files[i].type == 1 && this.powerteam.files[i].file && this.powerteam.files[i].file != null) {
                              formData.append("files", this.powerteam.files[i].file);
                              this.powerteam.files[i].link = "";
                              this.powerteam.files[i].name = this.powerteam.files[i].file.name;
                              this.powerteam.files[i].file = null;
                              presentationUploads.push( this.powerteam.files[i]);
                          }else if (this.powerteam.files[i].link != ""){
                              presentationUploads.push( this.powerteam.files[i]);
                          }
                      }
                      if(this.sendEmail && (this.useruid=='' || this.isManager)){
                        this.$http.post("/api/organization/powerteams/sendemail"+(this.useruid==''? "" : "/byuseruid"), this.powerteam).then(() => {
                        });
                      }

                      formData.append("presentation_files", JSON.stringify(presentationUploads));
                      formData.append("hash", this.powerteam.hash);
                      formData.append("id", this.powerteam.id);
                      formData.append("useruid", this.useruid);
                      this.$http.post("/api/organization/powerteams/files/update"+(this.useruid==''? "" : "/byuseruid"), formData, {
                      headers: {
                          "Content-Type" : "multipart/form-data"
                      }
                      }).then((response) => {
                          this.showUpload = false;
                          this.saveVariant = "blue";
                          if (response.data.success == true) {
                              if(this.useruid.length>0 && !this.isManager){
                                this.showSuccess = true;
                              }else{
                                this.back();
                              }
                          } else {
                              this.showAlert = true;
                              this.alertText = response.data.error;
                          }
                      }).catch((errors) => {
                          this.showUpload = false;
                          this.saveVariant = "blue";
                          this.showAlert = true;
                          this.alertText = JSON.stringify(errors);
                      });
                    }else{
                        if(this.useruid.length>0 && !this.isManager){
                            this.showSuccess = true;
                          }else{
                            this.back();
                          }
                    }
                      
                  }else{
                     this.showUpload = false;
                      this.showAlert = true;
                      this.alertText = response.data.errors;
                  }
                }).catch((errors) => {
                    this.showUpload = false;
                    this.saveVariant = "blue";
                    this.showAlert = true;
                    this.alertText = JSON.stringify(errors);
                });
            }
          },
        deletePowerteam(){
          this.powerteam.useruid = this.useruid;
          this.$http.post("/api/organization/powerteams/delete"+(this.useruid==''? "" : "/byuseruid"), this.powerteam).then((response) => {
            if (response.data.success) {
              this.back();
            }
          }).catch(() => {});
        },
        viewFile(h){
            window.open("/#/filecontrol/"+h, "_blank", 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
          },
        getIsMac(){
        return window.navigator.platform.indexOf('Mac')>=0 || window.navigator.platform.indexOf('mac')>=0 || window.navigator.platform.indexOf('Apple')>=0 || window.navigator.platform.indexOf('apple')>=0;
      },
      cropMove() {
        this.tempPhoto = this.$refs.cropper.getCroppedCanvas().toDataURL();
      },
      setImage(e) {
        const file = e.target.files[0];
        if (!file.type.includes('image/')) {
          alert('Please select an image file');
          return;
        }
        if (typeof FileReader === 'function') {
          var self = this;
          this.resizeImage(file,665,1080, function(dataurl){
            self.imgSrc = dataurl;
            self.$refs.cropper.replace(dataurl);
            setTimeout(function () {
              self.tempPhoto = self.$refs.cropper.getCroppedCanvas().toDataURL();
            }, 200);
          });
        } else {
          alert('Sorry, FileReader API not supported');
        }
      },
      resizeImage(file, scale_width,scale_height, onEnd) {
          var reader = new FileReader();
          var self = this;
          reader.onload = function(e) {
              self.resizeDataUrl(e.target.result,file.type, scale_width, scale_height, onEnd);
          }
          reader.readAsDataURL(file);
    },
    resizeDataUrl(filedata,imagetype, scale_width, scale_height, onEnd){
      var img = document.createElement("img");
      img.src = filedata;
      img.onload = function(){
          var width = img.width;
          var height = img.height;
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");
          
          ctx.drawImage(img, 0, 0);
          var MAX_WIDTH = scale_width;
          var MAX_HEIGHT = scale_height;
          if (width > height) {
              if (width > MAX_WIDTH) {
                  height *= MAX_WIDTH / width;
                  width = MAX_WIDTH;
              }
          } else {
              if (height > MAX_HEIGHT) {
                  width *= MAX_HEIGHT / height;
                  height = MAX_HEIGHT;
              }
          }
          canvas.width = width;
          canvas.height = height;
          if(imagetype.includes("j")){
            ctx.fillStyle = '#fff'; 
            ctx.fillRect(0, 0, canvas.width, canvas.height);
          }
          ctx.drawImage(img, 0, 0, width, height);
          var dataurl = canvas.toDataURL(imagetype, 0.8);
          onEnd(dataurl);
      }
    },
      cropImage() {
        if(this.$refs.cropper.getCroppedCanvas()){
          this.powerteam.photo = this.$refs.cropper.getCroppedCanvas().toDataURL();
          /*var self = this;
          this.resizeDataUrl(this.powerteam.nm_photo,'image/jpeg',132,216, function(dataurl){
              self.powerteam.photo = dataurl;
          });*/
        }else{
          this.powerteam.photo = null;
        }
       
        this.showPhotoEdit = false;
      },
          back(){
            router.push(this.useruid==''? "/admin/powerteams": "/powerteams/"+this.useruid);
          },
          onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length || !this.checkFileSize(files[0]))
              return;
            this.createImage(files[0]);
          },
          createImage(file) {
            var reader = new FileReader();
            reader.onload = (e) => {
              this.powerteam.photo = e.target.result;
            };
            reader.readAsDataURL(file);
          },
          removeImage: function () {
            this.powerteam.photo = null;
          },
          openDialog() {
            document.getElementById('fileid').click();
          },
          openDialog2() {
            document.getElementById('fileid2').click();
          },
          onFileChange2(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length || !this.checkFileSize(files[0]))
              return;

            this.powerteam.filename = files[0].name;
            this.powerteam.file = files[0];
         },
      rotateImageLeft(){
        this.$refs.cropper.rotate(90);
        this.cropMove();
      },
      rotateImageRight(){
        this.$refs.cropper.rotate(-90);
        this.cropMove();
      },
      flipImageX(){
        var d = this.$refs.cropper.getData()
        this.$refs.cropper.scaleX(d.scaleX*-1);
        this.cropMove();
      },
      flipImageY(){
        var d = this.$refs.cropper.getData()
        this.$refs.cropper.scaleY(d.scaleY*-1);
        this.cropMove();
      },
      getMembers() {
        this.$http.post((this.useruid==''? "/api/organization/members" : "/api/powerteam/members/byuseruid"), {useruid: this.useruid, id: this.powerteam.id}).then((response) => {
          
            this.members = response.data.members;
            if(this.powerteam.id==0 && response.data.success){
              this.isManager = true;
            }
            this.getPowerteam();
        }).catch((errors) => {console.log(errors);});
    },
    
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-powerteams"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      if(this.$route.params.useruid){
        this.useruid = this.$route.params.useruid;
      }
      this.powerteam.id = this.$route.params.id;
      this.getMembers();
      this.addClass(document.getElementById("menu-powerteams"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
    }
  }
</script>
