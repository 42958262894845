<template>
  <div>
    <div class="rel-grid-container" style="max-width: 90%;">
      <div id="top-div"></div>
      <div>
        <div class=" section-title">{{$t('scripts.meeting_script')}} - {{!permissions.organization.is_parent && next_meeting? $d(new Date("" + next_meeting.split("-").join("/")+ " 00:01"), 'short') : totalTime}}</div>
        <div class="section-title" style="font-size:18px;" v-if="!permissions.organization.is_parent">{{$t('scripts.total_duration')}} - {{totalTime}}</div>
            <Tip :tipNumber="8"></Tip>
          <div style="display:grid;">
            <b-form-checkbox style="margin:auto; margin-right:0px;margin-top:50px;" v-model="permissions.populateVariables">
                {{$t('scripts.show_variables')}}
              </b-form-checkbox>
          </div>
          <SetupTip v-if="!permissions.organization.is_parent" style="position:absolute; left: -40px;" :tipNumber="6"/>
          <SetupTip v-if="permissions.organization.is_parent" style="position:absolute; left: -40px;" :tipNumber="13"/>
          <table class="table b-table table-hover-new table-bordered table-sm">
            <thead>
              <tr>
                <th class="tableHeader" style="width: 80px;">{{$t('scripts.time_header')}}</th>
                <th class="tableHeader" style="width: 200px;">{{$t('scripts.section_header')}}</th>
                <th  style="width: 40%;" v-if="!permissions.organization.is_parent || organization_id>0" class="tableHeader">{{isCorporateConnections? $t('scripts.script_cc') : $t('scripts.script_bni')}}</th>
                <th style="width: 40%;" class="tableHeader">{{(!permissions.organization.is_parent || organization_id>0? $t('scripts.notes_header',{organization: org_name}) : $t('scripts.script_header',{organization: org_name}))}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="script in scripts" :key="script.id"  :class="{'lastRow-above': script.section_id>0, 'grey-row': script.isParent==1}">
                <td >
                  <!--    background: repeating-linear-gradient( -45deg, rgba(0,0,0,0) 24px, rgba(0,0,0,0) 36px, #ccc 5px, #ccc 45px );-->
                  <div v-if="script.section_id>0"  style="display:grid;text-align:center;">
                    <span>{{script.start_time.slice(0,8)}}</span>
                    <span>({{getScriptTime(script)}})</span>
                  </div>
                </td>  
                <td>
                  <span style="font-weight:bold;">{{script.name && script.name.length>0? script.name : $t('global.no_translation')  }}</span>
                  <div style="padding-left:30px;margin-top:20p;x" v-if="script.slide_names.length>0">
                    <div v-for="name in script.slide_names" :key="name">
                      <span>&#9679; </span>
                      <span style="color: #999;">{{name.name && name.name.length>0? name.name : $t('global.no_translation') }}</span>
                    </div> 
                  </div>
                </td>
                <td v-if="!permissions.organization.is_parent || organization_id>0">
                  <pre v-if="script.isParent==1" class="pre-format" v-html="permissions.populateVariables? script.var_script : highlight(script.script)"></pre>
                </td>
                <td v-on:click="editScript(script)" :class="{clickable: organization_id==0}">
                  <pre class="pre-format" v-html="permissions.populateVariables? (script.isParent==1? script.var_notes : script.var_script) : highlight(script.isParent==1? script.notes : script.script)"></pre>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      <div>
        <b-modal id="slide-modal" centered v-model="showScriptEdit" :title="$t('scripts.script_title')+$tc('scripts.script_notes', 1)">
          <div style="display: grid; grid-template-columns: auto 250px;">
            <div style="grid-column:1/3;">
              <Tip :tipNumber="9"></Tip>
            </div>
            <div>
              <span class="form-box-title">{{$tc('scripts.section_script', 1)+" "+$tc('scripts.script_notes', currentScript.isParent>0? 0 : 1)}}</span>
              <div class="form-box" style="grid-template-columns:auto;">
                <div class="editor">
                  <editor-menu-bar :editor="editor">
                    <div class="menubar" slot-scope="{ commands, isActive }">
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.bold() }" v-on:click="commands.bold">
                        <b>B</b>
                      </b-button>
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.italic() }" v-on:click="commands.italic">
                        <i>I</i>
                      </b-button>
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.strike() }" v-on:click="commands.strike">
                        <s>S</s>
                      </b-button>
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.underline() }" v-on:click="commands.underline">
                        <u>U</u>
                      </b-button>
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.paragraph() }" v-on:click="commands.paragraph">
                        P
                      </b-button>
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.superscript() }" v-on:click="commands.superscript()">
                        super<sup>script</sup>
                      </b-button>
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.heading({ level: 1 }) }" v-on:click="commands.heading({ level: 1 })">
                        H1
                      </b-button>
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.heading({ level: 2 }) }" v-on:click="commands.heading({ level: 2 })">
                        H2
                      </b-button>
                      
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.bullet_list() }" v-on:click="commands.bullet_list">
                        {{$t('entry.bullet_text')}}
                      </b-button>
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.ordered_list() }" v-on:click="commands.ordered_list">
                        {{$t('entry.list_text')}}
                      </b-button>
                      <b-button variant="outline-dark" v-on:click="commands.undo">
                        {{$t('entry.undo_text')}}
                      </b-button>
                      <b-button variant="outline-dark" v-on:click="commands.redo">
                        {{$t('entry.redo_text')}}
                      </b-button>
                    </div>
                  </editor-menu-bar>
                  <editor-content class="editor__content" :editor="editor" style="height:323px;width:100%;overflow-y: auto;overflow-x: hidden;" />
                </div>
              </div>
            </div>
            <div id="variables-box" style="grid-row-start:2;">
              <span class="form-box-title">{{$t('scripts.insert_variables')}}</span>
              <div class="form-box" style="grid-template-columns: auto;">
                <div>
                  <b-form-checkbox-group style="display: grid;" v-model="variableGroup" :options="groupOptions" value-field="value" text-field="name" >
                  </b-form-checkbox-group>
                </div>
                <div style="height: 221px; overflow-y: auto; margin-top: 10px;">
                  <table class="table b-table table-hover-new table-bordered table-sm">
                    <tbody>
                      <tr class="variable-row" v-for="variable in variable_map.filter(v => v.group == variableGroup)" v-on:click="addTextVariable(variable)" :key="variable.id">
                        <td>{{ variable.name }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style="font-size: 10px;color: #777;">{{$t('scripts.click_entry')}}</div>
              </div>
            </div>
          </div>
          <div slot="modal-footer" class="submit-box-2">
            <!--<b-button v-if="currentScript.isParent == 1" class="submit-button4" v-on:click="copyScriptToNotes();" variant="clear-blue"><div class="copy-button"></div><span>{{$t('global.button_copy')}}</span></b-button>-->
            <b-button class="submit-button4" v-on:click="updateScript();showScriptEdit = false;" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
        </b-modal>
      </div>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped lang="scss">
  .lastRow-above {
    border-top: $VUE_APP_COLOUR_MAIN solid 3px;
  }
  
  .section-title {
    text-align: center;
    font-size: 25px;
    margin-bottom: 10px;
  }
</style>
<script>
  import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
  import Superscript from '@/assets/Superscript'
  import {
    Blockquote,
    HardBreak,
    Heading,
    HorizontalRule,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Italic,
    Link,
    Strike,
    Underline,
    History
  } from 'tiptap-extensions'

    export default {
    name: "Scripts",
    components: {
      EditorContent,
      EditorMenuBar,
    },
    watch: {
      'permissions.populateVariables': {
        handler: function (v) {
          
          if(v){
            this.populateVariables()
          }
        },
        deep: true
      },
      '$i18n.locale': {
        handler: function () {
          this.organization_id = this.$route.params.id ? this.$route.params.id : 0;
          this.getScripts();
        }
      }
    },
      data() {
        return {
          editor: new Editor({
            disableInputRules: true,
            disablePasteRules: true,
            extensions: [
              new Blockquote(),
              new BulletList(),
              new HardBreak(),
              new Heading({ levels: [1, 2, 3] }),
              new HorizontalRule(),
              new ListItem(),
              new OrderedList(),
              new TodoItem(),
              new TodoList(),
              new Bold(),
              new Italic(),
              new Link(),
              new Strike(),
              new Underline(),
              new History(),
              new Superscript()
            ],
            content: ''
          }),
          groupOptions: [{name: this.$t('slide.group_option1'), value: 'chapter'},{name: this.$t('slide.group_option2'), value: 'analytic'},
          {name: this.$t('slide.group_option3'), value: 'regional'},{name: this.$t('slide.group_option4'), value: 'role'},{name: this.$t('slide.group_option7'), value: 'transition'},
          {name: this.$t('slide.group_option5'), value: 'speaker'},{name: this.$t('slide.group_option6'), value: 'other'}],
          variableGroup: 'chapter',
          showScriptEdit: false,
          scripts: [],
          variable_map: [],
          currentScript: {
            section_id: 0,
            slide_id: 0,
            script: "",
            notes: ""
          },
          currentIndex: 0,
          next_meeting: null,
          totalTime: "",
          organization_id: 0,
          org_name : ""
        }
      },
    methods: {
      getScriptTime(script){
        return script.section_id>0? script.min+":"+(script.sec<=9? "0" : "")+script.sec : "";
      },
      populateVariables(){
        var self = this;
        for (var i = 0; i < self.scripts.length; i++) {
          self.$set(self.scripts[i], 'var_script', self.scripts[i].script);
          self.$set(self.scripts[i], 'var_notes', self.scripts[i].notes);
          this.variable_map.forEach(function (item) {
              if(item.id == "{Current_Time}"){
                item.value = self.$d(new Date(), 'time12') ;
              }else if(item.id == "{Current_Date}"){
                item.value = self.$d(new Date(), 'short') ;
              }
              if (self.scripts[i].var_script.indexOf(item.id) >= 0) {
                self.scripts[i].var_script = self.scripts[i].var_script.split(item.id).join(item.noHighlight? item.value :("<b class='script-variable'>"+(item.value.length>0? item.value: item.id)+"</b>"));
              }
              if (self.scripts[i].var_notes.indexOf(item.id) >= 0) {
                self.scripts[i].var_notes = self.scripts[i].var_notes.split(item.id).join(item.noHighlight? item.value : ("<b class='script-variable'>"+(item.value.length>0? item.value: item.id)+"</b>"));
              }
          });
        }
      },
      populateScriptVariables(script){
        var self = this;
        self.$set(script, 'var_script', script.script);
        self.$set(script, 'var_notes', script.notes);
        this.variable_map.forEach(function (item) {
            if(item.id == "{Current_Time}"){
              item.value = self.$d(new Date(), 'time12') ;
            }else if(item.id == "{Current_Date}"){
              item.value = self.$d(new Date(), 'short') ;
            }
            if (script.var_script.indexOf(item.id) >= 0) {
              script.var_script = script.var_script.split(item.id).join(item.noHighlight? item.value : ("<b class='script-variable'>"+(item.value.length>0? item.value: item.id)+"</b>"));
            }
            if (script.var_notes.indexOf(item.id) >= 0) {
              script.var_notes = script.var_notes.split(item.id).join(item.noHighlight? item.value : ("<b class='script-variable'>"+(item.value.length>0? item.value: item.id)+"</b>"));
            }
        });
      },
      editScript(item) {
        if (this.organization_id == 0 && item != undefined) {
          this.showScriptEdit = true;
          this.currentScript = item;
          this.editor.setContent(this.currentScript.isParent? this.currentScript.notes :this.currentScript.script);
        }
      },
      focus(index) {
        this.currentIndex = index;
      },
      copyScriptToNotes(){
        this.editor.setContent(this.currentScript.script);
      },
      addTextVariable(variable) {
        const { state } = this.editor;
        var t = this.editor.getHTML();

        var a = state.selection.$anchor.nodeBefore != null? state.selection.$anchor.nodeBefore.text : "";
        var b = state.selection.$anchor.nodeAfter != null? state.selection.$anchor.nodeAfter.text: "";

        var parts = t.split(a+b);
        if(parts.length == 2){
          this.editor.setContent(parts[0]+a+variable.id+b+parts[1]);
        }else{
          this.editor.setContent(t+variable.id);
        }
      },
      getOrgName(){
        if(this.organization_id>0){
          this.$http.post("/api/organization/get/name", {id : this.organization_id}).then((response) => {
          if (response.data.success) {
           this.org_name = response.data.name;
          } 
        }).catch((errors) => {
          console.log(errors);
        });
        }else{
          this.org_name = this.permissions.organization.name;
        }
      },
      getScripts() {
        this.$http.post("/api/meeting/scripts", {id : this.organization_id,slide_scripts: false, language: this.$i18n.locale}).then((response) => {
          if (response.data.success) {
            this.totalTime = response.data.totalTime;
            this.next_meeting = response.data.next_meeting;
            this.scripts = response.data.scripts;
            var t = 0;
            this.start = response.data.start;
            
            for (var i = 0; i < this.scripts.length; i++) {
              if (!this.scripts[i].disabled && this.scripts[i].section_id>0) {
                var datetime = new Date(new Date().toDateString() + " " + this.start + " (EST)");
                datetime.setSeconds(datetime.getSeconds() + t);
                this.scripts[i].start_time = datetime.toLocaleTimeString();
                t += this.scripts[i].target_duration;
              }
            }
            this.variable_map = response.data.variable_map;
            if(this.permissions.populateVariables){
              this.populateVariables();
            }
          } else {
            this.scripts = [];
          }
        }).catch((errors) => {
          this.scripts = [];
          console.log(errors);
        });

      },
      highlight(script) {
        var self = this;
        this.variable_map.forEach(function (item) {
          if(item.id == "{Current_Time}"){
            item.value = self.$d(new Date(), 'time12') ;
          }else if(item.id == "{Current_Date}"){
            item.value = self.$d(new Date(), 'short') ;
          }
          if (script && script.indexOf(item.id) >= 0) {
            script = script.split(item.id).join("<b class='script-variable'>" + item.id + "</b>");
          }
        });
        return script;
      },
      updateScript() {
        if(this.currentScript.isParent){ 
          this.currentScript.notes = this.editor.getHTML();
        }else{
          this.currentScript.script = this.editor.getHTML();
        }
        if(this.permissions.populateVariables){
          this.populateScriptVariables(this.currentScript);
        }
         if (this.currentScript != null) {
           this.currentScript.language = this.$i18n.locale;
           this.currentScript.update_text = this.currentScript.isParent==1? this.currentScript.notes : this.currentScript.script;
          this.$http.post("/api/meeting/scripts/update", this.currentScript).then(() => {
          }).catch(() => {
            this.$bvToast.show('serverError');
          });
        }
      },
    },
    beforeDestroy() {
      this.editor.destroy()
    },
   destroyed() {
      this.removeClass(document.getElementById("menu-scripts"), "active-menu");
      this.removeClass(document.getElementById("menu-meeting"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-scripts"), "active-menu");
      this.addClass(document.getElementById("menu-meeting"), "active-menu");
      this.organization_id = this.$route.params.id ? this.$route.params.id : 0;
      this.getScripts();
      this.getOrgName();
    }
  }
</script>
