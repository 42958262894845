<template>
  <div>
    <div class="rel-grid-container">
      <div id="top-div"></div>
      <div >
          <table  class="table b-table table-hover-new table-bordered table-sm">
              <thead>
                <tr>
                <th class="tableHeader">{{$t('status.organization_header')}}</th>
                <th class="tableHeader">{{$t('status.start_header')}}</th>
                <th class="tableHeader">{{$t('status.end_header')}}</th>
                </tr>
            </thead>
              <tbody>
                  <tr v-if="status.ongoing.length+status.connected.length+status.upcoming.length==0">
                        <td  colspan="3">{{$t('status.no_status')}}</td>
                    </tr>
                  <tr v-if="status.ongoing.length>0">
                        <th class="tableHeader" colspan="3">{{$t('status.ongoing_header')}}</th>
                    </tr>
                  <tr v-for="ongoing in status.ongoing" :key="ongoing.name">
                      <td>{{ongoing.name}}</td>
                      <td>{{ongoing.start}}</td>
                      <td>{{ongoing.end}}</td>
                    </tr>
                    <tr v-if="status.upcoming.length>0">
                        <th class="tableHeader" colspan="3">{{$t('status.upcoming_header')}}</th>
                    </tr>
                    <tr v-for="upcoming in status.upcoming" :key="upcoming.name">
                      <td>{{upcoming.name}}</td>
                      <td>{{upcoming.start}}</td>
                      <td>{{upcoming.end}}</td>
                    </tr>
                    <tr v-if="status.connected.length>0">
                        <th class="tableHeader" colspan="3">{{$t('status.connected_header')}}</th>
                    </tr>
                    <tr v-for="connected in status.connected" :key="connected.name">
                      <td>{{connected.o_name +" > "+connected.name}}</td>
                      <td></td>
                      <td></td>
                    </tr>
              </tbody>
          </table>
      </div> 
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
  
</style>
<script>
    export default {
    name: "Status",
    data() {
      return {
          status: {ongoing: [],upcoming:[],connected: []}
      }
    },
    methods: {
      getStatus() {
        this.$http.post("/api/getstatus", {}).then((response) => {
          if (response.data.success) {
            this.status = response.data.ret;
          }
        }).catch(() => {
        });
      }
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-status"), "active-menu");
      this.removeClass(document.getElementById("menu-admin"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-status"), "active-menu");
      this.addClass(document.getElementById("menu-admin"), "active-menu");
      this.getStatus();
    }
    }
</script>
