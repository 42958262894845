<template>
  <div>
    <Header :useruid="this.$route.params.useruid" :title="$t('schedule.manage_speakers')" :showDate="false"></Header>
    <div class="rel-grid-container" :style="{'max-width': chapter_id>0? '100%': '75%'}">
      <div id="top-div"></div>
      <Tip :tipNumber="22"></Tip>
      <b-tabs v-model="permissions.showSpeakerGrid" content-class="mt-3" justified v-on:activate-tab="tabActivated">
            <b-tab :title-link-class="linkClass(0)">
                <template  #title>
                    <img style="height:25px;" :src="getImgUrl('nav-ListView-gray.png')"><span>{{isCorporateConnections? $t('schedule.spotlight_speakers') : $t('schedule.list_mode')}}</span>
                </template>
            <b-alert :show="speakersNotEmailed" style="margin:auto;" class="fit-width" variant="danger">{{ $t('general.debug_message1') }}<br/>{{ $t('general.debug_message2') }}</b-alert>
            <b-form-checkbox v-on:change="previousSpeakersChange" style="float: right;" v-model="permissions.previousSpeakers">
              {{$t('schedule.previous_speakers')}}
            </b-form-checkbox>
            <table  class="table b-table table-hover-new table-bordered table-sm">
              <thead>
                <tr>
                  <th class="tableHeader" v-if="permissions.organization.is_parent">{{$t('schedule.chapter_text')}}</th>
                  <th class="tableHeader">{{$t('schedule.name_text')}}</th>
                  <th class="tableHeader">{{$t('schedule.company_text')}}</th>
                  <th v-if="isCorporateConnections" class="tableHeader">{{$t('entry.note_text')}}</th>
                  <th class="tableHeader">{{$t('schedule.date_text')}}</th>
                </tr>
              </thead>
              <draggable :disabled="chapter_id==0 && permissions.organization.is_parent"  tag="tbody" group="speakers" :list="schedule" @end="endDrag">
                <tr :style="{'font-weight': next_meeting == entry.day? 'bold' : 'normal', cursor: 'pointer'}" class="dragndrop" :class="{'old-speaker-break':  schedule.indexOf(entry)!= schedule.length-1 && entry.old==1 && schedule[schedule.indexOf(entry)+1].old==0}" v-for="entry in schedule.filter(v => v.status==0)" :key="entry.id" v-on:click="editEntry(entry)">
                  <td v-if="permissions.organization.is_parent">{{ entry.chapter_name }}</td>
                  <td>{{ entry.name }}</td>
                  <td>{{ entry.company }}</td>
                  <td v-if="isCorporateConnections">{{ entry.note }}</td>
                  <td>{{ entry.day }}</td>
                </tr>
              </draggable>
            </table>
            <div v-if="chapter_id>0 || !permissions.organization.is_parent" style="float: right;font-size: 10px;">{{$t('schedule.drag_order')}}</div>
            <div class="submit-box-2">
              <b-button class="submit-button4" v-on:click="newEntry" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
            </div>
            </b-tab>
            <!--<b-tab v-if="!isCorporateConnections"  :title-link-class="linkClass(1)">
                <template #title>
                    <img style="height:25px;" :src="getImgUrl('nav-CalendarView-gray.png')"><span>{{$t('schedule.grid_mode')}}</span>
                </template>
            <div>
              <div :id="'dateblock_'+date.id" v-for="date in meetingSpeakers" :key="date.dc" class="date-bucket" :style="{'border-bottom': date != meetingSpeakers[meetingSpeakers.length-1]? 'none' : ''}">
                  <div >
                    <div  class="block-controls">
                      <b-button id="newSpeakerButton" variant="blue" v-on:click.stop="newEntryDate(date.dt)" v-b-popover.hover="$t('schedule.new_speaker')" ></b-button>
                      <b-button id="sectionUpButton" v-show="date.id>0" variant="blue" v-on:click.stop="moveSpeakersUp(date.id)" v-b-popover.hover="$t('schedule.speakers_up')" ></b-button>
                      <b-button id="sectionDownButton" variant="blue" v-on:click.stop="moveSpeakersDown(date.id)" v-b-popover.hover="$t('schedule.speakers_down')"></b-button>
                    </div>
                  </div>
                  <div style="text-align:left;margin:auto 0;font-weight:bold;" >{{date.date}}</div>
                  <draggable style="display: flex;"  tag="div" group="speakers2" @end="gridDrag" v-model="date.speakers">
                    <div :id="'speakerblock_'+speaker.id"  v-on:click="editEntry(speaker)" v-for="speaker in date.speakers" :key="speaker.id" class="speaker-bucket fit-width"><div class="trash-speaker" v-on:click.stop="speakerToDelete=speaker; showDelete=true;"></div>{{speaker.name}}</div>
                  </draggable>
              </div>
              <div style="text-align: right;font-size: 10px;">{{$t('schedule.drag_order')}}</div>
              <div style="display:grid; grid-template-columns: max-content auto max-content;width: 200px;margin: 10px auto;">
                <b-button v-show="permissions.speakerGridOffset>0" style="margin:auto;margin-left:0px;"  v-on:click="shiftOffset(-4)" class="submit-button4" variant="clear-blue">
                  <div class="back-button"></div><span>{{$t('global.button_back')}}</span>
                </b-button>
                <div></div>
                <b-button v-show="meeting_days.length==0 || permissions.speakerGridOffset+10<meeting_days.length" style="margin:auto; margin-right:0px;" v-on:click="shiftOffset(4)" class="submit-button4" variant="clear-blue">
                  <div class="next-button"></div><span>{{$t('global.button_next')}}</span>
                </b-button> 
              </div>
            </div>
          </b-tab>-->
          
          <b-tab v-if="!isCorporateConnections"  :title-link-class="linkClass(1)">
                <template #title>
                    <img style="height:25px;" :src="getImgUrl('icon-Planner.png')"><span>{{$t('schedule.planner_mode')}}</span>
                </template>
            <div >
              <div style="display:grid;grid-template-columns: 1fr 1fr;">
                <div>
                  <div style="display:grid; grid-template-columns: 300px 125px 125px;width: 550px;margin:bottom: 10px;">
                    <div style="margin: auto 10px;"><b>{{$t('schedule.meeting_dates')}}</b></div>
                    <b-button v-if="permissions.speakerGridOffset>0" style="margin:auto;margin-left:0px;"  v-on:click="shiftOffset(-4)" class="submit-button4" variant="clear-blue">
                      <div class="back-button"></div><span>{{$t('global.button_back')}}</span>
                    </b-button>
                    <div v-if="!(permissions.speakerGridOffset>0)"></div>
                    <b-button v-show="meeting_days.length==0 || permissions.speakerGridOffset+10<meeting_days.length" style="margin:auto; margin-right:0px;" v-on:click="shiftOffset(4)" class="submit-button4" variant="clear-blue">
                      <div class="next-button"></div><span>{{$t('global.button_next')}}</span>
                    </b-button> 
                  </div>
                  <div :id="'dateblock_'+date.id" v-for="date in meetingSpeakers" :key="date.dc" class="date-bucket" :style="{'grid-template-columns': '70px auto','border-bottom': date != meetingSpeakers[meetingSpeakers.length-1]? 'none' : ''}">
                      <div style="text-align:left;margin:auto 0;font-weight:bold;" >{{date.date}}</div>
                      <draggable style="display: flex;"  tag="div" group="speakers2"  v-model="date.speakers" handle=".speaker-bucket" @change="unAssignedChange(date, $event)">
                        <div :id="'speakerblock_'+speaker.id" v-for="speaker in date.speakers" :key="speaker.id" v-on:click="editPlanner(speaker,date)" :style="{'border-style': speaker.status==0? 'solid' : dashed, 'border-color': statusColor[speaker.status], 'color': statusColor[speaker.status]}" class="planner-bucket speaker-bucket fit-width curtail-text" >
                          <div class="status-image" :class="{'thumbs-up': speaker.status==0,'thumbs-down': speaker.status==3,'question-mark': speaker.status==2,'pinned-image': speaker.status==1}"></div><span >{{speaker.name}}</span><div class="trash-speaker" v-on:click.stop="speakerToDelete=speaker; showDelete=true;"></div></div>
                      </draggable>
                  </div>
                  
                  <!--<div style="text-align:center; margin-top:10px;">{{$t('schedule.save_note')}}</div>
                  <div >
                    <b-button style="margin:auto;"  v-on:click="saveUnassigned" class="submit-button4" variant="clear-blue">
                      <div class="save-button"></div><span>{{$t('global.button_save')}}</span>
                    </b-button>
                  </div>-->
                </div>
                <div>
                <div style="padding-left: 50px;max-height: 570px;overflow-y: auto;">
                  <div >
                    <div style="display:grid; grid-template-columns: 100px 100px auto;">
                      <b-form-checkbox v-model="membersOrRegional" :value="0" :unchecked-value="1" style="margin:auto;font-weight:bold;" class="fit-width">
                        {{$t('schedule.member_text')}}
                      </b-form-checkbox>
                      <b-form-checkbox v-model="membersOrRegional" :value="1" :unchecked-value="0" style="margin:auto;font-weight:bold;" class="fit-width">
                        {{$t('schedule.regional_text')}}
                      </b-form-checkbox>
                      <div style="padding-left:20px;font-weight:bold;">{{$t('schedule.last_date')}}</div>
                    </div>
                    <draggable v-if="membersOrRegional==0"  tag="div" group="speakers2" v-model="unAssigned" handle=".speaker-bucket" @end="unassignedDragEnd" @start="unassignedDragStart" >
                      <div :id="'unassigned_'+m.member_id" v-for="m in unAssigned"  :key="m.id" style="display:grid; grid-template-columns: 200px auto;padding:3px 0px;font-size:14px">
                        <div  class="new-speaker speaker-bucket">{{m.name}}</div><div  style="padding-left:5px;margin:auto 0px;" >{{m.last_date!=null? m.last_date: $t('schedule.no_record')}}</div>
                      </div>
                    </draggable>
                    <draggable v-if="membersOrRegional==1" tag="div" group="speakers2" v-model="unAssignedContacts" handle=".speaker-bucket" @end="unassignedDragEnd" @start="unassignedDragStart" >
                      <div :id="'unassigned_'+m.member_id" v-for="m in unAssignedContacts"  :key="m.id" style="display:grid; grid-template-columns: 200px auto;padding:3px 0px;font-size:14px">
                        <div  class="new-speaker speaker-bucket">{{m.name}}</div><div  style="padding-left:5px;margin:auto 0px;" >{{m.last_date!=null? m.last_date: $t('schedule.no_record')}}</div>
                      </div>
                    </draggable>
                  </div>

                </div>
                
                </div>
              </div>
              
            </div>
          </b-tab>

        </b-tabs>
      <div class="div-space"></div>
    </div>
    <b-modal id="modal-center" v-model="showAssignSpeakerModal" centered :title="assignSpeaker != null && assignSpeaker.id>0? $t('schedule.edit_speaker') : $t('schedule.assign_speaker')" :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true">
          <div v-if="assignSpeaker != null">
            <div style="display:grid;grid-template-columns:auto auto;margin:auto;text-align: left;column-gap: 10px;" class="fit-width"><span class="form-box-name" style="margin:0px">{{$t('schedule.member_name')}}</span><b>{{assignSpeaker.name}}</b>
            <span class="form-box-name" style="margin:0px">{{$t('schedule.date_and_time')}}</span><b>{{assignSpeaker.day}} @ {{assignSpeaker.time}}</b></div>
            <div v-if="assignSpeaker.originalStatus!= 3 && !(assignSpeaker.originalStatus==0 && assignSpeaker.responded_on !=null)" style=" margin: 10px auto;">
              <b-form-checkbox-group style="margin: auto 0;" v-model="assignSpeaker.status" :options="speakerStatusOptions">
            </b-form-checkbox-group>
            </div>
            <div v-if="(assignSpeaker.originalStatus==0 && assignSpeaker.responded_on !=null) || assignSpeaker.originalStatus==3" style="display:grid; grid-template-columns: auto auto; margin: 10px auto;width: 300px;">
              <b-form-checkbox v-model="assignSpeaker.status" :value="1" :unchecked-value="0">
                {{$t('schedule.planned_text')}}
              </b-form-checkbox>
              <b-form-checkbox v-model="assignSpeaker.status" :value="0" :unchecked-value="1" >
                {{$t('schedule.confirmed_text')}}
              </b-form-checkbox>
            </div>
            <div v-if="assignSpeaker.status==2 && assignSpeaker.confirmation_requested==null" style="margin-top:5px;">
              <div style="display:grid;grid-template-columns: 50px auto;grid-gap:5px;text-align:left;">
                  <div class="status-image question-mark" style="width:35px;height:35px;grid-row:1/3;"></div>
                  <i>{{$t('schedule.request_text1')}}</i>
                  <div style="display:grid; grid-template-columns: auto auto;" class="fit-width">
                    <span style="text-align:right;margin: auto 5px;">{{$t('schedule.expire_confirmation')}}:</span>
                    <b-form-select style="width: 150px;"  v-model="assignSpeaker.confirmation_expiry" :options="expiry_options" />
                  </div>
              </div>
            </div>
            <div v-if="assignSpeaker.status==2 && assignSpeaker.confirmation_requested!=null" style="margin-top:5px;">
              <div style="display:grid;grid-template-columns: 50px auto;grid-gap:5px;text-align:left;">
                  <div class="status-image question-mark" style="width:35px;height:35px;grid-row:1/3;"></div>
                  <i>{{$t('schedule.confirmation_requested',{requested: assignSpeaker.confirmation_requested})}}</i>
                  <i>{{assignSpeaker.expiry_date!=null? $t('schedule.expires_on', {expiry: assignSpeaker.expiry_date}) : $t('schedule.never_expires')}}</i>
              </div>
              <b-button class="submit-button4" v-on:click="resendConfirmation" variant="clear-blue" style="margin: auto;"><div class="email-button"></div><span>{{$t('global.button_resend')}}</span></b-button>
            </div>
            <div v-if="assignSpeaker.status==1" style="margin-top:5px;">
              <div style="display:grid;grid-template-columns: 50px auto;grid-gap:5px;text-align:left;">
                  <div class="status-image pinned-image" style="width:35px;height:35px;grid-row:1/3;"></div>
                  <i>{{$t('schedule.pinned_text1')}}</i>
                  <i>{{$t('schedule.pinned_text2')}}</i>
              </div>
            </div>
            <div v-if="assignSpeaker.status==0 && assignSpeaker.responded_on != null" style="margin-top:5px;">
              <div style="display:grid;grid-template-columns: 50px auto;grid-gap:5px;text-align:left">
                  <div class="status-image thumbs-up" style="width:35px;height:35px;grid-row:1/3;"></div>
                  <i>{{$t('schedule.confirmed_on',{time: assignSpeaker.responded_on})}}</i>
              </div>
            </div>
            <div v-if="assignSpeaker.status==0 && assignSpeaker.responded_on == null" style="margin-top:5px;">
                <div style="display:grid;grid-template-columns: 50px auto;grid-gap:5px;text-align:left">
                  <div class="status-image thumbs-up" style="width:35px;height:35px;grid-row:1/3;"></div>
                  <i>{{$t('schedule.confirmed_text1')}}</i>
                  <i>{{$t('schedule.confirmed_text2')}}</i>
              </div>
            </div>
            <div v-if="assignSpeaker.status==3 && assignSpeaker.responded_on != null" style="margin-top:5px;">
              <div style="display:grid;grid-template-columns: 50px auto;grid-gap:5px;text-align:left">
                  <div class="status-image thumbs-down" style="width:35px;height:35px;grid-row:1/3;"></div>
                 <i>{{$t('schedule.declined_on',{time: assignSpeaker.responded_on})}}</i>
              </div>
            </div>
            <div v-if="assignSpeaker.status==3" style="margin-top:5px;">
              <div style="display:grid;grid-template-columns: 50px auto;grid-gap:5px;text-align:left">
                  <div class="status-image thumbs-down" style="width:35px;height:35px;grid-row:1/3;"></div>
                 <i>{{$t('schedule.decline_expiry')}}</i>
              </div>
            </div>
          </div>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="submit-button4" v-on:click="removePlanned" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="submit-button4" v-on:click="updatePlanner" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
        </b-modal>
    <b-modal id="modal-center" v-model="showDelete" centered :title="$t('entry.delete_title')">
          <p v-if="speakerToDelete && (speakerToDelete.has_presentation==1 || speakerToDelete.has_bio==1)" class="my-4" style="font-weight:bold;">{{$t('schedule.delete_content')}}</p>
          <p class="my-4">{{$t('schedule.delete_text')}}</p>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="submit-button4" v-on:click="showDelete=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="submit-button4" v-on:click="deleteSpeaker" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          </div>
        </b-modal>
        <b-modal id="modal-center" v-model="showDeleteAll" centered :title="$t('entry.delete_title')">
          <p class="my-4">{{$t('schedule.delete_all')}}</p>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="submit-button4" v-on:click="showDeleteAll=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="submit-button4" v-on:click="deleteDate" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          </div>
        </b-modal>
        
    <b-modal centered id="slide-modal" v-model="showSyncSpeakers" :title="$t('schedule.sync_speakers')">
        <div id="preview-members">
          <b-table class="table-hover-new" small bordered :items="syncSpeakers" :fields="previewFields">
            <template v-slot:cell(import)="data">
              <b-form-checkbox v-model="data.item.import" value="1" unchecked-value="0" style="margin:auto;" class="fit-width">
            </b-form-checkbox>
            </template> 
          </b-table>
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="submit-button4" v-on:click="confirmImport" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_confirm')}}</span></b-button>
        </div>
      </b-modal>
      <b-modal centered  v-model="showSaveUnassigned" >
        <div>
          {{$t('schedule.save_unassigned')}}
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="submit-button4" v-on:click="showSaveUnassigned=false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="submit-button4" v-on:click="leaveUnassigned" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_no')}}</span></b-button>
          <b-button class="submit-button4" v-on:click="saveUnassigned" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_yes')}}</span></b-button>
        </div>
      </b-modal>
      <b-modal id="slide-modal-2" v-model="showEntry" centered >
          <div v-if="chapterSpeaker.id==0" style="display:grid; grid-template-columns: auto auto;">
            <span  class="form-box-name">{{$t('dates.chapter_text')}}</span>
            <b-form-select v-model="chapterSpeaker.org" :options="chapterOptions" class="mb-3 form-box-input" value-field="id" text-field="name" required />
          </div>
          <Entry v-if="chapterSpeaker.org>0" :chapter_id="chapterSpeaker.org" :speakerObject="chapterSpeaker"></Entry>
          <div slot="modal-footer" class="submit-box-2">
        </div>
      </b-modal>
    <Footer :useruid="this.$route.params.useruid"></Footer>

  </div>
</template>
<style scoped lang="scss">
.status-image{
  border: none;
  width: 16px;
  height: 16px;
  border: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: rgba(0,0,0,0);
  padding: 0px;
  margin:auto;
}
.thumbs-up{
  background-image: url("../assets/icon-Planner-Confirmed-Green.png");
}
.thumbs-down{
  background-image: url("../assets/icon-Planner-Declined-Red.png");
}
.pinned-image{
  background-image: url("../assets/icon-Planner-Planning.png");
}
.question-mark{
  background-image: url("../assets/icon-Planner-Confirming.png");
}
  .new-speaker{
    border-color: #00aeef !important;
    color: #00aeef;
  }
  #sectionDownButton {
    background-image: url("../assets/Icon-Dn-dim.png");
    border: none;
    width: 16px;
    height: 14px;
    border: none;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-color: rgba(0,0,0,0);
    padding: 0px;
    grid-column: 2;
    grid-row: 2;
  }
    #sectionDownButton:hover {
      filter: $VUE_APP_COLOUR_MAIN_FILTER;
    }
    #sectionUpButton {
      background-image: url("../assets/Icon-Up-dim.png");
      border: none;
      width: 16px;
      height: 14px;
      border: none;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-color: rgba(0,0,0,0);
      padding: 0px;
    }
      #sectionUpButton:hover {
        filter: $VUE_APP_COLOUR_MAIN_FILTER;
      }

      #sectionUpButton.active {
        border: none;
      }

    #sectionDownButton.active {
      border: none;
    }

    #newSpeakerButton {
      background-image: url("../assets/button-New.png");
      border: none;
      width: 25px;
      height: 22px;
      border: none;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-color: transparent !important;
      padding: 0px;
      margin:auto;
      grid-row:1/3;
      filter: $VUE_APP_COLOUR_MAIN_FILTER;
    }
    #deleteSpeakersButton {
      background-image: url("../assets/icon-delete.png");
      border: none;
      width: 14px;
      height: 12px;
      border: none;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-color: rgba(0,0,0,0);
      padding: 0px;
    }
.block-controls{
  display: none;
  width:23px;grid-template-columns: 14px 14px;
  grid-gap:6px;
  grid-column-gap: 20px;
}
.date-bucket:hover .block-controls{
  display:grid;
}
.date-bucket{
  height:57px;
  border: 1px solid black;
    display: grid;
    grid-template-columns: 50px 70px auto;
    grid-gap: 20px;
    padding: 10px;
    cursor: pointer;
    
}
.speaker-bucket{
      border: 1px dashed black;
    padding: 5px;
    //padding-right:25px;
    position: relative;
    margin: 0 5px;
    
}
.planner-bucket{
  display: grid;
    grid-template-columns: repeat(3,auto);
    grid-gap:5px;
}
.trash-speaker {
    background-image: url("../assets/cross-red.png");
    border: none;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 12px;
    height: 12px;
    display: block;
    right: 3px;
    top: 7px;
    text-align: center;
    background-color: transparent !important;
    margin:auto;
  }
  .tabClass{
    text-align: left;
    color: #495057 !important;
        color: #495057 !important;
        border: 1px solid grey !important;
    border-radius: 0px !important;
}
.tabClass > span{
    margin-left: 5px;
}
    .tabClassActive{
        background-color: #e0f5fd !important;
        text-align: left;
    color: #495057 !important;
        border: 1px solid grey !important;
    border-radius: 0px !important;
    }
    .tabClassActive > img{
        filter: $VUE_APP_COLOUR_MAIN_FILTER;
    }
    .tabClassActive > span{
        font-weight: bold;
        margin-left: 5px;
    }
    .old-speaker-break > td{
      border-bottom: 2px #01aeef solid;
    }
</style>
<script>
  import router from "../router"
  import draggable from "vuedraggable";
  import Entry from './Entry.vue';
import Dashboard from './Dashboard.vue';
    export default {
    name: "Schedule",
    components: {
      draggable,
      Entry,
        Dashboard
    },
    props:{
        chapter_id: {type: Number, default: 0},
        speakerObject: {type: Object, default: {id: -1}}
      },
    watch: {
      'chapterSpeaker.id': {
        handler: function (v) {
          if(v == -1){
            this.showEntry = false;
            this.getSchedule();
          }
        },
        deep: true
      },
      'permissions.showSpeakerGrid': {
        handler: function () {
          this.temp_speakers= new Map();
          for(var y=0; y<this.meetingSpeakers.length;y++){
            this.meetingSpeakers[y].speakers = this.meetingSpeakers[y].speakers.filter(v => v.id>0);
          }
        },
        deep: true
      },
    },
    data() {
      return {
        membersOrRegional: 0,
        enable_planner_confirmation: 0,
        speakerStatusOptions: [{value:1, text: this.$t('schedule.planned_text')},{value:0, text: this.$t('schedule.confirmed_text')},{value:2, text: this.$t('schedule.request_confirmation')}],
        statusColor: ['#30b447', '#00aeef','#00aeef','#ee2628'],
        showAssignSpeakerModal: false,
        enabled_speaker_planner: 0,
        showSaveUnassigned: false,
        unassigned_dragged_id: 0,
        unAssigned: [],
        unAssignedContacts: [],
        showEntry: false,
        chapterOptions: [],
        chapterSpeaker: {id: -1,org: 0},
        showDelete: false,
        showDeleteAll: false,
        schedule: [],
        next_meeting: '',
        speakersNotEmailed: false,
        useruid: '',
        syncSpeakers: [],
        showSyncSpeakers: false,
        previewFields: [
          { key: 'day',
              label: this.$t('schedule.day_header'),
              sortable: false,
              thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff"},
            },
            { key: 'name',
              label: this.$t('schedule.name_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
             { key: 'import',
              label: this.$t('events.import_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff", 'text-align': 'center'},
            }
          ],
        meetingSpeakers: [],
        speakerToDelete: null,
        dateToDelete: null,
        meeting_days: [],
        temp_speakers: new Map(),
        callbackTab: null,
        assignSpeaker: null,
        meet_start: '',
        expiry_options: [{value: 0, text: this.$t('schedule.expiry_option0')},{value: 1, text: this.$t('schedule.expiry_option1')},{value: 2, text: this.$t('schedule.expiry_option2')},{value: 3, text: this.$t('schedule.expiry_option3')}],
        removedFrom: null
      }
    },
    methods: {
      tabActivated(newTabIndex,oldTabIndex, event){
        /*if(oldTabIndex ==2 && !this.showSaveUnassigned){
          
          var newSpeakers = [];
          for(var y=0; y<this.meetingSpeakers.length;y++){
            for(var z=0; z<this.meetingSpeakers[y].speakers.length; z++){
              if(this.meetingSpeakers[y].speakers[z].id==undefined && this.meetingSpeakers[y].speakers[z].member_id>0){
                newSpeakers.push(this.meetingSpeakers[y].speakers[z]);
              }
            }
          }
          if(newSpeakers.length>0){
            this.callbackTab =  () => {this.permissions.showSpeakerGrid = newTabIndex};
            this.showSaveUnassigned = true;
              event.preventDefault();
              return;
          }
        }*/
        if(newTabIndex==2){
          this.getUnAssigned();
        }
        this.temp_speakers = new Map();
      },
      leaveUnassigned(){
        this.callbackTab();
        this.callbackTab = null;
        this.showSaveUnassigned = false;
      },
      checkUnsaved(){
        if(!this.showSaveUnassigned){
          var newSpeakers = [];
          for(var y=0; y<this.meetingSpeakers.length;y++){
            for(var z=0; z<this.meetingSpeakers[y].speakers.length; z++){
              if(this.meetingSpeakers[y].speakers[z].id==undefined && this.meetingSpeakers[y].speakers[z].member_id>0){
                newSpeakers.push(this.meetingSpeakers[y].speakers[z]);
              }
            }
          }
          return newSpeakers.length>0;
        }
        return false;
      },
      shiftOffset(n){
        for(var y=0; y<this.meetingSpeakers.length;y++){
          this.temp_speakers.set(this.meetingSpeakers[y].dc,this.meetingSpeakers[y].speakers);
        }
        if(this.permissions.speakerGridOffset+n>=0){
          this.permissions.speakerGridOffset = this.permissions.speakerGridOffset+n;
          this.makeSpeakerGrid();
        }
      },
      linkClass(idx) {
        if (this.permissions.showSpeakerGrid === idx) {
          return ['tabClassActive']
        } else {
          return ['bg-light','tabClass']
        }
      },
      previousSpeakersChange() {
       // this.permissions.previousSpeakers = !this.permissions.previousSpeakers;
        this.getSchedule();
      },
      /*confirmImport(){
        this.$http.post("/api/organization/schedule/updatesync"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""), {org: this.chapter_id,syncSpeakers : this.syncSpeakers.filter(v => v.import == 1), useruid: this.useruid}).then((response) => {
          if (response.data.success) {
            this.getSchedule();
            this.showSyncSpeakers = false;
          }
        }).catch(() => {});
      },
      getSyncSpeakers(){
        this.$http.post("/api/organization/schedule/getsync"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""), {org: this.chapter_id, useruid: this.useruid}).then((response) => {
          if (response.data.success) {
            this.syncSpeakers = response.data.syncSpeakers;
            if(this.syncSpeakers.length>0){
              this.showSyncSpeakers = true;
            }
          }
        }).catch(() => {});
      },*/
      makeSpeakerGrid(){
        this.meetingSpeakers = [];
        if(this.meeting_days.length>0){
          for(var i=Math.max(this.permissions.speakerGridOffset,0); i<Math.min(this.permissions.speakerGridOffset+10, this.meeting_days.length); i++){
            
            var dt = this.meeting_days[i].day;
            var d = new Date(dt.split("-").join("/") + " 00:01");
            var dc = "";
            if(dt){
              var g = dt.split("-");
              if(g.length>2){
                dc = g[2]+"-"+g[1]+"-"+g[0];
              }
            }
            this.meetingSpeakers.push({id: i,date: this.$d(d, 'day_month_short'), dc: dc, dt: dt, speakers: [], time: this.meeting_days[i].time});
            
          }
        }else{
            for(var i=this.permissions.speakerGridOffset; i<this.permissions.speakerGridOffset+10; i++){
            var d = new Date(this.next_meeting.split("-").join("/") + " 00:01");
            d.setDate(d.getDate()+7*i);
            var dt = d.toISOString().substr(0,10);
            var dc = "";
            if(dt){
              var g = dt.split("-");
              if(g.length>2){
                dc = g[2]+"-"+g[1]+"-"+g[0];
              }
            }
            this.meetingSpeakers.push({id: i,date: this.$d(d, 'day_month_short'), dc: dc, dt: dt, speakers: [], time: this.meet_start});
            
          }
        }
        
        
        for(var x=0;x<this.schedule.length;x++){
          for(var y=0; y<this.meetingSpeakers.length;y++){
            if(this.schedule[x].day == this.meetingSpeakers[y].dc){
              this.schedule[x].dateId = this.meetingSpeakers[y].id;
              this.meetingSpeakers[y].speakers.push(this.schedule[x]);
              break;
            }
          }
        }
        /*for(var y=0; y<this.meetingSpeakers.length;y++){
          if(this.temp_speakers.has(this.meetingSpeakers[y].dc)){
            this.meetingSpeakers[y].speakers = this.temp_speakers.get(this.meetingSpeakers[y].dc);
          }
        }*/
      },
      getMeetDay() {
        this.$http.post("/api/organization/get/meet_day_of_week"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""), {org: this.chapter_id,useruid: this.useruid}).then((response) => {
          if (response.data.success === true) {
            this.meeting_days = response.data.meeting_daytimes;
            this.meet_start = response.data.meet_start;
          }
        }).catch(() => { });
      },
      getSchedule() {
        this.$http.post("/api/organization/schedule"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""), {org: this.chapter_id, previousSpeakers: this.permissions.previousSpeakers , useruid: this.useruid}).then((response) => {
          if (response.data.success) {
            this.enable_planner_confirmation = response.data.enable_planner_confirmation;
            if(this.enable_planner_confirmation==0){
              this.speakerStatusOptions= [{value:1, text: this.$t('schedule.planned_text')},{value:0, text: this.$t('schedule.confirmed_text')}];
            }else{
              this.speakerStatusOptions= [{value:1, text: this.$t('schedule.planned_text')},{value:0, text: this.$t('schedule.confirmed_text')},{value:2, text: this.$t('schedule.request_confirmation')}];
            }
            this.schedule = response.data.schedule;
            this.speakersNotEmailed = (response.data.speakersEmailed==0);
            this.next_meeting = response.data.next_meeting;
            this.enabled_speaker_planner = response.data.enabled_speaker_planner;
            this.makeSpeakerGrid();
          }
        }).catch((err) => {console.log(err);});
      },
      newEntryDate(d){
        if (this.permissions.access) {
          router.push("/admin/schedule/"+d);
        }else if(this.useruid.length>0){
          router.push("/schedule/"+d+"/"+this.useruid);
        }
      },
      newEntry() {
        if (this.permissions.access) {
          if(this.chapter_id>0){
              this.speakerObject.id = 0;
          }else if(this.permissions.organization.is_parent){
            this.chapterSpeaker.id = 0;
            this.chapterSpeaker.org = 0;
            this.showEntry = true;
          }else{
            router.push("/admin/schedule/0");
          }
        }else if(this.useruid.length>0){
          router.push("/schedule/0/"+this.useruid);
        }
      },
      editEntry(item) {
        if (this.permissions.access) {
          if(this.chapter_id>0){
              this.speakerObject.id = item.id;
          } else if(this.permissions.organization.is_parent){
            this.chapterSpeaker.id = item.id;
            this.chapterSpeaker.org = item.org;
            this.showEntry = true;
          }else{
            router.push("/admin/schedule/" + item.id);
          }
        }else if(this.useruid.length>0){
          router.push("/schedule/" + item.id+"/"+this.useruid);
        }
      },
      gridDrag(event){
        var idstring = event.clone.id.split("_");
        if(idstring.length>1){
          var id = parseInt(idstring[1]);
          for(var x=0; x<this.meetingSpeakers.length;x++){
            for(var y=0; y<this.meetingSpeakers[x].speakers.length; y++){
              if(this.meetingSpeakers[x].speakers[y].id == id){
                var dateId = this.meetingSpeakers[x].id;
                this.$http.post("/api/organization/speaker/date/update"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""),
                 {org: this.chapter_id,id: this.meetingSpeakers[x].speakers[y].id, day: this.meetingSpeakers[x].dt, useruid: this.useruid }).then(() => {
                   this.recalculateDateRanks(dateId);
                }).catch((err) => {console.log(err);});
                break;
              }
            }
          } 
        }
      },
      resendConfirmation(){
        this.$http.post("/api/organization/speaker/confirmation/resend"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""),{id: this.assignSpeaker.id}).then((response) => {
            if(response.data.success && response.data.emailed){
               this.$bvToast.toast(this.$t('schedule.email_sent'), {
                variant: 'success',
                solid: true
                });
            }
        }).catch((err) => {console.log(err);});
      },
      unAssignedChange(date, event){
        if(event.added && event.added.element){
          this.assignSpeaker = {id: event.added.element.id? event.added.element.id : 0, rank: event.added.newIndex, name: event.added.element.name, member_id: event.added.element.member_id, dateId: date.id, date: date.dt, day: date.dc, status: 1, sendEmail: 0, confirmation_expiry: 0, originalStatus: 1, time: date.time}
          this.showAssignSpeakerModal = true;
        }
        if(event.moved){
          this.recalculateDateRanks(date.id);
        }
        if(event.removed){
          this.removedFrom = {dateId: date.id, index: event.removed.oldIndex, element: event.removed.element}
        }
      },
      removePlanned(){
        this.assignSpeaker.status = this.assignSpeaker.originalStatus
        if(!this.assignSpeaker.id>0){
          for(var y=0; y<this.meetingSpeakers.length;y++){
            this.meetingSpeakers[y].speakers = this.meetingSpeakers[y].speakers.filter(v => v.id>0);
          }
          this.getUnAssigned();
        }else if(this.assignSpeaker.id>0 && this.removedFrom){
          this.meetingSpeakers[this.assignSpeaker.dateId].speakers = this.meetingSpeakers[this.assignSpeaker.dateId].speakers.filter(v => v.id != this.assignSpeaker.id); 
          this.meetingSpeakers[this.removedFrom.dateId].speakers.splice(this.removedFrom.index, 0, this.removedFrom.element);
          this.removedFrom = null;
        }
        this.showAssignSpeakerModal = false;
      },
      unassignedDragEnd(event){
        this.unassigned_dragged_id=0;
      },
      unassignedDragStart(event){
        var idstring = event.clone.id.split("_");
        if(idstring.length>1){
          this.unassigned_dragged_id = parseInt(idstring[1]);
        }
      },
      editPlanner(speaker, date){
        this.assignSpeaker = speaker;
        this.$set(this.assignSpeaker, 'originalStatus',this.assignSpeaker.status);
        this.$set(this.assignSpeaker, 'time',date.time);
        this.assignSpeaker.dateId = date.id;
        this.$set(this.assignSpeaker, 'sendEmail' ,0);
        this.showAssignSpeakerModal = true;
      },
      updatePlanner(){
        if(this.assignSpeaker){
          if(this.assignSpeaker.date){
            this.assignSpeaker.day = this.assignSpeaker.date;
          }
          if(this.assignSpeaker.day[2]=="-"){
              var g = this.assignSpeaker.day.split("-");
              if(g.length>2){
                this.assignSpeaker.day = g[2]+"-"+g[1]+"-"+g[0];
              }
            }
          this.assignSpeaker.org = this.chapter_id;
          this.assignSpeaker.useruid = this.useruid;
          if(this.meetingSpeakers[this.assignSpeaker.dateId].speakers.indexOf(this.assignSpeaker)>-1){
            this.assignSpeaker.rank = this.meetingSpeakers[this.assignSpeaker.dateId].speakers.indexOf(this.assignSpeaker);
          } 
          
          this.$http.post("/api/organization/planner/update"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""),this.assignSpeaker).then((response) => {
            if(response.data.success){
              if(response.data.emailed){
               this.$bvToast.toast(this.$t('schedule.email_sent'), {
                variant: 'success',
                solid: true
                });
            }
              this.meetingSpeakers[this.assignSpeaker.dateId].speakers[this.assignSpeaker.rank].id = response.data.id;
              this.recalculateDateRanks(this.assignSpeaker.dateId);
              this.showAssignSpeakerModal = false;
            }
          }).catch((e) => {console.log(e)});
        }else{
          this.showAssignSpeakerModal = false;
        }
      },
      saveUnassigned(){
        var newSpeakers = [];
        for(var y=0; y<this.meetingSpeakers.length;y++){
          for(var z=0; z<this.meetingSpeakers[y].speakers.length; z++){
            if(this.meetingSpeakers[y].speakers[z].id==undefined && this.meetingSpeakers[y].speakers[z].member_id>0){
              this.meetingSpeakers[y].speakers[z].day = this.meetingSpeakers[y].dt;
              this.meetingSpeakers[y].speakers[z].rank = z;
              newSpeakers.push(this.meetingSpeakers[y].speakers[z]);
            }
          }
        }

         this.$http.post("/api/organization/speakers/add/members"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""),{org: this.chapter_id,speakers: newSpeakers, useruid: this.useruid }).then((response) => {
          if(response.data.success){
            this.temp_speakers = new Map();
            this.getMeetDay();
            this.getSchedule();
            this.getUnAssigned();
            this.showSaveUnassigned = false;
            this.$bvToast.toast(this.$t('schedule.speakers_updated'), {
              variant: 'success',
              solid: true
            });
            if(this.callbackTab){
              this.callbackTab();
            }
          }
          }).catch((e) => {console.log(e)});
      },
      moveSpeakersDown(dateId){
        if(dateId+1<this.meetingSpeakers.length){
          for(var i=0; i<this.meetingSpeakers[dateId].speakers.length;i++){
            this.meetingSpeakers[dateId+1].speakers.push(this.meetingSpeakers[dateId].speakers[i]);
            this.$http.post("/api/organization/speaker/date/update"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""),
                 {org: this.chapter_id,id: this.meetingSpeakers[dateId].speakers[i].id, day: this.meetingSpeakers[dateId+1].dt, useruid: this.useruid }).then(() => {
                }).catch(() => {});
          }
          this.meetingSpeakers[dateId].speakers = [];
          this.recalculateDateRanks(dateId+1);
        }
      },
      moveSpeakersUp(dateId){
        if(dateId-1>=0){
          for(var i=0; i<this.meetingSpeakers[dateId].speakers.length;i++){
            this.meetingSpeakers[dateId-1].speakers.push(this.meetingSpeakers[dateId].speakers[i]);
            this.$http.post("/api/organization/speaker/date/update"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""),
                 {org: this.chapter_id,id: this.meetingSpeakers[dateId].speakers[i].id, day: this.meetingSpeakers[dateId-1].dt, useruid: this.useruid }).then(() => {
                }).catch(() => {});
          }
          this.meetingSpeakers[dateId].speakers = [];
          this.recalculateDateRanks(dateId-1);
        }
      },
      deleteDate(){
        if(this.dateToDelete!=null && this.dateToDelete>=0 && this.dateToDelete<this.meetingSpeakers.length){
          var speakers = this.meetingSpeakers[this.dateToDelete].speakers;
          for(var i=0; i<speakers.length;i++){
            this.deleteEntry(speakers[i]);
          }
        }
        this.dateToDelete = null;
      },
      deleteSpeaker(){
        if(this.speakerToDelete != null){
          this.deleteEntry(this.speakerToDelete)
          this.getUnAssigned();
        }
        this.speakerToDelete = null;
      },
      deleteEntry(entry) {
        entry.useruid = this.useruid;
        entry.org = this.chapter_id;
        this.$http.post("/api/organization/schedule/delete"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""), entry).then((response) => {
          if (response.data.success === true) {
            this.getSchedule();
          }
          this.showDelete = false;
          this.showDeleteAll = false;
        }).catch(() => {});
      },
      recalculateDateRanks(dateId){
        var ranks = [];
        for (var i = 0; i < this.meetingSpeakers[dateId].speakers.length; i++) {
          ranks.push({ id: this.meetingSpeakers[dateId].speakers[i].id, rank: i });
        }

        this.$http.post("/api/organization/schedule/rank"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""), { org: this.chapter_id,ranks: ranks , useruid: this.useruid}).then(() => {
          this.getSchedule();
        }).catch(() => {});
      },
      endDrag() {
        var ranks = [];
        for (var i = 0; i < this.schedule.length; i++) {
          ranks.push({ id: this.schedule[i].id, rank: i });
        }

        this.$http.post("/api/organization/schedule/rank"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""), {org: this.chapter_id, ranks: ranks , useruid: this.useruid}).then(() => {
          this.getSchedule();
        }).catch(() => {});
      },
      getChapters(){
        this.$http.post("/api/organization/get/children/select", {}).then((response) => {
          if (response.data.success) {
            this.chapterOptions = response.data.children;
            this.chapterOptions.unshift({id: 0, name: this.$t('region.select_chapter')});
          }
        }).catch(() => {
        });
      },
      getUnAssigned(){
        this.$http.post("/api/organization/speakers/unassigned/get"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""), {org: this.chapter_id, useruid: this.useruid}).then((response) => {
          if (response.data.success) {
            this.unAssigned = response.data.unassigned.filter(v=> v.is_contact == 0);
            this.unAssignedContacts = response.data.unassigned.filter(v=> v.is_contact == 1);
          }
        }).catch(() => {
        });
      }
    },
     beforeRouteLeave (to, from, next) { 
       if(!this.isCorporateConnections && this.permissions.showSpeakerGrid ==2 && !this.showSaveUnassigned){
          var newSpeakers = [];
          for(var y=0; y<this.meetingSpeakers.length;y++){
            for(var z=0; z<this.meetingSpeakers[y].speakers.length; z++){
              if(this.meetingSpeakers[y].speakers[z].id==undefined && this.meetingSpeakers[y].speakers[z].member_id>0){
                newSpeakers.push(this.meetingSpeakers[y].speakers[z]);
              }
            }
          }
          if(newSpeakers.length>0){
            this.callbackTab = () => {next()};
            this.showSaveUnassigned = true;
            next(false);
            return;
          }else{
            next();
          }
      }else{
        next();
      }
     },
    destroyed() {
      this.removeClass(document.getElementById("menu-speakers"), "active-menu");
      this.removeClass(document.getElementById("menu-meeting"), "active-menu");
    },
    mounted() {
      if(this.$route.params.useruid){
        this.useruid = this.$route.params.useruid;
      }
      this.addClass(document.getElementById("menu-speakers"), "active-menu");
      this.addClass(document.getElementById("menu-meeting"), "active-menu");
      this.getMeetDay();
      this.getSchedule();
      this.getChapters();
      this.getUnAssigned();
      //this.getSyncSpeakers();
    }
    }
</script>
