<template>
  <div>
    <Header :useruid="this.$route.params.useruid" :title="$t('events.manage_events')" :showDate="true"></Header>
    <div class="rel-grid-container">
      <div id="top-div"></div>
      <div >
        <Tip :tipNumber="18"></Tip>
        <div v-if="!permissions.organization.is_parent" style="font-size: 26px;text-align: center;margin: 20px;">{{$t('events.chapter_events')}}</div>
        <table class="table b-table table-hover-new table-bordered table-sm">
          <thead>
            <tr>
              <th class="tableHeader">{{$t('events.when_header')}}</th>
              <th class="tableHeader">{{$t('events.title_header')}}</th>
              <th class="tableHeader">{{$t('events.submitted_header')}}</th>
              <th class="tableHeader">{{$t('events.delete_header')}}</th>
              <th class="tableHeader">{{permissions.organization.is_parent? $t('events.show_header') :$t('events.showslide_header')}}</th>
              <th v-if="!permissions.organization.is_parent" class="tableHeader">{{$t('events.shownewspaper_header')}}</th>
            </tr>
          </thead>
          <draggable tag="tbody" v-model="events"  @end="endDrag">
            <tr v-bind:class="{disabledClass : event.disabled==1 && event.newspaper_disabled==1, dragndrop: true}"  v-for="event in events" :key="event.id" >
              
              <td v-on:click="editEvent(event)" style="cursor: pointer;">{{ event.when_description }}</td>
              <td v-on:click="editEvent(event)" style="cursor: pointer;">{{ event.title }}</td>
              <td v-on:click="editEvent(event)" style="cursor: pointer;">{{ event.name }}</td>
              <td style="text-align:center;">
                <b-form-checkbox v-model="event.delete" :value="true" :unchecked-value="false" >
                </b-form-checkbox>
              </td>
              <td style="text-align:center;">
                <b-form-checkbox v-model="event.disabled" v-on:change="updateDisabled(event)" value="0" unchecked-value="1" >
                </b-form-checkbox>
              </td>
              <td style="text-align:center;" v-if="!permissions.organization.is_parent">
                <b-form-checkbox v-model="event.newspaper_disabled" v-on:change="updateNewspaperDisabled(event)" value="0" unchecked-value="1" >
                </b-form-checkbox>
              </td>
            </tr>
          </draggable>
        </table>
        <div id="drag-note">{{$t('categories.drag_order')}}</div>

       
        <div v-if="showAlert">
        <b-alert variant="danger"
                 dismissible
                 :show="showAlert"
                 @dismissed="showAlert=false;">
          {{alertText}}
        </b-alert>
      </div>
      <div v-if="showSuccess">
        <b-alert variant="success"
                 dismissible
                 :show="showSuccess"
                 @dismissed="showSuccess=false;">
          {{$t('import.successful_import')}}
        </b-alert>
      </div>
     
        <div class="submit-box-2">
          <b-button v-if="events.filter(v =>v.delete).length>0" class="submit-button4" v-on:click="showDelete=true" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          <b-button class="submit-button4" v-on:click="showImport=true" variant="clear-blue"><div class="import-button"></div><span>{{$t('global.button_import')}}</span></b-button>
          <b-button v-if="permissions.access || useruid.length>0" class="submit-button4" v-on:click="newEvent" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
        </div>
        
        <br/>
        <br/>
        <div v-if="!this.permissions.organization.is_parent" style="font-size: 26px;text-align: center;margin: 20px;">{{$t('events.region_events')}}</div>
        <b-table v-if="!this.permissions.organization.is_parent" class="table-hover-new" style="cursor: pointer;" small bordered :items="parentEvents" :fields="parentFields" v-on:row-clicked="editEvent">
          
        </b-table>
      </div>
       <b-modal centered  v-model="showImport" :title="$t('events.import_title')">
         <div>
           <div style="max-width: 600px;margin: auto;">
            <span class="form-box-title">{{$t('events.import_options')}}</span>
            <div class="form-box" style="grid-template-columns: 50% 50%;">
              <b-button variant="blue" v-on:click="openDialog" style="width:200px;">{{$t('import.select_file')}}</b-button>
              <b-form-file ref="file-input"  id='fileid' accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="onFileChange" plain hidden></b-form-file>
              <div style="margin: auto;">
               
              </div>
              <div style="grid-column: 1/3; margin-top: 20px;font-size:12px;">
                *{{$t('events.import_note')}}
              </div>
            </div>
          </div>
           <div v-if="showUpload">
            <b-alert style="line-height:30px;" variant="warning" :show="showUpload">
              {{$t('import.importing_message')}}
              <b-spinner style="float:right;" label="Uploading" variant="warning"></b-spinner>
            </b-alert>
          </div>
         </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="submit-button4" v-on:click="showImport=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
        </div>
        </b-modal>
       <b-modal centered id="slide-modal-4" v-model="showPreviewImport" :title="$t('import.import_title')">
        <div id="preview-members">
          <b-table class="table-hover-new" small bordered :items="previewEvents" :fields="previewFields">
            <template v-slot:cell(member_name)="data">
              <div :style="{'color': data.item.member_color}">{{data.item.member_name}}</div>
                <b-form-select v-if="data.item.member_name.length>0" v-model="data.item.submitted_by" :options="members" value-field="id" text-field="name" class="form-box-input" style="max-width: 300px;" />
            </template>
            <template v-slot:cell(import)="data">
              <b-form-checkbox v-model="data.item.import" value="1" unchecked-value="0" style="margin:auto;">
            </b-form-checkbox>
            </template>
          </b-table>
        </div>
        <div>
          *{{$t('events.preview_note')}}
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="submit-button4" v-on:click="cancelImport" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="submit-button4" v-on:click="importChanges" variant="clear-blue"><div class="import-button"></div><span>{{$t('global.button_import')}}</span></b-button>
        </div>
      </b-modal>
      <b-modal id="modal-center" v-model="showDelete" centered :title="$t('event.delete_title')">
          <div >
            <div>{{$t('events.delete_text')}}</div>
            <div>{{ events.filter(v =>v.delete).map(v => v.title).join(", ") }}</div>
          </div>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="submit-button4" v-on:click="showDelete=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="submit-button4" v-on:click="deleteSelectedEvents" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          </div>
        </b-modal>
      <div class="div-space"></div>
    </div>
    <Footer :useruid="this.$route.params.useruid"></Footer>
  </div>
</template>
<style scoped>
</style>
<script>
  import router from "../router"
  import draggable from "vuedraggable";
    export default {
    name: "Events",
    components: {
      draggable
    },
    watch:{
        '$i18n.locale': {
          handler: function () {
            

            this.parentFields[0].label = this.$t('events.when_header');
            this.parentFields[1].label = this.$t('events.title_header');
            this.parentFields[2].label = this.$t('events.submitted_header');
          },
          deep: true
        }
      },
    data() {
      return {
        showDelete: false,
        showImport: false,
        showImportEvents : false,
          showUpload : false,
          showAlert : false,
          showSuccess: false,
          alertText : "",
          saveVariant : "",
          showPreviewImport: false,
          previewEvents: [],
          members: [],
        events: [],
        parentEvents: [],
        useruid: '',
        previewFields: [
          { key: 'title',
              label: this.$t('events.title_header'),
              sortable: false,
              thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff"},
            },
            { key: 'when_description',
              label: this.$t('events.when_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
            { key: 'description',
              label: this.$t('events.description_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
             { key: 'link',
              label: this.$t('events.link_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
             { key: 'member_name',
              label: this.$t('events.submitted_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"},
            },
             { key: 'import',
              label: this.$t('events.import_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff", 'text-align': 'center'},
            }
          ],
         fields: [
            { key: 'when_description',
              label: this.$t('events.when_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
             { key: 'title',
              label: this.$t('events.title_header'),
              sortable: false,
              thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff"},
            },
             { key: 'name',
              label: this.$t('events.submitted_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"},
            },
             { key: 'disabled',
              label: this.$t('events.show_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" ,"text-align": "center", "width":"100px"},
              tdClass: "actions-centre"
            }
          ],
          parentFields: [
            { key: 'when_description',
              label: this.$t('events.when_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
             { key: 'title',
              label: this.$t('events.title_header'),
              sortable: false,
              thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff"},
            }
          ]
          
      }
    },
    methods: {
      deleteSelectedEvents(){
        var dv = this.events.filter(v => v.delete);
        if(dv.length>0){
          this.$http.post("/api/organization/events/delete/all"+(this.useruid==''? "" : "/byuseruid"), { useruid: this.useruid, ids: dv.map(v => v.id)}).then((response) => {
            if (response.data.success === true) {
              this.events = this.events.filter(v => !v.delete);
              this.showDelete = false;
            }
          }).catch(() => { });
        }

      },
      onFileChange(e) {
         var files = e.target.files || e.dataTransfer.files;
        if (!files.length || !this.checkFileSize(files[0]))
          return;
        this.file = files[0];
        this.getMembers();
        this.importEvents();
      },
      importEvents() {
        this.showImportEvents = false;
        this.showUpload = true;
        this.showAlert = false;
        this.alertText = this.$t('import.alert_text');
        this.saveVariant = "";
        this.$refs['file-input'].reset();
        let formData = new FormData();
        formData.append("file", this.file);
        this.$http.post("/api/organization/events/import/excel", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }).then((response) => {
          this.showImport = false;
          this.showUpload = false;
          this.saveVariant = "blue";
          if (response.data.success == true) {
            this.previewEvents= response.data.events;
            this.showPreviewImport = true;
          } else {
            this.showAlert = true;
            this.alertText = response.data.error;
          }
        }).catch((errors) => {
          this.showImport = false;
          this.showUpload = false;
          this.saveVariant = "blue";
          this.showAlert = true;
          this.alertText = JSON.stringify(errors);
        });
      },
      cancelImport() {
        this.previewEvents = [];
        this.showPreviewImport = false;
        this.file = { name: "" };
      },
      importChanges() {
          var vs = this.previewEvents.filter(g => g.import == 1);
          if(vs.length>0){
            this.$http.post("/api/organization/events/import/all", { events: this.previewEvents}).then((response) => {
            if (response.data.success === true) {
              this.showPreviewImport = false;
              this.getEvents();
            }
          }).catch(() => { });
        }else{
          this.showPreviewImport = false;
        }
          
      },
      openDialog() {
        document.getElementById('fileid').click();
      },
       rowClass(item) {
            if (!item) return
            if (item.disabled==1) return 'disabledClass'
          },
      getEvents() {
        this.$http.post("/api/organization/events"+(this.useruid==''? "" : "/byuseruid"), { useruid: this.useruid}).then((response) => {
          if (response.data.success) {
            for(const event of response.data.events){
              event.delete = false;
            }
            this.events = response.data.events;
          }
        }).catch(() => {});
      },
      getParentEvents() {
        this.$http.post("/api/organization/parent/events"+(this.useruid==''? "" : "/byuseruid"), { useruid: this.useruid}).then((response) => {
          if (response.data.success) {
            this.parentEvents = response.data.events;
          }
        }).catch(() => {});
      },
      newEvent() {
        if (this.permissions.access || this.useruid.length>0) {
          if(this.useruid==''){
            router.push("/admin/events/0");
          }else{
            router.push("/events/0/"+this.useruid);
          }
          
        }
      },
      editEvent(item) {
        if (this.permissions.access || this.useruid.length>0) {
           if(this.useruid==''){
            router.push("/admin/events/" + item.id);
           }else{
             router.push("/events/"+ item.id+"/"+this.useruid);
           }
        }
      },
      updateDisabled(e){
          this.$http.post("/api/organization/events/update/disabled"+(this.useruid==''? "" : "/byuseruid"), {id: e.id, disabled: (1-e.disabled), useruid: this.useruid}).then(() => {
        }).catch(() => {});
      },
      updateNewspaperDisabled(e){
        this.$http.post("/api/organization/events/update/newspaperdisabled"+(this.useruid==''? "" : "/byuseruid"), {id: e.id, disabled: (1-e.newspaper_disabled), useruid: this.useruid}).then(() => {
        }).catch(() => {});
      },
       getMembers() {
        this.$http.post("/api/organization/members/noauth", {org:this.permissions.organization.id, all: true}).then((response) => {
            this.members = response.data.members
        }).catch(() => { });
      },
      endDrag() {
        var ranks = [];
        for (var i = 0; i < this.events.length; i++) {
          ranks.push({ id: this.events[i].id, rank: i });
        }
        this.$http.post("/api/organization/events/rank"+(this.useruid==''? "" : "/byuseruid"), { ranks: ranks , useruid: this.useruid}).then(() => {}).catch(() => {});
      },
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-events"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      if(this.$route.params.useruid){
        this.useruid = this.$route.params.useruid;
      }
      this.addClass(document.getElementById("menu-events"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      this.getEvents();
      if(!this.permissions.organization.is_parent){
        this.getParentEvents();
      }
    }
  }
</script>
