<template>
  <div v-on:dragover="dragHandler" v-on:dragleave="dragLeaveHandler" v-on:dragenter="dragEnterHandler" style="height:100%;">
    <Header :useruid="this.$route.params.useruid"  :title="$t('preparer.manage_section')" ></Header>
    <div v-if="needsDate" style="text-align: center;font-size: 20px;margin-top:50px;">{{$t('global.need_date')}}</div>
    <div v-if="sectionReturned && sectionFound && !needsDate" id="preparer-box">
      <Tip :tipNumber="24"></Tip>
      <div>
        <form v-on:submit.prevent="uploadFiles" >
          <div style="background-color: #eeeeee;padding: 10px;">
            <div class="fit-width" id="preparer-section-description">
              <span class="form-box-name" style="margin: 0px 5px;">{{$t('preparer.section_name')}}:</span> <span style="font-weight:bold;">{{section.section_name}}</span>
            </div>
          </div>

          <div style="text-align:center;color: rgb(0, 174, 239);">{{$t('preparer.instructions1')}}</div>
          <div style="text-align:center;color: rgb(0, 174, 239);">{{$t('preparer.instructions2')}}</div>
          <div style="text-align:center;color: rgb(0, 174, 239);">{{$t('preparer.valid_files')}} <span style="text-decoration: underline;" class="clickable" v-on:click="showValidFileExts = true;">{{$t('preparer.see_full_list')}}</span></div>
          <div>
            <span class="form-box-title">{{$t('preparer.settings_box')}}</span>
            <div class="form-box" style="grid-template-columns: auto auto;">
              <span required  class="form-box-name">{{$t('preparer.topic_text')}}</span><b-form-input  v-model="section.topic" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')"  class="form-box-input"></b-form-input>
              <b-form-checkbox style="margin-left: 20px;margin-top: 5px;grid-column:2/3;" v-model="section.sharefile" unchecked-value="0" value="1">{{$t('preparer.share_files')}}</b-form-checkbox>
            </div>
          </div>
          
          <div>
            <span class="form-box-title">{{$t('preparer.files_links')}}</span>
            <div class="form-box" style="grid-template-columns: auto;position:relative;" v-on:drop="dropHandler"  @dragover.prevent  @dragenter.prevent @dragleave.prevent>
              <div v-show="showDrag" id="drop-overlay">
                <div></div>
                <div v-if="section.files.length<5">{{$t('preparer.drop_text')}}</div>
                <div v-if="section.files.length>=5">{{$t('preparer.max_files')}}</div>
                <div></div>
              </div>
              <div style="display:grid; grid-template-columns: auto max-content;text-align: center;margin-bottom: 20px;"><span v-html="$t('preparer.file_notes')"></span> <b-button v-if="getIsMac()" style="padding:0; width:70px;font-size:14px;" variant="link" v-on:click="showMacNote=true;">{{$t('entry.mac_note')}}</b-button></div>
              <div v-for="file in section.files" :key="file" style="display:grid; grid-template-columns: auto max-content;">
                <div style="text-align:center; grid-column:1/3;color: #003c80;font-size: 20px;">{{$t('preparer.file_text')+" #"+(section.files.indexOf(file)+1)}}</div>
                <b-button v-if="section.files.length>1 || (file.link != '' || file.file != null) " v-on:click="removeFileLink(file)" class="submit-button4" variant="clear-blue" style="margin:auto;grid-column:2/3;grid-row:2/5;"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
                <b-form-checkbox class="setting-check" v-model="file.type" :value="1" :unchecked-value="2" style="grid-column:1/2;">{{$t('preparer.power_point')}}</b-form-checkbox>
                <div style="display:grid; grid-template-columns: max-content auto;grid-column:1/2;" v-if="file.type==1">
                  <b-button  class="submit-button4" v-on:click="openFileDialog(file)" variant="blue" style="margin: auto 20px;">{{file.link.length>0 && file.file_path.length>0? $t('entry.reselect_presentation') : $t('preparer.upload_pp')}}</b-button>
                  <div v-if="file.filename && file.filename.length>0" id="preparer-filename" class="cut-filename">{{file.filename}}</div>
                  <div v-if="(!file.filename || file.filename.length==0)  && file.link.length>0 && file.file_path.length>0" style="text-align:center;line-height: 12px;margin:auto;">
                    <div class="cut-filename">{{file.file_path}}</div>
                    <div><b-button variant="link" v-on:click="viewFile(file.hash)" style="padding:0;border: none;font-weight: bold;">{{$t('entry.speaker_upload')}}</b-button></div>
                    <div style="font-size: 14px;"><a :href="'/uploads/'+file.file_path" download>{{$t('entry.download_original')}}</a></div>
                  </div>
                  <b-button style="grid-column:2;margin-top:20px;" class="submit-button4" v-if="(!file.filename || file.filename.length==0)  && file.link.length>0 && file.file_path.length>0" v-on:click="popupResendModal(file)" variant="clear-blue"><div class="email-button"></div><span>{{$t('preparer.resend_confirm')}}</span></b-button>
                </div>
                <b-form-file :id="'fileupload'+file.number" accept=".pdf, .doc, .docx, .pps, .ppt, .pptx, .ppsx, .xls, .xlsx, .vsd, .vsdx, .odp, .ods, .odt, .key, .bmp, .jpeg, .jpg, .png, .svg, .tif, .tiff, .webp" @change="onFileFileChange($event, file)" plain hidden></b-form-file> <!--  -->
                <b-form-checkbox class="setting-check" v-model="file.type" :value="2" :unchecked-value="1" style="grid-column:1/2;">{{$t('preparer.link_web')}}</b-form-checkbox>
                <div style="display:grid; grid-template-columns: max-content auto;grid-column:1/2;" v-if="file.type==2">
                  <b-form-input v-model="file.link" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input" style="grid-column:1/2;width:200px;grid-column: 1/2;margin: auto 20px;"></b-form-input>
                  <div v-if="file.link.length>0 && file.description.length>0" style="text-align:center;line-height: 12px;margin:auto;">
                    <div class="cut-filename" style="line-height:16px;">{{file.description}}</div>
                    <div><b-button variant="link" v-on:click="viewLink(file.link)" style="padding:0;border: none;font-weight: bold;">{{$t('entry.speaker_link')}}</b-button></div>
                  </div>
                </div>
                <hr style="width: 100%;border-color: grey;grid-column:1/3;" />
              </div>
              <b-button class="submit-button4" v-if="section.files.length<5 && !(section.files.length>0 && ((section.files[section.files.length-1].type == 1 && section.files[section.files.length-1].file == null && section.files[section.files.length-1].link == '') || (section.files[section.files.length-1].type == 2 && section.files[section.files.length-1].link == '')))" v-on:click="addFileLink" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
            </div>
            <b-modal centered v-model="showResendModal" :title="$t('preparer.resend_link')">
              <div style="margin-left:40px;">{{$t('preparer.resend_text1')}}</div><br/>
                <div style="display: grid; grid-template-columns: auto auto;margin-left:40px;">
                  <b-form-checkbox style="grid-column: 1/3;" v-model="resendType" value="0" unchecked-value="1">
                  {{$t('preparer.current_recipients')}}
                </b-form-checkbox>
                <b-form-checkbox style="grid-column: 1/3;" v-model="resendType" value="1" unchecked-value="0">
                  {{$t('preparer.alternate_email')}}
                </b-form-checkbox>
                  <span v-if="resendType==1" class="form-box-name">{{$t('preparer.recipient_text')}}</span>
                  <b-form-input v-if="resendType==1" v-model="resendEmail" type="text" pattern="[^<>]*" :state="validEmail" :title="$t('global.pattern_title')" @input="validateEmail"></b-form-input>
                </div><br/>
                <div style="text-align:center;" v-html="$t('preparer.resend_text2')"></div>
                <div slot="modal-footer" class="submit-box-2">
                  <b-button class="submit-button4" v-on:click="showResendModal=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
                  <b-button class="submit-button4" :disabled="resendType==1 && !validEmail" v-on:click="resendFileConfirm" variant="clear-blue"><div class="email-button"></div><span>{{$t('global.button_send')}}</span></b-button>
                </div>
            </b-modal>
          </div>

          <div v-if="showAlert">
            <b-alert variant="danger"
                     dismissible
                     :show="showAlert"
                     @dismissed="showAlert=false">
              {{alertText}}
            </b-alert>
          </div>
          <div v-if="showSuccess">
            <b-alert variant="success"
                     dismissible
                     :show="showSuccess"
                     @dismissed="showSuccess=false">
              {{$t('presentation.thanks_message_1')}}<br />
              {{$t('presentation.thanks_message_2')}}<br /><br />
              {{$t('presentation.thanks_message_3')}}<br /><br />
              {{$t('presentation.thanks_message_4')}}
            </b-alert>
          </div>
          <div v-if="showUpload">
            <b-alert style="line-height:30px;" variant="warning" :show="showUpload">
              {{$t('preparer.uploading_text')}}
              <b-spinner style="float:right;" label="Uploading" variant="warning"></b-spinner>
            </b-alert>
          </div>
          <div class="submit-box-2">
            <b-button v-if="!showUpload && !showSuccess" class="submit-button4" type="submit" :disabled="showUpload" variant="clear-blue"><div class="save-button"></div><span>{{section.files.filter(v=>v.type==1).length>0? $t('global.button_save_upload') : $t('global.button_save')}}</span></b-button>
          </div>
        </form>
      </div>
      <div class="div-space"></div>
    </div>
    <div v-if="sectionReturned && !sectionFound && !needsDate" style="height:100%;width:100%;display: grid; grid-template-rows: auto max-content auto;">
        <div></div>
        <div style="max-width: 600px;margin:auto;width: 100%;text-align:center;">
            <b-alert variant="warning" :show="!sectionFound" style="width: 100%;text-align:center;color:black;">
                {{$t('preparer.not_found1')}}<br/>
                {{$t('preparer.not_found2')}}
            </b-alert>
        </div>
        <div></div>
    </div>
    <b-modal id="modal-center" v-model="showMacNote" centered >
          <p class="my-4" v-html="$t('entry.mac_modal')"></p>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="submit-button4" v-on:click="showMacNote=false" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
          </div>
        </b-modal>
    <b-modal id="modal-center" v-model="showValidFileExts" centered >
      <p class="my-4" v-html="$t('preparer.upload_file_types')"></p>
      <table id="file-ext-table" style="border-collapse: collapse;border: lightgray 1px solid;text-align: left;">
        <tr><td>Microsoft Office</td><td>DOC DOCX PPS PPT PPTX PPSX XLS XLSX VSD VSDX</td></tr>
        <tr><td>OpenOffice</td><td>ODP ODS ODT</td></tr>
        <tr><td>Apple iWork</td><td>KEY NUMBERS PAGES</td></tr>
        <tr><td>Image</td><td>BMP JPEG JPG PNG PSD SVG TIF TIFF WEBP</td></tr>
      </table>
      <div slot="modal-footer" class="submit-box-2">
        <b-button class="submit-button4" v-on:click="showValidFileExts=false" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
      </div>
    </b-modal>
    <Footer :useruid="this.$route.params.useruid"></Footer>
  </div>
</template>
<style scoped>
#file-ext-table > tr > td{
  padding:5px;
  border: lightgray 1px solid;
}
#preparer-section-description{
  display: grid;grid-template-columns: max-content max-content;margin: auto;
}
#preparer-box{
  top: 40px;position: relative;margin: auto;width: 800px;
}
</style>
<script>
     import router from "../router"
    export default {
    name: "Preparer",
      data() {
        return {
          needsDate: false,
          showMacNote: false,
          presentVariant : "primary",
          subVariant: "outline-primary",
          showSuccess: false,
          saveVariant: 'blue',
          showAlert: false,
          showUpload: false,
          alertText: "",
          load: true,
          organization_name: "",
          section: {name: "", section_name: "", next_meeting: "", files: [], sharefile: 0, topic: ""},
          showDrag: false,
          dragTimeout: null,
          dragging: false,
          sectionReturned: false,
          sectionFound: false,
          useruid: '',
          showResendModal: false,
          validEmail: false,
          resendFile: null,
          resendEmail: "",
          resendType: 0,
          showValidFileExts: false,
        }
      },
    methods: {
      validateEmail() {
          if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.resendEmail)) {
              this.validEmail= true;
          } else {
              this.validEmail= false;
          }
      },
      resendFileConfirm(){
         if(this.resendFile!= null){
          var file = this.resendFile;
          if(this.resendType==0){
              this.resendEmail = "";
          }else{
            this.setCookie("resend_email", this.resendEmail);
          }
          this.showResendModal = false;
         this.$http.post("/api/organization/section/resendconfirm/byhash"+(this.useruid==''? "" : "/byuseruid"), {hash: this.section.hash,section_id: file.section_id, number: file.number, useruid: this.useruid, recipient: this.resendEmail}).then((response) => {
          if(response.data.success){
            this.$bvToast.toast(this.$t('preparer.emails_sent_to')+": "+response.data.emails.join(", "), {
              title: this.$t('preparer.resend_success'),
              variant: 'success',
              solid: true
            });
          }
        }).catch(() => {});
         }
      },
      popupResendModal(file){
        this.resendEmail = this.getCookie("resend_email");
        this.validateEmail();
        this.resendFile = file;
        this.showResendModal = true;
      },
      dropHandler(ev){
        if(ev){
          ev.preventDefault();
         if (ev.dataTransfer.items) {
            for (var i = 0; i < ev.dataTransfer.items.length; i++) {
              if (ev.dataTransfer.items[i].kind === 'file' && this.checkFileSize(ev.dataTransfer.items[i])) {
                var file = ev.dataTransfer.items[i].getAsFile();
                this.addFileLink();
                this.section.files[this.section.files.length-1].file = file;
                this.section.files[this.section.files.length-1].filename = file.name;
                this.section.files[this.section.files.length-1].description = file.name;
              }
            }
          } else {
            for (var j = 0; j < ev.dataTransfer.files.length; j++) {
              if(this.checkFileSize(ev.dataTransfer.files[j])){
                this.addFileLink();
                this.section.files[this.section.files.length-1].file = ev.dataTransfer.files[j];
                this.section.files[this.section.files.length-1].filename = ev.dataTransfer.files[j].name;
                this.section.files[this.section.files.length-1].description = ev.dataTransfer.files[j].name;
              }
            }
          }
        }
        this.showDrag = false;
        this.dragging = false;
      },
      dragEnterHandler(){
        this.showDrag = true;
        this.dragging = true;
      },
      dragHandler(){
        this.showDrag = true;
        this.dragging = true;
      },
      dragLeaveHandler(){
        this.dragging = false;
        var self = this;
        clearTimeout( this.dragTimeout );
        this.dragTimeout = setTimeout( function(){
          if( !self.dragging ){ self.showDrag = false;  }
      }, 200 );
      },
      
      addFileLink() {
        if((this.section.files[this.section.files.length-1].type == 1 && this.section.files[this.section.files.length-1].file == null && this.section.files[this.section.files.length-1].link == '') || (this.section.files[this.section.files.length-1].type == 2 && this.section.files[this.section.files.length-1].link == "")){
          return this.section.files.length-1;
        }
        for (var k = 1; k <= 5; k++) {
          var found = false;
          for (var i = 0; i < this.section.files.length; i++) {
            if (this.section.files[i].number == k) {
              found = true;
              
            }
          }
          if (!found) {
            this.showSuccess = false;
            this.section.files.push({ section_id: 0, number: k, type: 1, link: "", description: "", filename: "", file_path: "", file: null, new: true });
            return k;
          }
        }
        return -1;
      },
      viewFile(h){
            router.push("/filecontrol/"+h);
          },
          viewLink(l){
          window.open(l, "_blank", 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
        },
      removeFileLink(file) {
        var index = this.section.files.indexOf(file);
        if (index > -1) {
          this.section.files.splice(index, 1);
        }
         if(this.section.files.length == 0){
            this.section.files.push({ section_id: 0, number: 1, type: 1, link: "", description: "", filename: "",file_path: "", file: null, new: true });
          }
      },
      uploadFiles() {
        this.showUpload = true;
        this.saveVariant = "";

        let formData = new FormData();
        var presentationUploads = [];
        for (var i = 0; i < this.section.files.length; i++) {
          if (this.section.files[i].type == 1 && this.section.files[i].file && this.section.files[i].file != null) {
            formData.append("files", this.section.files[i].file);
            this.section.files[i].link = "";
            this.section.files[i].name = this.section.files[i].file.name;
            this.section.files[i].file = null;
            presentationUploads.push( this.section.files[i]);
          }else if (this.section.files[i].link != ""){
            presentationUploads.push( this.section.files[i]);
          }
        }
        formData.append("section_files", JSON.stringify(presentationUploads));
        formData.append("hash", this.section.hash);
        formData.append("sharefile", this.section.sharefile);
        formData.append("topic", this.section.topic);
        formData.append("useruid", this.useruid);
        this.$http.post("/api/organization/section/update/files/byhash"+(this.useruid==''? "" : "/byuseruid"), formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }).then((response) => {
          this.showUpload = false;
          this.saveVariant = "blue";
          if (response.data.success == true) {
            this.showSuccess = true;
          }
        }).catch(() => {
          this.showUpload = false;
          this.saveVariant = "blue";
          this.$bvToast.show('serverError');
        });
      },
          getSectionFiles() {
            this.sectionReturned = false;
            this.sectionFound = false;
            this.section.hash = this.$route.params.h;
            this.$http.post("/api/organization/section/get/files"+(this.useruid==''? "" : "/byuseruid"), { hash : this.section.hash, useruid: this.useruid}).then((response) => {
               
              if (response.data.success) {
                this.section = response.data.section;
                for (var i = 0; i < this.section.files.length; i++) {
                  this.$set(this.section.files[i], 'file', null);
                  this.$set(this.section.files[i], 'filename', "");
                }
                if(this.section.files.length == 0){
                  this.section.files.push({ section_id: 0, number: 1, type: 1, link: "", description: "", filename: "", file_path: "", file: null , new: true});
                }
                this.sectionFound = true;
                this.sectionReturned = true;
              } else {
                this.needsDate = response.data.needsDate;
                this.section.files = [];
                this.sectionFound = false;
                this.sectionReturned = true;
              }
            }).catch((errors) => {
              this.sectionFound = false;
              this.sectionReturned = true;
              console.log(errors);
            });
          },
      openFileDialog(file) {
        document.getElementById('fileupload' + file.number).click();
      },
      onFileFileChange(e, fileElement) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length || !this.checkFileSize(files[0]))
          return;
        fileElement.filename = files[0].name;
        fileElement.file = files[0];
        fileElement.new = true;
        if(fileElement.description.length == 0){
          fileElement.description = files[0].name;
        }
      },
       getIsMac(){
          return window.navigator.platform.indexOf('Mac')>=0 || window.navigator.platform.indexOf('mac')>=0 || window.navigator.platform.indexOf('Apple')>=0 || window.navigator.platform.indexOf('apple')>=0;
        }
    },
    mounted() {
      if(this.$route.params.useruid){
        this.useruid = this.$route.params.useruid;
      }
      this.setLanguageIfAvailable();
      this.getSectionFiles();
    }
    }
</script>
