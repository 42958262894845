<template>
  <div>
    <div class="rel-grid-container" style="max-width: 90%;">
      <div id="top-div"></div>
      <div>
          
        <div style="display:grid;margin: auto;width: fit-content;">
          <span class="form-box-name">{{$t('languageadmin.type_text')}}</span>
            <b-form-select style="max-width: 200px" v-model="permissions.languageObjectType" :options="objectOptions" class="mb-3 form-box-input" />
        </div>
        <table class="table b-table table-hover-new table-bordered table-sm">
          <thead>
            <tr>
              <th class="tableHeader" style="width: 200px;">{{$t('languageadmin.name_header')}}</th>
              <th style="width: 40%;" class="tableHeader"><b-form-select style="max-width: 200px;margin-left:30px;" v-model="language_from" :options="languageOptions" value-field="abbrev" text-field="name" /></th>
              <th style="width: 40%;" class="tableHeader"><b-form-select style="max-width: 200px;margin-left:30px;" v-model="language_to" :options="languageOptions" value-field="abbrev" text-field="name" /></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="object in languageObjects" :key="object.id">
              <td>
                <span style="font-weight:bold;">{{object.name}}</span>
              </td>
              <td>
                <pre v-if="object.language1 != null" class="pre-format" v-html="object.language1"></pre>
              </td>
               <td v-on:click="editScript(object)" class="clickable">
                <pre v-if="object.language2 != null" class="pre-format" v-html="object.language2"></pre>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <b-modal id="language-modal" centered v-model="showScriptEdit" :title="$t('languageadmin.edit_translation')">
           <div v-if="permissions.languageObjectType==1 || permissions.languageObjectType==2" style="display: grid; grid-template-columns: auto auto auto;">
            <div>
              <span class="form-box-title">{{$t('languageadmin.original')}}</span>
              <div class="form-box" style="grid-template-columns: auto;">
                <pre v-if="currentObject.language1 != null" class="pre-format" v-html="currentObject.language1"></pre>
              </div>
            </div>
            <div>
              <span class="form-box-title">{{$t('languageadmin.translation')}}</span>
              <div class="form-box" style="grid-template-columns:auto;">
                <div class="editor">
                  <editor-menu-bar :editor="editor">
                    <div class="menubar" slot-scope="{ commands, isActive }">
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.bold() }" v-on:click="commands.bold">
                        <b>B</b>
                      </b-button>
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.italic() }" v-on:click="commands.italic">
                        <i>I</i>
                      </b-button>
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.strike() }" v-on:click="commands.strike">
                        <s>S</s>
                      </b-button>
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.underline() }" v-on:click="commands.underline">
                        <u>U</u>
                      </b-button>
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.paragraph() }" v-on:click="commands.paragraph">
                        P
                      </b-button>
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.superscript() }" v-on:click="commands.superscript()">
                        super<sup>script</sup>
                      </b-button>
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.heading({ level: 1 }) }" v-on:click="commands.heading({ level: 1 })">
                        H1
                      </b-button>
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.heading({ level: 2 }) }" v-on:click="commands.heading({ level: 2 })">
                        H2
                      </b-button>
                      
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.bullet_list() }" v-on:click="commands.bullet_list">
                        {{$t('entry.bullet_text')}}
                      </b-button>
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.ordered_list() }" v-on:click="commands.ordered_list">
                        {{$t('entry.list_text')}}
                      </b-button>
                      <b-button variant="outline-dark" v-on:click="commands.undo">
                        {{$t('entry.undo_text')}}
                      </b-button>
                      <b-button variant="outline-dark" v-on:click="commands.redo">
                        {{$t('entry.redo_text')}}
                      </b-button>
                    </div>
                  </editor-menu-bar>
                  <editor-content class="editor__content" :editor="editor" style="height:300px;width:100%;overflow-y: auto;overflow-x: hidden;" />
                </div>
              </div>
            </div>
            <div >
              <span class="form-box-title">{{$t('scripts.insert_variables')}}</span>
              <div class="form-box" style="grid-template-columns: auto;">
                <div>
                  <b-form-checkbox-group style="display: grid;" v-model="variableGroup" :options="groupOptions" value-field="value" text-field="name" >
                  </b-form-checkbox-group>
                </div>
                <div style="height: 221px; overflow-y: auto; margin-top: 10px;">
                  <table class="table b-table table-hover-new table-bordered table-sm">
                    <tbody>
                      <tr class="variable-row" v-for="variable in variable_map.filter(v => v.group == variableGroup)" v-on:click="addTextVariable(variable)" :key="variable.id">
                        <td>{{ variable.name }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style="font-size: 10px;color: #777;">{{$t('scripts.click_entry')}}</div>
              </div>
            </div>
          </div>
          <div  v-if="permissions.languageObjectType>2" style="display: grid; grid-template-columns: auto auto;    margin: auto;" class="fit-content">
            <div class="form-box" style="grid-template-columns: auto;">
              <span class="form-box-name">{{$t('languageadmin.original')}}</span>
              <b-form-input disabled v-model="currentObject.language1" style="max-width: 300px;" class="form-box-input" ></b-form-input>
              <span class="form-box-name">{{$t('languageadmin.translation')}}</span>
              <b-form-input v-model="currentObject.language2" style="max-width: 300px;" class="form-box-input" ></b-form-input>
            </div>
          </div>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="submit-button4" v-on:click="copyObject" variant="clear-blue"><div class="copy-button"></div><span>{{$t('global.button_copy')}}</span></b-button>
            <b-button class="submit-button4" v-on:click="updateLanguageObject" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
        </b-modal>
      </div>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
 
</style>
<script>
  import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
  import Superscript from '@/assets/Superscript'
  import {
    Blockquote,
    HardBreak,
    Heading,
    HorizontalRule,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Italic,
    Link,
    Strike,
    Underline,
    History
  } from 'tiptap-extensions'

    export default {
    name: "Translations",
    components: {
      EditorContent,
      EditorMenuBar,
    },
    watch: {
      'permissions.languageObjectType': {
        handler: function () {
            this.getLanguageObjects();
        }
      },
      language_from: {
        handler: function () {
            this.getLanguageObjects();
        }
      },
      language_to: {
        handler: function () {
            this.getLanguageObjects();
        }
      },
      '$i18n.locale': {
        handler: function () {
          this.groupOptions= [{name: this.$t('slide.group_option1'), value: 'chapter'},{name: this.$t('slide.group_option2'), value: 'analytic'},
          {name: this.$t('slide.group_option3'), value: 'regional'},{name: this.$t('slide.group_option4'), value: 'role'},{name: this.$t('slide.group_option7'), value: 'transition'},
          {name: this.$t('slide.group_option5'), value: 'speaker'},{name: this.$t('slide.group_option6'), value: 'other'}];
          this.objectOptions= [{value: 1, text: this.$t('languageadmin.scripts')},
          {value: 3, text: this.$t('languageadmin.slide_names')},
          {value: 4, text: this.$t('languageadmin.section_names')},
          {value: 5, text: this.$t('languageadmin.roles')}];
          if(this.permissions.organization.is_parent==1){
            
           this.objectOptions.push({value: 6, text: this.$t('languageadmin.analytics')});
          }
          this.getLanguageObjects();
          this.getLanguages();
        },
        deep: true
      }
    },
      data() {
        return {
          groupOptions: [{name: this.$t('slide.group_option1'), value: 'chapter'},{name: this.$t('slide.group_option2'), value: 'analytic'},
          {name: this.$t('slide.group_option3'), value: 'regional'},{name: this.$t('slide.group_option4'), value: 'role'},{name: this.$t('slide.group_option7'), value: 'transition'},
          {name: this.$t('slide.group_option5'), value: 'speaker'},{name: this.$t('slide.group_option6'), value: 'other'}],
          variableGroup: 'chapter',
          editor: new Editor({
            disableInputRules: true,
            disablePasteRules: true,
            extensions: [
              new Blockquote(),
              new BulletList(),
              new HardBreak(),
              new Heading({ levels: [1, 2, 3] }),
              new HorizontalRule(),
              new ListItem(),
              new OrderedList(),
              new TodoItem(),
              new TodoList(),
              new Bold(),
              new Italic(),
              new Link(),
              new Strike(),
              new Underline(),
              new History(),
              new Superscript()
            ],
            content: ''
          }),
          showScriptEdit: false,
          variable_map: [],
          currentObject: {id: 0, name: '', language1: '', language2 : ''},
          languageOptions:[],
          objectOptions: [{value: 1, text: this.$t('languageadmin.scripts')},
          {value: 3, text: this.$t('languageadmin.slide_names')},
          {value: 4, text: this.$t('languageadmin.section_names')},
          {value: 5, text: this.$t('languageadmin.roles')},
          {value: 6, text: this.$t('languageadmin.analytics')}],
          language_from: '',
          language_to: '',
          languageObjects: []
        }
      },
    methods: {
      getVariableData(){
        this.$http.post("/api/organization/slide/get/variables", {joint: false}).then((response) => {
          if (response.data.success === true) {
            this.variable_map = response.data.variable_map;
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
      getLanguages(){
        this.$http.post("/api/organization/languages/possible", {}).then((response) => {
          if (response.data.success === true) {
            this.languageOptions = response.data.languages;
            this.language_from = response.data.languages.length>0? response.data.languages[0].abbrev : '';
            this.language_to = response.data.languages.length>1? response.data.languages[1].abbrev : '';
          }
        }).catch((errors) => {
          console.log(errors)
        });
        },
        copyObject(){
          if(this.currentObject.language1 && (this.permissions.languageObjectType == 1 || this.permissions.languageObjectType == 2)){
            this.editor.setContent(this.currentObject.language1);
          }else{
            this.currentObject.language2 = this.currentObject.language1;
          }
        },
      getLanguageObjects(){
        if(this.language_from.length==2 && this.language_to.length==2 && this.permissions.languageObjectType>0){
          switch(this.permissions.languageObjectType){
            case 1:
              this.getScripts();
              break;
            case 2:
              this.getSlideTexts();
              break;
            case 3:
              this.getSlideNames();
              break;
            case 4:
              this.getSectionNames();
              break;
            case 5:
              this.getRoleNames();
              break;
            case 6:
              this.getAnalyticNames();
              break;
            default:
              break;
          }
        }
      },
      updateLanguageObject(){
        if(this.language_from.length==2 && this.language_to.length==2 && this.permissions.languageObjectType>0){
          switch(this.permissions.languageObjectType){
            case 1:
              this.updateScript();
              break;
            case 2:
              this.updateSlideText();
              break;
            case 3:
              this.updateSlideName();
              break;
            case 4:
              this.updateSectionName();
              break;
            case 5:
              this.updateRoleName();
              break;
            case 6:
              this.updateAnalyticName();
              break;
            default:
              break;
          }
          this.showScriptEdit = false;
        }
      },
      highlight(script) {
        this.variable_map.forEach(function (item) {
          if (script && script.indexOf(item.id) >= 0) {
            script = script.split(item.id).join(item.noHighlight? item.value : ("<b class='script-variable'>" + item.id + "</b>"));
          }
        });
        return script;
      },
      editScript(item) {
        this.currentObject = item;
          this.showScriptEdit = true;
        if (this.permissions.languageObjectType == 1 || this.permissions.languageObjectType == 2) {
          this.editor.setContent(this.currentObject.language2);
        }
      },
      copyScriptToNotes(){
        this.editor.setContent(this.currentObject.script);
      },
      addTextVariable(variable) {
        const { state } = this.editor;
        var t = this.editor.getHTML();

        var a = state.selection.$anchor.nodeBefore != null? state.selection.$anchor.nodeBefore.text : "";
        var b = state.selection.$anchor.nodeAfter != null? state.selection.$anchor.nodeAfter.text: "";

        var parts = t.split(a+b);
        if(parts.length == 2){
          this.editor.setContent(parts[0]+a+variable.id+b+parts[1]);
        }else{
          this.editor.setContent(t+variable.id);
        }
      },
      getScripts() {
        this.$http.post("/api/organization/scripts/languageadmin", {language_from: this.language_from, language_to: this.language_to}).then((response) => {
          if (response.data.success) {
            this.languageObjects = response.data.scripts;
          } 
        }).catch((errors) => {
          console.log(errors);
        });
      },
      getSlideTexts(){
        this.$http.post("/api/organization/slides/languageadmin", {language_from: this.language_from, language_to: this.language_to}).then((response) => {
          if (response.data.success) {
            this.languageObjects = response.data.slides;
          } 
        }).catch((errors) => {
          console.log(errors);
        });
      },
      getSlideNames(){
        this.$http.post("/api/organization/slidenames/languageadmin", {language_from: this.language_from, language_to: this.language_to}).then((response) => {
          if (response.data.success) {
            this.languageObjects = response.data.slides;
          } 
        }).catch((errors) => {
          console.log(errors);
        });
      },
      getSectionNames(){
        this.$http.post("/api/organization/sectionnames/languageadmin", {language_from: this.language_from, language_to: this.language_to}).then((response) => {
          if (response.data.success) {
            this.languageObjects = response.data.sections;
          } 
        }).catch((errors) => {
          console.log(errors);
        });
      },
      getRoleNames(){
        this.$http.post("/api/organization/rolenames/languageadmin", {language_from: this.language_from, language_to: this.language_to}).then((response) => {
          if (response.data.success) {
            this.languageObjects = response.data.roles;
          } 
        }).catch((errors) => {
          console.log(errors);
        });
      },
      getAnalyticNames(){
        this.$http.post("/api/organization/analyticnames/languageadmin", {language_from: this.language_from, language_to: this.language_to}).then((response) => {
          if (response.data.success) {
            this.languageObjects = response.data.analytics;
          } 
        }).catch((errors) => {
          console.log(errors);
        });
      },
      updateScript() {
        if (this.permissions.languageObjectType == 1 || this.permissions.languageObjectType == 2) {
          this.currentObject.language2 = this.editor.getHTML();
         }
           this.$http.post("/api/organization/scripts/languageadmin/update", {id: this.currentObject.id, language2: this.currentObject.language2, language_to: this.language_to}).then(() => {
          }).catch(() => {
            this.$bvToast.show('serverError');
          });
      },
      updateSlideText() {
        if (this.permissions.languageObjectType == 1 || this.permissions.languageObjectType == 2) {
          this.currentObject.language2 = this.editor.getHTML();
         }
          this.$http.post("/api/organization/slides/languageadmin/update", {id: this.currentObject.id, language_from: this.language_from, language2: this.currentObject.language2, language_to: this.language_to}).then(() => {
        }).catch(() => {
          this.$bvToast.show('serverError');
        });
      },
      updateSlideName() {
          this.$http.post("/api/organization/slidenames/languageadmin/update", {id: this.currentObject.id,  language2: this.currentObject.language2, language_to: this.language_to}).then(() => {
        }).catch(() => {
          this.$bvToast.show('serverError');
        });
      },
      updateSectionName() {
          this.$http.post("/api/organization/sectionnames/languageadmin/update", {id: this.currentObject.id,  language2: this.currentObject.language2, language_to: this.language_to}).then(() => {
        }).catch(() => {
          this.$bvToast.show('serverError');
        });
      },
      updateRoleName() {
          this.$http.post("/api/organization/rolenames/languageadmin/update", {id: this.currentObject.id,  language2: this.currentObject.language2, language_to: this.language_to}).then(() => {
        }).catch(() => {
          this.$bvToast.show('serverError');
        });
      },
      updateAnalyticName() {
          this.$http.post("/api/organization/analyticnames/languageadmin/update", {id: this.currentObject.id, type: this.currentObject.type, language2: this.currentObject.language2, language_to: this.language_to}).then(() => {
        }).catch(() => {
          this.$bvToast.show('serverError');
        });
      },
    },
    beforeDestroy() {
      this.editor.destroy()
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-translations"), "active-menu");
      this.removeClass(document.getElementById("menu-admin"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-translations"), "active-menu");
      this.addClass(document.getElementById("menu-admin"), "active-menu");
      this.objectOptions= [{value: 1, text: this.$t('languageadmin.scripts')},
          {value: 3, text: this.$t('languageadmin.slide_names')},
          {value: 4, text: this.$t('languageadmin.section_names')},
          {value: 5, text: this.$t('languageadmin.roles')}];
          if(this.permissions.organization.is_parent==1){
           this.objectOptions.push({value: 6, text: this.$t('languageadmin.analytics')});
          }
      this.getLanguages();
      this.getVariableData();
    }
  }
</script>
