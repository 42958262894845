<template>
  <div style="height:100%;">
    <div style="top: 40px;position: relative;margin: auto;height:100%;">
      <div id="top-div"></div>
      <div style="height:100%;">
        <!--<b-table class="table-hover-new" style="cursor: pointer; max-width: 1000px; margin: auto;" small bordered :items="members" :fields="fields">
          <template v-slot:cell(actions)="data" >
            <b-button v-if="!data.item.uploaded" :disabled="showUpload" style="position:relative;font-size:16px;"  @click.stop="resizeMemberPhoto(data.item)" variant="light-blue-outline">
              Resize Image
            </b-button>
          </template>
        </b-table>-->
        <iframe src="https://zoom.us/wc/640993551/join?prefer=1&un=TWluZGF1Z2Fz&pwd=092986" crossorigin="anonymous" target="_parent" frameborder="0" style="width:665px;height:1080px;" sandbox="allow-forms allow-scripts allow-same-origin" allow="microphone; camera; fullscreen"></iframe>
      --></div>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
</style>
<script>
   /* import { ZoomMtg } from '@zoomus/websdk';
    ZoomMtg.setZoomJSLib('https://jssdk.zoomus.cn/1.7.2/lib', '/av'); 
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareJssdk();*/
    
    import router from "../router"

    export default {
    name: "Temp",
      data() {
        return {
          members : [],
          slides: [],
          current_slide_id: 0,
          current_member_id: 0,
          tempPhoto: "",
          imgSrc: '',
          showUpload: false,
          fields: [
            {
              key: 'name',
              label: "Name",
              sortable: true,
              thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
            },
            {
              key: 'actions',
              label: "Actions",
              sortable: false,
              thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff", "width": "200px", "text-align": "center" },
              tdClass: "actions-centre"
            }
          ],
        }
      },
    methods: {
      resizeImage(data, scale_width, scale_height, type, onEnd) {
        if (data == null) {
          onEnd(null);
          return;
        }
        var img = document.createElement("img");
          img.src = data;
          img.onload = function () {
            var width = img.width;
            var height = img.height;
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");
            
            ctx.drawImage(img, 0, 0);
            var MAX_WIDTH = scale_width;
            var MAX_HEIGHT = scale_height;
            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
            } else {
              if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }
            }
            canvas.width = width;
            canvas.height = height;
            if(type.includes("j")){
              ctx.fillStyle = '#fff'; 
              ctx.fillRect(0, 0, canvas.width, canvas.height);
            }
            ctx.drawImage(img, 0, 0, width, height);
            var dataurl = canvas.toDataURL(type, 0.8);
            onEnd(dataurl);
          }
      },
      getAllSlides() {
        this.$http.post("/api/slides/get/unbound", {}).then((response) => {
          if (response.data.success) {
            this.slides = response.data.slides;
            this.current_slide_id = 0;
            this.recurSlide();
          }
        }).catch((errors) => { console.log(errors);});
      },
      recurSlide(){
        var self = this;
        this.resizeSlidePhoto(this.slides[this.current_slide_id], function(){
          self.current_slide_id++;
          console.log("slide percent: "+((self.current_slide_id/self.slides.length)*100));
          if(self.current_slide_id<self.slides.length){
            self.recurSlide();
          }
        });
      },
      recurMember(){
        var self = this;
        this.resizeMemberPhoto(this.members[this.current_member_id], function(){
          self.current_member_id++;
          console.log("member percent: "+((self.current_member_id/self.members.length)*100));
          if(self.current_member_id<self.members.length){
            self.recurMember();
          }
        });
      },
      resizeSlidePhoto(slide, onEnd) {
        this.showUpload = true;
        this.$http.post("/api/slide/get/media/unbound", { id: slide.id }).then((response) => {
          if (response.data.success) {
            var photo = response.data.photo;
            var self = this;
            this.resizeImage(photo,1920, 1080,'image/jpeg', function(dataurl){
              self.updateSlide(slide, dataurl, onEnd);
            });
          } 
        }).catch((errors) => { console.log(errors);});
      },
       updateSlide(slide, photo, onEnd) {
        this.$http.post("/api/slide/update/media/unbound", {photo: photo, id : slide.id }).then((response) => {
          if (response.data.success) {
            this.succeeded = true;
          }
          onEnd();
        }).catch((errors) => { console.log(errors);});
      },
      getAllMembers() {
        this.$http.post("/api/members/get/unbound", {}).then((response) => {
          if (response.data.success) {
            for(var i=0; i<response.data.members.length;i++){
              response.data.members[i].uploaded = false;
            }
            this.members = response.data.members;
            this.current_member_id = 0;
            this.recurMember();
          }
        }).catch((errors) => { console.log(errors);});
      },
      resizeMemberPhoto(member, onEnd) {
        this.showUpload = true;
        this.$http.post("/api/member/get/media/unbound", { id: member.id }).then((response) => {
          if (response.data.success) {
            var photo = response.data.photo;
            //var logo = response.data.logo;
            var self = this;
            this.resizeImage(photo,665, 1080,'image/jpeg', function(dataurl){
              var photo = dataurl;
              self.updateMember(member, photo, onEnd);
            });
            /*this.resizeImage(photo,132,216,'image/jpeg', function(dataurl){
              var photo_small = dataurl;
              self.updateMember(member, photo_small);
            });*/
            /*self.resizeImage(photo, 665, 1080, "jpg", function (durl) {
              photo = durl;
              self.resizeImage(logo, 137, 242, "png", function (durl2) {
                  logo = durl2;
                self.updateMember(id, photo, logo);
              });
            });*/
          } else {
            this.showUpload = false; 
          }
        }).catch((errors) => { console.log(errors);});
      },
      updateMember(member, photo, onEnd) {
        this.$http.post("/api/member/update/media/unbound", {photo: photo, id : member.id }).then((response) => {
          this.showUpload = false;
          this.$set(member, 'uploaded', true);
          if (response.data.success) {
            this.succeeded = true;
          }
          onEnd();
        }).catch((errors) => { this.showUpload = false; console.log(errors);});
      },
      auth(){
        this.$http.get("/api/auth/ultimate")
          .then((response) => {
            if (response.data.authorized != true) {
              router.push("/login");
            }
          })
          .catch(() => {
            router.push("/login");
          });
      }
    },
    mounted() {
      this.auth();

      //this.getAllMembers();
      //this.getAllSlides();
      
    }
    }
</script>
