<template>
  <div>
    <div class="rel-grid-container">
      <div id="top-div"></div>
       <Tip :tipNumber="12"></Tip>
      <form v-on:submit.prevent="updateSection"  id="form-fix">
         
        <span class="form-box-title ">{{$t('section.meeting_section')}}</span>
        <div class="form-box" style="grid-template-columns: auto auto auto;">
          <span class="form-box-name">{{$t('section.name_text')}}</span><b-form-input :disabled="section.is_parent == 1 && !permissions.organization.is_parent" v-model="section.name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;" class="form-box-input" required></b-form-input>
          <span class="form-box-name">{{$t('section.target_duration')}}</span><div class="time-box"><span class="form-box-name">{{$t('section.mins_text')}}</span><b-form-input v-model="section.minutes" type="number" style="max-width: 100px;" required></b-form-input></div>
          <div class="time-box"><span class="form-box-name">{{$t('section.secs_text')}}</span><b-form-input v-model="section.seconds" type="number" style="max-width: 100px;" required></b-form-input></div>
          <span v-if="!permissions.organization.is_parent" class="form-box-name">{{$t('section.auto_start')}}</span>

          
         <b-form-timepicker :label-no-time-selected="$t('global.no_time')" v-if="!permissions.organization.is_parent"  reset-button class="form-box-input time-picker"  v-model="section.force_time" :locale="$i18n.locale"></b-form-timepicker>
            <!--<b-input-group >
             <b-input-group-append>
              <b-form-timepicker style="height:38px;" minutes-step="10" :label-no-time-selected="$t('global.no_time')" v-model="section.force_time" button-only reset-button :locale="$i18n.locale" aria-controls="example-input" ></b-form-timepicker>
            </b-input-group-append>
            <b-form-input v-model.lazy="shownTime" type="text" placeholder="HH:mm aa"></b-form-input>
            
          </b-input-group>-->
          <div id="section-check">
            <b-form-checkbox v-if="section.is_parent == 0" v-model="section.looped"
                             value="1"
                             unchecked-value="0">
              {{$t('section.loop_text')}}
            </b-form-checkbox>
            <b-form-checkbox v-if="(!permissions.organization.is_parent && ((section.allow_disable==1 && section.is_parent == 1) || section.is_parent == 0)) || (permissions.organization.is_parent && section.is_parent == 0)" v-model="section.disabled"
                             value="1"
                             unchecked-value="0">
              {{$t('section.disable_text')}}
            </b-form-checkbox>
            <b-form-checkbox v-if="permissions.organization.is_parent && section.is_parent == 0" v-model="section.allow_disable"
                             value="1"
                             unchecked-value="0">
              {{$t('section.allow_disable')}}
            </b-form-checkbox>
            <b-form-checkbox v-if="section.is_parent == 0" v-model="section.auto_play"
                             unchecked-value="0"
                             value="1">
              {{$t('section.auto_play')}}
            </b-form-checkbox>
          </div>

        </div>
        <div v-if="!permissions.organization.is_parent && !permissions.organization.official">
          <span class="form-box-title">{{$t('section.section_prep')}}</span>
          <div class="form-box" style="grid-template-columns: 30% 70%;">
            <span class="form-box-name">{{$t('section.contacts_text')}}</span>
            <multiselect :noOptions="$t('global.empty_list')" :class="{blueselect:true}" :selectedLabel="$t('global.selected_text')" selectLabel="" deselectLabel="" v-model="value" :options="contacts" :multiple="true" :close-on-select="false"  :preserve-search="true" :placeholder="$t('section.select_contacts')" label="name" track-by="id" :preselect-first="false">
              <template slot="option" slot-scope="props">
                <div><span>{{ props.option.name }}</span>  <span >- {{ props.option.email.length>0 ? props.option.email : $t('global.no_email')  }}</span></div>
              </template>
            </multiselect>
          </div>
        </div>
        <div v-if="!permissions.organization.is_parent && permissions.organization.official">
          <span class="form-box-title">{{$t('section.section_prep')}}</span>
          <div class="form-box" style="grid-template-columns: 30% 70%;">
            <span class="form-box-name">{{$t('section.preparer_text')}}</span>
            <multiselect :noOptions="$t('global.empty_list')" :class="{blueselect:true}" :selectedLabel="$t('global.selected_text')" selectLabel="" deselectLabel="" v-model="value" :options="members" :multiple="true" :close-on-select="false"  :preserve-search="true" :placeholder="$t('global.select_members')" label="name" track-by="id" :preselect-first="false">
              <template slot="option" slot-scope="props">
                <div><span>{{ props.option.name }}</span>  <span >- {{ props.option.email.length>0 ? props.option.email : $t('global.no_email')  }}</span></div>
              </template>
            </multiselect>
          </div>
        </div>
         <div v-if="permissions.organization.is_parent && permissions.organization.official">
          <span class="form-box-title">{{$t('section.section_prep')}}</span>
          <div class="form-box" style="grid-template-columns: 30% 70%;">
            <span class="form-box-name">{{$t('section.roles_text')}}</span>
            <multiselect :max="1" :noOptions="$t('global.empty_list')" :class="{blueselect:true}" :selectedLabel="$t('global.selected_text')" selectLabel="" deselectLabel="" v-model="value" :options="roles" :multiple="true" :close-on-select="false"  :preserve-search="true" :placeholder="$t('global.select_role')" label="name" track-by="id" :preselect-first="false">
              <template slot="option" slot-scope="props">
                <div><span>{{ props.option.name }}</span></div>
              </template>
            </multiselect>
          </div>
        </div>
        <div v-if="showAlert">
            <b-alert variant="danger"
                      dismissible
                      :show="showAlert"
                      @dismissed="showAlert=false">
              {{alertText}}
            </b-alert>
          </div>
        <div class="submit-box-2">
          <b-button v-b-modal.modal-center v-if="section.id>0 && !(section.is_parent == 1 && !permissions.organization.is_parent)" class="submit-button4" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          <b-button class="submit-button4" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="submit-button4" :disabled="updating" type="submit" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
        </div>
        <b-modal id="modal-center" v-model="showDelete" centered :title="$t('section.delete_title')">
          <p class="my-4">{{$t('section.delete_text')}}</p>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="submit-button4" v-on:click="showDelete=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="submit-button4" v-on:click="deleteSection" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          </div>
        </b-modal>
      </form>
        <div class="div-space"> </div>
      </div>
    </div>
</template>
  <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
  <style scoped>
    #section-check {
      grid-column-start: 2;
      grid-column-end: 4;
      color: #999;
    }
    
  </style>
  <script>
      
    import router from "../router"
    import Multiselect from 'vue-multiselect'

      export default {
      name: "Section",
      watch: {
        'section': {
          handler: function () {
            this.saveVariant = 'blue';
          },
          deep: true
        },
      },
      components: { Multiselect },
        data() {
          return {
            shownTime: "",
            showDelete: false,
            saveVariant : 'blue',
            selected: [],
            section: { id: 0, preparer_emails : "", looped: 0, disabled: 0,  name: "", minutes: 1, seconds: 0,
                  auto_play: 1, rank: " ", edit_name: 1, allow_disable: 1,section_id : 0, force_time: "",is_parent: 0},
            value: [],
            members: [],
            roles: [],
            showAlert: false,
            alertText: "",
            contacts: [],
            updating: false
          }
        },
          methods: {
            clearAutoTime(){
              this.$set(this.section, 'force_time', null);
            },
            getSection() {
              this.section.id = this.$route.params.id;
              if (this.section.id > 0) {
                this.$http.post("/api/meeting/section/get", this.section).then((response) => {
                  if (response.data.success) {
                    this.section = response.data.section;
                    this.section.minutes = Math.floor(this.section.target_duration / 60);
                    this.section.seconds = this.section.target_duration % 60;
                    var ids = this.section.preparer_emails.split(",");
                    if(this.permissions.organization.is_parent){
                      for(var i=0; i<this.roles.length;i++){
                        if(ids.indexOf(this.roles[i].id+"")>=0){
                          this.value.push(this.roles[i]);
                        }
                      } 
                    }else if(!this.permissions.organization.official){
                      for(var j=0; j<this.contacts.length;j++){
                        if(ids.indexOf(this.contacts[j].id+"")>=0){
                          this.value.push(this.contacts[j]);
                        }
                      }
                    }else{
                      for(j=0; j<this.members.length;j++){
                        if(ids.indexOf(this.members[j].id+"")>=0){
                          this.value.push(this.members[j]);
                        }
                      }
                    }
                    
                  }
                }).catch(() => {});
              }
            },
            updateSection() {
              this.updating = true;
              this.showAlert = false;
              var t = [];
              for(var i=0; i<this.value.length;i++){
                t.push(this.value[i].id);
              }
              this.section.preparer_emails = t.join(",");
              this.section.target_duration = parseInt(this.section.minutes * 60) + parseInt(this.section.seconds);
              if(this.section.target_duration<60){
                this.showAlert = true;
                this.alertText = this.$t('section.duration_error');
                return;
              }
              this.$http.post("/api/meeting/section/update", this.section).then((response) => {
                this.updating = false;
                if (response.data.success === true) {
                  this.saveVariant = 'success';
                  this.back();
                } else {  
                  this.saveVariant = 'danger';
                }
              }).catch(() => {this.updating = false;this.$bvToast.show('serverError');});
            },
            deleteSection() {
              this.$http.post("/api/meeting/section/delete", this.section).then((response) => {
                if (response.data.success === true) {
                  this.back();
                }
              }).catch(() => {});
            },
            back(){
              
              window.history.length > 1 ? router.go(-1) : router.push("/admin/sections");
            },
            getMembers() {
              this.$http.post("/api/organization/members/email/select", {}).then((response) => {
                this.members = response.data.members;
                this.getRoles();
              }).catch(() => {});
            },
            getRoles() {
              this.$http.post("/api/organization/roles/select", {}).then((response) => {
                this.roles = response.data.roles;
                this.getContacts();
              }).catch(() => {});
            },
            getContacts() {
              this.$http.post("/api/organization/contacts/assigned", {}).then((response) => {
                  this.contacts = response.data.contacts;
                  this.getSection();
              }).catch((errors) => {console.log(errors);});
            },
      },
      destroyed() {
      this.removeClass(document.getElementById("menu-agenda"), "active-menu");
      this.removeClass(document.getElementById("menu-meeting"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-agenda"), "active-menu");
      this.addClass(document.getElementById("menu-meeting"), "active-menu");
      this.getMembers();
        
      }
    }
  </script>
