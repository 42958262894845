<template>
  <div id="dash">
    <div id="meeting-box">
       <div id="meeting-description" class="fit-width" style="max-width: 1000px;margin: 30px auto; padding: 10px 75px;margin-bottom: 15px;">
          <div style="color:#ff0000;margin: 0 50px;font-size:26px;" class="meeting-descript">{{permissions.organization.name}}</div>
          <div style="color:#333;font-size:16px;" ><b>{{$d(new Date(), 'day_month_long')}}</b></div>
          <div v-if="regionOptions.length>1" style="display:grid; grid-template-columns: repeat(3,auto); grid-gap:10px;">
            <div v-for="reg in regionOptions" :key="reg.value" v-on:click="loginToAlternate(reg)" :class="{clickable: !reg.disabled}" class=" region-switch-button" :style="{'color': reg.value==permissions.organization.id?'black':''}">
            {{reg.text}}
          </div>
          </div>
        </div>
          <div v-if="permissions.organization.onboard==1 && permissions.organization.official" style="display: grid;grid-gap: 10px;width: fit-content;margin: auto;">
            <div class="dashboard-title" style="text-align:center;">
              <span>{{$t('dashboard.setup_launch')}}:</span>
            </div>
              <!--<div class="portal-option"  v-on:click="goToSetupPage('/admin/subscription', '',1)">
                <div class="option-title" style="color:black;">{{$t('dashboard.setup_subscription')}}</div>
              </div>-->
              <div class="portal-option"  v-on:click="goToSetupPage('/admin/regional', '',8)">
                <div class="option-title" style="color:black;">{{$t('dashboard.setup_region_settings')}}</div>
              </div>
              <!--<div class="portal-option"  v-on:click="goToSetupPage('/admin/regionmembers', '',10)">
                <div class="option-title" style="color:black;">{{$t('dashboard.setup_import')}}</div>
              </div>
              <div class="portal-option"  v-on:click="goToSetupPage('/admin/contacts', '',9)">
                <div class="option-title" style="color:black;">{{$t('dashboard.setup_contacts')}}</div>
              </div>-->
              <div class="portal-option"  v-on:click="goToSetupPage('/admin/agenda', '',12)">
                <div class="option-title" style="color:black;">{{$t('dashboard.setup_region_agenda')}}</div>
              </div>
              <div class="portal-option"  v-on:click="goToSetupPage('/admin/scripts', '',13)">
                <div class="option-title" style="color:black;">{{$t('dashboard.setup_region_script')}}</div>
              </div>
              <div class="portal-option"  v-on:click="goToSetupPage('/admin/chapters', '',11)">
                <div class="option-title" style="color:black;">{{$t('dashboard.setup_invite_chapters')}}</div>
              </div>
              <b-button variant="link" v-on:click="hideSetup" style="padding:0;border: none;"><i>{{$t('dashboard.hide_setup')}}</i></b-button>
          </div>
      <div v-if="permissions.organization.onboard==0 || !permissions.organization.official">
          
        <!--<b-table style="max-width:1000px; margin: auto;" class="table-hover-new" small bordered :items="organizations" :fields="fields">
              <template v-slot:cell(name)="data">
                {{data.item.name}}
                <img v-if="data.item.cron_speakers==1" style="height: 16px;margin: 7px 5px;" v-b-popover.hover.top="$t('organizations.debug_tooltip')"  src="../assets/icon-NoNotification.png" />
              </template>
            <template v-slot:cell(actions)="data">
                  <b-button style="position:relative;font-size:16px;" size="sm" :disabled="data.item.disabled==1" @click.stop="loginAsChapter(data.item)" variant="light-blue-outline">
                {{$t('organizations.login_as')}}
                </b-button>
             </template>
      </b-table>-->
        <div id="search-bar" class="fit-width" v-if="dates.length>0">
          <b-form-checkbox style="margin:auto;" v-model="showWeek" :value="0" :unchecked-value="1">
            {{$t('dashboard.day_text')}}
          </b-form-checkbox>
          <b-form-checkbox style="margin:auto;" v-model="showWeek" :value="1" :unchecked-value="0">
            {{$t('dashboard.week_text')}}
          </b-form-checkbox>
          <span class="form-box-name" style="margin: auto;margin-left: 10px;">{{$t('dashboard.regional_contact')}}</span><b-form-select style="margin: 5px;" v-model="contactId" :options="select_contacts" value-field="id" text-field="name" />
          <span v-if="(official==1 && !isCorporateConnections)" class="form-box-name" style="margin: auto;margin-left: 10px;">{{$t('dashboard.leadership_contact')}}</span><b-form-select v-if="(official==1 && !isCorporateConnections)" style="margin: 5px;" v-model="leaderId" :options="leaders" value-field="id" text-field="name"  />
          <b-button class="submit-button4" @click.stop="clearSearch" variant="clear-blue" style="margin:auto;">
            <div class="cancel-button"></div>
            <span>
            {{$t('global.button_clear')}}
            </span>
          </b-button>
        </div>
        <div v-if="dates.length==0" style="text-align:center; font-size: 30px; margin-top: 50px;">{{$t('dashboard.no_meetings')}}</div>
          <div>
            <div style="text-align:center; font-size: 30px; margin-top: 50px;" v-if="dates.length>0 && dates.filter(d => d.chapters.filter(v => (contactId==0 || v.contacts.includes(contactId)) && (leaderId==0 || v.leadership.filter(l => l.id == leaderId).length>0)).length>0 && (showWeek? true : d.day==today)).length==0">{{$t('organizations.no_chapters_found')}}</div>
            <div v-for="date in dates.filter(v=> showWeek? true : v.day==today)" :key="date.day" style=" margin:auto;max-width:1000px;" >
              <div style="font-size: 22px;margin-top:20px;" v-if="date.chapters.filter(v => (contactId==0 || v.contacts.includes(contactId)) && (leaderId==0 || v.leadership.filter(l => l.id == leaderId).length>0)).length>0"><b>{{$d(new Date("" + date.day.split("-").join("/") + " 00:01"), 'short_weekday')}}</b></div>
              <b-table class="table-hover-new" style="cursor:pointer;" v-on:row-clicked="editOrganization" small bordered :items="date.chapters.filter(v => (contactId==0 || v.contacts.includes(contactId)) && (leaderId==0 || v.leadership.filter(l => l.id == leaderId).length>0))" v-if="date.chapters.filter(v => (contactId==0 || v.contacts.includes(contactId)) && (leaderId==0 || v.leadership.filter(l => l.id == leaderId).length>0)).length>0" :fields="fields">
                    <template v-slot:cell(leadership)="data">
                        <div :id="'leader_'+leader.id" v-for="leader in data.item.leadership" :key="leader.id">
                          <span style="color: #01aeef;pointer:normal;">{{leader.name}}</span>
                          <b-popover v-if="leader.id>0"  custom-class="wide-popover" :target="'leader_'+leader.id" triggers="hover focus" @show="onPopoverShowLeader(leader)" >
                            <div style="display:grid; grid-template-columns: 100px auto;">
                              <img v-if="popup.photo_small && popup.photo_small.length>0" :src="popup.photo_small" style="width:75px;height:auto;" />
                              <div style="text-align: left;">
                                  <div :style="{'color': isCorporateConnections? '#FFD700' : '#CF2030', 'font-weight': 'bold'}">{{popup.name}}</div>
                                  <div >{{popup.company}}</div>
                                  <div style="font-weight:bold;">{{popup.category}}</div>
                                  <div><i>{{popup.roles}}</i></div>
                                  <div><a :href="'mailto:'+popup.email">{{popup.email}}</a></div>
                                  <div v-if="popup.phone"><a :href="'tel:'+popup.phone">{{popup.phone}}</a></div>
                              </div>
                            </div>
                          </b-popover>
                        </div>
                  </template>
                  <template v-slot:cell(speakers)="data">
                        <div :id="'leader_'+speaker.id"  v-for="speaker in data.item.speakers" :key="speaker.id">
                          <span style="color: #01aeef;pointer:normal;">{{speaker.name}}</span>
                          <b-popover v-if="speaker.id>0"  custom-class="wide-popover" :target="'leader_'+speaker.id" triggers="hover focus" @show="onPopoverShowSpeaker(speaker)" >
                            <div style="display:grid; grid-template-columns: 100px auto;">
                              <img v-if="popup.photo_small && popup.photo_small.length>0" :src="popup.photo_small" style="width:75px;height:auto;" />
                              <div style="text-align: left;">
                                  <div :style="{'color': isCorporateConnections? '#FFD700' : '#CF2030', 'font-weight': 'bold'}">{{popup.name}}</div>
                                  <div >{{popup.company}}</div>
                                  <div style="font-weight:bold;">{{popup.category}}</div>
                                  <div><i>{{popup.roles}}</i></div>
                                  <div><a :href="'mailto:'+popup.email">{{popup.email}}</a></div>
                                  <div v-if="popup.phone"><a :href="'tel:'+popup.phone">{{popup.phone}}</a></div>
                              </div>
                            </div>
                          </b-popover>
                        </div>
                  </template>
                  <template v-slot:cell(categories)="data">
                        <div v-for="category in data.item.categories" :key="category">{{category}}</div>
                  </template>
                  <template v-slot:cell(attendance)="data">
                        <div><b>{{data.item.present}}</b> | <span>{{data.item.registered}}</span></div>
                  </template>
                    <template v-slot:cell(actions)="data">
                        <b-button style="position:relative;font-size:16px;" size="sm"  @click.stop="loginAsChapter(data.item)" variant="light-blue-outline">
                      {{$t('organizations.login_as')}}
                      </b-button>
                  </template>
              </b-table>
            </div>
          </div>
      </div>
    </div>
    <b-modal id="slide-modal"  centered v-model="showNewOrg" :title="$tc('organizations.admin_title', organization_edit? 0 : 1)">
          <form style="display:grid; grid-template-columns: auto auto;">
            <div style="display:grid;grid-template-columns: 20% 80%;">
              <div style="grid-column: 1/3;">
                <span class="form-box-title">{{$t('organizations.chapter_info')}}</span>
                <div class="form-box" style="grid-template-columns: 35% 65%;">
                  <span class="form-box-name">{{$t('organizations.chapter_name')}}</span><b-form-input v-model="new_org.org_name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;" class="form-box-input" required></b-form-input>
                  
                  <b-form-checkbox v-if="organization_edit" :disabled="atMaxChapters && new_org.disabled==1" class="form-box-input" style="margin: auto 0;" v-model="new_org.disabled" value="1" unchecked-value="0" >
                    {{$t('organizations.disabled_header')}}
                  </b-form-checkbox>
                  <span class="form-box-name" style="grid-column:1;">{{$t('organizations.admin_name')}}</span><b-form-input v-model="new_org.admin_name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;" class="form-box-input" required></b-form-input>
                  <!--<span class="form-box-name">{{$t('organizations.admin_email')}}</span><b-form-input v-model="new_org.admin_email" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;" class="form-box-input"></b-form-input>-->
                  <span class="form-box-name">{{$t('organizations.admin_password')}}</span><b-form-input v-model="new_org.admin_pass" type="password" style="max-width: 300px;" class="form-box-input" ></b-form-input>
                  <span v-if="new_org.admin_pass.length>0" class="form-box-name">{{$t('organizations.retype_password')}}</span><b-form-input v-if="new_org.admin_pass.length>0" :state="new_org.admin_pass===new_org.admin_retype && new_org.admin_retype.length>0" v-model="new_org.admin_retype" type="password" class="form-box-input" ></b-form-input>
                  <div v-if="organization_edit" style="font-size: 10px;grid-column: 1/3;margin-left: auto;font-size: 10px;margin-bottom: 20px;">{{$t('organizations.password_change')}}</div>
                  <span v-if="organization_edit" class="form-box-name" style="grid-column:1;">{{$t('organizations.languages_text')}}</span>
                  <multiselect :noOptions="$t('global.empty_list')" v-if="organization_edit" :class="{blueselect:true}" style="grid-column: 2/3;margin-bottom: 10px;" :selectedLabel="$t('global.selected_text')" selectLabel="" deselectLabel="" v-model="new_org.languages" :options="languageOptions" :multiple="true" :close-on-select="false"  :preserve-search="true" :placeholder="$t('country.choose_language')" label="name" track-by="id" :preselect-first="false">
                  </multiselect>
                  <b-form-checkbox v-if="organization_edit" class="form-box-input" style="margin: auto 0;" v-model="new_org.language_admin" :value="1" :unchecked-value="0" >
                    {{$t('organizations.language_admin')}}
                  </b-form-checkbox>
                  <b-form-checkbox v-if="organization_edit" class="form-box-input" style="margin: auto 0;" v-model="new_org.onboard" :value="1" :unchecked-value="0" >
                    {{$t('organizations.setup_header')}}
                  </b-form-checkbox>
                  <b-button v-if="official_region>0 && organization_edit" style="grid-column: 2;margin-top:10px;"  v-on:click="showNewOrg=false;showMoveChapter=true;" variant="light-blue-outline"><span>{{$t('organizations.move_chapter')}}</span></b-button>
                  <b-form-checkbox v-if="!organization_edit && isCorporateConnections" class="form-box-input" style="margin: auto 0;" v-model="new_org.event_chapter" value="1" unchecked-value="0" v-on:change="new_org.onboard = 1-new_org.event_chapter">
                    {{$t('organizations.event_chapter')}}
                  </b-form-checkbox>
                  <b-form-checkbox v-if="!organization_edit" :disabled="new_org.event_chapter==1" class="form-box-input" style="margin: auto 0;" v-model="new_org.allow_logins" value="0" unchecked-value="1" >
                    {{$t('organizations.no_logins')}}
                  </b-form-checkbox>
                  <div v-if="!organization_edit"  style="grid-column: 2 / 3;font-size: 10px;margin: auto;margin-bottom: 10px;" v-html="$t('organizations.no_logins_note')">
                  </div>
                 
                  <!--<div v-if="!organization_edit "  style="grid-column: 2 / 3;font-size: 10px;margin: auto;margin-bottom: 10px;" v-html="$t('organizations.no_logins_prompt')">
                  </div>-->
                </div>
              </div>
            </div>
            <div style="display:grid;grid-template-columns: 20% 80%;" v-if="this.organization_edit">
              <div style="grid-column: 1/3;">
                <span class="form-box-title">{{$t('organizations.chapter_contacts')}}</span>
                <div class="form-box" style="grid-template-columns: auto;">
                  <div v-for="ctype in typeOptions" :key="ctype.id" style="grid-template-columns: 40% 60%;">
                    <span class="form-box-name">{{ctype.name}}</span>
                    <multiselect :noOptions="$t('global.empty_list')" style="grid-column:2/4;max-width: 400px;" :class="{blueselect:true}" :selectedLabel="$t('global.selected_text')" selectLabel="" deselectLabel=""  
                        v-model="ctype.members" :options="contacts.filter(t => (t.contact_type.split(',').find(v => v == ctype.id)!=undefined))" :multiple="true" :close-on-select="false"  :preserve-search="true" :placeholder="$t('global.select_types')" label="name" track-by="id" :preselect-first="false">
                    <template slot="option" slot-scope="props">
                      <div>{{ props.option.name }}</div>
                    </template>
                  </multiselect>
                  </div>
                </div>
              </div>
            </div>
            <b-alert variant="danger"
                     dismissible
                     :show="showAlert"
                     @dismissed="showAlert=false">
              {{alertText}}
            </b-alert>
          </form>
          <div slot="modal-footer" class="submit-box-2">
            <b-button v-if="new_org.was_disabled==1"  class="submit-button4" v-on:click="showNewOrg=false;showDeleteChapter=true" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
            <b-button  class="submit-button4" v-on:click="showNewOrg=false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="submit-button4" v-on:click="saveOrg" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
        </b-modal>
        <b-modal id="modal-center" v-model="showDeleteChapter" centered :title="$t('organizations.delete_title')">
            <p class="my-4" v-html="$t('organizations.delete_chapter')"></p>
            <div slot="modal-footer" class="submit-box-2">
              <b-button class="submit-button4" v-on:click="showDeleteChapter=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
              <b-button class="submit-button4" v-on:click="deleteChapter" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
            </div>
          </b-modal>
          <b-modal id="modal-center" v-model="showMoveChapter" centered :title="$t('organizations.move_chapter')">
            <p class="my-4" v-html="$t('organizations.move_confirm')"></p>
            <div slot="modal-footer" class="submit-box-2">
              <b-button class="submit-button4" v-on:click="showMoveChapter=false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
              <b-button class="submit-button4" v-on:click="moveChapter" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_yes')}}</span></b-button>
            </div>
          </b-modal>
    <b-modal centered v-model="showUnderConstruction" >
        <div style="text-align:center;" v-html="$t('dashboard.rst_construction')">
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="submit-button4" v-on:click="showUnderConstruction = false;" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
        </div>
      </b-modal>
  </div>
</template>
<style scoped>
#search-bar{
  display:grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 10px;
  padding: 10px;
  margin: auto;
  border-radius: 2px;
}
.region-switch-button{
  border-radius: 10px;
    border: 1px solid #aaa;
    color: #aaa;
    background-color: #fff;
    padding: 5px 10px;
}
.region-switch-button:hover{
  background-color: #e0f5fd;
}
.dashboard-title{
    border: 1px #f2f2f2 solid;
    color: #7f7f7f;
    border-radius: 2px;
    font-size: 19px;
    background-color: #fafafa;
    padding: 5px;
    margin-top: 15px;
    line-height: 19px;
    display:grid;
    grid-template-columns: max-content auto;
  }
.portal-option{
      text-align: left;
      border: #ccc solid 1px;
      border-radius: 10px;
      padding: 10px;
      max-width: 200px;
      height: fit-content;
      height: -moz-fit-content;
      height: -webkit-fit-content;
      cursor: pointer;
      min-width: 350px;
    }
    .portal-option>img{
      max-height: 50px;
      margin:auto;
          padding: 3px;
    }
    .portal-option:hover{
      /*background-color: #d3e3f0;*/
      background-color: #e0f5fd;
    }
#dash {
    height: calc(100% - 84px);
    position: relative;
    bottom: 0px;
    top: 0px;
    right: 0px;
    left: 0px;
    display: block;
  }
  #meeting-box {
    height: 100%;
  }
  #meeting-description {
    background-color: #fafafa;
    grid-template-columns: 1fr;
    text-align: center;
    grid-template-rows: repeat(4, max-content);
    height: fit-content;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    margin-top: 5%;
    border: 1px #f2f2f2 solid;
    border-radius: 2px;
  }
</style>
  <script>
  import Multiselect from 'vue-multiselect';
  import router from "../router"
export default {
  name: "RegionDash",
  components: {
      Multiselect,
  },
  watch: {
    '$i18n.locale': { 
      handler: function () {
        if(!this.isCorporateConnections && this.official==1){
          this.fields[0].label = this.$t('dashboard.time_header');
          this.fields[1].label = this.$t('dashboard.chapter_header');
          this.fields[2].label = this.$t('dashboard.size_header');
          this.fields[3].label = this.$t('dashboard.leadership_header');
          this.fields[4].label = this.$t('dashboard.speakers_header');
          this.fields[5].label = this.$t('dashboard.top_categories');
          this.fields[6].label = this.$t('dashboard.attendance_header');
          this.fields[7].label = this.$t('dashboard.manage_header');
        }else if(!this.isCorporateConnections && this.official!=1){
           this.fields[0].label = this.$t('dashboard.time_header');
          this.fields[1].label = this.$t('dashboard.chapter_header');
          this.fields[2].label = this.$t('dashboard.size_header');
          this.fields[3].label = this.$t('dashboard.speakers_header');
          this.fields[4].label = this.$t('dashboard.top_categories');
          this.fields[5].label = this.$t('dashboard.attendance_header');
          this.fields[6].label = this.$t('dashboard.manage_header');
        }else if(this.isCorporateConnections && this.official==1){
          this.fields[0].label = this.$t('dashboard.time_header');
          this.fields[1].label = this.$t('dashboard.chapter_header');
          this.fields[2].label = this.$t('dashboard.size_header');
          //this.fields[3].label = this.$t('dashboard.leadership_header');
          this.fields[3].label = this.$t('dashboard.speakers_header');
          this.fields[4].label = this.$t('dashboard.attendance_header');
          this.fields[5].label = this.$t('dashboard.manage_header');
        }else if(this.isCorporateConnections && this.official!=1){
          this.fields[0].label = this.$t('dashboard.time_header');
          this.fields[1].label = this.$t('dashboard.chapter_header');
          this.fields[2].label = this.$t('dashboard.size_header');
          this.fields[3].label = this.$t('dashboard.speakers_header');
          this.fields[4].label = this.$t('dashboard.attendance_header');
          this.fields[5].label = this.$t('dashboard.manage_header');
        }
        
      }
    }
  },
  data() {
    return {
      official: 0,
      select_contacts: [{id: 0, name: this.$t('dashboard.select_contact')}],
      official_region: 0,
      new_org: { admin_id: 0, org_id:0, org_name: "", admin_name: "", admin_pass: "", languages: [], laguage_admin: 0, onboard: 0, allow_logins: 1, event_chapter: 0,
          edmin_email: "", admin_retype: "", pull_parent: 1, exec_director: 0, ambassador: 0, director_consultant: 0, area_director: 0,was_disabled:0 },
      showNewOrg: false,
      showMoveChapter: false,
      showDeleteChapter: false,
      today: '',
      leaders: [{id: 0, name: this.$t('dashboard.select_contact')}],
      contacts: [{id: 0, name: this.$t('dashboard.select_contact')}],
      contactId: 0,
      leaderId: 0,
      showWeek: 0,
      dates: [],
      showUnderConstruction: false,
      regionOptions: [],
    organizations: [],
    regionType: 0,
      fields: [
        { key: 'start',
              label: this.$t('dashboard.time_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"},
              tdClass: "no-wrap"
            },
            { key: 'name',
              label: this.$t('dashboard.chapter_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"},
              tdClass: "bolded-text"
            },
            { key: 'size',
              label: this.$t('dashboard.size_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" , "text-align": "center" },
              tdClass: "actions-centre"
            },
             { key: 'speakers',
              label: this.$t('dashboard.speakers_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            },
            
             { key: 'attendance',
              label: this.$t('dashboard.attendance_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" , "text-align": "center"},
              tdClass: "actions-centre"
            },
            { key: 'actions',
              label: this.$t('dashboard.manage_header'),
              sortable: false,
              thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff", "width": "150px", "text-align": "center" },
              tdClass: "actions-centre"
            }
          ],
          typeOptions: [],
          languageOptions: [],
          popup: {}
    };
  },
  methods: {
    clearSearch(){
      this.showWeek = 0;
      this.contactId = 0;
      this.leaderId = 0;
    },
    getContactTypes(){
        this.$http.post("/api/contact/types/get", {only_assignable: true}).then((response) => {
          for(var j=0; j<response.data.types.length;j++){
              response.data.types[j].members = [];
            }
          this.typeOptions = response.data.types;
          
        }).catch(() => {
        });
      },
    hideSetup(){
        this.$http.post("/api/organization/onboard/update", {onboard: 0}).then((response) => {
          if (response.data.success) {
            this.permissions.organization.onboard = 0;
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
    goToSetupPage(page, anchor, popup){
      this.permissions.setup_popup = popup;
      router.push({path:page, hash: anchor});
    },
    setFields(){
      this.fields=[
        { key: 'start',
              label: this.$t('dashboard.time_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"},
              tdClass: "no-wrap"
            },
            { key: 'name',
              label: this.$t('dashboard.chapter_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"},
              tdClass: "bolded-text"
            },
            { key: 'size',
              label: this.$t('dashboard.size_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" , "text-align": "center" },
              tdClass: "actions-centre"
            },
            { key: 'speakers',
              label: this.$t('dashboard.speakers_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            },
            { key: 'attendance',
              label: this.$t('dashboard.attendance_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" , "text-align": "center"},
              tdClass: "actions-centre"
            },
            { key: 'actions',
              label: this.$t('dashboard.manage_header'),
              sortable: false,
              thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff", "width": "150px", "text-align": "center" },
              tdClass: "actions-centre"
            }
          ];
          if(!this.isCorporateConnections && this.official==1){
          this.fields.splice(3, 0, 
            { key: 'leadership',
              label: this.$t('dashboard.leadership_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            });
        }
        if(!this.isCorporateConnections){
          this.fields.splice(this.official==1? 5 : 4, 0, { key: 'categories',
            label: this.$t('dashboard.top_categories'),
            sortable: true,
            thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
          });
        }
    },
    loginToAlternate(reg){
      if(reg.disabled){
        return;
      }
      var value = reg.value;
      if(value != this.permissions.organization.id){
        this.$http.post("/api/login/toalternative", {id: value}).then((response) => {
            if(response.data.success){
              this.permissions.access = response.data.permissions.access;
              this.permissions.admin = response.data.permissions.admin;
              this.permissions.access = response.data.permissions.access;
              this.permissions.access = response.data.permissions.access;
              this.permissions.access = response.data.permissions.access;
              this.permissions.organization = response.data.permissions.organization;
              this.official = response.data.permissions.organization.official;
              this.setFields();
              this.permissions.user = response.data.permissions.user;
              document.title = this.$t('global.document_title_admin') +" "+ this.permissions.organization.name;
              if(this.permissions.organization.rst){
                router.push("/admin/dashboard");
              }else{
                this.getOrganizations();
                this.getAlternativeRegions();
              }
            }else if(response.data.error==1){
              this.showUnderConstruction = true;
            }
        }).catch((errors) => {console.log(errors);});
      }
    },
      goToScripts(id) {
        router.push("/admin/scripts/" + id);
      },
      goToAgenda(id) {
        router.push("/admin/agenda/" + id);
      },
      onPopoverShowLeader(leader){
        this.popup = {};
        this.$http.post("/api/member/getinfo/popup", {id: leader.id}).then((response) => {
            if(response.data.success){
              this.popup = response.data.member;
            }
        }).catch((errors) => {console.log(errors);});
      },
      onPopoverShowSpeaker(speaker){
        this.popup = {};
        this.$http.post("/api/speaker/getinfo/popup", {id: speaker.id}).then((response) => {
            if(response.data.success){
              this.popup = response.data.member;
            }
        }).catch((errors) => {console.log(errors);});
      },
      loginAsChapter(org){
        this.$http.post("/api/login/tochild", {id: org.id}).then((response) => {
            if(response.data.success){
              this.permissions.access = response.data.permissions.access;
              this.permissions.admin = response.data.permissions.admin;
              this.permissions.access = response.data.permissions.access;
              this.permissions.access = response.data.permissions.access;
              this.permissions.access = response.data.permissions.access;
              this.permissions.organization = response.data.permissions.organization;
              this.permissions.user = response.data.permissions.user;
              document.title = this.$t('global.document_title_admin') +" "+ this.permissions.organization.name;
              router.push("/admin/dashboard");
            }
        }).catch((errors) => {console.log(errors);});
      },
    getOrganizations() {
        this.$http.post("/api/organization/get/children/week", {}).then((response) => {
          /*for(var i=0; i<response.data.children.length;i++){
            var d = response.data.children[i];
            if(d.meet_start != null){
              d.next_meeting = " @ "+d.meet_start; //this.$t('general.day_option_'+(d.meet_day_of_week+1))+
            }
          }
          this.organizations = response.data.children;*/
          if(response.data.success){
            this.today = response.data.today;
            this.dates = response.data.dates;
            this.leaders = [];
            for(var i=0; i<this.dates.length; i++){
              for(var j=0; j<this.dates[i].chapters.length; j++){
                if(this.dates[i].chapters[j].leadership.length>0){
                  Array.prototype.push.apply(this.leaders, this.dates[i].chapters[j].leadership);
                }
              }
            }
            this.leaders.sort(function(a, b) {
                var textA = a.name.toUpperCase();
                var textB = b.name.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            this.leaders.unshift({id: 0, name: this.$t('dashboard.select_contact')});
          }
        }).catch(() => {
        });
      },
      getAlternativeRegions(){
        this.$http.post("/api/organization/get/alternatives", {}).then((response) => {
          if(response.data.success){
            this.official = response.data.official;
            this.setFields();
            this.regionOptions = [];
            for(var i=0; i<response.data.alternatives.length;i++){
              var a = response.data.alternatives[i];
              if(a.official == 1){
                this.regionOptions.push({value: a.id, text: this.$t('organizations.chapter_weekly'), disabled: false});
              }else if(a.official == 0){
                this.regionOptions.push({value: a.id, text: this.$t('organizations.startup_chapter'), disabled: this.isCorporateConnections});
              }else if(a.official == 2){
                this.regionOptions.push({value: a.id, text: this.isCorporateConnections? this.$t('organizations.director_meetings') : this.$t('organizations.support_team'), disabled: this.isCorporateConnections});
              }
            }
            this.regionType = response.data.region_type;
          }
        }).catch(() => {
        });
      },
       getContacts() {
        this.$http.post("/api/organization/contacts", {}).then((response) => {
            this.contacts = response.data.contacts;
            this.select_contacts = response.data.contacts.filter(function () {
              return true;
            });
             this.select_contacts.unshift({id: 0, name: this.$t('dashboard.select_contact')});
            
        }).catch((errors) => {console.log(errors);});
      },
      editOrganization(org) {
        this.$http.post("/api/organization/get/admin_info", {id: org.id}).then((response) => {
          if (response.data.success) {
            this.new_org.org_name = response.data.organization.name;
            this.new_org.admin_name = response.data.admin.name;
            this.new_org.admin_id = response.data.admin.id;
            this.new_org.org_id = response.data.organization.id;
            this.new_org.admin_pass = "";
            this.new_org.admin_email = response.data.admin.email;
            this.new_org.admin_retype = "";
            this.organization_edit = true;
            this.showNewOrg = true;
            this.new_org.was_disabled = response.data.organization.disabled;
            this.new_org.disabled = response.data.organization.disabled;
            this.new_org.cron_speakers = response.data.organization.cron_speakers;
            this.new_org.contacts = {};
            this.new_org.language_admin = response.data.organization.language_admin;
            this.new_org.onboard = response.data.organization.onboard;
            for(var j=0; j<this.typeOptions.length;j++){
              this.typeOptions[j].members = [];
            }
            var ids = response.data.organization.languages.split(",");
            this.new_org.languages = [];
            for(var i=0; i<this.languageOptions.length;i++){
                if(ids.indexOf(this.languageOptions[i].id+"")>=0){
                    this.new_org.languages.push(this.languageOptions[i]);
                }
            }
            this.$http.post("/api/organization/contacts/assigned/noauth", {org: org.id}).then((response) => {
                for(var i=0; i<response.data.contacts.length; i++){
                  for(var j=0; j<this.typeOptions.length;j++){
                      if(response.data.contacts[i].type_id == this.typeOptions[j].id){
                        this.typeOptions[j].members.push(response.data.contacts[i]);
                      }
                    }
                }
            }).catch((errors) => {console.log(errors);});
            
          }
        }).catch((e) => {console.log(e);
        });
      },
      updateAdmin() {
        this.showAlert = false;
        if (this.new_org.org_name.length == 0) {
          this.showAlert = true;
          this.alertText = this.$t('organizations.alert_chapter');
          return;
        }
        if (this.new_org.admin_name.length == 0) {
          this.showAlert = true;
          this.alertText = this.$t('organizations.alert_admin');
          return;
        }
        /*if (this.new_org.admin_email.length == 0) {
          this.showAlert = true;
          this.alertText = this.$t('organizations.alert_email');
          return;
        }*/
        if (this.new_org.admin_pass.length>0 && this.new_org.admin_pass != this.new_org.admin_retype) {
          this.showAlert = true;
          this.alertText = this.$t('organizations.alert_match');
          return;
        }
        this.alertText = "";
        this.new_org.contacts = this.typeOptions;

        var t = [];
        for(var i=0; i<this.new_org.languages.length;i++){
            t.push(this.new_org.languages[i].id);
        }
        this.new_org.languages = t.join(",");

        this.$http.post("/api/organization/update/admin_info", this.new_org).then((response) => {
          if (response.data.success) {
            this.organization_edit = false;
            this.showNewOrg = false;
            this.getOrganizations();
          } else if (response.data.error.errno == 1062) {
            this.showAlert = true;
            this.alertText = "The name '" + response.data.error.conflict + "' is already in use.";
          } else {
            this.showAlert = true;
            this.alertText = response.data.error;
          }
        }).catch(() => {
          this.$bvToast.show('serverError');
        });
      },
      saveOrg() {
        if (this.organization_edit) {
          this.updateAdmin();
          return;
        }
        this.showAlert = false;
        if (this.new_org.org_name.length == 0) {
          this.showAlert = true;
          this.alertText = this.$t('organizations.alert_chapter');
          return;
        }
        if (this.new_org.admin_name.length == 0) {
          this.showAlert = true;
          this.alertText = this.$t('organizations.alert_admin');
          return;
        }
        if (this.new_org.admin_pass.length == 0) {
          this.showAlert = true;
          this.alertText = this.$t('organizations.alert_password');
          return;
        }
        /*if (this.new_org.admin_email.length == 0) {
          this.showAlert = true;
          this.alertText = this.$t('organizations.alert_email');
          return;
        }*/
        if (this.new_org.admin_pass != this.new_org.admin_retype) {
          this.showAlert = true;
          this.alertText = this.$t('organizations.alert_match');
          return;
        }
        this.alertText = "";
        this.$http.post("/api/organization/new", this.new_org).then((response) => {
          if (response.data.success) {
            this.showNewOrg = false;
            this.getOrganizations();
          } else {
            this.showAlert = true;
            this.alertText = response.data.error;
          }
        }).catch(() => {
        });
      },
      moveChapter(){
        this.$http.post("/api/organization/child/makeofficial", {id: this.new_org.org_id}).then((response) => {
            if(response.data.success){
              this.getOrganizations();
            }
            this.showMoveChapter=false;
        }).catch((errors) => {console.log(errors); this.showMoveChapter=false;});
      },
      deleteChapter(){
        this.$http.post("/api/region/chapter/delete", {id: this.new_org.org_id}).then((response) => {
          if(response.data.success){
            //this.clearFilters();
            this.getOrganizations();
            this.showDeleteChapter = false;
          }else{
            this.showDeleteChapter = false;
          }
        }).catch(() => {
        });
      },
      getOfficialRegion(){
        this.$http.post("/api/region/get/officialregion", {}).then((response) => {
            if(response.data.success && response.data.official==0){
              this.official_region = response.data.official_region;
            }
        }).catch((errors) => {console.log(errors);});
      },
      getLanguages(){
        this.$http.post("/api/organization/languages/possible", {}).then((response) => {
          if (response.data.success === true) {
            this.languageOptions = response.data.languages;
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
  },
  created(){
    if(this.permissions.organization.rst){
      router.push("/admin/dashboard");
    }
  },
  destroyed() {
    this.removeClass(document.getElementById("menu-home"), "active-menu");
    this.$root.$emit('allowOptions', false);
  },
  mounted() {
    this.addClass(document.getElementById("menu-home"), "active-menu");
    this.getContacts();
    this.getContactTypes();
    this.getOfficialRegion();
    this.getLanguages();
    this.getOrganizations();
    this.getAlternativeRegions();
    this.$root.$emit('allowOptions', true);
  }
};
</script>
