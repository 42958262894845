<template>
  <div>
    <div class="rel-grid-container" style="max-width: 600px;">
      <div id="top-div"></div>
      <form v-on:submit.prevent="updateRegionalSettings" id="form-fix">
        <span class="form-box-title">{{$t('general.general_settings')}}</span>
        <div v-if="permissions.organization.official" class="form-box" style="grid-template-columns: max-content auto;">
          <SetupTip style="position:absolute; left: -40px;" :tipNumber="8"/>
          <span class="form-box-name">{{$t('region.name_text')}}</span><b-form-input :disabled="true" :state="regional.name.length==0? false : null" v-model="regional.name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;"  required></b-form-input>
            <span class="form-box-name">{{$t('general.year_end')}}</span>
            <div style="display:grid;grid-template-columns: max-content max-content;grid-gap: 20px;"><b-form-select  v-model="regional.month" :options="monthOptions" /><b-form-select  v-model="regional.day" :options="dayOptions"  /></div>
            <div v-if="!isCorporateConnections" style="display:grid;grid-column:1/3;grid-template-columns: repeat(5,max-content);">
                <b-form-checkbox style="margin:auto;" v-model="regional.transition_from_show" value="1" unchecked-value="0">
                  {{$t('general.show_transition')}}
                </b-form-checkbox>
              <span v-if="regional.transition_from_show==1" class="form-box-name">{{$t('general.from_text')}}</span>
              <div v-if="regional.transition_from_show==1"  style="display:grid;grid-template-columns: max-content max-content;grid-gap: 20px;margin-top: 5px;" ><b-form-select  v-model="regional.transition_month" :options="monthOptions" /><b-form-select  v-model="regional.transition_day" :options="dayOptions"  /></div>

              <span v-if="regional.transition_from_show==1"  class="form-box-name">{{$t('general.to_text')}}</span>
              <div v-if="regional.transition_from_show==1"  style="display:grid;grid-template-columns: max-content max-content;grid-gap: 20px;margin-top: 5px;" ><b-form-select  v-model="regional.transition_to_month" :options="monthOptions" /><b-form-select  v-model="regional.transition_to_day" :options="dayOptions"  /></div>
            </div>
             <span class="form-box-name" style="margin: auto 5px;">{{$t('general.days_closed')}}</span>
            <multiselect style="margin: 5px 0px;" :noOptions="$t('global.empty_list')" :class="{blueselect:true}" :selectedLabel="$t('global.selected_text')" selectLabel="" deselectLabel="" v-model="regional.banned_weeks" :options="weeks" :multiple="true" :close-on-select="false"  :preserve-search="true" :placeholder="$t('general.select_days')" label="text" track-by="id" :preselect-first="false">
            </multiselect>
            
        </div>
        <span class="form-box-title" v-if="permissions.organization.official">{{$t('general.meeting_settings')}}</span>
        <div v-if="permissions.organization.official" class="form-box" style="grid-template-columns: max-content auto;">
          <span class="form-box-name">{{$t('region.event_link')}}</span><b-form-input  v-model="regional.event_link" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" ></b-form-input>
            <span v-if="!isCorporateConnections"  class="form-box-name" style="grid-column: 1;">{{$t('general.report_periods')}}</span>
            <multiselect v-if="!isCorporateConnections"  :noOptions="$t('global.empty_list')" :style="{border : tracking_nums.length == 0? '1px solid red' : ''}" :class="{blueselect:true}" style="margin-top: 5px;" :selectedLabel="$t('global.selected_text')" selectLabel="" deselectLabel="" v-model="tracking_nums" :options="trackingOptions" :multiple="true" :close-on-select="false"  :preserve-search="true" :placeholder="$t('general.select_periods')" label="text" track-by="value" :preselect-first="false">
            </multiselect>
            <span v-if="!isCorporateConnections"  class="form-box-name">{{$t('general.categories_num')}}</span>
            <div v-if="!isCorporateConnections"  style="display:grid;grid-template-columns: max-content auto;">
              <b-form-input v-model="regional.categories_num" type="number" style="width:100px;margin-top: 3px;" />
              <div style="font-size:14px;color:#aaaaaa;margin:10px; margin-right:0px;text-align:left;">*{{$t('general.categories_note')}}</div>
            </div>
            <span class="form-box-name">{{$t('general.qr_code')}}</span><b-form-input  v-model="regional.apply_url" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;" ></b-form-input>
            <span class="form-box-name">{{$t('general.featured_presentation')}}</span>
            <div style="display:grid; grid-template-columns: max-content max-content;">
              <div class="time-box">
                <span class="form-box-name">{{$t('general.min_text')}}</span>
                <b-form-input v-model="regional.regional_presentation_min" type="number" style="width:100px;" />
              </div>
              <div class="time-box">
                <span class="form-box-name">{{$t('general.sec_text')}}</span>
                <b-form-input v-model="regional.regional_presentation_sec" type="number" style="width:100px;" />
              </div>
            </div>
            <span class="form-box-name" v-if="isCorporateConnections">{{$t('region.assistant_expiry_minutes')}}</span>
            <b-form-input v-if="isCorporateConnections" v-model="regional.assistant_expiry_minutes" type="number" min="0" style="width:100px;"></b-form-input>
            <span v-if="!isCorporateConnections"  class="form-box-name" style="margin: 0 5px;">{{$t('region.meeting_content')}}</span>
            <div v-if="!isCorporateConnections" >
              <b-form-checkbox v-model="regional.allow_powerteams" :value="1" :unchecked-value="0" style="grid-column:2/3;margin: auto 0;">
                {{$t('general.enable_powerteams')}}
              </b-form-checkbox>
              <b-form-checkbox v-model="regional.allow_top_performing" :value="1" :unchecked-value="0" style="grid-column:2/3;margin: auto 0;">
                {{$t('general.enable_performers')}}
              </b-form-checkbox>
              <b-form-checkbox v-model="regional.use_taglines" :value="1" :unchecked-value="0" style="grid-column:2/3;margin: auto 0;">
                {{$t('general.use_taglines')}}
              </b-form-checkbox>
              <span class="form-box-name" style="margin:0;"></span>
              <div style="display:grid; grid-template-columns: max-content max-content;">
                <span class="form-box-name" v-if="regional.use_taglines==1">{{$t('region.tagline_chars')}}</span>
                <b-form-input v-if="regional.use_taglines==1" v-model="regional.tagline_chars" type="num" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 100px;" ></b-form-input>
              </div>
              <b-form-checkbox v-model="regional.split_referrals" :value="1" :unchecked-value="0" style="grid-column:2/3;margin: auto 0;">
                {{$t('region.split_referrals')}}
              </b-form-checkbox>
              <b-form-checkbox v-if="regional.split_referrals==1" v-model="regional.show_single_referrals" :value="1" :unchecked-value="0" style="grid-column:2/3;margin: auto 0;">
                {{$t('region.show_single_referrals')}}
              </b-form-checkbox>
              <b-form-checkbox v-model="regional.allow_renewing_date" :value="1" :unchecked-value="0" style="grid-column:2/3;margin: auto 0;">
                {{$t('region.allow_renewing_date')}}
              </b-form-checkbox>
              <b-form-checkbox v-model="regional.allow_logos" :value="1" :unchecked-value="0" style="grid-column:2/3;margin: auto 0;">
                {{$t('region.allow_logos')}}
              </b-form-checkbox>
              <b-form-checkbox v-model="regional.show_bni_accent" :value="1" :unchecked-value="0" style="grid-column:2/3;margin: auto 0;">
                {{$t('region.show_bni_accent')}}
              </b-form-checkbox>
              <b-form-checkbox v-model="regional.require_consent" :value="1" :unchecked-value="0" style="grid-column:2/3;margin: auto 0;">
                {{$t('region.require_consent')}}
              </b-form-checkbox>
              <b-form-checkbox v-if="permissions.organization.subscription_status==1" v-model="regional.allow_visitor_photos" :value="1" :unchecked-value="0" style="grid-column:2/3;margin: auto 0;">
                {{$t('region.allow_visitor_photos')}}
              </b-form-checkbox>
              <b-form-checkbox v-model="regional.allow_joint" :value="1" :unchecked-value="0" style="grid-column:2/3;margin: auto 0;">
                {{$t('region.allow_joint')}}
              </b-form-checkbox>
              <b-form-checkbox v-model="regional.send_top_cat" :value="1" :unchecked-value="0" style="grid-column:2/3;margin: auto 0;">
                {{$t('region.send_top_cat')}}
              </b-form-checkbox>
              <!--<b-form-checkbox v-model="regional.enable_planner" :value="1" :unchecked-value="0" style="grid-column:2/3;margin: auto 0;">
                {{$t('region.send_top_cat')}}
              </b-form-checkbox>-->
              <b-form-checkbox v-model="regional.enable_planner_confirmation" :value="1" :unchecked-value="0" style="grid-column:2/3;margin: auto 0;">
                {{$t('region.enable_planner_confirmation')}}
              </b-form-checkbox>
            </div>
            <b-form-checkbox v-if="isCorporateConnections" v-model="regional.require_consent" :value="1" :unchecked-value="0" style="grid-column:2/3;margin: auto 0;">
                {{$t('region.require_consent')}}
              </b-form-checkbox>
            <b-form-checkbox v-if="isCorporateConnections" v-model="regional.allow_visitor_photos" :value="1" :unchecked-value="0" style="grid-column:2/3;margin: auto 0;">
                {{$t('region.allow_visitor_photos')}}
              </b-form-checkbox>
        </div>
        <div v-if="!permissions.organization.official" class="form-box" style="grid-template-columns: max-content auto;">
          <span class="form-box-name">{{$t('region.name_text')}}</span><b-form-input :disabled="true" :state="regional.name.length==0? false : null" v-model="regional.name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;"  required></b-form-input>
          <span class="form-box-name">{{$t('region.min_applicants')}}</span><b-form-input v-model="regional.min_applicants" type="number" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;"></b-form-input>
          <b-form-checkbox v-model="regional.applicant_photos" :value="1" :unchecked-value="0" style="grid-column:2/3;margin: auto 0;">
              {{$t('region.applicant_photos')}}
            </b-form-checkbox>
          <div style="display:grid;grid-template-columns: max-content auto;grid-column:1/3;">
            <span class="form-box-name" style="grid-column: 1/3;text-align:left;">{{$t('region.weekly_requests')}}</span>
            <span class="form-box-name">{{$t('region.block_title')}}</span><b-form-input style="width:300px;" v-model="regional.newsletter_ask_title" type="text" pattern="[^<>]*" max="255" :title="$t('global.pattern_title')"></b-form-input>
            <b-form-checkbox v-model="regional.newsletter_ask_column" :value="0" :unchecked-value="1" >
                {{$t('region.list_city')}}
              </b-form-checkbox>
              <b-form-checkbox v-model="regional.newsletter_ask_column" :value="1" :unchecked-value="0" style="margin-left:30px;">
                {{$t('region.list_company')}}
              </b-form-checkbox>
            </div>
        </div>

        <div v-if="isCorporateConnections" >
            <span class="form-box-title">{{$t('general.region_mission')}}</span>
            <div class="form-box " style="grid-template-columns:auto;position:relative;">
                <b-form-textarea v-model="regional.mission_statement" placeholder="" :rows="3"/>
            </div>
          </div>

        <div v-if="permissions.organization.official" >
            <span class="form-box-title">{{$t('general.social_media_links')}}</span>
            <div class="form-box " style="grid-template-columns:auto;grid-gap:20px;">
              <div  style="display: grid; grid-template-columns: auto 400px;">
                <span class="form-box-name">{{$t('general.add_channel')}}</span>
                <multiselect :noOptions="$t('global.empty_list')" style="min-width:400px;" :class="{blueselect:true}"  :selectedLabel="$t('global.selected_text')" selectLabel="" deselectLabel="" v-model="media_links" :options="mediaOptions" :multiple="true" :close-on-select="false"  :preserve-search="true" :placeholder="$t('general.select_media')" label="name" track-by="channel_id" :preselect-first="false">
                  <template slot="option" slot-scope="props">
                    <div><img style="height:40px;" :src="props.option.image"> <span>{{ props.option.name  }}</span></div>
                  </template>
                </multiselect>
                <span class="form-box-name" style="grid-column:1/3;text-align:center;">{{$t('general.selected_media')}}</span>
                <table class="table b-table  table-sm table-hover-new " style="grid-column:1/3;">
                  <thead>
                    <tr>
                      <th >{{$t('general.channel_header')}}</th>
                      <th  style="text-align: left;" >{{$t('general.url_header')}}</th>
                      <th>{{$t('general.remove_header')}}</th>
                    </tr>
                  </thead>
                  <draggable tag="tbody" style="grid-column:1/3;" v-model="media_links">
                      <tr v-for="m in media_links" :key="m.id">
                        <td><img style="height:40px;" :src="m.image"></td>
                        <td><b-form-input v-model="m.link" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 200px;" maxlength="255"></b-form-input></td>
                        <td><b-button class="trash-button" variant="blue" v-on:click="deleteLink(m)"></b-button></td>
                      </tr>
                  </draggable>
                </table>
              </div>
            </div>
          </div>

        <span v-if="permissions.organization.official" class="form-box-title">{{$t('region.admin_logins')}}</span>
        <div v-if="permissions.organization.official" id="region-box" class="form-box" style="grid-template-columns: repeat(1,1fr);">
            <span class="form-box-name" style="text-align:left;">{{$t('region.chapter_meetings')}}</span>
            <div style="display:grid;grid-gap: 5px;">
              <b-form-input :disabled="true" :state="admin.chapter.name.length>0? null : false" v-model="admin.chapter.name" :placeholder="this.$t('global.username_text')" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;"  required></b-form-input>
              <b-form-input v-model="admin.chapter.email" :placeholder="this.$t('global.email_text')" type="email" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;"  ></b-form-input>
              <b-form-input :state="(admin.chapter.password.length==0 || admin.chapter.password.length>8)? null : false" v-model="admin.chapter.password" :placeholder="this.$t('global.password_text')" type="password" style="max-width: 300px;" ></b-form-input>
              <b-form-input v-if="admin.chapter.password && admin.chapter.password.length>0" :placeholder="this.$t('global.retype_password')" style="max-width: 300px;" :state="admin.chapter.password===admin.chapter.password_confirm && admin.chapter.password_confirm.length>0" v-model="admin.chapter.password_confirm" type="password"  ></b-form-input>
            </div>
            <br/>
            <span v-if="admin.has_startup==1" class="form-box-name" style="text-align:left;">{{$t('region.startup_meetings')}}</span>
            <div v-if="admin.has_startup==1" style="display:grid;grid-gap: 5px;">
              <b-form-input :disabled="true" :state="admin.startup.name.length>0? null : false" v-model="admin.startup.name" :placeholder="this.$t('global.username_text')" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;"  required></b-form-input>
              <b-form-input v-model="admin.startup.email" :placeholder="this.$t('global.email_text')" type="email" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;"  ></b-form-input>
              <b-form-input v-model="admin.startup.password" :state="(admin.startup.password.length==0 || admin.startup.password.length>8)? null : false" :placeholder="this.$t('global.password_text')" type="password" style="max-width: 300px;"  ></b-form-input>
              <b-form-input v-if="admin.startup.password && admin.startup.password.length>0" :placeholder="this.$t('global.retype_password')"  style="max-width: 300px;" :state="admin.startup.password===admin.startup.password_confirm && admin.startup.password_confirm.length>0" v-model="admin.startup.password_confirm" type="password" ></b-form-input>
            </div>
            <br/>
            <span v-if="admin.has_rst==1" class="form-box-name" style="text-align:left;">{{$t('region.rst_meetings')}}</span>
            <div v-if="admin.has_rst==1" style="display:grid;grid-gap: 5px;">
              <b-form-input :disabled="true" :state="admin.rst.name.length>0? null : false" v-model="admin.rst.name" :placeholder="this.$t('global.username_text')" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;"  required></b-form-input>
              <b-form-input v-model="admin.rst.email" :placeholder="this.$t('global.email_text')" type="email" pattern="[^<>]*" :title="$t('global.pattern_title')" style="max-width: 300px;" ></b-form-input>
              <b-form-input v-model="admin.rst.password" :state="(admin.rst.password.length==0 || admin.rst.password.length>8)? null : false" :placeholder="this.$t('global.password_text')" type="password" style="max-width: 300px;"  ></b-form-input>
              <b-form-input v-if="admin.rst.password && admin.rst.password.length>0" :placeholder="this.$t('global.retype_password')" style="max-width: 300px;" :state="admin.rst.password===admin.rst.password_confirm && admin.rst.password_confirm.length>0" v-model="admin.rst.password_confirm" type="password"  ></b-form-input>
            </div>
         </div>

         

            <div v-if="showAlert">
            <b-alert variant="danger"
                    dismissible
                    :show="showAlert"
                    @dismissed="showAlert=false">
              {{alertText}}
            </b-alert>
          </div>
            <div class="submit-box-2">
            <b-button class="submit-button4" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="submit-button4" type="submit" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
        </div>
      </form>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
  #member-photo {
    max-height: 50px;
    max-width: 100px;
    border: 1px gray solid;
  }
</style>
<script>
    import Multiselect from 'vue-multiselect';
    import draggable from "vuedraggable";
  import router from "../router"
    export default {
    name: "Regional",
   components: {draggable ,Multiselect},
      data() {
        return {
          media_links: [],
          mediaOptions: [],
          admin: {has_startup: 1, has_rst: 1, email: '', chapter: {org_id: 0,id: 0, name: '',email: '', password: '', has_pass: false}, startup: {org_id: 0,id: 0, name: '',email: '', password: '', has_pass: false}, rst: {org_id: 0,id: 0, name: '',email: '', password: '', has_pass: false}},
          showDelete: false,
          saveVariant: "blue",
          regional: {enabled_speaker_planner:0,assistant_expiry_minutes: 30, transition_from_show: 1, show_bni_accent: 0, allow_visitor_photos: 0, allow_renewing_date: 0, allow_logos: 1, allow_top_performing: 0, official_region: 0,regional_presentation_min: 10, regional_presentation_sec: 0, applicant_photos: 1, min_applicants: 25, categories_num: 0, name: '', event_link: '',banned_weeks: [], year_end: null, discounted_dates: "", name_state:null, month: 4, day: 1, tracking_periods: 0, transition_month: 1, transition_day: 1, transition_to_month: 1, transition_to_day: 1, newsletter_ask_title: 'Weekly Requests and Referrals', newsletter_ask_column: 0},
          weeks: [],
          monthOptions: [{value: 1, text: this.$t('global.january')},{value: 2, text: this.$t('global.february')},{value: 3, text: this.$t('global.march')},
          {value: 4, text: this.$t('global.april')},{value: 5, text: this.$t('global.may')},{value: 6, text: this.$t('global.june')},
          {value: 7, text: this.$t('global.july')},{value: 8, text: this.$t('global.august')},{value: 9, text: this.$t('global.september')},
          {value: 10, text: this.$t('global.october')},{value: 11, text: this.$t('global.november')},{value: 12, text: this.$t('global.december')}],
          trackingOptions: [
          { value: 1, text: this.$t('general.tracking_option_1') },
          { value: 2, text: this.$t('general.tracking_option_2') },
          { value: 8, text: this.$t('general.tracking_option_8') },
          { value: 16, text: this.$t('general.tracking_option_16') },
          { value: 32, text: this.$t('general.tracking_option_32') },
          { value: 64, text: this.$t('general.tracking_option_64') },
        ],
        tracking_nums: [],
        showAlert: false,
        alertText: "",
        showPhotoEdit: false,
        tempPhoto: "",
        imgSrc: '',
        }
      },
       computed: {
        dayOptions: function () {
          var temp = []
          for(var i = 1;i<=31;i++){
            if((this.regional.month==2 && i==29) || ([4,6,9,11].indexOf(this.regional.month) !== -1 && i==31)){
              break;
            }
            temp.push({value: i, text: i+""});
          }
          return temp;
        }
      },
        methods: {
          /*rotateImageLeft(){
        this.$refs.cropper.rotate(90);
        this.cropMove();
      },
      rotateImageRight(){
        this.$refs.cropper.rotate(-90);
        this.cropMove();
      },
      flipImageX(){
        var d = this.$refs.cropper.getData()
        this.$refs.cropper.scaleX(d.scaleX*-1);
        this.cropMove();
      },
      flipImageY(){
        var d = this.$refs.cropper.getData()
        this.$refs.cropper.scaleY(d.scaleY*-1);
        this.cropMove();
      },
          openDialog() {
            document.getElementById('fileid').click();
      },
          clearPhoto(){
        this.imgSrc = '';
        this.regional.qr_code = null;
        this.tempPhoto = '';
      },
      openPhotoEdit(){
        this.showPhotoEdit=true
        if(this.imgSrc && this.imgSrc.length>0){
          this.cropMove();
        }
      },
      cropMove() {
        this.tempPhoto = this.$refs.cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.8);
      },
      setImage(e) {
        const file = e.target.files[0];
        if (!file.type.includes('image/')) {
          alert('Please select an image file');
          return;
        }
        if (typeof FileReader === 'function') {
          var reader = new FileReader();
          var self = this;
          reader.onload = function(e) {
            self.imgSrc =e.target.result;
            self.$refs.cropper.replace(e.target.result);
            setTimeout(function () {
              self.tempPhoto = self.$refs.cropper.getCroppedCanvas().toDataURL(file.type, 0.8);
            }, 200);
          }
          reader.readAsDataURL(file);
        } else {
          alert('Sorry, FileReader API not supported');
        }
      },
      cropImage() {
        if(this.$refs.cropper.getCroppedCanvas()){
          this.regional.qr_code = this.$refs.cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.8);
        }else{
          this.regional.qr_code= null;
        }
        this.showPhotoEdit = false;
      },*/
      customLabel ({ channel_id }) {
        return this.$t('general.mediaOption'+channel_id);
      },
      deleteLink(m){
        var index = this.media_links.indexOf(m);
        if (index > -1) {
          this.media_links.splice(index, 1);
        }
      },
          getRegionalSettings() {
            this.$http.post("/api/organization/regional/settings/get", {}).then((response) => {
            if (response.data.success) {
              this.media_links = response.data.regional.media_links;
              response.data.regional.banned_weeks = [];
              this.admin = response.data.admin;
              var date = response.data.regional.year_end? response.data.regional.year_end.split("-") : [];
              response.data.regional.day = date.length>2? parseInt(date[2]) : 1;
              response.data.regional.month  = date.length>1? parseInt(date[1]) : 1;

               date = response.data.regional.transition_from? response.data.regional.transition_from.split("-") : [];
              response.data.regional.transition_day = date.length>2? parseInt(date[2]) : 1;
              response.data.regional.transition_month  = date.length>1? parseInt(date[1]) : 1;

              date = response.data.regional.transition_to? response.data.regional.transition_to.split("-") : [];
              response.data.regional.transition_to_day = date.length>2? parseInt(date[2]) : 1;
              response.data.regional.transition_to_month  = date.length>1? parseInt(date[1]) : 1;

                this.regional = response.data.regional;
                var ids = this.regional.discounted_dates? this.regional.discounted_dates.split(",") : [];
                
                for(var i=0; i<this.weeks.length;i++){
                  if(ids.indexOf(this.weeks[i].id+"")>=0){
                      this.regional.banned_weeks.push(this.weeks[i]);
                  }
                }

                this.tracking_nums = [];
                for(i=0; i<this.trackingOptions.length;i++){
                  if((this.regional.tracking_periods & this.trackingOptions[i].value) != 0){
                    this.tracking_nums.push(this.trackingOptions[i]);
                  }
                }
                    
            }
            }).catch((e) => {console.log(e);});
          },
          updateRegionalSettings() {
            this.showAlert = false;
            var allgood = true;
            if(this.tracking_nums.length==0){
              allgood = false;
            }
            if(this.regional.name.length == 0){
              allgood = false;
            }
            if(this.permissions.organization.official){
              if((this.admin.chapter.password!==this.admin.chapter.password_confirm && this.admin.chapter.password && this.admin.chapter.password.length>0)){
                  this.states.pass = false;
                  allgood = false;
              }
              if((this.admin.startup.password!==this.admin.startup.password_confirm && this.admin.startup.password && this.admin.startup.password.length>0)){
                  allgood = false;
              }
              if((this.admin.rst.password!==this.admin.rst.password_confirm && this.admin.rst.password && this.admin.rst.password.length>0)){
                  allgood = false;
              }
            }
            if(!allgood){
              this.showAlert = true;
              this.alertText = this.$t("general.alert_text")
            }
            var t = [];
            for(var i=0; i<this.regional.banned_weeks.length;i++){
                t.push(this.regional.banned_weeks[i].id);
            }

            this.regional.tracking_periods = 0;
            for(i=0; i<this.tracking_nums.length;i++){
              this.regional.tracking_periods |= this.tracking_nums[i].value;
            }

            this.regional.year_end = "2020-"+this.regional.month+"-"+this.regional.day;
            this.regional.transition_from = "2020-"+this.regional.transition_month+"-"+this.regional.transition_day;
            this.regional.transition_to = "2020-"+this.regional.transition_to_month+"-"+this.regional.transition_to_day;
            this.regional.discounted_dates = t.join(",");

            if(this.regional.split_referrals==0){
              this.regional.show_single_referrals = 0;
            }
            this.regional.media_links = this.media_links;
            this.$http.post("/api/organization/regional/settings/update", {regional:this.regional, admin: this.admin}).then((response) => {
              if (response.data.success === true) {
                this.saveVariant = 'success';
                this.back();
              } else {
                this.saveVariant = 'danger';
              }
            }).catch(() => {this.$bvToast.show('serverError');});
          },
          back(){
            window.history.length > 1 ? router.go(-1) : router.push("/admin/regiondash");
          },
          buildWeeks(){
              var y_start = new Date("1/1/2020");
              this.weeks = [];
              for(var i=1; i<=366; i++){
                  this.weeks.push({id: i, text: this.$d(y_start, 'day_month_short')});
                  y_start.setDate(y_start.getDate() + 1);
              }
          },
          getMediaOptions() {
        this.$http.post("/api/media/types/get", {}).then((response) => {
          this.mediaOptions = response.data.types;
        }).catch(() => {});
      },
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-regional"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-regional"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      
      if(this.isCorporateConnections){
        this.mediaOptions.push({channel_id: 1, link:""});
      }
      this.buildWeeks();
      this.getRegionalSettings();
      this.getMediaOptions();
      
    }
    }
</script>
