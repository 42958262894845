<template>
  <div>
    <div class="rel-grid-container">
      <div id="top-div"></div>
      <div >
        <b-table class="table-hover-new clickable" small bordered :items="tips" :fields="fields" v-on:row-clicked="editTip">
          <template v-slot:cell(content)="data">
            <pre class="pre-format" v-html="data.item.content"></pre>
          </template>
         
        </b-table>
        <b-modal id="slide-modal-2" centered v-model="showTipEdit" :title="$t('tips.edit_tip')">
            <div>
              <span class="form-box-title">{{$t('tips.edit_tip')}}</span>
              <div class="form-box" >
                  <span class="form-box-name">{{$t('tips.title_header')}}</span><b-form-input v-model="currentTip.title" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
                <!--<span class="form-box-name">{{$t('tips.width_header')}}</span><b-form-input v-model="currentTip.width" type="number" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>-->
               <b-form-checkbox v-model="currentTip.disabled" class="form-box-input" value="1" unchecked-value="0" style="text-align:left;margin-bottom:20px;">
                {{$t('tips.disabled_text')}}
                </b-form-checkbox>
                <div class="editor" style="grid-column:1/4;">
                  <editor-menu-bar :editor="editor">
                    <div class="menubar" slot-scope="{ commands, isActive }">
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.bold() }" v-on:click="commands.bold">
                        <b>B</b>
                      </b-button>
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.italic() }" v-on:click="commands.italic">
                        <i>I</i>
                      </b-button>
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.strike() }" v-on:click="commands.strike">
                        <s>S</s>
                      </b-button>
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.underline() }" v-on:click="commands.underline">
                        <u>U</u>
                      </b-button>
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.paragraph() }" v-on:click="commands.paragraph">
                        P
                      </b-button>
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.superscript() }" v-on:click="commands.superscript()">
                        super<sup>script</sup>
                      </b-button>
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.heading({ level: 1 }) }" v-on:click="commands.heading({ level: 1 })">
                        H1
                      </b-button>
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.heading({ level: 2 }) }" v-on:click="commands.heading({ level: 2 })">
                        H2
                      </b-button>
                      
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.bullet_list() }" v-on:click="commands.bullet_list">
                        {{$t('entry.bullet_text')}}
                      </b-button>
                      <b-button variant="outline-dark" :class="{ 'is-active': isActive.ordered_list() }" v-on:click="commands.ordered_list">
                        {{$t('entry.list_text')}}
                      </b-button>
                      <b-button variant="outline-dark" v-on:click="commands.undo">
                        {{$t('entry.undo_text')}}
                      </b-button>
                      <b-button variant="outline-dark" v-on:click="commands.redo">
                        {{$t('entry.redo_text')}}
                      </b-button>
                    </div>
                  </editor-menu-bar>
                  <editor-content class="editor__content" :editor="editor" style="height:300px;width:100%;overflow-y: auto;overflow-x: hidden;" />
                </div>
            </div>
            <div :style="{width: currentTip.width+'px', 'margin': 'auto'}">
                <div style="margin-top: 20px;text-align:left">
                    <div class="pre-format" v-html="editor.getHTML()">></div>
                </div>
            </div>
            </div>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="submit-button4" v-on:click="updateTip" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
        </b-modal>
      </div> 
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
  
</style>
<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
  import Superscript from '@/assets/Superscript'
  import {
    Blockquote,
    HardBreak,
    Heading,
    HorizontalRule,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Italic,
    Link,
    Strike,
    Underline,
    History
  } from 'tiptap-extensions'
    export default {
    name: "OnboardTips",
     components: {
      EditorContent,
      EditorMenuBar,
    },
    watch:{
        '$i18n.locale': { 
        handler: function () {
            this.getTips();
        },
        deep: true
        }
    },
    data() {
      return {
           editor: new Editor({
            disableInputRules: true,
            disablePasteRules: true,
            extensions: [
              new Blockquote(),
              new BulletList(),
              new HardBreak(),
              new Heading({ levels: [1, 2, 3] }),
              new HorizontalRule(),
              new ListItem(),
              new OrderedList(),
              new TodoItem(),
              new TodoList(),
              new Bold(),
              new Italic(),
              new Link(),
              new Strike(),
              new Underline(),
              new History(),
              new Superscript()
            ],
            content: ''
          }),
          showTipEdit: false,
        tips: [],
        moving:false,
        currentTip: {},
        currentTipIndex: -1,
        fields: [
            {
              key: 'tag',
              label: this.$t('tips.tag_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
            { key: 'title',
              label: this.$t('tips.title_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            },
            
            // { key: 'width',
            //  label: this.$t('tips.width_header'),
           //   thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff", "text-align":"center" },
           //   tdClass: "actions-centre"
           // },
             { key: 'content',
              label: this.$t('tips.content_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            },
          ],
      }
    },
    methods: {
        updateTip(){
            
            this.currentTip.content = this.editor.getHTML();
            this.$http.post("/api/superadmin/tips/update", this.currentTip).then((response) => {
                this.showTipEdit = false;
                if (response.data.success) {
                    this.tips[this.currentTipIndex] = this.currentTip;
                    this.currentTip = {}
                }
            }).catch(() => {
                this.showTipEdit = false;
            });

        },
      getTips() {
        this.$http.post("/api/superadmin/onboardtips", {}).then((response) => {
          if (response.data.success) {
            this.tips = response.data.tips;
          }
        }).catch(() => {
        });
      },
      editTip(item,index) {
          this.currentTip = item;
          this.currentTipIndex = index;
          this.editor.setContent(this.currentTip.content);
            this.showTipEdit = true;
      }
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-onboardtips"), "active-menu");
      this.removeClass(document.getElementById("menu-admin"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-onboardtips"), "active-menu");
      this.addClass(document.getElementById("menu-admin"), "active-menu");
      this.getTips();
    }
    }
</script>
