<template>
  <div>
    <div class="rel-grid-container">
      <div id="top-div"></div>
      <div style="max-width:1000px;margin:auto;">
        <span class="form-box-title">{{$t('subscription.subscription_summary')}}</span>
        <div  class="form-box" style="grid-template-columns: 1fr 1fr;color:black;grid-row-gap: 10px;position:relative;padding:40px;">
            <div></div>
            <div>
                <div v-on:click="goToSubscription" class="edit-button clickable" style="position: absolute;right: 10px;top: 10px;"></div>
            </div>
            <div style="display: grid;" class="fit-height">
                <span>{{subscription_info.customer.name}}</span>
                <span>{{subscription_info.customer.email}}</span>
                <span>{{subscription_info.customer.phone}}</span>
            </div>

            <div style="display: grid;" class="fit-height">
                <span style="text-decoration:underline;color: #999;">{{$t('subscription.payment_method')}}:</span>
                <span v-if="subscription_info.card.id.length==0" style="color:black;">{{$t('subscription.no_method')}}</span>
                <span v-if="subscription_info.card.id.length>0" style="color:black;">{{subscription_info.card.brand+ " "+$t('subscription.ending_in')+" "+subscription_info.card.last4}}</span>
                <span v-if="subscription_info.card.id.length>0" style="color:black;">{{$t('subscription.expires_in')+" "+subscription_info.card.exp_month+"/"+subscription_info.card.exp_year}}</span>
                <span v-if="subscription_info.card.id.length>0" style="color:black;">{{$t('subscription.token_id')+" "+subscription_info.card.id}}</span>
            </div>

            <div style="display: grid;" class="fit-height">
                <span style="text-decoration:underline;color: #999;">{{$t('subscription.subscription_plan')}}:</span>
                <span v-if="subscription_info.sub_plan.billing_price==0" style="color:black;">{{$t('subscription.no_subscription')}}</span>
                <span v-if="subscription_info.sub_plan.billing_price>0">{{$t('subscription.rate_text')+": $"+subscription_info.sub_plan.billing_price*(100-(subscription_info.sub_plan.global_discount+subscription_info.sub_plan.country_discount))/100}}</span>
                <span v-if="subscription_info.sub_plan.billing_price>0 && subscription_info.chapter_pays==1">{{$t('subscription.cost_split')+": $"+subscription_info.chapter_price+" "+$t('subscription.to_chapter')}}</span>
                <span v-if="subscription_info.sub_plan.billing_price>0" v-html="$t('subscription.renews_text')"></span>
            </div>

            <div style="display: grid;" class="fit-height">
                <span style="text-decoration:underline;color: #999;">{{$t('subscription.billing_address')}}:</span>
                <span v-if="subscription_info.customer.address.line1.length==0" style="color:black;">{{$t('subscription.no_billing')}}</span>
                <span v-if="subscription_info.customer.address.line1.length>0">{{subscription_info.customer.address.line1}}</span>
                <span v-if="subscription_info.customer.address.line1.length>0">{{subscription_info.customer.address.city+", "+subscription_info.customer.address.state + " " + subscription_info.customer.address.postal_code}}</span>
            </div>
        </div>
        <div style="display:grid;font-size:18px;">
            <div v-for="i in invoices.filter(v => v.status == 1)" :key="i.id"  style="margin:20px 50px;">
                <div>{{$t('subscription.payment_declined', {id: i.id})}}</div>
                <div>{{$t('subscription.retry_payment')}}</div>
            </div>
        </div>
        <span class="form-box-title">{{$t('subscription.invoices_text')}}</span>
        <div  class="form-box" style="grid-template-columns: auto;">
            <b-table :per-page="perPage" :current-page="currentPage" :items="invoices" :fields="fields" style="width:100%;">
            <template v-slot:cell(id)="data">
              <b-button variant="link" style="padding:0px;" v-on:click="showInvoice(data.item.id)">{{data.item.id}}</b-button>
            </template>
            <template v-slot:cell(amount)="data">
              {{(data.item.status==2? '-' : '') +data.item.amount}}
            </template>
            <template v-slot:cell(description)="data">
              {{$d(new Date(data.item.descript_day + " 00:01"), 'month_year') + " " + (data.item.status!= 2? $t('subscription.chapter_monthly') : $t('subscription.chapter_refund'))+(permissions.organization.is_parent? " - "+data.item.chapters+" " : "")+$t('subscription.chapters_text')}}
            </template>
            <template v-slot:cell(status)="data">
              <b-button v-if="data.item.status==1" variant="link" v-on:click="retryInvoice(data.item.id)">{{$t('subscription.retry_text')}}</b-button>
              <span v-if="data.item.status==0">{{$t('subscription.paid_text')}}</span>
              <span v-if="data.item.status==2">{{$t('subscription.refund_text')}}</span>
            </template>
          </b-table>
          <b-pagination v-if="invoices.length>perPage" style="max-width:800px;margin:auto;" v-model="currentPage" :total-rows="invoices.length" :per-page="perPage" align="fill" size="sm" class="my-0" ></b-pagination>
        </div>
      </div>
      <b-modal id="slide-modal" v-model="showInvoiceModal" centered :title="$t('subscription.invoice_header')">
          <div v-html="invoice_html">
          </div>
            <div slot="modal-footer" class="submit-box-2">
                <b-button class="submit-button4" v-on:click="showInvoiceModal=false" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
            </div>
        </b-modal>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
.edit-button {
    width: 30px;
    height: 30px;
    background: url("../assets/button-Edit.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: block;
    margin: auto;
  }
</style>
<script>
  import router from "@/router"
    export default {
    name: "SubscriptionInfo",
      data() {
        return {
            perPage: 10,
        currentPage:1,
            showInvoiceModal: false,
            invoices: [],
            subscription_info: {
                global_discount: 0,
                chapter_price: 0,
                chapter_pays: 0,
                customer: {email: '', name: '', phone:'', address: {
                    city: '',
                    country: '',
                    line1: '',
                    line2: '',
                    postal_code: '',
                    state: ''
                }},
                card: {
                    id: '',
                    last4: '',
                    brand: '',
                    exp_month: '',
                    exp_year: ''
                }
            },
            invoice_html: '',
            fields: [
            {
              key: 'id',
              label: this.$t('subscription.invoice_header'),
              thStyle: { "text-align":  "center"},
              tdClass: "actions-centre"
              
            },
            {
              key: 'day',
              label: this.$t('subscription.date_header'),
              thStyle: { "text-align":  "center"},
              tdClass: "actions-centre"
            },
            {
              key: 'amount',
              label: this.$t('subscription.amount_header'),
              thStyle: { "text-align":  "center"},
              tdClass: "actions-centre"
            },
            {
              key: 'description',
              label: this.$t('subscription.description_header')
            },
            {
              key: 'status',
              label: this.$t('subscription.status_header'),
              thStyle: { "text-align":  "center"},
              tdClass: "actions-centre"
            },
          ],
        }
      },
        methods: {
          
            retryInvoice(invoice_id){
                this.$http.post("/api/organization/invoice/retry", {id: invoice_id}).then((response) => {
                if (response.data.success === true) {
                    this.getInvoices();
                    this.$bvToast.toast(this.$t('subscription.retry_success'), {
                      variant: 'success',
                      solid: true
                    });
                }else{
                  this.$bvToast.toast(this.$t('subscription.retry_failure'), {
                variant: 'danger',
                solid: true
              });
                }
                }).catch(() => {
                this.$bvToast.toast(this.$t('subscription.retry_failure'), {
                    variant: 'danger',
                    solid: true
                  });});
            },
            showInvoice(invoice_id){
                this.$http.post("/api/organization/subscription/invoice/get/html", {id: invoice_id}).then((response) => {
                if (response.data.success === true) {
                    this.invoice_html = response.data.invoice_html;
                    this.showInvoiceModal = true;
                }
                }).catch(() => {this.$bvToast.show('serverError');});
            },
            getSubscriptionInfo(){
                this.$http.post("/api/organization/subscription/get", {}).then((response) => {
                if (response.data.success === true) {
                    this.subscription_info = response.data;
                }
                }).catch(() => {this.$bvToast.show('serverError');});
            },
            getInvoices(){
                
                this.$http.post("/api/organization/subscription/invoices/get", {}).then((response) => {
                    if (response.data.success === true) {
                        this.invoices = response.data.invoices;
                    }
                }).catch(() => {this.$bvToast.show('serverError');});
            },
            
            back(){
                window.history.length > 1 ? router.go(-1) : router.push("/admin/regiondash");
            },
            goToSubscription(){
                router.push("/admin/subscription");
            }
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-admin"), "active-menu");
      this.removeClass(document.getElementById("menu-subscription"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-admin"), "active-menu");
      this.addClass(document.getElementById("menu-subscription"), "active-menu");
      this.getSubscriptionInfo();
      this.getInvoices();
    }
    }
</script>
