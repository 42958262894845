<template>
 <div v-if="!loggedIn && allow_logins && permissions.organization.official" style="max-width: 600px;margin-top:50px;margin:auto; text-align:center;padding: 50px 0px;font-size:12px;">
    <b-alert variant="light" style="boder:none;"  :show="!hasPass">
        <div>{{$t('footer.no_pass1')}}</div>
        <div>{{$t('footer.no_pass2')}}</div><br/>
        <div><b-button variant="link" v-on:click="goToReset" style="font-size: 12px;margin-bottom: 2px;" class="strip-button">{{$t('footer.no_pass3')}}</b-button></div>
    </b-alert>
    <b-alert variant="light" style="boder:none;" :show="hasPass">
        <div>{{$t('footer.yes_pass1')}}</div><br/>
        <div>{{$t('footer.yes_pass2')}} <b-button variant="link" style="font-size: 12px;margin-bottom: 2px;" v-on:click="goToLogin" class="strip-button">{{$t('footer.yes_pass3')}}</b-button></div>
    </b-alert>
</div>
</template>
<style scoped>
.strip-button{
      padding: 0px;
    margin: 0px;
    border: 0;
    line-height: 100%;
}
</style>
<script>
import router from "../router"
    export default {
    name: "Footer",
    props: {useruid: {type: String, default: ""}, speakerhash: {type: String, default: ""},byId: {type: Boolean, default: false}},
    
  data() {
    return {
      loggedIn: true,
      hasPass: false,
      email: '',
      reset_hash: "",
      allow_logins: false
    };
  },
   mounted() {
       if(this.useruid != ''){
           this.getByUid();
       }else if(this.speakerhash != ''){
           this.getBySpeakerHash();
       }else if(this.byId){
         this.getById();
       }
  },
  methods: {
    goToReset(){
      router.push("/reset/"+this.reset_hash);
    },
    sendCreateLogin(){
      this.$http.post("/api/password/reset", {name: this.email}).then((response) => {
          if (response.data.sent === true) {
            this.$bvToast.toast(this.$t('footer.sent_email'), {
                variant: 'success',
                solid: true
              });
          } else {
            var reset_alert = response.data.errorcode>0? this.$t('login.reset_alert'+response.data.errorcode): this.$t('login.reset_error');
            this.$bvToast.toast(reset_alert, {
                variant: 'danger',
                solid: true
              });
          }
        }).catch(() => {
          var reset_alert =  this.$t('login.reset_error');
            this.$bvToast.toast(reset_alert, {
                variant: 'danger',
                solid: true
              });
        });
    },
    goToLogin(){
      router.push("/login");
    },
      getById(){
        this.$http.post("/api/organization/footerinfo", { }).then(response => {
          if (response.data.success === true) {
            this.hasPass = response.data.has_pass==1;
            this.loggedIn = response.data.logged_in;
            this.email = response.data.email;
            this.reset_hash = response.data.reset_hash;
            this.allow_logins = response.data.no_logins ==0;
          } 
        }).catch(() => {});
    },
    getByUid(){
        if(this.useruid && this.useruid.length>0){
             this.$http.post("/api/organization/footerinfo/byuseruid", {useruid: this.useruid }).then(response => {
              if (response.data.success === true) {
              this.hasPass = response.data.has_pass==1;
              this.loggedIn = response.data.logged_in;
              this.email = response.data.email;
              this.reset_hash = response.data.reset_hash;
              this.allow_logins = response.data.no_logins ==0;
              } 
            }).catch(() => {});
        }
    },
    getBySpeakerHash(){
        this.$http.post("/api/organization/footerinfo/byspeakerhash", {speakerhash: this.speakerhash }).then(response => {
          if (response.data.success === true) {
            this.hasPass = response.data.has_pass==1;
            this.loggedIn = response.data.logged_in;
            this.email = response.data.email;
            this.reset_hash = response.data.reset_hash;
            this.allow_logins = response.data.no_logins ==0;
          } 
        }).catch(() => {});
    }
  }
}
</script>