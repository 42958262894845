<template>
  <div style="height:100%;">
      <Header :speakerhash="this.$route.params.hash" :showDate="false" :alwaysShow="true" :title="$t('schedule.confirm_speaker')" ></Header>
      
      <div v-if="returned && not_expired">
      <div style="background-color: #eeeeee;padding: 10px;max-width: 600px;margin: 50px auto;">
        <div class="fit-width" id="speaker-description">
            <span class="form-box-name" style="margin: 0px 5px;">{{$t('schedule.member_name')}}</span>
            <span style="font-weight:bold;">{{speaker.name}}</span>
            <span class="form-box-name" style="margin: 0px 5px;">{{$t('schedule.date_and_time')}}</span>
            <span style="font-weight:bold;">{{speaker.date_time}}</span>
        </div>
        </div>
        <div class="main-colour" style="text-align:center;">
            <div>{{$t('schedule.thank_you')}}</div>
            <br/>
            <div v-html="speaker.status==0? $t('schedule.speaker_confirmed') : $t('schedule.speaker_declined')"></div>
        </div>
    </div>
    <div v-if="returned && !not_expired" class="main-colour" style="text-align:center;margin-top: 200px;">
        <div>{{$t('schedule.sorry_text')}}</div>
        <br/>
        <div>{{$t('schedule.link_expired')}}</div>
    </div>

      <div class="footer white-text center">
        Meeting HUB for {{isCorporateConnections? 'Corporate Connections' : 'BNI'}} was designed and developed by Meeting HUB Inc.<br>
        © Meeting HUB Inc. 2021, All Rights Reserved.<br>
        Use of Meeting HUB is subject to our <a v-on:click="goTerms()" class="clickable" target="_blank"><u>Terms and Conditions</u></a> and <a class="clickable" v-on:click="goPrivacy()" target="_blank"><u>Privacy Policy</u></a>.
    </div>
  </div>
</template>
<style scoped>
#logo-box{
     margin: auto;margin-bottom:25px; max-width: 600px;
    background-image: url("../assets/logo-MeetingHubwSlogan2020.png");
    background-repeat: no-repeat;
    background-size: auto 150px;
    background-position: center;
    width:600px;
    background-color: #FFFFFF;
    height: 150px;
}
.center{
        text-align: center;
    }
    .underline{
        text-decoration: underline;
    }
    .footer{
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 75px;
        background-color: #000;
        padding: 8px 0 0 0;
        font-size: 13px;
    }
    .white-text{
        color: #fff !important;
    }
#speaker-description{
  display: grid;grid-template-columns: max-content max-content;margin: auto;
}
</style>
<script>
export default {
  name: "ConfirmJoint",
  data() {
        return {
            alertText: "",
            alertVariant: "success",
            hash : "",
            returned: false,
            not_expired: false,
            speaker: {name: '', date_time: ''}
        }
  },
  methods: {
    confirm(){
    if(this.$route.params.hash){
        this.hash = this.$route.params.hash;
      }
      this.$http.post("/api/organization/speaker/confirm",{hash: this.hash, confirmed: this.$route.params.confirmed}).then((response) => {
          if(response.data.success){
             this.speaker = response.data.speaker;
             this.not_expired = this.speaker.not_expired;
          }else{
              this.not_expired = false;
          }
          this.returned = true;
      }).catch((e) => { 
          this.returned = true;
       console.log(e);
      })
    },
  },
  created() {
    this.confirm();
  }
}
</script>
