<template>
  <div style="height:100%;">
    <div class="rel-grid-container" style="top:20px; display: grid;grid-template-rows: auto max-content auto;height:100%;">
      <div id="top-div"></div>
      <div style="margin-bottom:20px;">
       <!-- <div id="role-select">
          <span class="form-box-name">{{$t('members.show_chapter')}} </span>
          <b-form-select style="margin: 5px 0px;" required v-model="permissions.filterRoleId" :options="chapters" value-field="id" text-field="name" class="mb-3 " />
        </div>
        <SetupTip style="position:absolute; left: -40px;" :tipNumber="10"/>
        <b-table class="table-hover-new" style="margin-bottom:0px;" small bordered :per-page="perPage" @filtered="onFiltered" :filter="permissions.filterRoleId" :filter-function="tableFilter" :current-page="currentPage" :items="members" :fields="fields" :tbody-tr-class="rowClass" >
            <template v-slot:cell(roles)="data">
               {{data.item.roles? (data.item.roles + (data.item.sub_role.length>0? ", "+$t('member.sub_role')+": "+data.item.sub_role : '')) : ''}}
            </template>
          </b-table>
          <b-pagination  v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </div>-->
      <div v-if="showAlert">
        <b-alert variant="danger" dismissible :show="showAlert"  @dismissed="showAlert=false">
          {{alertText}}
        </b-alert>
      </div>
      <div v-if="showSuccess">
        <b-alert variant="success" dismissible :show="showSuccess"  @dismissed="showSuccess=false">
          {{$t('members.import_success')}}
        </b-alert>
      </div>
      <div v-if="showUpload">
        <b-alert style="line-height:30px;" variant="warning" :show="showUpload">
          {{$t('preparer.uploading_text')}}
          <b-spinner style="float:right;" label="Uploading" variant="warning"></b-spinner>
        </b-alert>
      </div>
      <div class="submit-box-2">
        <b-button v-if="!onboard && permissions.access" class="submit-button4" v-on:click="showImportChapters=true" variant="clear-blue"><div class="import-button"></div><span>{{$t('members.import_chapters')}}</span></b-button>
        <b-button v-if="!onboard && permissions.access" class="submit-button4" v-on:click="showImportAllMembers=true" variant="clear-blue"><div class="import-button"></div><span>{{$t('members.import_members')}}</span></b-button>
        <b-button v-if="!onboard && permissions.access" class="submit-button4" v-on:click="showImportAllRoles=true" variant="clear-blue"><div class="import-button"></div><span>{{$t('members.import_roles')}}</span></b-button>
        <b-button class="submit-button4" v-on:click="exportMembers" variant="clear-blue"><div class="import-button"></div><span>{{$t('attendance.export_members')}}</span></b-button>
        <div v-if="exportFile.filename.length>0 && showSuccess" style="margin:auto;" class="fit-width"> 
              <b-button class="submit-button4" v-on:click="downloadAttendanceExport" variant="clear-blue" ><div class="import-button"></div><span>{{$t('attendance.download_export')}}</span></b-button>
              <a id="export-file" :download="exportFile.filename" :href="exportFile.content" hidden></a>
            </div>
        <!--<<b-button v-if="!onboard && permissions.access" class="submit-button4" v-on:click="showImportRoleMembers=true" variant="clear-blue"><div class="import-button"></div><span>{{$t('members.import_roles')}}</span></b-button>
        b-button v-if="permissions.filterRoleId!=0" class="submit-button4" v-on:click="newMember" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>-->
      </div>

      <div class="div-space"></div>

    </div>
    <div></div>
    <b-modal centered  v-model="showImportMembers" :title="$t('members.import_title')">
         <div>
           <div style="max-width: 600px;margin: auto;">
            <div class="form-box" style="grid-template-columns: 50% 50%;">
              <b-button variant="blue" v-on:click="openDialog" style="width:200px;">{{$t('import.select_file')}}</b-button>
              <b-form-file ref="file-input"  id='fileid' accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="onFileChange" plain hidden></b-form-file>
              <div style="margin: auto;">
               
              </div>
              <div style="grid-column: 1/3; margin-top: 20px;font-size:12px;">
                *{{$t('members.import_note')}}
              </div>
            </div>
          </div>
           <div v-if="showUpload">
            <b-alert style="line-height:30px;" variant="warning" :show="showUpload">
              {{$t('import.importing_message')}}
              <b-spinner style="float:right;" label="Uploading" variant="warning"></b-spinner>
            </b-alert>
          </div>
         </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="submit-button4" v-on:click="showImportMembers=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
        </div>
    </b-modal>
       <b-modal centered id="slide-modal-4" v-model="showPreviewImport" :title="$t('import.import_title')">
        <div id="preview-members">
          <b-table class="table-hover-new" small bordered :items="previewMembers" :fields="previewFields">
            <template v-slot:cell(import)="data">
              <b-form-checkbox v-if="(data.item.id==0 || data.item.org_id==0) || data.item.shadow==1" v-model="data.item.import" value="1" unchecked-value="0" style="margin:auto;">
            </b-form-checkbox>
            </template>
          </b-table>
        </div>
        <div v-html="$t('members.preview_note')">
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="submit-button4" v-on:click="cancelImport" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="submit-button4" v-on:click="importChanges" variant="clear-blue"><div class="import-button"></div><span>{{$t('global.button_import')}}</span></b-button>
        </div>
      </b-modal>
      <b-modal centered  v-model="showImportRoleMembers" :title="$t('members.role_import_title')">
         <div>
           <div style="max-width: 600px;margin: auto;">
            <div class="form-box" style="grid-template-columns: 50% 50%;">
              <b-button variant="blue" v-on:click="openDialog2" style="width:200px;">{{$t('import.select_file')}}</b-button>
              <b-form-file ref="file-input"  id='fileid2' accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="onFileChange2" plain hidden></b-form-file>
              <div style="margin: auto;">
               
              </div>
              <div style="grid-column: 1/3; margin-top: 20px;font-size:12px;">
                *{{$t('members.role_import_note')}}
              </div>
            </div>
          </div>
           <div v-if="showUpload">
            <b-alert style="line-height:30px;" variant="warning" :show="showUpload">
              {{$t('import.importing_message')}}
              <b-spinner style="float:right;" label="Uploading" variant="warning"></b-spinner>
            </b-alert>
          </div>
         </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="submit-button4" v-on:click="showImportRoleMembers=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
        </div>
    </b-modal>
       <b-modal centered id="slide-modal-4" v-model="showRolePreviewImport" :title="$t('import.import_title')">
        <div id="preview-members">
          <b-table class="table-hover-new" small bordered :items="previewMembers" :fields="previewRoleFields">
            <template v-slot:cell(import)="data">
              <b-form-checkbox v-if="(data.item.id==0 || data.item.org_id==0) || data.item.shadow==1" v-model="data.item.import" value="1" unchecked-value="0" style="margin:auto;">
            </b-form-checkbox>
            </template>
          </b-table>
        </div>
        <div v-html="$t('members.preview_note')">
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="submit-button4" v-on:click="cancelImport" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="submit-button4" v-on:click="importRoleChanges" variant="clear-blue"><div class="import-button"></div><span>{{$t('global.button_import')}}</span></b-button>
        </div>
      </b-modal>
      <b-modal centered  v-model="showImportChapters" :title="$t('members.chapters_import_title')">
         <div>
           <div style="max-width: 600px;margin: auto;">
            <div class="form-box" style="grid-template-columns: 50% 50%;">
              <b-button variant="blue" v-on:click="openDialog3" style="width:200px;">{{$t('import.select_file')}}</b-button>
              <b-form-file ref="file-input"  id='fileid3' accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="onFileChange3" plain hidden></b-form-file>
              <div style="margin: auto;">
               
              </div>
            </div>
          </div>
           <div v-if="showUpload">
            <b-alert style="line-height:30px;" variant="warning" :show="showUpload">
              {{$t('import.importing_message')}}
              <b-spinner style="float:right;" label="Uploading" variant="warning"></b-spinner>
            </b-alert>
          </div>
         </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="submit-button4" v-on:click="showImportChapters=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
        </div>
    </b-modal>
      <b-modal centered id="slide-modal-4" v-model="showChapterPreviewImport" :title="$t('import.import_title')">
        <div id="preview-members">
          <b-table class="table-hover-new" small bordered :items="previewChapters">
            <template v-slot:cell(import)="data">
              <b-form-checkbox v-if="data.item.can_import" v-model="data.item.import" value="1" unchecked-value="0" style="margin:auto;">
            </b-form-checkbox>
            </template>
          </b-table>
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="submit-button4" v-on:click="cancelImport" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="submit-button4" v-on:click="importChapterData" variant="clear-blue"><div class="import-button"></div><span>{{$t('global.button_import')}}</span></b-button>
        </div>
      </b-modal>

      <b-modal centered  v-model="showImportAllMembers" :title="$t('members.import_title')">
         <div>
           <div style="max-width: 600px;margin: auto;">
            <div class="form-box" style="grid-template-columns: 50% 50%;">
              <b-button variant="blue" v-on:click="openDialog4" style="width:200px;">{{$t('import.select_file')}}</b-button>
              <b-form-file ref="file-input"  id='fileid4' accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="onFileChange4" plain hidden></b-form-file>
              <div style="margin: auto;padding:20px;">
                <b-form-checkbox v-model="only_new" value="1" unchecked-value="0" style="margin:auto;">
                  {{$t('import.only_new')}}
                </b-form-checkbox>
              </div>
            </div>
          </div>
           <div v-if="showUpload">
            <b-alert style="line-height:30px;" variant="warning" :show="showUpload">
              {{$t('import.importing_message')}}
              <b-spinner style="float:right;" label="Uploading" variant="warning"></b-spinner>
            </b-alert>
          </div>
         </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="submit-button4" v-on:click="showImportAllMembers=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
        </div>
    </b-modal>
    <b-modal centered  v-model="showImportAllRoles" :title="$t('members.import_roles')">
         <div>
           <div style="max-width: 600px;margin: auto;">
            <div class="form-box" style="grid-template-columns: 50% 50%;">
              <b-button variant="blue" v-on:click="openDialog5" style="width:200px;">{{$t('import.select_file')}}</b-button>
              <b-form-file ref="file-input"  id='fileid5' accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="onFileChange5" plain hidden></b-form-file>
              <div style="margin: auto;">
               
              </div>
            </div>
          </div>
           <div v-if="showUpload">
            <b-alert style="line-height:30px;" variant="warning" :show="showUpload">
              {{$t('import.importing_message')}}
              <b-spinner style="float:right;" label="Uploading" variant="warning"></b-spinner>
            </b-alert>
          </div>
         </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="submit-button4" v-on:click="showImportAllRoles=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
        </div>
    </b-modal>
      <b-modal centered id="slide-modal-4" v-model="showAllMembersPreviewImport" :title="$t('import.import_title')">
        <div id="preview-members">
          <b-alert style="line-height:30px;" variant="info" :show="only_roles">
              {{$t('import.roles_only_note')}}
            </b-alert>
             <table class="table b-table table-bordered table-sm">
          <thead>
            <tr>
              <th class="tableHeader center-text">Name </th>
              <th class="tableHeader center-text" style="white-space: pre;" v-for="field in optionalFields" :key="field.header"><b-form-checkbox v-model="field.enabled" value="1" unchecked-value="0" style="margin:auto;">
                  {{field.header}}
              </b-form-checkbox>
              </th>
              <th class="tableHeader center-text">Import</th>
            </tr>
          </thead>
            <tbody>
              <tr v-for="member in previewMembers.slice((currentPage-1)*perPage, (currentPage)*perPage-1)" :key="member" :style="{'background-color': member._rowVariant=='danger'? 'red' : (member._rowVariant=='warning'? 'yellow' : 'green')}">
                <td>{{member.name}}</td>
                <td v-for="field in optionalFields" :key="field.header" style="word-break: break-all;">{{member[field.field]? member[field.field] : ""}}</td>
                <td>
                  <b-form-checkbox v-if="member.can_import" v-model="member.import" value="1" unchecked-value="0" style="margin:auto;"></b-form-checkbox>
                </td>
              </tr>
            </tbody>
          </table>
          <!--<b-table class="table-hover-new" style="margin-bottom:0px;" small bordered :per-page="perPage" :current-page="currentPage" :items="previewMembers" >
            <template v-slot:cell(import)="data">
              <b-form-checkbox v-if="data.item.can_import" v-model="data.item.import" value="1" unchecked-value="0" style="margin:auto;">
            </b-form-checkbox>
            </template>
          </b-table>-->
          {{previewMembers.length}}
          <b-pagination  v-model="currentPage" :total-rows="previewMembers.length" :per-page="perPage" align="fill" size="sm" class="my-0" ></b-pagination>
        </div>
         <div>
            Total Members: {{previewMembers.length}}
          </div>
          <div>
            Unfound Roles: {{notRoles.length==0? 'None' : notRoles.join(',')}}
          </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="submit-button4" v-on:click="cancelImport" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="submit-button4" v-on:click="importAllMembersData" variant="clear-blue"><div class="import-button"></div><span>{{$t('global.button_import')}}</span></b-button>
        </div>
      </b-modal>
    </div>
    </div>
</template>
<style scoped>
#preview-members{
   max-height:500px; overflow: auto;
 }
#role-select{
  display:grid; grid-template-columns:auto auto; width: 400px;margin:auto;
}
</style>
<script>
  import router from "@/router"
    export default {
    name: "AdminImport",
     props:{
      onboard: Boolean,
    },
    watch: {
      'permissions.filterRoleId': {
        handler: function () {
          this.getMembers();
        },
        deep: true
      },
      '$i18n.locale': {
        handler: function () {
          if(this.roles.length>0){
            this.roles[0].name = this.$t('members.all_chapters');
          }
        },
        deep: true
      }
    },
    data() {
      return {
        optionalFields: [],
        showAllMembersPreviewImport: false,
        only_new: 0,
        showImportAllRoles: false,
        only_roles: false,
        notRoles: [],
        showImportAllMembers: false,
        showImportAllPreviewImport: false,
        showImportChapters: false,
        showChapterPreviewImport: false,
        previewChapters: [],
        perPage: 20,
        currentPage:1,
        totalRows:0,
        selectedMember: null,
        showLoginAs: false,
        members: [],
        showUpload: false,
        showAlert: false,
        saveVariant: "",
        alertText: "",
        showSuccess: false,
        members_num: 0,
        chapters: [],
        fields: [
           { key: 'org_name',
            label: this.$t('members.organization_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
          { key: 'name',
            label: this.$t('members.name_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'category',
            label: this.$t('members.category_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'company',
            label: this.$t('members.company_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'roles',
            label: this.$t('members.role_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" },
            tdClass: 'breakAll'
          }
        ],
        previewRoleFields: [
           { key: 'chapter_name',
            label: this.$t('import.chapter_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
          { key: 'name',
            label: this.$t('import.name_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'role',
            label: this.$t('import.role_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'import',
              label: this.$t('events.import_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff", 'text-align': 'center'},
            }
        ],
        previewFields: [
           { key: 'chapter_name',
            label: this.$t('import.chapter_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
          { key: 'name',
            label: this.$t('import.name_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'category',
            label: this.$t('import.category_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'company',
            label: this.$t('import.company_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'email',
            label: this.$t('import.email_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" },
            tdClass: 'breakAll'
          },
           { key: 'phone',
            label: this.$t('import.phone_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
            { key: 'website',
            label: this.$t('import.website_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" },
            tdClass: 'breakAll'
          },
           { key: 'job_title',
            label: this.$t('import.job_title'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'linkedin_url',
            label: this.$t('import.linkedin_url'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'preferred_language',
            label: this.$t('import.preferred_language'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'import',
              label: this.$t('events.import_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff", 'text-align': 'center'},
            }
        ],
          showImport: false,
          showImportMembers : false,
          showPreviewImport: false,
          previewMembers: [],
          showRoleImport: false,
          showImportRoleMembers: false,
          showRolePreviewImport: false,
          exportFile: {filename: '', content: ''},
      }
    },
    methods: {
      downloadAttendanceExport(){
        document.getElementById("export-file").click();
      },
      exportMembers(){
        this.$http.post("/api/superadmin/members/export", {}).then((response) => {
          if (response.data.success === true) {
            this.exportFile = response.data.file;
            this.showSuccess = true;
          }else{
            this.showAlert = true;
          }
        }).catch(() => { });
      },
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      tableFilter(row, filter){
        if(row.organization== filter || filter==0){
          return true;
        }
        return false;
      },
      onPopoverShow(member) {
        if (!member.gotThumbnail) {
          this.$http.post("/api/member/get/photo", { id: member.id }).then((response) => {
            if (response.data.success) {
              this.$set(member, "thumbnail", response.data.photo);
              this.$set(member, "gotThumbnail", true);
            }
          }).catch((errors) => {
            console.log(errors);
          });
        }
      },
      getMembers() {
        this.$http.post("/api/region/children/members",{}).then((response) => {
            this.members = response.data.members;
            this.totalRows = response.data.members.length;
        }).catch((errors) => {console.log(errors);});
      },
      newMember() {
        if (this.permissions.filterRoleId>0) {
          router.push("/admin/member/0/"+this.permissions.filterRoleId);
        }
      },
      editMember(item) {
        if (item.shadow==1) {
          router.push("/admin/member/" + item.id);
        }
      },
      importPage() {
        router.push("/admin/import");
      },
      getChildren() {
        this.$http.post("/api/organization/get/children/select", {}).then((response) => {
          if (response.data.success) {
            this.chapters = response.data.children;
            this.chapters.unshift({id: 0, name: this.$t('members.all_chapters')});
          }
        }).catch(() => { });
      },
      onFileChange(e) {
         var files = e.target.files || e.dataTransfer.files;
        if (!files.length || !this.checkFileSize(files[0]))
          return;
        this.file = files[0];
        //this.getMembers();
        this.importMembers();
      },
      importAllMembersData(){
        var vs = this.previewMembers.filter(g => g.import == 1);
          if(vs.length>0){
            this.$http.post("/api/region/allmembers/import/all", { members: vs, only_roles: this.only_roles,optionalFields: this.optionalFields}).then((response) => {
              this.$set(this, 'showAllMembersPreviewImport', false);
              if (response.data.success === true) {
                this.$bvToast.toast('Imported/Updated '+response.data.rows+" members", {
                  title: "Success",
                  variant: 'success',
                  solid: true
                });
              }
          }).catch(() => {this.showAllMembersPreviewImport = false;});
        }else{
          this.showAllMembersPreviewImport = false;
        } 
      },
      importAllMembers() {
        this.showImportAllMembers = false;
        this.showImportAllRoles = false;
        this.showUpload = true;
        this.showAlert = false;
        this.alertText = this.$t('import.alert_text');
        this.saveVariant = "";
        this.$refs['file-input'].reset();
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append('only_roles', this.only_roles);
        formData.append('only_new', this.only_roles? 0 : this.only_new);
        this.$http.post("/api/region/allmembers/import/excel", formData, {headers: {"Content-Type": "multipart/form-data"}}).then((response) => {
          this.showImport = false;
          this.showUpload = false;
          this.optionalFields = response.data.fields;
          this.saveVariant = "blue";
          if (response.data.success == true) {
            this.previewMembers = response.data.members;
            this.notRoles = response.data.notRoles;
            this.showAllMembersPreviewImport = true;
            this.only_roles = response.data.only_roles;
          } else {
            this.showAlert = true;
            this.alertText = response.data.error;
          }
        }).catch((errors) => {
          this.showImport = false;
          this.showUpload = false;
          this.saveVariant = "blue";
          this.showAlert = true;
          this.alertText = JSON.stringify(errors);
        });
      },
      importChapterData(){
        var vs = this.previewChapters.filter(g => g.import == 1);
          if(vs.length>0){
            this.$http.post("/api/region/chapters/import/all", { chapters: vs}).then((response) => {
            if (response.data.success === true) {
              this.showChapterPreviewImport = false;
              this.$bvToast.toast('Imported/Updated '+response.data.rows+" chapters", {
                title: "Success",
                variant: 'success',
                solid: true
              });
            }
          }).catch(() => { });
        }else{
          this.showChapterPreviewImport = false;
        } 
      },
      importChapters() {
        this.showImportChapters = false;
        this.showUpload = true;
        this.showAlert = false;
        this.alertText = this.$t('import.alert_text');
        this.saveVariant = "";
        this.$refs['file-input'].reset();
        let formData = new FormData();
        formData.append("file", this.file);
        this.$http.post("/api/region/chapters/import/excel", formData, {headers: {"Content-Type": "multipart/form-data"}}).then((response) => {
          this.showImport = false;
          this.showUpload = false;
          this.saveVariant = "blue";
          if (response.data.success == true) {
            this.previewChapters = response.data.chapters;
            this.showChapterPreviewImport = true;
          } else {
            this.showAlert = true;
            this.alertText = response.data.error;
          }
        }).catch((errors) => {
          this.showImport = false;
          this.showUpload = false;
          this.saveVariant = "blue";
          this.showAlert = true;
          this.alertText = JSON.stringify(errors);
        });
      },
      importMembers() {
        this.showImportMembers = false;
        this.showUpload = true;
        this.showAlert = false;
        this.alertText = this.$t('import.alert_text');
        this.saveVariant = "";
        this.$refs['file-input'].reset();
        let formData = new FormData();
        formData.append("file", this.file);
        this.$http.post("/api/region/members/import/excel", formData, {headers: {"Content-Type": "multipart/form-data"}}).then((response) => {
          this.showImport = false;
          this.showUpload = false;
          this.saveVariant = "blue";
          if (response.data.success == true) {
            this.previewMembers = response.data.members;
            this.showPreviewImport = true;
          } else {
            this.showAlert = true;
            this.alertText = response.data.error;
          }
        }).catch((errors) => {
          this.showImport = false;
          this.showUpload = false;
          this.saveVariant = "blue";
          this.showAlert = true;
          this.alertText = JSON.stringify(errors);
        });
      },
       onFileChange2(e) {
         var files = e.target.files || e.dataTransfer.files;
        if (!files.length || !this.checkFileSize(files[0]))
          return;
        this.file = files[0];
        //this.getMembers();
        this.importRoles();
      },
       onFileChange3(e) {
         var files = e.target.files || e.dataTransfer.files;
        if (!files.length || !this.checkFileSize(files[0]))
          return;
        this.file = files[0];
        //this.getMembers();
        this.importChapters();
      },
      onFileChange4(e) {
         var files = e.target.files || e.dataTransfer.files;
        if (!files.length || !this.checkFileSize(files[0]))
          return;
        this.file = files[0];
        //this.getMembers();
        this.only_roles = false;
        this.importAllMembers();
      },
      onFileChange5(e) {
         var files = e.target.files || e.dataTransfer.files;
        if (!files.length || !this.checkFileSize(files[0]))
          return;
        this.file = files[0];
        //this.getMembers();
        this.only_roles = true;
        this.importAllMembers();
      },
      importRoles() {
        this.showImportRoleMembers = false;
        this.showUpload = true;
        this.showAlert = false;
        this.alertText = this.$t('import.alert_text');
        this.saveVariant = "";
        this.$refs['file-input'].reset();
        let formData = new FormData();
        formData.append("file", this.file);
        
        this.$http.post("/api/region/roles/import/excel", formData, {headers: {"Content-Type": "multipart/form-data"}}).then((response) => {
          this.showImport = false;
          this.showUpload = false;
          this.saveVariant = "blue";
          if (response.data.success == true) {
            this.previewMembers = response.data.members;
            this.showRolePreviewImport = true;
          } else {
            this.showAlert = true;
            this.alertText = response.data.error;
          }
        }).catch((errors) => {
          this.showImport = false;
          this.showUpload = false;
          this.saveVariant = "blue";
          this.showAlert = true;
          this.alertText = JSON.stringify(errors);
        });
      },
      cancelImport() {
        this.previewMembers = [];
        this.showPreviewImport = false;
        this.showRolePreviewImport = false;
        this.file = { name: "" };
        this.previewChapters = [];
        this.showChapterPreviewImport = false;
        this.showAllMembersPreviewImport = false;
      },
      importChanges() {
          var vs = this.previewMembers.filter(g => g.import == 1);
          if(vs.length>0){
            this.$http.post("/api/region/members/import/all", { members: vs}).then((response) => {
            if (response.data.success === true) {
              this.showPreviewImport = false;
              this.getMembers();
            }
          }).catch(() => { });
        }else{
          this.showPreviewImport = false;
        } 
      },
      importRoleChanges(){
        var vs = this.previewMembers.filter(g => g.import == 1);
          if(vs.length>0){
            this.$http.post("/api/region/roles/import/all", { members: vs}).then((response) => {
            if (response.data.success === true) {
              this.showRolePreviewImport = false;
              this.getMembers();
            }
          }).catch(() => { });
        }else{
          this.showRolePreviewImport = false;
        } 
      },
      openDialog() {
        document.getElementById('fileid').click();
      },
      openDialog2() {
        document.getElementById('fileid2').click();
      },
      openDialog3() {
        document.getElementById('fileid3').click();
      },
      openDialog4() {
        document.getElementById('fileid4').click();
      },
      openDialog5() {
        document.getElementById('fileid5').click();
      },
      rowClass(item) {
          if (!item) return
          //if(item.disabled==1 && item.shadow==1) return 'disabledClickClass';
          //if(item.shadow==1) return 'clickable'
          if (item.disabled==1) return 'disabledClass';
        },
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-members"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-members"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      //this.getChildren();
      //this.getMembers();
    }
    }
</script>
