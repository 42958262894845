<template>
  <div>
    <div class="rel-grid-container">
      <div id="top-div"></div>
      <Tip :tipNumber="27"></Tip>
      <div>
          <div v-if="bounced.length==0 && ret" style="text-align:center; font-size:20px;">{{$t('emails.no_blocked')}}</div>
        <b-table v-if="bounced.length>0" small bordered :items="bounced" :fields="fields" :per-page="perPage" :current-page="currentPage">
            <template v-slot:cell(BouncedAt)="data">
                {{data.item.BouncedAt? $d(new Date(data.item.BouncedAt),'all') : ''}}
             </template>
            <template v-slot:cell(actions)="data">
              <div style="display:grid; grid-template-columns: repeat(4,max-content); ">
                <b-button v-if="data.item.CanActivate" style="position:relative;font-size:16px;width:100px;margin:0 10px;" size="sm" @click.stop="reactivate(data.item)" variant="light-blue-outline">
                 {{$t('emails.reactivate_text')}}
                </b-button>
                <div v-if="!data.item.CanActivate">
                    {{$t('emails.cant_reactivate')}}
                </div>
              </div>
             </template>
        </b-table>
        <b-pagination v-if="bounced.length>50" v-model="currentPage" :total-rows="bounced.length" :per-page="perPage" align="fill" size="sm" class="my-0" ></b-pagination>
      </div>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>

</style>
<script>
    export default {
    name: "Bounced",
    
      data() {
        return {
            ret: false,
            perPage: 50,
            currentPage:1,
          fields: [
             { key: 'BouncedAt',
              label: this.$t('emails.block_time'),
              sortable: true,
              thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff", "width": "200px", "text-align": "center" },
              tdClass: "actions-centre"
            },
             
            { key: 'Email',
              label: this.$t('emails.email_text'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
             { key: 'member_name',
              label: this.$t('emails.member_name'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            },
           { key: 'actions',
              label: "",
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff", "text-align": "center"  },
              tdClass: "actions-centre"
            }
          ],
          bounced: [],
        }
      },
    methods: {
        reactivate(item){
            this.$http.post("/api/organization/email/reactivate", {id: item.ID}).then((response) => {
            if (response.data.success) {
                var index = this.bounced.indexOf(item);
                if (index > -1) {
                    this.bounced.splice(index, 1);
                }
              this.$bvToast.toast(this.$t('emails.email_reactivated'), {
                variant: 'success',
                solid: true
                });
            }else{
                this.$bvToast.toast(this.$t('emails.email_not_reactivated'), {
                variant: 'danger',
                solid: true
                });
            }
          }).catch(() => {
          });
        },
      getEmails() {
          this.$http.post("/api/organization/get/bounced", {}).then((response) => {
            if (response.data.success) {
                if(response.data.isParent){
                    this.fields= [
                        { key: 'org_name',
                        label: this.$t('emails.chapter_header'),
                        sortable: true,
                        thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff"},
                        },
                        { key: 'BouncedAt',
                        label: this.$t('emails.block_time'),
                        sortable: true,
                        thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff", "width": "200px", "text-align": "center" },
                        tdClass: "actions-centre"
                        },
                        { key: 'Email',
                        label: this.$t('emails.email_text'),
                        sortable: true,
                        thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
                        },
                        { key: 'member_name',
                        label: this.$t('emails.member_name'),
                        sortable: true,
                        thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
                        },
                    { key: 'actions',
                        label: "",
                        thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff","width": "200px",  "text-align": "center"  },
                        tdClass: "actions-centre"
                        }
                    ];
                }
                this.ret = true;
              this.bounced = response.data.bounced;
            }
          }).catch(() => {
              this.ret = true;
          });
      },
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-bounced"), "active-menu");
      this.removeClass(document.getElementById("menu-admin"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-bounced"), "active-menu");
      this.addClass(document.getElementById("menu-admin"), "active-menu");
      
      //this.getBlocks();
      this.getEmails();
    }
    }
</script>
